import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

export const moduleId = 'newsCategory'

// Constants
export const constants = {
    FETCH: 'NEWSCATEGORY/FETCH',
    FETCH_FAILURE: 'NEWSCATEGORY/FETCH_FAILURE',
    RECEIVE_NEWS_CATEGORY_FILTERS: 'NEWSCATEGORY/RECEIVE_NEWS_CATEGORY_FILTERS'
}

// Action Creators
export const actions = {
    getNewsCategoryFilters(data = []) {
        return ({ type: constants.RECEIVE_NEWS_CATEGORY_FILTERS, data })
    }
}

// Reducer
export const initialState = fromJS({
    fetching: false,
    fetchError: null,
    newsCategoryFilters: []
})

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.FETCH:
            return state
                .set('fetching', true)
                .set('fetchError', null)
        case constants.FETCH_FAILURE:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
        case constants.RECEIVE_NEWS_CATEGORY_FILTERS:
            return state
                .set('newsCategoryFilters', parseFilters(action?.data))
        default:
            return state
    }
}
// Selectors
const getState = (state) => state.newsCategory

export const selectors = {
    fetching: createSelector(getState, (state) => state.get('fetching')),
    newsCategoryFilters: createSelector(getState, (state) => state.get('newsCategoryFilters'))
}

// Helper functions

function parseFilters(filters) {
    let filterCategories = []
    const values = filters?.categoryName
    const categoryType = 'categoryName'
    const categoryValues = !values
        ? []
        : values.map((val, index) => {
            return {
                label: val,
                value: val,
                key: index,
                name: val
            }
        })
    filterCategories.push({ categoryType, categoryValues, searchBox: false })
    return filterCategories
}