import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { filterTypes as deviceValidateSoftwareFilterTypes } from 'constants/deviceValidateSoftwareConstant'
import {resolveStream} from 'store/modules/FileManagement'

export const moduleId = 'deviceValidateSoftware'
export const constants = {
  FETCH: 'VALIDATE_SOFTWARE/FETCH',
  FETCH_FILTER_FAILURE: 'VALIDATE_SOFTWARE/FETCH_FILTER_FAILURE',
  VALIDATE_SOFTWARE_RECEIVE_FILTER_TYPES: 'VALIDATE_SOFTWARE/VALIDATE_SOFTWARE_RECEIVE_FILTER_TYPES',
  VALIDATE_SOFTWARE_LIST_SUCCESS: 'VALIDATE_SOFTWARE/VALIDATE_SOFTWARE_LIST_SUCCESS',
  VALIDATE_SOFTWARE_LIST_FAILURE: 'VALIDATE_SOFTWARE/VALIDATE_SOFTWARE_LIST_FAILURE',
  FETCH_SOFTWARE_STATUS_LIST_SUCCESS: 'VALIDATE_SOFTWARE/FETCH_SOFTWARE_STATUS_LIST_SUCCESS',
  FETCH_SOFTWARE_STATUS_LIST_FAILURE: 'VALIDATE_SOFTWARE/FETCH_SOFTWARE_STATUS_LIST_FAILURE',
  UPDATE_VALIDATE_SOFTWARE_LIST_SUCCESS: 'VALIDATE_SOFTWARE/UPDATE_VALIDATE_SOFTWARE_LIST_SUCCESS',
  UPDATE_VALIDATE_SOFTWARE_LIST_FAILURE: 'VALIDATE_SOFTWARE/UPDATE_VALIDATE_SOFTWARE_LIST_FAILURE',
  RESET_DEVICE_VALIDATE_SOFTWARE_DATA: 'VALIDATE_SOFTWARE/RESET_DEVICE_VALIDATE_SOFTWARE_DATA',
  FETCHING_VALIDATE_SOFTWARE_DATA: 'VALIDATE_SOFTWARE/FETCHING_VALIDATE_SOFTWARE_DATA',
  DOWNLOAD_SUCCESS: 'VALIDATE_SOFTWARE/DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'VALIDATE_SOFTWARE/DOWNLOAD_FAILURE',
  UPDATE_DOWNLOAD_STATUS: 'VALIDATE_SOFTWARE/UPDATE_DOWNLOAD_STATUS',
  DISABLE_SOFTWARE_PACKAGE_ICON: 'VALIDATE_SOFTWARE/DISABLE_SOFTWARE_PACKAGE_ICON',
  RESET_SOFTWARE_PACKAGE_ACCEPTED_REJECTED: 'VALIDATE_SOFTWARE/RESET_SOFTWARE_PACKAGE_ACCEPTED_REJECTED'
}

export const actions = {
  fetchDeviceValidateSoftwareFilter () {
    return safeFetch({
      apiFunction: 'fetchDeviceValidateSoftwareFilter',
      onSuccess: (data) => ({ type: constants.VALIDATE_SOFTWARE_RECEIVE_FILTER_TYPES, data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error })
    })
  },
  fetchValidateSoftwareData (query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCHING_VALIDATE_SOFTWARE_DATA }),
      apiFunction: 'fetchValidateSoftwarePopupList',
      args: query,
      onSuccess: (data) => ({ type: constants.VALIDATE_SOFTWARE_LIST_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.VALIDATE_SOFTWARE_LIST_FAILURE, error })
    })
  },
  fetchSoftwareStatusList () {
    return safeFetch({
      apiFunction: 'fetchSoftwareStatusList',
      onSuccess: (data) => ({ type: constants.FETCH_SOFTWARE_STATUS_LIST_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_SOFTWARE_STATUS_LIST_FAILURE, error })
    })
  },
  updateValidateSoftwareData (query, action) {
    return safeFetch({
      apiFunction: 'updateValidateSoftwareData',
      args: query,
      onSuccess: () => ({ type: constants.UPDATE_VALIDATE_SOFTWARE_LIST_SUCCESS, action }),
      onFailure: (error) => ({ type: constants.UPDATE_VALIDATE_SOFTWARE_LIST_FAILURE, error })
    })
  },
  resetValidateSwData () {
    return (dispatch) => {
      dispatch({ type: constants.RESET_DEVICE_VALIDATE_SOFTWARE_DATA })
    }
  },
  resetSotwarePkgStatus () {
    return (dispatch) => {
      dispatch({ type: constants.RESET_SOFTWARE_PACKAGE_ACCEPTED_REJECTED })
    }
  },
  getdownloadFileArgs (getState, documentId) {
    return ({documentId})
  },
  downloadPackage (documentId, disableDocs) {
    let docValues = []
    return async (dispatch, getState) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.DISABLE_SOFTWARE_PACKAGE_ICON, disableDocs }),
        apiFunction: 'downloadFile',
        args: (getState) => actions.getdownloadFileArgs(getState, documentId),
        onSuccess: (data) => {
          docValues = selectors.downloadProgress(getState()) && selectors.downloadProgress(getState()).toJS()
          resolveStream(data)
          return { type: constants.DOWNLOAD_SUCCESS, docValues, documentId }
        },
        onFailure: (error) => {
          docValues = selectors.downloadProgress(getState()) && selectors.downloadProgress(getState()).toJS()
          return { type: constants.DOWNLOAD_FAILURE, error, docValues, documentId }
        }
      }))
      return resp
    }
  },
  resetSuccessItemStatus() {
    return { type: constants.UPDATE_DOWNLOAD_STATUS }
  }
}

export const initialState = fromJS({
  fetching: false,
  filterCategories: [],
  validateList: [],
  statusList: [],
  fetcError: null,
  fetchingValidateSoftwareData: false,
  downloadStatus: null,
  downloadProgress: [],
  softwarePkgAccepReject: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
      .set('fetching', true)
    case constants.VALIDATE_SOFTWARE_RECEIVE_FILTER_TYPES:
      return state
        .set('fetching', false)
        .set('filterCategories', formatFilterCategories(action.data.data))
    case constants.UPDATE_VALIDATE_SOFTWARE_LIST_SUCCESS:
      return state
        .set('fetching', false)
        .set('softwarePkgAccepReject', action.action)
    case constants.RESET_SOFTWARE_PACKAGE_ACCEPTED_REJECTED:
      return state
        .set('softwarePkgAccepReject', null)
    case constants.FETCH_FILTER_FAILURE:
      return state
      .set('fetching', false)
    case constants.VALIDATE_SOFTWARE_LIST_SUCCESS:
      return state
      .set('validateList', fromJS(action.data.data))
      .set('fetchError', null)
      .set('fetchingValidateSoftwareData', false)
      .set('downloadStatus', null)
    case constants.VALIDATE_SOFTWARE_LIST_FAILURE:
      return state
      .set('fetchError', action.error)
      .set('fetchingValidateSoftwareData', false)
    case constants.FETCH_SOFTWARE_STATUS_LIST_SUCCESS:
      return state
      .set('statusList', action.data)
      .set('fetching', false)
    case constants.RESET_DEVICE_VALIDATE_SOFTWARE_DATA:
      return state
      .set('validateList', fromJS([]))
      .set('fetching', false)
    case constants.FETCHING_VALIDATE_SOFTWARE_DATA:
      return state
      .set('fetchingValidateSoftwareData', true)
    case constants.DOWNLOAD_SUCCESS:
      let index = action.docValues.indexOf(action.documentId)
      if (index > -1) {
        action.docValues.splice(index, 1)
      }
      return state
      .set('downloadStatus', 'SUCCESS')
      .set('downloadProgress', fromJS(action.docValues))
      .set('fetching', false)
    case constants.DOWNLOAD_FAILURE:
      index = action.docValues.indexOf(action.documentId)
      if (index > -1) {
        action.docValues.splice(index, 1)
      }
      return state
      .set('downloadStatus', 'FAILURE')
      .set('downloadProgress', fromJS(action.docValues))
      .set('fetching', false)
    case constants.UPDATE_DOWNLOAD_STATUS:
      return state
      .set('downloadStatus', null)
      .set('fetching', false)
    case constants.DISABLE_SOFTWARE_PACKAGE_ICON:
      return state
      .set('downloadProgress', fromJS(action.disableDocs))
      .set('fetching', false)
    default:
      return state
  }
}

const getState = (state) => state.deviceValidateSoftware

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  validateSoftwareData: createSelector(getState, (state) => {
    return state.get('validateList')
  }),
  statusList: createSelector(getState, (state) => {
    return state.get('statusList')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  fetchingValidateSoftwareData: createSelector(getState, (state) => {
    return state.get('fetchingValidateSoftwareData')
  }),
  downloadStatus: createSelector(getState, (state) => {
    return state.get('downloadStatus')
  }),
  downloadProgress: createSelector(getState, (state) => {
    return state.get('downloadProgress')
  }),
  softwarePkgAccepReject: createSelector(getState, (state) => {
    return state.get('softwarePkgAccepReject')
  })
}

function formatFilterCategories (filterTypes) {
  const filterOrder = [
    deviceValidateSoftwareFilterTypes.DEVICE_TYPE,
    deviceValidateSoftwareFilterTypes.SOFTWARE_RELEASE_STATUS,
    deviceValidateSoftwareFilterTypes.SOFTWARE_INFORMATION
  ]
  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      const values = filterTypes[filter]
      const categoryType = filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => ({
          name: val,
          label: val,
          key: index,
          value: `${filter}.${val}`
        }))
      filterCategories.push({categoryType, categoryValues, searchBox: false})
    }
  })
  return filterCategories
}
