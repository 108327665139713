import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Pagination.scss'
import { Icon } from '@liquid-design/liquid-design-react'
import { getIcon } from 'utils/iconUtils'
import { FormattedMessage } from 'react-intl'

const cx = classNames.bind(styles)

const propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  setCurrentPage: PropTypes.func,
  fetchPage: PropTypes.func,
  paginationName: PropTypes.string.isRequired,
  resetPage: PropTypes.func
}
class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {page: props.currentPage}
  }
  unsafe_componentwillreceiveprops (nextProps) {
    nextProps && this.setState({page: nextProps.currentPage})
  }
  componentWillUnmount () {
    this.props.resetPage(this.props.paginationName)
  }
  handleChange = async (event) => {
    const { setCurrentPage, fetchPage, currentPage, paginationName } = this.props
    let parsedValue = parseInt(event.target.value)
    this.setState({page: parsedValue || ''})
    if (parsedValue > 0) {
      if (currentPage !== parsedValue) {
        await setCurrentPage(parsedValue, paginationName)
        fetchPage()
      }
    }
  }
  handleBlur = (event) => {
    let pageValue = parseInt(event.target.value)
    const {currentPage} = this.props
    if (!pageValue.length) {
      this.setState({page: currentPage})
    }
  }
  handlePrevClick = async () => {
    const { setCurrentPage, fetchPage, paginationName } = this.props
    this.setState({page: parseInt(this.state.page) - 1})
    await setCurrentPage(parseInt(this.state.page) - 1, paginationName)
    fetchPage()
  }
  handleNextClick = async () => {
    const { setCurrentPage, fetchPage, paginationName } = this.props
    this.setState({page: parseInt(this.state.page) + 1})
    await setCurrentPage(parseInt(this.state.page) + 1, paginationName)
    fetchPage()
  }
  fetchFirstPage = async () => {
    const { setCurrentPage, fetchPage, paginationName } = this.props
    this.setState({page: 1})
    await setCurrentPage(1, paginationName)
    fetchPage()
  }
  fetchLastPage = async () => {
    const { setCurrentPage, fetchPage, totalPages, paginationName } = this.props
    this.setState({page: totalPages})
    await setCurrentPage(totalPages, paginationName)
    fetchPage()
  }
  render () {
    const { totalPages } = this.props
    const showPrev = this.state.page > 1
    const showNext = this.state.page < totalPages
    const colorPrev = showPrev ? 'primary' : 'muted'
    const colorNext = showNext ? 'primary' : 'muted'
    return (
      <div className={cx('pagination')}>
        <div className={cx('pagination-items-container')}>
          <span className={cx('show-first-icon')}>
            <Icon
              size={15}
              icon={getIcon('left-end-arrow')}
              color={colorPrev}
              disabled={!showPrev}
              onClick={showPrev ? () => this.fetchFirstPage() : undefined}
            />
          </span>
        </div>
        <div className={cx('pagination-items')}>
          <span className={cx('show-prev-icon')}>
            <Icon
              size={15}
              icon={getIcon('left-arrow')}
              color={colorPrev}
              disabled={!showPrev}
              onClick={showPrev ? () => this.handlePrevClick() : undefined}
            />
          </span>
          <FormattedMessage id='next.PAGINATION_BOX_PAGE' /> <input type='text' className={cx('page-number')} value={this.state.page}
            onChange={this.handleChange} onBlur={this.handleBlur} /> <FormattedMessage id='next.PAGINATION_BOX_PAGE_OF' /> {totalPages}
          <span className={cx('show-next-icon')}>
            <Icon
              size={15}
              icon={getIcon('right-arrow')}
              color={colorNext}
              disabled={!showNext}
              onClick={showNext ? () => this.handleNextClick() : undefined}
            />
          </span>
        </div>
        <div className={cx('pagination-items-container')}>
          <span className={cx('show-last-icon')}>
            <Icon
              size={15}
              icon={getIcon('right-end-arrow')}
              color={colorNext} disabled={!showNext}
              onClick={showNext ? () => this.fetchLastPage() : undefined}
            />
          </span>
        </div>
      </div>
    )
  }
}

Pagination.propTypes = propTypes

export default Pagination
