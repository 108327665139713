import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeviceCertificateStatus from 'components/DeviceCertificateStatus/DeviceCertificateStatus'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { injectOverlay } from 'shared-components/utils'
import genericListing from 'containers/GenericListingContainer/genericListing'
import StateHandler from 'components/StateHandler/StateHandler'
import {actions as headerActions} from 'store/modules/Header'
import {actions as deviceCertificateStatusActions, selectors as deviceCertificateStatusSelectors} from 'store/modules/DeviceCertificateStatus'
import {selectors as userSelectors} from 'store/modules/User'
import {useIntl} from 'react-intl'

export const moduleId= 'deviceCertificateStatus'

class DeviceCertificateDashboardContainer extends Component {
  static propTypes = {
    intl: useIntl,
    getFilters: PropTypes.func,
    filterCategories: PropTypes.array,
    setBreadcrumb: PropTypes.func,
    breadcrumbName: PropTypes.string,
    close: PropTypes.func,
    openModal: PropTypes.func,
    documentDownloadSuccess: PropTypes.bool,
    documentDownloadError: PropTypes.object,
    resetStatus: PropTypes.func,
    openSnackBar: PropTypes.func,
    createCertificateRenewNotification: PropTypes.func,
    renewalFailure: PropTypes.string,
    renewalSuccess: PropTypes.bool
  }
  componentDidMount () {
    const { setBreadcrumb, breadcrumbName, getFilters } = this.props
    setBreadcrumb(breadcrumbName,location.pathname)
    getFilters()
  }
  componentDidUpdate(prevProps){
    const {documentDownloadSuccess, documentDownloadError, resetStatus, openSnackBar, intl, close,renewalFailure, renewalSuccess} = this.props
    if(documentDownloadError && documentDownloadError?.status !== prevProps.documentDownloadError?.status){
      openSnackBar({ message: intl.formatMessage({ id: `next.DOCUMENT_DOWNLOAD_${documentDownloadError?.status}_ERROR` })})
      resetStatus()
      close()
    }
    if(documentDownloadSuccess && documentDownloadSuccess !== prevProps.documentDownloadSuccess){
      openSnackBar({ message: intl.formatMessage({ id: 'next.DOCUMENT_DOWNLOAD_SUCCESSFUL' })})
      resetStatus()
      close()
    }
    if(renewalSuccess && renewalSuccess !== prevProps.renewalSuccess){
      openSnackBar({ message: intl.formatMessage({ id: 'next.DEVICE_CERTIFICATES_RENEW_NOTIFICATION_SUCCESS' })})
      resetStatus()
    }
    if(renewalFailure && renewalFailure !== prevProps.renewalFailure){
      const {errorCode, status} = renewalFailure
      let message = (errorCode && status === 422)
        ? { message: intl.formatMessage({id: `next.DEVICE_CERTIFICATES_RENEW_NOTIFICATION_${errorCode}_ERROR`})}
        : { message: intl.formatMessage({id: `next.DEVICE_CERTIFICATES_RENEW_NOTIFICATION_ERROR`})}
      openSnackBar(message)
      resetStatus()
    }
  }
  render () {
    const { filterCategories = [] } = this.props
    return (
      <DeviceCertificateStatus
        moduleId={moduleId}
        filterCategories={filterCategories}
        {...this.props} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    fetching: deviceCertificateStatusSelectors.fetching(state),
    fetchError: deviceCertificateStatusSelectors.fetchError(state),
    filterCategories: deviceCertificateStatusSelectors.filterCategories(state).toJS(),
    fetchingDeviceCertificate: deviceCertificateStatusSelectors.fetching(state),
    permission: userSelectors.getUserPrivilege(state)?.toJS(),
    documentDownloadSuccess: deviceCertificateStatusSelectors.documentDownloadSuccess(state),
    documentDownloadError: deviceCertificateStatusSelectors.documentDownloadError(state),
    renewalFailure: deviceCertificateStatusSelectors.renewalFailure(state),
    renewalSuccess: deviceCertificateStatusSelectors.renewalSuccess(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getFilters: deviceCertificateStatusActions.getFilters,
    setBreadcrumb: headerActions.setBreadcrumb,
    downloadCertificateFile: deviceCertificateStatusActions.downloadCertificate,
    createCertificateRenewNotification: deviceCertificateStatusActions.createCertificateRenewNotification,
    downLoadDocumentCertificate: deviceCertificateStatusActions.downLoadDocumentCertificate,
    resetStatus: deviceCertificateStatusActions.resetStatus
  }, dispatch)
}

const Enhancers = [
  (component) => connect(mapStateToProps, mapDispatchToProps)(component),
  (component) => withRouter(component),
  (component) => injectOverlay(component),
  (component) => genericListing(component, { name: moduleId }),
  (component) => StateHandler(component, moduleId)
]

const EnhancedContainer = Enhancers
  .reduce((component, enhancer) => enhancer(component), DeviceCertificateDashboardContainer)

export default EnhancedContainer
