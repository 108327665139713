import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { injectOverlay } from 'shared-components/utils'
import DeviceCertificateViewMore from 'components/DeviceCertificateViewMore/DeviceCertificateViewMore'
import {
  actions as deviceCertificateStatusActions,
  selectors as deviceCertificateStatusSelectors
} from 'store/modules/DeviceCertificateStatus'

class DeviceCertificateViewMoreContainer extends Component {
  static propTypes = {
    fetchDeviceCertificateDetail: PropTypes.func,
    deviceCertificateDetails: PropTypes.object,
    applySort: PropTypes.func
  }
  componentDidMount () {
    const { deviceCertificateDetails } = this.props
    this.props.fetchDeviceCertificateDetail(deviceCertificateDetails.deviceId)
  }
  _handleSort = (sortColumn) => {
    let {
      applySort,
      fetchDeviceCertificateDetail,
      deviceCertificateDetails: {deviceId}
    } = this.props
    applySort(sortColumn)
    fetchDeviceCertificateDetail(deviceId)
  }
  render () {
    return <DeviceCertificateViewMore {...this.props} applySort={this._handleSort} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    fetching: deviceCertificateStatusSelectors.fetching(state),
    fetchError: deviceCertificateStatusSelectors.fetchError(state),
    fetchDetailError: deviceCertificateStatusSelectors.fetchDetailError(state),
    deviceCertificateData: deviceCertificateStatusSelectors.deviceCertificateData(state),
    sortColumn: deviceCertificateStatusSelectors.sortColumn(state),
    sortDirection: deviceCertificateStatusSelectors.sortDirection(state)
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    fetchDeviceCertificateDetail: deviceCertificateStatusActions.fetchDeviceCertificateDetail,
    applySort: deviceCertificateStatusActions.applySort
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectOverlay(DeviceCertificateViewMoreContainer))
