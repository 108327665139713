/* eslint-disable */
export const crudeNameMapping = {
  create: 'Create',
  delete: 'Delete',
  update: 'Update',
  read: 'Read'
}
export const tabTypes = {
  APPLICATION: 'application',
  CONFIGURATION: 'configuration',
  DEVICES: 'devices'
}

const getPermissionData = ({resourceId}) => {
  return ({
    permissions: [
      {module: 'Device Group Access Permissions', resourceId},
      {module: 'Cards Access Permissions', resourceId}
    ]
  })
}

const getResources = (resources, childEntities) => (
  resources.filter((obj) => childEntities.includes(obj.get('resourceName')))
)
const getPrivileges = (privileges, resourceName, resourceId) => (
  privileges
    .filter((obj) => obj.get('operation').includes(`${resourceName}`))
    .toJS().map((obj) => {
      obj.resourceId = resourceId
      return obj
    })
)
const isAccessible = (rolePrivileges, resource) => rolePrivileges.some((obj) => (
  obj.get('resourceId') === resource.resourceId && resource.resourceType && obj.get('resourceType').toLowerCase() === resource.resourceType.toLowerCase()
))
const isChecked = (rolePrivileges, resource) => rolePrivileges.some((obj) => (
  obj.get('privilegeId') === resource.privilegeId && obj.get('resourceId') === resource.resourceId && obj.get('resourceType').toLowerCase() === resource.resourceType.toLowerCase()
))
const isCheckedDeviceType = (rolePrivileges, resource) => resource.map(obj => isChecked(rolePrivileges, obj))

export const getTableData = (args, tableResource, privileges, rolePrivileges, resourceId) => {
  return args.reduce((acc, resource) => {
    let obj = {}
    obj.module = resource.resourceName.charAt(0).toUpperCase() + resource.resourceName.slice(1)
    obj.resourceId = resource.resourceId || resourceId
    obj.resourceType = resource.resourceType
    if (resource.childEntities) {
      obj.childEntities = getResources(tableResource, resource.childEntities)
    } else if (resource.selectPermissions) {
      obj.permissionSelection = getPermissionData(resource)
    } else {
      obj.privileges = getPrivileges(privileges, resource.resourceName, resource.resourceId || resourceId)
    }
    if (obj.privileges && obj.resourceType === 'devicetype') {
      obj.access = isCheckedDeviceType(rolePrivileges, obj.privileges).some(val => val === true)
    } else {
      obj.access = isAccessible(rolePrivileges, {resourceId: obj.resourceId, resourceType: resource.resourceType})
    }
    acc.push(obj)
    return acc
  }, [])
}

export const getTableDataForDeviceGroup = (args, resourceId, locationId = null) => {
  return args.reduce((acc, resource) => {
    let obj = {}
    obj.module = resource.resourceName.charAt(0).toUpperCase() + resource.resourceName.slice(1)
    obj.resourceId = resourceId
    obj.locationId = resource.locationId || locationId
    obj.resourceType = resource.resourceType || 'devicetype'
    if (resource.deviceGroupId) {
      obj.deviceGroupId = resource.deviceGroupId
    }
    obj.childEntities = resource.childEntities && sanitizeDeviceGroups(resource.childEntities)
    obj.access = resource.locationAccess
    acc.push(obj)
    return acc
  }, [])
}

export const getTableDataForDeviceGroupChild = (args, resourceId, locationId = null) => {
  return args.reduce((acc, resource) => {
    let obj = {}
    obj.module = resource.name.charAt(0).toUpperCase() + resource.name.slice(1)
    obj.resourceId = resourceId
    obj.locationId = resource.locationId || locationId
    obj.resourceType = resource.resourceType || 'devicetype'
    if (resource.id) {
      obj.deviceGroupId = resource.id
    }
    obj.childEntities = resource.childEntities && sanitizeDeviceGroups(resource.childEntities)
    obj.access = resource.access
    acc.push(obj)
    return acc
  }, [])
}

export const getPrivilegesData = (args, rolePrivileges) => {
  const res = args.reduce((acc, cur) => {
    let obj = {}
    if (cur.operation.split('_').length > 1){
      obj.privilege = cur.operation.split('_')[0]
      obj.checked = isChecked(rolePrivileges, cur)
      acc.push({ ...cur, ...obj })
      return acc
    }
  }, [])
  if (res) {
    const updatedRes = getDisabledRead(res)
    return [{module: updatedRes}]
  }
  return  null
}

const getDisabledRead = (privilegs) => {
  const updatedRow = privilegs.filter(crud => crud.checked).length > 1
     ? privilegs.map(crud => {
       if(crud.privilege === 'read'){
         crud.disable = true
       }
       return crud
      })
      : privilegs
     return  updatedRow
}

export const sanitizeDeviceTypes = (deviceTypes) => {
  return deviceTypes.map((obj) => ({
    resourceName: obj.deviceType,
    resourceType: 'deviceType',
    resourceId: obj.deviceTypeId,
    reourceIdFromFamily: obj?.deviceFamily?.id || '',
    selectPermissions: true
  }))
}

export const sanitizedeviceGroupPrivileges = (deviceGroupPrivilegesLocation) => deviceGroupPrivilegesLocation.map((loc) => {
  return {
    resourceName: loc.name,
    locationId: loc.id,
    childEntities: loc.deviceGroups,
    resourceType: 'deviceType',
    locationAccess: loc.access === undefined || loc.access
  }
})
export const sanitizeDeviceGroups = (deviceGroups) => deviceGroups.map((deviceGroup) => ({
  resourceName: deviceGroup.name,
  deviceGroupId: deviceGroup.id,
  locationAccess: deviceGroup.access === undefined || deviceGroup.access
}))
export const sanitizeDeviceGroupsForModuleClick = (deviceGroups) => deviceGroups.map((deviceGroup) => ({
  resourceName: deviceGroup.resourceName,
  deviceGroupId: deviceGroup.deviceGroupId,
  locationAccess: deviceGroup.locationAccess
}))

export const sanitizeEntities = resource => resource.map((obj) => ({
  resourceName: obj.entityName,
  resourceId: obj.entityId,
  resourceType: 'entity'
}))

export const sanitizeApplicationvalues = (appValue, resources) => appValue.map((cur) => {
  resources.forEach((obj) => {
    if (obj.get('resourceName') === cur.resourceName) cur.resourceId = obj.get('resourceId')
  })
  return cur
})

const isChildEntitiesAccessible = (childResources, rolePrivileges) =>
  childResources
    .map(resourceObj => isAccessible(rolePrivileges, resourceObj))
    .some((val) => val === true)

export const hasCheckedPrivileges = (resourceList, rolePrivileges) => resourceList.map((resource) => {
  resource.access = resource.childEntities
    ? isChildEntitiesAccessible(resource.childEntities, rolePrivileges)
    : isAccessible(rolePrivileges, resource)
  return resource
})

export const updateDeviceGroupPrivilege = (locations, access, locationId, deviceGroupId) => {
  return locations.toJS().filter(location => {
    if (location.locationId === locationId) {
      if (location.childEntities !== undefined) {
        location.access = !access
        location.childEntities.forEach(loc => {
          loc.access = !access
          loc.locationAccess = !access
        })
      } else if (deviceGroupId && deviceGroupId === location.deviceGroupId) {
        location.access = !access
      }
    }
    return location
  })
}

export const updateDeviceGroupPrivilegeForLocations = (locations, access, locationId, deviceGroupId) => {
  return locations.toJS().filter(location => {
    if (location.id === locationId) {
      if (!deviceGroupId) {
        location.access = !access
        location.deviceGroups.forEach(function(loc) { loc.access = !access })
      } else if (deviceGroupId && location.deviceGroups.filter(dev => dev.id === deviceGroupId)) {
        location.locationAccess = !access
        location.deviceGroups.forEach(dev => {
          if (dev.id === deviceGroupId) {
            dev.access = !access
          }
        })
      }
    }
    return location
  })
}

export const makeDefaultReadPrivilegeargs = (checkedPrivilege, allPrivilges, getRolePrivileges) => {
  const { operation, resourceId } = checkedPrivilege
  const moduleType = operation.split("_")[2] ? 'read_' +operation.split("_")[1]+'_'+operation.split("_")[2] : 'read_' +operation.split("_")[1]
  const defaultRead = allPrivilges.toJS().find(obj =>  obj.operation === moduleType)
  const {privilegeId} = defaultRead
  const isInRolePrivilege = getRolePrivileges.toJS().find(obj =>  (obj.privilegeId === privilegeId && obj.resourceId === resourceId))
  if (isInRolePrivilege !== undefined) {
    return [{privilegeid: checkedPrivilege.privilegeId, resourceid: checkedPrivilege.resourceId}]
  } else {
    return [{privilegeid: checkedPrivilege.privilegeId, resourceid: checkedPrivilege.resourceId},{privilegeid: privilegeId, resourceid: checkedPrivilege.resourceId}]
  }
}

export const checkDeviceGroupAccessAll = (rolePrivileges, resourceId) => {
  return rolePrivileges.some((privileges, index) => privileges.operation === 'accessAll' && privileges.resourceId ===resourceId)
}
