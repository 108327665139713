import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Carousel, Select, Col } from 'antd'
import { AntSelect } from 'antDComponents'
import style from './NewsFeedCarousel.scss'
import 'antd/dist/antd.css'
import classNames from 'classnames/bind'
import { injectIntl, FormattedMessage } from 'react-intl'
import Loader from 'components/Loader/Loader'
const { Option } = Select
const cx = classNames.bind(style)

class NewsFeedCarousel extends Component {
  static propTypes = {
    newsFeedCategory: PropTypes.array,
    newsFeedObj: PropTypes.object,
    categoryId: PropTypes.number,
    intl: PropTypes.object
  }
  state = {
    selecteTemplate: [],
    sellectedCat: '',
    loading: true,
    counter: 0
  }
  componentDidMount() {
    const { categoryId } = this.props
    this.filterDefaultTemplate(this.props)
    this.setState({ sellectedCat: categoryId })
  }
  componentDidUpdate(prevProps) {
    const { sellectedCat } = this.state
    if (sellectedCat === '' || sellectedCat === undefined) {
      this.filterDefaultTemplate(this.props)
    }
  }
  filterDefaultTemplate = (props) => {
    const { newsFeedCategory } = props
    if (newsFeedCategory) {
      const selecteTemplate = newsFeedCategory.filter((template) => template.categoryId === newsFeedCategory[0]['categoryId'])
      this.setState({ selecteTemplate })
    }
  }
  handleChange = (value) => {
    this.setState({ loading: true, counter: 0 })
    const { newsFeedCategory } = this.props
    const {
      newsFeedObj: { fetchNewsImages }
    } = this.props
    fetchNewsImages(value)
    this.setState({ sellectedCat: value })
    if (newsFeedCategory) {
      const selecteTemplate = newsFeedCategory.filter((template) => template.categoryId === value)
      this.setState({ selecteTemplate, sellectedCat: value })
    }
  }
  imageLoaded = urls => {
    this.setState({ counter: this.state.counter + 1 }, () => {
      const { counter } = this.state
      if (counter >= urls.length) {
        this.setState({ loading: false })
      }
    })
  }
  handleOpenNewsFeed = (url) => {
    url = (url.indexOf('://') === -1) ? 'https://' + url : url
    window.open(url)
  }
  renderCaousels = function () {
    const {
      newsFeedObj: { newsImages }
    } = this.props
    let images = newsImages && newsImages.toJS()
    let tempCatesIds = Object.keys(images) || []
    let listtemplated = tempCatesIds && tempCatesIds.length > 0 && tempCatesIds.map((template) => {
      const { loading } = this.state
      return (
        <div style={{ display: loading ? 'none' : 'block' }} key={template.templateNo} >
          <a onClick={() => this.handleOpenNewsFeed(images[template].url)}>
            <img className='read_news_feed' style={{ width: '100%', height: '19rem' }} src={`data:image/jpeg;base64,${images[template].blobData}`} alt={images[template].templateName} onLoad={() => this.imageLoaded(tempCatesIds)} />
          </a>
        </div>
      )
    })
    return listtemplated || []
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      dotPosition: 'bottom',
      pauseOnHover: true,
      cssEase: 'linear',
      fade: true
    }
    const { newsFeedCategory, newsFeedObj: { isFetchingImg, newsImages }, intl } = this.props
    let images = newsImages && newsImages.toJS()
    const options =
      (newsFeedCategory &&
        newsFeedCategory.length > 0 &&
        newsFeedCategory.map((value, idx) => (
          <Option key={idx} value={value.categoryId}>
            {value.categoryName}
          </Option>
        ))) || []
    const defaultCategory = newsFeedCategory && newsFeedCategory.length && newsFeedCategory[0]['categoryId']
    const { sellectedCat, selecteTemplate, loading } = this.state
    const isCarouselDis = sellectedCat && selecteTemplate.length > 0
    return (
      <Col className={cx('ant-carousel-div')} sm={6} md={8} lg={12}>
        {isFetchingImg && <Loader />}
        <div className={cx('display-select')}>
          <AntSelect
            label={intl.formatMessage({ id: 'next.MANAGE_NEWS_FEED_NOW_DISPLAYING' })}
            disabled={isFetchingImg}
            value={sellectedCat || defaultCategory}
            onChange={this.handleChange}
            options={options}
            isForm={false}
            isAync
          />
        </div>
        {Object.keys(images).length !== 0 && !images?.errorCode &&
          <div style={{ display: loading ? 'block' : 'none', height: '150px' }}>
            <FormattedMessage id='next.NEWS_FEED_LOADING_BANNER' />
          </div>
        }
        {isCarouselDis && !isFetchingImg && !images?.errorCode && Object.keys(images).length !== 0 ? (
          <Carousel
            {...settings}
          >
            {this.renderCaousels(selecteTemplate)}
          </Carousel>
        ) :
          !isFetchingImg &&
            <div className='no__news'>
              <p className='no__news__found'><FormattedMessage id='next.NEWS_FEED_CATEGORY_EMPTY_DATA' /></p>
            </div>
        }
      </Col>
    )
  }
}

export default injectIntl(NewsFeedCarousel)
