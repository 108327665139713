import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { sizes, getTypeValues } from '../types'

import FormGroup from './FormGroup'

const propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(getTypeValues(sizes)),
  id: PropTypes.string,
  value: PropTypes.string,
  isFormGroup: PropTypes.bool,
  formGroupStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.node
  })
}

const defaultProps = {
  type: 'text',
  isFormGroup: true
}

const Input = (props) => {
  const {
    type,
    placeholder,
    size,
    id,
    value,
    isFormGroup,
    formGroupStyle, // eslint-disable-line no-unused-vars
    inputStyle,
    className,
    input: inputProps,
    meta: { touched, error },
    ...otherProps
  } = props

  const showError = touched && error

  // Component Classes
  const componentClass = cx(
    'form-control',
    'input',
    {
      [`form-control-${size}`]: size,
      [`input-${size}`]: size,
      'error': showError
    },
    className
  )

  // Base Component
  const input = (
    <div>
      <input
        className={componentClass}
        id={id}
        value={value || ''}
        style={inputStyle}
        type={type}
        placeholder={placeholder}
        {...inputProps}
        {...otherProps}
      />
      {showError &&
        <div className={cx('form-error-message')}>{error}</div>
      }
    </div>
  )

  // If Form Group Return Base Component Wrapped in Form Group Element
  if (isFormGroup) {
    return (
      <FormGroup {...props} >
        {input}
      </FormGroup>
    )
  }

  // Return Base Component
  return input
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
