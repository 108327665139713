import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { actions as genericActions } from 'store/modules/Generic'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { injectIntl } from 'react-intl'

export default (Container, moduleName) => {
  class ContainerWrapper extends Component {
    static propTypes = {
      resetState: PropTypes.func
    }
    componentWillUnmount () {
      this.props.resetState(moduleName)
    }
    render () {
      return <Container {...this.props} />
    }
  }

  const mapStateToProps = () => ({
  })

  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({
      resetState: genericActions.resetState
    }, dispatch)
  }

  return injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContainerWrapper))
}
