import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  actions as messageActions,
  selectors as messageSelectors
} from 'store/modules/Translation'
import {
  selectors as userSelectors
} from 'store/modules/User'
import { IntlProvider } from 'react-intl'
import { defaultLanguage } from 'constants/profile'
import { isEmptyObject } from 'utils/dataUtils'

class TranslatedContainer extends PureComponent {
  componentDidMount() {
    const { userLanguage, loadMessages } = this.props
    loadMessages(userLanguage)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { error, userLanguage: newUserLanguage, messages, messageFetching } = this.props
    const { loadMessages, userLanguage: prevUserLanguage } = prevProps
    // When the page loads/refreshes if there is no translation
    // available, action to be triggered with default language.
    if (typeof error === 'string' && (isEmptyObject(messages))) {
      loadMessages(defaultLanguage)
    }
    if (error !== null) {
      loadMessages(defaultLanguage)
    } else if (newUserLanguage) {
      if (newUserLanguage !== prevUserLanguage && !messageFetching) {
        loadMessages(newUserLanguage)
      }
    }
  }

  render() {
    const { messages, children, locale } = this.props
    return !locale && isEmptyObject(messages)
      ? <div>Loading...</div>
      : (
        <IntlProvider key={locale} locale={locale} messages={messages}>
          {children}
        </IntlProvider>
      )
  }
}

TranslatedContainer.propTypes = {
  locale: PropTypes.string,
  userLanguage: PropTypes.string,
  messages: PropTypes.object,
  loadMessages: PropTypes.func,
  children: PropTypes.node,
  messageFetching: PropTypes.bool,
  error: PropTypes.object
}

TranslatedContainer.defaultProps = {
  userLanguage: defaultLanguage
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: messageSelectors.locale(state),
    messages: messageSelectors.messages(state).toJS(),
    error: messageSelectors.error(state),
    userLanguage: userSelectors.getLanguageCode(state),
    messageFetching: messageSelectors.messageFetching(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    loadMessages: messageActions.fetch
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatedContainer)
