import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { filterTypes as deviceSoftwareUploadFilterTypes } from 'constants/deviceSoftwareUploadConstant'

// Constants
export const moduleId = 'deviceSoftwareUpload'
export const constants = {
  FETCH: 'DEVICE_SOFTWARE_UPLOAD/FETCH',
  FETCH_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_FAILURE',
  FETCH_DELETE_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_DELETE_SUCCESS',
  RECEIVE_FILTER_TYPES_UPLOAD: 'DEVICE_SOFTWARE_UPLOAD/RECEIVE_FILTER_TYPES_UPLOAD',
  FETCH_DELETE_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_DELETE_FAILURE',
  RESET_STATUS: 'DEVICE_SOFTWARE_UPLOAD/RESET_STATUS',
  FETCH_DEVICE_TYPES: 'DEVICE_SOFTWARE_UPLOAD/FETCH_DEVICE_TYPES',
  FETCH_DEVICE_TYPES_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_DEVICE_TYPES_SUCCESS',
  FETCH_DEVICE_TYPES_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_DEVICE_TYPES_FAILURE',
  FETCH_SOFTWARE_TYPES_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_SOFTWARE_TYPES_SUCCESS',
  FETCH_SOFTWARE_TYPES_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_SOFTWARE_TYPES_FAILURE',
  FETCH_SOFTWARE_RELEASE_TYPES: 'DEVICE_SOFTWARE_UPLOAD/FETCH_SOFTWARE_RELEASE_TYPES',
  FETCH_SOFTWARE_RELEASE_STATUS_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_SOFTWARE_RELEASE_STATUS_SUCCESS',
  FETCH_SOFTWARE_RELEASE_STATUS_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_SOFTWARE_RELEASE_STATUS_FAILURE',
  FETCH_VALID_FILE_TYPES: 'DEVICE_SOFTWARE_UPLOAD/FETCH_VALID_FILE_TYPES',
  FETCH_VALID_FILE_TYPE_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_VALID_FILE_TYPE_SUCCESS',
  FETCH_VALID_FILE_TYPE_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_VALID_FILE_TYPE_FAILURE',
  FETCH_RELEASE_NOTE_TYPES: 'DEVICE_SOFTWARE_UPLOAD/FETCH_RELEASE_NOTE_TYPES',
  FETCH_VALID_RELEASE_NOTE_TYPE_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_VALID_RELEASE_NOTE_TYPE_SUCCESS',
  FETCH_VALID_RELEASE_NOTE_TYPE_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_VALID_RELEASE_NOTE_TYPE_FAILURE',
  LAST_SOFTWARE_REALEASE_DATE: 'DEVICE_SOFTWARE_UPLOAD/LAST_SOFTWARE_REALEASE_DATE',
  LAST_SOFTWARE_REALEASE_DATE_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/LAST_SOFTWARE_REALEASE_DATE_SUCCESS',
  LAST_SOFTWARE_REALEASE_DATE_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/LAST_SOFTWARE_REALEASE_DATE_FAILURE',
  FETCH_ADD: 'DEVICE_SOFTWARE_UPLOAD/FETCH_ADD',
  FETCH_ADD_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_ADD_SUCCESS',
  FETCH_ADD_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_ADD_FAILURE',
  FETCH_UPDATE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_UPDATE',
  FETCH_UPDATE_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/FETCH_UPDATE_SUCCESS',
  FETCH_UPDATE_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_UPDATE_FAILURE',
  FETCH_MODAL: 'DEVICE_SOFTWARE_UPLOAD/FETCH_MODAL',
  ADD_ITEM_SUCCESS: 'DEVICE_SOFTWARE_UPLOAD/ADD_ITEM_SUCCESS',
  ADD_ITEM_FAILURE: 'DEVICE_SOFTWARE_UPLOAD/ADD_ITEM_FAILURE',
  SET_UPLOADING_STATE: 'DEVICE_SOFTWARE_UPLOAD/SET_UPLOADING_STATE',
  FETCH_DELETE: 'DEVICE_SOFTWARE_UPLOAD/FETCH_DELETE',
  FETCH_SOFTWARE_TYPES: 'DEVICE_SOFTWARE_UPLOAD/FETCH_SOFTWARE_TYPES',
  SUCCESS_AKNOWLEDGMENT_FAILURE_EVENT: 'DEVICE_SOFTWARE_UPLOAD/SUCCESS_AKNOWLEDGMENT_FAILURE_EVENT',
  RESET_DEVICE_SOFTWARE_UPDATE: 'DEVICE_SOFTWARE_UPDATE/RESET_DEVICE_SOFTWARE_UPDATE',
  FAIL_AKNOWLEDGMENT_FAILURE_EVENT: 'DEVICE_SOFTWARE_UPLOAD/FAIL_AKNOWLEDGMENT_FAILURE_EVENT'
}

// Action Creators
export const actions = {
  fetchDeviceSoftwareUploadFilter() {
    return safeFetch({
      apiFunction: 'fetchDeviceSoftwareUploadFilter',
      onSuccess: (data) => ({ type: constants.RECEIVE_FILTER_TYPES_UPLOAD, data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error })
    })
  },
  acknowledgementFailureEvent(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'acknowledgementFailureEvent',
      args: deviceId,
      onSuccess: (data) => ({ type: constants.SUCCESS_AKNOWLEDGMENT_FAILURE_EVENT, data }),
      onFailure: (error) => ({ type: constants.FAIL_AKNOWLEDGMENT_FAILURE_EVENT, error })
    })
  },
  deleteRecallDeviceSoftware({ deviceTypeSoftwareReleaseId, deviceTypeId, comments }) {
    let deleteArgs = ''
    if (comments) {
      deleteArgs = '?deviceTypeSoftwareReleaseId=' + deviceTypeSoftwareReleaseId + '&deviceTypeId=' + deviceTypeId + '&comments=' + comments
    } else {
      deleteArgs = '?deviceTypeSoftwareReleaseId=' + deviceTypeSoftwareReleaseId + '&deviceTypeId=' + deviceTypeId
    }
    return safeFetch({
      args: deleteArgs,
      throwError: true,
      apiFunction: 'deleteDeviceSoftware',
      onFetch: () => ({ type: constants.FETCH_DELETE }),
      onSuccess: () => ({ type: constants.FETCH_DELETE_SUCCESS }),
      onFailure: (error) => ({ type: constants.FETCH_DELETE_FAILURE, error })
    })
  },
  fetchDeviceTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_DEVICE_TYPES }),
      apiFunction: 'getDeviceTypes',
      onSuccess: ({ data }) => ({ type: constants.FETCH_DEVICE_TYPES_SUCCESS, deviceTypes: data }),
      onFailure: (error) => ({ type: constants.FETCH_DEVICE_TYPES_FAILURE, error: error })
    })
  },
  fetchSoftwareTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_SOFTWARE_TYPES }),
      apiFunction: 'fetchSoftwareTypes',
      onSuccess: ({ data }) => ({ type: constants.FETCH_SOFTWARE_TYPES_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_SOFTWARE_TYPES_FAILURE, error })
    })
  },
  fetchDeviceSoftwareReleaseStatus() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_SOFTWARE_RELEASE_TYPES }),
      apiFunction: 'fetchDeviceSoftwareReleaseStatus',
      onSuccess: ({ data }) => ({ type: constants.FETCH_SOFTWARE_RELEASE_STATUS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_SOFTWARE_RELEASE_STATUS_FAILURE, error })
    })
  },
  getValidFileTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_VALID_FILE_TYPES }),
      apiFunction: 'geValidFileTypesAddDeviceSoftware',
      onSuccess: ({ data }) => ({ type: constants.FETCH_VALID_FILE_TYPE_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.FETCH_VALID_FILE_TYPE_FAILURE, status })
    })
  },
  getValidReleaseNoteTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_RELEASE_NOTE_TYPES }),
      apiFunction: 'getValidReleasenoteType',
      onSuccess: ({ data }) => ({ type: constants.FETCH_VALID_RELEASE_NOTE_TYPE_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.FETCH_VALID_RELEASE_NOTE_TYPE_FAILURE, status })
    })
  },
  lastSoftwareReleaseDate() {
    return safeFetch({
      onFetch: () => ({ type: constants.LAST_SOFTWARE_REALEASE_DATE }),
      apiFunction: 'lastSoftwareReleaseDate',
      onSuccess: ({ data }) => ({ type: constants.LAST_SOFTWARE_REALEASE_DATE_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.LAST_SOFTWARE_REALEASE_DATE_FAILURE, status })
    })
  },
  uploadSoftware(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'addDeviceSoftware',
      onFetch: () => ({ type: constants.FETCH_ADD }),
      onSuccess: () => ({ type: constants.FETCH_ADD_SUCCESS }),
      onFailure: (error) => ({ type: constants.FETCH_ADD_FAILURE, error })
    })
  },
  editDeviceSoftware(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'editDeviceSoftware',
      onFetch: () => ({ type: constants.FETCH_UPDATE }),
      onSuccess: () => ({ type: constants.FETCH_UPDATE_SUCCESS }),
      onFailure: () => ({ type: constants.FETCH_UPDATE_FAILURE })
    })
  },
  resetStatus() {
    return { type: constants.RESET_STATUS }
  },
  uploadFile(args, listingName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_MODAL, listingName }),
      apiFunction: 'uploadFile',
      args: args,
      onSuccess: ({ data }, getState) => {
        const totalEle = selectors.totalElements(getState(), listingName) + 1
        return { type: constants.ADD_ITEM_SUCCESS, item: data, totalEle, listingName }
      },
      onFailure: (error) => ({ type: constants.ADD_ITEM_FAILURE, error: error.message, listingName }),
      throwError: true
    })
  },
  setUploadingState(uploadStatus) {
    return { type: constants.SET_UPLOADING_STATE, uploadStatus }
  },
  validateDeviceSofwareBeforeAdding({ query }) {
    const { deviceTypeId, softwareVersion, documentTypeId, releaseStatusId } = query
    return safeFetch({
      args: { deviceTypeId, softwareVersion, documentTypeId, releaseStatusId },
      throwError: true,
      apiFunction: 'validateDeviceSoftwareBeforeAdding',
      onFetch: () => ({ type: constants.FETCH_ADD }),
      onSuccess: () => ({ type: constants.FETCH_ADD }),
      onFailure: () => ({ type: constants.FETCH_ADD_FAILURE })
    })
  },
  resetStatusUpdate() {
    return ({ type: constants.RESET_DEVICE_SOFTWARE_UPDATE })
  },
  addDeviceSoftware(data) {
    return async (dispatch) => {
      await dispatch(actions.validateDeviceSofwareBeforeAdding(data))
      await dispatch(actions.uploadSoftware(data))
    }
  },
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  filterCategories: [],
  succesDeletesStatus: null,
  uploadFileSuccess: null,
  fetchError: null,
  documentTypes: [],
  releaseStatus: null,
  validFileTypes: [],
  validReleaseNoteTypes: [],
  successStatus: null,
  uploading: false,
  deleteErrorMsg: null,
  isSuccessEventFailure: null,
  acknowledgementFailureEventError: null,
  successEventFailure: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
    case constants.RECEIVE_FILTER_TYPES_UPLOAD:
      return state
        .set('fetching', false)
        .set('filterCategories', formatFilterCategories(action.data.data))
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
    case constants.FETCH_DEVICE_TYPES:
      return state
        .set('fetching', true)
    case constants.FETCH_SOFTWARE_TYPES:
      return state
        .set('fetching', true)
    case constants.FETCH_SOFTWARE_RELEASE_TYPES:
      return state
        .set('fetching', true)
    case constants.FETCH_VALID_FILE_TYPES:
      return state
        .set('fetching', true)
    case constants.FETCH_SOFTWARE_TYPES_SUCCESS:
      return state
        .set('documentTypes', fromJS(action.data))
        .set('fetching', false)
        .set('uploadFileSuccess', null)
    case constants.FETCH_DEVICE_TYPES_SUCCESS:
      return state
        .set('deviceTypes', fromJS(action.deviceTypes))
        .set('fetching', false)
    case constants.FETCH_DEVICE_TYPES_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.FETCH_SOFTWARE_TYPES_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.FETCH_DELETE:
      return state
        .set('fetching', true)
    case constants.FETCH_DELETE_SUCCESS:
      return state
        .set('fetching', false)
        .set('succesDeletesStatus', true)
    case constants.FETCH_DELETE_FAILURE:
      return state
        .set('fetching', false)
        .set('succesDeletesStatus', false)
        .set('deleteErrorMsg', action.error)
    case constants.RESET_STATUS:
      return state
        .set('succesDeletesStatus', null)
        .set('successStatus', null)
        .set('deleteErrorMsg', null)
    case constants.FETCH_SOFTWARE_RELEASE_STATUS_SUCCESS:
      return state
        .set('releaseStatus', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_SOFTWARE_RELEASE_STATUS_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.FETCH_VALID_FILE_TYPE_SUCCESS:
      return state
        .set('validFileTypes', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_VALID_FILE_TYPE_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.FETCH_RELEASE_NOTE_TYPES:
      return state
        .set('fetching', true)
    case constants.FETCH_VALID_RELEASE_NOTE_TYPE_SUCCESS:
      return state
        .set('validReleaseNoteTypes', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_VALID_RELEASE_NOTE_TYPE_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.LAST_SOFTWARE_REALEASE_DATE:
      return state
        .set('fetching', true)
    case constants.LAST_SOFTWARE_REALEASE_DATE_SUCCESS:
      return state
        .set('fetching', false)
        .set('lastSoftReleaseDate', fromJS(action.data))
    case constants.LAST_SOFTWARE_REALEASE_DATE_FAILURE:
      return state.set('fetching', false)
    case constants.FETCH_ADD:
      return state
        .set('fetching', true)
    case constants.FETCH_ADD_SUCCESS:
      return state
        .set('fetching', false)
        .set('successStatus', 'CREATE')
    case constants.FETCH_ADD_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.FETCH_UPDATE:
      return state
        .set('fetching', true)
    case constants.FETCH_UPDATE_SUCCESS:
      return state
        .set('fetching', false)
        .set('successStatus', 'UPDATE')
    case constants.FETCH_UPDATE_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
    case constants.FETCH_MODAL:
      return state
        .setIn([action.listingName, 'fetchingInModal'], true)
        .setIn([action.listingName, 'fetchError'], null)
    case constants.ADD_ITEM_SUCCESS:
      return state
        .setIn([action.listingName, 'uploadFileSuccess'], fromJS(action.status))
    case constants.ADD_ITEM_FAILURE:
      return state
        .setIn([action.listingName, 'fetching'], false)
        .setIn([action.listingName, 'fetchError'], action.error)
    case constants.SET_UPLOADING_STATE:
      return state
        .set('uploading', action.uploadStatus)
    case constants.SUCCESS_AKNOWLEDGMENT_FAILURE_EVENT:
      return state
        .set('isSuccessEventFailure', true)
    case constants.RESET_DEVICE_SOFTWARE_UPDATE:
      return state
        .set('acknowledgementFailureEventError', null)
        .set('isSuccessEventFailure', null)
    case constants.FAIL_AKNOWLEDGMENT_FAILURE_EVENT:
      return state
        .set('acknowledgementFailureEventError', action.error)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceSoftwareUpload

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  getDeleteStatus: createSelector(getState, (state) => {
    return state.get('succesDeletesStatus')
  }),
  deviceTypes: createSelector(getState, (state) =>
    state.get('deviceTypes')
  ),
  documentTypes: createSelector(getState, (state) => {
    return state.get('documentTypes')
  }),
  releaseStatus: createSelector(getState, (state) =>
    state.get('releaseStatus')
  ),
  getValidFileTypes: createSelector(getState, (state) => {
    return state.get('validFileTypes')
  }),
  getvalidReleaseNoteTypes: createSelector(getState, (state) => {
    return state.get('validReleaseNoteTypes')
  }),
  lastSoftReleaseDate: createSelector(getState, (state) => {
    return state.get('lastSoftReleaseDate')
  }),
  getSuccessStatus: createSelector(getState, (state) => {
    return state.get('successStatus')
  }),
  uploading: createSelector(getState, (state) =>
    state.get('uploading')
  ),
  uploadFileSuccess: createSelector(getState, (state) =>
    state.get('uploadFileSuccess')
  ),
  fetchFilterError: createSelector(getState, (state) =>
    state.get('fetchFilterError')
  ),
  deleteErrorMsg: createSelector(getState, (state) =>
    state.get('deleteErrorMsg')
  ),
  isSuccessEventFailure: createSelector(getState, (state) =>
    state.get('isSuccessEventFailure')
  ),
  acknowledgementFailureEventError: createSelector(getState, (state) =>
    state.get('acknowledgementFailureEventError')
  )
}
// helper functions
function formatFilterCategories(filterTypes) {
  const filterOrder = [
    deviceSoftwareUploadFilterTypes.DEVICE_TYPE,
    deviceSoftwareUploadFilterTypes.SOFTWARE_RELEASE_STATUS,
    deviceSoftwareUploadFilterTypes.SOFTWARE_RELEASE_INFORMATION
  ]
  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      const values = filterTypes[filter]
      const categoryType = filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => {
          if (typeof val['deviceId'] !== 'undefined') {
            return {
              label: val,
              value: `${filter}.${val}`,
              key: index,
              name: val
            }
          } else {
            return {
              label: val,
              value: `${filter}.${val}`,
              key: index,
              name: val
            }
          }
        })
      filterCategories.push({ categoryType, categoryValues, searchBox: false })
    }
  })
  return filterCategories
}
