import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
const UserProfileSettingSvg = (props) => {
  const { className } = props
  return (
    <svg
      className={className}
      width={`${props.width}`}
      height={`${props.height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 25.41'
    >
      <defs></defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            style={{fill:'#afafaf', ...props.style}}
            d='M13.81,19.06l-.74-.12a4.55,4.55,0,0,0-.3-.72l.44-.61a.65.65,0,0,0-.07-.83l-.66-.66a.64.64,0,0,0-.46-.19.63.63,0,0,0-.37.12l-.61.44a4.3,4.3,0,0,0-.74-.31l-.13-.73a.65.65,0,0,0-.64-.54H8.61a.65.65,0,0,0-.64.54l-.13.75a4.87,4.87,0,0,0-.71.3l-.6-.43A.66.66,0,0,0,6.15,16a.62.62,0,0,0-.46.19L5,16.79a.66.66,0,0,0-.07.84l.44.61a4.51,4.51,0,0,0-.29.72l-.73.12a.66.66,0,0,0-.54.64v.93a.66.66,0,0,0,.54.64l.75.12a4.55,4.55,0,0,0,.3.72l-.43.6a.63.63,0,0,0,.07.83l.65.66a.66.66,0,0,0,.46.19.59.59,0,0,0,.37-.12l.62-.44a3.7,3.7,0,0,0,.69.28l.13.74a.64.64,0,0,0,.64.54h.92a.64.64,0,0,0,.64-.54l.13-.74a3.41,3.41,0,0,0,.71-.29l.61.43a.64.64,0,0,0,.38.12h0a.62.62,0,0,0,.46-.19l.65-.65a.64.64,0,0,0,.07-.84l-.43-.61a3.92,3.92,0,0,0,.29-.71l.74-.12a.66.66,0,0,0,.54-.65V19.7A.63.63,0,0,0,13.81,19.06Zm-.06,1.56s0,.06,0,.06l-.93.16a.29.29,0,0,0-.23.21,3.72,3.72,0,0,1-.38.92.29.29,0,0,0,0,.32l.54.77a0,0,0,0,1,0,.07l-.65.66H12l-.76-.54a.3.3,0,0,0-.33,0,3.25,3.25,0,0,1-.92.38.28.28,0,0,0-.21.24l-.16.92a.06.06,0,0,1-.06,0H8.63a.06.06,0,0,1-.06,0l-.15-.92a.3.3,0,0,0-.22-.24,3.88,3.88,0,0,1-.9-.37.26.26,0,0,0-.15,0,.27.27,0,0,0-.17.06l-.77.54H6.14l-.65-.66a0,0,0,0,1,0-.07L6,22.32A.29.29,0,0,0,6,22a3.51,3.51,0,0,1-.38-.92.32.32,0,0,0-.24-.22l-.93-.16a0,0,0,0,1-.05-.05v-.93a0,0,0,0,1,.05,0l.92-.16a.3.3,0,0,0,.24-.22A3.48,3.48,0,0,1,6,18.37a.31.31,0,0,0,0-.32l-.55-.77a0,0,0,0,1,0-.07l.65-.65a0,0,0,0,1,0,0s0,0,0,0l.76.54a.3.3,0,0,0,.33,0,3.31,3.31,0,0,1,.91-.38.33.33,0,0,0,.22-.24l.16-.93a.05.05,0,0,1,0-.05h.93a.06.06,0,0,1,.06.05l.15.92a.28.28,0,0,0,.22.23,3.89,3.89,0,0,1,.94.39.29.29,0,0,0,.32,0l.76-.55h0a0,0,0,0,1,0,0l.66.65a.06.06,0,0,1,0,.08l-.54.76a.29.29,0,0,0,0,.32,3.51,3.51,0,0,1,.38.92.29.29,0,0,0,.24.22l.92.15a.06.06,0,0,1,0,.06v.92Z'
          />
          <path
            style={{fill:'#afafaf', ...props.style}}
            d='M9.09,17.9a2.27,2.27,0,1,0,2.27,2.26A2.26,2.26,0,0,0,9.09,17.9Zm0,3.94a1.68,1.68,0,1,1,1.68-1.68A1.68,1.68,0,0,1,9.09,21.84Z'
          />
          <path
            style={{fill:'#afafaf', ...props.style}}
            d='M8.92,11.39h.14a4.07,4.07,0,0,0,3.09-1.34c1.69-1.9,1.41-5.15,1.38-5.46a4.44,4.44,0,0,0-2.13-4A4.89,4.89,0,0,0,9.05,0H9A4.93,4.93,0,0,0,6.61.6a4.47,4.47,0,0,0-2.15,4c0,.31-.31,3.56,1.38,5.46A4.06,4.06,0,0,0,8.92,11.39ZM5.63,4.7s0,0,0,0C5.78,1.52,8,1.19,9,1.19H9c1.18,0,3.19.5,3.33,3.47v0s.32,3-1.08,4.57A2.84,2.84,0,0,1,9,10.2H9a2.84,2.84,0,0,1-2.25-.93C5.33,7.71,5.63,4.72,5.63,4.7Z'
          />
          <path
            style={{fill:'#afafaf', ...props.style}}
            d='M17.79,18.76a7.13,7.13,0,0,0,.21-2v0s0-.07,0-.11c0-.86-.08-2.89-2-3.54h0a12.72,12.72,0,0,1-3.64-1.65.59.59,0,1,0-.68,1,13.34,13.34,0,0,0,4,1.83c1,.36,1.13,1.45,1.17,2.45,0,0-.18,2-.18,2.05'
          />
          <path
            style={{fill:'#afafaf', ...props.style}}
            d='M1.45,18.75s-.27-2-.27-2.06c0-1,.15-2.08,1.17-2.45a13.73,13.73,0,0,0,4-1.82.59.59,0,1,0-.68-1A12.74,12.74,0,0,1,2,13.11H2c-1.9.65-2,2.68-2,3.54a.41.41,0,0,1,0,.11H0a6.86,6.86,0,0,0,.22,2'
          />
        </g>
      </g>
    </svg>
  )
}
UserProfileSettingSvg.defaultProps= {
  width: '20',
  height: '20'
}
UserProfileSettingSvg.propTypes = propTypes
export default UserProfileSettingSvg
