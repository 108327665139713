// import { fromJS, Map } from 'immutable'

export const shouldContinuePolling = (notificationDetails) => {
  let flag = false
  let pollingStatus = notificationDetails && notificationDetails.status
  if (pollingStatus === 'PENDING') {
    flag = true
  }
  return flag
}

export const updatePollingCounter = (state) => {
  let updatedPollingCount
  const pollingCount = state.get('pollingCount')
  updatedPollingCount = pollingCount + 1
  return state.set('pollingCount', updatedPollingCount)
}
