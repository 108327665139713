import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { sizes, getTypeValues } from '../types'

const propTypes = {
  size: PropTypes.oneOf(getTypeValues(sizes)),
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  formGroupStyle: PropTypes.object,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  id: PropTypes.string
}

const FormGroup = (props) => {
  const {
    size,
    children,
    required,
    formGroupStyle,
    description,
    label,
    id
  } = props

  const componentClass = cx(
    'form-group',
    {
      [`form-group-${size}`]: size
    }
  )

  return (
    <fieldset className={componentClass} style={formGroupStyle}>
      {label &&
        <label htmlFor={id} className={cx('form-label')}>
          {label}{required && <span className={cx('required')}>*</span>}
        </label>
      }
      {children}
      {description &&
        <div className={cx('form-description')}>{description}</div>
      }
    </fieldset>
  )
}

FormGroup.propTypes = propTypes

export default FormGroup
