import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import {
  getTableData,
  getPrivilegesData,
  tabTypes,
  sanitizeDeviceTypes,
  hasCheckedPrivileges,
  sanitizeEntities,
  sanitizeApplicationvalues,
  sanitizedeviceGroupPrivileges,
  getTableDataForDeviceGroup,
  updateDeviceGroupPrivilege,
  updateDeviceGroupPrivilegeForLocations,
  sanitizeDeviceGroupsForModuleClick,
  checkDeviceGroupAccessAll
} from 'store/utils/privilegesUtils'
import { sanitizeUserPrivileges } from './User'

// Constants
export const constants = {
  FETCH: 'privileges/FETCH',
  FETCH_ENTITY: 'privileges/FETCH_ENTITY',
  FETCH_ENTITY_SUCCESS: 'privileges/FETCH_ENTITY_SUCCESS',
  FETCH_PRIVILEGES_SUCCESS: 'privileges/FETCH_PRIVILEGES_SUCCESS',
  FETCH_DEVICE_TYPE_SUCCESS: 'privileges/FETCH_DEVICE_TYPE_SUCCESS',
  FETCH_LOCATION_SUCCESS: 'privileges/FETCH_LOCATION_SUCCESS',
  FETCH_DEVICEGROUP_SUCCESS: 'privileges/FETCH_DEVICEGROUP_SUCCESS',
  ADD_ROLE_PRIVILEGES_SUCCESS: 'privileges/ADD_ROLE_PRIVILEGES_SUCCESS',
  ADD_ROLE_PRIVILEGES_FAILURE: 'privileges/ADD_ROLE_PRIVILEGES_FAILURE',
  DELETE_ROLE_PRIVILEGES_SUCCESS: 'privileges/DELETE_ROLE_PRIVILEGES_SUCCESS',
  FETCH_FAILURE: 'privileges/FETCH_FAILURE',
  FETCH_ROLE_PRIVILEGES: 'privileges/FETCH_ROLE_PRIVILEGES',
  ROLE_PRIVILEGES_SUCCESS: 'privileges/ROLE_PRIVILEGES_SUCCESS',
  ROLE_PRIVILEGES_FAILURE: 'privileges/ROLE_PRIVILEGES_FAILURE',
  SET_GROUPED_DATA: 'privileges/SET_GROUPED_DATA',
  SET_ROUTE: 'privileges/SET_ROUTE',
  SET_APP_BREADCRUMB: 'privileges/SET_APP_BREADCRUMB',
  SET_TAB_TYPE: 'privileges/SET_TAB_TYPE',
  SET_SELECTED_ROLE: 'privileges/SET_SELECTED_ROLE',
  SET_TAB_DATA: 'privileges/SET_TAB_DATA',
  SET_ALL_TABS_DATA: 'privileges/SET_ALL_TABS_DATA',
  SET_LOCATION: 'privileges/SET_LOCATION',
  SEARCH_LOCATION: 'privileges/SEARCH_LOCATION',
  SET_DEVICE_GROUPS: 'privileges/SET_DEVICE_GROUPS',
  DEVICE_TYPE_FETCH_FAILURE: 'privileges/DEVICE_TYPE_FETCH_FAILURE',
  FETCH_LOCATION_FAILURE: 'privileges/FETCH_LOCATION_FAILURE',
  FETCH_DEVICEGROUP_FAILURE: 'privileges/FETCH_DEVICEGROUP_FAILURE',
  SET_DEVICE_GROUP_PRIVILAGE: 'privileges/SET_DEVICE_GROUP_PRIVILAGE',
  SET_SELECTED_LOCATIONDEVICEGROUP: 'privileges/SET_SELECTED_LOCATIONDEVICEGROUP',
  UPDATE_BREADCRUMB_DATA: 'privileges/UPDATE_BREADCRUMB_DATA',
  SET_CLEAR_MODAL: 'privileges/SET_CLEAR_MODAL',
  SET_DEVICE_TYPE_ID: 'privileges/SET_DEVICE_TYPE_ID',
  ADD_ROLE_DEVICE_GROUP_PRIVILEGES_SUCCESS: 'privileges/ADD_ROLE_DEVICE_GROUP_PRIVILEGES_SUCCESS',
  UPDATE_NEW_BREADCRUMB_DATA: 'privileges/UPDATE_NEW_BREADCRUMB_DATA',
  SET_DEVICE_GROUP_PRIVILAGE_TAB: 'privileges/SET_DEVICE_GROUP_PRIVILAGE_TAB',
  REMOVE_LAST_BREADCRUMB: 'privileges/REMOVE_LAST_BREADCRUMB',
  OPEN_ADD_LOCATION_FORM: 'privileges/OPEN_ADD_LOCATION_FORM',
  CLOSE_ADD_LOCATION_FORM: 'privileges/CLOSE_ADD_LOCATION_FORM',
  SET_LOCATION_ERROR: 'privileges/SET_LOCATION_ERROR',
  UNSET_LOCATION_ERROR: 'privileges/UNSET_LOCATION_ERROR',
  UPDATE_SELECTED_ROLE: 'privileges/UPDATE_SELECTED_ROLE',
  UNSET_SELECTED_LOCATION: 'privileges/UNSET_SELECTED_LOCATION',
  RESET_ROLE_FETCH_STATUS: 'privileges/RESET_ROLE_FETCH_STATUS',
  RESET_ADD_PRIVILEGES_FAILURE: 'privileges/RESET_ADD_PRIVILEGES_FAILURE',
  SET_DEVICE_GROUP_ACCESS_ALL: 'privileges/SET_DEVICE_GROUP_ACCESS_ALL',
  CHANGE_GROUP_ACCESS_STATE: 'privileges/CHANGE_GROUP_ACCESS_STATE'
}

const initialAppVal = [
  { resourceName: 'deviceregistry', resourceType: 'entity' },
  { resourceName: 'User Management', childEntities: ['user', 'role'], resourceType: 'entity' },
  { resourceName: 'devicedatasync', resourceType: 'entity' },
  { resourceName: 'deviceTypeManagement', resourceType: 'entity' },
  { resourceName: 'ratingfeedback', resourceType: 'entity' }
]
const initialConfigVal = [
  { resourceName: 'file', resourceType: 'entity' },
  { resourceName: 'System Updates', resourceType: 'entity' },
  { resourceName: 'remoteConnectivity', resourceType: 'entity' }
]
const otherInitialDeviceTypeVal = [
  { resourceName: 'inventory', resourceType: 'devicetype' },
  { resourceName: 'diagnostics', resourceType: 'devicetype' },
  { resourceName: 'process', resourceType: 'devicetype' }
]
const labwaterInitialDeviceTypeVal = [
  { resourceName: 'inventory', resourceType: 'devicetype' },
  { resourceName: 'diagnostics', resourceType: 'devicetype' },
  { resourceName: 'process', resourceType: 'devicetype' },
  { resourceName: 'general', resourceType: 'devicetype' }
]

// Action Creators
export const actions = {
  getLocationFetchQuery(getState) {
    const searchTerm = selectors.searchLocationTerm(getState())
    const deviceTypeId = selectors.getDeviceTypeId(getState())
    const page = '1'
    const query = {
      searchTerm,
      page,
      deviceTypeId,
      pageLimit: 100
    }
    return query
  },
  getDeviceGroupsFetchQuery(getState) {
    const id = selectors.searchLocationTerm(getState())
    const page = '1'
    const query = {
      id,
      page,
      pageLimit: 100
    }
    return query
  },
  fetchEntities () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_ENTITY }),
      apiFunction: 'getEntities',
      onSuccess: ({ data }) => ({ type: constants.FETCH_ENTITY_SUCCESS, data: sanitizeEntities(data) }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchDeviceTypes () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getAllDeviceTypes',
      onSuccess: ({ data }) => ({ type: constants.FETCH_DEVICE_TYPE_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.DEVICE_TYPE_FETCH_FAILURE, error: error.status })
    })
  },
  fetchPrivileges () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getPrivileges',
      onSuccess: ({ data }) => ({ type: constants.FETCH_PRIVILEGES_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchRolePrivileges (roleId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getRolePrivileges',
      args: { roleId, query: {} },
      onSuccess: ({ data }) => ({ type: constants.ROLE_PRIVILEGES_SUCCESS, data }),
      onFailure: ({status}) => ({ type: constants.ROLE_PRIVILEGES_FAILURE, status: status })
    })
  },
  fetchRoleIdPrivileges (roleId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getRoleIdPrivileges',
      args: { roleId, query: {} },
      onSuccess: ({ data }) => ({ type: constants.ROLE_PRIVILEGES_SUCCESS, data }),
      onFailure: ({status}) => ({ type: constants.ROLE_PRIVILEGES_FAILURE, status: status })
    })
  },
  fetchLocations () {
    return safeFetch({
      apiFunction: 'fetchLocations',
      args: actions.getLocationFetchQuery,
      onSuccess: ({ data }) => ({ type: constants.FETCH_LOCATION_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_LOCATION_FAILURE, error: error.message })
    })
  },
  fetchDeviceGroups (location) {
    return safeFetch({
      apiFunction: 'fetchDeviceGroups',
      args: {locationId: location, query: actions.getDeviceGroupsFetchQuery},
      onSuccess: ({ data }) => ({ type: constants.FETCH_DEVICEGROUP_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_DEVICEGROUP_FAILURE, error: error.message })
    })
  },
  addRolePrivileges (arg) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addRolePrivileges',
      args: arg,
      onSuccess: ({ data }) => ({ type: constants.ADD_ROLE_PRIVILEGES_SUCCESS }),
      onFailure: ({status}) => ({ type: constants.ADD_ROLE_PRIVILEGES_FAILURE, status: status })
    })
  },
  deleteRolePrivileges (arg) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deleteRolePrivileges',
      args: arg,
      onSuccess: ({ data }) => ({ type: constants.DELETE_ROLE_PRIVILEGES_SUCCESS }),
      onFailure: ({status}) => ({ type: constants.ADD_ROLE_PRIVILEGES_FAILURE, status: status })
    })
  },
  setTabType (tabType) {
    return (dispatch) => {
      dispatch({ type: constants.SET_TAB_TYPE, data: tabType })
    }
  },
  setLocation (location) {
    return async (dispatch, getState) => {
      const locations = selectors.locations(getState()).toJS()
      const selectedLocationObject = locations.filter(obj => obj.locationName === location)[0]
      dispatch({ type: constants.SET_LOCATION, data: selectedLocationObject })
      dispatch(actions.fetchDeviceGroups(selectedLocationObject.locationId))
    }
  },
  searchLocation (location) {
    return (dispatch) => {
      dispatch({ type: constants.SEARCH_LOCATION, data: location })
      dispatch(actions.fetchLocations())
    }
  },
  setDeviceGroups (deviceGroups) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.SET_DEVICE_GROUPS, data: deviceGroups })
      dispatch({ type: constants.UNSET_LOCATION_ERROR })
    }
  },
  selectLocationDeviceGroups () {
    return async (dispatch, getState) => {
      const selectedDeviceGroups = selectors.selectedDeviceGroups(getState()).toJS()
      const allDeviceGroups = selectors.deviceGroups(getState()).toJS()
      const selectedDeviceGroupObjects = []
      selectedDeviceGroups.forEach(deviceGroup => {
        selectedDeviceGroupObjects.push(allDeviceGroups.filter(obj => obj.customerName === deviceGroup)[0])
      })
      const selectedLocation = selectors.selectedLocation(getState()).toJS()
      const data = selectors.deviceGroupPrivileges(getState())
      let { locations } = data.toJS()
      locations = locations || []
      let find = false
      locations.forEach(loc => {
        if (loc.id === parseInt(selectedLocation.locationId)) {
          selectedDeviceGroupObjects.forEach((deviceGroup) => {
            let custId = parseInt(deviceGroup.customerId)
            if (loc.deviceGroups.map(x => x.id).indexOf(custId) === -1) {
              loc.deviceGroups.push({id: custId, name: deviceGroup.customerName, access: false})
            }
          }
         )
          find = true
        }
      })
      if (!find) {
        const newLoc =
          {
            id: parseInt(selectedLocation.locationId),
            name: selectedLocation.locationName,
            access: false,
            deviceGroups: selectedDeviceGroupObjects.map(deviceGroup => ({ id: parseInt(deviceGroup.customerId), name: deviceGroup.customerName, access: false }))
          }
        locations.push(newLoc)
      }
      dispatch({type: constants.SET_SELECTED_LOCATIONDEVICEGROUP, locations})
    }
  },
  setSelectedRole (role) {
    return (dispatch) => {
      dispatch({ type: constants.SET_SELECTED_ROLE, role })
    }
  },
  getDeviceGroupPrivilage (roleId, deviceTypeId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getDeviceGroupPrivileges',
      args: { roleId: roleId, deviceTypeId: {devicetype: deviceTypeId} },
      onSuccess: ({ data }) => ({ type: constants.SET_DEVICE_GROUP_PRIVILAGE, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  getNewTabState (tabType, getState) {
    const breadcrumb = selectors.getBreadcrumb(tabType)(getState()).last().toJS()
    const entities = selectors.getEntities(getState())
    const privileges = selectors.getPrivileges(getState())
    const rolePrivileges = selectors.getRolePrivileges(getState())
    let data = null
    if (breadcrumb.data.privileges) {
      data = getPrivilegesData(breadcrumb.data.privileges, rolePrivileges)
    } else if (breadcrumb.data.childEntities) {
      data = getTableData(breadcrumb.data.childEntities, entities, privileges, rolePrivileges, breadcrumb.data.resourceId || null)
    } else if (breadcrumb.data.permissionSelection || breadcrumb.data.permissionType) {
      data = breadcrumb.data.permissionSelection || {permissionType: breadcrumb.data.permissionType}
    } else {
      data = hasCheckedPrivileges(breadcrumb.data, rolePrivileges)
    }
    return data
  },
   // action to refresh tab data
  refreshAllTabs () {
    return async (dispatch, getState) => {
      const application = actions.getNewTabState(tabTypes.APPLICATION, getState)
      const configuration = actions.getNewTabState(tabTypes.CONFIGURATION, getState)
      const devices = actions.getNewTabState(tabTypes.DEVICES, getState)
      await dispatch({ type: constants.SET_ALL_TABS_DATA, application, configuration, devices })
    }
  },
  refreshTab (tabType) {
    return async (dispatch, getState) => {
      const data = actions.getNewTabState(tabType, getState)
      await dispatch({ type: constants.SET_TAB_DATA, data, tabType })
    }
  },
  refreshDeviceGroupTab (tabType, lastChildCheck = false) {
    return async (dispatch, getState) => {
      const data = actions.getNewTabStateDeviceGroups(tabType, getState)
      let deviceGroupPrivilegeData = {}
      const breadcrumb = selectors.getBreadcrumb(tabType)(getState()).toJS()
      let index = 0
      if (lastChildCheck && breadcrumb.length > 3) {
        index = breadcrumb.length - 1
        dispatch({ type: constants.REMOVE_LAST_BREADCRUMB })
      } else {
        index = breadcrumb.length
      }
      dispatch({ type: constants.UPDATE_BREADCRUMB_DATA, data, index: index })
      const deviceTypeId = selectors.getDeviceTypeId(getState())
      deviceGroupPrivilegeData = getTableDataForDeviceGroup(data, deviceTypeId || null)
      const permissionType = true
      await dispatch({ type: constants.SET_TAB_DATA, data: {permissionType, deviceGroupPrivilege: deviceGroupPrivilegeData, resourceId: deviceTypeId}, tabType })
    }
  },
  childRefreshDeviceGroupTab (tabType, deviceGroupId) {
    return async (dispatch, getState) => {
      const data = actions.getNewTabStateDeviceGroups(tabType, getState)
      let newChild = []
      let i = 0
      let childIndex = 0
      let locationAccess = false
      data.forEach(dat => {
        if (dat.childEntities.some(child => child.id === deviceGroupId)) {
          newChild = dat.childEntities
          childIndex = i
        }
        i++
      })
      if (newChild.some(child => child.access !== false)) {
        locationAccess = true
      }
      const breadcrumb = selectors.getBreadcrumb(tabType)(getState()).toJS()
      dispatch({ type: constants.UPDATE_NEW_BREADCRUMB_DATA, data: newChild, childIndex: childIndex, index: breadcrumb.length, tabType, locationAccess })
    }
  },
  onModuleClick (child, tabType) {
    return async (dispatch, getState) => {
      const entities = selectors.getEntities(getState())
      const privileges = selectors.getPrivileges(getState())
      const rolePrivileges = selectors.getRolePrivileges(getState())
      let childData = null
      if (child.privileges) {
        childData = getPrivilegesData(child.privileges, rolePrivileges)
      }
      if (child.childEntities) {
        childData = getTableData(child.childEntities, entities, privileges, rolePrivileges)
      }
      if (child.permissionSelection) {
        childData = child.permissionSelection
      }
      await dispatch({ type: constants.SET_TAB_DATA, data: childData, crumbData: {route: child.module, data: child}, tabType })
    }
  },
  onDeviceGroupModuleClick (child, tabType) {
    return async (dispatch, getState) => {
      let childData = null
      let permissionType = true
      const sanitizeDeviceGroupsData = sanitizeDeviceGroupsForModuleClick(child.childEntities)
      childData = getTableDataForDeviceGroup(sanitizeDeviceGroupsData, child.resourceId, child.locationId)
      const locationTab = true
      await dispatch({ type: constants.SET_TAB_DATA, locationTab, data: {permissionType, deviceGroupPrivilege: childData, resourceId: child.resourceId}, crumbData: {route: child.module, data: child}, tabType })
    }
  },
  // Action to set initial table values for tabs
  groupData (tabType, value) {
    return async (dispatch, getState) => {
      const initialDevicesValue = sanitizeDeviceTypes(selectors.getDeviceTypes(getState())?.toJS())
      const entities = selectors.getEntities(getState())
      const sanitizedInitialAppVal = sanitizeApplicationvalues(initialAppVal, entities)
      const sanitizedInitialConfigVal = sanitizeApplicationvalues(initialConfigVal, entities)
      const privileges = selectors.getPrivileges(getState())
      const rolePrivileges = selectors.getRolePrivileges(getState())
      const application = await getTableData(sanitizedInitialAppVal, entities, privileges, rolePrivileges)
      const configuration = await getTableData(sanitizedInitialConfigVal, entities, privileges, rolePrivileges)
      const devices = await getTableData(initialDevicesValue, entities, privileges, rolePrivileges)
      dispatch({ type: constants.SET_GROUPED_DATA, application, configuration, devices, value })
    }
  },
  // Action for device type permissions click
  onPermissionClick (permissionType, child) {
    return async (dispatch, getState) => {
      const resources = selectors.getResources(getState())
      const privileges = selectors.getPrivileges(getState())
      const rolePrivileges = selectors.getRolePrivileges(getState())
      const roleId = selectors.getSelectedRole(getState()).get('value')
      const deviceTypeId = child.resourceId
      await dispatch({ type: constants.SET_DEVICE_TYPE_ID, Id: deviceTypeId })
      if (permissionType === 'card') {
        dispatch(actions.setDeviceGroupPrivilegeTab(false))
        const initialDeviceTypeVal = child.resourceId === 2 ? labwaterInitialDeviceTypeVal : otherInitialDeviceTypeVal
        const data = getTableData(initialDeviceTypeVal, resources, privileges, rolePrivileges, child.resourceId)
        const breadcrumbData = { module: child.module, access: true, resourceId: child.resourceId, childEntities: initialDeviceTypeVal }
        await dispatch({ type: constants.SET_TAB_DATA, data, tabType: tabTypes.DEVICES, crumbData: {route: child.module, data: breadcrumbData} })
      } else {
        dispatch(actions.setDeviceGroupPrivilegeTab(true))
        await dispatch(actions.getDeviceGroupPrivilage(roleId, deviceTypeId))
        const data = selectors.deviceGroupPrivileges(getState())
        const {locations} = data && data.toJS()
        const deviceGroupPrivilegeData = locations ? sanitizedeviceGroupPrivileges(locations) : []
        const deviceGroupPrivilegeValue = await getTableDataForDeviceGroup(deviceGroupPrivilegeData, deviceTypeId)
        const breadcrumbData = { module: child.module, access: true, resourceId: child.resourceId, permissionType, childEntities: deviceGroupPrivilegeData }
        await dispatch({type: constants.SET_TAB_DATA, data: {permissionType, deviceGroupPrivilege: deviceGroupPrivilegeValue, resourceId: child.resourceId}, tabType: tabTypes.DEVICES, crumbData: {route: child.module, data: breadcrumbData}})
      }
    }
  },
  getNewTabStateDeviceGroups (tabType, getState) {
    const data = selectors.deviceGroupPrivileges(getState())
    const {locations} = data && data.toJS()
    const deviceGroupPrivilegeData = locations ? sanitizedeviceGroupPrivileges(locations) : []
    return deviceGroupPrivilegeData
  },
  onRouteClick (index, tabType) {
    return async (dispatch, getState) => {
      const routeData = selectors.getBreadcrumbData(index, tabType)(getState()).toJS().data
      const newBreadcrumb = selectors.getBreadcrumb(tabType)(getState()).take(index + 1)
      let childData = null
      const entities = selectors.getEntities(getState())
      const privileges = selectors.getPrivileges(getState())
      const rolePrivileges = selectors.getRolePrivileges(getState())
      if (routeData.privileges) {
        childData = getPrivilegesData(routeData.privileges, rolePrivileges)
      } else if (routeData.childEntities) {
        childData = getTableData(routeData.childEntities, entities, privileges, rolePrivileges, routeData.resourceId || null)
      } else if (routeData.permissionSelection) {
        childData = routeData.permissionSelection
      } else {
        childData = hasCheckedPrivileges(routeData, rolePrivileges)
      }
      await dispatch({ type: constants.SET_ROUTE, newBreadcrumb, tabType })
      await dispatch({ type: constants.SET_TAB_DATA, data: childData, tabType })
    }
  },
  onDeviceGroupRouteClick (index, tabType) {
    return async (dispatch, getState) => {
      const routeData = selectors.getBreadcrumbData(index, tabType)(getState()).toJS().data
      const newBreadcrumb = selectors.getBreadcrumb(tabType)(getState()).take(index + 1)
      const rolePrivileges = selectors.getRolePrivileges(getState())
      let childData = null
      const permissionType = true
      let setTabData = null
      if (routeData.childEntities) {
        childData = getTableDataForDeviceGroup(routeData.childEntities, routeData.resourceId || null)
        const locationTab = true
        setTabData = { type: constants.SET_TAB_DATA, locationTab, data: {permissionType, deviceGroupPrivilege: childData, resourceId: routeData.resourceId}, tabType }
      } else if (routeData.permissionSelection) {
        childData = routeData.permissionSelection
        dispatch(actions.setDeviceGroupPrivilegeTab(false))
        setTabData = { type: constants.SET_TAB_DATA, data: childData, tabType }
      } else {
        childData = hasCheckedPrivileges(routeData, rolePrivileges)
        dispatch(actions.setDeviceGroupPrivilegeTab(false))
        setTabData = { type: constants.SET_TAB_DATA, data: childData, tabType }
      }
      await dispatch({ type: constants.SET_ROUTE, newBreadcrumb, tabType })
      await dispatch(setTabData)
    }
  },
  setClearModal () {
    return (dispatch) => {
      dispatch({type: constants.SET_CLEAR_MODAL})
    }
  },
  setDeviceGroupPrivilegeTab (enable) {
    return (dispatch) => {
      dispatch({type: constants.SET_DEVICE_GROUP_PRIVILAGE_TAB, enable})
    }
  },
  onAccessDeviceGropuPrivilegeClick (access, resource, roleId, privilegeId) {
    const updatePrivilageOperation = access ? 'deleteDeviceGroupPrivileges' : 'addDeviceGroupPrivileges'
    let arg
    if (resource.childEntities) {
      arg = resource.childEntities.map(child => {
        return {
          deviceGroupId: child.deviceGroupId,
          locationId: resource.locationId,
          resourceId: resource.resourceId,
          privilegeId: privilegeId
        }
      })
    } else {
      arg = [{
        deviceGroupId: resource.deviceGroupId,
        locationId: resource.locationId,
        resourceId: resource.resourceId,
        privilegeId: privilegeId
      }]
    }
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: updatePrivilageOperation,
      args: { roleId: roleId, privilegeList: arg },
      onSuccess: ({ data }) => ({ type: constants.ADD_ROLE_DEVICE_GROUP_PRIVILEGES_SUCCESS, access: access, locationId: resource.locationId, deviceGroupId: resource.deviceGroupId || false }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  openAddLocationForm () {
    return { type: constants.OPEN_ADD_LOCATION_FORM }
  },
  closeAddLocationForm () {
    return { type: constants.CLOSE_ADD_LOCATION_FORM }
  },
  setlocationError () {
    return { type: constants.SET_LOCATION_ERROR }
  },
  unsetlocationError () {
    return { type: constants.UNSET_LOCATION_ERROR }
  },
  updateSelectedRole (role) {
    return { type: constants.UPDATE_SELECTED_ROLE, name: role.name }
  },
  unsetSelectedLocation () {
    return { type: constants.UNSET_SELECTED_LOCATION }
  },
  resetRolePrivilegeFetchError () {
    return { type: constants.RESET_ROLE_FETCH_STATUS }
  },
  resetAddPrivilegesFailure () {
    return { type: constants.RESET_ADD_PRIVILEGES_FAILURE }
  },
  DeviceGroupAccessAll (resourceId) {
    return async (dispatch, getState) => {
      const rolePrivileges = selectors.getRolePrivileges(getState())
      const accessAll = checkDeviceGroupAccessAll(rolePrivileges.toJS(), resourceId)
      dispatch({type: constants.SET_DEVICE_GROUP_ACCESS_ALL, accessAll})
    }
  },
  changeGroupAccessAllState () {
    return { type: constants.CHANGE_GROUP_ACCESS_STATE }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  processing: false,
  error: null,
  tabType: null,
  addPrivilegesSuccess: false,
  deletePrivilegesSuccess: false,
  selectedRole: null,
  rolePrivileges: [],
  entities: [],
  resources: [],
  privileges: [],
  deviceTypes: [],
  application: {breadcrumb: []},
  configuration: {breadcrumb: []},
  devices: {breadcrumb: [], locations: [], deviceGroup: [], deviceGroupPrivileges: null, deviceTypeId: null},
  searchLocationTerm: '',
  selectedLocationDeviceGroups: [],
  locations: [],
  selectedDeviceGroups: [],
  locationTab: false,
  deviceGroupTabEnable: false,
  addLocationForm: false,
  locationError: false,
  rolePrivilegeFetchError: null,
  addPrivilegesFailure: null,
  deviceGroupAccessAll: false,
  selectedRolePrivileges: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('processing', true)
        .set('fetching', true)
        .set('addPrivilegesSuccess', false)
        .set('deletePrivilegesSuccess', false)
        .set('error', null)
    case constants.FETCH_ENTITY:
      return state
        .set('processing', true)
        .set('fetching', true)
        .set('selectedRole', null)
        .set('application', initialState.get('application'))
        .set('configuration', initialState.get('configuration'))
        .set('devices', initialState.get('devices'))
        .set('resources', fromJS([]))
        .set('rolePrivileges', fromJS([]))
        .set('entities', fromJS([]))
        .set('privileges', fromJS([]))
        .set('deviceTypes', fromJS([]))
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('error', action.error)
        .set('processing', false)
    case constants.DEVICE_TYPE_FETCH_FAILURE:
      return state
        .setIn(['devices', 'error'], action.error)
        .set('processing', false)
    case constants.FETCH_ENTITY_SUCCESS:
      return state
        .set('fetching', false)
        .set('entities', fromJS(action.data))
    case constants.FETCH_PRIVILEGES_SUCCESS:
      return state
        .set('fetching', false)
        .set('processing', false)
        .set('privileges', fromJS(action.data))
    case constants.ROLE_PRIVILEGES_SUCCESS:
      return state
        .set('fetching', false)
        .set('selectedRolePrivileges', sanitizeUserPrivileges(action.data))
        .set('rolePrivileges', fromJS(action.data))
    case constants.ROLE_PRIVILEGES_FAILURE:
      return state
        .set('rolePrivilegeFetchError', {status: action.status})
        .set('fetching', false)
    case constants.FETCH_DEVICE_TYPE_SUCCESS:
      return state
        .set('fetching', false)
        .set('deviceTypes', fromJS(action.data))
    case constants.SET_DEVICE_TYPE_ID:
      return state
         .setIn(['devices', 'deviceTypeId'], action.Id)
    case constants.FETCH_LOCATION_SUCCESS:
      return state
        .set('fetching', false)
        .set('locations', fromJS(action.data))
    case constants.FETCH_DEVICEGROUP_SUCCESS:
      return state
        .set('fetching', false)
        .setIn(['devices', 'deviceGroup'], fromJS(action.data))
    case constants.SET_LOCATION:
      return state
        .set('searchLocationTerm', fromJS(action.data))
        .setIn(['devices', 'selectedLocations'], fromJS(action.data))
        .setIn(['devices', 'selectedDeviceGroups'], fromJS([]))
    case constants.SEARCH_LOCATION:
      return state
        .set('searchLocationTerm', fromJS(action.data))
    case constants.SET_DEVICE_GROUPS:
      return state
        .updateIn(['devices', 'selectedDeviceGroups'], (state) => {
          return state.find(selectedDevGrp => selectedDevGrp === action.data)
          ? state.filter(item => item !== action.data) : state.push(action.data)
        })
    case constants.SET_DEVICE_GROUP_PRIVILAGE:
      return state
         .set('fetching', false)
         .set('processing', false)
         .setIn(['devices', 'deviceGroupPrivileges'], fromJS(action.data))
    case constants.SET_SELECTED_LOCATIONDEVICEGROUP:
      return state
         .setIn(['devices', 'deviceGroupPrivileges', 'locations'], fromJS(action.locations))
    case constants.ADD_ROLE_DEVICE_GROUP_PRIVILEGES_SUCCESS:
      return state
        .set('fetching', false)
        .set('processing', false)
        .updateIn(['devices', 'data', 'deviceGroupPrivilege'], (loc) => fromJS(updateDeviceGroupPrivilege(loc, action.access, action.locationId, action.deviceGroupId)))
        .updateIn(['devices', 'deviceGroupPrivileges', 'locations'], (loc) => fromJS(updateDeviceGroupPrivilegeForLocations(loc, action.access, action.locationId, action.deviceGroupId)))
    case constants.SET_CLEAR_MODAL:
      return state
         .setIn(['devices', 'selectedDeviceGroups'], fromJS([]))
    case constants.REMOVE_LAST_BREADCRUMB:
      return state
         .updateIn(['devices', 'breadcrumb'], (data) => data.pop())
    case constants.UPDATE_BREADCRUMB_DATA:
      return state
         .updateIn(['devices', 'breadcrumb', action.index - 1, 'data', 'childEntities'], () => fromJS(action.data))
    case constants.UPDATE_NEW_BREADCRUMB_DATA:
      return state
         .updateIn(['devices', 'breadcrumb', action.index - 2, 'data', 'childEntities', action.childIndex, 'childEntities'], () => fromJS(action.data))
         .updateIn(['devices', 'breadcrumb', action.index - 2, 'data', 'childEntities', action.childIndex, 'locationAccess'], () => fromJS(action.locationAccess))
    case constants.SET_GROUPED_DATA:
      return state
        .setIn(['application', 'data'], fromJS(action.application))
        .setIn(['configuration', 'data'], fromJS(action.configuration))
        .setIn(['devices', 'data'], fromJS(action.devices))
        .setIn(['application', 'breadcrumb'], fromJS([{ route: action.value, data: action.application }]))
        .setIn(['configuration', 'breadcrumb'], fromJS([{ route: action.value, data: action.configuration }]))
        .setIn(['devices', 'breadcrumb'], fromJS([{ route: action.value, data: action.devices }]))
        .set('processing', false)
    case constants.SET_TAB_DATA:
      let crumb = state.getIn([action.tabType, 'breadcrumb'])
      let crumbData = action.crumbData ? crumb.push(fromJS(action.crumbData)) : crumb
      return state
        .setIn([action.tabType, 'data'], fromJS(action.data))
        .setIn([action.tabType, 'breadcrumb'], crumbData)
        .set('processing', false)
        .set('locationTab', action.locationTab)
    case constants.SET_APP_BREADCRUMB:
      let breadcrumb = state.getIn([action.tabType, 'breadcrumb'])
      return state
        .setIn([action.tabType, 'breadcrumb'], breadcrumb.push(fromJS(action.data)))
    case constants.SET_ROUTE:
      return state
        .setIn([action.tabType, 'breadcrumb'], fromJS(action.newBreadcrumb))
    case constants.SET_TAB_TYPE:
      return state
        .set('tabType', fromJS(action.data))
    case constants.SET_DEVICE_GROUP_PRIVILAGE_TAB:
      return state
         .set('deviceGroupTabEnable', fromJS(action.enable))
    case constants.SET_SELECTED_ROLE:
      return state
        .set('selectedRole', fromJS(action.role))
    case constants.ADD_ROLE_PRIVILEGES_SUCCESS:
      return state
        .set('addPrivilegesSuccess', true)
    case constants.ADD_ROLE_PRIVILEGES_FAILURE:
      return state
         .set('addPrivilegesFailure', {status: action.status})
    case constants.DELETE_ROLE_PRIVILEGES_SUCCESS:
      return state
        .set('deletePrivilegesSuccess', true)
    case constants.SET_ALL_TABS_DATA:
      return state
        .setIn(['application', 'data'], fromJS(action.application))
        .setIn(['configuration', 'data'], fromJS(action.configuration))
        .setIn(['devices', 'data'], fromJS(action.devices))
        .set('processing', false)
    case constants.OPEN_ADD_LOCATION_FORM:
      return state
         .set('addLocationForm', true)
    case constants.CLOSE_ADD_LOCATION_FORM:
      return state
         .set('addLocationForm', false)
    case constants.SET_LOCATION_ERROR:
      return state
         .set('locationError', true)
    case constants.UNSET_LOCATION_ERROR:
      return state
         .set('locationError', false)
    case constants.UPDATE_SELECTED_ROLE:
      return state
         .setIn(['selectedRole', 'text'], action.name)
    case constants.UNSET_SELECTED_LOCATION:
      return state
         .setIn(['devices', 'selectedLocations'], null)
    case constants.RESET_ROLE_FETCH_STATUS:
      return state
         .set('rolePrivilegeFetchError', null)
    case constants.RESET_ADD_PRIVILEGES_FAILURE:
      return state
         .set('addPrivilegesFailure', null)
    case constants.SET_DEVICE_GROUP_ACCESS_ALL:
      return state
         .set('deviceGroupAccessAll', action.accessAll)
    case constants.CHANGE_GROUP_ACCESS_STATE:
      return state
         .set('deviceGroupAccessAll', !state.get('deviceGroupAccessAll'))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.privileges

export const selectors = {
  error: createSelector(getState, (state) =>
    state.get('error')
  ),
  deviceTypeError: createSelector(getState, (state) =>
    state.getIn(['devices', 'error'])
  ),
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  getTabType: createSelector(getState, (state) =>
    state.get('tabType')
  ),
  getResources: createSelector(getState, (state) =>
    state.get('resources')
  ),
  getEntities: createSelector(getState, (state) =>
    state.get('entities')
  ),
  getPrivileges: createSelector(getState, (state) =>
    state.get('privileges')
  ),
  getDeviceTypes: createSelector(getState, (state) =>
    state.get('deviceTypes')
  ),
  getRolePrivileges: createSelector(getState, (state) =>
    state.get('rolePrivileges')
  ),
  getSelectedRolePrivileges: createSelector(getState, (state) =>
    state.get('selectedRolePrivileges')
  ),
  getTableDAta: (tabType) => createSelector(getState, (state) =>
    state.getIn([tabType, 'data'])
  ),
  getBreadcrumb: (tabType) => createSelector(getState, (state) =>
    state.getIn([tabType, 'breadcrumb'])
  ),
  getBreadcrumbData: (index, tabType) => createSelector(getState, (state) =>
    state.getIn([tabType, 'breadcrumb', index])
  ),
  getSelectedRole: createSelector(getState, (state) =>
    state.get('selectedRole')
  ),
  locations: createSelector(getState, (state) =>
    state.get('locations')
  ),
  deviceGroups: createSelector(getState, (state) =>
    state.getIn(['devices', 'deviceGroup'])
  ),
  selectedLocation: createSelector(getState, (state) =>
    state.getIn(['devices', 'selectedLocations'])
  ),
  selectedDeviceGroups: createSelector(getState, (state) =>
    state.getIn(['devices', 'selectedDeviceGroups'])
  ),
  processing: createSelector(getState, (state) =>
    state.get('processing')
  ),
  searchLocationTerm: createSelector(getState, (state) =>
    state.get('searchLocationTerm')
  ),
  deviceGroupPrivileges: createSelector(getState, (state) =>
    state.getIn(['devices', 'deviceGroupPrivileges'])
  ),
  deviceGroupPrivilegeId: createSelector(getState, (state) =>
    state.getIn(['devices', 'deviceGroupPrivileges', 'privilegeId'])
  ),
  getDeviceTypeId: createSelector(getState, (state) =>
  state.getIn(['devices', 'deviceTypeId'])
  ),
  locationTab: createSelector(getState, (state) =>
  state.get('locationTab')
  ),
  deviceGroupTabEnable: createSelector(getState, (state) =>
  state.get('deviceGroupTabEnable')
  ),
  getAddLocationForm: createSelector(getState, (state) =>
  state.get('addLocationForm')
  ),
  getLocationError: createSelector(getState, (state) =>
  state.get('locationError')
  ),
  getrolePrivilegeFetchError: createSelector(getState, (state) =>
  state.get('rolePrivilegeFetchError')
  ),
  getAddPrivilegesFailure: createSelector(getState, (state) =>
  state.get('addPrivilegesFailure')
  ),
  getDeviceGroupAccessAll: createSelector(getState, (state) =>
  state.get('deviceGroupAccessAll')
  )
}
