import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
const deleteIconSvg = (props) => {
  const { className, width, height, style } = props
  return (
    <svg
      className={className}
      width={`${width}`}
      height={`${height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16.4 16.4'
    >
      <defs></defs>

      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            style={{fill: '#ffd3cc', stroke: '#e76e54', strokeMiterlimit:'10', strokeWidth: '0.5px', ...style}}
            d='M13.17,16.15H3.23a3,3,0,0,1-3-3V3.23a3,3,0,0,1,3-3h9.94a3,3,0,0,1,3,3v9.94A3,3,0,0,1,13.17,16.15Z'
          />
          <path
            style={{fill: '#e76e54', ...style}}
            d='M8.78,8.2,12,5a.41.41,0,1,0-.58-.58L8.2,7.62,5,4.44A.41.41,0,0,0,4.44,5L7.62,8.2,4.44,11.38A.41.41,0,1,0,5,12L8.2,8.78,11.38,12a.41.41,0,1,0,.58-.58Z'
          />
        </g>
      </g>
    </svg>
  )
}
deleteIconSvg.propTypes = propTypes
deleteIconSvg.defaultProps = {
  width: '18',
  height: '18',
}
export default deleteIconSvg
