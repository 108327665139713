import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './FeedbackView.scss'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'

const cx = classNames.bind(styles)
const CANCEL_BUTTON_STYLE = {
    textTransform: 'capitalize',
    background: '#2dbecd',
    color: 'white',
    borderRadius: '5px',
    fontWeight: '900',
}

const propTypes = {
    close: PropTypes.func
}

const FeedbackView = (props) => {
    const { close } = props
    return (
        <div>
            <span style={{fontSize: 'large'}}>
            <FormattedMessage id='next.NEWS_FEEDBACK_POPUP_MESSAGE' />
        </span>
            <div className={cx('modal-footer-container')}>
                {/* <input
                    type="checkbox"
                />
                <span style={{paddingLeft: '6px'}}>
                    <FormattedMessage id='next.NEWS_FEEDBACK_TICKBOX_MESSAGE' />
                </span> */}
                <Button
                    color='muted'
                    style={CANCEL_BUTTON_STYLE}
                    onClick={() => close()}
                >
                    <FormattedMessage id='next.NEWS_FEEDBACK_POPUP_OK_BUTTON' />
                </Button>
            </div>
        </div>

    )
}

FeedbackView.propTypes = propTypes

export default FeedbackView
