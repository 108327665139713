import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'

export const constants = {
  FETCH_ERROR_NAMES: 'CUSTOMER_QUALITY_DASHBOARD/FETCH_ERROR_NAMES',
  RECEIVE_ERROR_NAMES: 'CUSTOMER_QUALITY_DASHBOARD/RECEIVE_ERROR_NAMES',
  FETCH_ERROR_NAMES_FAILURE: 'CUSTOMER_QUALITY_DASHBOARD/FETCH_ERROR_NAMES_FAILURE'
}

// Action Creators
export const actions = {
  fetchErrorNames(data) {
    const {
      deviceId
    } = data
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_ERROR_NAMES, deviceId }),
      apiFunction: 'fetchErrorNames',
      args: data,
      onSuccess: ({ data: errorMessages }) => ({ type: constants.RECEIVE_ERROR_NAMES, errorMessages, deviceId }),
      onFailure: ({ status }) => ({ type: constants.FETCH_ERROR_NAMES_FAILURE, deviceId, error: status })
    })
  }
}

// Reducer
export const initialState = fromJS({
  errorNames: {}
})

// Selectors
const getState = (state) => state.customerDashboardTooltip

export const selectors = {
  errorNames: createSelector(getState, (state) => {
    return state?.get('errorNames')
  })
}

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_ERROR_NAMES:
      return state
        .setIn(['errorNames', action.deviceId, 'fetching'], true)
        .setIn(['errorNames', action.deviceId, 'error'], null)
    case constants.FETCH_ERROR_NAMES_FAILURE:
      return state
        .setIn(['errorNames', action.deviceId, 'fetching'], false)
        .setIn(['errorNames', action.deviceId, 'error'], action.error)
    case constants.RECEIVE_ERROR_NAMES:
      return state
        .setIn(['errorNames', action.deviceId, 'fetching'], false)
        .setIn(['errorNames', action.deviceId, 'error'], null)
        .setIn(['errorNames', action.deviceId, 'data'], action.errorMessages)
    default:
      return state
  }
}
