import React from 'react'
import PropTypes from 'prop-types'
import { Input, AntSelect, Tooltip } from 'antDComponents'
import { deviceMaxWidth } from 'utils/screenSizeConstants'
import PhoneNumberCriteria from 'components/UsersCriteria/PhoneNumberCriteria'
import classNames from 'classnames/bind'
import styles from './PhoneWithIsdCode.scss'

const cx = classNames.bind(styles)
const PhoneWithIsdCode = ({
  values,
  handleChange,
  phonePlaceholder,
  isdPlaceholder,
  phoneLabel,
  isdLabel,
  phoneStyle,
  isdStyle,
  setFieldValue,
  isRequired,
  options,
  phoneInputName,
  style,
  isDisabled,
  chFlag
}) => {
  return (
    <div
      style={window.innerWidth <= deviceMaxWidth.MOBILE ?
        { ...style } :
        { display: 'flex', ...style }}
    >
      <AntSelect
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        options={options}
        value={values.isdCode}
        placeholder={isdPlaceholder}
        style={{ ...isdStyle }}
        label={isdLabel}
        name='isdCode'
        isRequired={isRequired}
        showSearch
        filterOption={(input, option) =>
          option.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        className={cx('isd__code', chFlag ? 'ch_isdcode' : '')}
        disabled={isDisabled}
      />
      <Tooltip placement='rightTop' title={PhoneNumberCriteria}>
        <Input
          label={phoneLabel}
          name={phoneInputName || 'phone'}
          placeholder={phonePlaceholder}
          value={values.phoneNumber || values.phone}
          handleChange={handleChange}
          style={{ ...phoneStyle }}
          className={cx('phone-number-isd-field', chFlag ? 'ch_phone' : '')}
          type='text'
          isRequired={isRequired}
          disabled={isDisabled}
        />
      </Tooltip>
    </div>
  )
}

PhoneWithIsdCode.defaultProps = {
  phonePlaceholder: 'Enter Phone',
  isdPlaceholder: 'ISD Code',
  phoneLabel: '',
  isdLabel: 'Code',
  isRequired: true,
  style: {}
}

const propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  phonePlaceholder: PropTypes.string,
  isdPlaceholder: PropTypes.string,
  phoneLabel: PropTypes.string,
  isdLabel: PropTypes.string,
  chFlag: PropTypes.string,
  phoneStyle: PropTypes.object,
  isdStyle: PropTypes.object,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
  setFieldValue: PropTypes.func,
  phoneInputName: PropTypes.func,
  style: PropTypes.object,
  isDisabled: PropTypes.bool
}
PhoneWithIsdCode.propTypes = propTypes

export default PhoneWithIsdCode
