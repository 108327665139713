import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import { fromJS } from 'immutable'
import { batch } from 'react-redux'
import {
  shouldContinuePolling,
  updatePollingCounter
} from 'store/utils/remoteConnectivityUtils'
import {
  actions as deviceDetailsAction
} from './DeviceDetails'
import {
  actions as authActions
} from 'store/modules/Auth'
import { SESSION_DISCONNECTED } from 'constants/remoteConnectivity'

export const constants = {
  SET_DEVICE_UUID: 'REMOTE_CONNECTIVITY/SET_DEVICE_UUID',
  SHOW_REMOTE_CONNECTIVITY_WINDOW: 'REMOTE_CONNECTIVITY/SHOW_REMOTE_CONNECTIVITY_WINDOW',
  FETCH_REMOTE_CONNECTION: 'REMOTE_CONNECTIVITY/FETCH_REMOTE_CONNECTION',
  FETCH_REMOTE_CONNECTION_FAILURE: 'REMOTE_CONNECTIVITY/FETCH_REMOTE_CONNECTION_FAILURE',
  RECEIVE_REMOTE_CONNECTION_NOTIFICATION_DATA: 'REMOTE_CONNECTIVITY/RECEIVE_REMOTE_CONNECTION_NOTIFICATION_DATA',
  RECEIVE_REMOTE_CONNECTION_SESSION_DATA: 'REMOTE_CONNECTIVITY/RECEIVE_REMOTE_CONNECTION_SESSION_DATA',
  RESET_REMOTE_NOTIFICATION_ID_ERROR_STATUS: 'REMOTE_CONNECTIVITY/RESET_REMOTE_NOTIFICATION_ID_ERROR_STATUS',
  RECEIVE_CONNECTED_SESSION_DATA: 'REMOTE_CONNECTIVITY/RECEIVE_CONNECTED_SESSION_DATA',
  DEVICE_REMOTE_CONNECTION_STATUS: 'REMOTE_CONNECTIVITY/DEVICE_REMOTE_CONNECTION_STATUS',
  FETCH_POLL_REMOTE_CONNECTION: 'REMOTE_CONNECTIVITY/FETCH_POLL_REMOTE_CONNECTION',
  DELETE_REMOTE_CONNECTION: 'REMOTE_CONNECTIVITY/DELETE_REMOTE_CONNECTION',
  DELETE_REMOTE_CONNECTION_FAILURE: 'REMOTE_CONNECTIVITY/DELETE_REMOTE_CONNECTION_FAILURE',
  FETCH_REMOTE_CONNECTION_NOTIFICATION_ID_FAILURE: 'REMOTE_CONNECTIVITY/FETCH_REMOTE_CONNECTION_NOTIFICATION_ID_FAILURE',
  CLEAR_POLL_DETAILS: 'REMOTE_CONNECTIVITY/CLEAR_POLL_DETAILS',
  SET_WINDOW_LOCATION: 'REMOTE_CONNECTIVITY/SET_WINDOW_LOCATION',
  RESET_LOCATION_URL: 'REMOTE_CONNECTIVITY/RESET_LOCATION_URL',
  CHECK_DEVICE_WINDOW_STATUS_FAILURE: 'REMOTE_CONNECTIVITY/CHECK_DEVICE_WINDOW_STATUS_FAILURE',
  CHECK_DEVICE_WINDOW_STATUS_SUCCESS: 'REMOTE_CONNECTIVITY/CHECK_DEVICE_WINDOW_STATUS_SUCCESS',
  RESET_DEVICE_WINDOW_SESSION_STATUS: 'REMOTE_CONNECTIVITY/RESET_DEVICE_WINDOW_SESSION_STATUS',
  SET_MODAL_OPEN: 'REMOTE_CONNECTIVITY/SET_MODAL_OPEN',
  ADDFEEDBACK: 'REMOTE_CONNECTIVITY/ADDFEEDBACK',
  ADD_FEEDBACK_SUCCESS: 'REMOTE_CONNECTIVITY/ADD_FEEDBACK_SUCCESS',
  ADD_FEEDBACK_FAILURE: 'REMOTE_CONNECTIVITY/ADD_FEEDBACK_FAILURE',
  RESET_DEVICE_REMOTECONNECTIVITY: 'REMOTE_CONNECTIVITY/RESET_DEVICE_REMOTECONNECTIVITY',
  FETCH: 'REMOTE_CONNECTIVITY/FETCH'
}

// Action Creators
export const actions = {
  setUUID(uuid) {
    return {
      type: constants.SET_DEVICE_UUID, uuid
    }
  },
  showRemoteConnectivityWindow(status) {
    return {
      type: constants.SHOW_REMOTE_CONNECTIVITY_WINDOW, status
    }
  },
  fetchRemoteConnectionNotificationDetails(uuid) {
    return async(dispatch, getState) => {
      await dispatch(actions.fetchRemoteConnectionNotificationId(uuid))
      let { notificationId } = selectors.remoteConnectionNotificationData(getState()).toJS()
      if (notificationId) {
        dispatch(actions.fetchRemoteConnectionNotificationStatus(notificationId))
      }
    }
  },
  fetchRemoteConnectionNotificationId(uuid) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_REMOTE_CONNECTION }),
      apiFunction: 'fetchRemoteConnectionNotificationId',
      args: uuid,
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_REMOTE_CONNECTION_NOTIFICATION_DATA,
        data
      }),
      onFailure: (error) => ({
        type: constants.FETCH_REMOTE_CONNECTION_NOTIFICATION_ID_FAILURE,
        error: error
      })
    })
  },
  fetchRemoteConnectionNotificationStatus(notificationId) {
    return async(dispatch, getState) => {
      await dispatch(actions.pollRemoteConnection(notificationId))
      const pollCount = selectors.pollingCount(getState())
      const remotePollTimerId = setTimeout(() => {
        const shouldPoll = shouldContinuePolling(
          selectors.remoteConnectionSessionData(getState()).toJS())
        if (shouldPoll && (pollCount <= 30) && selectors.remoteConnectivityFlag(getState())) {
          dispatch(actions.fetchRemoteConnectionNotificationStatus(notificationId))
        }
      }, 6000)
      if (pollCount > 30) {
        clearTimeout(remotePollTimerId)
        dispatch(actions.closeRemoteConnectionWindow({uuid: selectors.deviceUUID(getState()), notificationId: notificationId}))
      }
    }
  },
  pollRemoteConnection(notificationId) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH_POLL_REMOTE_CONNECTION}),
      apiFunction: 'pollRemoteConnectionSessionId',
      args: notificationId,
      onSuccess: ({data}) => {
        return (dispatch, getState) => {
          batch(() => {
            dispatch({type: constants.RECEIVE_REMOTE_CONNECTION_SESSION_DATA, data})
            if (data && data.status === 'SUCCESS') {
              setTimeout(() => {
                dispatch(authActions.getAccessToken())
              }, 3300000)
              dispatch(authActions.getAccessToken())
              dispatch(deviceDetailsAction.setRemoteConnectionSessionData(data))
            }
          })
        }
      },
      onFailure: (error) => ({
        type: constants.FETCH_REMOTE_CONNECTION_FAILURE,
        error: error
      })
    })
  },
  redirectToRemoteSession(sessionId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_REMOTE_CONNECTION }),
      apiFunction: 'redirectToRemoteSession',
      args: sessionId,
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_CONNECTED_SESSION_DATA,
        data
      }),
      onFailure: (error) => ({
        type: constants.FETCH_REMOTE_CONNECTION_FAILURE,
        error: error
      })
    })
  },
  resetRemoteNotificationIdErrorStatus() {
    return {
      type: constants.RESET_REMOTE_NOTIFICATION_ID_ERROR_STATUS
    }
  },
  updateDeviceRemoteConnectionStatus(uuid, deviceStatus) {
    return {
      type: constants.DEVICE_REMOTE_CONNECTION_STATUS, uuid, deviceStatus
    }
  },
  closeRemoteConnectionWindow(data) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_REMOTE_CONNECTION }),
      apiFunction: 'closeRemoteConnectionWindow',
      args: data,
      onSuccess: ({ data }) => {
        return ({
          type: constants.DELETE_REMOTE_CONNECTION,
          data
        })
      },
      onFailure: (error) => {
        return ({
          type: constants.DELETE_REMOTE_CONNECTION_FAILURE,
          error: error
        })
      }
    })
  },
  clearPollDetails() {
    return {
      type: constants.CLEAR_POLL_DETAILS
    }
  },
  setWindowLocationUrl(locationUrl, sessionType) {
    return {
      type: constants.SET_WINDOW_LOCATION, locationUrl, sessionType
    }
  },
  handleWindowOpenClose() {
    return {
      type: constants.RESET_LOCATION_URL
    }
  },
  checkDeviceWindowStatus(uuid) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_REMOTE_CONNECTION }),
      apiFunction: 'checkDeviceWindowStatus',
      args: uuid,
      onSuccess: ({ data }) => ({
        type: constants.CHECK_DEVICE_WINDOW_STATUS_SUCCESS,
        data
      }),
      onFailure: (error) => ({
        type: constants.CHECK_DEVICE_WINDOW_STATUS_FAILURE,
        error: error
      })
    })
  },
  resetDeviceWindowSessionStatus() {
    return {
      type: constants.RESET_DEVICE_WINDOW_SESSION_STATUS
    }
  },
  setModalOpen(status) {
    return {
      type: constants.SET_MODAL_OPEN, status
    }
  },
  saveRemoteSessionFeedback(query, notificationId) {
    return safeFetch({
      args: {query, notificationId},
      throwError: true,
      apiFunction: 'saveRemoteSessionFeedback',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({data}) => ({ type: constants.ADD_FEEDBACK_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.ADD_FEEDBACK_FAILURE, error })
    })
  },
  resetRemoteControlStatus () {
    return {
      type: constants.RESET_DEVICE_REMOTECONNECTIVITY
    }
  }
}

export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  remoteConnectivityFlag: false,
  remoteConnectionNotificationData: {},
  remoteConnectionSessionData: {},
  connectedSessionResponse: null,
  deviceRemoteConnectionStatus: {},
  pollingCount: 0,
  fetchNotificationIdError: null,
  locationUrl: '/remote',
  sessionType: null,
  notificationIdErrorMsg: null,
  getDeviceSessionStatusMsg: null,
  checkDeviceWindowStatusFail: null,
  windowTitle: 'Remote Connection',
  notificationIdSnackBarMsg: null,
  addedFeedback: null,
  addedFeedbackFailure: null,
  deleteRemoteConnectionSuccess: {},
  deleteRemoteConnectionFailure: null,
  remoteMessage: ''
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.SET_DEVICE_UUID:
      return state
        .set('deviceUUID', action.uuid)
    case constants.FETCH_REMOTE_CONNECTION:
      return state
        .set('fetching', true)
    case constants.SHOW_REMOTE_CONNECTIVITY_WINDOW:
      return state
        .set('remoteConnectivityFlag', action.status)
        .set('fetching', false)
    case constants.RECEIVE_REMOTE_CONNECTION_NOTIFICATION_DATA:
      return state
        .set('remoteConnectionNotificationData', fromJS(action.data))
        .set('fetching', false)
        .set('fetchError', null)
        .set('fetchNotificationIdError', null)
        .set('notificationIdErrorMsg', null)
        .set('notificationIdSnackBarMsg', null)
    case constants.RECEIVE_REMOTE_CONNECTION_SESSION_DATA:
      return state
        .set('remoteConnectionSessionData', fromJS(action.data))
        .set('fetching', false)
        .set('fetchError', null)
    case constants.FETCH_REMOTE_CONNECTION_FAILURE:
      return state
        .set('fetchError', fromJS(action.error))
        .set('fetching', false)
    case constants.FETCH_REMOTE_CONNECTION_NOTIFICATION_ID_FAILURE:
      return state
        .set('fetchNotificationIdError', fromJS(action.error))
        .set('fetching', false)
        .set('notificationIdErrorMsg', fromJS(action.error))
        .set('notificationIdSnackBarMsg', fromJS(action.error))
    case constants.SET_MODAL_OPEN:
      return state
        .set('modalOpen', action.status)
    case constants.RESET_REMOTE_NOTIFICATION_ID_ERROR_STATUS:
      return state
        .set('notificationIdSnackBarMsg', null)
        .set('fetching', false)
    case constants.RECEIVE_CONNECTED_SESSION_DATA:
      return state
        .set('connectedSessionResponse', fromJS(action.data))
        .set('fetching', false)
    case constants.DEVICE_REMOTE_CONNECTION_STATUS:
      return state
        .setIn(['deviceRemoteConnectionStatus', action.uuid], action.deviceStatus)
    case constants.FETCH_POLL_REMOTE_CONNECTION:
      return updatePollingCounter(state)
    case constants.CLEAR_POLL_DETAILS:
      return state
        .set('pollingCount', 0)
    case constants.SET_WINDOW_LOCATION:
      return state
        .set('locationUrl', action.locationUrl)
        .set('sessionType', action.sessionType)
    case constants.RESET_LOCATION_URL:
      return state
        .set('locationUrl', '/remote')
        .set('windowTitle', titleRandomCreationFunc())
    case constants.CHECK_DEVICE_WINDOW_STATUS_SUCCESS:
      return state
        .set('deviceWindowSessionStatus', fromJS(action.data))
        .set('getDeviceSessionStatusMsg', checkStatusHelper(fromJS(action.data)))
    case constants.RESET_DEVICE_WINDOW_SESSION_STATUS:
      return state
        .set('getDeviceSessionStatusMsg', null)
    case constants.CHECK_DEVICE_WINDOW_STATUS_FAILURE:
      return state
        .set('checkDeviceWindowStatusFail', fromJS(action.error))
    case constants.ADD_FEEDBACK_SUCCESS:
      return state
        .set('addedFeedback', action.data)
    case constants.ADD_FEEDBACK_FAILURE:
      return state
        .set('addedFeedbackFailure', action.error)
    case constants.DELETE_REMOTE_CONNECTION:
      return state
        .set('remoteMessage', action.data.remoteMessage && action.data.remoteMessage === SESSION_DISCONNECTED ? action.data.remoteMessage : '')
        .set('deleteRemoteConnectionSuccess', fromJS(action.data))
    case constants.DELETE_REMOTE_CONNECTION_FAILURE:
      return state
        .set('deleteRemoteConnectionFailure', fromJS(action.error))
    case constants.RESET_DEVICE_REMOTECONNECTIVITY:
      return state
        .set('deleteRemoteConnectionSuccess', {})
        .set('deleteRemoteConnectionFailure', null)
        .set('addedFeedback', null)
        .set('addedFeedbackFailure', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceRemoteConnectivity

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  fetchNotificationIdError: createSelector(getState, (state) =>
    state.get('fetchNotificationIdError')
  ),
  remoteConnectivityFlag: createSelector(getState, (state) => {
    return state.get('remoteConnectivityFlag')
  }),
  remoteConnectionNotificationData: createSelector(getState, (state) => {
    return state.get('remoteConnectionNotificationData')
  }),
  remoteConnectionSessionData: createSelector(getState, (state) => {
    return state.get('remoteConnectionSessionData')
  }),
  connectedSessionResponse: createSelector(getState, (state) => {
    return state.get('connectedSessionResponse')
  }),
  deviceRemoteConnectionStatus: createSelector(getState, (state) => {
    return state.get('deviceRemoteConnectionStatus')
  }),
  pollingCount: createSelector(getState, (state) =>
    state.get('pollingCount')
  ),
  deviceUUID: createSelector(getState, (state) => {
    return state.get('deviceUUID')
  }),
  locationUrl: createSelector(getState, (state) =>
    state.get('locationUrl')
  ),
  sessionType: createSelector(getState, (state) =>
    state.get('sessionType')
  ),
  notificationIdErrorMsg: createSelector(getState, (state) =>
    state.get('notificationIdErrorMsg')
  ),
  notificationIdSnackBarMsg: createSelector(getState, (state) =>
    state.get('notificationIdSnackBarMsg')
  ),
  deviceWindowSessionStatus: createSelector(getState, (state) =>
    state.get('deviceWindowSessionStatus')
  ),
  getDeviceSessionStatusMsg: createSelector(getState, (state) =>
    state.get('getDeviceSessionStatusMsg')
  ),
  windowTitle: createSelector(getState, (state) =>
    state.get('windowTitle')
  ),
  modalOpen: createSelector(getState, (state) =>
    state.get('modalOpen')
  ),
  addedFeedback: createSelector(getState, (state) =>
    state.get('addedFeedback')
  ),
  deleteRemoteConnectionSuccess: createSelector(getState, (state) =>
    state.get('deleteRemoteConnectionSuccess')
  ),
  deleteRemoteConnectionFailure: createSelector(getState, (state) =>
    state.get('deleteRemoteConnectionFailure')
  ),
  remoteMessage: createSelector(getState, (state) =>
    state.get('remoteMessage')
  )
}

function checkStatusHelper (status) {
  if (!status) {
    return 'Success'
  } else {
    return 'Failure'
  }
}

function titleRandomCreationFunc() {
  const min = 1
  const max = 100
  const rand = min + Math.random() * (max - min)
  return `RemoteConnection${rand}`
}
