export const moduleId = 'deviceSoftwareUpload'

export const eventTypes = {
  'OFFLINE': 'Offline'
}

export const filterTypes = {
  'DEVICE_TYPE': 'deviceType',
  'SOFTWARE_RELEASE_STATUS': 'swReleaseStatus',
  'SOFTWARE_RELEASE_INFORMATION': 'swReleaseInformation'
}

export const uploadStatus = {
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  pending: 'PENDING',
  recalled: 'RECALLED'
}

export const formMode = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  recall: 'recall'
}

export const softwareInfo = {
  securityImprovements: 'Security Improvements',
  bugCorrection: 'Bug Correction',
  newFeatures: 'New Features'
}
