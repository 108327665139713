import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { filterTypes as deviceSoftwareUpdateFilterTypes } from 'constants/deviceSoftwareUpdateConstant'
import {
  actions as userProfileActions
} from 'store/modules/UserProfile'
import {
  actions as listingActions
} from 'store/modules/GenericListing'
// Constants
export const moduleId = 'deviceSoftwareUpdate'
export const constants = {
  FETCH: 'DEVICE_SOFTWARE_UPDATE/FETCH',
  FETCH_FAILURE: 'DEVICE_SOFTWARE_UPDATE/FETCH_FAILURE',
  FETCH_POLL_CALLED: 'PARTNER_ADMIN_DASHBOARD/FETCH_POLL_CALLED',
  RECEIVE_FILTER_TYPES: 'DEVICE_SOFTWARE_UPDATE/RECEIVE_FILTER_TYPES',
  FETCH_FILTER_FAILURE: 'DEVICE_SOFTWARE_UPDATE/FETCH_FILTER_FAILURE',
  FETCH_FILTER_TYPES: 'DEVICE_SOFTWARE_UPDATE/FETCH_FILTER_TYPES',
  DEVICE_TYPES_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/DEVICE_TYPES_SUCCESS',
  DEVICE_TYPES_FAILURE: 'DEVICE_SOFTWARE_UPDATE/DEVICE_TYPES_FAILURE',
  PRODUCT_RANGE_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/PRODUCT_RANGE_SUCCESS',
  PRODUCT_RANGE_FAILURE: 'DEVICE_SOFTWARE_UPDATE/PRODUCT_RANGE_FAILURE',
  OPEN_UPLOAD_FILE_FORM: 'DEVICE_SOFTWARE_UPDATE/OPEN_UPLOAD_FILE_FORM',
  CLOSE_UPLOAD_FILE_FORM: 'DEVICE_SOFTWARE_UPDATE/CLOSE_UPLOAD_FILE_FORM',
  SET_UPLOADING_STATE: 'DEVICE_SOFTWARE_UPDATE/SET_UPLOADING_STATE',
  DOWNLOAD_FAIL: 'DEVICE_SOFTWARE_UPDATE/DOWNLOAD_FAIL',
  FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_SUCCESS',
  FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_ERROR: 'DEVICE_SOFTWARE_UPDATE/FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_ERROR',
  FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_SUCCESS',
  FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_ERROR: 'DEVICE_SOFTWARE_UPDATE/FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_ERROR',
  UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_SUCCESS',
  UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_FAILURE: 'DEVICE_SOFTWARE_UPDATE/UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_FAILURE',
  RESET_DEVICE_SINGLE_DEVICE_SOFTWARE_STATUS: 'DEVICE_SOFTWARE_UPDATE/RESET_DEVICE_SINGLE_DEVICE_SOFTWARE_STATUS',
  FETCH_USER_TERRITORIES_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/FETCH_USER_TERRITORIES_SUCCESS',
  FETCH_CANCEL_UPDATE_SOFTWARE: 'DEVICE_SOFTWARE_UPDATE/FETCH_CANCEL_UPDATE_SOFTWARE',
  CANCEL_UPDATE_SOFTWARE_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/CANCEL_UPDATE_SOFTWARE_SUCCESS',
  CANCEL_UPDATE_SOFTWARE_FAILURE: 'DEVICE_SOFTWARE_UPDATE/CANCEL_UPDATE_SOFTWARE_FAILURE',
  RESET_STATE_UPDATE_SOFTWARE_FAILURE: 'DEVICE_SOFTWARE_UPDATE/RESET_STATE_UPDATE_SOFTWARE_FAILURE',
  SET_POLL_TIMER_ID: 'DEVICE_SOFTWARE_UPDATE/SET_POLL_TIMER_ID',
  CLEAR_POLL_TIMER_ID: 'DEVICE_SOFTWARE_UPDATE/CLEAR_POLL_TIMER_ID',
  CLEAR_POLL_DEVICES: 'DEVICE_SOFTWARE_UPDATE/CLEAR_POLL_DEVICES',
  FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS',
  FETCH_DEVICE_SOFTWARE_UPDATE_ERROR: 'DEVICE_SOFTWARE_UPDATE/FETCH_DEVICE_SOFTWARE_UPDATE_ERROR',
  START_POLLING_DEVICE_SOFTWARE_UPDATE: 'DEVICE_SOFTWARE_UPDATE/START_POLLING_DEVICE_SOFTWARE_UPDATE',
  STOP_POLLING_DEVICE_SOFTWARE_UPDATE: 'DEVICE_SOFTWARE_UPDATE/STOP_POLLING_DEVICE_SOFTWARE_UPDATE',
  FETCH_CATEGORIES_ID: 'DEVICE_SOFTWARE_UPDATE/FETCH_CATEGORY_ID',
  FETCH_CATEGORIES_ID_FAILURE: 'DEVICE_SOFTWARE_UPDATE/FETCH_CATEGORY_ID_FAILURE',
  RECEIVE_CATEGORIES_ID_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/RECEIVE_CATEGORY_ID_SUCCESS',
  PAUSE_POLL_DEVICE_SOFTWARE_UPDATE: 'DEVICE_SOFTWARE_UPDATE/PAUSE_POLL_DEVICE_SOFTWARE_UPDATE',
  RESUME_POLL_DEVICE_SOFTWARE_UPDATE: 'DEVICE_SOFTWARE_UPDATE/RESUME_POLL_DEVICE_SOFTWARE_UPDATE',
  GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS: 'DEVICE_SOFTWARE_UPDATE/GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS',
  GENERATE_DEVICE_SOFTWARE_REPORT_FAILURE: 'DEVICE_SOFTWARE_UPDATE/GENERATE_DEVICE_SOFTWARE_REPORT_FAILURE',
  RESET_SOFTWARE_REPORT_STATUS: 'DEVICE_SOFTWARE_UPDATE/RESET_SOFTWARE_REPORT_STATUS',
  RESET_SOFTWARE_LIST_DATA: 'DEVICE_SOFTWARE_UPDATE/RESET_SOFTWARE_LIST_DATA',
  RESET_DEVICE_SERIAL_NUMBER: 'DEVICE_SOFTWARE_UPDATE/RESET_DEVICE_SERIAL_NUMBER'
}

// Action Creators
export const actions = {
  resetSoftwareList () {
    return (dispatch) => {
      dispatch({ type: constants.RESET_SOFTWARE_LIST_DATA })
    }
  },
  openUploadFileForm () {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_UPLOAD_FILE_FORM })
    }
  },
  closeUploadFileForm () {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_UPLOAD_FILE_FORM })
    }
  },
  setUploadingState (uploadStatus) {
    return { type: constants.SET_UPLOADING_STATE, uploadStatus }
  },
  fetchDeviceTypes () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getDeviceTypes',
      onSuccess: ({ data }) => ({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes: data }),
      onFailure: (error) => ({ type: constants.DEVICE_TYPES_FAILURE, error: error })
    })
  },
  fetchProductRange (deviceTypeId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchProductRange',
      getResponse: true,
      args: {deviceTypeId},
      onSuccess: ({ data }) => ({ type: constants.PRODUCT_RANGE_SUCCESS, productRanges: data }),
      onFailure: (error) => ({ type: constants.PRODUCT_RANGE_FAILURE, error: error })
    })
  },
  fetchDeviceSoftwareUpdateFilter () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTER_TYPES }),
      apiFunction: 'fetchDeviceSoftwareFilter',
      onSuccess: ({data}) => {
        return ({ type: constants.RECEIVE_FILTER_TYPES, data })
      },
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error })
    })
  },
  fetchUserTerritories (userId) {
    return async (dispatch, getState) => {
      try {
        await dispatch({ type: constants.FETCH })
        const { data: territories } = await dispatch(userProfileActions.fetchUserTerritories(userId))
        const { data: countries } = await dispatch(userProfileActions.searchTerritories())

        const data = territories
          .map(({ resourceId }) => {
            const country = countries.find(({ id }) => id === resourceId)

            return country
          })

        dispatch({ type: constants.FETCH_USER_TERRITORIES_SUCCESS, data })
      } catch (error) {
        dispatch({ type: constants.FETCH_FAILURE, error: error.toString() })
      }
    }
  },
  fetchSerialNumber(deviceTypeId, productRangeId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchSerialNumber',
      getResponse: true,
      args: {deviceTypeId, productRangeId},
      onSuccess: ({data}) => ({type: constants.FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_SUCCESS, data}),
      onFailure: (error) => ({type: constants.FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_ERROR, error})
    })
  },
  fetchSoftwareReleaseDetails(params) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchSoftwareReleaseDetails',
      args: params,
      onSuccess: ({data}) => ({type: constants.FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_SUCCESS, data}),
      onFailure: ({status}) => ({type: constants.FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_ERROR, error: status})
    })
  },
  updateDeviceSoftware(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateDeviceSoftware',
      args: payload,
      throwError: true,
      onSuccess: ({data}) => ({type: constants.UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_SUCCESS, data}),
      onFailure: ({status}) => ({type: constants.UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_FAILURE, error: status})
    })
  },
  resetUpdateSwStatus () {
    return (dispatch) => {
      dispatch({ type: constants.RESET_DEVICE_SINGLE_DEVICE_SOFTWARE_STATUS })
    }
  },
  multipleProductRange () {
    return (dispatch) => {
      dispatch({ type: constants.RESET_DEVICE_SERIAL_NUMBER })
    }
  },
  cancelSoftwareUpdate(payload) {
    return async (dispatch, getState) => {
      dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH_CANCEL_UPDATE_SOFTWARE }),
        apiFunction: 'cancelSoftwareUpdate',
        args: payload,
        throwError: true,
        onSuccess: ({data}) => {
          dispatch({type: constants.CANCEL_UPDATE_SOFTWARE_SUCCESS, data})
          const deviceUpdateStatus = selectors.deviceUpdateStatus(getState())?.toJS()
          dispatch(listingActions.updateDeviceSoftwareStatus({data: deviceUpdateStatus, payload}))
        },
        onFailure: (error) => ({type: constants.CANCEL_UPDATE_SOFTWARE_FAILURE, error})
      }))
    }
  },
  resetErrorOrSuccessCancelSoftUpdate () {
    return (dispatch) => {
      dispatch({type: constants.RESET_STATE_UPDATE_SOFTWARE_FAILURE})
    }
  },
  fetchDeviceSoftwareUpdateStatus(payload) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({type: constants.FETCH_POLL_CALLED}),
        preventMultipleRequest: true,
        apiFunction: 'fetchDeviceSoftwareUpdateStatus',
        args: payload,
        getResponse: true,
        onSuccess: ({data}) => {
          dispatch(listingActions.updateDeviceSoftwareStatus({data}))
          return ({ type: constants.FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS, data })
        },
        onFailure: (error) => ({ type: constants.FETCH_DEVICE_SOFTWARE_UPDATE_ERROR, error: error.toString() })
      }))
      return (resp && resp.data) || []
    }
  },
  setPollTimerId(pollTimerId) {
    return {type: constants.SET_POLL_TIMER_ID, pollTimerId}
  },
  clearPollTimerId() {
    return {type: constants.CLEAR_POLL_TIMER_ID}
  },
  clearPollDevices() {
    return {type: constants.CLEAR_POLL_DEVICES}
  },
  onPausePoll() {
    return {type: constants.PAUSE_POLL_DEVICE_SOFTWARE_UPDATE}
  },
  onResumePoll() {
    return async (dispatch, getState) => {
      await dispatch({type: constants.RESUME_POLL_DEVICE_SOFTWARE_UPDATE})
      await dispatch(actions.poll())
    }
  },
  stopPoll() {
    return async (dispatch, getState) => {
      const pollTimerId = selectors.pollTimerId(getState())
      if (typeof pollTimerId === 'number') {
        clearTimeout(pollTimerId)
        return dispatch({type: constants.STOP_POLLING_DEVICE_SOFTWARE_UPDATE})
      }
    }
  },
  startPoll(listingDevices) {
    return {
      type: constants.START_POLLING_DEVICE_SOFTWARE_UPDATE,
      listingDevices
    }
  },
  pollingAction(listingData) {
    return async (dispatch, getState) => {
      if (selectors.pollingShouldStart(getState())) {
        await dispatch(actions.clearPollDevices())
        const deviceIds = listingData.map(({deviceId}) => (deviceId))
        await dispatch(actions.startPoll(deviceIds))
        await dispatch(actions.poll())
      } else {
        return await dispatch(actions.stopPoll())
      }
    }
  },
  poll() {
    return async (dispatch, getState) => {
      const deviceIds = selectors.listingDevices(getState())
      if (selectors.pollingShouldContinue(getState()) && deviceIds && deviceIds.length > 0) {
        await dispatch(actions.fetchDeviceSoftwareUpdateStatus({deviceIds}))
        const pollTimerId = await setTimeout(() => {
          dispatch(actions.poll())
        }, 5000)
        dispatch(actions.setPollTimerId(pollTimerId))
      } else {
        return await dispatch(actions.stopPoll())
      }
    }
  },
  getCategoriesId () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_CATEGORIES_ID }),
      apiFunction: 'getCategoriesId',
      throwError: true,
      onSuccess: ({data}) => ({type: constants.RECEIVE_CATEGORIES_ID_SUCCESS, data}),
      onFailure: ({status}) => ({type: constants.FETCH_CATEGORIES_ID_FAILURE, error: status})
    })
  },
  generateSoftwareReport (payload) {
    return safeFetch({
      apiFunction: 'generateSoftwareReport',
      args: payload,
      throwError: true,
      onSuccess: (data) => ({type: constants.GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS, data}),
      onFailure: (error) => ({type: constants.GENERATE_DEVICE_SOFTWARE_REPORT_FAILURE, error})
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  openUploadFileForm: false,
  fetchError: null,
  fetchFilterError: null,
  fetchingFilters: false,
  filterCategories: [],
  deviceTypes: null,
  productRanges: null,
  serialNumber: [],
  deviceSoftwareUploadList: [],
  swUpdateSuccess: false,
  userTerritories: [],
  cancelSoftwareUpdateSuccess: false,
  pollingShouldStart: true,
  pollingShouldContinue: false,
  pollTimerId: null,
  listingDevices: [],
  deviceUpdateStatus: null,
  fetchSerialNoError: null,
  fetchProductRangeError: null,
  categoriesId: [],
  fetchingCategoryErr: null,
  swReportSuccess: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
      .set('fetching', true)
      .set('fetchingFilters', true)
    case constants.FETCH_FAILURE:
      return state
      .set('fetchError', action.error)
      .set('fetching', false)
      .set('fetchingFilters', false)
    case constants.FETCH_FILTER_TYPES:
      return state
      .set('fetchingFilters', true)
      .set('fetching', true)
    case constants.FETCH_FILTER_FAILURE:
      return state
      .set('fetchFilterError', action.error)
      .set('fetching', false)
      .set('fetchingFilters', false)
    case constants.RECEIVE_FILTER_TYPES:
      return state
      .set('fetchingFilters', false)
      .set('fetching', false)
      .set('filterCategories', formatFilterCategories(action.data))
    case constants.DEVICE_TYPES_SUCCESS:
      return state
      .set('deviceTypes', fromJS(action.deviceTypes))
      .set('fetching', false)
    case constants.PRODUCT_RANGE_SUCCESS:
      return state
      .set('productRanges', fromJS(action.productRanges))
      .set('fetchProductRangeError', null)
      .set('fetching', false)
    case constants.PRODUCT_RANGE_FAILURE:
      return state
      .set('fetchProductRangeError', action.error)
      .set('productRanges', fromJS(null))
      .set('fetching', false)
    case constants.OPEN_UPLOAD_FILE_FORM:
      return state
      .set('openUploadFileForm', true)
    case constants.CLOSE_UPLOAD_FILE_FORM:
      return state
      .set('openUploadFileForm', false)
    case constants.SET_UPLOADING_STATE:
      return state
      .set('uploading', action.uploadStatus)
    case constants.FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_SUCCESS:
      const { softwareReleaseNo, softwareReleaseDate } = action.data
      return state
      .set('serialNumber', fromJS(action.data))
      .set('deviceSoftwareUploadList', fromJS([{softwareReleaseNo, softwareReleaseDate}]))
      .set('fetchSerialNoError', null)
      .set('fetching', false)
    case constants.FETCH_DEVICE_SOFTWARE_UPLOAD_SERIAL_NUMBER_ERROR:
      return state
      .set('fetching', false)
      .set('fetchSerialNoError', action.error)
      .set('serialNumber', fromJS([]))
      .set('deviceSoftwareUploadList', fromJS([]))
    case constants.FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_SUCCESS:
      return state
      .set('fetching', false)
      .set('deviceSoftwareUploadList', fromJS(action.data))
    case constants.FETCH_DEVICE_SOFTWARE_UPLOAD_LIST_ERROR:
      return state
      .set('fetching', false)
    case constants.UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_SUCCESS:
      return state
      .set('fetching', false)
      .set('swUpdateSuccess', true)
    case constants.UPDATE_DEVICE_SINGLE_DEVICE_SOFTWARE_FAILURE:
      return state
      .set('fetching', false)
      .set('swUpdateSuccess', false)
    case constants.RESET_DEVICE_SINGLE_DEVICE_SOFTWARE_STATUS:
      return state
      .set('serialNumber', fromJS([]))
      .set('deviceSoftwareUploadList', fromJS([]))
      .set('swUpdateSuccess', false)
      .set('fetchSerialNoError', null)
    case constants.FETCH_USER_TERRITORIES_SUCCESS:
      return state
      .set('fetching', false)
      .set('userTerritories', action.data)
    case constants.FETCH_CANCEL_UPDATE_SOFTWARE:
      return state
      .set('fetching', true)
    case constants.CANCEL_UPDATE_SOFTWARE_SUCCESS:
      return state
      .set('fetching', false)
      .set('cancelSoftwareUpdateSuccess', true)
    case constants.CANCEL_UPDATE_SOFTWARE_FAILURE:
      return state
      .set('fetching', false)
      .set('cancelUpdateError', action.error)
    case constants.RESET_STATE_UPDATE_SOFTWARE_FAILURE:
      return state
      .set('fetching', false)
      .set('cancelSoftwareUpdateSuccess', null)
      .set('cancelUpdateError', null)
    case constants.PAUSE_POLL_DEVICE_SOFTWARE_UPDATE:
      return state
      .set('pollingShouldContinue', false)
      .set('pollingShouldStart', true)
    case constants.RESUME_POLL_DEVICE_SOFTWARE_UPDATE:
      return state
      .set('pollingShouldContinue', true)
      .set('pollingShouldStart', false)
    case constants.CLEAR_POLL_DEVICES:
      return state
      .set('listingDevices', fromJS([]))
    case constants.STOP_POLLING_DEVICE_SOFTWARE_UPDATE:
      return state
      .set('deviceUpdateStatus', fromJS(null))
      .set('pollingShouldContinue', false)
      .set('pollingShouldStart', true)
    case constants.SET_POLL_TIMER_ID:
      return state
      .set('pollTimerId', action.pollTimerId)
    case constants.CLEAR_POLL_TIMER_ID:
      return state
      .set('deviceUpdateStatus', fromJS(null))
      .set('pollTimerId', null)
      .set('pollingShouldContinue', false)
      .set('pollingShouldStart', false)
    case constants.FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS:
      return state
      .set('deviceUpdateStatus', fromJS(action.data))
      .set('pollingShouldContinue', true)
      .set('pollingShouldStart', false)
    case constants.FETCH_DEVICE_SOFTWARE_UPDATE_ERROR:
      return state
      .set('deviceUpdateStatus', fromJS(null))
      .set('pollingShouldContinue', true)
      .set('pollingShouldStart', false)
    case constants.START_POLLING_DEVICE_SOFTWARE_UPDATE:
      return state
      .set('listingDevices', action.listingDevices)
      .set('pollingShouldContinue', true)
      .set('pollingShouldStart', false)
    case constants.FETCH_CATEGORIES_ID:
      return state
      .set('fetching', true)
    case constants.RECEIVE_CATEGORIES_ID_SUCCESS:
      return state
      .set('fetching', false)
      .set('categoriesId', action.data)
    case constants.FETCH_CATEGORIES_ID_FAILURE:
      return state
      .set('fetchingCategoryErr', action.error)
      .set('fetching', false)
      .set('categoriesId', [])
    case constants.GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS:
      return state
      .set('fetching', false)
      .set('swReportSuccess', 'SUCCESS')
    case constants.RESET_SOFTWARE_REPORT_STATUS:
      return state
      .set('fetching', false)
      .set('swReportSuccess', null)
    case constants.RESET_SOFTWARE_LIST_DATA:
      return state
      .set('deviceSoftwareUploadList', fromJS([]))
    case constants.RESET_DEVICE_SERIAL_NUMBER:
      return state
      .set('serialNumber', fromJS([]))
      .set('deviceSoftwareUploadList', fromJS([]))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceSoftwareUpdate

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  deviceTypes: createSelector(getState, (state) => {
    return state.get('deviceTypes')
  }),
  productRanges: createSelector(getState, (state) => {
    return state.get('productRanges')
  }),
  openUploadFileForm: createSelector(getState, (state) =>
    state.get('openUploadFileForm')
  ),
  fetchFilterError: createSelector(getState, (state) => {
    return state.get('fetchFilterError')
  }),
  fetchingFilters: createSelector(getState, (state) => {
    return state.get('fetchingFilters')
  }),
  userTerritories: createSelector(getState, (state) => {
    return state.get('userTerritories')
  }),
  getSerialNumber: createSelector(getState, (state) => {
    return state.get('serialNumber')
  }),
  deviceSoftwareUploadList: createSelector(getState, (state) => {
    return state.get('deviceSoftwareUploadList')
  }),
  swUpdateSuccess: createSelector(getState, (state) => {
    return state.get('swUpdateSuccess')
  }),
  cancelSoftwareUpdateSuccess: createSelector(getState, (state) => {
    return state.get('cancelSoftwareUpdateSuccess')
  }),
  cancelUpdateError: createSelector(getState, (state) => {
    return state.get('cancelUpdateError')
  }),
  pollingShouldContinue: createSelector(getState, (state) =>
    state.get('pollingShouldContinue')
  ),
  pollingShouldStart: createSelector(getState, (state) =>
    state.get('pollingShouldStart')
  ),
  pollTimerId: createSelector(getState, (state) =>
    state.get('pollTimerId')
  ),
  listingDevices: createSelector(getState, (state) =>
    state.get('listingDevices')
  ),
  deviceUpdateStatus: createSelector(getState, (state) =>
    state.get('deviceUpdateStatus')
  ),
  fetchSerialNoError: createSelector(getState, (state) => {
    return state.get('fetchSerialNoError')
  }),
  fetchProductRangeError: createSelector(getState, (state) => {
    return state.get('fetchProductRangeError')
  }),
  categoriesId: createSelector(getState, (state) => {
    return state.get('categoriesId')
  }),
  fetchingCategoryErr: createSelector(getState, (state) => {
    return state.get('fetchingCategoryErr')
  }),
  swReportSuccess: createSelector(getState, (state) =>
    state.get('swReportSuccess')
  )
}

// helper functions
function formatFilterCategories (filterTypes) {
  const filterOrder = [
    deviceSoftwareUpdateFilterTypes.COUNTRY_CODE,
    deviceSoftwareUpdateFilterTypes.DEVICE_TYPE,
    deviceSoftwareUpdateFilterTypes.ORGANIZATION_NAME,
    deviceSoftwareUpdateFilterTypes.DIS_ORG_NAME,
    deviceSoftwareUpdateFilterTypes.DEVICE_SOFTWARE_STATUS
  ]

  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filterTypes[filter] && filterTypes[filter] instanceof Array && filterTypes[filter].length && filter in filterTypes) {
      const values = filterTypes[filter]
      let isSearchBox = filter === deviceSoftwareUpdateFilterTypes.ORGANIZATION_NAME || filter === deviceSoftwareUpdateFilterTypes.COUNTRY_CODE
      const categoryType = filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => ({
          name: val,
          label: val,
          key: index,
          value: `${filter}.${val}`
        }))
      filterCategories.push({categoryType, categoryValues, searchBox: isSearchBox})
    }
  })
  return fromJS(filterCategories)
}
