
export const authTypes = {
  INTERNAL: 'internal_auth',
  EXTERNAL_SIAL: 'sial_auth'
}

export const constants = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  RESET_PASSWORD_TOKEN: 'resetPasswordToken',
  REMOTE_SESSION_TOKEN: 'userRemoteToken',
  ATOM_CACHE_TOKEN: 'atomCacheCode',
  USER_AGENT: 'user-agent',
  REMOTE_CONNECTION_NOTIFICATION_ID: 'notificationId',
  REMOTE_CONNECTION_DEVICE_TYPE_NAME: 'deviceTypeName',
  REMOTE_CONNECTION_DEVICE_UUID: 'uuid'
}
const USER_AGENT = 'Milli-Q Connect'
export const removeAccessToken = () => {
  localStorage.removeItem(constants.ACCESS_TOKEN)
}

export const saveAccessToken = accessToken => {
  localStorage.setItem(constants.ACCESS_TOKEN, accessToken)
}

export const loadAccessToken = () => {
  return localStorage.getItem(constants.ACCESS_TOKEN)
}
export const removeRefreshToken = () => {
  localStorage.removeItem(constants.REFRESH_TOKEN)
}

export const userStatusConstants = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  INPROGRESS: 'In Progress',
  INACTIVE: 'InActive',
  EXPIRED: 'Expired'
}

export const removeAtomToken = () => {
  localStorage.remove(constants.ATOM_CACHE_TOKEN)
}

export const saveRefreshToken = (refreshToken) => {
  localStorage.setItem(constants.REFRESH_TOKEN, refreshToken)
}

export const saveResetPasswordToken = (resetPasswordToken) => {
  localStorage.setItem(constants.RESET_PASSWORD_TOKEN, resetPasswordToken)
}

export const checkIfEmailInString = (text) => {
  let re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
  return re.test(text)
}

const addDomain = window?.location?.host === 'www.mymilliq.com' ? window.location.host.split('.')[1] + '.' + window.location.host.split('.')[2] : window?.location?.host

export const setCookie = (key, value, cookies) => {
  cookies.set(key, value, {
    path: '/',
    httpOnly: false,
    secure: true,
    domain:'.' + addDomain,
    maxAge: 86400 //300 // 604800 // expires in 7 days for testing purpose 24 hours
  })
} 
export const saveSessionIdAsCookieForRemoteConnection = (sesId, expiryTime, cookies) => {
  cookies.set(constants.REMOTE_SESSION_TOKEN, sesId, {
    path: '/',
    httpOnly: false,
    secure: true,
    domain:'.' + addDomain,
  })
}
export const removeUserAgentAsCookieForRemoteConnection = (cookies) => {
  if (typeof cookies.remove === 'function') {
    cookies.remove(constants.USER_AGENT)
    cookies.remove(constants.REMOTE_CONNECTION_NOTIFICATION_ID)
    cookies.remove(constants.REMOTE_CONNECTION_DEVICE_UUID)
    cookies.remove(constants.REMOTE_CONNECTION_DEVICE_TYPE_NAME)
    cookies.remove(constants.REMOTE_SESSION_TOKEN)
  } else {
    document.cookie = `${constants.USER_AGENT}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${addDomain}`
    document.cookie = `${constants.REMOTE_CONNECTION_NOTIFICATION_ID}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${addDomain}`
    document.cookie = `${constants.REMOTE_CONNECTION_DEVICE_UUID}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${addDomain}`
    document.cookie = `${constants.REMOTE_CONNECTION_DEVICE_TYPE_NAME}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${addDomain}`
    document.cookie = `${constants.REMOTE_SESSION_TOKEN}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${addDomain}`
  }
}
export const setUserAgentAsCookieForRemoteConnection = (cookies, notificationId, uuid, deviceName) => {
  cookies.set(constants.USER_AGENT, USER_AGENT, {
    path: '/',
    httpOnly: false,
    secure: true,
    domain:'.' + addDomain,
  })
  cookies.set(constants.REMOTE_CONNECTION_NOTIFICATION_ID, notificationId, {
    path: '/',
    httpOnly: false,
    secure: true,
    domain:'.' + addDomain,
  })
  cookies.set(constants.REMOTE_CONNECTION_DEVICE_UUID, uuid, {
    path: '/',
    httpOnly: false,
    secure: true,
    domain:'.' + addDomain,
  })
  cookies.set(constants.REMOTE_CONNECTION_DEVICE_TYPE_NAME, deviceName, {
    path: '/',
    httpOnly: false,
    secure: true,
    domain:'.' + addDomain,
  })
}
export const uiAccessCheck = (privileges = {}) => {
  return privileges.access && Object.values(privileges.access).includes('api')
}
export const IdentifyAppAPIURL = {
  'ncappsource': 'ncapp'
}

