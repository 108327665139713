import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import { fromJS } from 'immutable'

export const constants = {
  FETCH: 'ALARM_ALERT_MANAGEMENT/FETCH',
  FETCH_SUCCESS: 'ALARM_ALERT_MANAGEMENT/FETCH_SUCCESS',
  FETCH_FAILURE: 'ALARM_ALERT_MANAGEMENT/FETCH_FAILURE',
  SET_ALARM_ALERT_FAILURE: 'ALARM_ALERT_MANAGEMENT/SET_ALARM_ALERT_FAILURE',
}

// Action Creators
export const actions = {
  getAlarmAlertConfig (deviceId) {
    return safeFetch({
      args: deviceId,
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'getInitialAlarmAlertInfo',
      onSuccess: ({data}) => ({type: constants.FETCH_SUCCESS, data}),
      onFailure: (error) => ({type: constants.FETCH_FAILURE, error: error})
    })
  }
}

export const initialState = fromJS({
  alarmAlertFetching: false,
  fetchError: {},
  getInitialAlarmAlerts: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('alarmAlertFetching', true)
        .set('fetchError', null)
    case constants.FETCH_SUCCESS:
      return state
        .set('getInitialAlarmAlerts', fromJS(action.data))
        .set('alarmAlertFetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', {error: action.error})
        .set('alarmAlertFetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.alarmAlertManagement

export const selectors = {
  alarmAlertFetching: createSelector(getState, (state) =>
    state.get('alarmAlertFetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  getInitialAlarmAlerts: createSelector(getState, (state) =>
    state.get('getInitialAlarmAlerts')
  )
}
