import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import 'antd/lib/checkbox/style/css'
import './Checkbox.scss'
import { ErrorMessage } from 'formik'

const propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
}

const antCheckbox = ({
  name,
  value,
  handleChange,
  className,
  style,
  errorMessage,
  label,
  ...restProps
}) => (
  <div
    className={`input-div ${className ? className : ''}`}
    style={{ ...style }}
  >
    <Checkbox
      name={name}
      checked={value}
      onChange={handleChange}
      {...restProps}
    >
      {label}
    </Checkbox>
    <span className='error-message checkbox-error'>
      <ErrorMessage name={name} />
    </span>
  </div>
)

antCheckbox.propTypes = propTypes

export default antCheckbox
