import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  SORT: 'CERTIFICATES_STATUS/SORT',
  FETCH: 'CERTIFICATES_STATUS/FETCH',
  RECEIVE: 'CERTIFICATES_STATUS/RECEIVE',
  FETCH_FAILURE: 'CERTIFICATES_STATUS/FETCH_FAILURE',
  FETCH_DETAILS_FAILURE: 'CERTIFICATES_STATUS/FETCH_DETAILS_FAILURE',
  RECEIVE_DEVICE_CERTIFICATE: 'CERTIFICATES_STATUS/RECEIVE_DEVICE_CERTIFICATE',
  RECEIVE_FILTERS: 'CERTIFICATES_STATUS/RECEIVE_FILTERS',
  RECEIVE_DEVICE_CERTIFICATE_DETAILS: 'CERTIFICATES_STATUS/RECEIVE_DEVICE_CERTIFICATE_DETAILS',
  DOWNLOAD_CERTIFICATE_SUCCESS: 'CERTIFICATES_STATUS/DOWNLOAD_CERTIFICATE_SUCCESS',
  CREATE_RENEW_CERTIFICATE_NOTIFICATION_SUCCESS: 'CERTIFICATES_STATUS/CREATE_RENEW_CERTIFICATE_NOTIFICATION_SUCCESS',
  CREATE_RENEW_CERTIFICATE_NOTIFICATION_FAILURE: 'CERTIFICATES_STATUS/CREATE_RENEW_CERTIFICATE_NOTIFICATION_FAILURE',
  DOWNLOAD_CERTIFICATE_FAILURE: 'CERTIFICATES_STATUS/DOWNLOAD_CERTIFICATE_FAILURE',
  FETCH_CERTIFICATE_DETAILS: 'CERTIFICATES_STATUS/FETCH_CERTIFICATE_DETAILS',
  DOWNLOAD_DOCUMENT_SUCCESS: 'CERTIFICATES_STATUS/DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_FAILURE: 'CERTIFICATES_STATUS/DOWNLOAD_DOCUMENT_FAILURE',
  RESET_STATUS: 'CERTIFICATES_STATUS/RESET_STATUS'
}

// Action Creators
export const actions = {
  getCardsFetchQuery(getState, deviceId) {
    const sortBy = selectors.sortColumn(getState())
    const sortDirection = selectors.sortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const query = {
      sortBy,
      orderBy
    }
    return { query, deviceId }
  },
  getFilters() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceCertificateFilters',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_FILTERS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.status })
    })
  },
  fetchDeviceCertificateDetail(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_CERTIFICATE_DETAILS }),
      apiFunction: 'fetchDeviceCertificateDetail',
      args: (state) => {
        return actions.getCardsFetchQuery(state, deviceId)
      },
      onSuccess: ({ data }) => {
        return ({ type: constants.RECEIVE_DEVICE_CERTIFICATE_DETAILS, data })
      },
      onFailure: (error) => ({ type: constants.FETCH_DETAILS_FAILURE, error: error.status })
    })
  },
  createCertificateRenewNotification(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'createRenewCertificateNotification',
      args: deviceId,
      onSuccess: () => ({ type: constants.CREATE_RENEW_CERTIFICATE_NOTIFICATION_SUCCESS }),
      getResponse: true,
      throwError: true,
      onFailure: (error) => ({ type: constants.CREATE_RENEW_CERTIFICATE_NOTIFICATION_FAILURE, error })
    })
  },
  downloadCertificate(certificateType, deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'downlaodDeviceCertificate',
      args: { certificateType, deviceId },
      onSuccess: () => ({ type: constants.DOWNLOAD_CERTIFICATE_SUCCESS }),
      getResponse: true,
      throwError: true,
      onFailure: () => ({ type: constants.DOWNLOAD_CERTIFICATE_FAILURE })
    })
  },
  downLoadDocumentCertificate(docId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'downLoadDocumentCertificate',
      args: { docId },
      onSuccess: () => ({ type: constants.DOWNLOAD_DOCUMENT_SUCCESS }),
      getResponse: true,
      throwError: true,
      onFailure: (error) => ({ type: constants.DOWNLOAD_DOCUMENT_FAILURE, error })
    })
  },
  downloadCertificateIot(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deviceCertificateDownloadIot',
      args: { deviceId },
      onSuccess: () => ({ type: constants.DOWNLOAD_CERTIFICATE_SUCCESS }),
      getResponse: true,
      throwError: true,
      onFailure: (error) => ({ type: constants.DOWNLOAD_CERTIFICATE_FAILURE, error: error.message })
    })
  },
  applySort(sortColumn) {
    return { type: constants.SORT, sortColumn }
  },
  resetStatus(sortColumn) {
    return { type: constants.RESET_STATUS, sortColumn }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  fetchDetailError: null,
  filterCategories: [],
  deviceCertificateData: {},
  sortColumn: '',
  sortDirection: true,
  certificateDownloadError: null,
  documentDownloadError: null,
  documentDownloadSuccess: null,
  renewalSuccess: false,
  renewalFailure: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchDetailError', null)
        .set('fetchError', null)
        .set('renewalFailure', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.FETCH_DETAILS_FAILURE:
      return state
        .set('fetchDetailError', action.error)
        .set('fetching', false)
    case constants.FETCH_CERTIFICATE_DETAILS:
      return state
        .set('fetching', true)
        .set('fetchDetailError', null)
        .set('deviceCertificateData', fromJS({}))
    case constants.RECEIVE_FILTERS:
      return state
        .set('fetching', false)
        .set('filterCategories', parseFilters(action.data))
    case constants.RECEIVE_DEVICE_CERTIFICATE_DETAILS:
      return state
        .set('fetching', false)
        .set('deviceCertificateData', parseDeviceCertificateDetails(action.data))
    case constants.DOWNLOAD_CERTIFICATE_SUCCESS:
      return state
        .set('fetching', false)
    case constants.SORT:
      return state
        .set('sortColumn', action.sortColumn)
        .set('sortDirection', !state.get('sortDirection'))
    case constants.CREATE_RENEW_CERTIFICATE_NOTIFICATION_SUCCESS:
      return state
        .set('fetching', false)
        .set('renewalSuccess', true)
        .set('renewalFailure', null)
    case constants.CREATE_RENEW_CERTIFICATE_NOTIFICATION_FAILURE:
      return state
        .set('fetching', false)
        .set('renewalSuccess', false)
        .set('renewalFailure', action.error)
    case constants.DOWNLOAD_CERTIFICATE_FAILURE:
      return state
        .set('fetching', false)
    case constants.DOWNLOAD_DOCUMENT_SUCCESS:
      return state
        .set('documentDownloadSuccess', true)
        .set('fetching', false)
    case constants.DOWNLOAD_DOCUMENT_FAILURE:
      return state
        .set('documentDownloadError', action.error)
        .set('fetching', false)
    case constants.RESET_STATUS:
      return state
        .set('documentDownloadError', null)
        .set('documentDownloadSuccess', null)
        .set('renewalFailure', null)
        .set('renewalSuccess', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceCertificateStatus

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  fetchDetailError: createSelector(getState, (state) => {
    return state.get('fetchDetailError')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  deviceCertificateData: createSelector(getState, (state) => {
    return state.get('deviceCertificateData')
  }),
  sortColumn: createSelector(getState, (state) => {
    return state.get('sortColumn')
  }),
  sortDirection: createSelector(getState, (state) => {
    return state.get('sortDirection')
  }),
  documentDownloadError: createSelector(getState, (state) => {
    return state.get('documentDownloadError')
  }),
  documentDownloadSuccess: createSelector(getState, (state) => {
    return state.get('documentDownloadSuccess')
  }),
  renewalSuccess: createSelector(getState, (state) => {
    return state.get('renewalSuccess')
  }),
  renewalFailure: createSelector(getState, (state) => {
    return state.get('renewalFailure')
  }),
}

// helper functions
const parseFilters = (filters) => {
  const searchFilters = ['issuedByCa', 'renewalStatus']
  const customInputFields = ['expiry']
  const horizontalFields = ['expiry', 'validCertCount', 'totalCertCount', 'deviceStatus']
  const cutomInputPatterns = {
    'expiry': '^[0-9]{0,4}$'
  }
  const dateRangeFilters = ['renewalStatus']
  // renew status  temporary
  // filters.renewalStatus = ['Success', 'Failure']
  const updatedFilters = Object.keys(filters).map(name => {
    const filterConfig = {
      searchBox: searchFilters.indexOf(name) > -1,
      searchBoxHighlightSelection: true,
      customInputField: customInputFields.indexOf(name) > -1,
      horizontalField: horizontalFields.indexOf(name) > -1,
      customInputPattern: cutomInputPatterns[name],
      dateRange: dateRangeFilters.indexOf(name) > -1
    }

    return {
      categoryType: name === 'expiry' ? 'expiry_within' : name,
      categoryValues: (name === 'deviceStatus') ? filters[name].map(value => ({
        name: value === 'true' ? 'ACTIVE' : 'DEACTIVE',
        label: value === 'true' ? 'ACTIVE' : 'DEACTIVE',
        value: value === 'true' ? `${1}` : `${0}`
      }))
        : (name === 'expiry') ? filters[name].map(value => ({
          name: `${value} days`,
          label: `${value} days`,
          value: `${value.split('<')[1]}`
        }))
          : filters[name].map(value => ({
            name: value,
            label: value,
            value: `${value}`
          })),
      ...filterConfig
    }
  })

  return fromJS(updatedFilters)
}

const parseDeviceCertificateDetails = (data) => {
  data['deviceCertificates'] = data.deviceCertificates.map((data, index) => ({ index: index + 1, ...data }))
  return fromJS(data)
}
