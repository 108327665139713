import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { routerActions } from 'connected-react-router'
import Content from 'components/Content/Content'
import {
  selectors as headerSelector,
  actions as headerAction
} from 'store/modules/Header'
import { selectors as userSelectors, USER_SESSION_EXPIRED } from 'store/modules/User'
import { hashingFunction } from 'utils/userInfoUtils'
import { Redirect } from 'react-router-dom'
import { PATHS } from 'routes'
import { Theme } from '@liquid-design/liquid-design-react'
import { bindActionCreators } from 'redux'
import { userStatusConstants } from 'utils/authUtils'
import injectOverlay from 'shared-components/utils/injectOverlay'
import { injectIntl } from 'react-intl'

const propTypes = {
  user: PropTypes.object,
  userStatus: PropTypes.bool,
  children: PropTypes.node,
  accessTokenFailure: PropTypes.string,
}

class ContentContainer extends Component {
  constructor(props) {
    super(props)
    const { user } = this.props
    let userInfo = user && user.toJS()
    this.hashValue = userInfo && hashingFunction(userInfo.username)
  }

  render() {
    const { children, accessTokenFailure, userStatus } = this.props
    let renderContent = (
      <Theme><Content uid={this.hashValue} {...this.props}>
        {children}
      </Content></Theme>
    )
    if (accessTokenFailure === USER_SESSION_EXPIRED) {
      renderContent = <Theme><Redirect to={PATHS.LOGIN} /></Theme>
    }
    if (userStatus === userStatusConstants.INPROGRESS) {
      renderContent = <Theme><Redirect to={PATHS.CHANGE_PASSWORD} /></Theme>
    }
    return renderContent
  }
}

ContentContainer.propTypes = propTypes

const mapStateToProps = (state, ownProps) => ({
  breadcrumbName: headerSelector.getRouteName(state),
  loggedIn: userSelectors.loggedIn(state),
  permission: userSelectors.getUserPrivilege(state),
  userStatus: userSelectors.getStatus(state),
  user: userSelectors.user(state),
  accessTokenFailure: userSelectors.accessTokenFailure(state),
  handlePush: routerActions.push,
  sidebarToggleIsActive: headerSelector.sidebarToggleIsActive(state),
  fetchingVerticalMenuList: headerSelector.fetchingVerticalMenuList(state),
  verticalMenuList: headerSelector.verticalMenuList(state),
  verticalMenuListError: headerSelector.verticalMenuListError(state),
  fetchingSaveVerticalMenu: headerSelector.fetchingSaveVerticalMenu(state),
  ackSaveVerticalMenu: headerSelector.ackSaveVerticalMenu(state),
  fetchedVerticalMenuList: headerSelector.fetchedVerticalMenuList(state)
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    handleSidebarToggle: (sidebarToggleIsActive) => headerAction.handleSidebarToggle(sidebarToggleIsActive),
    saveVerticalMenu: headerAction.saveVerticalMenu,
    fetchVerticalMenu: headerAction.fetchVerticalMenu,
    resetVerticalMenu: headerAction.resetVerticalMenu
  }, dispatch)
}

const Enhancers = [
  // Reset state on component unmount
  (component) => withRouter(component),
  (component) => injectOverlay(component),
  (component) => injectIntl(component),
  (component) => connect(mapStateToProps, mapDispatchToProps)(component),
]

const EnhancedContainer = Enhancers.reduce(
  (component, enhancer) => enhancer(component),
  ContentContainer
)

export default EnhancedContainer
