import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './DeviceCertificateViewMore.scss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Col, Row, Alert } from 'antd'
import Table from 'components/Table/TableList'
const cx = classNames.bind(styles)
import { fromJS } from 'immutable'
import WithDateFormat from 'containers/withDateFormat/withDateFormat'
import Tooltip from 'components/Tooltip/Tooltip'

const propTypes = {
  deviceCertificateDetails: PropTypes.object,
  deviceCertificateData: PropTypes.object,
  close: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.bool,
  applySort: PropTypes.func,
  fetchDetailError: PropTypes.number,
  fetching: PropTypes.bool,
  intl: PropTypes.object
}
const headers = [
  { header: 'csrSendDate' },
  { header: 'csrSendBy' },
  { header: 'serialNo' },
  { header: 'validFrom' },
  { header: 'validTo' },
  { header: 'issuedByCa' },
  { header: 'status' },
]
const DeviceCertificateViewMore = (props) => {
  const {
    deviceCertificateDetails = {},
    deviceCertificateData,
    fetchDetailError,
    close,
    applySort,
    sortDirection,
    sortColumn,
    fetching
  } = props
  const customizingRowData = (data) => {
    let listing = data?.toJS()
    let mapData =
      listing &&
      listing.map((list) => {
        return {
          ...list,
          csrSendDate:  <WithDateFormat date={list.csrSendDate}/>,
          csrSendBy: <Tooltip title={list.csrSendBy} />,
          serialNo: <Tooltip title={list.serialNo} />,
          validFrom: <WithDateFormat date={list.validFrom}/> ,
          validTo: <WithDateFormat date={list.validTo}/>
        }
      })
    return mapData && fromJS(mapData)
  }
  const { intl } = props
  return (
    <div className={cx('view-more-certificate-details-modal-container')}>
      <div className={cx('view-more-certificate-details-modal-body')}>
        <Row>
          <Col>
            <div className={cx('row')}>
              <div className={cx('view-more-certificate-details-body')}>
                {deviceCertificateDetails && (
                  <div className={cx('view-more-certificate-details-data')}>
                    <Row className={cx('view-more-certificate-details-row')}>
                      <Col
                        className={cx('view-more-certificate-details-row-col')}
                      >
                        <FormattedMessage id='next.DEVICE_NAME' />:{' '}
                        <span className={cx('device-details-value')}>
                          {deviceCertificateDetails.deviceName}
                        </span>
                      </Col>
                      <Col
                        className={cx('view-more-certificate-details-row-col')}
                      >
                        <FormattedMessage id='next.BU' />:{' '}
                        <span className={cx('device-details-value')}>
                          {deviceCertificateData.get('businessUnit')}
                        </span>
                      </Col>
                      <Col
                        className={cx('view-more-certificate-details-row-col')}
                      >
                        <FormattedMessage id='next.SERIAL_NO' />:{' '}
                        <span className={cx('device-details-value')}>
                          {deviceCertificateDetails.serialNo}
                        </span>
                      </Col>
                    </Row>
                    <Row className={cx('view-more-certificate-details-row')}>
                      <Col
                        className={cx('view-more-certificate-details-row-col')}
                      >
                        <FormattedMessage id='next.LOCATION' />:{' '}
                        <span className={cx('device-details-value')}>
                          {deviceCertificateData.get('location')}
                        </span>
                      </Col>
                      <Col>
                        <FormattedMessage id='next.UUID' />:{' '}
                        <span className={cx('device-details-value')}>
                          {deviceCertificateDetails.deviceUuid}
                        </span>
                      </Col>
                    </Row>
                    <Row className={cx('view-more-certificate-details-row')}>
                      <Col
                        className={cx(
                          'view-more-certificate-details-table-row'
                        )}
                      >
                        {fetchDetailError ? (
                          <Alert
                            type='error'
                            message={intl.formatMessage({ id: `next.DEVICE_NOT_FOUND_${fetchDetailError}` })}
                          />
                        ) : (
                          <Table
                            hasHeader
                            data={
                              customizingRowData(deviceCertificateData.get('deviceCertificates')) ||
                              []
                            }
                            headers={headers}
                            handleSort={applySort}
                            sortDirection={sortDirection}
                            fetching={fetching}
                            sortColumn={sortColumn}
                            headersTitle={'DEVICE_CERTIFICATE_MORE_DETAIL_'}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={cx('modal-footer-container')}>
        <Button
          appearance='secondary'
          onClick={() => {
            close()
          }}
          style={{ textTransform: 'uppercase' }}
        >
          <FormattedMessage id='next.CANCEL' />
        </Button>
      </div>
    </div>
  )
}

DeviceCertificateViewMore.propTypes = propTypes

export default injectIntl(DeviceCertificateViewMore)
