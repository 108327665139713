import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'USER_REGISTRATION_CONSOLIDATED/FETCH',
  TOKEN_EXPIRY_CHECK_SUCCESS: 'USER_REGISTRATION_CONSOLIDATED/TOKEN_EXPIRY_CHECK_SUCCESS',
  TOKEN_EXPIRY_CHECK_FAILURE: 'USER_REGISTRATION_CONSOLIDATED/TOKEN_EXPIRY_CHECK_FAILURE',
  RECEIVE_COUNTRIES: 'USER_REGISTRATION_CONSOLIDATED/RECEIVE_COUNTRIES',
  RECEIVE_COUNTRIES_ZONE: 'USER_REGISTRATION_CONSOLIDATED/RECEIVE_COUNTRIES_ZONE',
  FETCH_FAILURE: 'USER_REGISTRATION_CONSOLIDATED/FETCH_FAILURE',
  FETCH_SUCCESS: 'USER_REGISTRATION_CONSOLIDATED/FETCH_SUCCESS',
  FETCH_LANGUAGES_SUCCESS: 'USER_REGISTRATION_CONSOLIDATED/FETCH_LANGUAGES_SUCCESS',
  FETCH_USER_INFO_SUCCESS: 'USER_REGISTRATION_CONSOLIDATED/FETCH_USER_INFO_SUCCESS'
}

// Action Creators
export const actions = {
  resetTokenExpiryCheck (token) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'resetExpiryCheck',
      args: {token},
      onSuccess: ({data: {
        userId,
        firstName,
        lastName,
        loginName,
        email,
        phone,
        country,
        consentStatus,
        privacyPolicyStatus,
        userDomain: {domainId},
        language: {id: languageId}
      }}) =>
      ({ type: constants.TOKEN_EXPIRY_CHECK_SUCCESS,
        user: {
          userId,
          firstName,
          lastName,
          loginName,
          email,
          phone,
          country,
          consentStatus,
          privacyPolicyStatus,
          domainId,
          languageId
        }
      }),
      onFailure: ({status}) => ({ type: constants.TOKEN_EXPIRY_CHECK_FAILURE, status }),
      throwError: true,
      preventRetryAccessToken: true
    })
  },
  loadCountries(country = null) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'getCountriesUserRegistrationConsolidated',
      args: {searchBy: country},
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES, data, country }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.status })
    })
  },
  loadCountriesZone(countryZone = null) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'getCountriesZoneUserRegistrationConsolidated',
      args: {countryId: countryZone},
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES_ZONE, data, countryZone }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  updateUserDetails(userId, user) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'updateUserRegistrationConsolidatedDetails',
      args: {user, userId},
      onSuccess: ({ data }) => ({ type: constants.FETCH_SUCCESS }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.status }),
      throwError: true
    })
  },
  resetPassword(password) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'userRegistrationConsolidatedResetPassword',
      args: {password},
      onSuccess: ({ data }) => ({ type: constants.FETCH_SUCCESS }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.status }),
      throwError: true
    })
  },
  getAllLanguages () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchAllLanguagesUserRegistration',
      onSuccess: ({ data }) => ({ type: constants.FETCH_LANGUAGES_SUCCESS, data }),
      onFailure: ({status}) => ({ type: constants.FETCH_FAILURE, status })
    })
  },
  fetchUserInfo () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchInvitedUserInfo',
      onSuccess: ({ data }) => ({ type: constants.FETCH_USER_INFO_SUCCESS, data }),
      onFailure: ({status}) => ({ type: constants.FETCH_FAILURE, status })
    })
  }
}

// Reducers
export const initialState = fromJS({
  fetching: true,
  tokenExpired: false,
  tokenExpiredStatus: null,
  user: {},
  countries: [],
  countriesZone: [],
  searchedCountries: [],
  searchedCountriesZone: [],
  languages: [],
  userInfo: {}
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCH_SUCCESS:
      return state
        .set('fetching', false)
        .set('fetchError', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.status)
    case constants.TOKEN_EXPIRY_CHECK_SUCCESS:
      return state
        .set('tokenExpired', null)
        .set('fetching', false)
        .set('user', fromJS(action.user))
        .set('fetchError', null)
    case constants.TOKEN_EXPIRY_CHECK_FAILURE:
      return state
        .set('tokenExpired', true)
        .set('fetching', false)
        .set('tokenExpiredStatus', action.status)
    case constants.RECEIVE_COUNTRIES:
      if (action.country) {
        return state
            .set('searchedCountries', fromJS(action.data))
            .set('fetching', false)
            .set('fetchError', null)
      } else {
        return state
            .set('countries', fromJS(action.data))
            .set('fetching', false)
            .set('fetchError', null)
      }
    case constants.RECEIVE_COUNTRIES_ZONE:
      if (action.countryZone) {
        return state
          .set('searchedCountriesZone', fromJS(action.data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      } else {
        return state
          .set('countriesZone', fromJS(action.data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      }
    case constants.FETCH_LANGUAGES_SUCCESS:
      return state
        .set('languages', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_USER_INFO_SUCCESS:
      return state
        .set('userInfo', fromJS(action.data))
        .set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.userRegistrationConsolidated

export const selectors = {
  tokenExpired: createSelector(getState, (state) =>
    state.get('tokenExpired')
  ),
  tokenExpiredStatus: createSelector(getState, (state) =>
    state.get('tokenExpiredStatus')
  ),
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  user: createSelector(getState, (state) =>
    state.get('user')
  ),
  countries: createSelector(getState, (state) =>
    state.get('countries')
  ),
  countriesZone: createSelector(getState, (state) =>
    state.get('countriesZone')
  ),
  searchedCountries: createSelector(getState, (state) =>
    state.get('searchedCountries')
  ),
  searchedCountriesZone: createSelector(getState, (state) =>
    state.get('searchedCountriesZone')
  ),
  getAllLanguages: createSelector(getState, (state) =>
    state.get('languages')
  ),
  userInfo: createSelector(getState, (state) =>
    state.get('userInfo')
  )
}
