export const moduleId = 'feedbackDefaulters'

export const documentType = {
  DOCUMENT_TYPE_ID: 1
}
export const filterTypes = {
  'COUNTRY_CODE': 'countryCode',
  'CUSTOMER_ORGANIZATION': 'cusorg',
  'ROLES': 'roles',
  'SHOW_OLD_FEEDBACK': 'oldFeedBack'
}
