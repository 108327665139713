import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
// import { debounce } from 'utils/formUtils'
import CustomInput from './CustomInput'
import { Checkbox } from '@liquid-design/liquid-design-react'
import styles from './CheckBoxFilter.scss'
import { FormattedMessage } from 'react-intl'

const cx = classNames.bind(styles)

/** Component support Group CheckBox & Custom Input. */
class CheckBoxFilter extends Component {
  constructor() {
    super()
    this.customFieldValue = ''
    this.handleCustomFieldChange = this._handleCustomFieldChange
  }

  UNSAFE_componentWillMount(){
    const { selectedFilterValues } = this.props
    selectedFilterValues?.length && selectedFilterValues.forEach((value) => {
      let filterCategory = value?.split('.')[0]
      if(filterCategory === 'expiry')
        this.customFieldValue = value?.split('.')[1]
    })
  }

  /**
    This method is for temporary, will be removed in future
    filterCategory 'expiry' is renamed as 'expiry_within' at store

    This method should be removed once the usage of 'expiry_within'
    gets removed.

    @return {string} categoryType
  */
  _getCategoryType() {
    const { category: { categoryType } } = this.props

    return (categoryType === 'expiry_within')
      ? 'expiry'
      : categoryType
  }

  /**
    This method creates string in format `categoryType.categoryValue`

    catgeoryValue from store are prefixed with categoryType, this method
    is used to convert values from custom modal to categoryValue format

    @return {string} categoryValue
  */
  _createCategoryValue(value) {
    return `${this._getCategoryType()}.${value}`
  }

  /**
    This method check given string is a categoryValue that is used in CheckBox
    @param {string} customValue
    @return {boolean}
  */
  _isCheckBoxValue(customValue) {
    const { category: { categoryValues } } = this.props

    return categoryValues.findIndex(({value: checkBoxValue}) => {
      return checkBoxValue === customValue
    }) > -1
  }

  /**
    This method is used to handle onChange event in custom modal field.

    1. save value latest value from custom modal field
    2. updates the store: (i) add new filter & (ii) remove old filter value

    @param {string} newValue
    @return {undefined}
  */
  _handleCustomFieldChange = (newValue) => {
    const {
      addfilterCatogory: toggleCategoryValue,
      selectedFilterValues, setCurrentPage
    } = this.props
    const { customFieldValue: prevValue } = this
    let shallUpdateStore = false

    // No update is required if previous value is empty string
    // When text field is cleared previous value should be removed
    // on each valid new value, previous value fromstore should be removed
    if (prevValue.trim() !== '' || newValue.trim() === '') {
      const value = this._createCategoryValue(prevValue)
      const hasReference = this._isCheckBoxValue(value)

      // If the previous value is defined or from api or used in checkbox
      // then state update is not required
      if (!hasReference) {
        toggleCategoryValue(null, value)
        shallUpdateStore = true
      }
    }

    if (newValue.trim() !== '') {
      const value = this._createCategoryValue(newValue)
      // If the new value is already selected in checkbox no need to update
      // NOTE: addfilterCatogory method removes values if it exist.
      if (selectedFilterValues.indexOf(value) === -1) {
        toggleCategoryValue(null, value)
        shallUpdateStore = true
      }
    }

    if (shallUpdateStore) {
      setCurrentPage(1)
      // reLoadData()
    }

    this.customFieldValue = newValue
  }

  /**
    This mehtod is used to handle CheckBox change event

    @param {string} value
    @param {bool} checked
    @return {undefined}
  */
  handleCheckBoxChange = (value, checked) => {
    const {
      addfilterCatogory: toggleCategoryValue,
      setCurrentPage, handleClose, category
    } = this.props
    const { customInputField } = category
    let shallUpdateStore = false

    // checked `false` should not update store If custom modal has same value.
    // NOTE: no need to consider checked `true`, checkbox is set `true` when
    // same value is set in custom modal
    if (!customInputField) {
      shallUpdateStore = true
    } else {
      const { customFieldValue } = this
      shallUpdateStore = this._createCategoryValue(customFieldValue) !== value
    }

    if (shallUpdateStore) {
      toggleCategoryValue(null, value)
      setCurrentPage(1)
    }

    if (handleClose) {
      handleClose()
    }
  }

  /**
    This method is used to handle checkbox change event in custom modal
    reference value saved in instance have to cleared when unchecked

    @param {boolean} - custom modal checkbox value
  */
  handleCustomCheckBoxChange = (checked) => {
    // remove entered value
    if (!checked) {
      const { customFieldValue: value } = this
      if (value.trim() === '') return

      const {
       addfilterCatogory: toggleCategoryValue
      } = this.props
      const categoryValue = this._createCategoryValue(value)
      const hasReference = this._isCheckBoxValue(
        this._createCategoryValue(value)
      )

      // if the custom modal value is same in CheckBox,no need to update store.
      if (!hasReference) {
        toggleCategoryValue(null, categoryValue)
        // reloadList()
      }
    }
  }

  render() {
    const {
      category, onlyFilterValue, showCount, selectedFilterValues
    } = this.props
    const { customInputField, customInputPattern } = category

    return (
      <div
        key={`${category.categoryType}`}
        className={cx('checkbox-filter',
        { 'horizontal': category.horizontalField })}>
        {category.categoryValues.map((categoryValue, index) => {
          const value = `${onlyFilterValue ? category.categoryType + '.' : ''}${categoryValue.value}`
          const checked = selectedFilterValues?.indexOf(value) > -1
          const label = `${categoryValue.name} ${showCount ? `(${categoryValue.count})` : ''}`
          return (
            <div
              className={customInputField && cx('checkbox-wrap')}
              key={`${category.categoryType}_${index}`}>
              <Checkbox
                label={categoryValue.translation ? <FormattedMessage id={`next.FILTER_LABEL_${categoryValue.name}`} /> : label}
                isChecked={checked}
                onChange={(item) => this.handleCheckBoxChange(value, item)} />
            </div>
          )
        })}
        {customInputField && (
          <div className={cx('checkbox-wrap', 'custom')}>
            <CustomInput
              
              pattern={customInputPattern}
              onCheckBoxChange={this.handleCustomCheckBoxChange}
              onInputChange={this.handleCustomFieldChange}
              customFieldValue={this.customFieldValue} />
          </div>
        )}
      </div>
    )
  }
}

CheckBoxFilter.propTypes = {
  showCount: PropTypes.bool,
  onlyFilterValue: PropTypes.bool,
  category: PropTypes.object,
  setCurrentPage: PropTypes.func,
  handleClose: PropTypes.func,
  selectedFilterValues: PropTypes.array,
  loadList: PropTypes.func,
  addfilterCatogory: PropTypes.func
}

export default CheckBoxFilter
