import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  actions,
  selectors
} from 'store/modules/OrganizationDetails'
import {
  selectors as userSelectors
} from 'store/modules/User'

const withStateSubscription = (FormComponent, formName) => {
  class ComponentWrapper extends Component {
    render () {
      return (
        <FormComponent {...this.props} />
      )
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    brandValues: selectors.brandValues(state),
    orgTypes: selectors.orgTypes(state),
    fetchPartnerTypeError: selectors.fetchPartnerTypeError(state),
    permission: userSelectors.getUserPrivilege(state)?.toJS(),
    getPeripheralPartnerType: selectors.getPeripheralPartnerType(state)
  })

  const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getBrandValues: actions.getBrandValuesByPartnerType,
    fetchOrganizationTypes: actions.fetchOrganizationTypes,
    fetchPeripheralPartnerTypes: actions.fetchPeripheralPartnerTypes
  }, dispatch)

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWrapper)
}

export default withStateSubscription
