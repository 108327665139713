import { combineReducers } from 'redux'
import auth from 'store/modules/Auth'
import user from 'store/modules/User'
import about from 'store/modules/About'
import userRegistration from 'store/modules/UserRegistration'
import resetPassword from 'store/modules/ResetPassword'
import translation from 'store/modules/Translation'
import genericListing from 'store/modules/GenericListing'
import pagination from 'store/modules/Pagination'
import globalAlertsAndAlarms from 'store/modules/GlobalAlertsAndAlarms'
import customerTech from 'store/modules/CustomerTech'
import customerQualityAlerts from 'store/modules/CustomerQuality'
import partnerAdminDashboard from 'store/modules/PartnerAdminDashboard'
import deviceDetails from 'store/modules/DeviceDetails'
import deviceRegistry from 'store/modules/DeviceRegistry'
import deviceProfile from 'store/modules/DeviceProfile'
import deviceRemoteConnectivity from 'store/modules/DeviceRemoteConnectivity'
import alarmAlertManagement from 'store/modules/AlarmAlertManagement'
import deviceInteraction from 'store/modules/DeviceInteraction'
import fileManagement from 'store/modules/FileManagement'
import header from 'store/modules/Header'
import globalOverlay from 'store/modules/GlobalOverlay'
import organizationDetails from 'store/modules/OrganizationDetails'
import users from 'store/modules/Users'
import privileges from 'store/modules/Privileges'
import deviceCertificateStatus from 'store/modules/DeviceCertificateStatus'
import deviceNotificationStatus from 'store/modules/DeviceNotificationStatus'
import orgUsers from 'store/modules/OrgUsers'
import { constants as genericConstants } from 'store/modules/Generic'
import cards from 'store/modules/Cards'
import manageCard from 'store/modules/ManageCard'
import editCard from 'store/modules/EditCard'
import cardsCreation from 'store/modules/CardsCreation'
import contactHotlineDashboard from 'store/modules/ContactHotlineDashboard'
import userProfile from 'store/modules/UserProfile'
import userNotificationPreferences from 'store/modules/UserNotificationPreferences'
import deviceSoftwareUpdate from 'store/modules/DeviceSoftwareUpdate'
import deviceSoftwareUpload from 'store/modules/DeviceSoftwareUpload'
import deviceSoftwareValidate from 'store/modules/DeviceSoftwareValidate'
import devices from 'store/modules/Devices'
import partnerPeripheral from 'store/modules/PartnerPeripheral'
import withSubmitForm from 'store/modules/WithSubmitForm'
import newsFeed from 'store/modules/NewsFeed'
import manageDeviceType from 'store/modules/ManageDeviceType'
import customerDevices from 'store/modules/CustomerDevices'
import deviceAccessAction from 'store/modules/DeviceAccessAction'
import { createBrowserHistory } from 'history'
import userRegistrationConsolidated from 'store/modules/UserRegistrationConsolidated'
import { reducer as form } from 'redux-form'
import deviceValidateSoftware from 'store/modules/DeviceValidateSoftware'
import ratingAndImprovements from 'store/modules/RatingAndImprovements'
import feedbackDefaulters from 'store/modules/FeedbackDefaulters'
import userFeedBackReport from 'store/modules/UserFeedBackReport'
import customerDashboardTooltip from 'store/modules/CustomerDashboardTooltip'
import commercialReport from 'store/modules/GenerateCommercialReport'
import newsCategory from 'store/modules/NewsCategory'
import serviceContract from 'store/modules/ServiceContract'
import serviceContractDetail from 'store/modules/ServiceContractDetail'
import dataAnalysis from 'store/modules/DataAnalysis'
import softwareUpgrade from 'store/modules/SoftwareUpgrade'
import remoteControlAnalysis from 'store/modules/RemoteControlAnalysis'
import howTo from 'store/modules/HowTo'
import iotConnectionAnalysis from 'store/modules/IotConnectionAnalysis'


// external reducers
import { connectRouter } from 'connected-react-router'

const history = createBrowserHistory()
const defaultLanguage = 'en'
const appReducer = combineReducers({
  router: connectRouter(history),
  auth,
  user,
  about,
  userRegistration,
  resetPassword,
  translation,
  genericListing,
  pagination,
  globalAlertsAndAlarms,
  customerTech,
  customerQualityAlerts,
  partnerAdminDashboard,
  deviceDetails,
  deviceNotificationStatus,
  deviceRegistry,
  deviceProfile,
  deviceRemoteConnectivity,
  alarmAlertManagement,
  header,
  deviceInteraction,
  fileManagement,
  globalOverlay,
  organizationDetails,
  users,
  privileges,
  orgUsers,
  cards,
  manageCard,
  cardsCreation,
  editCard,
  contactHotlineDashboard,
  userProfile,
  deviceSoftwareUpdate,
  deviceSoftwareUpload,
  deviceSoftwareValidate,
  deviceCertificateStatus,
  devices,
  partnerPeripheral,
  withSubmitForm,
  newsFeed,
  manageDeviceType,
  userRegistrationConsolidated,
  form,
  customerDevices,
  deviceValidateSoftware,
  ratingAndImprovements,
  feedbackDefaulters,
  userNotificationPreferences,
  userFeedBackReport,
  commercialReport,
  customerDashboardTooltip,
  newsCategory,
  serviceContract,
  serviceContractDetail,
  dataAnalysis,
  softwareUpgrade,
  remoteControlAnalysis,
  howTo,
  iotConnectionAnalysis,
  deviceAccessAction
})

let capitalize = (string) => {
  let stringArray = string.split('')
  stringArray[0] = stringArray[0].toUpperCase()
  return stringArray.join('')
}

export const rootReducer = (state, action) => {
  if (action.type === genericConstants.RESET_ALL_STATE) {
    const { router, translation } = state
    if (translation.get('locale') === defaultLanguage) {
      state = { router, translation }
    } else {
      state = { router }
    }
  }
  if (action.type === genericConstants.RESET_STATE) {
    const { moduleName } = action
    const module = require(`store/modules/${moduleName && capitalize(moduleName)}`)
    const initialState = module.initialState

    state[moduleName] = initialState || {}
  }
  return appReducer(state, action)
}

export default rootReducer
