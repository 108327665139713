import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DragMenus } from './DragMenu'
import './DragMenu.scss'

export const VerticalBarWrapper = props => {
    const { toggleDragMenu, extStyle } = props
    const [isActive, updateIsActive] = useState(false)
    const onClickMenus = () => {
        updateIsActive(!isActive)
        toggleDragMenu()
    }
    
    return (
        <div style={extStyle} className='vertical-dropdown'>
            <span className={`vertical-collapse-button`} onClick={onClickMenus}>...</span>
            <DragMenus {...props} isActive={isActive} toggleDragMenu={onClickMenus} />
        </div>
    )
}

VerticalBarWrapper.propTypes = {
    toggleDragMenu: PropTypes.func,
    extStyle: PropTypes.object
}