import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

// Constants
export const constants = {
  SET_CURRENT_PAGE: 'PAGINATION/SET_CURRENT_PAGE',
  SET_PAGE_LIMIT: 'PAGINATION/SET_PAGE_LIMIT',
  RESET_PAGE_LIMIT: 'PAGINATION/RESET_PAGE_LIMIT',
  RESET: 'PAGINATION/RESET'
}
const PAGE_LIMIT = 10

// Action Creators
export const actions = {
  setCurrentPage (page, paginationName) {
    return { type: constants.SET_CURRENT_PAGE, page, paginationName }
  },
  resetPage (paginationName) {
    return { type: constants.RESET, paginationName }
  },
  setPerPageLimit (limit, paginationName) {
    return { type: constants.SET_PAGE_LIMIT, limit, paginationName }
  },
  resetPerPageLimit () {
    return { type: constants.RESET_PAGE_LIMIT }
  }
}

// Reducer
export const initialState = fromJS({
  pagination: {},
  perPage: PAGE_LIMIT
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.SET_CURRENT_PAGE:
      return state
        .setIn(['pagination', action.paginationName, 'currentPage'], action.page)
    case constants.RESET:
      return state
        .setIn(['pagination', action.paginationName, 'currentPage'], 1)
    case constants.SET_PAGE_LIMIT:
      return state
        .set('perPage', action.limit)
        .setIn(['pagination', action.paginationName, 'perPage'], action.limit)
    case constants.RESET_PAGE_LIMIT:
      return state
        .set('perPage', PAGE_LIMIT)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.pagination

export const selectors = {
  pagination: createSelector(getState, (state) => {
    return state.get('pagination')
  }),
  perPage: createSelector(getState, (state) => {
    return state.get('perPage')
  })
}
