import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { isString, isObject } from 'utils/dataUtils'
import { debounce } from 'utils/formUtils'
import classNames from 'classnames/bind'
import AntDSelect from 'antd/lib/select'
import 'antd/lib/select/style/css'
import styles from './SearchFilter.scss'

const cx = classNames.bind(styles)
const Option = AntDSelect.Option

class SearchFilter extends Component {
  constructor(props) {
    super()

    this.state = {
      values: [],
      searchResults: []
    }
  }

  _updateValues (values) {
    this.setState({ values })
  }

  _createCategoryValue (value) {
    const { category: { categoryType, categoryParam } } = this.props

    return !categoryParam
      ? `${categoryType}.${value}`
      : `${categoryParam}.${value}`
  }

  handleSearch = async(searchString) => {
    const { category, fetchFacetValues } = this.props

    if (!(isString(searchString) && searchString.trim().length >= category.minSearchCharacter)) {
      this.setState({
        searchResults: []
      })
    } else {
      try {
        const response = await fetchFacetValues({
          field: category.categoryType,
          searchBy: searchString
        })

        if (isObject(response) && response.data && response.data.fieldValues) {
          this.setState({
            searchResults: response.data.fieldValues
              .map(value => ({
                name: value,
                label: value,
                value: value
              }))
          })
        }
      } catch (e) {
        this.setState({
          searchResults: []
        })
      }
    }
  }

  handleSelection = async(newValues, options) => {
    const {
      handleClose, setCurrentPage, addfilterCatogory, loadList,
      shouldBlockFetch, category, handheldDevice
    } = this.props
    const { values: prevValues } = this.state
    const VALUE_CLEARED = newValues.length === 0 && prevValues.length > 0
    const VALUE_SELECTED = newValues.length > prevValues.length

    if (VALUE_CLEARED) {
      addfilterCatogory(null,
        prevValues.map(value => this._createCategoryValue(value)))
      setCurrentPage(1)
      loadList(handheldDevice, shouldBlockFetch(category.categoryType, null))

      this._updateValues([])
    } else {
      let selectedValue

      if (VALUE_SELECTED) {
        selectedValue = newValues[newValues.length - 1]
      } else {
        selectedValue = prevValues
          .find(value => newValues.indexOf(value) === -1)
      }

      addfilterCatogory(null, this._createCategoryValue(selectedValue))
      setCurrentPage(1)
      this._updateValues(newValues)

      loadList(handheldDevice, shouldBlockFetch(category.categoryType, selectedValue))

      if (handleClose) {
        handleClose()
      }
    }
  }

  handleBlur = (searchString) => {
    if (!(isString(searchString) && searchString.trim().length > 2)) {
      this.setState({
        searchResults: []
      })
    }
  }

  handleFilterOption = (input, option) => {
    const { props: { children } } = option

    if (children) {
      return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return false
  }

  render() {
    const { category, modeOfSelect, disableSearchFilter } = this.props

    const placeholder = (
      <FormattedMessage
        id={`next.LISTING_FILTER_SECTION_SEARCH_TYPE_PLACEHOLDER_${category.categoryType}`} />
    )

    return (
      <div
        key={`${category.categoryType}`}
        className={cx({'highlight-selection': category.searchBoxHighlightSelection})}>
        <AntDSelect
          mode={modeOfSelect}
          disabled={disableSearchFilter}
          style={{width: '100%'}}
          value={this.state.values}
          placeholder={placeholder}
          name={category.categoryType}
          onBlur={this.handleBlur}
          onSearch={debounce(this.handleSearch, 500)}
          onChange={this.handleSelection}
          optionFilterProp='children'
          notFoundContent={<FormattedMessage id='next.SELECT_COMP_NO_RESULTS_FOUND' />}
          filterOption={this.handleFilterOption}>
          {this.state.searchResults.map(({label, value}) => <Option key={value}>{label}</Option>)}
        </AntDSelect>
      </div>
    )
  }
}

SearchFilter.propTypes = {
  modeOfSelect: PropTypes.string,
  category: PropTypes.object,
  fetchFacetValues: PropTypes.func,
  handleClose: PropTypes.func,
  loadList: PropTypes.func,
  setCurrentPage: PropTypes.func,
  addfilterCatogory: PropTypes.func,
  disableSearchFilter: PropTypes.bool,
  handheldDevice: PropTypes.bool,
  shouldBlockFetch: PropTypes.func
}

SearchFilter.defaultProps = {
  modeOfSelect: 'multiple'
}

export default SearchFilter
