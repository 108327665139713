import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}
const UserProfileSettingSvg = (props) => {
  const { className, width, height } = props
  return (
    <svg
      className={className}
      width={`${width}`}
      height={`${height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 25.41'
    >
      <defs></defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
             style={{fill:'#afafaf',stroke:'#afafaf',strokeMiterlimit:'10',strokeWidth:'0.5px', ...props.style}}
            d='M11,3.84a.35.35,0,0,0,.35-.34V2A1.74,1.74,0,0,0,9.64.25H2A1.74,1.74,0,0,0,.25,2V14.51A1.74,1.74,0,0,0,2,16.25H9.64a1.74,1.74,0,0,0,1.74-1.74V13a.35.35,0,0,0-.7,0v1.51a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V2A1,1,0,0,1,2,1H9.64a1,1,0,0,1,1,1V3.5A.35.35,0,0,0,11,3.84Z'
          />
          <path
             style={{fill:'#afafaf',stroke:'#afafaf',strokeMiterlimit:'10',strokeWidth:'0.5px', ...props.style}}
            d='M15.9,7.9H4.77a.35.35,0,0,0,0,.7H15.9a.35.35,0,0,0,0-.7Z'
          />
          <path
             style={{fill:'#afafaf',stroke:'#afafaf',strokeMiterlimit:'10',strokeWidth:'0.5px', ...props.style}}
            d='M16.15,8,14.06,5.92a.35.35,0,0,0-.49.49l1.84,1.84-1.84,1.84a.34.34,0,0,0,0,.49.35.35,0,0,0,.25.1.34.34,0,0,0,.24-.1L16.15,8.5A.36.36,0,0,0,16.15,8Z'
          />
        </g>
      </g>
    </svg>
  )
}
UserProfileSettingSvg.defaultProps= {
  width: '20',
  height: '20'
}
UserProfileSettingSvg.propTypes = propTypes
export default UserProfileSettingSvg
