import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  actions as userProfileActions
} from 'store/modules/UserProfile'
export const constants = {
  FETCH: 'COMMERCIAL_REPORT/FETCH',
  FETCH_FAILURE: 'COMMERCIAL_REPORT/FETCH_FAILURE',
  DEVICE_TYPES_SUCCESS: 'COMMERCIAL_REPORT/DEVICE_TYPES_SUCCESS',
  DEVICE_TYPES_FAILURE: 'COMMERCIAL_REPORT/DEVICE_TYPES_FAILURE',
  FETCH_USER_TERRITORIES_SUCCESS: 'COMMERCIAL_REPORT/FETCH_USER_TERRITORIES_SUCCESS',
  FETCH_USER_TERRITORIES_FAILURE: 'COMMERCIAL_REPORT/FETCH_USER_TERRITORIES_FAILURE',
  GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS: 'COMMERCIAL_REPORT/GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS',
  GENERATE_DEVICE_SOFTWARE_REPORT_FAILURE: 'COMMERCIAL_REPORT/GENERATE_DEVICE_SOFTWARE_REPORT_FAILURE',
  RECEIVE_ORGNIZATIONS: 'COMMERCIAL_REPORT/RECEIVE_ORGNIZATIONS',
  RESET_FETCH_COUNTRY_ORGANIZATION: 'COMMERCIAL_REPORT/RESET_FETCH_COUNTRY_ORGANIZATION',
  RESET_SOFTWARE_REPORT_STATUS: 'COMMERCIAL_REPORT/RESET_SOFTWARE_REPORT_STATUS'
}

// Action Creators
export const actions = {
  fetchDeviceTypes () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getDeviceTypes',
      onSuccess: ({ data }) => {
        return ({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes: data })
      },
      onFailure: (error) => ({ type: constants.DEVICE_TYPES_FAILURE, error: error })
    })
  },
  fetchUserTerritories (userId) {
    return async (dispatch, getState) => {
      try {
        await dispatch({ type: constants.FETCH })
        const { data: territories } = await dispatch(userProfileActions.fetchUserTerritories(userId))
        const { data: countries } = await dispatch(userProfileActions.searchTerritories())
        const data = territories.map(({ resourceId }) => {return countries.find(({ id }) => id === resourceId)})
        dispatch({ type: constants.FETCH_USER_TERRITORIES_SUCCESS, data })
      } catch (error) {
        dispatch({ type: constants.FETCH_USER_TERRITORIES_FAILURE, error: error.toString() })
      }
    }
  },
  fetchCountryOrganizations (data) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'fetchCountryOrganizations',
        getResponse: true,
        args: data,
        onSuccess: ({ data }) => ({ type: constants.RECEIVE_ORGNIZATIONS, data }),
        onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error })
      }))
      return resp.data
    }
  },
  generateSoftwareReport (payload) {
    return safeFetch({
      apiFunction: 'generateCommercialReport',
      args: payload,
      throwError: true,
      onSuccess: (data) => ({type: constants.GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS, data}),
      onFailure: (error) => ({type: constants.GENERATE_DEVICE_SOFTWARE_REPORT_FAILURE, error})
    })
  },
  resetFetchCountryOrganizationError () {
    return ({type: constants.RESET_FETCH_COUNTRY_ORGANIZATION})
  },
  resetSwReportStatus () {
    return ({type: constants.RESET_SOFTWARE_REPORT_STATUS})
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  openUploadFileForm: false,
  fetchError: null,
  fetchFilterError: null,
  fetchingFilters: false,
  filterCategories: [],
  deviceTypes: null,
  serialNumber: [],
  deviceSoftwareUploadList: [],
  swUpdateSuccess: false,
  userTerritories: [],
  cancelSoftwareUpdateSuccess: false,
  pollingShouldStart: true,
  pollingShouldContinue: false,
  pollTimerId: null,
  listingDevices: [],
  deviceUpdateStatus: null,
  fetchSerialNoError: null,
  categoriesId: [],
  fetchingCategoryErr: null,
  organizations: [],
  swReportSuccess: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
      .set('fetching', true)
      .set('fetchError',null)
    case constants.FETCH_FAILURE:
      return state
      .set('fetchError', action.error)
      .set('fetching', false)
      .set('fetchingFilters', false)
    case constants.DEVICE_TYPES_SUCCESS:
      return state
      .set('deviceTypes', fromJS(action.deviceTypes))
      .set('fetching', false)
    case constants.FETCH_USER_TERRITORIES_SUCCESS:
      return state
      .set('fetching', false)
      .set('userTerritories', action.data)
    case constants.GENERATE_DEVICE_SOFTWARE_REPORT_SUCCESS:
      return state
      .set('fetching', false)
      .set('swReportSuccess', 'SUCCESS')
    case constants.RESET_SOFTWARE_REPORT_STATUS:
      return state
      .set('fetching', false)
      .set('swReportSuccess', null)
    case constants.RECEIVE_ORGNIZATIONS:
      return state
      .set('fetchingCustomerOrg', false)
      .set('organizations', action.data)
      .set('fetchError', null)
      .set('fetchingFilters', false)
    case constants.RESET_FETCH_COUNTRY_ORGANIZATION:
      return state
      .set('fetchError', null)
      .set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.commercialReport

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  deviceTypes: createSelector(getState, (state) => {
    return state.get('deviceTypes')
  }),
  openUploadFileForm: createSelector(getState, (state) =>
    state.get('openUploadFileForm')
  ),
  fetchFilterError: createSelector(getState, (state) => {
    return state.get('fetchFilterError')
  }),
  fetchingFilters: createSelector(getState, (state) => {
    return state.get('fetchingFilters')
  }),
  userTerritories: createSelector(getState, (state) => {
    return state.get('userTerritories')
  }),
  getSerialNumber: createSelector(getState, (state) => {
    return state.get('serialNumber')
  }),
  deviceSoftwareUploadList: createSelector(getState, (state) => {
    return state.get('deviceSoftwareUploadList')
  }),
  swUpdateSuccess: createSelector(getState, (state) => {
    return state.get('swUpdateSuccess')
  }),
  cancelSoftwareUpdateSuccess: createSelector(getState, (state) => {
    return state.get('cancelSoftwareUpdateSuccess')
  }),
  cancelUpdateError: createSelector(getState, (state) => {
    return state.get('cancelUpdateError')
  }),
  pollingShouldContinue: createSelector(getState, (state) =>
    state.get('pollingShouldContinue')
  ),
  pollingShouldStart: createSelector(getState, (state) =>
    state.get('pollingShouldStart')
  ),
  pollTimerId: createSelector(getState, (state) =>
    state.get('pollTimerId')
  ),
  listingDevices: createSelector(getState, (state) =>
    state.get('listingDevices')
  ),
  deviceUpdateStatus: createSelector(getState, (state) =>
    state.get('deviceUpdateStatus')
  ),
  fetchSerialNoError: createSelector(getState, (state) => {
    return state.get('fetchSerialNoError')
  }),
  categoriesId: createSelector(getState, (state) => {
    return state.get('categoriesId')
  }),
  fetchingCategoryErr: createSelector(getState, (state) => {
    return state.get('fetchingCategoryErr')
  }),
  getCountryWiseOrganizations: createSelector(getState, (state) =>
    state.get('organizations')
  ),
  swReportSuccess: createSelector(getState, (state) =>
    state.get('swReportSuccess')
  )
}
