import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
const DeviceCertSvg = (props) => {
  const { className } = props
  return (
    <svg
      className={className}
      width={`${props.width}`}
      height={`${props.height}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 18.86">
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: '#afafaf', ...props.style }}
            d="M1.47,7A5.53,5.53,0,1,0,7,1.47,5.54,5.54,0,0,0,1.47,7ZM7,2.21A4.79,4.79,0,1,1,2.21,7,4.8,4.8,0,0,1,7,2.21Z"/>
          <path
            style={{ fill: '#afafaf', ...props.style }}
            d="M3.59,6.34,5,7.75l-.33,2a.36.36,0,0,0,.14.36.39.39,0,0,0,.39,0L7,9.19l1.78.93a.39.39,0,0,0,.39,0,.36.36,0,0,0,.14-.36L9,7.75l1.43-1.41a.37.37,0,0,0-.2-.62l-2-.3-.9-1.8a.37.37,0,0,0-.66,0l-.9,1.8-2,.3a.37.37,0,0,0-.2.62Zm2.48-.22a.39.39,0,0,0,.28-.2L7,4.61l.65,1.31a.39.39,0,0,0,.28.2l1.44.22-1,1a.37.37,0,0,0-.1.32l.23,1.44L7.17,8.45a.38.38,0,0,0-.34,0l-1.29.67.23-1.44a.34.34,0,0,0-.1-.32l-1-1Z"/>
          <path style={{ fill: '#afafaf', ...props.style }} d="M7.37.37a.37.37,0,0,1-.74,0,.37.37,0,0,1,.74,0Z"/>
          <path
            style={{ fill: '#afafaf', ...props.style }}
            d="M5.26.22a7,7,0,0,0-3,12L.08,16.1a.36.36,0,0,0,0,.4.38.38,0,0,0,.37.15L3,16.12l.89,2.5a.36.36,0,0,0,.31.24.37.37,0,0,0,.35-.18L7,14.38l2.45,4.3a.36.36,0,0,0,.32.18h0a.36.36,0,0,0,.31-.24l.89-2.5,2.52.53a.38.38,0,0,0,.37-.15.36.36,0,0,0,0-.4L11.7,12.19a7,7,0,0,0-3-12,.37.37,0,0,0-.45.26.37.37,0,0,0,.26.45,6.26,6.26,0,1,1-3.1,0A.37.37,0,0,0,5.71.48.37.37,0,0,0,5.26.22Zm-1,17.39-.72-2a.38.38,0,0,0-.42-.24l-2,.43,1.75-3.1A7,7,0,0,0,6.38,14Zm6.52-2.28a.38.38,0,0,0-.42.24l-.72,2L7.62,14a7,7,0,0,0,3.5-1.31l1.75,3.1Z"/>
        </g>
      </g>
    </svg>
  )
}
DeviceCertSvg.defaultProps= {
  width: '20',
  height: '20'
}
DeviceCertSvg.propTypes = propTypes
export default DeviceCertSvg