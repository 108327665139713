import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { colors, sizes, textAlign, getTypeValues } from '../types'
import { Link } from 'react-router-dom'

const alertTypes = {
  DEFAULT: 'default',
  OUTLINE: 'outline'
}

const propTypes = {
  alertType: PropTypes.oneOf(getTypeValues(alertTypes)),
  size: PropTypes.oneOf(getTypeValues(sizes)),
  color: PropTypes.oneOf(getTypeValues(colors)),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  textAlign: PropTypes.oneOf(getTypeValues(textAlign)),
  fullWidth: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node
}

const defaultProps = {
  alertType: alertTypes.DEFAULT,
  color: colors.PRIMARY,
  textAlign: textAlign.CENTER
}

const Alert = (props) => {
  const {
    alertType,
    color,
    size,
    text,
    textAlign,
    fullWidth,
    isLink,
    href,
    icon,
    ...wrapperProps
  } = props

  const componentClass = cx(
    'alert',
    `text-align-${textAlign}`,
    {
      'alert-full-width': fullWidth,
      [`alert-${size}`]: !!size,
      [`alert-outline-${color}`]: alertType === alertTypes.OUTLINE,
      [`alert-${color}`]: alertType === alertTypes.DEFAULT
    }
  )

  const alert = (
    <div className={componentClass} {...wrapperProps}>
      {icon}
      {text}
    </div>
  )
  // Return Alert as Link
  if (isLink) {
    return (
      <Link path={href} noDecoration to={href}>
        {alert}
      </Link>
    )
  }
  // Return Alert
  return alert
}

Alert.propTypes = propTypes
Alert.defaultProps = defaultProps

export default Alert
