export const deviceTypes = {
  LABWATER: 'labwater',
  LABWATERID: 2
}

export const certificateTypes = {
  CA_CHAIN: 'cachain',
  DEVICE: 'devicecertificate'
}

export const addPartnerPeripheralFormName = 'addPartnerPeripheral'

export const partnerPeripheralRelationTypes = {
  feed: 'Feed',
  source: 'Source',
  primary: 'Primary',
  secondary: 'Secondary'
}

export const deviceConnectivity = {
  connected: 'connected',
  validatedSystem: 'smRegulatedEnvironment'
}

export const DEVICE_STATUS = [
  {
    label: 'Connected',
    value: true
  },
  {
    label: 'Unconnected',
    value: false
  }
]
export const partnerPeripheralSuppliers = {
  'MILLI_Q': 5
}
export const cardsSection = {
  diagnostics: 'diagnostics',
  general: 'general',
  inventory: 'inventory',
  process: 'process'
}
export const moduleId = 'deviceDetails'
export const deviceFileManagementModuleId = 'deviceInteraction'
export const dispenseReportWaterTypes = ['Pure', 'Ultra-Pure', 'All']
export const swUpdateStatus = {
  'SW_UPDATE_READY': 'update available',
  'NOT_AUTHORIZED': 'Not Authorized'
}
export const DEVICEDETAILS_VERTICALBUTTONS = 'DEVICEDETAILS_VERTICALBUTTONS'
export const DEVICE_DETAILS_BTN_ID = 'Device_details'

export const orgDetails = {
  businessOrgType: 'Business Unit',
  orgNameLabwater: 'LABWATER'
}