import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import HomeSvg from 'static/icons/HomeSvg'
import DeviceSvg from 'static/icons/DeviceSvg'
import DeviceCertSvg from 'static/icons/DeviceCertSvg'
import merckLogo from 'static/images/merck-logo.png'
import collapse from 'static/icons/svg/collapse.svg'
import { Layout, Menu } from 'antd'
import LogoutSvg from 'static/icons/LogoutSvg'
import { PATHS } from 'routes/index'
import 'antd/dist/antd.css'
import './Sidebar.scss'
import DeviceSetting from 'static/icons/device-settings.svg'
import cardsSvg from 'static/icons/svg/cards.svg'
import fileManagementSvg from 'static/icons/svg/fileManagement.svg'
import userManagementSvg from 'static/icons/svg/userManagement.svg'
import deviceSoftwareSvg from 'static/icons/svg/deviceSoftware.svg'
import contactHotlineSoftwareSvg from 'static/icons/svg/contact-hotline.svg'
import serviceContractSvg from 'static/icons/svg/service-contract-management.svg'
import { userStatusConstants } from 'utils/authUtils'
import { deviceMaxWidth } from 'utils/screenSizeConstants'
import star from 'static/icons/star-sidebar.svg'
import dataanalysis from 'static/icons/data_analysis.svg'
import howtoIcon from 'static/icons/howto.svg'
// import {
//   hasGlobalFileManagementAccess,
//   hasCardsPermission,
//   hasDevicesAccess,
// } from 'utils/sideBarAccessUtils'
import SidebarTooltip from './SidebarTooltip'
import { VerticalBarWrapper } from './VerticalBarWrapper'
// import GetMenuItems from './GetMenuItems'

const { Sider } = Layout
const propTypes = {
  collapsed: PropTypes.bool,
  toggle: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  permission: PropTypes.object,
  userStatus: PropTypes.string,
  loggedIn: PropTypes.bool,
  userOrgId: PropTypes.number,
  fetchingSaveVerticalMenu: PropTypes.bool,
  verticalMenuList: PropTypes.array,
  toggleDragMenu: PropTypes.func,
  handleSaveBtn: PropTypes.func,
  fetchVerticalMenu: PropTypes.func,
  fetchedVerticalMenuList: PropTypes.bool
}
const Sidebar = props => {
  const {
    collapsed,
    toggle,
    location,
    history,
    // permission,
    userStatus,
    loggedIn,
    // userOrgId,
    fetchingSaveVerticalMenu,
    verticalMenuList,
    toggleDragMenu,
    handleSaveBtn,
    fetchVerticalMenu,
    fetchedVerticalMenuList,
  } = props

  const handleLogout = () => {
    history.push(PATHS.LOGOUT)
  }

  useEffect(() => {
    if (!fetchedVerticalMenuList) {
      fetchVerticalMenu('HOMEPAGE_VERTICALBUTTONS')
    }
    document.addEventListener('click', handleDragMenu, false)
    return () => {
      document.removeEventListener('click', handleDragMenu, false)
    }
  }, [])

  const handleDragMenu = (e) => {
    const dragMenuRef = document.getElementById('vertical_menu')
    if (dragMenuRef?.contains(e.target)) {
      return
    }
    const classList = dragMenuRef.classList
    if (classList.contains('show'))
      classList.toggle('show')
  }

  const getSortedMenus = menus => {
    if (menus) {
      let a = [], b = []
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].visible) {
          a.push(menus[i])
        } else {
          b.push(menus[i])
        }
      }
      a.sort((a, b) => a.menuSequence - b.menuSequence)
      b.sort((a, b) => a.menuSequence - b.menuSequence)
      return [...a, ...b]
    }
  }

  const MENU_ITEMS = {
    'DEVICE_LIST': { icon: <DeviceSvg className='nav-svg' />, message: 'DEVICES' },
    'USER_MANAGEMENT': { icon: <img alt='true' src={userManagementSvg} className='nav-svg menu-icon-size' />, message: 'USER_MANAGEMENT' },
    'DEVICE_REGISTRY': { icon: <img alt='true' src={DeviceSetting} className='nav-svg menu-icon-size' />, message: 'MAIN_MENU_DEVICE_REGISTRY_BUTTON' },
    'CERTIFICATES_STATUS': { icon: <DeviceCertSvg className='nav-svg' />, message: 'CERTIFICATES_STATUS' },
    'GLOBAL_FILE_MANAGEMENT': { icon: <img alt='true' src={fileManagementSvg} className='nav-svg menu-icon-size' />, message: 'MAIN_MENU_FILE_MANAGEMENT_BUTTON' },
    'CONTACT_HOTLINE_DASHBOARD': { icon: <img alt='true' src={contactHotlineSoftwareSvg} className='nav-svg menu-icon-size' />, message: 'CONTACT_HOTLINE_DASHBOARD' },
    'MANAGE_NEWS_FEED': { icon: <DeviceCertSvg className='nav-svg' />, message: 'MANAGE_NEWS_FEED' },
    'CARDS': { icon: <img alt='true' src={cardsSvg} className='nav-svg menu-icon-size' />, message: 'CARDS_LISTING_TITLE' },
    'DEVICE_SOFTWARE_UPDATE': { icon: <img alt='true' src={deviceSoftwareSvg} className='nav-svg menu-icon-size' />, message: 'DEVICES_SOFTWARE_UPDATES' },
    'USEREXP_FEEDBACK': { icon: <img alt='true' src={star} className='nav-svg menu-icon-size' />, message: 'USER_EXPERIENCE_FEEDBACK' },
    'SERVICE_CONTRACT_MANAGEMENT': { icon: <img alt='true' src={serviceContractSvg} className='nav-svg menu-icon-size' />, message: 'SERVICE_CONTRACT_MANAGEMENT' },
    'DATA_ANALYSIS': { icon: <img alt='true' src={dataanalysis} className='nav-svg menu-icon-size' />, message: 'DATA_ANALYSIS' },
    'HOW_TO': { icon: <img alt='true' src={howtoIcon} className='nav-svg menu-icon-size' />, message: 'HOW_TO' },
    'SERVICE_CONTRACT_PLAN': {},
    'INVENTORY_PAGE': {}
  }

  let menus = getSortedMenus(verticalMenuList && verticalMenuList.toJS()[0]?.menus)
  menus = menus?.map((item) => {
    return {
      ...item,
      menuSequence: item.menuSequence,
      menuKey: `next.${MENU_ITEMS[item.menuKey]?.message}`,
      visible: item.visible,
      keyUrl: item.menuKey,
      btnVisibility: { disabled: false, show: true }
    }
  })

  const onDoubleClickAction = actionKey => {
    const dragMenuRef = document.getElementById('vertical_menu')
    const classList = dragMenuRef.classList
    if (actionKey)
      history.replace(PATHS[actionKey])
    if (classList.contains('show'))
      classList.toggle('show')
  }

  return (
    <Layout>
      <Sider
        style={{
          height: '100%',
          position: 'fixed',
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={`side-bar-box ${!collapsed ? 'expanded-sidebar' : ''}`}
      >
        {!collapsed && (
          <div onClick={toggle} className='sidebar-close'>
            <img alt src={collapse} width='35' height='30' />
          </div>
        )}
        <ul className='sideber-nav'>
          <li>
            <img style={{ width: '60px', height: '35px' }} src={merckLogo} alt='expand menu' />
          </li>
        </ul>
        <hr />
        {loggedIn && (
          <div className='sidebar-menus'>
            {userStatus === userStatusConstants.ACTIVE && (
              <Menu theme='dark' mode='inline' selectedKeys={[location.pathname]}>
                <Menu.Item key={PATHS.DASHBOARD} icon={<HomeSvg className='nav-svg' />}>
                  <SidebarTooltip
                    collapsed={collapsed}
                    path={`${PATHS.DASHBOARD}`}
                    message='next.MAIN_MENU_DASHBOARD_BUTTON'
                    showTooltip={false}
                  />
                </Menu.Item>
                {menus?.map(item =>
                  item.visible && (
                    <Menu.Item key={PATHS[item.keyUrl]} icon={MENU_ITEMS[item.keyUrl]?.icon}
                      onClick={window.innerWidth <= deviceMaxWidth.IPAD ? toggle : () => { }}
                    >
                      <SidebarTooltip
                        collapsed={collapsed}
                        path={`${PATHS[item.keyUrl]}`}
                        message={`next.${MENU_ITEMS[item.keyUrl]?.message}`}
                      />
                    </Menu.Item>
                  
                  ))}
                {/* <Menu.Item key={PATHS.DASHBOARD} icon={<HomeSvg className='nav-svg' />}>
                  <SidebarTooltip
                    collapsed={collapsed}
                    path={`${PATHS.DASHBOARD}`}
                    message='next.MAIN_MENU_DASHBOARD_BUTTON'
                    showTooltip={false}
                  />
                </Menu.Item>
                {hasDevicesAccess(permission) && (
                  <Menu.Item key={PATHS.DEVICE_LIST} icon={<DeviceSvg className='nav-svg' />}>
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.DEVICE_LIST}`}
                      message='next.DEVICES'
                      showTooltip={false}
                    />
                  </Menu.Item>
                )}
                {hasCardsPermission(permission, userOrgId) && (
                  <Menu.Item key={PATHS.CARDS}
                             icon={<img alt='true' src={cardsSvg} className='nav-svg menu-icon-size' />}>
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.CARDS}`}
                      message='next.CARDS_LISTING_TITLE'
                      showTooltip={false}
                    />
                  </Menu.Item>
                )}
                {permission.deviceregistry && (
                  <Menu.Item
                    key={PATHS.DEVICE_REGISTRY}
                    icon={<img alt='true' src={DeviceSetting} className='nav-svg menu-icon-size' />}>
                  <SidebarTooltip
                    collapsed={collapsed}
                    path={`${PATHS.DEVICE_REGISTRY}`}
                    message='next.MAIN_MENU_DEVICE_REGISTRY_BUTTON'
                    showTooltip={false}
                  />
                </Menu.Item>
              )}
              {permission?.certificate?.['READ'] && (
                <Menu.Item
                  key={PATHS.CERTIFICATES_STATUS}
                  icon={<DeviceCertSvg className='nav-svg' />}
                >
                  <SidebarTooltip
                    collapsed={collapsed}
                    path={`${PATHS.CERTIFICATES_STATUS}`}
                    message='next.CERTIFICATES_STATUS'
                  />
                </Menu.Item>
              )}
              {permission.contacthotline && (
                  <Menu.Item
                    className='side-content'
                    key={PATHS.CONTACT_HOTLINE_DASHBOARD}
                    icon={<img alt='true' src={contactHotlineSoftwareSvg} className='nav-svg menu-icon-size'/>}>
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.CONTACT_HOTLINE_DASHBOARD}`}
                      message='next.CONTACT_HOTLINE_DASHBOARD'
                    />
                  </Menu.Item>
              )}
                {(permission.user || permission.role) && (
                  <Menu.Item
                    key={PATHS.USER_MANAGEMENT}
                    icon={<img alt='true' src={userManagementSvg} className='nav-svg menu-icon-size' />}>
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.USER_MANAGEMENT}`}
                      message='next.USER_MANAGEMENT'
                      showTooltip={true}/>
                  </Menu.Item>
                )}
                {hasGlobalFileManagementAccess(permission) && (
                  <Menu.Item
                    key={PATHS.GLOBAL_FILE_MANAGEMENT}
                    icon={<img alt='true' src={fileManagementSvg} className='nav-svg menu-icon-size' />}>
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.GLOBAL_FILE_MANAGEMENT}`}
                      message='next.MAIN_MENU_FILE_MANAGEMENT_BUTTON'
                      showTooltip={true}
                    />
                  </Menu.Item>
                )}
                {permission.swupdate && permission.swupdate.READDEVICEMGMT && (
                  <Menu.Item
                    key={PATHS.DEVICE_SOFTWARE_UPDATE}
                    icon={<img alt='true' src={deviceSoftwareSvg} className='nav-svg menu-icon-size'/>}>
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.DEVICE_SOFTWARE_UPDATE}`}
                      message='next.DEVICES_SOFTWARE_UPDATES'
                    />
                  </Menu.Item>
                )}
                {permission && permission['news'] && permission['news']['MANAGE'] && (
                  <Menu.Item
                    key={PATHS.MANAGE_NEWS_FEED}
                    icon={<DeviceCertSvg className='nav-svg' />}
                  >
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.MANAGE_NEWS_FEED}`}
                      message='next.MANAGE_NEWS_FEED'
                      showTooltip={true}
                    />
                  </Menu.Item>)}
                {permission && permission['feedback'] && permission['feedback']['VIEW'] && (
                  <Menu.Item
                    key={PATHS.RATING_IMPROVEMENT}
                    icon={<img alt='true' src={star} className='nav-svg menu-icon-size'/>}
                  >
                    <SidebarTooltip
                      collapsed={collapsed}
                      path={`${PATHS.RATING_IMPROVEMENT}`}
                      message='next.USER_EXPERIENCE_FEEDBACK' />
                  </Menu.Item>
                )} */}
                {verticalMenuList && verticalMenuList?.size && menus?.length > 0 &&
                  <div>
                    <VerticalBarWrapper
                      toggleDragMenu={toggleDragMenu}
                      Loading={fetchingSaveVerticalMenu}
                      handleSaveBtn={handleSaveBtn}
                      myTasks={menus}
                      id={'vertical_menu'}
                      position='right'
                      visibleLimit={7}
                      onDoubleClickAction={onDoubleClickAction}
                      MENU_ITEMS={MENU_ITEMS}
                    /></div>}
                <Menu.Item
                  onClick={handleLogout}
                  key={PATHS.LOGOUT}
                  icon={<LogoutSvg className='nav-svg-logout' style={{ fill: 'red', stroke: 'red' }} />}>
                  <Link to={`${PATHS.LOGOUT}`}>
                    <FormattedMessage id='next.MAIN_MENU_LOGOUT_BUTTON' />
                  </Link>
                </Menu.Item>
              </Menu>
            )}
          </div>
        )}
      </Sider>
    </Layout >
  )
}
Sidebar.propTypes = propTypes
export default injectIntl(withRouter(React.memo(Sidebar)))
