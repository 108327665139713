import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './CheckBox.scss'

const cx = classNames.bind(styles)

const CheckBox = (props) => {
  const { value, checked, onChange, label, disabled, children } = props

  return (
    <div className={cx('filter-content', {'disabled': disabled})}>
      <label className={cx('filter-label')}>
        <input
          type='checkbox'
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={cx('filter-checkbox-modal')} />
        {label}
      </label>
      {children}
    </div>
  )
}

CheckBox.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ])
}

export default CheckBox
