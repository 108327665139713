import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { FormattedMessage } from 'react-intl'

export const MovableItem = ({ name, menuId, index, currentColumnName, moveCardHandler, setItems, columnNames, onDoubleClickAction, keyUrl, disabled, icon }) => {
  const changeItemColumn = (currentItem, columnName) => {
    setItems((prevState) => {
      const hoverIndex = index
      let coppiedStateArray = [...prevState]
      if (!(columnName === columnNames.DISPLAYED_MENU && coppiedStateArray[hoverIndex]?.visible) ||
        (columnName === columnNames.DISPLAYED_MENU && coppiedStateArray[hoverIndex]?.visible))
        coppiedStateArray = coppiedStateArray.filter(arr => arr.menuId !== 9999)
      const initialLoad = coppiedStateArray?.filter(({ visible }) => visible === false)
      if (initialLoad?.length < 1) {
        coppiedStateArray.push({ visible: false, btnVisibility: { show: true, disabled: false }, menuId: 9999, menuKey: 'Drop here to Move', menuSequence: coppiedStateArray?.length + 100 })
      } else if (initialLoad?.length === coppiedStateArray?.length) {
        coppiedStateArray.unshift({ visible: true, btnVisibility: { show: true, disabled: false }, menuId: 9999, menuKey: 'Drop here to Move', menuSequence: coppiedStateArray?.length + 100 })
      }
      return coppiedStateArray
    })
  }

  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: 'Our first type',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { index, name, currentColumnName, type: 'Our first type' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (dropResult)
        changeItemColumn(item, currentColumnName)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.4 : 1

  drag(drop(ref))
  return (
    <div
      ref={ref}
      name={name}
      style={{ opacity }}
      onDoubleClick={() => (keyUrl && !disabled) && onDoubleClickAction(keyUrl)}
      className={`${menuId === 9999 ? 'drop-here' : 'movable-item'} ${disabled && 'disabled-menu'} truncate`}>
      {icon && <div>{icon}</div>}
      <span className='movable-item-name'>
      <FormattedMessage id={name} /></span>
    </div>
  )
}

MovableItem.propTypes = {
  name: PropTypes.string,
  menuId: PropTypes.number,
  index: PropTypes.number,
  currentColumnName: PropTypes.number,
  moveCardHandler: PropTypes.func,
  setItems: PropTypes.object,
  columnNames: PropTypes.object,
  keyUrl: PropTypes.string,
  onDoubleClickAction: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.element
}
