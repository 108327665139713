export const defaultLanguage = 'en'

export const cookiePolicyAcceptance = 'cookiePolicyAcceptance'

export const roles = {
  'CUSTOMER_TECH': 'CustomerTech',
  'CUSTOMER_MAINTENANCE': 'CustomerMaintenance'
}

export const territory = {
  'ASSIGN_TERRITORY_PRIVILEGE_ID': 88,
  'ASSIGN_TERRITORY_OPERATION': 'has_territories',
  'ASSIGN_TERRITORY_RESOURCE_TYPE': 'entity'
}
