import { lazy } from 'react'

export const asyncComponent = (component) => {
  return typeof component === 'function'
    ? component
    : lazy(() => import(`views/${component}`))
}
asyncComponent.displayName = 'asyncComponent'

export default asyncComponent
