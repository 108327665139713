import React from 'react'
import PropTypes from 'prop-types'
import { certificateTypes } from 'constants/device'
import { FormattedMessage } from 'react-intl'
import download from 'static/icons/svg/download.svg'
import {
  Button
} from 'antd'
import './DownloadPopup.scss'
import Loader from 'components/Loader/Loader'
import { useSelector } from 'react-redux'

const DownloadPopup = (props) => { 
    const { close, initiateCertificateFileDownload, docId, initiateDocDownloadCertificate } = props
    const deviceCertificateStatus = useSelector(state => state.deviceCertificateStatus)
    const { fetching } = deviceCertificateStatus?.toJS()
    return (
      <div>
        <div className='div-card-center'>
          {fetching && <Loader />}
          <div className='input-div'>
            <div className='download-buttons-wrapper'>
              <div className='download-button' onClick={() => initiateCertificateFileDownload(certificateTypes.CA_CHAIN)}>
                {<FormattedMessage id='next.CA_CHAIN_CERTIFICATE' />}
                <span className='icon'>
                  <img className='style-icon certi_ca_chain' src={download} />
                </span>
              </div>
              <div className='download-button' onClick={() => initiateCertificateFileDownload(certificateTypes.DEVICE)}>
                {<FormattedMessage id='next.DEVICE_CERTIFICATE' />}
                <span className='icon'>
                  <img className='style-icon certi_download' src={download} />
                </span>
              </div>
              {docId && <div className='download-button' onClick={() => initiateDocDownloadCertificate(docId)}>
                {<FormattedMessage id='next.SYSTEM_CONFIG_FILE_DOWNLOAD' />}
                <span className='icon'>
                  <img className='style-icon certi_system_config' src={download} />
                </span>
              </div>
              }
            </div>
          </div>
        </div>
        <span className='add-user-form-banner'>
          <div className='invite-user-btns'>
          <Button
            appearance='secondary'
            onClick={() => { close() }}
            style={{textTransform: 'uppercase'}}>
            <FormattedMessage id='next.CANCEL' />
          </Button>
          </div>
        </span>
      </div>
    )
}

DownloadPopup.propTypes = {
  close: PropTypes.func,
  initiateCertificateFileDownload: PropTypes.func,
  docId: PropTypes.number,
  initiateDocDownloadCertificate: PropTypes.func,
  fetching: PropTypes.bool
}

export default DownloadPopup