import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import versions from 'versions/versions'

// Constants
export const constants = {
  FETCH: 'messages/FETCH',
  RECEIVE: 'messages/RECEIVE',
  FETCH_FAILURE: 'messages/FETCH_FAILURE',
  RECEIVE_MESSAGE: 'messages/RECEIVE_MESSAGE'
}

// const messageApi = process.env.NODE_ENV === 'development' ? 'getMessagesDevelopment' : 'getMessages'

// Action Creators
export const actions = {
  fetch (locale) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      // apiFunction: messageApi,
      apiFunction: 'getMessagesDevelopment',
      args: {locale, v: versions && versions.nextconnect && versions.nextconnect.version},
      throwError: true,
      onSuccess: ({ data: messages }) => {
        return ({ type: constants.RECEIVE, messages, locale })
      },
      onFailure: (error) => {
        return ({ type: constants.FETCH_FAILURE, error: error.message, locale })
      }
    })
  }
}

// Reducer
export const initialState = fromJS({
  messageFetching: false,
  messages: {},
  error: null,
  languageNotSuppoted: null,
  locale: 'en'
})

export default function(state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('messageFetching', true)
    case constants.RECEIVE:
      return state
        .set('messageFetching', false)
        .set('error', null)
        .set('locale', action.locale)
        .set('messages', fromJS(action.messages))
        .set('languageNotSuppoted', null)
    case constants.FETCH_FAILURE:
      return state
        .set('messageFetching', false)
        .set('languageNotSuppoted', action.locale)
        .set('error', action.error)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.translation

export const selectors = {
  messageFetching: createSelector(getState, (state) =>
    state.get('messageFetching')
  ),
  messages: createSelector(getState, (state) =>
    state.get('messages')
  ),
  locale: createSelector(getState, (state) =>
    state.get('locale')
  ),
  error: createSelector(getState, (state) =>
    state.get('error')
  ),
  languageNotSuppoted: createSelector(getState, (state) =>
    state.get('languageNotSuppoted')
  )
}
