import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

const RouteChangeTracker = ({ history }) => {
    history.listen((location, action) => {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
    })
    return <div></div>
}

RouteChangeTracker.propTypes = {
    history: PropTypes.object
}

export default withRouter(RouteChangeTracker)