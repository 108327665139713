import React from 'react'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'

const sidebarTooltip = ({ collapsed, path, message, showTooltip }) => {
  const intl = useIntl()
  return(
    collapsed || !showTooltip ? (
      <Link to={path}>
        <FormattedMessage id={message} />
      </Link>
    ) : (
      <Tooltip placement='top' title={intl.formatMessage({id: message})}>
        <Link to={path}>
          <FormattedMessage id={message} />
        </Link>
      </Tooltip>
    )
  )
}
const propTypes = {
  collapsed: PropTypes.bool,
  path: PropTypes.string,
  message: PropTypes.string,
  showTooltip: PropTypes.bool
}
sidebarTooltip.defaultProps = {
  showTooltip: true
}
sidebarTooltip.propTypes = propTypes
export default sidebarTooltip
