import React from 'react'
import { FormattedMessage } from 'react-intl'
import className from 'classnames/bind'
import styles from './PhoneNumberCriteria.scss'

const cx = className.bind(styles)

const PhoneNumberCriteria = (<div className={cx('phoneContent')}>
  <span><FormattedMessage id={`next.PHONE_NUMBER_SUGGESTION_HEADER`} /></span>
  <ul className={cx('phoneList')}>
    <li><span className='list__style'></span><FormattedMessage id={`next.PHONE_NUMBER_SUGGESTION_INT_CODE`} /></li>
    <li><span className='list__style'></span><FormattedMessage id={`next.PHONE_NUMBER_SUGGESTION_LOCAL_PHONE_NUMBER`} /></li>
  </ul>
  <div className={cx('phoneSuggestion')}>
    <div className={cx('phoneFormatlbl')}><FormattedMessage id={`next.PHONE_NUMBER_SUGGESTION_EXAMPLE`} /></div>
    <div className={cx('phoneFormatCountrycode')}>
      <span className={cx('exampleStyle')}><FormattedMessage id={`next.PHONE_NUMBER_EXAMPLE_XXX`} /></span>
      <div className={cx('examplblStyle')}><FormattedMessage id={`next.PHONE_NUMBER_EXAMPLE_COUNTRY_CODE`} /></div>
    </div>
    <div className={cx('phoneFormatMinus')}><FormattedMessage id={`next.PHONE_NUMBER_EXAMPLE_MINUS`} /></div>
    <div className={cx('phoneFormatPhoneNumber')}>
      <span className={cx('exampleStyle')}><FormattedMessage id={`next.PHONE_NUMBER_EXAMPLE_XXXX`} /></span>
      <div className={cx('examplblStyle')}><FormattedMessage id={`next.PHONE_NUMBER_EXAMPLE_PHONE_NUMBER`} /></div>
    </div>
  </div>
</div>)

export default PhoneNumberCriteria
