import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH_SW_UPDATES_PUSHED: 'SOFTWARE_UPGRADE/FETCH_SW_UPDATES_PUSHED',
  RECEIVE_SW_UPDATES_PUSHED: 'SOFTWARE_UPGRADE/RECEIVE_SW_UPDATES_PUSHED',
  SW_UPDATES_PUSHED_FAILURE: 'SOFTWARE_UPGRADE/SW_UPDATES_PUSHED_FAILURE',

  FETCH_SW_UPDATES_NEEDED: 'SOFTWARE_UPGRADE/FETCH_SW_UPDATES_NEEDED',
  RECEIVE_SW_UPDATES_NEEDED: 'SOFTWARE_UPGRADE/RECEIVE_SW_UPDATES_NEEDED',
  SW_UPDATES_NEEDED_FAILURE: 'SOFTWARE_UPGRADE/SW_UPDATES_NEEDED_FAILURE',

  FETCH_SW_UPDATES_INSTALLED: 'SOFTWARE_UPGRADE/FETCH_SW_UPDATES_INSTALLED',
  RECEIVE_SW_UPDATES_INSTALLED: 'SOFTWARE_UPGRADE/RECEIVE_SW_UPDATES_INSTALLED',
  SW_UPDATES_INSTALLED_FAILURE: 'SOFTWARE_UPGRADE/SW_UPDATES_INSTALLED_FAILURE',
}


// Action Creators
export const actions = {

  fetchSoftwareUpdatesPushed(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_SW_UPDATES_PUSHED }),
      apiFunction: 'getRemoteControlChartData',
      args:payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_SW_UPDATES_PUSHED, data }),
      onFailure: (error) => ({ type: constants.SW_UPDATES_PUSHED_FAILURE, error: error.toString() })
    })
  },
  fetchSoftwareUpdatesNeeded(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_SW_UPDATES_NEEDED }),
      apiFunction: 'getRemoteControlChartData',
      args:payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_SW_UPDATES_NEEDED, data }),
      onFailure: (error) => ({ type: constants. SW_UPDATES_NEEDED_FAILURE, error: error.toString() })
    })
  },
  fetchSoftwareUpdatesInstalled(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants. FETCH_SW_UPDATES_INSTALLED }),
      apiFunction: 'getRemoteControlChartData',
      args:payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_SW_UPDATES_INSTALLED, data }),
      onFailure: (error) => ({ type: constants.SW_UPDATES_INSTALLED_FAILURE, error: error.toString() })
    })
  },

}

// Reducer
export const initialState = fromJS({
  fetchingSwUpdatesPushed: false,
  fetchSwUpdatesPushedError: null,
  SwUpdatesPushedData: null,

  fetchingSwUpdatesNeeded: false,
  fetchSwUpdatesNeededError: null,
  SwUpdatesNeededData: null,

  fetchingSwUpdatesInstalled: false,
  fetchSwUpdatesInstalledError: null,
  SwUpdatesInstalledData: null,
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_SW_UPDATES_PUSHED:
      return state
        .set('fetchingSwUpdatesPushed', true)
        .set('fetchSwUpdatesPushedError', null)

    case constants.RECEIVE_SW_UPDATES_PUSHED:
      return state
        .set('fetchingSwUpdatesPushed', false)
        .set('SwUpdatesPushedData', action.data.data)

    case constants.SW_UPDATES_PUSHED_FAILURE:
      return state
        .set('fetchSwUpdatesPushedError', action.error)
        .set('fetchingSwUpdatesPushed', false)

        case constants.FETCH_SW_UPDATES_NEEDED:
      return state
        .set('fetchingSwUpdatesNeeded', true)
        .set('fetchSwUpdatesNeededError', null)

    case constants.RECEIVE_SW_UPDATES_NEEDED:
      return state
        .set('fetchingSwUpdatesNeeded', false)
        .set('SwUpdatesNeededData', action.data.data)

    case constants.SW_UPDATES_NEEDED_FAILURE:
      return state
        .set('fetchSwUpdatesNeededError', action.error)
        .set('fetchingSwUpdatesNeeded', false)

        case constants.FETCH_SW_UPDATES_INSTALLED:
      return state
        .set('fetchingSwUpdatesInstalled', true)
        .set('fetchSwUpdatesInstalledError', null)

    case constants.RECEIVE_SW_UPDATES_INSTALLED:
      return state
        .set('fetchingSwUpdatesInstalled', false)
        .set('SwUpdatesInstalledData', action.data.data)

    case constants.SW_UPDATES_INSTALLED_FAILURE:
      return state
        .set('fetchSwUpdatesInstalledError', action.error)
        .set('fetchingSwUpdatesInstalled', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.softwareUpgrade

export const selectors = {
  fetchingSwUpdatesPushed: createSelector(getState, (state) =>
    state.get('fetchingSwUpdatesPushed')
  ),
  fetchSwUpdatesPushedError: createSelector(getState, (state) =>
    state.get('fetchSwUpdatesPushedError')
  ),
  SwUpdatesPushedData: createSelector(getState, (state) =>
    state.get('SwUpdatesPushedData')
  ),
  fetchingSwUpdatesNeeded: createSelector(getState, (state) =>
  state.get('fetchingSwUpdatesNeeded')
),
fetchSwUpdatesNeededError: createSelector(getState, (state) =>
  state.get('fetchSwUpdatesNeededError')
),
SwUpdatesNeededData: createSelector(getState, (state) =>
  state.get('SwUpdatesNeededData')
),
fetchingSwUpdatesInstalled: createSelector(getState, (state) =>
state.get('fetchingSwUpdatesInstalled')
),
fetchSwUpdatesInstalledError: createSelector(getState, (state) =>
state.get('fetchSwUpdatesInstalledError')
),
SwUpdatesInstalledData: createSelector(getState, (state) =>
state.get('SwUpdatesInstalledData')
),
}

