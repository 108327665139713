import React from 'react'
import PropTypes from 'prop-types'
import {PATHS} from 'routes'
import classNames from 'classnames/bind'
import styles from './Footer.scss'
import {FormattedMessage} from 'react-intl'
import MerckVibrantLogo from 'components/MerckVibrantLogo/MerckVibrantLogo'
const cx = classNames.bind(styles)
const Footer = (props) => {
  const gotoTerms = () => {
    const { country, history } = props
    history.push({
      pathname: PATHS.TERMS_CONDITION,
      search: `?query=${country}`
    })
  }
  const gotoPrivacy = (event) => {
    const { country, history } = props
    history.push({
      pathname: PATHS.PRIVACY_POLICY,
      search: `?query=${country}`
    })
  }
  const {loggedIn} = props
  return (
    <div className={cx('footer-container')}>
      <div className={cx('footer')}>
        {loggedIn && (
          <div style={{marginTop: '12px'}}>
          <span className={cx('footer-link-style')} onClick={gotoPrivacy}>
            <FormattedMessage id='next.FOOTER_VIEW_POLICY' />
          </span>
            {' | '}
            <span onClick={gotoTerms} className={cx('footer-link-style')}>
            <FormattedMessage id='next.FOOTER_VIEW_TERMS' />
          </span>
          </div>)}
      </div>
      <MerckVibrantLogo />
    </div>
  )
}

Footer.propTypes = {
  history: PropTypes.object,
  country: PropTypes.string,
  loggedIn: PropTypes.bool
}

export default Footer
