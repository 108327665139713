import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { selectors as pageSelectors } from 'store/modules/Pagination'
import safeFetch from 'store/utils/safeFetch'
import { cardsFilterTypes } from 'constants/cards'

// Constants
const pageLimit = 10
export const constants = {
  FETCH: 'CARDS/FETCH',
  FETCH_FAILURE: 'CARDS/FETCH_FAILURE',
  FETCH_CARDS: 'CARDS/FETCH_CARDS',
  RECEIVE_CARDS: 'CARDS/RECEIVE_CARDS',
  RECEIVE_CARD_FACETS: 'CARDS/RECEIVE_CARD_FACETS',
  SORT: 'CARDS/SORT',
  SET_SEARCH_TERM: 'CARDS/SET_SEARCH_TERM',
  SET_CURRENT_PAGE: 'CARDS/SET_CURRENT_PAGE',
  DELETE: 'CARDS/DELETE',
  DELETE_SUCCESS: 'CARDS/DELETE_SUCCESS',
  DELETE_FAILURE: 'CARDS/DELETE_FAILURE',
  SELECTED_FILTER: 'CARDS/SELECTED_FILTER',
  RESET_DELETE_CARD_STATUS: 'CARDS/RESET_DELETE_CARD_STATUS'
}

// Action Creators
export const actions = {
  getCardsFetchQuery(getState) {
    const filterBy = selectors.selectedFilterValues(getState()).toJS()
    const sortBy = selectors.sortColumn(getState())
    const sortDirection = selectors.sortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const searchBy = selectors.searchTerm(getState())
    const page = pageSelectors.pagination(getState()).getIn(['cardsPagination', 'currentPage'])
    const query = {
      filterBy,
      sortBy,
      orderBy,
      searchBy,
      page,
      pageLimit
    }
    return query
  },
  loadCards (isHandheldDevice = false) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      preventMultipleRequest: true,
      apiFunction: 'fetchCards',
      args: actions.getCardsFetchQuery,
      onSuccess: ({ data, headers }) => ({type: constants.RECEIVE_CARDS, data, headers, isHandheldDevice}),
      onFailure: (error) => ({type: constants.FETCH_FAILURE, error: error.message})
    })
  },
  applySort (sortColumn) {
    return { type: constants.SORT, sortColumn }
  },
  setSearchTerm (searchTerm) {
    return { type: constants.SET_SEARCH_TERM, searchTerm }
  },
  setCurrentPage (page) {
    return { type: constants.SET_CURRENT_PAGE, page }
  },
  deleteCard (id, cardName) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'deleteCard',
      args: { id },
      onSuccess: () => ({type: constants.DELETE_SUCCESS, status: 204, cardName}),
      onFailure: ({status}) => ({type: constants.DELETE_FAILURE, status})
    })
  },
  resetCardDeleteStatus () {
    return {type: constants.RESET_DELETE_CARD_STATUS}
  },
  loadCardFacets() {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'fetchCardsFacets',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_CARD_FACETS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  addfilterCategory (filterType, selectedValue) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.SELECTED_FILTER, selectedValue })
    }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  cards: [],
  searchTerm: '',
  sortDirection: true,
  sortColumn: 'deviceType',
  totalPages: 0,
  currentPage: 0,
  selectedFilterValues: [],
  cardDeleteStatus: null,
  cardFacets: {}
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.RECEIVE_CARD_FACETS:
      return state
        .set('cardFacets', sanitizeFilters(action.data))
    case constants.RECEIVE_CARDS:
      if (action.isHandheldDevice) {
        let cards = state.get('cards')
        return state
          .set('cards', fromJS([
            ...cards,
            ...action.data
          ]))
          .set('totalPages', parseInt(fromJS(action.headers.get('x-pagination-count'))))
          .set('fetching', false)
      }
      return state
        .set('cards', fromJS(action.data))
        .set('totalPages', parseInt(fromJS(action.headers.get('x-pagination-count'))))
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.SORT:
      return state
        .set('sortColumn', action.sortColumn)
        .set('sortDirection', !state.get('sortDirection'))
    case constants.SET_SEARCH_TERM:
      return state
      .set('searchTerm', action.searchTerm)
    case constants.SELECTED_FILTER:
      return state
        .updateIn(['selectedFilterValues'], (state) => {
          return state.find(selectedFilter => selectedFilter === action.selectedValue)
          ? state.filter(item => item !== action.selectedValue) : state.push(action.selectedValue)
        })
    case constants.DELETE_SUCCESS:
      return state
        .set('cardDeleteStatus', {status: action.status, name: action.cardName})
        .set('fetching', false)
    case constants.DELETE_FAILURE:
      return state
        .set('cardDeleteStatus', {status: action.status})
        .set('fetching', false)
    case constants.RESET_DELETE_CARD_STATUS:
      return state
        .set('cardDeleteStatus', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.cards

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  cards: createSelector(getState, (state) =>
    state.get('cards')
  ),
  getCardFacets: createSelector(getState, (state) =>
    state.get('cardFacets')
  ),
  sortColumn: createSelector(getState, (state) =>
    state.get('sortColumn')
  ),
  sortDirection: createSelector(getState, (state) =>
    state.get('sortDirection')
  ),
  cardHeaders: createSelector(getState, (state) => {
    const headers = ['Card Name', 'Data Type', 'Created By', 'Created On']
    return headers
  }),
  searchTerm: createSelector(getState, (state) =>
    state.get('searchTerm')
  ),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages')
  ),
  currentPage: createSelector(getState, (state) =>
    state.get('currentPage')
  ),
  selectedFilterValues: createSelector(getState, (state) =>
    state.get('selectedFilterValues')
  ),
  cardDeleteStatus: createSelector(getState, state =>
    state.get('cardDeleteStatus')
  ),
  filterCount: createSelector(getState, state =>
    state.get('selectedFilterValues').size || 0
  )
}

// Helper functions

function sanitizeFilters(filterTypes) {
  const filterOrder = [
    cardsFilterTypes.DEVICETYPE,
    cardsFilterTypes.DATATYPE,
    cardsFilterTypes.CARDTYPE
  ]

  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      const values = filterTypes[filter]
      const isSearchBox = false
      const categoryType = filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => ({
          name: val,
          label: val,
          key: index,
          value: `${filter}.${val}`
        }))

      filterCategories.push({categoryType, categoryValues, searchBox: isSearchBox})
    }
  })

  return filterCategories
}
