import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {Select} from 'antd'
import 'antd/lib/select/style/css'
import classNames from 'classnames/bind'
import styles from './SearchFilter.scss'

const cx = classNames.bind(styles)

class SearchFilter extends Component {
  constructor(props) {
    super()

    // alarm and alert specific code - need to remove in future
    const { userCountry, category: { categoryType } } = props
    const defaultCountries = userCountry ? [userCountry] : []
    const setDefaultValues = categoryType === 'countryCode'

    this.state = {
      values: setDefaultValues ? defaultCountries : []
    }
  }

  _updateValues (values) {
    this.setState({ values })
  }

  _createCategoryValue (value) {
    const { category: { categoryType, categoryParam } } = this.props

    return !categoryParam
      ? `${categoryType}.${value}`
      : `${categoryParam}.${value}`
  }

  handleSelection = (newValues, options) => {
    const {
      handleClose, setCurrentPage, addfilterCatogory, loadList, shouldBlockFetch,
      category, handheldDevice
    } = this.props
    const { values: prevValues } = this.state
    const VALUE_CLEARED = newValues.length === 0 && prevValues.length > 0
    const VALUE_SELECTED = newValues.length > prevValues.length

    if (VALUE_CLEARED) {
      setCurrentPage(1)
      addfilterCatogory(null,
        prevValues.map(value => this._createCategoryValue(value)))
      loadList(handheldDevice, shouldBlockFetch(category.categoryType, null))
      this._updateValues([])
    } else {
      let selectedValue

      if (VALUE_SELECTED) {
        selectedValue = newValues[newValues.length - 1]
      } else {
        selectedValue = prevValues
          .find(value => newValues.indexOf(value) === -1)
      }

      addfilterCatogory(null, this._createCategoryValue(selectedValue))
      setCurrentPage(1)
      loadList(handheldDevice, shouldBlockFetch(category.categoryType, selectedValue))

      this._updateValues(newValues)

      if (handleClose) {
        handleClose()
      }
    }
  }

  handleFilterOption = (input, option) => {
    const { props: { children } } = option

    if (children) {
      return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return false
  }

  render() {
    const { category, modeOfSelect, disableSearchFilter } = this.props
    const placeholder = (
      <FormattedMessage
        id={`next.LISTING_FILTER_SECTION_SEARCH_TYPE_PLACEHOLDER_${category.categoryType}`} />
    )

    return (
      <div
        key={`${category.categoryType}`}
        className={cx({'highlight-selection': category.searchBoxHighlightSelection})}>
        <Select
          allowClear
          mode={modeOfSelect}
          disabled={disableSearchFilter}
          style={{width: '100%'}}
          value={this.state.values}
          placeholder={placeholder}
          name={category.categoryType}
          onChange={this.handleSelection}
          optionFilterProp='children'
          options={category.categoryValues}
          notFoundContent={<FormattedMessage id='next.SELECT_COMP_NO_RESULTS_FOUND' />}
          filterOption={this.handleFilterOption} />
      </div>
    )
  }
}

SearchFilter.propTypes = {
  userCountry: PropTypes.string,
  modeOfSelect: PropTypes.string,
  category: PropTypes.object,
  handleClose: PropTypes.func,
  loadList: PropTypes.func,
  setCurrentPage: PropTypes.func,
  addfilterCatogory: PropTypes.func,
  shouldBlockFetch: PropTypes.func,
  disableSearchFilter: PropTypes.bool,
  handheldDevice: PropTypes.bool
}

SearchFilter.defaultProps = {
  modeOfSelect: 'multiple',
  disableSearchFilter: false
}

export default SearchFilter
