import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterModal from 'components/FilterModal/FilterModal'
import { selectors as listingSelectors } from 'store/modules/GenericListing'
class FIlterModalContainer extends PureComponent {
  static propTypes = {
    genericListing: PropTypes.object,
    selectedFilterValues: PropTypes.array
  }
  render() {
    const { genericListing, selectedFilterValues } = this.props
    return (
      <FilterModal
        {...this.props}
        selectedFilterValues={genericListing?.get('selectedFilterValues')?.toJS() || selectedFilterValues}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    genericListing: listingSelectors.genericListing(
      state,
      ownProps.listingName
    ),
  }
}

export default connect(mapStateToProps, null)(FIlterModalContainer)
