import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
// import { contractCovergeStatus } from 'constants/globalAlarmAlerts'

// Constants
export const constants = {
  FETCH: 'CUSTOMER_TECH_DASHBOARD/FETCH',
  RECEIVE: 'CUSTOMER_TECH_DASHBOARD/RECEIVE',
  FETCH_FAILURE: 'CUSTOMER_TECH_DASHBOARD/FETCH_FAILURE',
  RECEIVE_ALERTS: 'CUSTOMER_TECH_DASHBOARD/RECEIVE_ALERTS',
  RECEIVE_TECH_FILTERS: 'CUSTOMER_TECH_DASHBOARD/RECEIVE_TECH_FILTERS',
  RECEIVE_MAINTENANCE_FILTERS: 'CUSTOMER_TECH_DASHBOARD/RECEIVE_MAINTENANCE_FILTERS',
  MAINTENANCE_SYNC_SUCCESS: 'CUSTOMER_TECH_DASHBOARD/MAINTENANCE_SYNC_SUCCESS',
  MAINTENANCE_SYNC_FAILURE: 'CUSTOMER_TECH_DASHBOARD/MAINTENANCE_SYNC_FAILURE',
  RESET_MAINTENANCE_SYNC: 'CUSTOMER_TECH_DASHBOARD/RESET_MAINTENANCE_SYNC'
}

// Action Creators
export const actions = {
  getTechFilters(data) {
    return ({ type: constants.RECEIVE_TECH_FILTERS, data })
  },
  getMaintenanceFilters(data = []) {
    return ({ type: constants.RECEIVE_MAINTENANCE_FILTERS, data })
  },
  maintenanceSync (data) {
    return safeFetch({
      apiFunction: 'maintenanceSync',
      args: data,
      onSuccess: ({ data }) => ({ type: constants.MAINTENANCE_SYNC_SUCCESS }),
      onFailure: (error) => ({ type: constants.MAINTENANCE_SYNC_FAILURE, error: error.status })
    })
  },
  resetMaintenanceSync () {
    return ({ type: constants.RESET_MAINTENANCE_SYNC })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  techFilterCategories: [],
  maintenanceFilterCategories: [],
  maintenanceSyncSuccess: false,
  maintenanceSyncError: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RECEIVE_TECH_FILTERS:
      return state
        .set('fetching', false)
        .set('techFilterCategories', parseFilters(action.data))
    case constants.RECEIVE_MAINTENANCE_FILTERS:
      return state
        .set('fetching', false)
        .set('maintenanceFilterCategories', parseMaintenanceFilters(action.data))
    case constants.MAINTENANCE_SYNC_SUCCESS:
      return state
        .set('maintenanceSyncSuccess', true)
    case constants.MAINTENANCE_SYNC_FAILURE:
      return state
        .set('maintenanceSyncError', action.error)
    case constants.RESET_MAINTENANCE_SYNC:
      return state
        .set('maintenanceSyncSuccess', false)
        .set('maintenanceSyncError', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.customerTech

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  techFilterCategories: createSelector(getState, (state) => {
    return state.get('techFilterCategories')
  }),
  maintenanceFilterCategories: createSelector(getState, (state) => {
    return state.get('maintenanceFilterCategories')
  }),
  maintenanceSyncSuccess: createSelector(getState, (state) => {
    return state.get('maintenanceSyncSuccess')
  }),
  maintenanceSyncError: createSelector(getState, (state) => {
    return state.get('maintenanceSyncError')
  })
}

// helper functions
const parseFilters = (filters) => {
  const searchFilters = ['locationName']
  const requiredFilters = ['eventType', 'deviceName', 'contractStatus', 'locationName']
  const updatedFilters = []
  requiredFilters.forEach((name) => {
    const filterConfig = {
      searchBox: searchFilters.includes(name),
      searchBoxHighlightSelection: true
    }

    updatedFilters.push({
      categoryType: name,
      categoryValues: filters[name] ? filters[name].map(value => {
        if (name === 'contractStatus') {
          return {
            name: value,
            label: value,
            value: `${name}.${value}`
          }
        } else {
          const filterValue = name === 'locationName' ? value : `${name}.${value}`
          return {
            name: value,
            label: value,
            value: filterValue
          }
        }
      }) : [],
      ...filterConfig
    })
  })

  return fromJS(updatedFilters)
}

const parseMaintenanceFilters = (filters = {}) => {
  filters = {
    ...filters,
    dateRange: []
  }
  const searchFilters = ['locationName']
  const requiredFilters = ['deviceName', 'locationName', 'dateRange']
  const dateRangeFilters = ['dateRange']
  const updatedFilters = []

  requiredFilters.forEach((name) => {
    const filterConfig = {
      searchBox: searchFilters.includes(name),
      searchBoxHighlightSelection: true,
      dateRange: dateRangeFilters.includes(name)
    }

    updatedFilters.push({
      categoryType: name,
      categoryValues: filters[name] ? filters[name].map(value => ({
        name: value,
        label: value,
        value: name !== 'locationName' ? `${name}.${value}` : value
      })) : [],
      ...filterConfig
    })
  })

  return fromJS(updatedFilters)
}
