import React, { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actions, selectors } from 'store/modules/GlobalOverlay'

class GlobalOverlay extends Component {
  static propTypes = {
    element: PropTypes.node,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    timed: PropTypes.bool,
    speed: PropTypes.oneOf(['slow', 'default', 'fast']),
    animation: PropTypes.oneOf([
      'slide-top',
      'slide-right',
      'slide-bottom',
      'slide-left',
      'fade',
      'no-animation'
    ])
  }

  constructor(props) {
    super(props)
    this.state = {
      timeoutID: null
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const newProps = this.props
    if (newProps.element !== prevProps.element) {
      window.clearTimeout(this.state.timeoutID)
    }

    if (newProps.open && !prevProps.open && newProps.timed) {
      this.setState({
        timeoutID: window.setTimeout(
          newProps.handleClose,
          this.getTimeout(newProps.speed)
        )
      })
    }
  }
  getTimeout(speed) {
    switch (speed) {
      case 'slow':
        return 5000
      case 'fast':
        return 1000
      default:
        return 3000
    }
  }

  render() {
    const { element, open, handleClose } = this.props

    return (
      <div>
        {open ? cloneElement(element, { handleClose }) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    element: selectors.element(state),
    open: selectors.open(state),
    timed: selectors.timed(state),
    speed: selectors.speed(state),
    animation: selectors.animation(state)
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleClose: actions.close
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalOverlay)
