import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH_TOTAL_DEVICE_TYPES: 'IOT_ANALYSIS/FETCH_TOTAL_DEVICE_TYPES',
  RECEIVE_TOTAL_DEVICE_TYPES: 'IOT_ANALYSIS/RECEIVE_TOTAL_DEVICE_TYPES',
  TOTAL_DEVICE_TYPES_FAILURE: 'IOT_ANALYSIS/TOTAL_DEVICE_TYPES_FAILURE',

  FETCH_TOTAL_DEVICE_TYPES_PIE: 'IOT_ANALYSIS/FETCH_TOTAL_DEVICE_TYPES_PIE',
  RECEIVE_TOTAL_DEVICE_TYPES_PIE: 'IOT_ANALYSIS/RECEIVE_TOTAL_DEVICE_TYPES_PIE',
  TOTAL_DEVICE_TYPES_FAILURE_PIE: 'IOT_ANALYSIS/TOTAL_DEVICE_TYPES_FAILURE_PIE',

  FETCH_TOTAL_DEVICE_TYPES_IOT_ACTIVE: 'IOT_ANALYSIS/FETCH_TOTAL_DEVICE_TYPES_IOT_ACTIVE',
  RECEIVE_TOTAL_DEVICE_TYPES_IOT_ACTIVE: 'IOT_ANALYSIS/RECEIVE_TOTAL_DEVICE_TYPES_IOT_ACTIVE',
  TOTAL_DEVICE_TYPES_FAILURE_IOT_ACTIVE: 'IOT_ANALYSIS/TOTAL_DEVICE_TYPES_FAILURE_IOT_ACTIVE',

  FETCH_TOTAL_DEVICE_TYPES_IOT_CPABLE: 'IOT_ANALYSIS/FETCH_TOTAL_DEVICE_TYPES_IOT_CPABLE',
  RECEIVE_TOTAL_DEVICE_TYPES_IOT_CAPABLE: 'IOT_ANALYSIS/RECEIVE_TOTAL_DEVICE_TYPES_IOT_CAPABLE',
  TOTAL_DEVICE_TYPES_FAILURE_IOT_CPABLE: 'IOT_ANALYSIS/TOTAL_DEVICE_TYPES_FAILURE_IOT_CPABLE',

  FETCH_TOTAL_CONNECTIONS: 'IOT_ANALYSIS/FETCH_TOTAL_CONNECTIONS',
  RECEIVE_TOTAL_CONNECTIONS: 'IOT_ANALYSIS/RECEIVE_TOTAL_CONNECTIONS',
  TOTAL_DEVICE_CONNECTIONS_FAILURE: 'IOT_ANALYSIS/TOTAL_DEVICE_CONNECTIONS_FAILURE',

  FETCH_WORLD_MAP_DATA: 'IOT_ANALYSIS/FETCH_WORLD_MAP_DATA',
  RECEIVE_WORLD_MAP_DATA: 'IOT_ANALYSIS/RECEIVE_WORLD_MAP_DATA',
  TOTAL_WORLD_MAP_DATA_FAILURE: 'IOT_ANALYSIS/TOTAL_WORLD_MAP_DATA_FAILURE',

  FETCH_TOTAL_ENABLED_DEVICE: 'IOT_ANALYSIS/FETCH_TOTAL_ENABLED_DEVICE',
  RECEIVE_TOTAL_ENABLED_DEVICE: 'IOT_ANALYSIS/RECEIVE_TOTAL_ENABLED_DEVICE',
  TOTAL_ENABLED_DEVICE_FAILURE: 'IOT_ANALYSIS/TOTAL_DEVICE_TYPES_FAILURE_IOT_CPABLE',
}


// Action Creators
export const actions = {

  fetchTotalDeviceTypes(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_TOTAL_DEVICE_TYPES }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_TOTAL_DEVICE_TYPES, data }),
      onFailure: (error) => ({ type: constants.TOTAL_DEVICE_TYPES_FAILURE, error: error.toString() })
    })
  },

  fetchTotalDevicePieChart(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_TOTAL_DEVICE_TYPES_PIE }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_TOTAL_DEVICE_TYPES_PIE, data }),
      onFailure: (error) => ({ type: constants.TOTAL_DEVICE_TYPES_FAILURE_PIE, error: error.toString() })
    })
  },

  fetchTotalIOTActiveDeviceTypes(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_TOTAL_DEVICE_TYPES_IOT_ACTIVE }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_TOTAL_DEVICE_TYPES_IOT_ACTIVE, data }),
      onFailure: (error) => ({ type: constants.TOTAL_DEVICE_TYPES_FAILURE_IOT_ACTIVE, error: error.toString() })
    })
  },

  fetchTotalIOTActiveIOTCapableData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_TOTAL_DEVICE_TYPES_IOT_CPABLE }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_TOTAL_DEVICE_TYPES_IOT_CAPABLE, data }),
      onFailure: (error) => ({ type: constants.TOTAL_DEVICE_TYPES_FAILURE_IOT_CPABLE, error: error.toString() })
    })
  },

  fetchtotalIOTEnabledData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_TOTAL_ENABLED_DEVICE }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_TOTAL_ENABLED_DEVICE, data }),
      onFailure: (error) => ({ type: constants.TOTAL_ENABLED_DEVICE_FAILURE, error: error.toString() })
    })
  },

  fetchIOTConnections(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_TOTAL_CONNECTIONS }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_TOTAL_CONNECTIONS, data }),
      onFailure: (error) => ({ type: constants.TOTAL_DEVICE_CONNECTIONS_FAILURE, error: error.toString() })
    })
  },

  fetchWorldMapData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_WORLD_MAP_DATA }),
      apiFunction: 'getWorldMapData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_WORLD_MAP_DATA, data }),
      onFailure: (error) => ({ type: constants.TOTAL_WORLD_MAP_DATA_FAILURE, error: error.toString() })
    })
  },



}

// Reducer
export const initialState = fromJS({
  fetchingTotalDeviceTypes: false,
  fetchTotalDeviceError: null,
  totalDeviceData: null,

  fetchingTotalDeviceTypesPie: false,
  fetchTotalDeviceErrorPie: null,
  overallActiveDeviceData: null,

  fetchingTotalDeviceTypesIotActive: false,
  fetchTotalDeviceErrorIotActive: null,
  totalIOTActiveDeviceData: null,

  fetchingTotalDeviceTypesIotCapable: false,
  fetchTotalDeviceErrorIotCapable: null,
  totalIOTActiveIOTCapableData: null,

  fetchingTotalDeviceTypesIotEnabled: false,
  fetchTotalDeviceErrorIotEnabled: null,
  totalIOTEnabledData: null,

  fetchingTotalConnections: false,
  fetchTotalConnectionsError: null,
  totalIOTConnectionsAchieved:null,

  fetchingWorldMapData: false,
  fetchMapError: null,
  worldMapData:null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_TOTAL_DEVICE_TYPES:
      return state
        .set('fetchingTotalDeviceTypes', true)
        .set('fetchTotalDeviceError', null)

    case constants.RECEIVE_TOTAL_DEVICE_TYPES:
      return state
        .set('fetchingTotalDeviceTypes', false)
        .set('totalDeviceData', action.data.data)

    case constants.TOTAL_DEVICE_TYPES_FAILURE:
      return state
        .set('fetchTotalDeviceError', action.error)
        .set('fetchingTotalDeviceTypes', false)

    case constants.FETCH_TOTAL_DEVICE_TYPES_PIE:
      return state
        .set('fetchingTotalDeviceTypesPie', true)
        .set('fetchTotalDeviceErrorPie', null)

    case constants.RECEIVE_TOTAL_DEVICE_TYPES_PIE:
      return state
        .set('fetchingTotalDeviceTypesPie', false)
        .set('overallActiveDeviceData', action.data.data)

    case constants.TOTAL_DEVICE_TYPES_FAILURE_PIE:
      return state
        .set('fetchTotalDeviceErrorPie', action.error)
        .set('fetchingTotalDeviceTypesPie', false)

    case constants.FETCH_TOTAL_DEVICE_TYPES_IOT_ACTIVE:
      return state
        .set('fetchingTotalDeviceTypesIotActive', true)
        .set('fetchTotalDeviceErrorIotActive', null)

    case constants.RECEIVE_TOTAL_DEVICE_TYPES_IOT_ACTIVE:
      return state
        .set('fetchingTotalDeviceTypesIotActive', false)
        .set('totalIOTActiveDeviceData', action.data.data)

    case constants.TOTAL_DEVICE_TYPES_FAILURE_IOT_ACTIVE:
      return state
        .set('fetchTotalDeviceErrorIotActive', action.error)
        .set('fetchingTotalDeviceTypesIotActive', false)

    case constants.RECEIVE_TOTAL_DEVICE_TYPES_IOT_CAPABLE:
      return state
        .set('fetchingTotalDeviceTypesIotCapable', false)
        .set('totalIOTActiveIOTCapableData', action.data.data)

        case constants.RECEIVE_TOTAL_ENABLED_DEVICE:
          return state
            .set('fetchingTotalDeviceTypesIotEnabled', false)
            .set('totalIOTEnabledData', action.data.data)

        case constants.RECEIVE_TOTAL_CONNECTIONS:
          return state
            .set('fetchingTotalConnections', false)
            .set('totalIOTConnectionsAchieved', action.data.data)

            case constants.RECEIVE_WORLD_MAP_DATA:
              return state
                .set('fetchingWorldMapData', false)
                .set('worldMapData', action.data.data)

    default:
      return state
  }
}

// Selectors
const getState = (state) => state.iotConnectionAnalysis

export const selectors = {
  fetchingTotalDeviceTypes: createSelector(getState, (state) =>
    state.get('fetchingTotalDeviceTypes')
  ),
  fetchTotalDeviceError: createSelector(getState, (state) =>
    state.get('fetchTotalDeviceError')
  ),
  totalDeviceData: createSelector(getState, (state) =>
    state.get('totalDeviceData')
  ),
  fetchingTotalDeviceTypesPie: createSelector(getState, (state) =>
    state.get('fetchingTotalDeviceTypesPie')
  ),
  fetchTotalDeviceErrorPie: createSelector(getState, (state) =>
    state.get('fetchTotalDeviceErrorPie')
  ),
  overallActiveDeviceData: createSelector(getState, (state) =>
    state.get('overallActiveDeviceData')
  ),
  totalIOTActiveDeviceData: createSelector(getState, (state) =>
    state.get('totalIOTActiveDeviceData')
  ),
  totalIOTActiveIOTCapableData: createSelector(getState, (state) =>
    state.get('totalIOTActiveIOTCapableData')
  ),
  totalIOTEnabledData: createSelector(getState, (state) =>
  state.get('totalIOTEnabledData')
),
  totalIOTConnectionsAchieved: createSelector(getState, (state) =>
  state.get('totalIOTConnectionsAchieved')
  ),
  worldMapData: createSelector(getState, (state) =>
  state.get('worldMapData')
  )
}

