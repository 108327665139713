export const isSystemDefinedUser = (roleId, roles) => {
  return roles.some((role) => role.roleId === roleId && role.systemDefined)
}

export const isCardAccess = (operation, privilages) => {
  let canAccess = false
  const oper = operation.toUpperCase()
  const types = 'devicetypes'
  if (privilages[types]) {
    const deviceTypes = Object.values(privilages[types])
    deviceTypes.forEach((deviceType) => {
      const cardTypes = Object.values(deviceType)
      cardTypes.forEach((cards) => {
        if (oper in cards) {
          canAccess = true
        }
      })
    })
  }
  return canAccess
}

export const orgSettings = [
  {
    appName: 'customer',
    logo: 'customerLogo',
    theme: 'customerTheme',
    dashboard: 'CustomerTechDashboardView'
  },
  {
    appName: 'customerQuality',
    logo: 'customerQualityLogo',
    theme: 'customerTheme',
    dashboard: 'CustomerQualityDashboardView'
  },
  {
    appName: 'customerTech',
    logo: 'customerTechLogo',
    theme: 'customerTheme',
    dashboard: 'CustomerTechDashboardView'
  },
  {
    appName: 'labwater',
    logo: 'labwaterLogo',
    theme: 'labwaterTheme',
    dashboard: 'GlobalAlertsAndAlarmsView'
  },
  {
    appName: 'labwater',
    logo: 'labwaterLogo',
    theme: 'labwaterTheme',
    dashboard: 'PartnerAdminDashboardView'
  },
  {
    appName: 'default',
    logo: 'logo',
    theme: 'defaultTheme',
    dashboard: 'DefaultDashboardView'
  }
]

export const dashboards = {
  'default': 'DefaultDashboardView',
  'labwater': 'GlobalAlertsAndAlarmsView',
  'custdefault': 'CustomerTechDashboardView',
  'custquality': 'CustomerQualityDashboardView',
  'merck': 'DefaultDashboardView',
  'labwater_customer': 'GlobalAlertsAndAlarmsView',
  'partnerhomepage': 'PartnerAdminDashboardView'
}

export const appNames = {
  labwater: 'labwater',
  customerTech: 'customerTech',
  customerQuality: 'customerQuality',
  default: 'default'
}

export const appLogos = {
  labwater: 'labwaterLogo',
  customer: 'customerLogo',
  customerTech: 'customerTechLogo',
  customerQuality: 'customerQualityLogo',
  default: 'logo'
}

export const hashingFunction = (username) => {
  let hashValue = username.split('').reduce((prevHash, currVal) =>
    (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0)
  return 'uid' + hashValue.toString()
}
