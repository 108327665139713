export const OPERATION = 'assign_device'
export const RESOURCE_TYPE = 'entity'
export const searchFilters = {
  PARTNER_NAME: 'partnerName',
  CUSTOMER_NAME: 'customerName',
  LOCATION_NAME: 'locationName',
  COUNTRY_CODE: 'countryCode',
  DEVICE_NAME: 'deviceName',
  STATUS: 'status'
}
export const moduleId = 'CustomerDevices'
export const moduleIdApproval = 'CustomerDevicesApproval'

