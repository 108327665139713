import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'DATA_ANALYSIS/FETCH',
  RECEIVE_USER_DATA: 'DATA_ANALYSIS/RECEIVE_USER_DATA',
  FETCH_FAILURE: 'DATA_ANALYSIS/FETCH_FAILURE',
  FETCH_REPORT: 'DATA_ANALYSIS/FETCH_REPORT',
  FETCHING_FILE: 'DATA_ANALYSIS/FETCHING_FILE',
  DOWNLOAD_FAIL: 'DATA_ANALYSIS/DOWNLOAD_FAIL',

  FETCH_FILTERS: 'DATA_ANALYSIS/FETCH_FILTERS',
  RECEIVE_FILTER_CATEGORIES:'DATA_ANALYSIS/RECEIVE_FILTER_CATEGORIES',
  FETCH_FILTERS_FAIL: 'DATA_ANALYSIS/FETCH_FILTERS_FAIL',

  FETCH_MAP_FILTERS: 'DATA_ANALYSIS/FETCH_MAP_FILTERS',
  RECEIVE_MAP_CATEGORIES:'DATA_ANALYSIS/RECEIVE_MAP_CATEGORIES',
  FETCH_MAP_FAIL: 'DATA_ANALYSIS/FETCH_MAP_FAIL',
}

export const resolveStream = ({data, headers}) => {
  var cd = headers.get('content-disposition')
  let fileName = `${cd.split('=')[2]}`.replace(/["']/g, '')
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(data, fileName)
  } else {
    let url = window.URL.createObjectURL(data)
    var element = document.createElement('a')
    document.body.appendChild(element)
    element.style = 'display: none'
    element.href = url
    element.download = fileName
    element.click()
    document.body.removeChild(element)
    window.URL.revokeObjectURL(url)
  }
}

// Action Creators
export const actions = {

  fetchUserProfileData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_USER_DATA, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchKpiFilters() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTERS }),
      apiFunction: 'fetchKpiFilters',
      args: [],
      onSuccess: (data) => ({ type: constants.RECEIVE_FILTER_CATEGORIES, data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTERS_FAIL, error: error.toString() })
    })
  },
  fetchMapFilters() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_MAP_FILTERS }),
      apiFunction: 'fetchMapFilters',
      args: [],
      onSuccess: (data) => ({ type: constants.RECEIVE_MAP_CATEGORIES, data }),
      onFailure: (error) => ({ type: constants.FETCH_MAP_FAIL, error: error.toString() })
    })
  },
  generateUserReport(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCHING_FILE }),
      apiFunction: 'generateUserReport',
      args: payload,
      onSuccess: (data) => {
        resolveStream(data)
        return { type: constants.FETCH_REPORT}
      },
      onFailure: (error) => ({ type: constants.DOWNLOAD_FAIL, error: error.errorMessage.toString() })
    })
  },

}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  kpiUserProfileData: null,
  downloadError: null,
  fetchingFile: null,
  downloadSuccessful:false,
  filterCategories:null,
  mapFilterCategories:null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCHING_FILE:
      return state
        .set('fetchingFile', true)
        .set('downloadError', null)
        .set('downloadSuccessful', false)

    case constants.RECEIVE_USER_DATA:
      return state
        .set('fetching', false)
        .set('kpiUserProfileData', action.data.data)

    case constants.RECEIVE_FILTER_CATEGORIES:
          return state
            .set('fetching', false)
            .set('filterCategories', formatFilterCategories(action.data.data))
    case constants.RECEIVE_MAP_CATEGORIES:
       return state
                .set('fetching', false)
                .set('mapFilterCategories',formatMapFilterCategories(action.data.data))

    case constants.FETCH_REPORT:
      return state
        .set('fetchingFile', false)
        .set('downloadError', null)
        .set('downloadSuccessful', true)

    case constants.DOWNLOAD_FAIL:
      return state
        .set('fetchingFile', false)
        .set('downloadError', action.error)
        .set('downloadSuccessful', false)

    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.dataAnalysis

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  kpiUserProfileData: createSelector(getState, (state) =>
    state.get('kpiUserProfileData')
  ),
  fetchingFile: createSelector(getState, (state) =>
    state.get('fetchingFile')
  ),
  filterCategories:createSelector(getState, (state) =>
  state.get('filterCategories')
),
mapFilterCategories:createSelector(getState, (state) =>
state.get('mapFilterCategories')
),
  downloadSuccessful: createSelector(getState, (state) =>
  state.get('downloadSuccessful')
),
  downloadError: createSelector(getState, (state) =>
  state.get('downloadError')
),
}

const formatFilterCategories = (filters) =>{

  let finalFilterCategories=[]
  let categoryValuesDevice = []
  let categoryValuesCountry = []
  let categoryValuesAccount = []
   filters?.deviceTypeList?.forEach((elem,i)=>{
      categoryValuesDevice.push({'name':elem,'label':elem,value:'deviceType.'+elem,key:i})
    })
    filters?.countryCodeList?.forEach((elem,i)=>{
      categoryValuesCountry.push({'name':elem,'label':elem,value:elem,key:i})
    })  
    filters?.accountNameList?.forEach((elem,i)=>{
      categoryValuesAccount.push({'name':elem,'label':elem,value:elem,key:i})
    })
 
    let categoryValuesDate = [{
      'name': '1 month',
      'label': '1 month',
      'value': 'date.1 month'
  },
  {
      'name': '3 months',
      'label': '3 months',
      'value': 'date.3 months'
  },
  {
     'name': '6 months',
      'label': '6 months',
      'value': 'date.6 months'
  // },
  // {
  //     'name': '12 months',
  //     'label': '12 months',
  //     'value': 'date.12 months'
  }]
  finalFilterCategories.push({categoryType:'date',categoryValues:categoryValuesDate,searchBox:null})
  finalFilterCategories.push({categoryType:'accountName',categoryValues:categoryValuesAccount,searchBox:true})
  finalFilterCategories.push({categoryType:'countryCode',categoryValues:categoryValuesCountry,searchBox:true})
  finalFilterCategories.push({categoryType:'deviceType',categoryValues:categoryValuesDevice,searchBox:null})
  return fromJS(finalFilterCategories)
}
const formatMapFilterCategories = (filters) =>{

  let finalFilterCategories=[]
  let categoryValuesDevice = []
  let categoryValuesOrganisation = []
  let categoryValuesStatus = []
   filters?.deviceTypeList?.forEach((elem,i)=>{
      categoryValuesDevice.push({'name':elem,'label':elem,value:'deviceType.'+elem,key:i})
    })
    filters?.orgNameList?.forEach((elem,i)=>{
      categoryValuesOrganisation.push({'name':elem,'label':elem,value:elem,key:i})
    })  
    filters?.deviceStatusList?.forEach((elem,i)=>{
      categoryValuesStatus.push({'name':elem,'label':elem,value:'status.'+elem,key:i})
    })
  finalFilterCategories.push({categoryType:'status',categoryValues:categoryValuesStatus,searchBox:null})
  finalFilterCategories.push({categoryType:'disOrgName',categoryValues:categoryValuesOrganisation,searchBox:true})
  finalFilterCategories.push({categoryType:'deviceType',categoryValues:categoryValuesDevice,searchBox:null})
  return fromJS(finalFilterCategories)
}

