import React from 'react'
import merckLogo from 'static/images/merck-logo.png'
import './MerckVibrantLogo.scss'

const MerckVibrantLogo = (props) => {
  return (
    <div className='merck-vibrant-logo'>
      <img alt src={merckLogo} />
    </div>
  )
}

export default MerckVibrantLogo
