export const moduleId = 'orgUsers'

export const filterTypes = {
  'ROLE': 'role',
  'STATUS': 'status',
  'COUNTRY_CODE': 'countryCode'
}
export const orgTypes = {
  PARTNERS: 'Partners',
  CUSTOMERS: 'Customers',
  DISTRIBUTORS: 'Distributors',
}
export const statusOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
]

export const historicalDataSubsArr = [
  {
    value: 24,
    label: '2 Years'
  },
  {
    value: -1,
    label: 'Full History'
  }
]
