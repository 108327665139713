import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isDragMenu: PropTypes.bool
}
const deviceSvg = (props) => {
  const { className, isDragMenu } = props
  const isActve = className && className.length && className.includes('active')
  return (
    <svg
      className={props.className}
      width={`${props.width}`}
      height={`${props.height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15.25 20.78'
    >
      <defs></defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            style={{
              fill: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              stroke: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              strokeMiterlimit: '10',
              strokeWidth: '0.25px',
              ...props.style,
            }}
            d='M14.74,16.94a.37.37,0,0,0-.39.35v1.5a.43.43,0,0,1-.45.41H1.35a.43.43,0,0,1-.45-.41V1.21a.43.43,0,0,1,.45-.4H13.9a.43.43,0,0,1,.45.4V16.06a.39.39,0,0,0,.77,0V1.21A1.16,1.16,0,0,0,13.9.13H1.35A1.16,1.16,0,0,0,.13,1.21V18.79a1.1,1.1,0,0,0,.84,1v.49a.36.36,0,0,0,.38.34.36.36,0,0,0,.39-.34v-.43H13.51v.43a.39.39,0,0,0,.77,0v-.49a1.11,1.11,0,0,0,.85-1v-1.5A.38.38,0,0,0,14.74,16.94Z'
          />
          <path
            style={{
              fill: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              stroke: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              strokeMiterlimit: '10',
              strokeWidth: '0.25px',
              ...props.style,
            }}
            d='M12.33,3.38H11.28a.36.36,0,0,0-.39.34.36.36,0,0,0,.39.34H12V5.52H3.3V4.06H9.89a.36.36,0,0,0,.38-.34.36.36,0,0,0-.38-.34h-7a.36.36,0,0,0-.39.34V5.86a.36.36,0,0,0,.39.34h9.41a.36.36,0,0,0,.39-.34V3.72A.36.36,0,0,0,12.33,3.38Z'
          />
          <path
            style={{
              fill: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              stroke: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              strokeMiterlimit: '10',
              strokeWidth: '0.25px',
              ...props.style,
            }}
            d='M9.79,15.4H7.43a.34.34,0,1,0,0,.68H9.79a.34.34,0,1,0,0-.68Z'
          />
          <path
            style={{
              fill: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              stroke: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              strokeMiterlimit: '10',
              strokeWidth: '0.25px',
              ...props.style,
            }}
            d='M6.14,15.4H5.46a.34.34,0,1,0,0,.68h.68a.34.34,0,1,0,0-.68Z'
          />
          <path
            style={{
              fill: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              stroke: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              strokeMiterlimit: '10',
              strokeWidth: '0.25px',
              ...props.style,
            }}
            d='M9.79,13.92H5.46a.35.35,0,1,0,0,.69H9.79a.35.35,0,1,0,0-.69Z'
          />
          <path
            style={{
              fill: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              stroke: isActve ? '#FFF' : isDragMenu ? 'white': '#afafaf',
              strokeMiterlimit: '10',
              strokeWidth: '0.25px',
              ...props.style,
            }}
            d='M9.79,12.45H5.46a.34.34,0,1,0,0,.68H9.79a.34.34,0,1,0,0-.68Z'
          />
        </g>
      </g>
    </svg>
  )
}
deviceSvg.defaultProps= {
  width: '20',
  height: '20'
}
deviceSvg.propTypes = propTypes
export default deviceSvg
