import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { filterTypes } from 'constants/serviceContractManagement'

export const moduleId = 'serviceContract'

//Constants
export const constants = {
    RECEIVE_SERVICE_CONTRACT_FILTERS: 'SERVICE_CONTRACT/RECEIVE_SERVICE_CONTRACT_FILTERS'
}

// Actions
export const actions = {
    fetchServiceContractFilters(data) {
        return ({ type: constants.RECEIVE_SERVICE_CONTRACT_FILTERS, data })
    }
}

// Reducer
export const initialState = fromJS({
    serviceContractfilterCategories: []
})
export default function (state = initialState, action) {
    switch (action.type) {
        case constants.RECEIVE_SERVICE_CONTRACT_FILTERS:
            return state
                .set('serviceContractfilterCategories', parseServiceContractFilters(action.data))
        default:
            return state
    }
}

// Selectors
const getState = (state) => state.serviceContract

export const selectors = {
    serviceContractfilterCategories: createSelector(getState, (state) => (
        state.get('serviceContractfilterCategories')
    ))
}

// Helper functions

function parseServiceContractFilters(serviceFilters) {
    let parsedServiceFilters = []
    const filterOrder = [
        filterTypes.ACCOUNT_NAME,
        filterTypes.CONTRACT_STATUS,
        filterTypes.COUNTRIES
    ]
    filterOrder.forEach((filter) => {
        let isSearchBox = filter !== filterTypes.CONTRACT_STATUS
        if (filter in serviceFilters && serviceFilters[filter]) {
            const values = serviceFilters[filter]
            const categoryType = filter
            const categoryValues = !values
                ? []
                : values.map((val, index) => {
                    return {
                        label: val,
                        value: isSearchBox ? val : `${filter}.${val}`,
                        key: index,
                        name: val
                    }
                })
            parsedServiceFilters.push({ categoryType, categoryValues, searchBox: isSearchBox })
        }
    })
    return parsedServiceFilters
}