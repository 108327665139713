import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

import {
  selectors as pageSelectors
} from 'store/modules/Pagination'
import {
  selectors as userSelectors
} from 'store/modules/User'
import safeFetch from 'store/utils/safeFetch'
import * as dataUtils from 'utils/dataUtils'
import { filterTypes as deviceFilterTypes } from 'constants/deviceList'

// Constants
export const constants = {
  FETCH: 'DEVICES/FETCH',
  RECEIVE_DEVICES: 'DEVICES/RECEIVE_DEVICES',
  FETCH_FAILURE: 'DEVICES/FETCH_FAILURE',
  TOGGLE_VIEW: 'DEVICES/TOGGLE_VIEW',
  SET_SEARCH_TERM: 'DEVICES/SET_SEARCH_TERM',
  ADD_FILTER_CATEGORY: 'DEVICES/ADD_FILTER_CATEGORY',
  REMOVE_FILTER_CATEGORY: 'DEVICES/REMOVE_FILTER_CATEGORY',
  ADD_FILTER_CATEGORY_VALUES: 'DEVICES/ADD_FILTER_CATEGORY_VALUES',
  ADD_FILTERS: 'DEVICES/ADD_FILTERS',
  SORT: 'DEVICES/SORT',
  RESET: 'DEVICES/RESET',
  RECEIVE_FILTER_CATEGORIES: 'DEVICES/RECEIVE_FILTER_CATEGORIES',
  SELECTED_FILTER: 'DEVICES/SELECTED_FILTER',
  RECIEVE_CONTRACT_VALUES_SUCCESS: 'DEVICES/RECIEVE_CONTRACT_VALUES_SUCCESS',
  RECIEVE_CONTRACT_VALUES_FAILURE: 'DEVICES/RECIEVE_CONTRACT_VALUES_FAILURE',
  CONTRACT_VALUES_RESET: 'DEVICES/CONTRACT_VALUES_RESET',
  ASSIGN_DEVICES_SUCCESS: 'DEVICES/ASSIGN_DEVICES_SUCCESS',
  ASSIGN_DEVICES_FAILURE: 'DEVICES/ASSIGN_DEVICES_FAILURE',
  DEVICE_ASSIGN_ACCESS_SUCCESS: 'DEVICES/DEVICE_ASSIGN_ACCESS_SUCCESS',
  DEVICE_ASSIGN_ACCESS_FAILURE: 'DEVICES/DEVICE_ASSIGN_ACCESS_FAILURE',
  DEVICE_ASSIGN_LISTING_SUCCESS: 'DEVICES/DEVICE_ASSIGN_LISTING_SUCCESS',
  DEVICE_ASSIGN_LISTING_FAILURE: 'DEVICES/DEVICE_ASSIGN_LISTING_FAILURE',
  CLEAR_DEVICE_HISTORY: 'DEVICES/CLEAR_DEVICE_HISTORY',
}

// Action Creators
export const actions = {
  getDeviceFetchQuery(getState) {
    const filterBy = selectors.selectedFilterValues(getState())
    const sortBy = selectors.sortColumn(getState())
    const sortDirection = selectors.sortDirection(getState())
    const filterType = selectors.filterType(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const searchTerm = selectors.searchTerm(getState())
    // If page property value set to false values (0 or undefined) api will throw error
    // api can't handle million device records
    const page = pageSelectors.pagination(getState()).getIn(['devicesPagination', 'currentPage']) || 1

    const query = {
      filterBy: filterBy && filterBy.toJS(),
      sortBy: sortBy,
      orderBy: orderBy,
      filterType: filterType,
      searchBy: searchTerm,
      page: page
    }
    return query
  },
  loadDevices(isHandheldDevice = false) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      preventMultipleRequest: true,
      apiFunction: 'fetchDevices',
      args: actions.getDeviceFetchQuery,
      onSuccess: (devices) => ({ type: constants.RECEIVE_DEVICES, devices, isHandheldDevice }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  loadDeviceFilters() {
    return async (dispatch, getState) => {
      let filterCategories = selectors.filterCategories(getState()).toJS()
      dispatch({ type: constants.FETCH })
      try {
        const filters = filterCategories.map((filter) => {
          const categoryValues = filter.categoryValues
          const modifiedCategoryValues = categoryValues.map((item) => {
            return { checked: false, ...item }
          })
          return { ...filter, categoryValues: modifiedCategoryValues }
        })
        dispatch({ type: constants.ADD_FILTERS, filters })
      } catch (error) {
        dispatch({ type: constants.FETCH_FAILURE, error: error })
      }
    }
  },
  toggleView(toggle) {
    return { type: constants.TOGGLE_VIEW, toggle }
  },
  applySort(sortColumn) {
    return { type: constants.SORT, sortColumn }
  },
  setSearchTerm(searchTerm) {
    return { type: constants.SET_SEARCH_TERM, searchTerm }
  },
  addfilterCatogory(selectedCategory, selectedValue) {
    return { type: constants.SELECTED_FILTER, selectedValue }
  },
  addfilterCatogoryValues(categoryValue, isChecked) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.FETCH })
      const deviceFilters = selectors.filters(getState()).toJS()
      const filters = deviceFilters.map((filter) => {
        const categoryValues = filter.categoryValues
        const modifiedCategoryValues = categoryValues.map((item) => {
          return item.name === categoryValue
            ? { ...item, checked: isChecked }
            : { ...item }
        }
        )
        return { ...filter, categoryValues: modifiedCategoryValues }
      }
      )
      dispatch({ type: constants.ADD_FILTERS, filters })
      dispatch({ type: constants.ADD_FILTER_CATEGORY_VALUES, categoryValue })
    }
  },
  setCurrentPage(page) {
    return { type: constants.SET_CURRENT_PAGE, page }
  },
  reset() {
    return { type: constants.RESET }
  },
  fetchFilterCatogories() {
    return async (dispatch, getState) => {
      const isCustomerView = userSelectors.getUserPrivilege(getState())?.getIn(['devicelisting', 'CUST'])
      await dispatch(actions.fetchFilters(isCustomerView))
      await dispatch(actions.loadDeviceFilters())
    }
  },
  fetchFilters(isCustomerView) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getCustomers',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_FILTER_CATEGORIES, data, isCustomerView }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchContractValues(serialId) {
    return safeFetch({
      apiFunction: 'fetchContractValues',
      args: serialId,
      onSuccess: (data) => ({ type: constants.RECIEVE_CONTRACT_VALUES_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RECIEVE_CONTRACT_VALUES_FAILURE, error })
    })
  },
  addAssignDevices(deviceIds) {
    return safeFetch({
      apiFunction: 'addAssignDevices',
      args: { deviceIds },
      onSuccess: () => ({ type: constants.ASSIGN_DEVICES_SUCCESS }),
      onFailure: (error) => ({ type: constants.ASSIGN_DEVICES_FAILURE, error: error }),
      throwError: true
    })
  },
  contractReset() {
    return { type: constants.CONTRACT_VALUES_RESET }
  },
  assignDeviceAccess(payload) {
    return safeFetch({
      apiFunction: 'addNewDeviceRequest',
      args: payload,
      onSuccess: (data) => ({ type: constants.DEVICE_ASSIGN_ACCESS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.DEVICE_ASSIGN_ACCESS_FAILURE, error: error }),
      throwError: true
    })
  },
  getDeviceHistoryFetchQuery(getState) {
    const page = pageSelectors.pagination(getState()).getIn(['deciceAccessRequest', 'currentPage'])
    const args = {
      page,
      pageLimit: 10
    }
    return args
  },
  getDeviceAccessList() {
    return safeFetch({
      apiFunction: 'deviceAccessRequesterList',
      args: actions.getDeviceHistoryFetchQuery,
      onSuccess: ({ data, headers }) => ({ type: constants.DEVICE_ASSIGN_LISTING_SUCCESS, data, headers }),
      onFailure: (error) => ({ type: constants.DEVICE_ASSIGN_LISTING_FAILURE, error: error }),
      throwError: true
    })
  },
  clearDeviceHistory () {
    return { type: constants.CLEAR_DEVICE_HISTORY }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  data: [],
  tileView: false,
  searchTerm: '',
  sortColumn: 'deviceName',
  sortDirection: true,
  filterType: '',
  filterBy: [],
  filters: [],
  filterCategoryType: [],
  deviceTypesFilters: [],
  customers: [],
  filterCategories: [],
  selectedFilterValues: [],
  deviceData: [],
  fetchSerialError: null,
  deviceRequestList: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.RECEIVE_DEVICES:
      if (action.isHandheldDevice) {
        let devices = state.get('data')

        return state
          .set('data', fromJS([
            ...devices,
            ...action.devices.data
          ]))
          .set('totalPages', parseInt(action.devices.headers.get('X-Pagination-Count')))
          .set('fetching', false)
      }

      return state
        .set('data', fromJS(action.devices.data))
        .set('totalPages', parseInt(action.devices.headers.get('X-Pagination-Count')))
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.TOGGLE_VIEW:
      return state
        .set('tileView', action.toggle)
    case constants.SET_SEARCH_TERM:
      return state
        .set('searchTerm', action.searchTerm)
    case constants.ADD_FILTER_CATEGORY:
      return state
        .set('filterType', action.filterType)
        .updateIn(['filterCategoryType'], (state) => {
          return state.find(categoryType => categoryType === action.filterType)
            ? state : state.push(action.filterType)
        })
    case constants.REMOVE_FILTER_CATEGORY:
      return state
        .updateIn(['filterCategoryType'], (state) => {
          return state.find(categoryType => categoryType === action.categoryType)
            ? state.filter(item => item !== action.categoryType) : state
        })
    case constants.ADD_FILTER_CATEGORY_VALUES:
      return state
        .updateIn(['filterBy'], (state) => {
          return state.find(category => category === action.categoryValue)
            ? state.filter(item => item !== action.categoryValue) : state.push(action.categoryValue)
        })
    case constants.ADD_FILTERS:
      return state
        .set('filters', fromJS(action.filters))
        .set('fetching', false)
    case constants.SORT:
      return state
        .set('sortColumn', action.sortColumn)
        .set('sortDirection', !state.get('sortDirection'))
    case constants.RESET:
      return state
        .set('filterBy', fromJS([]))
        .set('selectedFilterValues', fromJS([]))
        .set('searchTerm', '')
        .set('tileView', false)
        .set('sortColumn', 'deviceName')
        .set('sortDirection', true)
    case constants.RECEIVE_FILTER_CATEGORIES:
      return state
        .set('filterCategories', formatFilterCategories(action.data, action.isCustomerView))
        .set('fetching', false)
    case constants.SELECTED_FILTER:
      return toggleFilterValues(state, action.selectedValue)
    case constants.RECIEVE_CONTRACT_VALUES_SUCCESS:
      return state
        .set('deviceData', action.data.data)
        .set('fetching', false)
        .set('fetchSerialError', null)
    case constants.RECIEVE_CONTRACT_VALUES_FAILURE:
      return state
        .set('deviceData', fromJS([]))
        .set('fetchSerialError', action.error)
        .set('fetching', false)
    case constants.CONTRACT_VALUES_RESET:
      return state
        .set('deviceData', fromJS([]))
        .set('fetchSerialError', null)
        .set('fetching', false)
    case constants.ASSIGN_DEVICES_SUCCESS:
      return state
        .set('fetching', false)
    case constants.ASSIGN_DEVICES_FAILURE:
      return state
        .set('fetchSerialError', action.error)
        .set('fetching', false)
    case constants.DEVICE_ASSIGN_LISTING_SUCCESS:
      return state
        .set('deviceRequestList', action.data)
        .set('totalPages', parseInt(fromJS(action.headers.get('X-Pagination-Count'))))
        .set('fetching', false)
    case constants.DEVICE_ASSIGN_LISTING_FAILURE:
      return state
        .set('deviceRequestList', fromJS([]))
        .set('fetching', false)
    case constants.CLEAR_DEVICE_HISTORY:
      return state
        .set('totalPages', 0)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.devices

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  devices: createSelector(getState, (state) =>
    state.get('data') || []
  ),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages') || 0
  ),
  currentPage: createSelector(getState, (state) =>
    state.get('currentPage') || ''
  ),
  tileView: createSelector(getState, (state) =>
    state.get('tileView')
  ),
  searchTerm: createSelector(getState, (state) =>
    state.get('searchTerm')
  ),
  sortColumn: createSelector(getState, (state) =>
    state.get('sortColumn')
  ),
  sortDirection: createSelector(getState, (state) =>
    state.get('sortDirection')
  ),
  filterBy: createSelector(getState, (state) =>
    state.get('filterBy') || []
  ),
  filterType: createSelector(getState, (state) =>
    state.get('filterType')
  ),
  filters: createSelector(getState, (state) =>
    state.get('filters') || []
  ),
  filterCount: createSelector(getState, (state) =>
    state.get('filterBy').size || 0
  ),
  filterCategories: createSelector(getState, (state) =>
    state.get('filterCategories')
  ),
  selectedFilterValues: createSelector(getState, (state) =>
    state.get('selectedFilterValues')
  ),
  deviceData: createSelector(getState, (state) =>
    state.get('deviceData')
  ),
  fetchSerialError: createSelector(getState, (state) =>
    state.get('fetchSerialError')
  ),
  deviceRequestList: createSelector(getState, (state) =>
    state.get('deviceRequestList')
  )
}

// helper function

function formatFilterCategories(filters, isCustomerView) {
  const requiredFilters = isCustomerView
    ? [deviceFilterTypes.DEVICE_TYPE, deviceFilterTypes.DEVICE_LOCATION, deviceFilterTypes.CONNECTED_STATUS, deviceFilterTypes.INSTALLED_DATE]
    : [deviceFilterTypes.DEVICE_TYPE, deviceFilterTypes.CONNECTED_STATUS, deviceFilterTypes.CUSTOMER_NAME, deviceFilterTypes.INSTALLED_DATE, deviceFilterTypes.COUNTRY_CODE, deviceFilterTypes.ORGANIZATION_NAME]
  let updatedFilters = []

  requiredFilters.forEach((filter) => {
    if (filter in filters && filters[filter] !== null) {
      const values = filters[filter]
      let isSearchBox = isCustomerView ? filter === deviceFilterTypes.DEVICE_LOCATION : (filter === deviceFilterTypes.COUNTRY_CODE || filter === deviceFilterTypes.CUSTOMER_NAME)
      const categoryType = (filter === deviceFilterTypes.DEVICE_NAME)
        ? `listing${filter}`
        : (filter === deviceFilterTypes.DEVICE_LOCATION)
          ? deviceFilterTypes.LOCATION_NAME
          : filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => ({
          name: val,
          label: val,
          key: index,
          value: val
        }))
      updatedFilters.push({ categoryType, categoryValues, searchBox: isSearchBox })
      // For Device Name and Customer Name Attributes, conflict in generic filters listing section titile,
      // To display specific label name, template literals added.
    }
  })
  return fromJS(updatedFilters)
}

const toggleFilterValues = (state, selections) => {
  const updateValues = dataUtils.isString(selections)
    ? [selections]
    : selections

  let filters = state.get('selectedFilterValues')

  updateValues.forEach((value) => {
    const indexOfValue = filters.indexOf(value)

    filters = (indexOfValue > -1)
      ? filters.delete(indexOfValue)
      : filters.push(value)
  })

  return state.set('selectedFilterValues', filters)
}
