import { createSelector } from 'reselect'
import { fromJS, List } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'PROFILE/FETCH',
  RECEIVE: 'PROFILE/RECEIVE',
  FETCH_FAILURE: 'PROFILE/FETCH_FAILURE',
  POST_DEVICE_PROFILE_DATA_SUCCESS: 'PROFILE/POST_DEVICE_PROFILE_DATA_SUCCESS',
  POST_DEVICE_PROFILE_DATA_FAILURE: 'PROFILE/POST_DEVICE_PROFILE_DATA_FAILURE',
  UPDATE_DEVICE_PROFILE_DATA_SUCCESS: 'PROFILE/UPDATE_DEVICE_PROFILE_DATA_SUCCESS',
  UPDATE_DEVICE_PROFILE_DATA_FAILURE: 'PROFILE/UPDATE_DEVICE_PROFILE_DATA_FAILURE',
  ADD_DEVICE_LOCATION_SUCCESS: 'PROFILE/ADD_DEVICE_LOCATION_SUCCESS',
  ADD_DEVICE_LOCATION_FAILURE: 'PROFILE/ADD_DEVICE_LOCATION_FAILURE',
  SEARCH_DEVICE_LOCATION_SUCCESS: 'PROFILE/SEARCH_DEVICE_LOCATION_SUCCESS',
  SEARCH_DEVICE_LOCATION_FAILURE: 'PROFILE/SEARCH_DEVICE_LOCATION_FAILURE',
  SET_DEVICE_LOCATION_SUCCESS: 'PROFILE/SET_DEVICE_LOCATION_SUCCESS',
  SET_DEVICE_LOCATION_FAILURE: 'PROFILE/SET_DEVICE_LOCATION_FAILURE',
  OPEN_ADD_DEVICE_LOCATION_FORM: 'PROFILE/OPEN_ADD_DEVICE_LOCATION_FORM',
  CLOSE_ADD_DEVICE_LOCATION_FORM: 'PROFILE/CLOSE_ADD_DEVICE_LOCATION_FORM',
  ADD_DEVICE_GROUP_SUCCESS: 'PROFILE/ADD_DEVICE_GROUP_SUCCESS',
  ADD_DEVICE_GROUP_FAILURE: 'PROFILE/ADD_DEVICE_GROUP_FAILURE',
  SEARCH_DEVICE_GROUP_SUCCESS: 'PROFILE/SEARCH_DEVICE_GROUP_SUCCESS',
  SEARCH_DEVICE_GROUP_FAILURE: 'PROFILE/SEARCH_DEVICE_GROUP_FAILURE',
  OPEN_ADD_DEVICE_GROUP_FORM: 'PROFILE/OPEN_ADD_DEVICE_GROUP_FORM',
  CLOSE_ADD_DEVICE_GROUP_FORM: 'PROFILE/CLOSE_ADD_DEVICE_GROUP_FORM',
  SET_DEVICE_GROUP_SUCCESS: 'PROFILE/SET_DEVICE_GROUP_SUCCESS',
  SET_DEVICE_GROUP_FAILURE: 'PROFILE/SET_DEVICE_GROUP_FAILURE',
  RESET_SEARCH_DEVICE_LOCATION_SUCCESS: 'PROFILE/RESET_SEARCH_DEVICE_LOCATION_SUCCESS',
  RESET_SEARCH_DEVICE_GROUP_SUCCESS: 'PROFILE/RESET_SEARCH_DEVICE_GROUP_SUCCESS',
  RESET_DEVICE_PROFILE_DATA: 'PROFILE/RESET_DEVICE_PROFILE_DATA',
  ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM: 'PROFILE/ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM',
  ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_SAVE: 'PROFILE/ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_SAVE',
  CLOSE_ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM: 'PROFILE/CLOSE_ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM',
  ADD_ADDITIONAL_FIELD_DATA: 'PROFILE/ADD_ADDITIONAL_FIELD_DATA',
  DELETE_NEW_ADDITIONAL_FIELD: 'PROFILE/DELETE_NEW_ADDITIONAL_FIELD',
  RECEIVE_DEVICE_DETAILS_DATA: 'PROFILE/RECEIVE_DEVICE_DETAILS_DATA',
  RECEIVE_DEVICE_DETAILS_DATA_FAILURE: 'PROFILE/RECEIVE_DEVICE_DETAILS_DATA_FAILURE',
  RECEIVE_COUNTRIES: 'PROFILE/RECEIVE_COUNTRIES',
  SET_DEVICE_LOCATION_VALUE: 'PROFILE/SET_DEVICE_LOCATION_VALUE',
  SET_DEVICE_GROUP_VALUE: 'PROFILE/SET_DEVICE_GROUP_VALUE'
}

// Action Creators
export const actions = {
  fetch (deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceProfileData',
      args: {deviceId},
      onSuccess: ({ data }) => ({ type: constants.RECEIVE, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchDeviceDetails (deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceDetails',
      args: {deviceId},
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_DEVICE_DETAILS_DATA, data }),
      onFailure: (error) => ({ type: constants.RECEIVE_DEVICE_DETAILS_DATA_FAILURE, error: error.toString() })
    })
  },
  saveDeviceProfile (saveDeviceProfileData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'postDeviceProfileData',
      args: saveDeviceProfileData,
      onSuccess: ({ data }) => ({ type: constants.POST_DEVICE_PROFILE_DATA_SUCCESS, status: String(data) }),
      onFailure: (error) => ({ type: constants.POST_DEVICE_PROFILE_DATA_FAILURE, error: error.status })
    })
  },
  updateDeviceProfile (updateDeviceProfileData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateDeviceProfileData',
      args: updateDeviceProfileData,
      onSuccess: ({ data }) => ({ type: constants.UPDATE_DEVICE_PROFILE_DATA_SUCCESS, status: String(data) }),
      onFailure: (error) => ({ type: constants.UPDATE_DEVICE_PROFILE_DATA_FAILURE, error: error.status })
    })
  },
  addDeviceLocation (deviceLocationData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addDeviceLocation',
      args: deviceLocationData,
      onSuccess: ({ data }) => ({ type: constants.ADD_DEVICE_LOCATION_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.ADD_DEVICE_LOCATION_FAILURE, error: error.message }),
      throwError: true
    })
  },
  searchDeviceLocation (searchLocationParams) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchDeviceLocation',
      args: searchLocationParams,
      onSuccess: ({ data }) => ({ type: constants.SEARCH_DEVICE_LOCATION_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SEARCH_DEVICE_LOCATION_FAILURE, error: error.message }),
      throwError: true
    })
  },
  setDeviceLocation (searchLocationParams) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchDeviceLocation',
      args: searchLocationParams,
      onSuccess: ({ data }) => ({ type: constants.SET_DEVICE_LOCATION_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SET_DEVICE_LOCATION_FAILURE, error: error.message }),
      throwError: true
    })
  },
  openAddDeviceLocationForm () {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_ADD_DEVICE_LOCATION_FORM })
    }
  },
  closeAddDeviceLocationForm () {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_ADD_DEVICE_LOCATION_FORM })
    }
  },
  addDeviceGroup (deviceGroupData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addDeviceGroup',
      args: deviceGroupData,
      onSuccess: ({ data }) => ({ type: constants.ADD_DEVICE_GROUP_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.ADD_DEVICE_GROUP_FAILURE, error: error.message }),
      throwError: true
    })
  },
  searchDeviceGroup (searchDeviceGroupParams) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchDeviceGroup',
      args: searchDeviceGroupParams,
      onSuccess: ({ data }) => ({ type: constants.SEARCH_DEVICE_GROUP_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SEARCH_DEVICE_GROUP_FAILURE, error: error.message }),
      throwError: true
    })
  },
  setDeviceGroup (searchDeviceGroupParams) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchDeviceGroup',
      args: searchDeviceGroupParams,
      onSuccess: ({ data }) => ({ type: constants.SET_DEVICE_GROUP_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SET_DEVICE_GROUP_FAILURE, error: error.message }),
      throwError: true
    })
  },
  searchDeviceGroupWithLocation (args) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchDeviceGroupWithLocation',
      args: args,
      onSuccess: ({ data }) => ({ type: constants.SEARCH_DEVICE_GROUP_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SEARCH_DEVICE_GROUP_FAILURE, error: error.message }),
      throwError: true
    })
  },
  setDeviceGroupWithLocation (args) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchDeviceGroupWithLocation',
      args: args,
      onSuccess: ({ data }) => ({ type: constants.SET_DEVICE_GROUP_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SET_DEVICE_GROUP_FAILURE, error: error.message }),
      throwError: true
    })
  },
  openAddDeviceGroupForm () {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_ADD_DEVICE_GROUP_FORM })
    }
  },
  closeAddDeviceGroupForm () {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_ADD_DEVICE_GROUP_FORM })
    }
  },
  resetSearchDeviceLocationStatus () {
    return {type: constants.RESET_SEARCH_DEVICE_LOCATION_SUCCESS}
  },
  resetSearchDeviceGroupStatus () {
    return {type: constants.RESET_SEARCH_DEVICE_GROUP_SUCCESS}
  },
  resetDeviceProfileData () {
    return {type: constants.RESET_DEVICE_PROFILE_DATA}
  },
  addAdditionalFieldInDeviceGroupForm () {
    return {type: constants.ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM}
  },
  addAdditionalFieldSave () {
    return {type: constants.ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_SAVE}
  },
  closeAddAdditionalFieldForm () {
    return {type: constants.CLOSE_ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM}
  },
  addAdditionalFields (newFieldsData) {
    return {type: constants.ADD_ADDITIONAL_FIELD_DATA, newFieldsData}
  },
  deleteNewField (name, value) {
    return {type: constants.DELETE_NEW_ADDITIONAL_FIELD, name, value}
  },
  loadCountries() {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'loadCountries',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  setLocationValue (deviceLocationValue) {
    return {type: constants.SET_DEVICE_LOCATION_VALUE, deviceLocationValue}
  },
  setDeviceGroupValue (deviceGroupValue) {
    return {type: constants.SET_DEVICE_GROUP_VALUE, deviceGroupValue}
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  data: {customer: {}, device: {}, profileId: ''},
  deviceProfile: null,
  addDeviceLocationSuccess: null,
  addDeviceLocationError: null,
  searchDeviceLocationSuccess: null,
  searchDeviceLocationError: null,
  searchDeviceGroupSuccess: null,
  searchDeviceGroupError: null,
  openAddDeviceLocationForm: false,
  addDeviceGroupSuccess: null,
  addDeviceGroupError: null,
  openAddDeviceGroupForm: false,
  showDeviceGroupFields: false,
  showDeviceLocationFields: false,
  postDeviceProfileSuccess: null,
  updateDeviceProfileSuccess: null,
  allowSaveLocation: false,
  allowSaveCustomer: false,
  locationNameInDeviceGroup: null,
  locationDetailsForCustomerSearch: null,
  addAdditionalFieldInDeviceGroupFormSuccess: false,
  addAdditionalFieldSaveSuccess: false,
  newAdditionalFields: {},
  newFieldDataUpdate: [],
  updateProfileButton: false,
  saveProfileButton: false,
  deviceData: null,
  countries: [],
  saveDeviceProfileFailure: null,
  updateDeviceProfileFailure: null,
  enableUpdateProfileBtn: false,
  deviceLocationValue: '',
  deviceGroupValue: ''
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
        .set('addDeviceLocationError', null)
        .set('addDeviceGroupError', null)
        .set('searchDeviceLocationError', null)
        .set('addDeviceLocationSuccess', null)
        .set('addDeviceGroupSuccess', null)
    case constants.RECEIVE:
      return state
        .set('data', fromJS(action.data))
        .set('fetching', false)
        .set('showDeviceGroupFields', true)
        .set('showDeviceLocationFields', true)
        .set('allowSaveLocation', true)
        .set('allowSaveCustomer', true)
        .set('locationNameInDeviceGroup', action.data.customer.location)
        .set('locationDetailsForCustomerSearch', action.data.customer.location)
        .set('updateProfileButton', false)
        .set('saveProfileButton', false)
        .set('enableUpdateProfileBtn', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
        .set('showDeviceGroupFields', false)
        .set('showDeviceLocationFields', false)
        .set('allowSaveLocation', false)
        .set('allowSaveCustomer', false)
        .set('saveProfileButton', false)
        .set('updateProfileButton', false)
        .set('enableUpdateProfileBtn', false)
    case constants.POST_DEVICE_PROFILE_DATA_SUCCESS:
      return state
        .set('postDeviceProfileSuccess', action.status)
        .set('fetching', false)
    case constants.POST_DEVICE_PROFILE_DATA_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('saveDeviceProfileFailure', action.error)
        .set('fetching', false)
    case constants.UPDATE_DEVICE_PROFILE_DATA_SUCCESS:
      return state
        .set('updateDeviceProfileSuccess', action.status)
        .set('fetching', false)
    case constants.UPDATE_DEVICE_PROFILE_DATA_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('updateDeviceProfileFailure', action.error)
        .set('fetching', false)
    case constants.ADD_DEVICE_LOCATION_SUCCESS:
      return state
        .set('addDeviceLocationSuccess', fromJS(action.data))
        .setIn(['data', 'customer', 'location'], fromJS(action.data))
        .set('fetching', false)
        .set('showDeviceLocationFields', true)
        .set('allowSaveLocation', true)
        .set('locationNameInDeviceGroup', action.data)
        .set('enableUpdateProfileBtn', true)
        .setIn(['data', 'customer', 'customerName'], '')
        .setIn(['data', 'customer', 'properties'], null)
    case constants.ADD_DEVICE_LOCATION_FAILURE:
      return state
        .set('addDeviceLocationError', action.error)
        .set('fetching', false)
        .set('showDeviceLocationFields', true)
        .set('allowSaveLocation', false)
        .set('allowSaveCustomer', false)
        .set('enableUpdateProfileBtn', false)
    case constants.SEARCH_DEVICE_LOCATION_SUCCESS:
      return state
        .set('searchDeviceLocationSuccess', fromJS(action.data))
        .set('fetching', false)
        .set('showDeviceLocationFields', false)
        .set('allowSaveLocation', true)
        .set('enableUpdateProfileBtn', true)
    case constants.SEARCH_DEVICE_LOCATION_FAILURE:
      return state
        .set('searchDeviceLocationError', action.error)
        .set('fetching', false)
        .set('showDeviceLocationFields', false)
        .set('allowSaveLocation', false)
        .set('allowSaveCustomer', false)
        .set('enableUpdateProfileBtn', false)
    case constants.SET_DEVICE_LOCATION_SUCCESS:
      return state
        .setIn(['data', 'customer', 'location'], fromJS(action.data[0]))
        .set('fetching', false)
        .set('showDeviceLocationFields', true)
        .set('allowSaveLocation', true)
        .set('searchDeviceGroupSuccess', null)
        .set('locationNameInDeviceGroup', action.data[0])
        .set('locationDetailsForCustomerSearch', action.data[0])
        .set('enableUpdateProfileBtn', true)
    case constants.SET_DEVICE_LOCATION_FAILURE:
      return state
        .set('fetching', false)
        .set('showDeviceLocationFields', false)
        .set('allowSaveLocation', false)
        .set('allowSaveCustomer', false)
        .set('enableUpdateProfileBtn', false)
    case constants.RESET_SEARCH_DEVICE_LOCATION_SUCCESS:
      return state
        .set('searchDeviceLocationSuccess', null)
        .setIn(['data', 'customer', 'customerName'], '')
        .set('showDeviceLocationFields', true)
        .set('allowSaveLocation', true)
        .setIn(['data', 'customer', 'properties'], null)
        .set('enableUpdateProfileBtn', true)
    case constants.ADD_DEVICE_GROUP_SUCCESS:
      return state
        .set('addDeviceGroupSuccess', fromJS(action.data))
        .setIn(['data', 'customer'], fromJS(action.data))
        .set('fetching', false)
        .set('showDeviceGroupFields', true)
        .set('allowSaveCustomer', true)
        .set('newFieldDataUpdate', fromJS([]))
        .set('enableUpdateProfileBtn', true)
    case constants.ADD_ADDITIONAL_FIELD_DATA:
      return state
        .set('newAdditionalFields', action.newFieldsData)
        .update('newFieldDataUpdate', newFieldDataUpdate => newFieldDataUpdate.push(action.newFieldsData))
        .set('addAdditionalFieldInDeviceGroupFormSuccess', false)
    case constants.DELETE_NEW_ADDITIONAL_FIELD:
      return state
        .update('newFieldDataUpdate', newFieldDataUpdate => newFieldDataUpdate.filter(
          (obj) => (obj.name !== action.name && obj.value !== action.value)))
    case constants.ADD_DEVICE_GROUP_FAILURE:
      return state
        .set('addDeviceGroupError', action.error)
        .set('fetching', false)
        .set('showDeviceGroupFields', true)
    case constants.SEARCH_DEVICE_GROUP_SUCCESS:
      return state
        .set('searchDeviceGroupSuccess', fromJS(action.data))
        .set('fetching', false)
        .set('showDeviceGroupFields', false)
        .set('allowSaveCustomer', false)
        .set('enableUpdateProfileBtn', true)
    case constants.SEARCH_DEVICE_GROUP_FAILURE:
      return state
        .set('searchDeviceGroupError', action.error)
        .set('fetching', false)
        .set('showDeviceGroupFields', false)
        .set('allowSaveCustomer', false)
        .set('enableUpdateProfileBtn', false)
    case constants.SET_DEVICE_GROUP_SUCCESS:
      return state
        .setIn(['data', 'customer'], fromJS(action.data[0]))
        .set('fetching', false)
        .set('showDeviceGroupFields', true)
        .set('showDeviceLocationFields', true)
        .set('allowSaveCustomer', true)
        .set('allowSaveLocation', true)
        .set('enableUpdateProfileBtn', true)
    case constants.SET_DEVICE_GROUP_FAILURE:
      return state
        .set('fetching', false)
        .set('showDeviceGroupFields', false)
        .set('allowSaveCustomer', false)
        .set('enableUpdateProfileBtn', false)
    case constants.RESET_SEARCH_DEVICE_GROUP_SUCCESS:
      return state
        .set('searchDeviceGroupSuccess', null)
        .set('showDeviceGroupFields', true)
        .set('allowSaveCustomer', true)
        .set('showDeviceGroupFields', true)
        .set('enableUpdateProfileBtn', true)
    case constants.OPEN_ADD_DEVICE_LOCATION_FORM:
      return state
        .set('openAddDeviceLocationForm', true)
        .set('showDeviceGroupFields', false)
    case constants.CLOSE_ADD_DEVICE_LOCATION_FORM:
      return state
        .set('openAddDeviceLocationForm', false)
        .set('addDeviceLocationSuccess', null)
        .set('addDeviceGroupSuccess', null)
    case constants.OPEN_ADD_DEVICE_GROUP_FORM:
      return state
        .set('openAddDeviceGroupForm', true)
    case constants.CLOSE_ADD_DEVICE_GROUP_FORM:
      return state
        .set('openAddDeviceGroupForm', false)
        .set('addDeviceLocationSuccess', null)
        .set('addDeviceGroupSuccess', null)
        .set('addAdditionalFieldInDeviceGroupFormSuccess', false)
        .set('newFieldDataUpdate', List())
    case constants.RESET_DEVICE_PROFILE_DATA:
      return state
        .set('data', initialState.get('data'))
        .set('postDeviceProfileSuccess', null)
        .set('addDeviceLocationSuccess', null)
        .set('addDeviceGroupSuccess', null)
        .set('updateDeviceProfileSuccess', null)
        .set('updateDeviceProfileFailure', null)
        .set('saveDeviceProfileFailure', null)
        .set('locationNameInDeviceGroup', null)
    case constants.ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM:
      return state
        .set('addAdditionalFieldInDeviceGroupFormSuccess', true)
        .set('addAdditionalFieldSaveSuccess', false)
    case constants.ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_SAVE:
      return state
        .set('addAdditionalFieldSaveSuccess', true)
        .set('addAdditionalFieldInDeviceGroupFormSuccess', false)
    case constants.CLOSE_ADD_ADDITIONAL_FIELD_IN_DEVICE_GROUP_FORM:
      return state
        .set('addAdditionalFieldInDeviceGroupFormSuccess', false)
        .set('addAdditionalFieldSaveSuccess', false)
    case constants.RECEIVE_DEVICE_DETAILS_DATA:
      return state
        .set('deviceData', fromJS(action.data))
    case constants.RECEIVE_COUNTRIES:
      return state
          .set('countries', fromJS(action.data))
          .set('fetching', false)
    case constants.SET_DEVICE_LOCATION_VALUE:
      return state
        .set('deviceLocationValue', action.deviceLocationValue)
    case constants.SET_DEVICE_GROUP_VALUE:
      return state
        .set('deviceGroupValue', action.deviceGroupValue)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceProfile

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  deviceProfile: createSelector(getState, (state) =>
    state.get('data')
  ),
  addDeviceLocationSuccess: createSelector(getState, (state) =>
    state.get('addDeviceLocationSuccess')
  ),
  addDeviceLocationError: createSelector(getState, (state) =>
    state.get('addDeviceLocationError')
  ),
  openAddDeviceLocationForm: createSelector(getState, (state) =>
    state.get('openAddDeviceLocationForm')
  ),
  searchDeviceLocationSuccess: createSelector(getState, (state) =>
    state.get('searchDeviceLocationSuccess')
  ),
  searchDeviceLocationError: createSelector(getState, (state) =>
    state.get('searchDeviceLocationError')
  ),
  showDeviceLocationFields: createSelector(getState, (state) =>
    state.get('showDeviceLocationFields')
  ),
  addDeviceGroupSuccess: createSelector(getState, (state) =>
    state.get('addDeviceGroupSuccess')
  ),
  addDeviceGroupError: createSelector(getState, (state) =>
    state.get('addDeviceGroupError')
  ),
  showDeviceGroupFields: createSelector(getState, (state) =>
    state.get('showDeviceGroupFields')
  ),
  openAddDeviceGroupForm: createSelector(getState, (state) =>
    state.get('openAddDeviceGroupForm')
  ),
  searchDeviceGroupSuccess: createSelector(getState, (state) =>
    state.get('searchDeviceGroupSuccess')
  ),
  searchDeviceGroupError: createSelector(getState, (state) =>
    state.get('searchDeviceGroupError')
  ),
  postDeviceProfileSuccess: createSelector(getState, (state) =>
    state.get('postDeviceProfileSuccess')
  ),
  updateDeviceProfileSuccess: createSelector(getState, (state) =>
    state.get('updateDeviceProfileSuccess')
  ),
  allowSaveCustomer: createSelector(getState, (state) =>
    state.get('allowSaveCustomer')
  ),
  allowSaveLocation: createSelector(getState, (state) =>
    state.get('allowSaveLocation')
  ),
  addAdditionalFieldInDeviceGroupFormSuccess: createSelector(getState, (state) =>
    state.get('addAdditionalFieldInDeviceGroupFormSuccess')
  ),
  addAdditionalFieldSaveSuccess: createSelector(getState, (state) =>
    state.get('addAdditionalFieldSaveSuccess')
  ),
  locationNameInDeviceGroup: createSelector(getState, (state) =>
    state.get('locationNameInDeviceGroup')
  ),
  newFieldDataUpdate: createSelector(getState, (state) =>
    state.get('newFieldDataUpdate').toJS()
  ),
  locationDetailsForCustomerSearch: createSelector(getState, (state) =>
    state.get('locationDetailsForCustomerSearch')
  ),
  saveProfileButton: createSelector(getState, (state) =>
    state.get('saveProfileButton')
  ),
  updateProfileButton: createSelector(getState, (state) =>
    state.get('updateProfileButton')
  ),
  deviceData: createSelector(getState, (state) =>
    state.get('deviceData')
  ),
  getCountries: createSelector(getState, (state) =>
    state.get('countries')
  ),
  updateDeviceProfileFailure: createSelector(getState, (state) =>
    state.get('updateDeviceProfileFailure')
  ),
  saveDeviceProfileFailure: createSelector(getState, (state) =>
    state.get('saveDeviceProfileFailure')
  ),
  enableUpdateProfileBtn: createSelector(getState, (state) =>
    state.get('enableUpdateProfileBtn')
  ),
  deviceLocationValue: createSelector(getState, (state) =>
    state.get('deviceLocationValue')
  ),
  deviceGroupValue: createSelector(getState, (state) =>
    state.get('deviceGroupValue')
  )
}
