import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Select } from 'antd'
import 'antd/lib/select/style/css'
import classNames from 'classnames/bind'
import styles from './SearchFilter.scss'
import { isString, isArray } from 'utils/dataUtils'
import { filterTypes, filterLabels } from 'constants/deviceList'
import { mainRoutes } from 'constants/mainroutes'

const cx = classNames.bind(styles)

class SearchFilter extends PureComponent {
  constructor(props) {
    super(props)
    const {
      userCountry,
      category: { categoryType },
      categoryName,
      selectedFilterValues
    } = props
    const defaultCountries = this.getDefaultCountries(userCountry, selectedFilterValues)
    const setDefaultValues = (categoryType === 'countryIsoCode' || categoryType === 'countryCode')
    this.state = {
      [categoryName]: setDefaultValues ? defaultCountries : [],
    }
  }
  componentDidMount() {
    const { userCountry, categoryName, category: { categoryType }, selectedFilterValues } = this.props
    const defaultCountries = this.getDefaultCountries(userCountry, selectedFilterValues)
    let selectedFilterCat = ''
    let filterCateValue = selectedFilterValues?.filter(values => (values.split('.')[0] === categoryName))
    const setDefaultValues = (categoryType === 'countryIsoCode' || categoryType === 'countryCode')
    if(filterCateValue?.length > 0){
      let filterValue = filterCateValue[0].split('.')
      selectedFilterCat = filterValue[0]
    }
    let filterValue = filterCateValue?.map((values) => {
      let filterValue = values.split('.')
      if (filterValue?.length > 2) {
        filterValue = filterValue.slice(1)
        return filterValue.join('.')
      } else if(filterValue?.length){
        return filterValue[filterValue.length - 1]
      } else {
        return ''
      }
    })
    if (selectedFilterCat === categoryName) {
      const isDefaultCountry = filterValue.find(item => (setDefaultValues && item !== defaultCountries[0]))
      const filterDefaultValue = [...new Set(isDefaultCountry ? [...filterValue] : [...defaultCountries, ...filterValue])]
      this.setState({
        [categoryName]: setDefaultValues ? filterDefaultValue : filterValue,
      })
    } else {
      this.setState({
        [categoryName]: setDefaultValues ? defaultCountries : [],
      })
    }

  }
  getDefaultCountries(userCountry, selectedFilterValues) {
    if (selectedFilterValues?.length > 0 && selectedFilterValues.includes('countryIsoCode.IN')) {
      if (isString(userCountry)) {
        return [userCountry]
      }
      if (isArray(userCountry)) {
        return userCountry
      }
    }

    return []
  }

  _updateValues(values) {
    const { categoryName } = this.props
    this.setState({ [categoryName]: values })
  }

  _createCategoryValue(value) {
    const {
      category: { categoryType, categoryParam },
    } = this.props

    return !categoryParam
      ? `${categoryType}.${value}`
      : `${categoryParam}.${value}`
  }

  handleSelection = (newValues, options) => {
    const {
      handleFilters,
      handleClose,
      setCurrentPage,
      addfilterCatogory,
      categoryName
    } = this.props
    const { [categoryName]: prevValues } = this.state
    const VALUE_CLEARED = newValues.length === 0 && prevValues.length > 0
    const VALUE_SELECTED = newValues.length > prevValues.length
    if (VALUE_CLEARED) {
      setCurrentPage(1)
      addfilterCatogory(
        null,
        prevValues.map((value) => this._createCategoryValue(value))
      )
      this._updateValues([])
    } else {
      let selectedValue

      if (VALUE_SELECTED) {
        selectedValue = newValues[newValues.length - 1]
      } else {
        selectedValue = prevValues.find(
          (value) => newValues.indexOf(value) === -1
        )
      }

      handleFilters({
        target: {
          value: this._createCategoryValue(selectedValue),
        },
      })

      this._updateValues(newValues)

      if (handleClose) {
        handleClose()
      }
    }
  }

  handleFilterOption = (input, option) => {
    const {
      props: { name },
    } = option

    if (name) {
      return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return false
  }
  sortOptions = (options, values) => {
    const newOptions = JSON.parse(JSON.stringify(options))
    values.forEach((code, index) => {
      const option = newOptions.find(o => o.label === code)
      if (option) {
        const otherOption = newOptions.find(o => o.key === index)
        if (otherOption) {
          otherOption.key = option.key
        }
        option.key = index
      }
    })
    return newOptions
  }

  render() {
    const {
      category,
      modeOfSelect,
      disableSearchFilter,
      categoryName
    } = this.props
    const placeholder = (
      <FormattedMessage
        id={`next.LISTING_FILTER_SECTION_SEARCH_TYPE_PLACEHOLDER_${
          location.pathname === mainRoutes.MAIN_MENU_DEVICE_LIST && category.categoryType === filterTypes.CUSTOMER_NAME
          ? filterLabels.DEVICE_CUSTOMER_NAME
          : category.categoryType}`}
      />
    )
  
    return (
      <div
        key={`${category.categoryType}`}
        className={cx({
          'highlight-selection': category.searchBoxHighlightSelection,
        })}
      >
        <Select
          mode={modeOfSelect}
          disabled={disableSearchFilter}
          style={{ width: '100%' }}
          value={this.state[categoryName]}
          placeholder={placeholder}
          onChange={this.handleSelection}
          optionFilterProp='children'
         // options={category.categoryValues}
          options={this.sortOptions(category.categoryValues,this.state[categoryName])}
          getPopupContainer={trigger => trigger.parentNode}
          notFoundContent={<FormattedMessage id='next.SELECT_COMP_NO_RESULTS_FOUND' />}
          filterOption={this.handleFilterOption}
        />
      </div>
    )
  }
}

SearchFilter.propTypes = {
  userCountry: PropTypes.string,
  modeOfSelect: PropTypes.string,
  category: PropTypes.object,
  handleFilters: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  loadList: PropTypes.func,
  setCurrentPage: PropTypes.func,
  addfilterCatogory: PropTypes.func,
  disableSearchFilter: PropTypes.bool,
  selectedFilterValues: PropTypes.array,
  categoryName: PropTypes.string,
}

SearchFilter.defaultProps = {
  modeOfSelect: 'multiple',
  disableSearchFilter: false,
}

export default SearchFilter
