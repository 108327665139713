import React from 'react'
import { injectIntl } from 'react-intl'
import classNames from 'classnames/bind'
import style from './UserRatingPopup.scss'
import UserRatingForm from './UserRatingForm'

const cx = classNames.bind(style)
class UserRatingPopup extends React.Component {
  render() {
    return (
      <div className={cx('user-rating-form-container')}>
        <div className={cx('add-user-form-body')}>
          <UserRatingForm {...this.props} />
        </div>
      </div>
    )
  }
}

export default injectIntl(UserRatingPopup)
