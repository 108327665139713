import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions, constants } from 'store/modules/GlobalOverlay'
import { v4 as uuid } from 'uuid'
import { message as msgPopup } from 'antd'
// import {injectIntl,useIntl} from 'react-intl'
import Modal from '../Modal'
// import SnackBar from '../SnackBar'
import GlobalOverlayContainer from '../GlobalOverlay'
// import { clearCache } from './utils/GlobalOverlayUtils'


class GlobalOverlayProvider extends Component {

  static propTypes = {
    children: PropTypes.node,
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func,
    wrapperStyle: PropTypes.object
  }
  static childContextTypes = {
    openModal: PropTypes.func,
    openSnackBar: PropTypes.func,
    close: PropTypes.func
  }

  // componentDidMount() {
  //   clearCache()
  // }

  getChildContext() {
    return {
      openModal: (content, options = {}, animation = 'fade') =>
        this.props.handleOpen({
          element: (
            <Modal close={this?.props?.handleClose} key={uuid()} {...options}>
              {content}
            </Modal>
          ),
          overlayType: constants.MODAL,
          timed: false,
          speed: null,
          animation
        }),
      openSnackBar: ({ message, type = 'S', appendvalue = '', wrapClassname }) => {
        //let appVal = (appendvalue) ? appendvalue : ''
        // let msg = intl.formatMessage({ id: `next.${message}` }) + appVal
        if (type === 'S')
          msgPopup.success({content: message + appendvalue, className: wrapClassname})
        else if (type === 'W')
          msgPopup.warning(message)
        else
          msgPopup.error(message)
      },
      close: this.props.handleClose
    }
  }

  render() {
    const { wrapperStyle, children } = this.props
    return (
      <div style={wrapperStyle}>
        {children}
        <GlobalOverlayContainer />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    handleOpen: actions.open,
    handleClose: actions.close
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalOverlayProvider)
