import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { routerMiddleware } from 'connected-react-router'

export default (initialState = {}, history) => {
  // Store Enhancers
  const enhancers = []
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }
  // Store Instantiation
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk, routerMiddleware(history)),
      ...enhancers
    )
  )
}
