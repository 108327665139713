import ApiError from 'api/ApiError'

export const ensureIntl = async (locale) => {
  let messages
  try {
    messages = require(`static/intl/${locale}.json`)
  } catch (_) {
    throw new ApiError(404)
  }
  return {data: messages}
}
