import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const moduleId = 'deviceNotificationStatus'
export const constants = {
  FETCH: 'DEVICE_NOTIFICATION_STATUS_DASHBOARD/FETCH',
  RECEIVE: 'DEVICE_NOTIFICATION_STATUS_DASHBOARD/RECEIVE',
  RECEIVE_DEVICE_DETAILS: 'DEVICE_DETAILS/RECEIVE_DEVICE_DETAILS',
  FETCH_DEVICE_FAILURE: 'DEVICE_DETAILS/FETCH_DEVICE_FAILURE',
  FETCH_FAILURE: 'DEVICE_NOTIFICATION_STATUS_DASHBOARD/FETCH_FAILURE',
  RECEIVE_DEVICE_NOTIFICATION: 'DEVICE_NOTIFICATION_STATUS_DASHBOARD/RECEIVE_DEVICE_NOTIFICATION',
  RECEIVE_FILTERS: 'DEVICE_NOTIFICATION_STATUS_DASHBOARD/RECEIVE_FILTERS',
  RECEIVE_DEVICE_NOTIFICATION_DETAILS: 'DEVICE_NOTIFICATION_STATUS_DASHBOARD/RECEIVE_DEVICE_NOTIFICATION_DETAILS'
}

// Action Creators
export const actions = {

  getFilters(deviceId) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'fetchDeviceNotificationFilters',
      args: deviceId,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_FILTERS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  fetchDeviceDetailsNotification(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceDetails',
      args: {deviceId},
      onSuccess: ({data}) => ({ type: constants.RECEIVE_DEVICE_DETAILS, data }),
      onFailure: ({status}) => ({ type: constants.FETCH_DEVICE_FAILURE, error: status })
    })
  },
  fetchDeviceNotifications(deviceId) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'fetchDeviceNotifications',
      args: deviceId,
      onSuccess: (data) => ({ type: constants.RECEIVE_DEVICE_NOTIFICATION, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  fetchDeviceNotificationDetail(deviceId, notificationId) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'fetchDeviceNotificationsDetails',
      args: {deviceId, notificationId},
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_DEVICE_NOTIFICATION_DETAILS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  filterCategories: [],
  devicenotifications: [],
  deviceNotificationDetail: {},
  deviceDetails: {}
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RECEIVE_DEVICE_DETAILS:
      return state
        .set('fetching', false)
        .set('deviceDetails', fromJS(action.data))
    case constants.FETCH_DEVICE_FAILURE:
      return state
        .set('fetchDeviceError', fromJS(action.error))
        .set('fetching', false)
    case constants.RECEIVE_FILTERS:
      return state
        .set('fetching', false)
        .set('filterCategories', parseFilters(action.data))
    case constants.RECEIVE_DEVICE_NOTIFICATION:
      return state
        .set('fetching', false)
        .set('devicenotifications', fromJS(action.data))
    case constants.RECEIVE_DEVICE_NOTIFICATION_DETAILS:
      return state
        .set('fetching', false)
        .set('deviceNotificationDetail', fromJS(action.data))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceNotificationStatus

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  deviceNotifications: createSelector(getState, (state) => {
    return state.get('devicenotifications')
  }),
  getDeviceNotificationDetails: createSelector(getState, (state) => {
    return state.get('deviceNotificationDetail')
  }),
  deviceDetails: createSelector(getState, (state) => {
    return state.get('deviceDetails')
  })
}

// helper functions
const parseFilters = (filters) => {
  const searchFilters = ['createdBy', 'notificationWithIn']
  const combobox = ['notificationWithIn']
  const updatedFilters = Object.keys(filters).map(name => {
    const filterConfig = {
      searchBox: searchFilters.indexOf(name) > -1,
      searchBoxHighlightSelection: true,
      combobox: combobox.indexOf(name) > -1
    }

    return {
      categoryType: name,
      categoryValues: filters[name].map(value => ({
        name: value,
        label: value,
        value: `${name}.${value}`
      })),
      ...filterConfig
    }
  })

  return fromJS(updatedFilters)
}
