import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import GenericListingContainer from 'containers/GenericListingContainer/GenericListingContainer'
import { injectOverlay } from 'shared-components/utils'
import { fromJS } from 'immutable'
import EditDeleteAction from 'components/EditDeleteAction/EditDeleteAction'
import WithDateFormat from 'containers/withDateFormat/withDateFormat'
import Tooltip from 'components/Tooltip/Tooltip'
import ManageOrganization from 'components/Forms/ManageOrganization/ManageOrganization'
import './OrganizationDetails.scss'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import OrganizationAdditionalInfo from './OrganizationAdditionalInfo'
import { Switch } from 'antd'
import Loader from 'components/Loader/Loader'
import AddButtonInHeader from 'components/AddButtonInHeader/AddButtonInHeader'
import AddIconSvg from 'static/icons/AddIconSvg'

export const headers = [{ header: 'name' }, { header: 'type' }, { header: 'description' }, { header: 'createdBy' }, { header: 'createdOn' }, { header: 'status' }, { header: 'action', isSort: true, isSortable: false }]

class OrganizationDetails extends PureComponent {
  handleDelete = (data) => { }
  editModal = (data) => {
    const { openModal, editOrganization, loadList, close, peripheralBrands, user } = this.props
    openModal(
      <ManageOrganization
        loadList={loadList}
        editOrganization={editOrganization}
        data={data}
        initialValues={data}
        mode='edit'
        options={peripheralBrands}
        user={user}
        close={close} />,
      { title: <FormattedMessage id={'next.ORGANIZATION_MODAL_EDIT_TITLE'} /> }
    )
  }
  addOrganization = () => {
    const {
      openModal,
      editOrganization,
      loadList,
      close,
      peripheralBrands,
      user,
    } = this.props
    openModal(
      <ManageOrganization
        loadList={loadList}
        editOrganization={editOrganization}
        data={{ status: true }}
        options={peripheralBrands}
        close={close}
        user={user} />,
      { title: <FormattedMessage id={'next.ORGANIZATION_MODAL_ADD_TITLE'} /> }
    )
  }
  customizeMaintenanceRow = (data, handleDelete) => {
    const { handleStatusChange, viewOrgDetails } = this.props
    const listing = (data && data.toJS()) || []
    let mapData = listing && listing.length > 0 ? listing.map((list) => {
      return {
        ...list,
        name: (
          <span style={{ width: '100%' }} onClick={() => viewOrgDetails(list)}>
            <Link to={`./organizations/${list.orgId}/users`} className='org_users'>
              <span className='org_icon'>
                <OrganizationAdditionalInfo additionalInfo={list} />
              </span>
              <span className='org_name'>
                <Tooltip title={list.name} />
              </span>
            </Link>
          </span>
        ),
        description: <Tooltip title={list.description} />,
        createdOn: <WithDateFormat date={list.createdOn} />,
        createdBy: <Tooltip title={list.createdBy} />,
        status: (<Switch disabled={!list?.enableToggle}
          checked={list.status} size='small' onClick={() => handleStatusChange(list)} className='org_status' />),
        action: (
          <EditDeleteAction
            isDisableDelete={!list.enableDeleteIcon}
            rowData={list}
            handleEdit={() => this.editModal(list)}
            handleDelete={() => handleDelete(list.orgId)} />),
      }
    }) : []
    return mapData && fromJS(mapData)
  }

  render() {
    const { moduleId, orgFilters, fetching } = this.props
    const additionalProps = {}
    const columnWidths = [15, 15, 20, 20, 10, 10, 10]
    const uploadButton = (
      <AddButtonInHeader
        iconName={<AddIconSvg />}
        handleOnClick={this.addOrganization}
        message={'ORGANIZATION_MODAL_ADD_TITLE'}
      />
    )
    return (
      <div className='customer-view-table'>
        {(fetching) && <Loader />}
        {orgFilters && <GenericListingContainer
          columnWidths={columnWidths}
          headers={headers}
          title={'ORGANIZATION_DETAILS'}
          headersTable={'ORGANIZATION_DETAILS_HEADER_'}
          listingApi='fetchOrganizationDetails'
          paginationName={moduleId}
          deleteApi='deleteOrganization'
          defaultSort='name'
          defaultSortDirection
          showSearch
          searchBy={[]}
          filterCategories={orgFilters.toJS()}
          customizeRow
          customizeRowData={this.customizeMaintenanceRow}
          searchPlaceholder='next.ORGANIZATION_DETAILS_HEADER_SEARCH'
          listingName={moduleId}
          showFilters
          additionalElement={uploadButton}
          {...additionalProps}
        />}
      </div>
    )
  }
}
OrganizationDetails.propTypes = {
  moduleId: PropTypes.string,
  openModal: PropTypes.func,
  editOrganization: PropTypes.func,
  loadList: PropTypes.func,
  close: PropTypes.func,
  peripheralBrands: PropTypes.string,
  orgFilters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  handleStatusChange: PropTypes.func,
  fetching: PropTypes.bool,
  viewOrgDetails: PropTypes.func,
  user: PropTypes.object,
  permission: PropTypes.object
}

export default injectOverlay(React.memo(OrganizationDetails))
