import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { getTypeValues } from '../types'
import Select from './Select'
import Input from './Input'
import InputGroup from './InputGroup'
import TextArea from './TextArea'
import RadioCheckbox from './RadioCheckbox'
import './FormField.scss'

const fieldTypes = {
  INPUT: 'input',
  INPUT_GROUP: 'inputGroup',
  SELECT: 'select',
  TEXT_AREA: 'textarea',
  CHECKBOX: 'checkbox',
  RADIO: 'radio'
}

class FormField extends Component {
  static propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    fieldType: PropTypes.oneOf(getTypeValues(fieldTypes)),
    isFormGroup: PropTypes.bool,
    withState: PropTypes.bool,
    parse: PropTypes.func,
    format: PropTypes.func,
    meta: PropTypes.object
  }
  static defaultProps = {
    fieldType: fieldTypes.INPUT,
    isFormGroup: true,
    withState: true,
    meta: {}
  }

  constructor(props) {
    super(props)
    // bind methods
    this.renderField = this._renderField.bind(this)
  }

  _renderField(fieldProps = {}) {
    const { fieldType, withState, defaultValue, name, parse, format, ...displayProps } = this.props // eslint-disable-line
    switch (fieldType) {
      case fieldTypes.INPUT:
        return (
          <Input
            {...displayProps}
            {...fieldProps}
          />
        )
      case fieldTypes.INPUT_GROUP:
        return (
          <InputGroup
            {...displayProps}
            {...fieldProps}
          />
        )
      case fieldTypes.TEXT_AREA:
        return (
          <TextArea
            {...displayProps}
            {...fieldProps}
          />
        )
      case fieldTypes.SELECT:
        return (
          <Select
            {...displayProps}
            {...fieldProps}
          />
        )
      case fieldTypes.CHECKBOX:
      case fieldTypes.RADIO:
        return (
          <RadioCheckbox
            fieldType={fieldType}
            {...displayProps}
            {...fieldProps}
          />
        )
    }
  }

  render() {
    return this.props.withState
      ? <Field
        name={this.props.name}
        defaultValue={this.props.defaultValue}
        component={this.renderField}
        parse={this.props.parse}
        format={this.props.format}
      />
      : this.renderField()
  }
}

export default FormField
