import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { requireAuth } from './hooks'
import { PATHS } from 'routes'
import Loader from 'components/Loader/Loader'
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout'
import { useHistory } from 'react-router'

const ProtectedRoute = ({ children, store }) => {
  const history= useHistory()
  const authTokens = localStorage.getItem('accessToken')
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    let result = (async () =>await requireAuth(store))()
    result.then((response)=>{
      setIsLoading(false)
      result = response
    })
    return () => result
  }, [])

  if (!authTokens && store?.getState()?.router?.location?.search?.startsWith('?search=')) {
    sessionStorage.setItem('validateUserUrl',
    store.getState().router.location.pathname + store.getState().router.location.search)
  }
  if (!authTokens && store?.getState()?.router?.location?.search?.startsWith('?userId=')) {
    const urlParams= store.getState().router.location.search
    const userId= urlParams.substr(8)
    sessionStorage.setItem('validateDeviceUrl',
    store.getState().router.location.pathname + `/${userId}` + '/customerdevices')
  }
  if (authTokens && store?.getState()?.router?.location?.search?.startsWith('?userId=')) {
    const urlParams= store.getState().router.location.search
    const userId= urlParams.substr(8)
    history.push(store.getState().router.location.pathname + `/${userId}` + '/customerdevices')
  }
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : !isLoading && authTokens ? (
        <DefaultLayout>{children}</DefaultLayout>
      ) : (
        <Redirect to={PATHS.HOME} />
      )}
    </div>
  )
}
ProtectedRoute.propTypes = {
  children: PropTypes.node,
  store: PropTypes.object,
}

export default ProtectedRoute
