import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { ErrorMessage } from 'formik'
import 'antd/lib/input/style/css'
import './Textarea.scss'
const { TextArea } = Input

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isRequired: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  rows: PropTypes.number,
  disabled:PropTypes.bool
}

const antTextarea = ({label, name, placeholder, value, handleChange, className,
                       style, isRequired, rows, disabled, setFieldValue, ...restProps}) => (
  <div className={`input-div ${className ? className : ''}`} style={{ ...style }}>
    <label>
      {label} {isRequired && <span className='required'>*</span>}
    </label>
    <TextArea
      rows={rows}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={({target}) => setFieldValue(name, target.value)}
      disabled={disabled}
      showCount={disabled ? false : { formatter: ({ count, maxLength }) => `Remaining Characters : ${maxLength - count}` }}
      {...restProps}
    />
    <span className='error-message'>
      <ErrorMessage name={name} />
    </span>
  </div>
)
antTextarea.defaultProps = {
  rows: 4
}

antTextarea.propTypes = propTypes

export default antTextarea
