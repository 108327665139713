import { createSelector } from 'reselect'
import { fromJS, List } from 'immutable'
import {actions as listingActions} from 'store/modules/GenericListing'
import {selectors as userSelectors} from 'store/modules/User'
import {actions as userProfileActions} from 'store/modules/UserProfile'
import { filterTypes as alarmAlertsFilterTypes } from 'constants/globalAlarmAlerts'

// Constants
export const constants = {
  FETCH: 'GLOBAL_ALERTS/FETCH',
  FETCH_FAILURE: 'GLOBAL_ALERTS/FETCH_FAILURE',
  FETCH_FILTER_LOADER_DISABLE: 'GLOBAL_ALERTS/FETCH_FILTER_LOADER_DISABLE',
  RECEIVE_FILTER_TYPES: 'GLOBAL_ALERTS/RECEIVE_FILTER_TYPES',
  RESET_ALERTS_DATA: 'GLOBAL_ALERTS/RESET_ALERTS_DATA',
  FETCH_FILTER_FAILURE: 'GLOBAL_ALERTS/FETCH_FILTER_FAILURE',
  FETCH_FILTER_TYPES: 'GLOBAL/FETCH_FILTER_TYPES',
  NOTIFY_POLLING_STARTED: 'GLOBAL/NOTIFY_POLLING_STARTED',
  CLEAR_POLLING: 'GLOBAL/CLEAR_POLLING',
  FETCH_USER_TERRITORIES_SUCCESS: 'GLOBAL/FETCH_USER_TERRITORIES_SUCCESS'
}

let timer

// Action Creators
export const actions = {
  pollFetchFilterTypes (init = false, stop = false) {
    return async (dispatch, getState) => {
      const polling = selectors.polling(getState())
      if (stop && timer) {
        clearTimeout(timer)
        timer = null
        dispatch({ type: constants.CLEAR_POLLING })
      } else if (init || timer) {
        const delay = (!polling && timer) ? 15000 : 0
        timer = setTimeout(async () => {
          await dispatch(listingActions.loadList('fetchAlertsAndAlarms', 'eventType'))
          dispatch(actions.pollFetchFilterTypes())
        }, delay)
        dispatch({type: constants.NOTIFY_POLLING_STARTED})
      }
    }
  },
  fetchFilterTypes(data, getState) {
    const userPrivileges = userSelectors.getUserPrivilege(getState())
    const isDistView = userPrivileges && userPrivileges.get('filter') && !!userPrivileges.getIn(['filter', 'DISTRIBUTOR'])
    return ({ type: constants.RECEIVE_FILTER_TYPES, data, isDistView })
  },
  resetAlertsData () {
    return { type: constants.RESET_ALERTS_DATA }
  },
  fetchUserTerritories (userId) {
    return async (dispatch, getState) => {
      try {
        await dispatch({ type: constants.FETCH })
        const { data: territories } = await dispatch(userProfileActions.fetchUserTerritories(userId))
        const { data: countries } = await dispatch(userProfileActions.searchTerritories())
        const data = territories
          .map(({ resourceId }) => {
            const country = countries.find(({ id }) => id === resourceId)

            return country.countryCode
          })
        dispatch({ type: constants.FETCH_USER_TERRITORIES_SUCCESS, data })
      } catch (error) {
        dispatch({ type: constants.FETCH_FAILURE, error: error.toString() })
      }
    }
  },
  fetchFilterTypesLoaderDisable() {
    return ({ type: constants.FETCH_FILTER_LOADER_DISABLE })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  fetchFilterError: null,
  polling: false,
  pollingStarted: false,
  fetchingFilters: false,
  filterCategories: [],
  userTerritories: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchingFilters', true)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
        .set('fetchingFilters', false)
    case constants.FETCH_FILTER_TYPES:
      return state
        .set('fetchingFilters', true)
        .set('fetching', true)
        .set('polling', true)
    case constants.FETCH_FILTER_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
        .set('polling', false)
        .set('fetchingFilters', false)
    case constants.RECEIVE_FILTER_TYPES:
      return state
        .set('fetchingFilters', false)
        .set('fetching', false)
        .set('filterCategories', formatFilterCategories(action.data, action.isDistView))
        .set('polling', false)
        .set('fetchFilterError', null)
    case constants.RESET_ALERTS_DATA:
      return state
        .set('filterCategories', List())
        .set('fetchFilterError', null)
    case constants.NOTIFY_POLLING_STARTED:
      return state
        .set('pollingStarted', true)
    case constants.CLEAR_POLLING:
      return state
        .set('pollingStarted', false)
    case constants.FETCH_USER_TERRITORIES_SUCCESS:
      return state
        .set('fetching', false)
        .set('userTerritories', fromJS(action.data))
    case constants.FETCH_FILTER_LOADER_DISABLE:
      return state
        .set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.globalAlertsAndAlarms

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  fetchFilterError: createSelector(getState, (state) => {
    return state.get('fetchFilterError')
  }),
  polling: createSelector(getState, (state) => {
    return state.get('polling')
  }),
  pollingStarted: createSelector(getState, (state) => {
    return state.get('pollingStarted')
  }),
  fetchingFilters: createSelector(getState, (state) => {
    return state.get('fetchingFilters')
  }),
  userTerritories: createSelector(getState, (state) => {
    return state.get('userTerritories')
  })
}

// helper functions
function formatFilterCategories (filterTypes, isDistView) {
  const DataVal = [
    alarmAlertsFilterTypes.EVENT_TYPE,
    alarmAlertsFilterTypes.DEVICE_NAME,
    alarmAlertsFilterTypes.DIS_ORGANIZATION_NAME,
    alarmAlertsFilterTypes.COUNTRY_CODE
  ]

  const filterOrder = isDistView
    ? DataVal
    :[
      alarmAlertsFilterTypes.EVENT_TYPE,
      alarmAlertsFilterTypes.DEVICE_NAME,
      alarmAlertsFilterTypes.PARTNER,
      alarmAlertsFilterTypes.CONTRACT_COVERAGE_LEVEL,
      alarmAlertsFilterTypes.CUSTOMER_NAME,
      alarmAlertsFilterTypes.COUNTRY_CODE
    ]

  let filterCategories = []

  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      const values = filterTypes[filter]
      const isSearchBox = filter === alarmAlertsFilterTypes.COUNTRY_CODE ||
        filter === alarmAlertsFilterTypes.CUSTOMER_NAME ||
        filter === alarmAlertsFilterTypes.PARTNER ||
        filter === alarmAlertsFilterTypes.DEVICE_NAME ||
        filter === alarmAlertsFilterTypes.DIS_ORGANIZATION_NAME
      const categoryType = alarmAlertsFilterTypes.PARTNER !== filter
        ? filter
        : 'partnerName'
      const categoryValues = !values
        ? []
        : values.map((val, index) => {
          return {
            label: val,
            value: val,
            key: index,
            name: val
          }
        })
      filterCategories.push({categoryType, categoryValues, searchBox: isSearchBox})
    }
  })
  return fromJS(filterCategories)
}
