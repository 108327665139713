import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'PARTNER_ADMIN_DASHBOARD/FETCH',
  FETCH_FAILURE: 'PARTNER_ADMIN_DASHBOARD/FETCH_FAILURE',
  RECEIVED_PERIPHERAL_SUCCESS: 'PARTNER_ADMIN_DASHBOARD/RECEIVED_PERIPHERAL_SUCCESS',
  FETCH_SYSTEM_STATUS_FAILURE: 'PARTNER_ADMIN_DASHBOARD/FETCH_SYSTEM_STATUS_FAILURE',
  FETCH_SYSTEM_STATUS_SUCCESS: 'PARTNER_ADMIN_DASHBOARD/FETCH_SYSTEM_STATUS_SUCCESS',
  RESET_SYSTEM_STATUS: 'PARTNER_ADMIN_DASHBOARD/RESET_SYSTEM_STATUS',
  SET_SEARCH_TERM: 'PARTNER_ADMIN_DASHBOARD/SET_SEARCH_TERM',
  CREATE_NOTIFICATION_SUCCESS: 'PARTNER_ADMIN_DASHBOARD/CREATE_NOTIFICATION_SUCCESS',
  CREATE_NOTIFICATION_FAILURE: 'PARTNER_ADMIN_DASHBOARD/CREATE_NOTIFICATION_FAILURE',
  VALIDATING_NOTIFICATION_SUCCESS: 'PARTNER_ADMIN_DASHBOARD/VALIDATING_NOTIFICATION_SUCCESS',
  VALIDATING_NOTIFICATION_FAILURE: 'PARTNER_ADMIN_DASHBOARD/VALIDATING_NOTIFICATION_FAILURE',
  CREATE_LOGS_SUCCESS: 'PARTNER_ADMIN_DASHBOARD/CREATE_LOGS_SUCCESS',
  CREATE_LOGS_FAILURE: 'PARTNER_ADMIN_DASHBOARD/CREATE_LOGS_FAILURE',
  SET_POLL_TIMER_ID: 'PARTNER_ADMIN_DASHBOARD/SET_POLL_TIMER_ID',
  CLEAR_POLL_TIMER_ID: 'PARTNER_ADMIN_DASHBOARD/CLEAR_POLL_TIMER_ID',
  FETCH_CALLED: 'PARTNER_ADMIN_DASHBOARD/FETCH_CALLED',
  NOTIFICATION_STATUS_COUNT: 'PARTNER_ADMIN_DASHBOARD/NOTIFICATION_STATUS_COUNT',
  CLEAR_NOTIFICATION_STATUS_COUNT: 'PARTNER_ADMIN_DASHBOARD/CLEAR_NOTIFICATION_STATUS_COUNT'
}
export const params = {
  DISTRIBUTION_LOOP_MGT: 'DISTRIBUTION_LOOP_MANAGEMENT',
  SDS_KIT_ELIX_CONFIG: 'SDS_KIT_ELIX_CONFIGURATION'
}
export const measures = ['DISTRIBUTION_RESISTIVITY_TC_REC_VALUE', 'PERMEATE_CONDUCTIVITY_TC_REC_VALUE', 'ELIX_RESISTIVITY_TC_REC_VALUE']

// Actions
export const actions = {
  createNotificationPayload(values) {
    return values.filter(({offline}) => (!offline)).map(({uuid}) => ({deviceId: uuid, alias: 'SYSTEM_INFO'}))
  },
  notificationStatusPayload(values, createdLogValues) {
    if (createdLogValues && createdLogValues.length > 0) {
      const pendingStatusValues = values.filter(({deviceId}) => (!createdLogValues.find(({deviceId: id}) => (id === deviceId))))
      return pendingStatusValues && pendingStatusValues.length > 0 ? pendingStatusValues.map(({deviceId, notificationId}) => ({deviceId, notificationId})) : []
    }
    return values && values.length > 0 ? values.map(({deviceId, notificationId}) => ({deviceId, notificationId})) : []
  },
  notificationLogsPayload(values) {
    return values.map(({deviceId, notificationId}) => ({deviceId, notificationId}))
  },
  notificationStatusCount(count) {
    return {
      type: constants.NOTIFICATION_STATUS_COUNT,
      count
    }
  },
  clearNotificationStatusCount() {
    return {
      type: constants.CLEAR_NOTIFICATION_STATUS_COUNT
    }
  },
  setPollTimerId(pollTimerId) {
    return {
      type: constants.SET_POLL_TIMER_ID,
      pollTimerId
    }
  },
  clearPollTimerId() {
    return {
      type: constants.CLEAR_POLL_TIMER_ID
    }
  },
  stopPoll() {
    return async (dispatch, getState) => {
      const pollTimerId = selectors.pollTimerId(getState())
      if (typeof pollTimerId === 'number') {
        clearTimeout(pollTimerId)
        return dispatch(actions.clearPollTimerId())
      }
    }
  },
  pollingAction() {
    return async (dispatch, getState) => {
      const notificationPayload = actions.createNotificationPayload(selectors.data(getState()).toJS())
      const pollingShouldStart = selectors.pollingShouldStart(getState())
      if ((notificationPayload && notificationPayload.length > 0) && pollingShouldStart) {
        await dispatch(actions.createPeripheralNotifications(notificationPayload))
        await dispatch(actions.poll())
      } else {
        return await dispatch(actions.stopPoll())
      }
    }
  },
  poll() {
    return async (dispatch, getState) => {
      const pollingShouldContinue = selectors.pollingShouldContinue(getState())
      const createdNotifications = await selectors.createdNotifications(getState()).toJS() || []
      const createdLogsValues = await selectors.createdLogsValues(getState()).toJS() || []
      const notificationStatusPayload = await actions.notificationStatusPayload(createdNotifications, createdLogsValues)
      if (pollingShouldContinue && notificationStatusPayload && notificationStatusPayload.length > 0) {
        const status = await dispatch(actions.validateNotificationStatus(notificationStatusPayload))
        if (status && status.length > 0) {
          await dispatch(actions.clearNotificationStatusCount())
          const fetchPWValuesDevices = await actions.notificationLogsPayload(status)
          if ((fetchPWValuesDevices && fetchPWValuesDevices.length > 0) && pollingShouldContinue) {
            const logsPayload = {
              config: {
                params: [params.DISTRIBUTION_LOOP_MGT, params.SDS_KIT_ELIX_CONFIG]
              },
              measures,
              notifications: fetchPWValuesDevices
            }
            await dispatch(actions.createLogsStoreQuery(logsPayload, createdLogsValues))
          }
        } else {
          let count = selectors.statusCount(getState())
          count > 6 ? await dispatch(actions.stopPoll()) : await dispatch(actions.notificationStatusCount(count + 1))
        }
        const pollTimerId = await setTimeout(() => {
          dispatch(actions.poll())
        }, 4000)
        dispatch(actions.setPollTimerId(pollTimerId))
      } else {
        return await dispatch(actions.stopPoll())
      }
    }
  },
  handleSearch (searchTerm) {
    return async(dispatch) => {
      await dispatch(actions.resetSystemStatus())
      await dispatch(actions.loadPeripherals(searchTerm))
    }
  },
  loadPeripherals (searchTerm) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      preventMultipleRequest: true,
      apiFunction: 'fetchPartnerDevicesOnSearch',
      args: (getState) => ({searchBy: searchTerm}),
      onSuccess: ({data}) => ({ type: constants.RECEIVED_PERIPHERAL_SUCCESS, data }),
      onFailure: ({errorMessage}) => ({ type: constants.FETCH_FAILURE, error: errorMessage })
    })
  },
  createPeripheralNotifications (payload) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH_CALLED}),
      preventMultipleRequest: true,
      apiFunction: 'createNotifications',
      args: payload,
      onSuccess: ({data}) => ({ type: constants.CREATE_NOTIFICATION_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.CREATE_NOTIFICATION_FAILURE, error: error.toString() })
    })
  },
  validateNotificationStatus(payload) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({type: constants.FETCH_CALLED}),
        preventMultipleRequest: true,
        apiFunction: 'validateNotificationStatus',
        args: payload,
        getResponse: true,
        onSuccess: ({data}) => ({ type: constants.VALIDATING_NOTIFICATION_SUCCESS, data }),
        onFailure: (error) => ({ type: constants.VALIDATING_NOTIFICATION_FAILURE, error: error.toString() })
      }))
      return (resp && resp.data) || []
    }
  },
  createLogsStoreQuery (payload, createdLogsValues) {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH_CALLED}),
      preventMultipleRequest: true,
      apiFunction: 'createLogsStore',
      args: payload,
      onSuccess: ({data}) => ({ type: constants.CREATE_LOGS_SUCCESS, data, createdLogsValues }),
      onFailure: (error) => ({ type: constants.CREATE_LOGS_FAILURE, error: error.toString() })
    })
  },
  setSearchTerm (searchTerm) {
    return { type: constants.SET_SEARCH_TERM, searchTerm }
  },
  fetchSystemStatus(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: deviceId,
      throwError: true,
      apiFunction: 'fetchSystemStatus',
      onSuccess: ({data}) => ({ type: constants.FETCH_SYSTEM_STATUS_SUCCESS, status: data, deviceId }),
      onFailure: ({message}) => ({ type: constants.FETCH_SYSTEM_STATUS_FAILURE, error: message })
    })
  },
  resetSystemStatus() {
    return { type: constants.RESET_SYSTEM_STATUS }
  }
}

const getState = (state) => state.partnerAdminDashboard

// Reducer
export const initialState = fromJS({
  fetching: false,
  systemStatus: {},
  pollingShouldStart: false,
  pollingShouldContinue: false,
  searchTerm: '',
  data: [],
  createdNotifications: [],
  createdLogsValues: [],
  statusCount: 0,
  fetchError: '',
  listingDataLoaded: false,
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
    case constants.FETCH_SYSTEM_STATUS_SUCCESS:
      const status = action.status
      return state
        .set('fetching', false)
        .setIn(['systemStatus', action.deviceId], status)
    case constants.FETCH_SYSTEM_STATUS_FAILURE:
      return state
        .set('fetching', false)
    case constants.RESET_SYSTEM_STATUS:
      return state
        .set('systemStatus', fromJS({}))
    case constants.SET_SEARCH_TERM:
      return state
      .set('searchTerm', action.searchTerm)
      .set('pollTimerId', null)
      .set('pollingShouldContinue', false)
      // .set('createdLogsValues', fromJS([]))
      .set('statusCount', 0)
    case constants.RECEIVED_PERIPHERAL_SUCCESS:
      const {data} = action
      return state
      .set('listingDataLoaded', true)
      .set('pollingShouldStart', data && data.length > 0)
      .set('data', fromJS(data))
      .set('fetching', false)
      .set('fetchError', '')
    case constants.FETCH_FAILURE:
      return state
      .set('pollingShouldStart', false)
      .set('pollingShouldContinue', false)
      .set('fetching', false)
      .set('fetchError', action.error)
    case constants.FETCH_CALLED:
      return state
      .set('pollingShouldStart', false)
    case constants.CREATE_NOTIFICATION_SUCCESS:
      const dataList = action && action.data
      const notificationList = dataList && dataList.length > 0 && dataList.map(item => item !== '' && JSON.parse(item.replace('data:', '')))
        .filter(item => item && item.notificationId)
      return state
      .set('createdNotifications', fromJS(notificationList))
      .set('pollingShouldStart', false)
      .set('pollingShouldContinue', true)
      .set('createdLogsValues', fromJS([]))
      .set('statusCount', 0)
    case constants.CREATE_NOTIFICATION_FAILURE:
      return state
      .set('pollingShouldStart', false)
      .set('pollingShouldContinue', false)
      .set('createdLogsValues', fromJS([]))
      .set('statusCount', 0)
    case constants.VALIDATING_NOTIFICATION_SUCCESS:
      return state
      .set('pollingShouldContinue', true)
    case constants.VALIDATING_NOTIFICATION_FAILURE:
      return state
      .set('pollingShouldContinue', false)
    case constants.CREATE_LOGS_SUCCESS:
      return state
      .set('createdLogsValues', fromJS([...action.createdLogsValues, ...action.data]))
      .set('pollingShouldContinue', true)
    case constants.CREATE_LOGS_FAILURE:
      return state
      .set('pollingShouldContinue', false)
    case constants.SET_POLL_TIMER_ID:
      return state
      .set('pollTimerId', action.pollTimerId)
    case constants.CLEAR_POLL_TIMER_ID:
      return state
      .set('pollTimerId', null)
      .set('pollingShouldContinue', false)
      .set('createdNotifications', fromJS([]))
    case constants.NOTIFICATION_STATUS_COUNT:
      return state
      .set('statusCount', action.count)
    case constants.CLEAR_NOTIFICATION_STATUS_COUNT:
      return state
      .set('statusCount', 0)
    default:
      return state
  }
}
// Selectors
export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  systemStatus: createSelector(getState, (state) => {
    return state.get('systemStatus')
  }),
  searchTerm: createSelector(getState, (state) =>
    state.get('searchTerm')
  ),
  data: createSelector(getState, (state) =>
    state.get('data')
  ),
  createdNotifications: createSelector(getState, (state) =>
    state.get('createdNotifications')
  ),
  pollingShouldStart: createSelector(getState, (state) =>
    state.get('pollingShouldStart')
  ),
  pollingShouldContinue: createSelector(getState, (state) =>
    state.get('pollingShouldContinue')
  ),
  createdLogsValues: createSelector(getState, (state) =>
    state.get('createdLogsValues')
  ),
  pollTimerId: createSelector(getState, (state) =>
    state.get('pollTimerId')
  ),
  statusCount: createSelector(getState, (state) =>
    state.get('statusCount')
  ),
  fetchError: createSelector(getState, (state) =>
  state.get('fetchError')
  ),
  listingDataLoaded: createSelector(getState, (state) =>
  state.get('listingDataLoaded')
  )
}
