import { fromJS, Map } from 'immutable'

// Cards
export const setCards = (state, data) => {
  const generalSectionCards = data['general'] && data['general']['persistent']
  const isServiceVisit = data['general'] && data['general']['persistent'].filter(({cardName}) => (cardName === 'lab_Gen_Service Visits'))?.length > 0
  if (data && data['general']) {
    data['general']['persistent'] = generalSectionCards.filter(({cardName}) => (!cardName.includes('lab_Gen_Service')))
  }
  let cardsList = fromJS(data)
  let cards = Map()
  let transientCards = Map()

  cardsList.keySeq().forEach(dataType => {
    let persistentCards = cardsList.get(dataType).get('persistent')
    persistentCards = persistentCards.map(card => {
      const deviceTypeId = card.getIn(['deviceType', 'deviceTypeId'])

      return card
        .set('_version', '0.1')
        .set('_pristine', true)
        .set('_downloads', 0)
        .set('_downloadAs', null)
        .set('deviceTypeId', deviceTypeId)
        .set('showCard', true)
        .remove('deviceType')
        .remove('createdBy')
        .remove('createdOn')
        .remove('modifiedOn')
    })
    cards = cards.set(dataType, persistentCards)

    let trCards = cardsList.get(dataType).get('transient')
    trCards = trCards.map(card => {
      const deviceTypeId = card.getIn(['deviceType', 'deviceTypeId'])

      return card
        .set('_version', '0.1')
        .set('_pristine', true)
        .set('_downloads', 0)
        .set('_downloadAs', null)
        .set('deviceTypeId', deviceTypeId)
        .set('showCard', true)
        .remove('deviceType')
        .remove('createdBy')
        .remove('createdOn')
        .remove('modifiedOn')
    })
    transientCards = transientCards.set(dataType, trCards)
  })
  return state
    .set('cards', cards)
    .set('isServiceVisit', isServiceVisit)
    .set('transientCards', transientCards)
}

export const updateCardVersion = (state, references) => {
  references.forEach(({ id, dataType }) => {
    const cardList = state.getIn(['cards', dataType])

    state = state.setIn(['cards', dataType], cardList.update(
      cardList.findIndex(card => card.get('id') === id),
      card => {
        let version = card.get('_version')
        return card.set('_version', (parseFloat(version) + 0.1).toFixed(1))
      }
    ))
  })

  return state
}

export const toggleCard = (state, references) => {
  references.forEach(({ id, dataType }) => {
    const cardList = state.getIn(['cards', dataType])

    state = state.setIn(['cards', dataType], cardList.update(
      cardList.findIndex(card => card.get('id') === id),
      card => {
        return card.set('showCard', false)
      }
    ))
  })

  return state
}

export const resetCardVisibility = (state, section) => state.setIn(['cards', section], state.getIn(['cards', section]).map(c => c.set('showCard', true)))

export const updateCard = (state, cardId, newCard) => {
  let cardList = state.get('cards').get(newCard.dataType)

  return state.setIn(['cards', newCard.dataType], cardList.update(
    cardList.findIndex(card => card.get('id') === cardId),
    card => {
      let version = (parseFloat(card.get('_version')) + 0.1).toFixed(1)
      return Map(newCard)
        .set('id', cardId)
        .set('_version', version)
        .set('measures', newCard.measures)
        .set('dimensions', newCard.dimensions)
    }
  ))
}

export const updateDownloadCount = (state, cardId, dataType, downloadAs) => {
  const cardList = state.get('cards').get(dataType)

  return state.setIn(['cards', dataType], cardList.update(
    cardList.findIndex(card => card.get('id') === cardId),
    card => {
      return card
        .set('_downloads', card.get('_downloads') + 1)
        .set('_downloadAs', downloadAs)
    }
  ))
}

// Notifications
const POLLING_COMPLETED_STATUS = {
  'new': true,
  'completed': true,
  'failed': true
}

export const insertNotifications = (state, aliasNames) => {
  let nextNotifications = state.get('notifications').toJS()

  aliasNames.forEach(({aliasName, dataType}) => {
    nextNotifications = {
      ...nextNotifications,
      [aliasName]: {
        dataType,
        pollingCount: 0,
        pollingStatus: 'new',
        notificationId: null,
        logTimestamp: null,
        initiatedBy: null
      }
    }
  })

  return state.set('notifications', fromJS(nextNotifications))
}

export const updateNotifications = (state, updates) => {
  let nextNotifications = state.get('notifications').toJS()

  for (let aliasName in updates) {
    const { notificationId } = updates[aliasName]
    const {
      dataType, logTimestamp, pollingCount, initiatedBy
    } = nextNotifications[aliasName]

    nextNotifications = {
      ...nextNotifications,
      [aliasName]: {
        dataType,
        initiatedBy,
        notificationId,
        logTimestamp,
        pollingCount,
        pollingStatus: 'started'
      }
    }
  }

  return state.set('notifications', fromJS(nextNotifications))
}

export const updatePollingResult = (state, pollingResponse) => {
  let notifications = state.get('notifications')

  // update polling result
  if (pollingResponse.length) {
    pollingResponse.forEach(({ notificationId: id, data }) => {
      notifications
        .keySeq()
        .forEach((aliasName) => {
          const notificationId = notifications.getIn([aliasName, 'notificationId'])
          if (notificationId === id) {
            notifications = notifications
              .setIn([aliasName, 'pollingCount'], 0)
              .setIn([aliasName, 'pollingStatus'], 'completed')
              .setIn([aliasName, 'logTimestamp'], data[0]['logTimestamp'])
          }
        })
    })
  }

  // terminate polling above 40 counts
  notifications
    .keySeq()
    .forEach((aliasName) => {
      const pollingStatus = notifications.getIn([aliasName, 'pollingStatus'])
      const isBeingPolled = (pollingStatus === 'started')
      if (isBeingPolled) {
        const pollingCount = notifications.getIn([aliasName, 'pollingCount'])
        if (pollingCount > 6) {
          notifications = notifications
            .setIn([aliasName, 'pollingStatus'], 'failed')
            .setIn([aliasName, 'pollingCount'], 0)
        }
      }
    })

  return state.set('notifications', notifications)
}

export const updatePollingFailure = (state, notificationIds) => {
  const notifications = state.get('notifications').toJS()

  notificationIds.forEach((id) => {
    for (let aliasName in notifications) {
      const { notificationId } = notifications[aliasName]
      if (notificationId === id) {
        state = state
          .setIn(['notifications', aliasName, 'pollingCount'], 0)
          .setIn(['notifications', aliasName, 'pollingStatus'], 'failed')
      }
    }
  })

  return state
}

export const updatePollingStatus = (state, aliasNames, status, initiator) => {
  const notifications = state.get('notifications').toJS()

  aliasNames.forEach((_aliasName) => {
    for (let aliasName in notifications) {
      if (_aliasName === aliasName) {
        state = state
          .setIn(
            ['notifications', aliasName, 'pollingStatus'],
            status ? 'started' : 'failed'
          )
          .setIn(
            ['notifications', aliasName, 'initiatedBy'],
            initiator
          )
      }
    }
  })

  return state
}

export const updatePollingCounter = (state) => {
  let notifications = state.get('notifications')
  notifications
    .keySeq()
    .forEach((aliasName) => {
      const pollingStatus = notifications.getIn([aliasName, 'pollingStatus'])
      const isBeingPolled = (pollingStatus === 'started')
      if (isBeingPolled) {
        const pollingCount = notifications.getIn([aliasName, 'pollingCount'])
        notifications = notifications.setIn([aliasName, 'pollingCount'], pollingCount + 1)
      }
    })

  return state.set('notifications', notifications)
}

export const getNotificationSections = (cards, notificationSections) => {
  let aliasNames = []

  for (const dataType in cards) {
    const notificationSectionIndex = notificationSections
      .findIndex(({ name }) => name === dataType)

    if (notificationSectionIndex > -1) {
      const { aliasName } = notificationSections[notificationSectionIndex]

      aliasNames = [
        ...aliasNames,
        {
          dataType,
          aliasName
        }
      ]
    }
  }

  return aliasNames
}

export const createNotificationDTO = (device, notifications) => {
  let notificationDetails = []

  Object.keys(notifications).forEach((aliasName) => {
    const { dataType, pollingStatus, notificationId } = notifications[aliasName]

    if (!POLLING_COMPLETED_STATUS[pollingStatus] && notificationId) {
      notificationDetails = [
        ...notificationDetails,
        { dataType, notificationId: [notificationId] }
      ]
    }
  })

  return {
    deviceId: device?.uuid,
    deviceTypeId: device?.deviceType?.id,
    notifications: notificationDetails
  }
}

export const shouldContinuePolling = (notifications) => {
  let flag = false

  Object.keys(notifications).forEach((aliasName) => {
    const { pollingStatus } = notifications[aliasName]
    if (!POLLING_COMPLETED_STATUS[pollingStatus]) {
      flag = true
    }
  })

  return flag
}
