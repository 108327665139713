import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import { fromJS } from 'immutable'
import {
  actions as userProfileActions
} from 'store/modules/UserProfile'

export const constants = {
  FETCH: 'USER_FEED_BACK_REPORT/FETCH',
  FETCH_USER_TERRITORIES_SUCCESS: 'USER_FEED_BACK_REPORT/FETCH_USER_TERRITORIES_SUCCESS',
  FETCH_USER_TERRITORIES_FAILURE: 'USER_FEED_BACK_REPORT/FETCH_USER_TERRITORIES_SUCCESS',
  RECEIVE_COUNTRIES: 'USER_FEED_BACK_REPORT/RECEIVE_COUNTRIES',
  FETCH_FAILURE: 'USER_FEED_BACK_REPORT/FETCH_FAILURE',
  GENERATE_USER_FEEDBACK_REPORT_SUCCESS: 'USER_FEED_BACK_REPORT/GENERATE_USER_FEEDBACK_REPORT_SUCCESS',
  GENERATE_USER_FEEDBACK_REPORT_FAILURE: 'USER_FEED_BACK_REPORT/GENERATE_USER_FEEDBACK_REPORT_FAILURE',
  RESET_USER_FEED_BACK_REPORT: 'USER_FEED_BACK_REPORT/RESET_USER_FEED_BACK_REPORT'
}

// Action Creators
export const actions = {
  fetchUserTerritories (userId) {
    // I am waiting over here for terrotory response that is why this method is async
    return async (dispatch, getState) => {
      try {
        await dispatch({ type: constants.FETCH })
        const { data: territories } = await dispatch(userProfileActions.fetchUserTerritories(userId))
        const { data: countries } = await dispatch(userProfileActions.searchTerritories())

        const data = territories
            .map(({ resourceId }) => {
              const country = countries.find(({ id }) => id === resourceId)
              return country
            })

        dispatch({ type: constants.FETCH_USER_TERRITORIES_SUCCESS, data })
      } catch (error) {
        dispatch({ type: constants.FETCH_FAILURE, error: error.toString() })
      }
    }
  },
  resetUserFeedBackReport () {
    return {type: constants.RESET_USER_FEED_BACK_REPORT}
  },
  loadCountries(country = null) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'loadCountries',
      args: { searchBy: country },
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES, data, country }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  generateUserFeedbackReport (payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'generateUserFeedbackReport',
      args: payload,
      throwError: true,
      onSuccess: (data) => ({type: constants.GENERATE_USER_FEEDBACK_REPORT_SUCCESS, data}),
      onFailure: (error) => ({type: constants.GENERATE_USER_FEEDBACK_REPORT_FAILURE, error})
    })
  }
}

export const initialState = fromJS({
  fetching: false,
  fetchingFilters: false,
  fetchError: null,
  countries: [],
  searchedCountries: [],
  userTerritories: [],
  userFeedbackReportSuccess: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCH_USER_TERRITORIES_SUCCESS:
      return state
        .set('fetching', false)
        .set('userTerritories', action.data)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
        .set('fetchingFilters', false)
    case constants.RECEIVE_COUNTRIES:
      if (action.country) {
        return state
        .set('searchedCountries', fromJS(action.data))
        .set('fetching', false)
        .set('fetchSuccess', true)
      } else {
        return state
          .set('countries', fromJS(action.data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      }
    case constants.GENERATE_USER_FEEDBACK_REPORT_SUCCESS:
      return state
      .set('fetching', false)
      .set('userFeedbackReportSuccess', 'SUCCESS')
    case constants.GENERATE_USER_FEEDBACK_REPORT_FAILURE:
      return state
      .set('fetching', false)
      .set('userFeedbackReportSuccess', null)
    case constants.RESET_USER_FEED_BACK_REPORT:
      return state
        .set('fetching', false)
        .set('fetchingFilters', false)
        .set('fetchError', null)
        .set('countries', [])
        .set('searchedCountries', [])
        .set('userTerritories', [])
        .set('userFeedbackReportSuccess', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.userFeedBackReport

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  userTerritories: createSelector(getState, (state) => {
    return state.get('userTerritories')
  }),
  userFeedbackReportSuccess: createSelector(getState, (state) =>
    state.get('userFeedbackReportSuccess')
  )
}
