import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames/bind'
import { sizes, getTypeValues } from '../types'
import {Select as AntSelect} from 'antd'
import FormGroup from './FormGroup'

const propTypes = {
  size: PropTypes.oneOf(getTypeValues(sizes)),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  isFormGroup: PropTypes.bool,
  formGroupStyle: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.node
  })
}

const defaultProps = {
  searchable: false,
  clearable: false,
  isFormGroup: true
}

const Select = (props) => {
  const {
    size,
    isFormGroup,
    formGroupStyle, // eslint-disable-line no-unused-vars
    className,
    input: inputProps,
    meta: { touched, error },
    ...otherProps
  } = props

  const showError = touched && error

  // Component Classes
  const componentClass = cx(
    {
      [`select-size-${size}`]: size
    },
    className
  )

  const select = (
    <div className={cx('select-container')}>
      <div className={componentClass}>
        <AntSelect {...inputProps} {...otherProps} />
        {showError &&
          <div className={cx('form-error-message')}>{error}</div>
        }
      </div>
    </div>
  )

  // If Form Group Return Base Component Wrapped in Form Group Element
  if (isFormGroup) {
    return (
      <FormGroup {...props}>
        {select}
      </FormGroup>
    )
  }

  // Return Base Component
  return select
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
