import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import GenericListingContainer from 'containers/GenericListingContainer/GenericListingContainer'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'
import { fromJS } from 'immutable'
import './DeviceCertificateStatus.scss'
import DownloadPopup from './DownloadPopup'
import DeviceCertificateViewMoreContainer from 'containers/DeviceCertificateViewMoreContainer/DeviceCertificateViewMoreContainer'
import { Tooltip } from 'antd'
import Tooltips from 'components/Tooltip/Tooltip'
import refresh from 'static/icons/refresh.svg'
import download from 'static/icons/svg/download.svg'
import options from 'static/icons/options.svg'
import { injectOverlay } from 'shared-components/utils'
import WithDateFormat from 'containers/withDateFormat/withDateFormat'

const headers = [
  { header: 'deviceName' },
  { header: 'serialNo' },
  { header: 'deviceStatus' },
  { header: 'issuedByCa' },
  { header: 'expires' },
  { header: 'validCerts' },
  { header: 'configFileLastUploadedDate', isSortable: false },
  { header: 'actions', isSortable: false }
]
const COL_WIDTHS = [20, 10, 10, 15, 10, 10, 15, 10]

class DeviceCertificateStatus extends PureComponent {
  static propTypes = {
    row: PropTypes.object,
    openModal: PropTypes.func,
    createCertificateRenewNotification: PropTypes.func,
    downloadCertificateFile: PropTypes.func,
    close: PropTypes.func,
    openSnackBar: PropTypes.func,
    permission: PropTypes.bool,
    filterCategories: PropTypes.array,
    intl: PropTypes.object,
    downLoadDocumentCertificate: PropTypes.func,
    fetching: PropTypes.bool
  }
  async initiateCertificateFileDownload(certificateType, deviceId) {
    const { downloadCertificateFile, close, openSnackBar, intl } = this.props
    try {
      const { data } = await downloadCertificateFile(certificateType, deviceId)
      var certificateData = data && data.certificate
      var file = new Blob([certificateData], { type: 'text/plain' })
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(file)
      a.download = `${certificateType}.pem`
      a.click()
    } catch (ex) {
      close()
      openSnackBar(
        { message: intl.formatMessage({ id: 'next.DOWNLOAD_CERTIFICATE_ERROR' }), type: 'E' })
    }
  }
  async initiateDocDownloadCertificate(docId) {
    const { downLoadDocumentCertificate, close } = this.props
    try {
      const { data, headers } = await downLoadDocumentCertificate(docId)
      this.download(data, headers)
    } catch (ex) {
      close()
    }
  }
  download = (data, headers) => {
    var cd = headers.get('content-disposition')
    let fileName = `${cd.split('=')[2]}`.replace(/["']/g, '')
    let url = window.URL.createObjectURL(data)
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.target = '_self'
    a.style = 'display: none'
    a.href = url
    a.download = fileName
    a.click()
    setTimeout(function () { window.URL.revokeObjectURL(url) }, 1000)
  }
  openViewMore = (list) => {
    const { openModal } = this.props
    const deviceCertificateDetails = {
      deviceName: list.deviceName,
      serialNo: list.serialNo,
      deviceUuid: list.deviceUuid,
      deviceId: list.deviceId,
    }

    openModal(
      <DeviceCertificateViewMoreContainer
        deviceCertificateDetails={deviceCertificateDetails}
      />,
      {
        title: 'View More',
        wrapClassName: 'device-certificate-modal'
      }
    )
  }
  openDownloadPopup = (deviceId, docId) => {
    const { close, openModal, fetching } = this.props
    const options = {
      title: 'Downloads',
    }
    openModal(
      <DownloadPopup
        close={close}
        docId={docId}
        fetching={fetching}
        initiateCertificateFileDownload={(certificateType) =>
          this.initiateCertificateFileDownload(certificateType, deviceId)
        }
        initiateDocDownloadCertificate={() => this.initiateDocDownloadCertificate(docId)}
      />,
      options
    )
  }
  customizeRowData = (data) => {
    const listing = (data && typeof data === 'object' && data.toJS()) || []
    let { permission, createCertificateRenewNotification } = this.props
    const certificateAccessPermission =
      permission?.deviceregistry && 'CREATE' in permission.deviceregistry
    const certificateDownloadPermission =
      permission?.certificate && 'READ' in permission.certificate
    let mapData =
      listing && listing.length > 0 ?
        listing.map((list) => {
          let expires = list.expires
          let now = new Date()
          now.setHours(0, 0, 0, 0)
          let expiresIn = moment(expires).diff(moment(now), 'days')
          let currentTS = new Date().getTime()
          let expiresInTS = new Date(expires).getTime()
          let activeCerts = list.validCerts
          let shouldEnableRenewButton =
            list.deviceStatus === '1' &&
            list.validCerts === 1 &&
            expiresIn <= 30 &&
            currentTS <= expiresInTS - 1800000 // Button will be disabled 30 minutes before the expiry
          const shouldEnableDownloadButton = list.deviceStatus === '1'
          const isEnableDownloadCert =
            certificateDownloadPermission && shouldEnableDownloadButton
          const isEnableRenewCert =
            certificateAccessPermission && shouldEnableRenewButton
          return {
            ...list,
            deviceName: <Tooltips title={list.deviceName}>{list.deviceName}</Tooltips>,
            configFileLastUploadedDate: <WithDateFormat date={list.configFileLastUploadedDate} />,
            deviceStatus: list.deviceStatus === '1' ? 'ACTIVE' : 'DEACTIVE',
            expires: Number.isNaN(expiresIn) ? (
              ''
            ) : currentTS > expiresInTS ? (
              <FormattedMessage id='next.EXPIRED' />
            ) : expiresIn < 2 ? (
              `${expiresIn} day`
            ) : (
              `${expiresIn} days`
            ),
            activeCerts: activeCerts > 2 ? '>2' : activeCerts,
            actions: (
              <div className='show-icons'>
                <Tooltip placement='top' title={<FormattedMessage id='next.RENEW_CERTIFICATE' />}>
                  <a onClick={() => isEnableRenewCert && createCertificateRenewNotification(list.deviceId)}
                    className={`device-link-no-decoration ${(!shouldEnableRenewButton || !certificateAccessPermission) && 'disabled'}`}>
                    <img alt className={`device-style-icon certi_renew_icon ${!isEnableRenewCert ? 'fade-opacity' : ''}`} src={refresh} />
                  </a>
                </Tooltip>
                <Tooltip placement='top' title={<FormattedMessage id='next.DOWNLOAD_CERTIFICATE' />}>
                  <a className='device-link-no-decoration'>
                    <span onClick={() => isEnableDownloadCert && this.openDownloadPopup(list.deviceId, list.docId)}>
                      <img alt className={`device-style-icon ${!isEnableDownloadCert ? 'fade-opacity' : ''}`} src={download} />
                    </span>
                  </a>
                </Tooltip>
                <Tooltip
                  placement='top'
                  title={<FormattedMessage id='next.MORE' />}
                >
                  <a onClick={() => this.openViewMore(list)} className='device-link-no-decoration'>
                    <img alt className='device-style-icon certi_viewmore_icon' src={options} />
                  </a>
                </Tooltip>
              </div>
            ),
          }
        }) : []
    return mapData && fromJS(mapData)
  }
  render() {
    const { filterCategories } = this.props
    const additionalProps = {
      additionalFilterProps:
      {
        onlyFilterValue: true
      }
    }
    return (
      <div className='customer-view-table'>
        <GenericListingContainer
          headers={headers}
          title={'CERTIFICATES_STATUS'}
          headersTitle={'DEVICE_CERTIFICATES_HEADER_'}
          columnWidths={COL_WIDTHS}
          listingApi='fetchDeviceCertificates'
          paginationName='deviceCertificateStatus'
          defaultSort='expires'
          defaultSortDirection
          showFilters
          showSearch
          customizeRowData={this.customizeRowData}
          customizeRow
          searchBy={[]}
          searchPlaceholder='next.DEVICE_CERTIFICATE_DASHBOARD_SEARCH_PLACEHOLDER'
          listingName='deviceCertificateStatus'
          filterCategories={filterCategories}
          {...additionalProps}
        />
      </div>
    )
  }
}

export default injectOverlay(injectIntl(DeviceCertificateStatus))
