import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { actions as withSubmitFormAction, selectors as withSubmitSelectors } from 'store/modules/WithSubmitForm'
import { actions as authAction } from 'store/modules/Auth'

const withSubmitForm = (WrapComponent) => {
  class WithSubmitForm extends PureComponent {
    static propTypes = {
      submitForm: PropTypes.func,
      resetErrorMessage: PropTypes.func,
      getAccessToken: PropTypes.func
    }
    handleSubmitForm = async (apiFunction, args, additionalValue, preventToken, callRefreshToken, callBackFunc) => {
      const { submitForm, resetErrorMessage, getAccessToken } = this.props
      callRefreshToken && await getAccessToken()
      resetErrorMessage()
      await submitForm(apiFunction, args, additionalValue, preventToken, callBackFunc)
    }
    componentWillUnmount() {
      const { resetErrorMessage } = this.props
      resetErrorMessage()
    }
    render() {
      return (
        <WrapComponent
          handleSubmitForm={this.handleSubmitForm}
          {...this.props}
        />
      )
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      isSubmitted: withSubmitSelectors.isSubmitted(state),
      isAddedSuccessfully: withSubmitSelectors.isAddedSuccessfully(state),
      errorMessage: withSubmitSelectors.errorMessage(state),
      errorCode: withSubmitSelectors.errorCode(state),
      errorStatus: withSubmitSelectors.errorStatus(state),
      responseData: withSubmitSelectors.responseData(state),
      additionalValue: withSubmitSelectors.additionalValue(state),
      fetchingStatus: withSubmitSelectors.fetchingStatus(state)
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
      {
        submitForm: (apiFunction, args, isArgs, preventToken, callBackFunc) =>
          withSubmitFormAction.submitForm(apiFunction, args, isArgs, preventToken, callBackFunc),
        resetErrorMessage: withSubmitFormAction.resetErrorMessage,
        getAccessToken: authAction.getAccessToken
      },
      dispatch
    )
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithSubmitForm)
}
withSubmitForm.displayName = 'withSubmitForm'
export default withSubmitForm