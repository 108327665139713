import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as AntTooltip } from 'antd'
import 'antd/lib/tooltip/style/css'
import './Tooltip.scss'

const tooltip = ({ title, tooltipStyle, children, ...restProps }) => {
  return (
    <AntTooltip title={title} {...restProps}>
      <div className="tooltip-wrap" style={{ ...tooltipStyle }}>
        {children ? <span className='download-filename' alt={title}>{children}</span>
          : <span className='download-filename' alt={title}>{title}</span>}
      </div>
    </AntTooltip>
  )
}

const propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  tooltipStyle: PropTypes.object,
  children: PropTypes.element
}
tooltip.propTypes = propTypes
export default tooltip