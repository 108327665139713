import ExtendableError from 'es6-error'

export default class ApiError extends ExtendableError {
  constructor (status = 500, errorCode, errorMessage) {
    super()
    this.status = status
    this.errorCode = errorCode
    this.errorMessage = errorMessage
  }
}
