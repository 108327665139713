import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { filterTypes as orgUsersFilterTypes } from 'constants/orgUsersListing'

// Constants
export const constants = {
  FETCH: 'ORG_USERS/FETCH',
  FETCH_FAILURE: 'ORG_USERS/FETCH_FAILURE',
  RECEIVE_FACETS: 'ORG_USERS/RECEIVE_FACETS',
  FETCH_SET_DATA: 'ORG_USERS/FETCH_SET_DATA',
  SET_DATA: 'ORG_USERS/SET_DATA',
  RESET_ORG_DATA: 'ORG_USERS//RESET_ORG_DATA'
}

export const actions = {
  fetchUserFacets() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'loadUserFacets',
      onSuccess: ({ data }) => {
        return ({ type: constants.RECEIVE_FACETS, data })
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  viewOrganization(data) {
    if (data) {
      return ({
        type: constants.SET_DATA,
        data
      })
    }
  },
  resetSuccessItemStatus() {
    return { type: constants.RESET_ORG_DATA }
  }
}

export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  userFacets: [],
  orgData: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.RECEIVE_FACETS:
      return state
      .set('userFacets', sanitizeFilters(action.data))
        .set('fetching', false)
    case constants.SET_DATA:
      return state
        .set('orgData', action.data)
        .set('fetching', false)
    case constants.RESET_ORG_DATA:
      return state
        .set('orgData', null)
        .set('fetching', false)
        .set('fetchError', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.orgUsers

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  userFacets: createSelector(getState, (state) => {
    return state.get('userFacets')
  }),
  getOrgData: createSelector(getState, (state) =>
    state.get('orgData')
  )
}

// helper functions
function sanitizeFilters(filterTypes) {
  const filterOrder = [
    orgUsersFilterTypes.STATUS,
    orgUsersFilterTypes.COUNTRY_CODE
  ]
  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      const values = filterTypes[filter]
      const isSearchBox = filter === orgUsersFilterTypes.ORG || filter === orgUsersFilterTypes.COUNTRY_CODE
      const categoryType = filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => ({
          name: val,
          label: val,
          key: index,
          value: categoryType === orgUsersFilterTypes.STATUS || categoryType === orgUsersFilterTypes.ROLE ? `${filter}.${val}` : val
        }))
      filterCategories.push({categoryType, categoryValues, searchBox: isSearchBox})
    }
  })

  return fromJS(filterCategories)
}
