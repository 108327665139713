import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'USERPROFILE/FETCH',
  RECEIVE_USERPROFILE_SUCCESS: 'USERPROFILE/RECEIVE_USERPROFILE_SUCCESS',
  RECEIVE_USERPROFILE_FAILURE: 'USERPROFILE/RECEIVE_USERPROFILE_FAILURE',
  FETCH_FAILURE: 'USERPROFILE/FETCH_FAILURE',
  ENABLE_SAVE_BTN: 'USERPROFILE/ENABLE_SAVE_BTN',
  DISABLE_SAVE_BTN: 'USERPROFILE/DISABLE_SAVE_BTN',
  RESET_USERPROFILE_DATA: 'USERPROFILE/RESET_USERPROFILE_DATA',
  UPDATED_USERPROFILE_ROLE: 'USERPROFILE/UPDATED_USERPROFILE_ROLE',
  RESET_UPDATE_ROLE_STATUS: 'USERPROFILE/RESET_UPDATE_ROLE_STATUS',
  SET_SELECTED_ROLE: 'USERPROFILE/SET_SELECTED_ROLE',
  RESET_SELECTED_ROLE: 'USERPROFILE/RESET_SELECTED_ROLE',
  UPDATE_USER_PROFILE_DATA: 'USERPROFILE/UPDATE_USER_PROFILE_DATA',
  SET_SELECTED_COUNTRY: 'USERPROFILE/SET_SELECTED_COUNTRY',
  SET_SELECTED_COUNTRY_ZONE: 'USERPROFILE/SET_SELECTED_COUNTRY_ZONE',
  RE_INVITE_USER_SUCCESS: 'USERPROFILE/RE_INVITE_USER_SUCCESS',
  RESET_REINVITE_SUCCESS: 'USERPROFILE/RESET_REINVITE_SUCCESS',
  RE_INVITE_USER_FAILURE: 'USERPROFILE/RE_INVITE_USER_FAILURE',
  RESET_REINVITE_FAILURE: 'USERPROFILE/RESET_REINVITE_FAILURE',
  FAILURE_USERPROFILE_ROLE: 'USERPROFILE/FAILURE_USERPROFILE_ROLE',
  RESET_UPDATE_ROLE_FAILURE: 'USERPROFILE/RESET_UPDATE_ROLE_FAILURE',
  LOAD_DATE_TIME_FORMAT_SUCCESS: 'USERPROFILE/LOAD_DATE_TIME_FORMAT_SUCCESS',
  LOAD_DATE_TIME_FORMAT_FAILURE: 'USERPROFILE/LOAD_DATE_TIME_FORMAT_FAILURE',
  SET_SELECTED_LANGUAGE: 'USERPROFILE/SET_SELECTED_LANGUAGE',
  LOAD_LANGUAGES_SUCCESS: 'USERPROFILE/LOAD_LANGUAGES_SUCCESS',
  LOAD_LANGUAGES_FAILURE: 'USERPROFILE/LOAD_LANGUAGES_FAILURE',
  UNSUBSCRIBE_USER_FAILURE: 'USERPROFILE/UNSUBSCRIBE_USER_FAILURE',
  SET_CONSENT_PRIVACY_DISABLE_STATUS: 'USERPROFILE/SET_CONSENT_PRIVACY_DISABLE_STATUS',
  SET_DEFAULT_TRANSLATION_SUCCESS_MESSAGE: 'USERPROFILE/SET_DEFAULT_TRANSLATION_SUCCESS_MESSAGE',
  FETCH_USER_TERRITORIES_SUCCESS: 'USERPROFILE/FETCH_USER_TERRITORIES_SUCCESS',
  SEARCH_TERRITORIES_SUCCESS: 'USERPROFILE/SEARCH_TERRITORIES_SUCCESS',
  SEARCH_TERRITORIES_FAILURE: 'USERPROFILE/SEARCH_TERRITORIES_FAILURE',
  ADD_TERRITORY: 'USERPROFILE/ADD_TERRITORY',
  REMOVE_TERRITORY: 'USERPROFILE/REMOVE_TERRITORY',
  SET_USER_TERRITORY_PRIVILEGE: 'USERPROFILE/SET_USER_TERRITORY_PRIVILEGE',
  UPDATED_USERPROFILE_DATA: 'USERPROFILE/UPDATED_USERPROFILE_DATA',
  FAILURE_UPDATE_USERPROFILE_DATA: 'USERPROFILE/FAILURE_UPDATE_USERPROFILE_DATA',
  CLEAR_USER_TERRITORIES: 'USERPROFILE/CLEAR_USER_TERRITORIES',
  RESET_PROFILE_DATA_SUCCESS_STATUS: 'USERPROFILE/RESET_PROFILE_DATA_SUCCESS_STATUS',
  RESET_PROFILE_DATA_FAILURE_STATUS: 'USERPROFILE/RESET_PROFILE_DATA_FAILURE_STATUS',
  UPDATE_TERMS_AND_CONDITION_CONTENT: 'USERPROFILE/UPDATE_TERMS_AND_CONDITION_CONTENT',
  UPDATE_PRIVACY_POLICY_CONTENT: 'USERPROFILE/UPDATE_PRIVACY_POLICY_CONTENT',
  UPDATE_CONSENT_COUNTRY_CODE_TERMS: 'USERPROFILE/UPDATE_CONSENT_COUNTRY_CODE_TERMS',
  UPDATE_CONSENT_COUNTRY_CODE_PRIVACY: 'USERPROFILE/UPDATE_CONSENT_COUNTRY_CODE_PRIVACY',
  RECIEVE_OUTPUT_DISPLAY_DATA_SUCCESS: 'USERPROFILE/RECIEVE_OUTPUT_DISPLAY_DATA_SUCCESS',
  RECIEVE_OUTPUT_DISPLAY_DATA_FAILURE: 'USERPROFILE/RECIEVE_OUTPUT_DISPLAY_DATA_FAILURE',
  FETCH_USER_IMAGE_SUCCESS: 'USERPROFILE/FETCH_USER_IMAGE_SUCCESS',
  ADD_USER_IMAGE_SUCCESS: 'USERPROFILE/ADD_USER_IMAGE_SUCCESS',
  DELETE_USER_IMAGE_SUCCESS: 'USERPROFILE/DELETE_USER_IMAGE_SUCCESS',
  MANAGE_PROFILE_FETCH_FAILURE: 'USERPROFILE/MANAGE_PROFILE_FETCH_FAILURE',
  RESET_MANAGE_PROFILE_FAILURE_STATUS: 'USERPROFILE/RESET_MANAGE_PROFILE_FAILURE_STATUS',
  RESET_FETCH: 'USERPROFILE/RESET_FETCH'
}

// Action Creators
export const actions = {
  loadUserProfile(userId, ownUser) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'fetchUserProfileData',
        args: userId,
        getResponse: true,
        onSuccess: ({ data }) => ({ type: constants.RECEIVE_USERPROFILE_SUCCESS, data, ownUser }),
        onFailure: (error) => ({ type: constants.RECEIVE_USERPROFILE_FAILURE, error: error.toString() })
      }))
      return resp?.data || []
    }
  },
  resetUserProfileData(ownUserProfileData) {
    return ({ type: constants.RESET_USERPROFILE_DATA, ownUserProfileData })
  },
  enableSaveBtn() {
    return ({ type: constants.ENABLE_SAVE_BTN })
  },
  disableSaveBtn() {
    return ({ type: constants.DISABLE_SAVE_BTN })
  },
  updateUserRole(userId, role) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateUserRole',
      args: { userId, role },
      onSuccess: () => ({ type: constants.UPDATED_USERPROFILE_ROLE }),
      onFailure: ({ status }) => ({ type: constants.FAILURE_USERPROFILE_ROLE, status })
    })
  },
  updateUserProfile(userId, { updatedFields, ...data }) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateUserProfile',
      args: { userId, query: updatedFields },
      throwError: true,
      onSuccess: () => ({ type: constants.UPDATED_USERPROFILE_DATA, data: { updatedFields, ...data } }),
      onFailure: ({ status }) => ({ type: constants.FAILURE_UPDATE_USERPROFILE_DATA, status })
    })
  },
  resetUpdateRoleStatus() {
    return ({ type: constants.RESET_UPDATE_ROLE_STATUS })
  },
  setSelectedRole(role) {
    return ({ type: constants.SET_SELECTED_ROLE, role: role })
  },
  resetSelecedRole() {
    return ({ type: constants.RESET_SELECTED_ROLE })
  },
  updateUserProfileData(user) {
    return ({ type: constants.UPDATE_USER_PROFILE_DATA, user })
  },
  setSelectCountry(country) {
    return ({ type: constants.SET_SELECTED_COUNTRY, country })
  },
  setSelectCountryZone(countryZone) {
    return ({ type: constants.SET_SELECTED_COUNTRY_ZONE, countryZone })
  },
  setSelectLanguage(language) {
    return ({ type: constants.SET_SELECTED_LANGUAGE, language })
  },
  reInviteUser(userId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'reInviteUser',
      args: { userId },
      onSuccess: () => ({ type: constants.RE_INVITE_USER_SUCCESS }),
      onFailure: ({ status }) => ({ type: constants.RE_INVITE_USER_FAILURE, status })
    })
  },
  updateReInviteSuccess() {
    return ({ type: constants.RESET_REINVITE_SUCCESS })
  },
  updateReInviteFailure() {
    return ({ type: constants.RESET_REINVITE_FAILURE })
  },
  resetUpdateRoleFailure() {
    return ({ type: constants.RESET_UPDATE_ROLE_FAILURE })
  },
  loadDateTimeFormats() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDateTimeFormats',
      onSuccess: ({ data }) => ({ type: constants.LOAD_DATE_TIME_FORMAT_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.LOAD_DATE_TIME_FORMAT_FAILURE, status })
    })
  },
  fetchAllLanguages() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchAllLanguages',
      onSuccess: ({ data }) => ({ type: constants.LOAD_LANGUAGES_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.LOAD_LANGUAGES_FAILURE, status })
    })
  },
  unsubscribeUser() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'unsubscribeUser',
      throwError: true,
      onFailure: () => ({ type: constants.UNSUBSCRIBE_USER_FAILURE })
    })
  },
  setConsentPrivacyDisableStatus(status) {
    return ({ type: constants.SET_CONSENT_PRIVACY_DISABLE_STATUS, status })
  },
  setDefaultTranslationSuccess(status) {
    return ({ type: constants.SET_DEFAULT_TRANSLATION_SUCCESS_MESSAGE, status })
  },
  fetchUserTerritories(userId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchUserTerritories',
      getResponse: true,
      args: { userId, filterBy: 'territory' },
      onSuccess: ({ data }) => ({ type: constants.FETCH_USER_TERRITORIES_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  searchTerritories(country = null) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'loadCountries',
      args: { searchBy: country },
      getResponse: true,
      onSuccess: ({ data }) => ({ type: constants.SEARCH_TERRITORIES_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SEARCH_TERRITORIES_FAILURE, error: error.message })
    })
  },
  addUserTerritories(userId, privileges) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addUserPrivileges',
      args: { userId, privileges },
      onSuccess: ({ data }) => ({ type: constants.ADD_TERRITORY }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  removeUserTerritories(userId, privileges) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'removeUserPrivileges',
      args: { userId, privileges },
      onSuccess: ({ data }) => ({ type: constants.REMOVE_TERRITORY }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchUserTerritoryPrivilege(roleId, resourceType) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'getRolePrivileges',
        args: { roleId, query: { filterBy: resourceType } },
        getResponse: true,
        onSuccess: ({ data }) => ({ type: constants.SET_USER_TERRITORY_PRIVILEGE, data }),
        onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
      }))
      return resp.data
    }
  },
  clearUserTerritories() {
    return {
      type: constants.CLEAR_USER_TERRITORIES
    }
  },
  resetProfileDataSuccessStatus() {
    return ({ type: constants.RESET_PROFILE_DATA_SUCCESS_STATUS })
  },
  resetProfileDataFailureStatus() {
    return ({ type: constants.RESET_PROFILE_DATA_FAILURE_STATUS })
  },
  fetchTermsContentInviterUser(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getTermsPrivacyInfoInvitedUser',
      args: query,
      onSuccess: ({ data }) => ({ type: constants.UPDATE_TERMS_AND_CONDITION_CONTENT, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchPrivacyContentInviterUser(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getTermsPrivacyInfoInvitedUser',
      args: query,
      onSuccess: ({ data }) => ({ type: constants.UPDATE_PRIVACY_POLICY_CONTENT, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchTermsConditionContent(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getTermsPrivacyInfo',
      args: query,
      onSuccess: ({ data }) => ({ type: constants.UPDATE_TERMS_AND_CONDITION_CONTENT, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  fetchPrivacyPolicyContent(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getTermsPrivacyInfo',
      args: query,
      onSuccess: ({ data }) => ({ type: constants.UPDATE_PRIVACY_POLICY_CONTENT, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  consentCountryCodeUpdateTerms(country) {
    return ({ type: constants.UPDATE_CONSENT_COUNTRY_CODE_TERMS, country })
  },
  consentCountryCodeUpdatePrivacy(country) {
    return ({ type: constants.UPDATE_CONSENT_COUNTRY_CODE_PRIVACY, country })
  },
  fetchPerPageItemList() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchPerPageItemList',
      onSuccess: ({ data }) => ({ type: constants.RECIEVE_OUTPUT_DISPLAY_DATA_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.RECIEVE_OUTPUT_DISPLAY_DATA_FAILURE, status })
    })
  },
  getUserImage(userId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: userId,
      apiFunction: 'getUserImage',
      onSuccess: ({ data }) => ({ type: constants.FETCH_USER_IMAGE_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.MANAGE_PROFILE_FETCH_FAILURE, error })
    })
  },
  addUserImage(userId, updatedImage) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: { userId, updatedImage },
      apiFunction: 'addUserImage',
      onSuccess: ({ data }) => ({ type: constants.ADD_USER_IMAGE_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.MANAGE_PROFILE_FETCH_FAILURE, error })
    })
  },
  deleteUserImage(userId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: userId,
      apiFunction: 'deleteUserImage',
      onSuccess: ({ data }) => ({ type: constants.DELETE_USER_IMAGE_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.MANAGE_PROFILE_FETCH_FAILURE, error })
    })
  },
  resetManageProfileFailure() {
    return ({ type: constants.RESET_MANAGE_PROFILE_FAILURE_STATUS })
  },
  resetFetch(status) {
    return ({ type: constants.RESET_FETCH, status })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  userProfileData: null,
  ownUserProfileData: null,
  btnDisable: true,
  updateRoleSuccess: null,
  selectedRole: { roleName: '', roleId: null },
  selectedCountry: { countryName: '', id: null },
  selectedCountryZone: { timezoneName: '', id: null },
  selectedLanguage: { languageName: '', id: null },
  reinviteSuccess: false,
  reinviteFailure: null,
  updateRoleFailure: null,
  validProfile: true,
  dateTimeFormats: [],
  languages: [],
  consentPrivacyDisableStatus: true,
  defaultTranslationMessage: false,
  userTerritories: [],
  searchedTerritories: [],
  searchedTerritoriesError: null,
  userProfilePrivileges: [],
  profileDataUpdateSuccess: null,
  profileDataUpdateFailure: null,
  termsConditionContent: null,
  privacyPolicyContent: null,
  consentCountryCodeTerms: null,
  consentCountryCodePrivacy: null,
  displayPerPageData: null,
  displayPerPageError: null,
  userProfileImage: null,
  addUserImageSuccess: null,
  deleteUserImageSuccess: null,
  manageProfileFetchErrors: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
        .set('displayPerPageError', null)
        .set('addUserImageSuccess', null)
        .set('deleteUserImageSuccess', null)
        .set('userProfileImage', null)
        .set('manageProfileFetchErrors', null)
    case constants.RECEIVE_USERPROFILE_SUCCESS:
      return action.ownUser ? state
        .set('userProfileData', fromJS(action.data))
        .set('ownUserProfileData', fromJS(action.data))
        .set('validProfile', true)
        .set('fetching', false)
        : state
          .set('userProfileData', fromJS(action.data))
          .set('validProfile', true)
          .set('fetching', false)
    case constants.RECEIVE_USERPROFILE_FAILURE:
      return state
        .set('validProfile', false)
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RESET_USERPROFILE_DATA:
      return state
        .set('userProfileData', action.ownUserProfileData)
    case constants.ENABLE_SAVE_BTN:
      return state
        .set('btnDisable', false)
    case constants.DISABLE_SAVE_BTN:
      return state
        .set('btnDisable', true)
    case constants.UPDATED_USERPROFILE_ROLE:
      return state
        .set('updateRoleSuccess', true)
        .set('fetching', false)
        .setIn(['userProfileData', 'role', 'roleId'], state.getIn(['selectedRole', 'roleId']))
        .setIn(['userProfileData', 'role', 'roleName'], state.getIn(['selectedRole', 'roleName']))
    case constants.RESET_UPDATE_ROLE_STATUS:
      return state
        .set('updateRoleSuccess', null)
    case constants.FAILURE_USERPROFILE_ROLE:
      return state
        .set('updateRoleFailure', { status: action.status })
    case constants.UPDATED_USERPROFILE_DATA:
      return updateUserProfileData(state, action.data)
        .set('profileDataUpdateSuccess', true)
        .set('fetching', false)
    case constants.FAILURE_UPDATE_USERPROFILE_DATA:
      return state
        .set('fetching', false)
        .set('profileDataUpdateFailure', { status: action.status })
    case constants.RESET_UPDATE_ROLE_FAILURE:
      return state
        .set('updateRoleFailure', null)
    case constants.SET_SELECTED_ROLE:
      return state
        .setIn(['selectedRole', 'roleName'], action.role.roleName)
        .setIn(['selectedRole', 'roleId'], action.role.roleId)
    case constants.RESET_SELECTED_ROLE:
      return state
        .setIn(['selectedRole', 'roleName'], '')
        .setIn(['selectedRole', 'roleId'], null)
    case constants.UPDATE_USER_PROFILE_DATA:
      return state
        .setIn(['userProfileData', 'firstName'], action.user.firstName)
        .setIn(['userProfileData', 'lastName'], action.user.lastName)
        .setIn(['userProfileData', 'phone'], action.user.phone)
        .setIn(['userProfileData', 'consentStatus'], action.user.consentStatus)
        .setIn(['userProfileData', 'privacyPolicyStatus'], action.user.privacyPolicyStatus)
        .setIn(['userProfileData', 'language'], action.user.language)
        .setIn(['userProfileData', 'country', 'countryCode'], action.user.countryCode)
        .setIn(['userProfileData', 'country', 'id'], action.user.country)
        .setIn(['userProfileData', 'timeZoneId'], action.user.timeZoneId)
        .setIn(['userProfileData', 'isdCode'], action.user.isdCode)
        .setIn(['userProfileData', 'userProfileSettings'], action.user.userProfileSettings)
        .set('activatedUser', action.user.userName)
    case constants.SET_SELECTED_COUNTRY:
      return state
        .setIn(['selectedCountry', 'countryName'], action.country.countryName)
        .setIn(['selectedCountry', 'id'], action.country.id)
    case constants.SET_SELECTED_COUNTRY_ZONE:
      return state
        .setIn(['selectedCountryZone', 'timezoneName'], action.countryZone.timezoneName)
        .setIn(['selectedCountryZone', 'id'], action.countryZone.id)
    case constants.SET_SELECTED_LANGUAGE:
      return state
        .setIn(['selectedLanguage', 'languageName'], action.language.languageName)
        .setIn(['selectedLanguage', 'id'], action.language.id)
    case constants.RE_INVITE_USER_SUCCESS:
      return state
        .set('reinviteSuccess', true)
        .set('fetching', false)
        .set('fetchError', null)
    case constants.RESET_REINVITE_SUCCESS:
      return state
        .set('reinviteSuccess', false)
    case constants.RE_INVITE_USER_FAILURE:
      return state
        .set('reinviteFailure', { status: action.status })
        .set('fetching', false)
        .set('fetchError', null)
    case constants.RESET_REINVITE_FAILURE:
      return state
        .set('reinviteFailure', null)
    case constants.LOAD_DATE_TIME_FORMAT_SUCCESS:
      return state
        .set('dateTimeFormats', fromJS(action.data))
        .set('fetching', false)
    case constants.LOAD_LANGUAGES_SUCCESS:
      return state
        .set('languages', fromJS(action.data))
    case constants.UNSUBSCRIBE_USER_FAILURE:
      return state
        .set('fetching', false)
    case constants.SET_CONSENT_PRIVACY_DISABLE_STATUS:
      return state
        .set('consentPrivacyDisableStatus', action.status)
    case constants.SET_DEFAULT_TRANSLATION_SUCCESS_MESSAGE:
      return state
        .set('defaultTranslationMessage', action.status)
    case constants.FETCH_USER_TERRITORIES_SUCCESS:
      return state
        .set('fetching', false)
        .set('userTerritories', action.data)
    case constants.CLEAR_USER_TERRITORIES:
      return state
        .set('userTerritories', [])
    case constants.ADD_TERRITORY:
    case constants.REMOVE_TERRITORY:
    case constants.SEARCH_TERRITORIES_SUCCESS:
      return state
        .set('fetching', false)
        .set('searchedTerritories', fromJS(action.data))
    case constants.SEARCH_TERRITORIES_FAILURE:
      return state
        .set('fetching', false)
        .set('searchedTerritoriesError', action.error)
    case constants.SET_USER_TERRITORY_PRIVILEGE:
      return state
        .set('fetching', false)
        .set('userProfilePrivileges', fromJS(action.data))
    case constants.RESET_PROFILE_DATA_SUCCESS_STATUS:
      return state
        .set('profileDataUpdateSuccess', null)
    case constants.RESET_PROFILE_DATA_FAILURE_STATUS:
      return state
        .set('profileDataUpdateFailure', null)
    case constants.UPDATE_TERMS_AND_CONDITION_CONTENT:
      return state
        .set('fetching', false)
        .set('termsConditionContent', action.data)
    case constants.UPDATE_PRIVACY_POLICY_CONTENT:
      return state
        .set('fetching', false)
        .set('privacyPolicyContent', action.data)
    case constants.UPDATE_CONSENT_COUNTRY_CODE_TERMS:
      return state
        .set('consentCountryCodeTerms', action.country)
    case constants.UPDATE_CONSENT_COUNTRY_CODE_PRIVACY:
      return state
        .set('consentCountryCodePrivacy', action.country)
    case constants.RECIEVE_OUTPUT_DISPLAY_DATA_SUCCESS:
      return state
        .set('displayPerPageData', action.data)
        .set('fetching', false)
    case constants.RECIEVE_OUTPUT_DISPLAY_DATA_FAILURE:
      return state
        .set('displayPerPageError', action.status)
        .set('fetching', false)
    case constants.FETCH_USER_IMAGE_SUCCESS:
      return state
        .set('userProfileImage', fromJS(action.data))
        .set('fetching', false)
        .set('manageProfileFetchErrors', null)
    case constants.ADD_USER_IMAGE_SUCCESS:
      return state
        .set('addUserImageSuccess', true)
        .set('fetching', false)
    case constants.DELETE_USER_IMAGE_SUCCESS:
      return state
        .set('deleteUserImageSuccess', true)
        .set('userProfileImage', null)
        .setIn(['userProfileData', 'userImage'], null)
        .set('fetching', false)
    case constants.MANAGE_PROFILE_FETCH_FAILURE:
      return state
        .set('manageProfileFetchErrors', action.error)
        .set('fetching', false)
        .set('userProfileImage', null)
    case constants.RESET_MANAGE_PROFILE_FAILURE_STATUS:
      return state
        .set('manageProfileFetchErrors', null)
    case constants.RESET_FETCH:
      return state
        .set('fetching', action.status)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.userProfile

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  getUserProfile: createSelector(getState, (state) => {
    return state.get('userProfileData')
  }),
  getUserProfileId: createSelector(getState, (state) =>
    state.getIn(['userProfileData', 'userId'])
  ),
  saveBtnStatus: createSelector(getState, (state) =>
    state.get('btnDisable')
  ),
  getUpdateRoleSuccess: createSelector(getState, (state) =>
    state.get('updateRoleSuccess')
  ),
  getSelectedRoleName: createSelector(getState, (state) =>
    state.getIn(['selectedRole', 'roleName'])
  ),
  getSelectedRoleId: createSelector(getState, (state) =>
    state.getIn(['selectedRole', 'roleId'])
  ),
  getSelectedCountryName: createSelector(getState, (state) =>
    state.getIn(['selectedCountry', 'countryName'])
  ),
  getSelectedCountryId: createSelector(getState, (state) =>
    state.getIn(['selectedCountry', 'id'])
  ),
  getSelectedCountryZoneId: createSelector(getState, (state) =>
    state.getIn(['selectedCountryZone', 'id'])
  ),
  getSelectedLanguageName: createSelector(getState, (state) =>
    state.getIn(['selectedLanguage', 'languageName'])
  ),
  getReinviteSuccess: createSelector(getState, (state) =>
    state.get('reinviteSuccess')
  ),
  getReinviteFailure: createSelector(getState, (state) =>
    state.get('reinviteFailure')
  ),
  getUpdateRoleFailure: createSelector(getState, (state) =>
    state.get('updateRoleFailure')
  ),
  getProfileValid: createSelector(getState, (state) =>
    state.get('validProfile')
  ),
  dateTimeFormats: createSelector(getState, (state) =>
    state.get('dateTimeFormats')
  ),
  getAllLanguages: createSelector(getState, (state) =>
    state.get('languages')
  ),
  consentPrivacyDisableStatus: createSelector(getState, (state) =>
    state.get('consentPrivacyDisableStatus')
  ),
  defaultTranslationMessage: createSelector(getState, (state) =>
    state.get('defaultTranslationMessage')
  ),
  userTerritories: createSelector(getState, (state) =>
    state.get('userTerritories')
  ),
  searchedTerritories: createSelector(getState, (state) =>
    state.get('searchedTerritories')
  ),
  searchedTerritoriesError: createSelector(getState, (state) =>
    state.get('searchedTerritoriesError')
  ),
  userProfilePrivileges: createSelector(getState, (state) =>
    state.get('userProfilePrivileges')
  ),
  profileDataUpdateSuccess: createSelector(getState, (state) =>
    state.get('profileDataUpdateSuccess')
  ),
  profileDataUpdateFailure: createSelector(getState, (state) =>
    state.get('profileDataUpdateFailure')
  ),
  termsConditionContent: createSelector(getState, (state) =>
    state.get('termsConditionContent')
  ),
  privacyPolicyContent: createSelector(getState, (state) =>
    state.get('privacyPolicyContent')
  ),
  consentCountryCodeTerms: createSelector(getState, (state) =>
    state.get('consentCountryCodeTerms')
  ),
  consentCountryCodePrivacy: createSelector(getState, (state) =>
    state.get('consentCountryCodePrivacy')
  ),
  displayPerPageData: createSelector(getState, (state) =>
    state.get('displayPerPageData')
  ),
  displayPerPageError: createSelector(getState, (state) =>
    state.get('displayPerPageError')
  ),
  ownUserProfileData: createSelector(getState, (state) =>
    state.get('ownUserProfileData')
  ),
  activatedUser: createSelector(getState, (state) =>
    state.get('activatedUser')
  ),
  userProfileImage: createSelector(getState, (state) =>
    state.get('userProfileImage')
  ),
  addUserImageSuccess: createSelector(getState, (state) =>
    state.get('addUserImageSuccess')
  ),
  deleteUserImageSuccess: createSelector(getState, (state) =>
    state.get('deleteUserImageSuccess')
  ),
  manageProfileFetchErrors: createSelector(getState, (state) =>
    state.get('manageProfileFetchErrors')
  )
}

// helper function
const updateUserProfileData = (state, data) => {
  const { updatedFields, country, timeZone, role } = data
  let newState = state

  if (updatedFields.countryId) {
    newState = newState.setIn(
      ['userProfileData', 'country'],
      fromJS(country)
    )
  }

  if (updatedFields.timeZoneId) {
    newState = newState.setIn(
      ['userProfileData', 'timeZoneId'],
      timeZone.id
    )
  }

  if (updatedFields.roleId) {
    newState = newState.setIn(
      ['userProfileData', 'role'],
      fromJS(role)
    )
  }

  if (updatedFields.phoneNumber) {
    newState = newState.setIn(
      ['userProfileData', 'phone'],
      updatedFields.phoneNumber
    )
  }

  if (updatedFields.isdCode) {
    newState = newState.setIn(
      ['userProfileData', 'isdCode'],
      updatedFields.isdCode
    )
  }

  return newState
}
