import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AccordionSection from './AccordionSection'
import { isArray } from 'utils'

class Accordion extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string
  }

  constructor(props) {
    super(props)

    const openSections = {}

    this.state = { openSections }
  }

  onClick = label => {
    const {
      state: { openSections },
    } = this

    const isOpen = !!openSections[label]

    this.setState({
      openSections: {
        [label]: !isOpen
      }
    })
  }

  render() {
    const {
      onClick, props: { children, className }, state: { openSections }} = this
    return (
      <div className={className}>
  {isArray(children) && children.map((child, index) => (
    <AccordionSection
      key={index}
      isOpen={!!openSections[child.props.label]}
      label={child.props.label}
      count={child.props.count}
      onClick={onClick}
    >
      {child.props.children}
    </AccordionSection>
  ))}
  </div>
  )
  }
}

export default Accordion
