import PropTypes from 'prop-types'
import { GLOBAL_CSS_PREFIX } from './consts'
import { css } from 'styled-components'
const getMediaQuery = mediaQueryKey =>
  Object.keys(SCREEN_SIZES).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (${mediaQueryKey}: ${SCREEN_SIZES[label]}px) {
        ${css(...args)};
      }
    `

    return acc
  }, {})

  
export const SCREEN_SIZES = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

export const media = {
  min: getMediaQuery('min-width'),
  max: getMediaQuery('max-width'),
  customMin: breakpoint => (...args) =>
    css`
      @media (min-width: ${breakpoint}px) {
        ${css(...args)};
      }
    `,
  customMax: breakpoint => (...args) =>
    css`
      @media (max-width: ${breakpoint}px) {
        ${css(...args)};
      }
    `,
}
export const optionPropType = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export const getClassName = Component =>
`${GLOBAL_CSS_PREFIX}${Component.displayName || Component.name}`

export const isTouchDevice = () => {
  if (typeof document === 'undefined') return
  return 'ontouchstart' in document.documentElement
}

export const nonTouchDevicesHoverStyles = styleString =>
  !isTouchDevice() &&
  css`
    &:hover {
      ${styleString}
    }
  `
  export const cursorValue = ({ disabled, defaultValue = 'default' }) => css`
  cursor: ${disabled ? 'not-allowed' : defaultValue}`

  
export const getNumberOption = num => ({
  name: num.toString(),
  id: num.toString(),
})

export const times = n => Object.keys([...Array(n)]).map(v => parseInt(v))