import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  selectors as PageSelectors
} from 'store/modules/Pagination'
import {
  actions as fileManagementActions
} from 'store/modules/FileManagement'

// Constants
export const constants = {
  FETCH: 'DEVICE_INTERACTION/FETCH',
  FETCH_FAILURE: 'DEVICE_INTERACTION/FETCH_FAILURE',
  COMMAND_ACTIVATE_SUCCESS: 'DEVICE_INTERACTION/COMMAND_ACTIVATE_SUCCESS',
  COMMAND_ACTIVATE_FAILURE: 'DEVICE_INTERACTION/COMMAND_ACTIVATE_FAILURE',
  FETCH_OPENTRON: 'DEVICE_INTERACTION/FETCH_OPENTRON',
  RUN_OPENTRON_SUCCESS: 'DEVICE_INTERACTION/RUN_OPENTRON_SUCCESS',
  RUN_OPENTRON_FAILURE: 'DEVICE_INTERACTION/RUN_OPENTRON_FAILURE',
  VALIDATE_OPENTRON_SUCCESS: 'DEVICE_INTERACTION/VALIDATE_OPENTRON_SUCCESS',
  VALIDATE_OPENTRON_FAILURE: 'DEVICE_INTERACTION/VALIDATE_OPENTRON_FAILURE',
  VIEW_JOB_IDS: 'DEVICE_INTERACTION/VIEW_JOB_IDS',
  NAVIGATE_TO_PROTOCOLS: 'DEVICE_INTERACTION/NAVIGATE_TO_PROTOCOLS',
  SHOW_JOB_LOG_CARD: 'DEVICE_INTERACTION/SHOW_JOB_LOG_CARD',
  RECEIVE_PROTOCOLS_LIST_SUCCESS: 'DEVICE_INTERACTION/RECEIVE_PROTOCOLS_LIST_SUCCESS',
  RECEIVE_JOBS_LIST_SUCCESS: 'DEVICE_INTERACTION/RECEIVE_JOBS_LIST_SUCCESS',
  CHECK_DEVICE_JOB_STATUS_SUCCESS: 'DEVICE_INTERACTION/CHECK_DEVICE_JOB_STATUS_SUCCESS',
  RECEIVE_NOTIFICATIONS_ACTIONS: 'DEVICE_INTERACTION/RECEIVE_NOTIFICATIONS_ACTIONS',
  CREATE_NOTIFICATION: 'DEVICE_INTERACTION/CREATE_NOTIFICATION',
  RECEIVE_NOTIFICATIONS_CATEGORIES: 'DEVICE_INTERACTION/RECEIVE_NOTIFICATIONS_CATEGORIES',
  FETCH_NOTIFICATIONS: 'DEVICE_INTERACTION/FETCH_NOTIFICATIONS',
  RESET_CREATE_NOTIFICATION_SUCCESS: 'DEVICE_INTERACTION/RESET_CREATE_NOTIFICATION_SUCCESS',
  SORT: 'DEVICE_INTERACTION/SORT',
  RESET: 'DEVICE_INTERACTION/RESET',
  RECEIVE_NOTIFICATIONS_PARAMS: 'DEVICE_INTERACTION/RECEIVE_NOTIFICATIONS_PARAMS',
  NOTIFICATION_VALUE: 'DEVICE_INTERACTION/NOTIFICATION_VALUE',
  RESET_NOTIFICATION_VALUES: 'DEVICE_INTERACTION/RESET_NOTIFICATION_VALUES',
  SHOW_FILE_SECTIONS: 'DEVICE_INTERACTION/SHOW_FILE_SECTIONS',
  SHOW_NOTIFICATIONS_SECTIONS: 'DEVICE_INTERACTION/SHOW_NOTIFICATIONS_SECTIONS',
  FETCH_FILES_LIST: 'DEVICE_INTERACTION/FETCH_FILES_LIST',
  FETCH_DOCUMENT_TYPE_FILES_LIST_FAILURE: 'DEVICE_INTERACTION/FETCH_DOCUMENT_TYPE_FILES_LIST_FAILURE',
  RECEIVE_DOCUMENT_TYPE_FILES_LIST_SUCCESS: 'DEVICE_INTERACTION/RECEIVE_DOCUMENT_TYPE_FILES_LIST_SUCCESS',
  RECEIVE_PARTNER_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/RECEIVE_PARTNER_PERIPHERAL_INFORMATION',
  SORT_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/SORT_PERIPHERAL_INFORMATION',
  DELETE_PERIPHERAL_DEVICE_OPERATION_FAILURE: 'DEVICE_DETAILS/DELETE_PERIPHERAL_DEVICE_OPERATION_FAILURE',
  DELETED_PARTNER_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/DELETED_PARTNER_PERIPHERAL_INFORMATION',
  FETCH_PERIPHERAL_DEVICE_FAILURE: 'DEVICE_DETAILS/FETCH_PERIPHERAL_DEVICE_FAILURE'
}

const pageLimit = 10
// Action Creators
export const actions = {
  fetchPartnerPeripheralInformation(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchPartnerPeripheralInformation',
      args: (getState) => (actions.getPeripheralInformationFetchQuery(getState, deviceId)),
      onSuccess: ({data}) => ({
        type: constants.RECEIVE_PARTNER_PERIPHERAL_INFORMATION,
        data
      }),
      onFailure: (error) => ({
        type: constants.FETCH_PERIPHERAL_DEVICE_FAILURE,
        error: error
      })
    })
  },
  sortPeripheralInformation(sortColumn) {
    return {type: constants.SORT_PERIPHERAL_INFORMATION, sortColumn}
  },
  addPartnerPeripheral (deviceId, peripheralDeviceInfo) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addPartnerPeripheralInformation',
      args: {deviceId, peripheralDeviceInfo},
      throwError: true,
      onSuccess: () => ({ type: constants.ADDED_PARTNER_PERIPHERAL_INFORMATION }),
      onFailure: ({status}) => ({
        type: constants.PERIPHERAL_DEVICE_OPERATION_FAILURE,
        error: status
      })
    })
  },
  updatePartnerPeripheral (peripheralDeviceId, peripheralDeviceInfo) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updatePartnerPeripheralInformation',
      args: {peripheralDeviceId, peripheralDeviceInfo},
      throwError: true,
      onSuccess: () => ({ type: constants.UPDATED_PARTNER_PERIPHERAL_INFORMATION }),
      onFailure: ({status}) => ({
        type: constants.PERIPHERAL_DEVICE_OPERATION_FAILURE,
        error: status
      })
    })
  },
  deletePartnerPeripheral (peripheralDeviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deletePartnerPeripheralInformation',
      args: {peripheralDeviceId},
      throwError: true,
      onSuccess: () => ({ type: constants.DELETED_PARTNER_PERIPHERAL_INFORMATION }),
      onFailure: (error) => ({
        type: constants.DELETE_PERIPHERAL_DEVICE_OPERATION_FAILURE,
        error: error
      })
    })
  },
  getPeripheralInformationFetchQuery(getState, deviceId) {
    const sortBy = selectors.peripheralInformationSortColumn(getState())
    const sortDirection = selectors.peripheralInformationSortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const query = {
      sortBy,
      orderBy
    }
    return {query, deviceId}
  },
  loadDocumentTypeFilesList (deviceId, deviceTypeId, documentTypeName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILES_LIST }),
      apiFunction: 'fetchFiles',
      args: (getState) => actions.getDocumentFetchQuery(getState, deviceId, deviceTypeId, documentTypeName),
      onSuccess: ({ data, headers }) => ({ type: constants.RECEIVE_DOCUMENT_TYPE_FILES_LIST_SUCCESS, data, headers, documentTypeName }),
      onFailure: (error) => ({ type: constants.FETCH_DOCUMENT_TYPE_FILES_LIST_FAILURE, error, documentTypeName })
    })
  },
  getDocumentFetchQuery(getState, deviceId, deviceTypeId, filterName) {
    const sortBy = selectors.sortColumn(getState()) || 'createdDate'
    const sortDirection = selectors.sortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const query = {
      sortBy: sortBy,
      orderBy: orderBy,
      filterBy: `documentTypeName.${filterName}`
    }
    return ({deviceId, deviceTypeId, query})
  },
  getProtocolsFetchQuery(getState, deviceId, deviceTypeId, filterName) {
    const sortBy = selectors.sortColumn(getState()) || 'createdDate'
    const sortDirection = selectors.sortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const page = PageSelectors.pagination(getState()).getIn([filterName, 'currentPage']) || 1
    const query = {
      sortBy: sortBy,
      orderBy: orderBy,
      filterBy: `documentTypeName.${filterName}`,
      page: page,
      pageLimit
    }
    return ({deviceId, deviceTypeId, query})
  },
  loadProtocolsList (deviceId, deviceTypeId, filterName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_OPENTRON }),
      apiFunction: 'fetchFiles',
      args: (getState) => actions.getProtocolsFetchQuery(getState, deviceId, deviceTypeId, filterName),
      onSuccess: ({ data, headers }) => ({ type: constants.RECEIVE_PROTOCOLS_LIST_SUCCESS, protocolsList: data, headers }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error })
    })
  },
  getJobsListFetchQuery(getState, deviceId, documentId) {
    const sortBy = selectors.sortColumn(getState())
    const sortDirection = selectors.sortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const page = PageSelectors.pagination(getState()).getIn(['protocolJobs', 'currentPage']) || 1
    const query = {
      sortBy: sortBy,
      orderBy: orderBy,
      page: page,
      pageLimit,
      uuid: deviceId,
      documentId
    }
    return query
  },
  loadJobsList (deviceId, documentId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_OPENTRON }),
      apiFunction: 'fetchJobsList',
      args: (getState) => actions.getJobsListFetchQuery(getState, deviceId, documentId),
      onSuccess: ({ data, headers }) => ({ type: constants.RECEIVE_JOBS_LIST_SUCCESS, jobsListData: data, headers }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  runOpentron (protocolData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_OPENTRON }),
      apiFunction: 'runOpentron',
      args: protocolData,
      onSuccess: ({ data }) => ({ type: constants.RUN_OPENTRON_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RUN_OPENTRON_FAILURE, error: error.message }),
      throwError: true
    })
  },
  validateOpentron (protocolData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_OPENTRON }),
      apiFunction: 'validateOpentron',
      args: protocolData,
      onSuccess: ({ data }) => {
        return { type: constants.VALIDATE_OPENTRON_SUCCESS, data }
      },
      onFailure: (error) => ({ type: constants.VALIDATE_OPENTRON_FAILURE, error: error.message }),
      throwError: true
    })
  },
  viewJobIdOpentron (documentName, documentId) {
    return {
      type: constants.VIEW_JOB_IDS,
      documentName,
      documentId
    }
  },
  navigateToProtocols () {
    return {
      type: constants.NAVIGATE_TO_PROTOCOLS
    }
  },
  showJobLogCard (jobId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_OPENTRON }),
      apiFunction: 'showJobLogs',
      args: jobId,
      onSuccess: ({ data }) => {
        return { type: constants.SHOW_JOB_LOG_CARD, data, jobId }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true
    })
  },
  checkDeviceJobStatus (deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_OPENTRON }),
      apiFunction: 'checkDeviceJobStatus',
      args: deviceId,
      onSuccess: ({ data }) => {
        return { type: constants.CHECK_DEVICE_JOB_STATUS_SUCCESS, data }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true
    })
  },
  fetchNotificationActions(categoryId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_NOTIFICATIONS }),
      apiFunction: 'fetchNotificationActions',
      args: categoryId,
      onSuccess: ({ data }) => {
        return { type: constants.RECEIVE_NOTIFICATIONS_ACTIONS, data }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true
    })
  },
  fetchNotificationParams(categoryId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_NOTIFICATIONS }),
      apiFunction: 'fetchNotificationParams',
      args: categoryId,
      onSuccess: ({ data }) => {
        return { type: constants.RECEIVE_NOTIFICATIONS_PARAMS, data }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true
    })
  },
  createNotification(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_NOTIFICATIONS }),
      apiFunction: 'createNotification',
      args: query,
      onSuccess: ({ data }) => {
        return { type: constants.CREATE_NOTIFICATION, status: 'success', data }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true
    })
  },
  fetchNotificationCategories(deviceTypeId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_NOTIFICATIONS }),
      apiFunction: 'fetchNotificationCategories',
      args: {deviceTypeId},
      onSuccess: ({ data }) => {
        return { type: constants.RECEIVE_NOTIFICATIONS_CATEGORIES, data }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true
    })
  },
  resetcreateNotificationStatus() {
    return {type: constants.RESET_CREATE_NOTIFICATION_SUCCESS}
  },
  applySort (sortColumn) {
    return { type: constants.SORT, sortColumn }
  },
  reset () {
    return { type: constants.RESET }
  },
  opentronRunAction (deviceId, args) {
    return async(dispatch, getState) => {
      await dispatch(actions.checkDeviceJobStatus(deviceId))
      const runJobActionStatus = await selectors.deviceJobStatus(getState()).toJS()
      if (runJobActionStatus && runJobActionStatus.status === false) {
        await dispatch(actions.runOpentron(args))
      }
    }
  },
  fetchNotificationValue () {
    return { type: constants.NOTIFICATION_VALUE }
  },
  resetNotificationValues () {
    return { type: constants.RESET_NOTIFICATION_VALUES }
  },
  fetchFilesDocumentTypes (status) {
    return async(dispatch, getState) => {
      await dispatch(fileManagementActions.fetchDeviceDocumentTypes())
      dispatch(actions.showFileSections(status))
    }
  },
  showFileSections (status) {
    return { type: constants.SHOW_FILE_SECTIONS, status }
  },
  showNotificationSection (status) {
    return { type: constants.SHOW_NOTIFICATIONS_SECTIONS, status }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  protocolsList: [],
  showJobId: false,
  showJobCard: false,
  fetchingOpentron: false,
  jobsListData: [],
  logs: null,
  deviceJobStatus: null,
  protocolExecutionSuccessStatus: null,
  notificationCategories: [],
  notificationActions: [],
  notificationParams: [],
  createNotificationSuccess: null,
  fetchingNotifications: false,
  totalPages: 0,
  sortColumn: '',
  sortDirection: false,
  totalPagesJobsList: 0,
  showNotificationAction: false,
  showNotificationParam: false,
  showNotificationValue: false,
  fileSectionFlag: false,
  notificationSectionFlag: false,
  fetchingFilesList: false,
  filesListData: {},
  fetchFilesListError: {},
  partnerPeripheralInformation: [],
  deleteErrorMsg: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('protocolExecutionSuccessStatus', null)
        .set('deviceJobStatus', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', fromJS(action.error))
        .set('fetching', false)
        .set('fetchingOpentron', false)
        .set('fetchingNotifications', false)
    case constants.FETCH_OPENTRON:
      return state
        .set('fetchingOpentron', true)
        .set('protocolExecutionSuccessStatus', null)
        .set('deviceJobStatus', null)
    case constants.RUN_OPENTRON_SUCCESS:
      return state
        .set('protocolExecutionSuccessStatus', fromJS(action.data.message))
        .set('fetchingOpentron', false)
    case constants.RUN_OPENTRON_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetchingOpentron', false)
    case constants.VALIDATE_OPENTRON_SUCCESS:
      return state
        .set('protocolExecutionSuccessStatus', fromJS(action.data.message))
        .set('fetchingOpentron', false)
    case constants.VALIDATE_OPENTRON_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetchingOpentron', false)
    case constants.VIEW_JOB_IDS:
      return state
        .set('showJobId', true)
        .set('protocolName', action.documentName)
        .set('documentId', action.documentId)
    case constants.NAVIGATE_TO_PROTOCOLS:
      return state
        .set('showJobId', false)
        .set('showJobCard', false)
    case constants.SHOW_JOB_LOG_CARD:
      return state
        .set('showJobCard', true)
        .set('showJobId', true)
        .set('batchId', action.jobId)
        .set('logs', fromJS(action.data.log))
    case constants.RECEIVE_PROTOCOLS_LIST_SUCCESS:
      return state
        .set('protocolsList', fromJS(action.protocolsList))
        .set('fetchingOpentron', false)
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('totalPages', parseInt(fromJS(action.headers.get('x-pagination-count'))))
    case constants.FETCH_FILES_LIST:
      return state
        .set('fetchingFilesList', true)
    case constants.RECEIVE_DOCUMENT_TYPE_FILES_LIST_SUCCESS:
      return state
        .setIn(['filesListData', action.documentTypeName, 'fileData'], action.data)
        .setIn(['filesListData', action.documentTypeName, 'totalPages'], parseInt(action.headers.get('x-pagination-count')))
        .set('fetchingOpentron', false)
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('fetchingFilesList', false)
    case constants.FETCH_DOCUMENT_TYPE_FILES_LIST_FAILURE:
      return state
        .setIn(['fetchFilesListError', action.documentTypeName], action.error)
        .set('fetching', false)
        .set('fetchingOpentron', false)
        .set('fetchingNotifications', false)
        .set('fetchingFilesList', false)
    case constants.RECEIVE_JOBS_LIST_SUCCESS:
      return state
        .set('jobsListData', fromJS(action.jobsListData))
        .set('fetchingOpentron', false)
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('totalPagesJobsList', parseInt(fromJS(action.headers.get('x-pagination-count'))))
    case constants.CHECK_DEVICE_JOB_STATUS_SUCCESS:
      return state
        .set('deviceJobStatus', fromJS(action.data))
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('fetchingOpentron', false)
    case constants.FETCH_NOTIFICATIONS:
      return state
        .set('fetchingNotifications', true)
        .set('fetchingOpentron', false)
        .set('fetching', false)
        .set('protocolExecutionSuccessStatus', null)
        .set('deviceJobStatus', null)
    case constants.RECEIVE_NOTIFICATIONS_ACTIONS:
      return state
        .set('notificationActions', fromJS(action.data))
        .set('fetching', false)
        .set('fetchingOpentron', false)
        .set('fetchingNotifications', false)
        .set('showNotificationAction', true)
        .set('showNotificationValue', false)
    case constants.RECEIVE_NOTIFICATIONS_PARAMS:
      return state
        .set('notificationParams', fromJS(action.data))
        .set('fetching', false)
        .set('fetchingOpentron', false)
        .set('fetchingNotifications', false)
        .set('showNotificationParam', true)
    case constants.CREATE_NOTIFICATION:
      return state
        .set('createNotificationSuccess', action.status)
        .set('fetching', false)
        .set('fetchingOpentron', false)
        .set('fetchingNotifications', false)
    case constants.RECEIVE_NOTIFICATIONS_CATEGORIES:
      return state
        .set('notificationCategories', fromJS(action.data))
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('fetchingOpentron', false)
    case constants.RESET_CREATE_NOTIFICATION_SUCCESS:
      return state
        .set('createNotificationSuccess', null)
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('fetchingOpentron', false)
        .set('deleteErrorMsg', null)
        .set('deleteSucessMsg', false)
    case constants.SORT:
      return state
        .set('sortColumn', fromJS(action.sortColumn))
        .set('sortDirection', !state.get('sortDirection'))
    case constants.RESET:
      return state
        .set('sortDirection', initialState.get('sortDirection'))
        .set('sortColumn', initialState.get('sortColumn'))
    case constants.NOTIFICATION_VALUE:
      return state
        .set('showNotificationValue', true)
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('fetchingOpentron', false)
    case constants.RESET_NOTIFICATION_VALUES:
      return state
        .set('showNotificationAction', false)
        .set('showNotificationValue', false)
        .set('showNotificationParam', false)
        .set('fetching', false)
        .set('fetchingNotifications', false)
        .set('fetchingOpentron', false)
        .set('fileSectionFlag', false)
    case constants.SHOW_FILE_SECTIONS:
      return state
        .set('fileSectionFlag', action.status)
        .set('fetchingFilesList', false)
    case constants.SHOW_NOTIFICATIONS_SECTIONS:
      return state
        .set('notificationSectionFlag', action.status)
        .set('fetchingFilesList', false)
    case constants.RECEIVE_PARTNER_PERIPHERAL_INFORMATION:
      return state
        .set('partnerPeripheralInformation', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_PERIPHERAL_DEVICE_FAILURE:
      return state
        .set('fetchPeripheralDeviceError', fromJS(action.error))
        .set('partnerPeripheralInformation', fromJS([]))
        .set('fetching', false)
    case constants.PERIPHERAL_DEVICE_OPERATION_FAILURE:
      return state
        .set('fetching', false)
    case constants.DELETED_PARTNER_PERIPHERAL_INFORMATION:
      return state
        .set('deleteSucessMsg', true)
    case constants.DELETE_PERIPHERAL_DEVICE_OPERATION_FAILURE:
      return state
        .set('deleteErrorMsg', action.error)
    case constants.SORT_PERIPHERAL_INFORMATION:
      return state
        .set('peripheralInformationSortColumn', action.sortColumn)
        .set('peripheralInformationSortDirection', !state.get('peripheralInformationSortDirection'))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceInteraction

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  peripheralInformationSortColumn: createSelector(getState, (state) => {
    return state.get('peripheralInformationSortColumn')
  }),
  peripheralInformationSortDirection: createSelector(getState, (state) => {
    return state.get('peripheralInformationSortDirection')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  protocolExecutionSuccessStatus: createSelector(getState, (state) => {
    return state.get('protocolExecutionSuccessStatus')
  }),
  protocolsList: createSelector(getState, (state) => {
    return state.get('protocolsList')
  }),
  showJobId: createSelector(getState, (state) => {
    return state.get('showJobId')
  }),
  protocolName: createSelector(getState, (state) => {
    return state.get('protocolName')
  }),
  showJobCard: createSelector(getState, (state) => {
    return state.get('showJobCard')
  }),
  batchId: createSelector(getState, (state) => {
    return state.get('batchId')
  }),
  jobsListData: createSelector(getState, (state) => {
    return state.get('jobsListData')
  }),
  logs: createSelector(getState, (state) => {
    return state.get('logs')
  }),
  deviceJobStatus: createSelector(getState, (state) => {
    return state.get('deviceJobStatus')
  }),
  notificationActions: createSelector(getState, (state) => {
    return state.get('notificationActions')
  }),
  notificationCategories: createSelector(getState, (state) => {
    return state.get('notificationCategories')
  }),
  createNotificationSuccess: createSelector(getState, (state) => {
    return state.get('createNotificationSuccess')
  }),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages')
  ),
  sortColumn: createSelector(getState, (state) =>
    state.get('sortColumn')
  ),
  sortDirection: createSelector(getState, (state) =>
    state.get('sortDirection')
  ),
  totalPagesJobsList: createSelector(getState, (state) => {
    return state.get('totalPagesJobsList')
  }),
  documentId: createSelector(getState, (state) => {
    return state.get('documentId')
  }),
  fetchingOpentron: createSelector(getState, (state) => {
    return state.get('fetchingOpentron')
  }),
  notificationParams: createSelector(getState, (state) => {
    return state.get('notificationParams')
  }),
  showNotificationParam: createSelector(getState, (state) => {
    return state.get('showNotificationParam')
  }),
  showNotificationValue: createSelector(getState, (state) => {
    return state.get('showNotificationValue')
  }),
  showNotificationAction: createSelector(getState, (state) => {
    return state.get('showNotificationAction')
  }),
  fetchFilesListError: createSelector(getState, (state) =>
    state.get('fetchFilesListError')
  ),
  fileSectionFlag: createSelector(getState, (state) =>
    state.get('fileSectionFlag')
  ),
  notificationSectionFlag: createSelector(getState, (state) =>
    state.get('notificationSectionFlag')
  ),
  fetchingFilesList: createSelector(getState, (state) =>
    state.get('fetchingFilesList')
  ),
  filesListData: createSelector(getState, (state) => {
    return state.get('filesListData')
  }),
  fetchingNotifications: createSelector(getState, (state) => {
    return state.get('fetchingNotifications')
  }),
  partnerPeripheralInformation: createSelector(getState, (state) => {
    return state.get('partnerPeripheralInformation')
  }),
  deleteErrorMsg: createSelector(getState, (state) => {
    return state.get('deleteErrorMsg')
  }),
  deleteSucessMsg: createSelector(getState, (state) => {
    return state.get('deleteSucessMsg')
  }),
}
