import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'CONTACT_HOTLINE_DASHBOARD/FETCH',
  FETCH_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/FETCH_FAILURE',
  RECEIVE_FILTER_CATEGORIES: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_FILTER_CATEGORIES',
  CONTACTHOTLINE_FILTERS_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/CONTACTHOTLINE_FILTERS_SUCCESS',
  CONTACTHOTLINE_FILTERS_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/CONTACTHOTLINE_FILTERS_FAILURE',
  ADD_CONTACT_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/ADD_CONTACT_SUCCESS',
  ADD_CONTACT_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/ADD_CONTACT_FAILURE',
  EDIT_CONTACT_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/EDIT_CONTACT_SUCCESS',
  EDIT_CONTACT_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/EDIT_CONTACT_FAILURE',
  RECEIVE_REGION_DETAILS_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_REGION_DETAILS_SUCCESS',
  RECEIVE_REGION_DETAILS_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_REGION_DETAILS_FAILURE',
  RESET_CONTACT_HOTLINE_SUCCESS_STATUS: 'CONTACT_HOTLINE_DASHBOARD/RESET_CONTACT_HOTLINE_SUCCESS_STATUS',
  RECEIVE_CONTACT_HOTLINE_COUNTRY_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_CONTACT_HOTLINE_COUNTRY_SUCCESS',
  RECEIVE_CONTACT_HOTLINE_COUNTRY_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_CONTACT_HOTLINE_COUNTRY_FAILURE',
  SET_DISABLE_MODE: 'CONTACT_HOTLINE_DASHBOARD/SET_DISABLE_MODE',
  RECEIVE_CATEGORY_DETAILS_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_CATEGORY_DETAILS_SUCCESS',
  RECEIVE_CATEGORY_DETAILS_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_CATEGORY_DETAILS_FAILURE',
  RECEIVE_EDIT_CATEGORY_DETAILS_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_EDIT_CATEGORY_DETAILS_SUCCESS',
  RECEIVE_EDIT_CATEGORY_DETAILS_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_EDIT_CATEGORY_DETAILS_FAILURE',
  RECEIVE_DASHBOARD_DETAILS_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_DASHBOARD_DETAILS_SUCCESS',
  RECEIVE_DASHBOARD_DETAILS_FAILURE: 'CONTACT_HOTLINE_DASHBOARD/RECEIVE_DASHBOARD_DETAILS_FAILURE',
  RESET_CONTACT_HOTLINE_DETAILS_SUCCESS: 'CONTACT_HOTLINE_DASHBOARD/RESET_CONTACT_HOTLINE_DETAILS_SUCCESS',
  RESET_CONTACT_HOTLINE_COUNTRY: 'CONTACT_HOTLINE_DASHBOARD/RESET_CONTACT_HOTLINE_COUNTRY'

}

// Actions
export const actions = {
  fetchContacthotlineFilters() {
    return safeFetch({
      apiFunction: 'fetchContacthotlineFilters',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({ data }) => ({ type: constants.CONTACTHOTLINE_FILTERS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.CONTACTHOTLINE_FILTERS_FAILURE, error })
    })
  },
  addContact(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'addContactDetails',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: () => ({ type: constants.ADD_CONTACT_SUCCESS }),
      onFailure: (error) => ({ type: constants.ADD_CONTACT_FAILURE, error })
    })
  },
  editContact(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'editContactDetails',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: () => ({ type: constants.EDIT_CONTACT_SUCCESS }),
      onFailure: () => ({ type: constants.EDIT_CONTACT_FAILURE })
    })
  },
  getRegionsList() {
    return safeFetch({
      apiFunction: 'fetchRegionsList',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_REGION_DETAILS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RECEIVE_REGION_DETAILS_FAILURE, error })
    })
  },
  getCountriesListByRegionId(regionId) {
    return safeFetch({
      args: regionId,
      apiFunction: 'fetchCountriesListByRegionId',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_CONTACT_HOTLINE_COUNTRY_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RECEIVE_CONTACT_HOTLINE_COUNTRY_FAILURE, error })
    })
  },
  resetSuccessStatus() {
    return { type: constants.RESET_CONTACT_HOTLINE_SUCCESS_STATUS }
  },
  setDisableMode(mode) {
    return { type: constants.SET_DISABLE_MODE, mode }
  },
  getHotlineCategories(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'getHotlineCategories',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_CATEGORY_DETAILS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RECEIVE_CATEGORY_DETAILS_FAILURE, error })
    })
  },
  getUserEditHotlineDetails(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'editUserContactHotlineDetails',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_EDIT_CATEGORY_DETAILS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RECEIVE_EDIT_CATEGORY_DETAILS_FAILURE, error })
    })
  },
  getDashboardDetailsData(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'fetchContactHotlineDetailsDashboard',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_DASHBOARD_DETAILS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.RECEIVE_DASHBOARD_DETAILS_FAILURE, error })
    })
  },
  resetDashboardDetails() {
    return { type: constants.RESET_CONTACT_HOTLINE_DETAILS_SUCCESS }
  },
  resetCountryList() {
    return { type: constants.RESET_CONTACT_HOTLINE_COUNTRY }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  filterCategories: [],
  addContactSuccess: null,
  editContactSuccess: null,
  regionsList: [],
  countriesList: [],
  disableMode: true,
  hotlineCategoriesList: null,
  editHotlineDetails: [],
  dashboardDetails: [],
  editErrorStatus: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('editErrorStatus', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.ADD_CONTACT_SUCCESS:
      return state
        .set('fetching', false)
        .set('addContactSuccess', 'Success')
    case constants.ADD_CONTACT_FAILURE:
      return state
        .set('fetching', false)
        .set('errorMessage', action.error.errorMessage)
    case constants.EDIT_CONTACT_SUCCESS:
      return state
        .set('fetching', false)
        .set('editContactSuccess', 'Success')
    case constants.EDIT_CONTACT_FAILURE:
      return state
        .set('fetching', false)
    case constants.RECEIVE_REGION_DETAILS_SUCCESS:
      return state
        .set('fetching', false)
        .set('regionsList', fromJS(action.data))
    case constants.RECEIVE_REGION_DETAILS_FAILURE:
      return state
        .set('fetching', false)
    case constants.RECEIVE_CONTACT_HOTLINE_COUNTRY_SUCCESS:
      return state
        .set('fetching', false)
        .set('countriesList', fromJS(action.data))
    case constants.RECEIVE_CONTACT_HOTLINE_COUNTRY_FAILURE:
      return state
        .set('fetching', false)
    case constants.RESET_CONTACT_HOTLINE_SUCCESS_STATUS:
      return state
        .set('addContactSuccess', null)
        .set('editContactSuccess', null)
    case constants.SET_DISABLE_MODE:
      return state
        .set('disableMode', action.mode)
    case constants.CONTACTHOTLINE_FILTERS_SUCCESS:
      return state
        .set('fetchError', null)
        .set('fetching', false)
        .set('filterCategories', parseFilters(action.data))
    case constants.CONTACTHOTLINE_FILTERS_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
        .set('filterCategories', null)
    case constants.RECEIVE_CATEGORY_DETAILS_SUCCESS:
      return state
        .set('fetching', false)
        .set('hotlineCategoriesList', fromJS(action.data))
    case constants.RECEIVE_CATEGORY_DETAILS_FAILURE:
      return state
        .set('fetching', false)
    case constants.RECEIVE_EDIT_CATEGORY_DETAILS_SUCCESS:
      return state
        .set('fetching', false)
        .set('editHotlineDetails', fromJS(action.data))
        .set('editErrorStatus', null)
    case constants.RECEIVE_EDIT_CATEGORY_DETAILS_FAILURE:
      return state
        .set('fetching', false)
        .set('editErrorStatus', action.error?.errorCode)
        .set('editHotlineDetails', fromJS([]))
    case constants.RECEIVE_DASHBOARD_DETAILS_SUCCESS:
      return state
        .set('fetching', false)
        .set('dashboardDetails', fromJS(action.data))
    case constants.RECEIVE_DASHBOARD_DETAILS_FAILURE:
      return state
        .set('fetching', false)
    case constants.RESET_CONTACT_HOTLINE_DETAILS_SUCCESS:
      return state
        .set('editHotlineDetails', fromJS([]))
    case constants.RESET_CONTACT_HOTLINE_COUNTRY:
      return state
        .set('fetching', false)
        .set('countriesList', fromJS([]))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.contactHotlineDashboard

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) => (
    state.get('fetchError')
  )),
  errorMessage: createSelector(getState, (state) => (
    state.get('errorMessage')
  )),
  filterCategories: createSelector(getState, (state) => (
    state.get('filterCategories')
  )),
  addContactSuccess: createSelector(getState, (state) => (
    state.get('addContactSuccess')
  )),
  editContactSuccess: createSelector(getState, (state) => (
    state.get('editContactSuccess')
  )),
  countriesList: createSelector(getState, (state) => (
    state.get('countriesList')
  )),
  regionsList: createSelector(getState, (state) => (
    state.get('regionsList')
  )),
  disableMode: createSelector(getState, (state) => (
    state.get('disableMode')
  )),
  hotlineCategoriesList: createSelector(getState, (state) => (
    state.get('hotlineCategoriesList')
  )),
  editHotlineDetails: createSelector(getState, (state) => (
    state.get('editHotlineDetails')
  )),
  dashboardDetails: createSelector(getState, (state) => (
    state.get('dashboardDetails')
  )),
  editErrorStatus: createSelector(getState, (state) => (
    state.get('editErrorStatus')
  ))
}

// helper functions
const parseFilters = ({ countries, regions, contactType }) => {
  const filters = {
    regionName: regions && regions.length && regions || [],
    countryCode: countries && countries.length && countries || [],
    contactType: contactType && contactType.length && contactType || []
  }
  const searchFilters = ['countryCode']
  const requiredFilters = regions ? ['regionName', 'countryCode', 'contactType'] : ['countryCode']
  const updatedFilters = []
  requiredFilters.forEach((name) => {
    const filterConfig = {
      searchBox: searchFilters.includes(name),
      searchBoxHighlightSelection: true
    }

    updatedFilters.push({
      categoryType: name,
      disableSearchFilter: searchFilters.includes(name) && !filters[name].length,
      categoryValues: filters[name] ? filters[name].map(value => {
        if (name === 'regionName') {
          return ({
            name: value.regionName,
            label: value.regionName,
            value: value.regionName
          })
        } else if (name === 'countryCode') {
          return ({
            name: value.countryCode,
            label: value.countryCode,
            value: value.countryCode
          })
        }
        else if (name === 'contactType') {
          return ({
            name: value,
            label: value,
            value: value
          })
        }
      }) : [],
      ...filterConfig
    })
  })
  return fromJS(updatedFilters)
}
