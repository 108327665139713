import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { filterTypes as partnerPeripheralFilterTypes } from 'constants/partnerPeripheralConstant'

// Constants
export const moduleId = 'partnerPeripheral'
export const constants = {
  FETCH: 'PARTNER_PERIPHERAL/FETCH',
  FETCH_CREATE_SUCCESS: 'PARTNER_PERIPHERAL/FETCH_CREATE_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'PARTNER_PERIPHERAL/FETCH_UPDATE_SUCCESS',
  UPDATE_DEVICE_TYPE_STATUS: 'PARTNER_PERIPHERAL/UPDATE_DEVICE_TYPE_STATUS',
  FETCH_PARTNER_PERIPHERAL_SUCCESS: 'PARTNER_PERIPHERAL/FETCH_PARTNER_PERIPHERAL_SUCCESS',
  FETCH_PARTNER_PERIPHERAL_ERROR: 'PARTNER_PERIPHERAL/FETCH_PARTNER_PERIPHERAL_ERROR',
  RECIEVE_PARTNER_TYPES_SUCCESS: 'PARTNER_PERIPHERAL/RECIEVE_PARTNER_TYPES_SUCCESS',
  RECIEVE_PARTNER_TYPES_FAILURE: 'PARTNER_PERIPHERAL/RECIEVE_PARTNER_TYPES_FAILURE',
  FETCH_PERIPHERAL_SUPPLIER: 'PARTNER_PERIPHERAL/FETCH_PERIPHERAL_SUPPLIER',
  FETCH_PERIPHERAL_SUPPLIER_ERROR: 'PARTNER_PERIPHERAL/FETCH_PERIPHERAL_SUPPLIER_ERROR',
  DEVICE_TYPES_SUCCESS: 'PARTNER_PERIPHERAL/DEVICE_TYPES_SUCCESS',
  DEVICE_TYPES_FAILURE: 'PARTNER_PERIPHERAL/DEVICE_TYPES_FAILURE',
  CREATE_PARTNER_PERIPHERAL_BRAND_SUCCESS: 'PARTNER_PERIPHERAL/CREATE_PARTNER_PERIPHERAL_BRAND_SUCCESS',
  CREATE_PARTNER_PERIPHERAL_BRAND_FAILURE: 'PARTNER_PERIPHERAL/CREATE_PARTNER_PERIPHERAL_BRAND_FAILURE',
  UPDATE_PARTNER_PERIPHERAL_BRAND_SUCCESS: 'PARTNER_PERIPHERAL/UPDATE_PARTNER_PERIPHERAL_BRAND_SUCCESS',
  UPDATE_PARTNER_PERIPHERAL_BRAND_FAILURE: 'PARTNER_PERIPHERAL/UPDATE_PARTNER_PERIPHERAL_BRAND_FAILURE',
  UPDATE_PARTNER_BRAND_STATUS: 'PARTNER_PERIPHERAL/UPDATE_PARTNER_BRAND_STATUS'
}

// Action Creators
export const actions = {
  loadPartnerPeripheralFilter() {
    return safeFetch({
      apiFunction: 'LoadPartnerPeripheralFilter',
      onSuccess: ({data}) => ({
        type: constants.FETCH_PARTNER_PERIPHERAL_SUCCESS,
        data
      }),
      onFailure: ({status}) => ({
        type: constants.FETCH_PARTNER_PERIPHERAL_ERROR,
        error: status
      })
    })
  },
  fetchPeripheralSuppliers() {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'fetchPeripheralSuppliers',
      onSuccess: ({data}) => ({
        type: constants.FETCH_PERIPHERAL_SUPPLIER,
        data
      }),
      onFailure: ({status}) => ({
        type: constants.FETCH_PERIPHERAL_SUPPLIER_ERROR,
        error: status
      })
    })
  },
  getPartnerTypes(deviceTypeId) {
    return safeFetch({
      args: {deviceTypeId},
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'getPartnerTypes',
      onSuccess: ({data}) => ({type: constants.RECIEVE_PARTNER_TYPES_SUCCESS, data}),
      onFailure: (error) => ({type: constants.RECIEVE_PARTNER_TYPES_FAILURE, error}),
      throwError: true
    })
  },
  fetchDeviceTypes () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getDeviceTypes',
      onSuccess: ({ data }) => ({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes: data }),
      onFailure: (error) => ({ type: constants.DEVICE_TYPES_FAILURE, error: error })
    })
  },
  createPartnerBrand (query) {
    return safeFetch({
      apiFunction: 'createPartnerBrand',
      args: query,
      onSuccess: () => ({ type: constants.CREATE_PARTNER_PERIPHERAL_BRAND_SUCCESS }),
      onFailure: (error) => ({ type: constants.CREATE_PARTNER_PERIPHERAL_BRAND_FAILURE, error: error }),
      throwError: true
    })
  },
  updatePartnerBrand (query) {
    return safeFetch({
      apiFunction: 'updatePartnerBrand',
      args: query,
      onSuccess: () => ({ type: constants.UPDATE_PARTNER_PERIPHERAL_BRAND_SUCCESS }),
      onFailure: (error) => ({ type: constants.UPDATE_PARTNER_PERIPHERAL_BRAND_FAILURE, error: error }),
      throwError: true
    })
  },
  resetSuccessItemStatus() {
    return { type: constants.UPDATE_PARTNER_BRAND_STATUS }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  successStatus: null,
  filterCategories: [],
  partnerTypes: [],
  suppliers: [],
  deviceTypes: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.FETCH_PARTNER_PERIPHERAL_SUCCESS:
      return state
      .set('filterCategories', formatFilterCategories(action.data))
      .set('fetching', false)
    case constants.FETCH_PARTNER_PERIPHERAL_ERROR:
      return state
      .set('fetching', false)
    case constants.RECIEVE_PARTNER_TYPES_SUCCESS:
      return state
      .set('partnerTypes', action.data)
      .set('fetching', false)
    case constants.RECIEVE_PARTNER_TYPES_FAILURE:
      return state
      .set('fetching', false)
    case constants.FETCH_PERIPHERAL_SUPPLIER:
      return state
      .set('fetching', false)
      .set('suppliers', action.data)
    case constants.FETCH_PERIPHERAL_SUPPLIER_FAILURE:
      return state
      .set('fetching', false)
    case constants.DEVICE_TYPES_SUCCESS:
      return state
      .set('deviceTypes', action.deviceTypes)
      .set('fetching', false)
    case constants.CREATE_PARTNER_PERIPHERAL_BRAND_SUCCESS:
      return state
      .set('fetching', false)
      .set('successStatus', 'CREATE')
    case constants.UPDATE_PARTNER_PERIPHERAL_BRAND_SUCCESS:
      return state
      .set('fetching', false)
      .set('successStatus', 'UPDATE')
    case constants.UPDATE_PARTNER_BRAND_STATUS:
      return state
      .set('successStatus', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.partnerPeripheral

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  }),
  getSuccessStatus: createSelector(getState, (state) => {
    return state.get('successStatus')
  }),
  partnerTypes: createSelector(getState, (state) => {
    return state.get('partnerTypes')
  }),
  getSuppliers: createSelector(getState, (state) => {
    return state.get('suppliers')
  }),
  deviceTypes: createSelector(getState, (state) => {
    return state.get('deviceTypes')
  })
}
// helper functions
function formatFilterCategories (filterTypes) {
  const filterOrder = [
    partnerPeripheralFilterTypes.DEVICE_TYPE,
    partnerPeripheralFilterTypes.PARTNER_TYPE
  ]
  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      // let isSearchBox = filter === partnerPeripheralFilterTypes.PARTNER_TYPE
      const values = filterTypes[filter]
      const categoryType = filter
      const categoryValues = !values
          ? []
          : values.map((val, index) => {
            return {
              label: val,
              value: `${filter}.${val}`,
              key: index,
              name: val
            }
          })
      filterCategories.push({categoryType, categoryValues})
    }
  })
  return filterCategories
}
