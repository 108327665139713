import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import expandMenuIcon from 'static/icons/menu-extend-arrow.svg'
import 'antd/dist/antd.css'
import Footer from 'components/Footer/Footer'
import './Content.scss'
import { Layout, Row, Col } from 'antd'
import HeaderContainer from 'containers/HeaderContainer/HeaderContainer'
import Sidebar from 'components/Sidebar/Sidebar'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Breadcrumbs } from '@liquid-design/liquid-design-react'
import StaticHome from 'components/StaticHome/StaticHome'
import { deviceMaxWidth } from 'utils/screenSizeConstants'
const { Content } = Layout

class ContentS extends PureComponent {
  state = {
    collapsed: false,
    breadCrumbsItems: [],
  }

  toggle = () => {
    const { handleSidebarToggle, sidebarToggleIsActive } = this.props
    handleSidebarToggle(!sidebarToggleIsActive)
  }

  breadCrumbObjectCreaor(breadcrumbs) {
    var newArray = []
    breadcrumbs.forEach((element) => {
      newArray.push({
        content: <FormattedMessage id={`next.${element.routeName}`} />,
        name: element.routeName,
        onClick: this.onBreadCrumbClickHandler.bind(this, element.location),
      })
    })
    return newArray
  }

  componentDidUpdate(prevProps) {
    if (this.props.breadcrumbName !== prevProps.breadcrumbName) {
      let newBreadcrumbItems = this.breadCrumbObjectCreaor(
        this.props.breadcrumbName
      )
      this.setState({ breadCrumbsItems: newBreadcrumbItems })
    }
  }

  onBreadCrumbClickHandler(route) {
    this.props?.history?.push(route)
  }

  toggleDragMenu = async () => {
    const { fetchVerticalMenu } = this.props
    const classList = document.getElementById('vertical_menu').classList
    if (!classList.contains('show'))
      await fetchVerticalMenu('HOMEPAGE_VERTICALBUTTONS')
    classList.toggle('show')
  }

  handleSaveBtn = async (items) => {
    const { verticalMenuList, saveVerticalMenu } = this.props
    items = items?.filter(item => item.menuId !== 9999)
    items = items.map(function(item, index){ return {...item, menuSequence: index+1}})
    const data = {
      menuGroupId: verticalMenuList?.toJS()[0]?.menuGroupId,
      menuGroupName: 'HOMEPAGE_VERTICALBUTTONS',
      menus: items.map(({ menuId, menuSequence, visible }) => {
        return {
          menuId,
          sequence: menuSequence,
          visible: visible
        }
      })
    }
    await saveVerticalMenu(data)
    const { ackSaveVerticalMenu, openSnackBar, resetVerticalMenu, intl } = this.props
    if (ackSaveVerticalMenu) {
      this.toggleDragMenu()
      openSnackBar({ message: intl.formatMessage({ id: 'next.SAVED_BTN_DETAILS_SUCCESSFULLY' }) })
      resetVerticalMenu()
    }
  }

  render() {
    const {
      breadcrumbName,
      children,
      loggedIn,
      history,
      permission,
      userStatus,
      user,
      sidebarToggleIsActive,
    } = this.props
    const { countryCode: country, invitedVia, validated } = (user && user.toJS()) || ''
    const pageTitle = breadcrumbName[breadcrumbName.length - 1]?.routeName
    let isNewUser = false
    if (invitedVia !== null && invitedVia === 'Self Registration' && validated === false) {
      isNewUser = true
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <div className={`${!sidebarToggleIsActive ? 'blank-div' : ''}`} />
        <Row className={'header-top'} gutter={16}>
          <Col className={'header-container'} span={24}><HeaderContainer toggle={this.toggle} /></Col>
        </Row>
        <Row>
          <Col className={'side-bar-container'} span={sidebarToggleIsActive ? 1 : 3}>
            {
              isNewUser ? (<div />) : (
                <Sidebar
                  {...this.props}
                  collapsible
                  collapsed={sidebarToggleIsActive}
                  userOrgId={user && user.orgId}
                  loggedIn={loggedIn}
                  toggle={this.toggle}
                  userStatus={userStatus}
                  permission={permission && permission.toJS()}
                  toggleDragMenu={this.toggleDragMenu}
                  handleSaveBtn={this.handleSaveBtn}
                />
              )
            }
          </Col>
          <Col
            className={'body-content-container'}
            span={
              window.innerWidth <= deviceMaxWidth.IPAD ?
                24 : sidebarToggleIsActive ? 23 : 21
            }
          >
            {sidebarToggleIsActive ? (
              <div className='expand-div'>
                <img
                  alt
                  className='expand-icon'
                  onClick={this.toggle}
                  src={expandMenuIcon}
                />
              </div>
            ) : (
              <></>
            )}
            <Content
              className='site-layout-background'
              style={{
                minHeight: 280,
                marginTop: isNewUser ? '0px' : sidebarToggleIsActive ? '-55px' : '-30px',
                maxWidth: '98%'
              }}
            >
              {
                isNewUser ? (<StaticHome />) : (
                  <div className='content'>
                    <Row className={sidebarToggleIsActive ? 'margin-change' : 'no-margin-change'}>
                      <div className="header-title ">
                        {pageTitle && (
                          <h2 className='heading-text'>
                            <FormattedMessage id={`next.${pageTitle}`} />
                          </h2>
                        )}
                        <p className='sub-heading'>
                          <Breadcrumbs
                            items={this.state.breadCrumbsItems}
                            active={this.state.breadCrumbsItems.length - 1}
                          />
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ width: '100%', height: '100%' }}>
                        {children}
                      </div>
                    </Row>
                  </div>
                )
              }
              {/* <Footer loggedIn={loggedIn} history={history} country={country} /> */}
            </Content>
          </Col>
        </Row>
        {/* <Row><MerckVibrantLogo /></Row> */}
        <Row><Footer loggedIn={loggedIn} history={history} country={country} /></Row>
      </DndProvider>
    )
  }
}

ContentS.propTypes = {
  fetchedVerticalMenuList: PropTypes.bool,
  children: PropTypes.array,
  breadcrumbName: PropTypes.array,
  location: PropTypes.object,
  loggedIn: PropTypes.bool,
  handlePush: PropTypes.func,
  permission: PropTypes.object,
  userStatus: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  handleSidebarToggle: PropTypes.func,
  sidebarToggleIsActive: PropTypes.bool,
  fetchVerticalMenu: PropTypes.func,
  /*params: PropTypes.object,
  uid: PropTypes.string */
  verticalMenuList: PropTypes.array,
  saveVerticalMenu: PropTypes.func,
  ackSaveVerticalMenu: PropTypes.object,
  openSnackBar: PropTypes.func,
  resetVerticalMenu: PropTypes.func,
  intl: PropTypes.object,
}

export default withRouter(ContentS)
