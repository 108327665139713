import React, { Component } from 'react'
import NewsFeedCarousel from 'components/NewsFeedCarousel/NewsFeedCarousel'
import PropTypes from 'prop-types'
import {
  actions as newsActions,
  selectors as newsSelectors,
} from 'store/modules/NewsFeed'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { PATHS } from 'routes'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'antDComponents/Tooltip/Tooltip'
import {Glyph} from '@liquid-design/liquid-design-react'

class NewsFeedCarouselContainer extends Component {
  static propTypes = {
    imgFetchError: PropTypes.object,
    isFetchingImg: PropTypes.bool,
    newsImages: PropTypes.array,
    fetchNewsImages: PropTypes.func,
    fetchImageAndCategory: PropTypes.func,
    permission: PropTypes.object,
    resetNewsFeedState: PropTypes.func,
    newsFeedCategory: PropTypes.array,
    getNewsFeedCategory: PropTypes.func,
    category: PropTypes.object
  }

  async componentDidMount() {
    const { getNewsFeedCategory, fetchNewsImages, category } = this.props
    await fetchNewsImages(category)
    await getNewsFeedCategory()
  }
  componentWillUnmount() {
    const { resetNewsFeedState } = this.props
    resetNewsFeedState()
  }

  render() {
    const {
      imgFetchError,
      newsImages,
      isFetchingImg,
      fetchNewsImages,
      newsFeedCategory,
      permission,
      category
    } = this.props
    const newsFeedObj = {
      imgFetchError,
      newsImages,
      isFetchingImg,
      fetchNewsImages
    }
    return (
      <div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {permission && permission['news'] && permission['news']['MANAGE'] && (
            <div style={{ margin: '10px 10px 0px 0px' }}>
              <Tooltip placement='topLeft' title={<FormattedMessage id='next.MANAGE_NEWS_FEED' />}>
                <Link to={PATHS.MANAGE_NEWS_FEED} noDecoration>
                  <Glyph size={25} name='calendar' />
                </Link>
              </Tooltip>
            </div>
          )}
        </div>
        {newsFeedCategory.size > 0 && (
          <NewsFeedCarousel
            newsFeedObj={newsFeedObj}
            permission={permission}
            newsFeedCategory={newsFeedCategory.toJS()}
            categoryId={category && category.categoryId}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    imgFetchError: newsSelectors.imgFetchError(state),
    newsImages: newsSelectors.newsImages(state),
    newsFeedCategory: newsSelectors.newsFeedCategory(state),
    isFetchingImg: newsSelectors.isFetchingImg(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getNewsFeedCategory: (dispatch) =>
        newsActions.getNewsFeedCategory(dispatch),
      fetchNewsImages: (category) =>
        newsActions.fetchNewsImages(category),
      fetchImageAndCategory: (newsFeedCategory) => newsActions.fetchImageAndCategory(newsFeedCategory),
      resetNewsFeedState: newsActions.resetNewsFeedState
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsFeedCarouselContainer)
