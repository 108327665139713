
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* import HomeSvg from 'static/icons/HomeSvg'
import DeviceSvg from 'static/icons/DeviceSvg'
import DeviceCertSvg from 'static/icons/DeviceCertSvg'
import FileManagementSvg from 'static/icons/FileManagementSvg'
import ContactHotlineSvg from 'static/icons/ContactHolineSvg'
import CardsSvg from 'static/icons/CardsSvg' */
import { MovableItem } from './MovableItem'
import { Column } from './Column'
import { Button } from '@liquid-design/liquid-design-react'
import { FormattedMessage } from 'react-intl'
import './DragMenu.scss'
import { Spin, Space } from 'antd'

const COLUMN_NAMES = {
  DISPLAYED_MENU: <FormattedMessage id='next.VERTICAL_BAR_DISPLAYED_MENU_ITEMS' />,
  OTHER_MENU: <FormattedMessage id='next.VERTICAL_BAR_OTHER_MENU_ITEMS' />
}
let dragItem = {}

export const DragMenus = ({ id, toggleDragMenu, collapsed, position, myTasks, handleSaveBtn, Loading, isActive, visibleLimit, history, onDoubleClickAction, MENU_ITEMS, verticalBarRef }) => {

  const checkInitialLoadBtns = (tasks) => {
    const initialLoad = tasks?.filter(({ visible }) => visible === false)
    tasks = tasks?.sort(function (a, b) { return a.menuSequence - b.menuSequence })?.sort(function (a, b) { return b.visible - a.visible })
    if (initialLoad?.length < 1) {
      tasks && tasks?.push({ visible: false, menuId: 9999, btnVisibility: { show: true, disabled: false }, menuKey: 'Drop here to Move', menuSequence: tasks?.length + 100 })
    } else if (initialLoad?.length === tasks?.length)
      tasks && tasks?.unshift({ visible: true, menuId: 9999, btnVisibility: { show: true, disabled: false }, menuKey: 'Drop here to Move', menuSequence: tasks?.length + 100 })
    return tasks
  }
  const newMenuBtnList = checkInitialLoadBtns(myTasks)
  const [items, setItems] = useState(newMenuBtnList)

  useEffect(() => {
    const newMenuBtnList = checkInitialLoadBtns(myTasks)
    if (isActive || newMenuBtnList) {
      newMenuBtnList && setItems([...newMenuBtnList])
    }
  }, [isActive])

  const moveCardHandler = (dragIndex, hoverIndex) => {
    dragItem = items[dragIndex]
    if (dragItem) {
      setItems((prevState => {
        const coppiedStateArray = [...prevState]
        const visibleArrayLength = coppiedStateArray.filter(coppiedItem => coppiedItem.visible === true)?.length
        if ((!dragItem.visible && visibleArrayLength < visibleLimit)
          || (dragItem.visible && visibleArrayLength > 1)) {
          if ((!coppiedStateArray[hoverIndex].visible && dragItem.visible)
            || (coppiedStateArray[hoverIndex].visible && !dragItem.visible)) {
            dragItem.visible = coppiedStateArray[hoverIndex].visible
          } else {
            // swapping visible values
            let temp1
            temp1 = dragItem.visible
            dragItem.visible = coppiedStateArray[hoverIndex].visible
            coppiedStateArray[hoverIndex].visible = temp1
          }

          // remove item by "hoverIndex" and put "dragItem" instead
          const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem)

          // swapping sequence values
          let temp
          temp = prevItem[0].menuSequence
          prevItem[0].menuSequence = coppiedStateArray[hoverIndex].menuSequence
          coppiedStateArray[hoverIndex].menuSequence = temp

          // remove item by "dragIndex" and put "prevItem" instead
          coppiedStateArray.splice(dragIndex, 1, prevItem[0])

          return coppiedStateArray
        } else {
          // remove item by 'hoverIndex' and put 'dragItem' instead
          const cloneArray = coppiedStateArray.slice().filter(({ menuKey }) => menuKey === dragItem.menuKey)
          const prevItem = coppiedStateArray.splice(hoverIndex, 1, { ...dragItem, menuSequence: coppiedStateArray[hoverIndex].menuSequence, visible: coppiedStateArray[hoverIndex].visible })
          // remove item by 'dragIndex' and put 'prevItem' instead
          coppiedStateArray.splice(dragIndex, 1, { ...prevItem[0], menuSequence: cloneArray[0].menuSequence, visible: cloneArray[0].visible })

          return coppiedStateArray
        }
      }))
    }
  }

  const returnItemsForColumn = isVisible => {
    const visibleItemsLength = isVisible ? 0 : items?.filter((item) => item.visible === true)?.length
    return items?.filter((item) => item.visible === isVisible)
      .map((item, index) => {
        const index1 = visibleItemsLength + index
        return item.btnVisibility?.show && (
          <MovableItem
            key={item.menuSequence}
            menuId={item.menuId}
            name={item.menuKey}
            currentColumnName={dragItem?.visible ? COLUMN_NAMES.DISPLAYED_MENU : COLUMN_NAMES.OTHER_MENU}
            setItems={setItems}
            index={index1}
            columnNames={COLUMN_NAMES}
            moveCardHandler={moveCardHandler}
            history={history}
            keyUrl={item.keyUrl}
            disabled={item.btnVisibility?.disabled}
            onDoubleClickAction={onDoubleClickAction}
            icon={MENU_ITEMS ? MENU_ITEMS[item.keyUrl]?.icon : <></>}
          />
        )
      })
  }

  const { DISPLAYED_MENU, OTHER_MENU } = COLUMN_NAMES
  return (
    <div id={id} ref={verticalBarRef} className={`${!collapsed && 'collapsed'} ${position}-side drag-menu-parent`}>
      {Loading && <Space size='middle'> <Spin size='large' /> </Space>}
      <Button onClick={() => handleSaveBtn(items)}>
        <FormattedMessage id='next.DEVICE_DETAILS_VIEW_SAVE_BTN' />
      </Button>
      <Column
        columnNames={COLUMN_NAMES}
        toggleDragMenu={toggleDragMenu}
        title={DISPLAYED_MENU}
        className='drag-column first-label'>
        {returnItemsForColumn(true)}
      </Column>
      <Column
        columnNames={COLUMN_NAMES}
        title={OTHER_MENU}
        className='drag-column second-label'>
        {returnItemsForColumn(false)}
      </Column>
    </div>
  )
}

DragMenus.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  currentColumnName: PropTypes.number,
  moveCardHandler: PropTypes.func,
  setItems: PropTypes.object,
  children: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  toggleDragMenu: PropTypes.func,
  collapsed: PropTypes.func,
  position: PropTypes.string,
  id: PropTypes.string,
  myTasks: PropTypes.array,
  handleSaveBtn: PropTypes.func,
  Loading: PropTypes.bool,
  isActive: PropTypes.bool,
  visibleLimit: PropTypes.number,
  history: PropTypes.object,
  onDoubleClickAction: PropTypes.func,
  MENU_ITEMS: PropTypes.object,
  verticalBarRef: PropTypes.any
}

DragMenus.defaultProps = {
  visibleLimit: 5
}