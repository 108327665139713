import {
  actions as userActions,
  selectors as userSelectors
} from 'store/modules/User'
import {
  actions as resetPasswordActions
} from 'store/modules/ResetPassword'
import {
  selectors as messageSelectors,
  actions as messageActions
} from 'store/modules/Translation'
import { routerActions } from 'connected-react-router'
// import { actions as userRegistrationConsolidatedActions } from 'store/modules/UserRegistrationConsolidated'
import { constants as tokenConstants, userStatusConstants, uiAccessCheck } from 'utils/authUtils'
import { PATHS } from 'routes'
import { defaultLanguage } from 'constants/profile'

const checkUser = async (dispatch, getState) => {
  await dispatch(userActions.fetch())
  return userSelectors.userProfile(getState()) !== 'Failure'
}

export const requireAuth = async(store) => {
  const { getState, dispatch } = store
  if (!userSelectors.loggedIn(getState())) {
    const isLoggedIn = (localStorage.getItem(tokenConstants.REFRESH_TOKEN) && await checkUser(dispatch, getState)) || false
    const isApiOnly = userSelectors.getUserPrivilege(getState()) && uiAccessCheck(userSelectors.getUserPrivilege(getState()).toJS())
    if (isApiOnly) {
      await dispatch(userActions.setApiOnlyMessage())
      routerActions.replace(PATHS.LOGOUT)
    }
    if (userSelectors.getStatus(getState()) === userStatusConstants.PENDING && getState().router.locationBeforeTransitions.pathname !== PATHS.USER_REGISTRATION) {
      routerActions.replace(PATHS.USER_REGISTRATION)
    } else if (userSelectors.getStatus(getState()) === userStatusConstants.INPROGRESS) {
      routerActions.replace(PATHS.CHANGE_PASSWORD)
    }else if (!isLoggedIn) {
      localStorage.removeItem(tokenConstants.REFRESH_TOKEN)
      routerActions.replace(PATHS.LOGIN)
    }
    if (isLoggedIn) {
      const {languageCode} = userSelectors.userProfile(getState())?.toJS() || {}
      if (languageCode && languageCode !== defaultLanguage) {
        const locale = messageSelectors.locale(getState()) && messageSelectors.locale(getState())
        if (locale && languageCode && locale !== languageCode) {
          await dispatch(messageActions.fetch(languageCode))
        }
      }
    }
  }
  return false
}

export const requireActiveUser = async(store) => {
  const { dispatch, getState } = store
  if (userSelectors.getStatus(getState()) === userStatusConstants.PENDING) await checkUser(dispatch, getState)
  if (userSelectors.getStatus(getState()) === userStatusConstants.PENDING) {
    routerActions.replace({ pathname: PATHS.USER_REGISTRATION })
  } else if (userSelectors.getStatus(getState()) === userStatusConstants.INPROGRESS) {
    routerActions.replace({ pathname: PATHS.CHANGE_PASSWORD })
  }
}

export const redirectLoggedIn = (nextState, replace) => {
  if (localStorage.getItem('accessToken')) {
    replace({
      pathname: PATHS.HOME
    })
  }
}

export const requireAccess = (path, store) => async(nextState, replace, cb) => {
  const { getState } = store
  let access = false
  let canAccess = false
  path.forEach((path) => {
    access = userSelectors.canAccess(path)(getState())
    if (access) {
      canAccess = true
    }
  })
  if (!canAccess) {
    replace({
      pathname: PATHS.HOME
    })
  }
  cb()
}
export const requireCreateAccess = (path, store) => async(nextState, replace, cb) => {
  const { getState } = store
  let canAccess = false
  const privilages = userSelectors.getUserPrivilege(getState()).toJS()
  path.forEach((path) => {
    if (path.includes('_')) {
      const pathValue = path.split('_')
      const oper = pathValue[1].toUpperCase()
      const types = pathValue[0]
      if (privilages[types]) {
        const deviceTypes = Object.values(privilages[types])
        deviceTypes.forEach((deviceType) => {
          const cardTypes = Object.values(deviceType)
          cardTypes.forEach((cards) => {
            if (oper in cards) {
              canAccess = true
            }
          })
        })
      }
    }
  })
  if (!canAccess) {
    replace({
      pathname: PATHS.HOME
    })
  }
  cb()
}
export const checkResetPasswordExpiry = (store) => async (nextState) => {
  const {location: {query: {token}}} = nextState
  const { dispatch } = store
  await dispatch(resetPasswordActions.resetExpiryCheck(token, true))
  return true
}
export const checkInProgressStatus = (store) => async(nextState, replace, cb) => {
  const { getState } = store
  if (userSelectors.getStatus(getState()) !== userStatusConstants.INPROGRESS) {
    routerActions.replace(PATHS.DASHBOARD)
  }
  cb()
}

export const checkRegisterUserConsolidatedTokenExpiry = (store) => async (nextState) => {
  // const {location: {query: {token}}} = nextState
  // const { dispatch } = store
  // await dispatch(userRegistrationConsolidatedActions.resetTokenExpiryCheck(token))
  return true
}

