import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Bubble } from '@liquid-design/liquid-design-react'
import './AccordionSection.scss'
import {FormattedMessage} from 'react-intl'

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const {onClick, label} = this.props
    onClick(label)
  }

  render() {
    const {onClick, props: {isOpen, label, children, count}} = this

    return (
      <div className='accordion-section'>
        <div onClick={onClick} className='vertical-line'>
          <span style={{cursor: 'pointer'}}>
            <span className='section-title'><FormattedMessage id={`next.LISTING_FILTER_SECTION_${label}`}/></span>
            <Bubble label={count}/>
          </span>
        </div>
        {isOpen && (<div style={{padding: '10px 0', minHeight: '50px'}}>{children}</div>
        )}
      </div>
    )
  }
}

export default AccordionSection
