import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

// Constants
export const constants = {
  FETCH: 'CUSTOMER_QUALITY_DASHBOARD/FETCH',
  RECEIVE: 'CUSTOMER_QUALITY_DASHBOARD/RECEIVE',
  FETCH_FAILURE: 'CUSTOMER_QUALITY_DASHBOARD/FETCH_FAILURE',
  RECEIVE_ALERTS: 'CUSTOMER_QUALITY_DASHBOARD/RECEIVE_ALERTS',
  RECEIVE_FILTERS: 'CUSTOMER_QUALITY_DASHBOARD/RECEIVE_FILTERS',
  RECEIVE_FILTERS_LOADER_DISABLE: 'CUSTOMER_QUALITY_DASHBOARD/RECEIVE_FILTERS_LOADER_DISABLE'
}

// Action Creators
export const actions = {
  getFilters(data) {
    return ({ type: constants.RECEIVE_FILTERS, data })
  },
  getFiltersLoader() {
    return ({ type: constants.FETCH })
  },
  getFiltersLoaderDisable() {
    return ({ type: constants.RECEIVE_FILTERS_LOADER_DISABLE })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  filterCategories: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RECEIVE_FILTERS:
      return state
        .set('fetching', false)
        .set('filterCategories', parseFilters(action.data))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.customerQualityAlerts

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  filterCategories: createSelector(getState, (state) => {
    return state.get('filterCategories')
  })
}

// helper functions
const parseFilters = (filters) => {
  const searchFilters = ['locationName']
  const requiredFilters = ['eventType', 'deviceName', 'locationName']
  const updatedFilters = []
  requiredFilters.forEach((name) => {
    const filterConfig = {
      searchBox: searchFilters.includes(name),
      searchBoxHighlightSelection: true
    }

    updatedFilters.push({
      categoryType: name,
      categoryValues: filters[name] ? filters[name].map(value => ({
        name: value,
        label: value,
        value: `${name}.${value}`
      })) : [],
      ...filterConfig
    })
  })

  return fromJS(updatedFilters)
}
