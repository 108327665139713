import ApiError from 'api/ApiError'
import { constants as userConstants, USER_SESSION_EXPIRED } from 'store/modules/User'
import {
  actions as authActions
} from 'store/modules/Auth'
import { constants as tokenConstants, removeAccessToken, removeRefreshToken } from 'utils/authUtils'
import * as api from 'api/Api'
import { routerActions } from 'connected-react-router'
import { PATHS } from 'routes'
import { batch } from 'react-redux'

let fetchQueue = {}

/**
 * This function extends isomorphic-fetch:
 * [1] access state and dispatch actions
 * [2] handles session expiry
 * [3] avoid multiple invocation of fetch before initial/previous promise is resolved
 *
 * @param {object} options
 * @param {function} dispatch
 * @param {function} getState
 *
 * @return {promise|undefined}
 */
const genericFetch = async (options, dispatch, getState) => {
  // AbortController & AbortSignal was not supported by all browser at the time of development
  // So, timestamp created at the recent invocation of method is used as reference
  // to ignore the success/failire of previous api call
  let timestamp
  if (options.preventMultipleRequest) {
    timestamp = +(new Date())
    fetchQueue[options.apiFunction] = timestamp
  }

  if (options.onFetch) await dispatch(options.onFetch())
  try {
    let arg = typeof options.args === 'function' ? options.args(getState) : options.args
    if (options.callBackFunc) {
      arg = { ...arg, ...{ callBackFunc: options.callBackFunc } }
    }
    const res = await api[options.apiFunction](arg)

    // success callback should be only be invoked for recent request
    if (options.onSuccess) {
      if (!options.preventMultipleRequest ||
        (options.preventMultipleRequest && fetchQueue[options.apiFunction] === timestamp)) {
        await dispatch(options.onSuccess(res, getState))
      }
    }

    if (options.getResponse) return res
  } catch (error) {
    if (error instanceof ApiError) {
      batch(async () => {
        if (options.apiFunction === 'getAccessToken') {
          removeAccessToken()
          removeRefreshToken()
          await dispatch({ type: userConstants.FETCH_FAILURE, error: USER_SESSION_EXPIRED })
          await dispatch(routerActions.push(PATHS.LOGIN))
        } else if (error.status === 401 && !options.preventRetryAccessToken && options.apiFunction !== 'changePassword') {
          if (localStorage.getItem(tokenConstants.REFRESH_TOKEN)) {
            // if (!localStorage.getItem('isTokenCalled')) {
            //   localStorage.setItem('isTokenCalled', 'true')
            //   await dispatch(authActions.getAccessToken())
            // }
            await dispatch(authActions.getAccessToken())
            await genericFetch(options, dispatch, getState)
          } else {
            await dispatch({ type: userConstants.FETCH_FAILURE, error: USER_SESSION_EXPIRED, errorMessage: error })
            await dispatch(routerActions.push(PATHS.LOGIN))
          }
        } else {
          if (options.onFailure) {
            // error handling is not required for old request
            if (!options.preventMultipleRequest ||
              (options.preventMultipleRequest && fetchQueue[options.apiFunction] === timestamp)) {
              await dispatch(options.onFailure(error))
            }
          }
          if (options.throwError) throw error
        }
      })
    }
  }
}

export default (options = {}) => async (dispatch, getState) => genericFetch(options, dispatch, getState)