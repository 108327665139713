export default {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MUTED: 'muted',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  ACTION: 'action'
}
