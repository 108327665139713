import React from 'react'
import PropTypes from 'prop-types'
import { Input as InputField } from 'antd'
import { ErrorMessage } from 'formik'
import 'antd/lib/input/style/css'
import './Input.scss'
const { TextArea } = InputField

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  rows: PropTypes.number,
  setFieldValue: PropTypes.func,
  readOnly: PropTypes.bool,
  isCallback: PropTypes.bool,
  callbackFunction: PropTypes.func,
  browseTextFile: PropTypes.string
}

const Input = ({
  label,
  name,
  placeholder,
  value,
  handleChange,
  className,
  style,
  setFieldValue,
  isRequired,
  type,
  rows,
  readOnly,
  isCallback,
  callbackFunction,
  browseTextFile,
  ...restProps
}) => {
  return (
    <div
      className={`input-div ${className ? className : ''}`}
      style={{ ...style }}
    >
      <label className='input-text-label'>
        {label} {isRequired && <span className='required'>*</span>}
      </label>
      {type === 'textarea' ? (
        <TextArea
          rows={rows}
          name={name}
          placeholder={placeholder || <span className='text-placeholder'>placeholder</span>}
          value={value}
          onChange={handleChange}
          className='input-text'
          readOnly={readOnly}
          {...restProps}
        />
      ) : type === 'file' ? (
        <div className='input-file-div'>
          <InputField
            value={value?.name || value}
            className='input-file news-feed contacthotline-attachment'
            type='input'
            readOnly
            placeholder={placeholder}
          />
          <label className='custom-file-upload news-feed-file'>
            <InputField
              name={name}
              placeholder={placeholder}
              onChange={(event) => {
                setFieldValue(name, event.currentTarget.files[0])
                isCallback && callbackFunction(event, setFieldValue)
              }
              }
              className='input-text'
              type={type}
              readOnly={readOnly}
              {...restProps}
            />
            <i className="fa fa-cloud-upload"/> {browseTextFile}
          </label>
        </div>
      ) : (
        <InputField
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className='input-text'
          type={type}
          readOnly={readOnly}
          {...restProps}
        />
      )}
      <span className='error-message'>
      <ErrorMessage name={name} />
    </span>
    </div>
  )
}
Input.defaultProps = {
  type: 'text',
  rows: 4,
}

Input.propTypes = propTypes

export default Input
