import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

const propTypes = {
  date: PropTypes.string
}

const withDateFormat = ({ date }) => {
  let {userProfile} = useSelector(state => state.user.toJS())
  return(
    <React.Fragment>
      {date ? moment(date).format(userProfile?.dateFormat.toUpperCase()) : null}
    </React.Fragment>
  )
}

withDateFormat.propTypes = propTypes

export default React.memo(withDateFormat)