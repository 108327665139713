import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { DatePicker } from '@liquid-design/liquid-design-react'
import { injectIntl, useIntl } from 'react-intl'
import styles from './DateRangeFilter.scss'
import moment from 'moment'
import { dashboardTypes } from 'constants/customerMaintenance'

const cx = classNames.bind(styles)

class DateRangeFilter extends Component {

  static propTypes = {
    intl: useIntl.isRequired,
    setCurrentPage: PropTypes.func,
    handleClose: PropTypes.func,
    loadList: PropTypes.func,
    format: PropTypes.string,
    selectedQueryParams: PropTypes.array,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    dashboardType: PropTypes.string,
    addQueryParams: PropTypes.func,
  }
  constructor(props) {
    super(props)
    const { startTime, endTime, addQueryParams, selectedQueryParams } = props
    this.state = {
      startTime: moment(startTime).format(),
      endTime: moment(endTime).format(),
    }
    if (selectedQueryParams?.startTime === '' || selectedQueryParams?.startTime === undefined) {
      addQueryParams({ startTime, endTime })
    }
  }
  componentDidMount() {
    const { startTime, endTime, addQueryParams, selectedQueryParams } = this.props
    if (selectedQueryParams.startTime === '' || selectedQueryParams.startTime === undefined) {
      addQueryParams({ startTime, endTime })
    }
  }

  getValue = (name, selectedQueryParams) => {
    let requiredFilter = selectedQueryParams[name]
    return requiredFilter ? moment(requiredFilter) : null
  }

  disabledStartDate = (startValue) => {
    const { selectedQueryParams } = this.props
    const endValue = this.getValue('endTime', selectedQueryParams)
    if (!startValue || !endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = (endValue) => {
    const { selectedQueryParams, dashboardType } = this.props
    const startValue = this.getValue('startTime', selectedQueryParams)
    if (!endValue || !startValue) {
      return false
    }
    if (dashboardType && dashboardType === dashboardTypes.CUSTOMER_TECH) {
      return (
        endValue < startValue || endValue > moment(startValue).add(12, 'month')
      )
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  handleDateChange = (value) => {
    const { startTime, endTime } = this.state
    const {
      addQueryParams,
      setCurrentPage,
      handleClose,
    } = this.props
    if (startTime && endTime) {
      let obj = {
        startTime: moment(startTime).isValid() && (new Date(startTime)).toISOString(),
        endTime: endTime && (new Date(endTime)).toISOString(),
      }
      addQueryParams(obj)
      setCurrentPage(1)
    }
    if (handleClose) {
      handleClose()
    }
  }

  render() {
    const { intl, selectedQueryParams, format } = this.props
    return (
      <div className={cx('filter-content')}>
        <div>
          <DatePicker
            withCalendar
            format={format || 'DD-MM-YYYY'}
            rangeMode={true}
            endDateLabel={intl.formatMessage({ id: 'next.To' })}
            startDateLabel={intl.formatMessage({ id: 'next.From' })}
            startDateChange={(val) => {
              this.setState({ startTime: moment(val).format() }, () => {
                this.handleDateChange(moment(val).format())
              })
            }}
            endDateChange={(val) => {
              this.setState({ endTime: moment(val).format() }, () => {
                this.handleDateChange(moment(val).format())
              })
            }}
            defaultStartDate={this.getValue('startTime', selectedQueryParams)}
            defaultEndDate={this.getValue('endTime', selectedQueryParams)}
            placeholder={intl.formatMessage({ id: 'next.Select_Time' })}
            style={{ width: '100%' }}
            className={cx('nem-calender')}
          />
        </div>
      </div>
    )
  }
}
export default injectIntl(DateRangeFilter)
