import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  selectors as listingSelectors,
  constants as listingConstants
} from 'store/modules/GenericListing'
import { orgTypes } from 'constants/orgUsersListing'
import {headers as tableHeaders} from 'components/OrganizationDetails/OrganizationDetails'
// Constants
export const moduleId = 'organizationDetails'
const filterTypes = {
  'ORGANIZATIONTYPE': 'type',
  'ORGANIZATIONSTATUS': 'status'
}
const ORGANIZATIONSTATUS = {
  'active': 'Active',
  'inActive': 'Inactive'
}
export const constants = {
  FETCH: 'ORGANIZATION_DETAILS/FETCH',
  FETCH_COMPLETE: 'ORGANIZATION_DETAILS/FETCH_COMPLETE',
  FETCH_FAILURE: 'ORGANIZATION_DETAILS/FETCH_FAILURE',
  ADD_ORGANIZATION: 'ORGANIZATION_DETAILS/ADD_ORGANIZATION',
  EDIT_ORGANIZATION: 'ORGANIZATION_DETAILS/EDIT_ORGANIZATION',
  FETCH_PERIPHERAL_PARTNER_TYPES_SUCCESS: 'ORGANIZATION_DETAILS/FETCH_PERIPHERAL_PARTNER_TYPES_SUCCESS',
  FETCH_PERIPHERAL_BRANDS_ERROR: 'ORGANIZATION_DETAILS/FETCH_PERIPHERAL_BRANDS_ERROR',
  UPDATE_ORGANIZATION_STATUS: 'ORGANIZATION_DETAILS/UPDATE_ORGANIZATION_STATUS',
  FETCH_PERIPHERAL_BRAND_SUCCESS: 'ORGANIZATION_DETAILS/FETCH_PERIPHERAL_BRAND_SUCCESS',
  FETCH_PERIPHERAL_BRAND_ERROR: 'ORGANIZATION_DETAILS/FETCH_PERIPHERAL_BRAND_ERROR',
  FETCH_ORGANIZATION_TYPE_SUCCESS: 'ORGANIZATION_DETAILS/FETCH_ORGANIZATION_TYPE_SUCCESS',
  FETCH_ORGANIZATION_TYPE_ERROR: 'ORGANIZATION_DETAILS/FETCH_ORGANIZATION_TYPE_ERROR',
  FETCH_FILTER_TYPES: 'ORGANIZATION_DETAILS/FETCH_FILTER_TYPES',
  FETCH_ORGANIZATION_FILTER_SUCCESS: 'ORGANIZATION_DETAILS/FETCH_ORGANIZATION_FILTER_SUCCESS',
  FETCH_ORGANIZATION_FILTER_ERROR: 'ORGANIZATION_DETAILS/FETCH_ORGANIZATION_FILTER_ERROR',
  STATUS_TOGGLE: 'ORGANIZATION_DETAILS/STATUS_TOGGLE',
  STATUS_TOGGLE_FAILURE: 'ORGANIZATION_DETAILS/STATUS_TOGGLE_FAILURE',
  STATUS_TOGGLE_SUCCESS: 'ORGANIZATION_DETAILS/STATUS_TOGGLE_SUCCESS'
}

// Action Creators
export const actions = {
  addOrganization(data) {
    return safeFetch({
      args: createPayload(data),
      throwError: true,
      apiFunction: 'addOrganizationDetails',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: () => ({ type: constants.ADD_ORGANIZATION }),
      onFailure: () => ({ type: constants.FETCH_FAILURE })
    })
  },
  editOrganizationStatus(data) {
    const { status, orgId } = data
    return async (dispatch) => {
      await dispatch({ type: constants.STATUS_TOGGLE })
      await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'editOrganizationDetails',
        args: createPayload(data),
        onSuccess: (res, getState) => {
          dispatch({ type: constants.STATUS_TOGGLE_SUCCESS })

          const headers = new Headers()
          headers.append('x-pagination-count', listingSelectors.totalPages(getState(), moduleId))
          headers.append('x-record-count', listingSelectors.totalRecords(getState(), moduleId))

          const data = listingSelectors.listingData(getState(), moduleId)
          const newData = data.update(data.findIndex(item => item.get('orgId') === orgId), item => item.set('status', status)).toJS()

          return ({
            type: listingConstants.RECEIVE_LIST,
            listingName: moduleId,
            data: newData,
            headers,
            tableHeaders
          })
        },
        onFailure: (error) => {
          return ({ type: constants.STATUS_TOGGLE_FAILURE, error: error.status })
        }
      }))
    }
  },
  editOrganization(data) {
    return safeFetch({
      args: createPayload(data),
      throwError: true,
      apiFunction: 'editOrganizationDetails',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: () => ({ type: constants.EDIT_ORGANIZATION }),
      onFailure: () => ({ type: constants.FETCH_FAILURE })
    })
  },
  fetchPeripheralPartnerTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getPartnerTypes',
      onSuccess: ({data}) => ({
        type: constants.FETCH_PERIPHERAL_PARTNER_TYPES_SUCCESS,
        data
      }),
      onFailure: ({status}) => ({
        type: constants.FETCH_PERIPHERAL_BRANDS_ERROR,
        error: status
      })
    })
  },
  getBrandValuesByPartnerType (partnerTypeIds, deviceTypeId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getBrandValuesByPartnerType',
      args: {partnerTypeIds, deviceTypeId},
      onSuccess: ({data}) => ({type: constants.FETCH_PERIPHERAL_BRAND_SUCCESS, data}),
      onFailure: (error) => ({type: constants.FETCH_PERIPHERAL_BRAND_ERROR, error}),
      throwError: true
    })
  },
  resetSuccessItemStatus() {
    return { type: constants.UPDATE_ORGANIZATION_STATUS }
  },
  fetchOrganizationTypes () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchOrganizationTypes',
      onSuccess: ({data}) => ({type: constants.FETCH_ORGANIZATION_TYPE_SUCCESS, data}),
      onFailure: (error) => ({type: constants.FETCH_ORGANIZATION_TYPE_ERROR, error})
    })
  },
  fetchOrganizationFilters () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchOrganizationFilters',
      onSuccess: ({data}) => ({type: constants.FETCH_ORGANIZATION_FILTER_SUCCESS, data}),
      onFailure: (error) => ({type: constants.FETCH_ORGANIZATION_FILTER_ERROR, error})
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  orgStatus: null,
  orgTypes: [],
  orgFilters: null,
  brandValues: [],
  fetchPartnerTypeError: null,
  togglefetching: false,
  orgStatusError: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.FETCH_FAILURE:
      return state
      .set('fetching', false)
      .set('fetchPartnerTypeError', null)
    case constants.FETCH_PERIPHERAL_PARTNER_TYPES_SUCCESS:
      return state
      .set('peripheralPartnerType', action.data)
      .set('fetching', false)
    case constants.FETCH_PERIPHERAL_BRANDS_FAILURE:
      return state
      .set('fetching', false)
    case constants.ADD_ORGANIZATION:
      return state
      .set('fetching', false)
      .set('orgStatus', 'CREATE')
    case constants.EDIT_ORGANIZATION:
      return state
      .set('fetching', false)
      .set('orgStatus', 'UPDATE')
    case constants.UPDATE_ORGANIZATION_STATUS:
      return state
      .set('orgStatus', null)
      .set('fetchPartnerTypeError', null)
      .set('orgStatusError', null)
    case constants.FETCH_PERIPHERAL_BRAND_SUCCESS:
      return state
      .set('brandValues', action.data)
      .set('fetchPartnerTypeError', null)
      .set('fetching', false)
    case constants.FETCH_PERIPHERAL_BRAND_ERROR:
      return state
      .set('fetchPartnerTypeError', action.error)
      .set('brandValues', null)
      .set('fetching', false)
    case constants.FETCH_ORGANIZATION_TYPE_SUCCESS:
      return state
      .set('orgTypes', action.data)
      .set('fetching', false)
    case constants.FETCH_ORGANIZATION_FILTER_SUCCESS:
      return state
      .set('orgFilters', getOrganizationFilter(action.data))
      .set('fetching', false)
    case constants.FETCH_ORGANIZATION_FILTER_ERROR:
      return state
      .set('orgFilters', null)
      .set('fetching', false)
    case constants.STATUS_TOGGLE:
      return state
        .set('togglefetching', true)
    case constants.STATUS_TOGGLE_FAILURE:
      return state
        .set('fetching', false)
        .set('orgStatusError', action.error)
    case constants.STATUS_TOGGLE_SUCCESS:
      return state
        .set('fetching', false)
        .set('orgStatus', 'UPDATE')
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.organizationDetails

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  getPeripheralPartnerType: createSelector(getState, (state) => {
    return state.get('peripheralPartnerType')
  }),
  getOrgSuccessStatus: createSelector(getState, (state) => {
    return state.get('orgStatus')
  }),
  brandValues: createSelector(getState, (state) => {
    return state.get('brandValues')
  }),
  orgTypes: createSelector(getState, (state) => {
    return state.get('orgTypes')
  }),
  orgFilters: createSelector(getState, (state) => {
    return state.get('orgFilters')
  }),
  fetchPartnerTypeError: createSelector(getState, (state) => {
    return state.get('fetchPartnerTypeError')
  }),
  updateOrgSuccess: createSelector(getState, (state) =>
    state.get('updateOrgSuccess')
  ),
  getOrgSuccessStatusError: createSelector(getState, (state) => (
    state.get('orgStatusError')
  ))
}

// helper function
const createPayload = ({ type, ...data }) => {
  let template = ''

  if (type === orgTypes.CUSTOMERS) {
    template = 'labwater_customer'
  } else if (type === orgTypes.PARTNERS) {
    template = 'labwater_partner'
  } else if (type === orgTypes.DISTRIBUTORS) {
    template = 'labwater_distributor'
  }
  return {
    ...data,
    type,
    template
  }
}
function getOrganizationFilter (organizationFilter) {
  const filterOrder = [
    filterTypes.ORGANIZATIONTYPE,
    filterTypes.ORGANIZATIONSTATUS
  ]
  let filterCategories = []
  filterOrder.forEach((filter) => {
    const values = organizationFilter[filter]
    const categoryValues = !values
        ? []
        : values.map((val, index) => {
          return {
            label: val,
            value: `${filter}.${val}`,
            key: index,
            name: filter === filterTypes.ORGANIZATIONSTATUS ? val ? ORGANIZATIONSTATUS.active : ORGANIZATIONSTATUS.inActive : val
          }
        })
    filterCategories.push({categoryType: filter, categoryValues})
  })
  return fromJS(filterCategories)
}
