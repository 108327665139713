import React from 'react'
import { connect } from 'react-redux'
import {
  selectors as headerSelectors
} from 'store/modules/Header'
import UserRatingPopup from 'components/UserRatingPopup/UserRatingPopup'

class RatingPopupContainer extends React.Component {
  render() {
    return (
      <UserRatingPopup {...this.props} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSubmit: headerSelectors.isSubmit(state)
  }
}

export default connect(
  mapStateToProps,
  null
)(RatingPopupContainer)
