import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'EDIT_CARD/FETCH',
  FETCH_FAILURE: 'EDIT_CARD/FETCH_FAILURE',
  RECEIVE_CARD_DETAILS: 'EDIT_CARD/RECEIVE_CARD_DETAILS',
  CARD_UPDATE_SUCCESS: 'EDIT_CARD/CARD_UPDATE_SUCCESS'
}

// Action Creators
export const actions = {
  fetchCardDetail (cardId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchCardDetail',
      args: cardId,
      onSuccess: ({data}) => ({ type: constants.RECEIVE_CARD_DETAILS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error })
    })
  },
  updateCardDetail (card, cardId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateCardDetail',
      args: {card, cardId},
      onSuccess: ({data: status}) => ({ type: constants.CARD_UPDATE_SUCCESS, status }),
      throwError: true
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  cardUpdateSuccess: null,
  cardDetails: {}
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('cardUpdateSuccess', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.RECEIVE_CARD_DETAILS:
      return state
        .set('fetching', false)
        .set('cardDetails', receiveCardDetails(action.data))
    case constants.CARD_UPDATE_SUCCESS:
      return state
        .set('fetching', false)
        .set('cardUpdateSuccess', fromJS(action.status))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.editCard

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  cardDetails: createSelector(getState, (state) =>
    state.get('cardDetails')
  ),
  cardUpdateSuccess: createSelector(getState, (state) =>
    state.get('cardUpdateSuccess')
  )
}

// helper functions
function receiveCardDetails (data) {
  if (!data.cardName) {
    return fromJS({})
  } else {
    const { shortcard, ...card } = data
    return fromJS({
      shortcard: String(shortcard),
      ...card
    })
  }
}
