import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import 'antd/lib/input/style/css'
import './Radio.scss'
const optionsWithDisabled = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange'},
]
const propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  errorMessage: PropTypes.string
}

const antRadio = ({
  name,
  value,
  handleChange,
  className,
  style,
  errorMessage,
  ...restProps
}) => (
  <div className={`input-div ${className ? className : ''}`} style={{ ...style }}>
    <Radio.Group name='radio' options={optionsWithDisabled} onChange={handleChange} value={value} {...restProps} />
    <span className='error-message'>{errorMessage}</span> 
  </div>
)

antRadio.propTypes = propTypes

export default antRadio
