import React from 'react'
import PropTypes from 'prop-types'
import EditIconSvg from 'static/icons/EditIconSvg'
import DeleteIconSvg from 'static/icons/DeleteIconSvg'

const propTypes = {
  style: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  rowData: PropTypes.object,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  isDisableDelete: PropTypes.bool,
  isDisableEdit: PropTypes.bool,
  handleContactDetails: PropTypes.func
}

const editDeleteAction = ({
  style,
  isDisableDelete,
  isDisableEdit,
  handleDelete,
  handleEdit,
  isEdit,
  isDelete,
  rowData,
  handleContactDetails,
  ...restProps
}) => {
  return (
    <div style={{ display: 'flex', maxWidth: '4rem' }}>
      {isEdit && (
        <span style={isDisableEdit ? { marginRight: '10px', opacity: '.4', cursor: 'not-allowed' } : { marginRight: '10px', cursor: 'pointer' }} onClick={() => !isDisableEdit && handleEdit(rowData)}>
          <EditIconSvg {...restProps} />
        </span>
      )}
      {isDelete && (
        <span style={isDisableDelete ? { opacity: '.4', cursor: 'not-allowed' } : { cursor: 'pointer' }} onClick={() => !isDisableDelete && handleDelete(rowData)}>
          <DeleteIconSvg {...restProps} />
        </span>
      )}
    </div>
  )
}

editDeleteAction.defaultProps = {
  isEdit: true,
  isDelete: true,
  isDisableDelete: false,
  isDisableEdit: false
}
editDeleteAction.propTypes = propTypes
export default editDeleteAction
