import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import { isUndefined } from 'utils/dataUtils'
import { dashboards } from 'utils/userInfoUtils'

export const USER_SESSION_EXPIRED = 'USER_SESSION_EXPIRED'
// Constants
export const constants = {
  FETCH: 'USER/FETCH',
  FETCH_SUCCESS: 'USER/FETCH_SUCCESS',
  USER_PRIVILEGES_SUCCESS: 'USER/USER_PRIVILEGES_SUCCESS',
  FETCH_FAILURE: 'USER/FETCH_FAILURE',
  FORGOT_PASSWORD_SUCCESS: 'USER/FORGOT_PASSWORD_SUCCESS',
  RESET_FOTGOT_SUCCESS: 'USER/RESET_FOTGOT_SUCCESS',
  DISABLE_FORGOT_PAGE: 'USER/DISABLE_FORGOT_PAGE',
  FORGOT_PAGE: 'USER/FORGOT_PAGE',
  UPDATE_USER_STATUS: 'USER/UPDATE_USER_STATUS',
  UPDATE_LOGGED_USER: 'USER/UPDATE_LOGGED_USER',
  FETCH_USER_INFO_FAILURE: 'USER/FETCH_USER_INFO_FAILURE',
  SET_API_ONLY_MESSAGE: 'USER/SET_API_ONLY_MESSAGE',
  UPDATE_USER_INFO_COUNTRY_CODE: 'USER_REGISTRATION_CONSOLIDATED/UPDATE_USER_INFO_COUNTRY_CODE',
  RESET_ACCESS_TOKEN_FAILURE: 'USER/RESET_ACCESS_TOKEN_FAILURE'
}

// Action Creators
export const actions = {
  fetch () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchUser',
      onSuccess: ({data: {orgSettings, ...data}}) => {
        return ({
          type: constants.FETCH_SUCCESS,
          userProfile: {
            ...data,
            privileges: sanitizeUserPrivileges(data.privileges),
            orgSettings,
            dashboardSettings: sanitizeDashboardSettings(orgSettings || {}, data)
          }
        })
      },
      onFailure: (error) => ({ type: constants.FETCH_USER_INFO_FAILURE, error: error.toString() })
    })
  },
  fetchUserPrivileges (roleId, resourceType = null) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getRolePrivileges',
      args: { args: {roleId, query: {filterBy: resourceType}} },
      onSuccess: ({ data }) => {
        return ({ type: constants.USER_PRIVILEGES_SUCCESS, userPrivileges: sanitizeUserPrivileges(data) })
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.toString() })
    })
  },
  passwordReset (email) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'forgotPassword',
      args: email,
      onSuccess: ({ data }) => {
        return ({ type: constants.FORGOT_PASSWORD_SUCCESS })
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message }),
      throwError: true,
      preventRetryAccessToken: true
    })
  },
  resetSuccess () {
    return ({ type: constants.RESET_FOTGOT_SUCCESS })
  },
  forgotPage () {
    return ({ type: constants.FORGOT_PAGE })
  },
  disableForgotPage () {
    return ({type: constants.DISABLE_FORGOT_PAGE})
  },
  updateUserStatus (status) {
    return ({type: constants.UPDATE_USER_STATUS, status})
  },
  updateLoggedUser (dateFormatId, dateTimeFormats, userLanguageCode) {
    const { dateFormat } = dateTimeFormats.find((date) => date.id === dateFormatId)
    return ({type: constants.UPDATE_LOGGED_USER, dateFormat, userLanguageCode})
  },
  setApiOnlyMessage () {
    return ({type: constants.SET_API_ONLY_MESSAGE})
  },
  updateUserInfoCountryCode (countryCode) {
    return ({ type: constants.UPDATE_USER_INFO_COUNTRY_CODE, countryCode })
  },
  resetAccessTokenMessage () {
    return ({ type: constants.RESET_ACCESS_TOKEN_FAILURE })
  },
}

export const sanitizeUserPrivileges = privileges => {
  const devicetypes = {}
  return privileges?.reduce((acc, cur) => {
    const privilegeString = cur.operation.split('_')
    const temp = acc[privilegeString[1]] || {}
    if (cur.resourceType === 'devicetype') {
      if (privilegeString[1] !== undefined) {
        const temp2 = devicetypes[cur.resourceId] || {}
        const tempObj = { [privilegeString[1]]: {[privilegeString[0].toUpperCase()]: privilegeString[0]} }
        devicetypes[cur.resourceId] = {...devicetypes[cur.resourceId], [privilegeString[1]]: { ...temp2[privilegeString[1]], ...tempObj[privilegeString[1]] }}
        acc['devicetypes'] = {...acc['devicetypes'], ...devicetypes}
      }
    } else {
      if (cur.operation === 'lw_customer_view' || cur.operation === 'lw_partner_view') {
        acc[`${privilegeString[0]}${privilegeString[1]}`] = { [privilegeString[2].toUpperCase()]: privilegeString[2] }
      } else {
        acc[isUndefined(privilegeString[1]) ? privilegeString[0] : privilegeString[1]] = { ...temp, ...{ [privilegeString[0].toUpperCase()]: privilegeString[0] } }
      }
    }
    return acc
  }, {})
}

const sanitizeDashboardSettings = (orgSettings, {privileges}) => {
  let roleBasedOrgSettings = privileges.find(privilege => privilege.operation.indexOf('_homepage') !== -1)
  let dashboardType
  if (roleBasedOrgSettings) {
    dashboardType = roleBasedOrgSettings.operation.split('_')[0] + roleBasedOrgSettings.operation.split('_')[1]
  } else {
    dashboardType = orgSettings.appName || 'default'
  }
  return {dashboard: dashboards[dashboardType]}
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  error: null,
  userProfile: null,
  forgotpasswordSuccess: false,
  enableforgotpage: false,
  apiOnlyMessage: null,
  accessTokenFailure: null,
  errorMessage: null,
  serviceToken: false
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('serviceToken', false)
        .set('error', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('error', action.error)
        .set('accessTokenFailure', {status: action?.errorMessage?.status, errorMessage: action?.errorMessage?.errorMessage})
        .set('errorMessage', action?.errorMessage)
    case constants.FETCH_USER_INFO_FAILURE:
      return state
        .set('fetching', false)
        .set('serviceToken', false)
        .set('error', action.error)
        .set('userProfile', 'Failure')
    case constants.FETCH_SUCCESS:
      return state
        .set('fetching', false)
        .set('serviceToken', true)
        .set('userProfile', fromJS(action.userProfile))
    case constants.USER_PRIVILEGES_SUCCESS:
      return state
        .set('fetching', false)
        .set('userPrivileges', fromJS(action.userPrivileges))
    case constants.FORGOT_PASSWORD_SUCCESS:
      return state
        .set('forgotpasswordSuccess', true)
    case constants.RESET_FOTGOT_SUCCESS:
      return state
        .set('forgotpasswordSuccess', false)
    case constants.FORGOT_PAGE:
      return state
        .set('enableforgotpage', true)
    case constants.DISABLE_FORGOT_PAGE:
      return state
        .set('enableforgotpage', false)
    case constants.UPDATE_USER_STATUS:
      return state
        .updateIn(['userProfile', 'status'], () => action.status)
    case constants.UPDATE_LOGGED_USER:
      return state
        .updateIn(['userProfile', 'dateFormat'], () => action.dateFormat)
        .updateIn(['userProfile', 'languageCode'], () => action.userLanguageCode)
    case constants.SET_API_ONLY_MESSAGE:
      return state
        .set('apiOnlyMessage', true)
    case constants.UPDATE_USER_INFO_COUNTRY_CODE:
      return state
        .updateIn(['userProfile', 'countryCode'], () => action.countryCode)
    case constants.RESET_ACCESS_TOKEN_FAILURE:
      return state
        .set('accessTokenFailure', null)
        .set('error', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.user

export const selectors = {
  error: createSelector(getState, (state) =>
    state.get('error')
  ),
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  user: createSelector(getState, (state) =>
    state.get('userProfile')
  ),
  loggedIn: createSelector(getState, (state) =>
    !!state.get('userProfile')
  ),
  canAccess: (resource) => createSelector(getState, (state) => {
    return !!state.getIn(['userProfile', 'privileges', resource])
  }),
  getStatus: createSelector(getState, (state) =>
    state.get('userProfile') && state.get('userProfile').get('status')
  ),
  checkStatus: (status) => createSelector(getState, (state) =>
    state.getIn(['userProfile', 'status']) === status
  ),
  roleId: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'roleId'])
  ),
  getRole: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'role'])
  ),
  getUserPrivilege: createSelector(getState, (state) =>
    state.get('userProfile') && state.getIn(['userProfile', 'privileges'])
  ),
  getLoggedUserId: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'id'])
  ),
  getForgotSuccess: createSelector(getState, (state) =>
    state.get('forgotpasswordSuccess')
  ),
  enableForgotPage: createSelector(getState, (state) =>
    state.get('enableforgotpage')
  ),
  dateFormat: createSelector(getState, (state) =>
    state.get('userProfile') && state.getIn(['userProfile', 'dateFormat'])
  ),
  userProfile: createSelector(getState, (state) => state.get('userProfile')),
  getLanguageCode: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'languageCode'])
  ),
  getUserCountryCode: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'countryCode'])
  ),
  getOrgSettings: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'orgSettings'])
  ),
  getDashboardSettings: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'dashboardSettings'])
  ),
  apiOnlyMessage: createSelector(getState, (state) =>
    state.get('apiOnlyMessage')
  ),
  accessTokenFailure: createSelector(getState, (state) =>
    state.get('accessTokenFailure')
  ),
  errorMessage: createSelector(getState, (state) =>
    state.get('accessTokenFailure')
  ),
  getOrgName: createSelector(getState, (state) =>
    state.getIn(['userProfile', 'orgName'])
  ),
  serviceToken: createSelector(getState, (state) =>
    state.get('serviceToken')
  )
}
