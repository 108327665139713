import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const moduleId = 'serviceContractDetail'
export const constants = {
    FETCH: 'SERVICE_CONTRACT_DETAIL/FETCH',
    FETCH_COVERED_PRODUCTS_SUCCESS: 'SERVICE_CONTRACT_DETAIL/FETCH_COVERED_PRODUCTS_SUCCESS',
    FETCH_COVERED_PRODUCTS_FAILURE: 'SERVICE_CONTRACT_DETAIL/FETCH_COVERED_PRODUCTS_FAILURE',
    SET_COVERED_PRODUCT_QUERY: 'SERVICE_CONTRACT_DETAIL/SET_COVERED_PRODUCT_QUERY',
    FETCH_CONSUMABLES_SUCCESS: 'SERVICE_CONTRACT_DETAIL/FETCH_CONSUMABLES_SUCCESS',
    FETCH_CONSUMABLES_FAILURE: 'SERVICE_CONTRACT_DETAIL/FETCH_CONSUMABLES_FAILURE',
    FETCH_OPTIONS_INCLUDED_SUCCESS: 'SERVICE_CONTRACT_DETAIL/FETCH_OPTIONS_INCLUDED_SUCCESS',
    FETCH_OPTIONS_INCLUDED_FAILURE: 'SERVICE_CONTRACT_DETAIL/FETCH_OPTIONS_INCLUDED_FAILURE',
    SET_OPTIONS_INCLUDED_QUERY: 'SERVICE_CONTRACT_DETAIL/SET_OPTIONS_INCLUDED_QUERY',
    FETCH_UPDATE_PLAN_LIST_SUCCESS: 'SERVICE_CONTRACT_DETAIL/FETCH_UPDATE_PLAN_LIST_SUCCESS',
    FETCH_UPDATE_PLAN_LIST_FAILURE: 'SERVICE_CONTRACT_DETAIL/FETCH_UPDATE_PLAN_LIST_FAILURE',
    SET_UPDATE_PLAN_QUERY: 'SERVICE_CONTRACT_DETAIL/SET_UPDATE_PLAN_QUERY',
    FETCH_DESIRED_SERVICE_LEVELS_SUCCESS: 'SERVICE_CONTRACT_DETAIL/FETCH_DESIRED_SERVICE_LEVELS_SUCCESS',
    FETCH_DESIRED_SERVICE_LEVELS_FAILURE: 'SERVICE_CONTRACT_DETAIL/FETCH_DESIRED_SERVICE_LEVELS_FAILURE',
    UPDATE_SERVICE_LEVELS: 'SERVICE_CONTRACT_DETAIL/UPDATE_SERVICE_LEVELS',
    MODIFY_PMVISIT_PRODUCTS_SELECTION: 'SERVICE_CONTRACT_DETAIL/MODIFY_PMVISIT_PRODUCTS_SELECTION',
    FETCH_UPDATE_PM_VISIT_LIST_SUCCESS: 'SERVICE_CONTRACT_DETAIL/FETCH_UPDATE_PM_VISIT_LIST_SUCCESS',
    FETCH_UPDATE_PM_VISIT_LIST_FAILURE: 'SERVICE_CONTRACT_DETAIL/FETCH_UPDATE_PM_VISIT_LIST_FAILURE',
    SET_UPDATE_PM_VISIT_QUERY: 'SERVICE_CONTRACT_DETAIL/SET_UPDATE_PM_VISIT_QUERY'
}

// Action Creators
export const actions = {
    getFetchQuery(getState, contractId, queryType) {
        const state = getState()
        let productQuery = state?.serviceContractDetail?.get(queryType)?.toJS()
        let selectedContractId = state?.serviceContractDetail?.get('selectedContractId')
        const serviceContractId = contractId || selectedContractId
        let query = {}
        query['sortBy'] = productQuery['sortColumn']
        query['orderBy'] = productQuery['sortDirection']
        query['pageLimit'] = productQuery['perPage']
        query['page'] = productQuery['currentPage']
        return { query, serviceContractId }
    },
    getFetchQueryUpdate(getState, contractId, queryType) {
        const state = getState()
        let productQuery = state?.serviceContractDetail?.get(queryType)?.toJS()
        let selectedContractId = state?.serviceContractDetail?.get('selectedContractId')
        const serviceContractId = contractId || selectedContractId
        let query = {
            updateTypeFlag: queryType === 'updatePMVisitQuery' ? 'update-pm-visit' : 'update-plan'
        }
        query['sortBy'] = productQuery['sortColumn']
        query['orderBy'] = productQuery['sortDirection']
        return { query, serviceContractId }
    },
    fetchCoveredProducts(serviceContractId) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            args: (getState) => actions.getFetchQuery(getState, serviceContractId, 'coveredProductQuery'),
            apiFunction: 'getCoveredProducts',
            onSuccess: ({ data }) => ({ type: constants.FETCH_COVERED_PRODUCTS_SUCCESS, data, serviceContractId }),
            onFailure: (error) => ({ type: constants.FETCH_COVERED_PRODUCTS_FAILURE, error: error })
        })
    },
    setCoveredProductQuery(queryName, queryValue) {
        return async (dispatch) => {
            dispatch({ type: constants.SET_COVERED_PRODUCT_QUERY, queryName, queryValue })
            await dispatch(actions.fetchCoveredProducts())
        }
    },
    fetchConsumables(serviceContractId) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            args: { serviceContractId },
            apiFunction: 'getConsumables',
            onSuccess: ({ data }) => ({ type: constants.FETCH_CONSUMABLES_SUCCESS, data }),
            onFailure: (error) => ({ type: constants.FETCH_CONSUMABLES_FAILURE, error: error })
        })
    },
    fetchOptionsIncluded(serviceContractId) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            args: (getState) => actions.getFetchQuery(getState, serviceContractId, 'optionsIncludedQuery'),
            apiFunction: 'getOptionsIncluded',
            onSuccess: ({ data }) => ({ type: constants.FETCH_OPTIONS_INCLUDED_SUCCESS, data, serviceContractId }),
            onFailure: (error) => ({ type: constants.FETCH_OPTIONS_INCLUDED_FAILURE, error: error })
        })
    },
    setOptionsIncludedQuery(queryName, queryValue) {
        return async (dispatch) => {
            dispatch({ type: constants.SET_OPTIONS_INCLUDED_QUERY, queryName, queryValue })
            await dispatch(actions.fetchOptionsIncluded())
        }
    },
    setUpdatePlanQuery(queryName, queryValue) {
        return async (dispatch) => {
            dispatch({ type: constants.SET_UPDATE_PLAN_QUERY, queryName, queryValue })
            await dispatch(actions.fetchUpdatePlan())
        }
    },
    fetchUpdatePlan(serviceContractId) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            args: (getState) => actions.getFetchQueryUpdate(getState, serviceContractId, 'updatePlanQuery'),
            apiFunction: 'getCoveredProducts',
            onSuccess: ({ data }) => ({ type: constants.FETCH_UPDATE_PLAN_LIST_SUCCESS, data }),
            onFailure: (error) => ({ type: constants.FETCH_UPDATE_PLAN_LIST_FAILURE, error: error })
        })
    },
    getServiceLevels(data) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            args: data,
            apiFunction: 'getServiceLevels',
            onSuccess: ({ data }) => ({ type: constants.FETCH_DESIRED_SERVICE_LEVELS_SUCCESS, data }),
            onFailure: (error) => ({ type: constants.FETCH_DESIRED_SERVICE_LEVELS_FAILURE, error: error })
        })
    },
    updateServiceLevels(coveredProductId, serviceLevel) {
        return (dispatch) => {
            dispatch({ type: constants.UPDATE_SERVICE_LEVELS, coveredProductId, serviceLevel })
        }
    },
    modifyPmVisitSelection(productsForPMVisit) {
        return (dispatch) => {
            dispatch({ type: constants.MODIFY_PMVISIT_PRODUCTS_SELECTION, productsForPMVisit })
        }
    },
    fetchUpdatePMVisit(serviceContractId) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            args: (getState) => actions.getFetchQueryUpdate(getState, serviceContractId, 'updatePMVisitQuery'),
            apiFunction: 'getCoveredProducts',
            onSuccess: ({ data }) => ({ type: constants.FETCH_UPDATE_PM_VISIT_LIST_SUCCESS, data }),
            onFailure: (error) => ({ type: constants.FETCH_UPDATE_PM_VISIT_LIST_FAILURE, error: error })
        })
    },
    setUpdatePMVisitQuery(queryName, queryValue) {
        return async (dispatch) => {
            dispatch({ type: constants.SET_UPDATE_PM_VISIT_QUERY, queryName, queryValue })
            await dispatch(actions.fetchUpdatePMVisit())
        }
    },
}

// Reducer
export const initialState = fromJS({
    fetching: false,
    contractDetails: {},
    coveredProductQuery: {
        sortColumn: 'installationDate',
        sortDirection: 'desc',
        currentPage: 1,
        perPage: 10,
        totalRecords: 0
    },
    selectedContractId: '',
    optionsIncludedQuery: {
        sortColumn: '',
        sortDirection: '',
        currentPage: 1,
        perPage: 10,
        totalRecords: 0
    },
    optionsIncluded: {},
    consumables: [],
    updatePlanQuery: {
        sortColumn: 'deviceName',
        sortDirection: 'asc',
    },
    updatePlanProducts: {},
    desiredServiceLevels: [],
    clonedUpdateProducts: [],
    selectedProductsForPMVisit: [],
    updatePmVisitProducts: {},
    updatePMVisitQuery: {
        sortColumn: 'deviceName',
        sortDirection: 'asc',
    },
})

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.FETCH:
            return state.set('fetching', true)
        case constants.FETCH_COVERED_PRODUCTS_SUCCESS:
            return state
                .set('fetching', false)
                .set('contractDetails', fromJS(action.data))
                .setIn(['coveredProductQuery', 'totalRecords'], action?.data?.totalRecords)
                .set('selectedContractId', action?.serviceContractId || state.get('selectedContractId'))
        case constants.FETCH_COVERED_PRODUCTS_FAILURE:
            return state
                .set('fetching', false)
                .set('contractDetails', fromJS({}))
        case constants.SET_COVERED_PRODUCT_QUERY:
            const coveredProductQuery = state.get('coveredProductQuery')?.toJS()
            if (action?.queryName === 'sortColumn') {
                if (coveredProductQuery['sortColumn'] === action?.queryValue)
                    coveredProductQuery['sortDirection'] = coveredProductQuery['sortDirection'] === 'asc' ? 'desc' : 'asc'
                else coveredProductQuery['sortDirection'] = 'asc'
            }
            coveredProductQuery[action?.queryName] = action?.queryValue
            return state
                .set('coveredProductQuery', fromJS(coveredProductQuery))
        case constants.FETCH_OPTIONS_INCLUDED_SUCCESS:
            return state
                .set('fetching', false)
                .set('optionsIncluded', fromJS(action.data))
                .setIn(['optionsIncludedQuery', 'totalRecords'], action?.data?.totalRecords)
        case constants.FETCH_OPTIONS_INCLUDED_FAILURE:
            return state
                .set('fetching', false)
                .set('optionsIncludedQuery', fromJS({}))
        case constants.SET_OPTIONS_INCLUDED_QUERY:
            const optionsIncludedQuery = state.get('optionsIncludedQuery')?.toJS()
            if (action?.queryName === 'sortColumn') {
                if (optionsIncludedQuery['sortColumn'] === action?.queryValue)
                    optionsIncludedQuery['sortDirection'] = optionsIncludedQuery['sortDirection'] === 'asc' ? 'desc' : 'asc'
                else optionsIncludedQuery['sortDirection'] = 'asc'
            }
            optionsIncludedQuery[action?.queryName] = action?.queryValue
            return state
                .set('optionsIncludedQuery', fromJS(optionsIncludedQuery))
        case constants.FETCH_CONSUMABLES_SUCCESS:
            return state
                .set('consumables', action?.data)
                .set('fetching', false)
        case constants.FETCH_CONSUMABLES_FAILURE:
            return state
                .set('fetching', false)
                .set('consumables', [])
        case constants.SET_UPDATE_PLAN_QUERY:
            const updatePlanQuery = state.get('updatePlanQuery')?.toJS()
            if (action?.queryName === 'sortColumn') {
                if (updatePlanQuery['sortColumn'] === action?.queryValue)
                    updatePlanQuery['sortDirection'] = updatePlanQuery['sortDirection'] === 'asc' ? 'desc' : 'asc'
                else updatePlanQuery['sortDirection'] = 'asc'
            }
            updatePlanQuery[action?.queryName] = action?.queryValue
            return state
                .set('updatePlanQuery', fromJS(updatePlanQuery))
        case constants.FETCH_UPDATE_PLAN_LIST_SUCCESS:
            return state
                .set('fetching', false)
                .set('updatePlanProducts', fromJS(action.data))
                .set('clonedUpdateProducts', fromJS(action.data?.coveredProducts))
                .setIn(['updatePlanQuery', 'totalRecords'], action?.data?.totalRecords)
                .set('selectedContractId', action?.serviceContractId || state.get('selectedContractId'))
        case constants.FETCH_UPDATE_PLAN_LIST_FAILURE:
            return state
                .set('fetching', false)
        case constants.FETCH_DESIRED_SERVICE_LEVELS_SUCCESS:
            return state
                .set('fetching', false)
                .set('desiredServiceLevels', fromJS(action.data))
        case constants.FETCH_DESIRED_SERVICE_LEVELS_FAILURE:
            return state
                .set('fetching', false)
        case constants.UPDATE_SERVICE_LEVELS:
            const clonedProducts = state.get('clonedUpdateProducts')?.toJS()
            const updatedRecord = clonedProducts.map((el) => {
                const record = {
                    ...el,
                    serviceLevel: el.coveredProductId === action.coveredProductId ? action.serviceLevel : el.serviceLevel
                }
                return record
            })
            return state
                .set('clonedUpdateProducts', fromJS(updatedRecord))
        case constants.MODIFY_PMVISIT_PRODUCTS_SELECTION:
            return state
                .set('selectedProductsForPMVisit', fromJS(action.productsForPMVisit))
        case constants.FETCH_UPDATE_PM_VISIT_LIST_SUCCESS:
            return state
                .set('updatePmVisitProducts', fromJS(action?.data))
                .set('fetching', false)
        case constants.FETCH_UPDATE_PM_VISIT_LIST_FAILURE:
            return state
                .set('fetching', false)
        case constants.SET_UPDATE_PM_VISIT_QUERY:
            const updatePMVisitQuery = state.get('updatePMVisitQuery')?.toJS()
            if (action?.queryName === 'sortColumn') {
                if (updatePMVisitQuery['sortColumn'] === action?.queryValue)
                    updatePMVisitQuery['sortDirection'] = updatePMVisitQuery['sortDirection'] === 'asc' ? 'desc' : 'asc'
                else updatePMVisitQuery['sortDirection'] = 'asc'
            }
            updatePMVisitQuery[action?.queryName] = action?.queryValue
            return state
                .set('coveredProductQuery', fromJS(coveredProductQuery))
        default:
            return state
    }
}

// Selectors
const getState = (state) => state.serviceContractDetail

export const selectors = {
    fetching: createSelector(getState, (state) => {
        return state.get('fetching')
    }),
    contractDetails: createSelector(getState, (state) => {
        return state.get('contractDetails')
    }),
    coveredProductQuery: createSelector(getState, (state) => {
        return state.get('coveredProductQuery')
    }),
    consumables: createSelector(getState, (state) => {
        return state.get('consumables')
    }),
    optionsIncludedQuery: createSelector(getState, (state) => {
        return state.get('optionsIncludedQuery')
    }),
    optionsIncluded: createSelector(getState, (state) => {
        return state.get('optionsIncluded')
    }),
    updatePlanProducts: createSelector(getState, (state) => {
        return state.get('updatePlanProducts')
    }),
    updatePlanQuery: createSelector(getState, (state) => {
        return state.get('updatePlanQuery')
    }),
    desiredServiceLevels: createSelector(getState, (state) => {
        return state.get('desiredServiceLevels')
    }),
    clonedUpdateProducts: createSelector(getState, (state) => {
        return state.get('clonedUpdateProducts')
    }),
    selectedProductsForPMVisit: createSelector(getState, (state) => {
        return state.get('selectedProductsForPMVisit')
    }),
    updatePmVisitProducts: createSelector(getState, (state) => {
        return state.get('updatePmVisitProducts')
    }),
    updatePMVisitQuery: createSelector(getState, (state) => {
        return state.get('updatePMVisitQuery')
    })
}