import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import { FormattedMessage } from 'react-intl'
import Confirmation from 'components/Confirmation/Confirmation'
import Table from 'components/Table/TableList'
import Pagination from 'components/Pagination/Pagination'
import GenericHeader from 'components/GenericListing/GenericHeader'
import classNames from 'classnames/bind'
import style from './GenericListing.scss'
import injectOverlay from 'shared-components/utils/injectOverlay'
import { injectIntl } from 'react-intl'
import TablePagination from 'components/EMDPagination/TablePagination'
import UnAuthorized from 'components/UnAuthorized/UnAuthorized'
import { Button } from '@liquid-design/liquid-design-react'

const cx = classNames.bind(style)

const propTypes = {
  fetching: PropTypes.bool,
  fetchError: PropTypes.object,
  data: PropTypes.object,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.bool,
  filterBy: PropTypes.array,
  updateSearchTerm: PropTypes.func,
  handleUserFilters: PropTypes.func,
  categories: PropTypes.array,
  handleSort: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
  setCurrentPage: PropTypes.func,
  refreshPage: PropTypes.func,
  permission: PropTypes.object,
  selectedFilterValues: PropTypes.array,
  showFilters: PropTypes.bool,
  headers: PropTypes.array,
  columnWidths: PropTypes.array,
  showSearch: PropTypes.bool,
  removeItem: PropTypes.func,
  openModal: PropTypes.func,
  close: PropTypes.func,
  additionalFilterProps: PropTypes.object,
  additionalTableProps: PropTypes.object,
  searchPlaceholder: PropTypes.string,
  paginationName: PropTypes.string,
  pagination: PropTypes.object,
  resetPage: PropTypes.func,
  addfilterCatogory: PropTypes.func,
  loadList: PropTypes.func,
  addQueryParams: PropTypes.func,
  selectedQueryParams: PropTypes.object,
  listingName: PropTypes.string,
  Table: PropTypes.func,
  searchTerm: PropTypes.string,
  handleSearch: PropTypes.func,
  headersTitle: PropTypes.string,
  customizeRow: PropTypes.bool,
  customizeRowData: PropTypes.object,
  filterCategories: PropTypes.array,
  title: PropTypes.string,
  headersTable: PropTypes.string,
  handleApplyFilter: PropTypes.func,
  additionalElement: PropTypes.node,
  intl: PropTypes.object,
  handleCurrentPageChange: PropTypes.func,
  handlePerPageLimit: PropTypes.func,
  perPage: PropTypes.func,
  isDisplayPagination: PropTypes.bool
}
// isDisplayPagination is added to hide pagination on customerDevice if user is serach or select filter customer name then we have to enable pagination(CustomerDevices : 243)
const defaultProps = {
  Table: Table,
  isDisplayPagination: true
}

class GenericListing extends PureComponent {
  handleDelete = (args, deleteMsg, additionalVal) => {
    const {
      openModal,
      removeItem,
      loadList,
      intl,
      close
    } = this.props
    let isInitialClick = true
    openModal(
      <Confirmation
        intl={intl}
        confirmationText={<FormattedMessage id={`${deleteMsg || 'next.GENERIC_DELETE_CONFIRMATION'}`} />}
        onYesClick={async () => {
          // Check to avoid multiple delete of same row
          if (isInitialClick) {
            isInitialClick = !isInitialClick
            close()
            await removeItem(args, additionalVal)
            await loadList()
          }
        }}
        onNoClick={close} />
    )
  }

  displayPagesPerList = () => {
    const { additionalTableProps } = this.props
    return additionalTableProps && additionalTableProps.isCompactView ? [5, 10, 25, 50] : [10, 25, 50]
  }

  getPagesSum = () => {
    const { totalRecords, perPage, totalPages } = this.props
    const itemsCount = totalRecords > 0 ? totalRecords : (totalPages * perPage || 0)
    return Math.ceil(itemsCount / 10)
  }


  getPageInBounds = pageNum => {
    this.setState({
      test: 1
    })
    return Math.min(Math.max(1, pageNum), this.getPagesSum())
  }


  render() {
    const {
      fetching,
      data,
      sortDirection,
      sortColumn,
      updateSearchTerm,
      handleUserFilters,
      categories,
      handleSort,
      loadList,
      fetchError,
      filterBy,
      totalPages,
      totalRecords,
      setCurrentPage,
      refreshPage,
      permission,
      selectedFilterValues,
      showFilters,
      headers,
      columnWidths,
      showSearch,
      additionalFilterProps,
      additionalTableProps,
      searchPlaceholder,
      paginationName,
      pagination,
      resetPage,
      addfilterCatogory,
      addQueryParams,
      selectedQueryParams,
      listingName,
      Table,
      searchTerm,
      handleSearch,
      filterCategories,
      title,
      headersTable,
      handleApplyFilter,
      customizeRow,
      customizeRowData,
      additionalElement,
      handleCurrentPageChange,
      handlePerPageLimit,
      perPage,
      isDisplayPagination,
      ...restProps
    } = this.props
    const hasPagination = typeof paginationName === 'string'
    let pageName
    let currentPageValue
    // let showNext

    if (hasPagination) {
      pageName = pagination && pagination[paginationName]
      currentPageValue = (pageName && pageName.currentPage) || 1
      // showNext = currentPageValue < totalPages
    }
    return (
      <div className={cx('user-main-content')}>
        {!fetchError?.status
          && <GenericHeader
            {...additionalTableProps}
            {...additionalFilterProps}
            title={title}
            defaultSearchTerm={searchTerm}
            updateSearchTerm={updateSearchTerm}
            handleSearch={handleSearch}
            placeholder={searchPlaceholder}
            searchIconColor='primary'
            showSearch={showSearch}
            showFilters={showFilters}
            filterCategories={filterCategories}
            handleUserFilters={handleUserFilters}
            categories={categories}
            handleFilters={handleUserFilters}
            handleApplyFilter={handleApplyFilter}
            filterBy={filterBy}
            selectedFilterValues={selectedFilterValues}
            addfilterCatogory={addfilterCatogory}
            loadList={loadList}
            setCurrentPage={setCurrentPage}
            addQueryParams={addQueryParams}
            selectedQueryParams={selectedQueryParams}
            additionalElement={additionalElement}
            listingName={listingName}
          />}
        {fetchError
          ? fetchError.status
            ? <UnAuthorized id={`next.GENERIC_LISTING_${fetchError.status}_ERROR`} />
            : <Alert style={{ margin: '20px' }} message={<FormattedMessage id={'next.GENERIC_LISTING_ERROR'} />} type="error" showIcon />
          : <Table
            hasHeader
            data={customizeRow ? customizeRowData(data, this.handleDelete) : data}
            handleSort={handleSort}
            sortDirection={sortDirection}
            sortColumn={sortColumn}
            headers={headers}
            headersTitle={headersTable}
            fetching={fetching}
            handleDelete={this.handleDelete}
            refreshUserPage={refreshPage}
            permission={permission}
            columnWidths={columnWidths}
            totalPages={totalPages}
            perPage={perPage}
            {...additionalTableProps}
            {...restProps} />
        }
        {(window.innerWidth < 767 && totalPages > 1 && currentPageValue != totalPages) && (
          <>
            <Button
              style={{ marginTop: '2px', width: '100%', zIndex: '100' }}
              size='sm'
              color='muted'
              onClick={() => handleCurrentPageChange(this.getPageInBounds(currentPageValue + 1))}>
              <FormattedMessage id={'next.DEVICES_LOAD_MORE'} />
            </Button>
          </>
        )}
        {(window.innerWidth > 767 && hasPagination && totalPages > 0 && isDisplayPagination &&
          <TablePagination
            itemsPerPageOptions={this.displayPagesPerList()}
            itemsPerPageAmount={perPage}
            itemsCount={totalRecords > 0 ? totalRecords : (totalPages * perPage || 0)}
            currentPage={currentPageValue}
            onChange={handleCurrentPageChange}
            onItemsPerPageAmountChange={handlePerPageLimit}
            isDisplayedBelowTable
          />
        )}
        {(hasPagination && totalPages === null &&
          <Pagination
            paginationName={paginationName}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            fetchPage={loadList}
            currentPage={currentPageValue}
            resetPage={resetPage} />
        )}
      </div>
    )
  }
}

GenericListing.propTypes = propTypes
GenericListing.defaultProps = defaultProps

export default injectIntl(injectOverlay(GenericListing))
