import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { sizes, getTypeValues } from '../types'

import FormGroup from './FormGroup'

const propTypes = {
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  isFormGroup: PropTypes.bool,
  formGroupStyle: PropTypes.object,
  error: PropTypes.string,
  size: PropTypes.oneOf(getTypeValues(sizes)),
  inputStyle: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.node
  })
}

const defaultProps = {
  isFormGroup: true,
  rows: 5
}

const TextArea = (props) => {
  const {
    rows,
    placeholder,
    id,
    isFormGroup,
    formGroupStyle, // eslint-disable-line no-unused-vars
    size,
    inputStyle,
    className,
    description, // eslint-disable-line no-unused-vars
    input: inputProps,
    meta: { touched, error },
    ...otherProps
  } = props

  const showError = touched && error

  // Component Classes
  const componentClass = cx(
    'form-control',
    {
      [`form-control-${size}`]: size,
      'error': showError
    },
    className
  )

  // Base Component
  const textArea = (
    <div>
      <textarea
        className={componentClass}
        id={id}
        rows={rows}
        style={inputStyle}
        placeholder={placeholder}
        {...inputProps}
        {...otherProps}
      />
      {showError &&
        <div className={cx('form-error-message')}>{error}</div>
      }
    </div>
  )

  // If Form Group Return Base Component Wrapped in Form Group Element
  if (isFormGroup) {
    return (
      <FormGroup {...props}>
        {textArea}
      </FormGroup>
    )
  }

  // Return Base Component
  return textArea
}

TextArea.propTypes = propTypes
TextArea.defaultProps = defaultProps

export default TextArea
