import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, useIntl } from 'react-intl'

const propTypes = {
  id: PropTypes.string,
  fallback: PropTypes.string,
  intl: useIntl.isRequired
}

const getMessage = (props) => {
  const { intl, id, values = {}, fallback = 'next.UNEXPECTED_ERROR' } = props
  let message = intl.formatMessage({id}, values)
  return (message === id && id !== fallback)
    ? getMessage({ intl, id: fallback, values })
    : message
}

const Localize = (props) => {
  return (<span>
    {
      getMessage(props)
    }
  </span>)
}

Localize.propTypes = propTypes

export default injectIntl(Localize)
