import { createSelector } from 'reselect'
import { fromJS, Map } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  getDateFormat,
  formatDeviceTypesForDropdown,
  getAnalyticData,
  formatDataTypesForDropdown
} from 'store/utils/cardsCreationUtils'

// Constants
export const constants = {
  FETCH: 'MANAGE_CARD/FETCH',
  FETCH_FAILURE: 'MANAGE_CARD/FETCH_FAILURE',
  RECEIVE_DEVICE_TYPES: 'MANAGE_CARD/RECEIVE_DEVICE_TYPES',
  RECEIVE_ANALYTIC_DATA: 'MANAGE_CARD/RECEIVE_ANALYTIC_DATA',
  FETCH_ANALYTIC_DATA_FAILURE: 'MANAGE_CARD/FETCH_ANALYTIC_DATA_FAILURE',
  CREATE_CARD_SUCCESS: 'MANAGE_CARD/CREATE_CARD_SUCCESS',
  RECEIVE_METRIC_SEARCH_RESULTS: 'MANAGE_CARD/RECEIVE_METRIC_SEARCH_RESULTS',
  RECEIVE_DATA_TYPES: 'MANAGE_CARD/RECEIVE_DATA_TYPES',
  REMOVE_ANALYTIC_DATA: 'MANAGE_CARD/REMOVE_ANALYTIC_DATA',
  SELECT_METRIC: 'MANAGE_CARD/SELECT_METRIC',
  DESELECT_METRIC: 'MANAGE_CARD/DESELECT_METRIC',
  CLEAR_STORE_VALUES: 'MANAGE_CARD/CLEAR_STORE_VALUES'
}

// Action Creators
export const actions = {
  fetchDeviceTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getDeviceTypes',
      onSuccess: ({data: deviceTypes}) => ({
        type: constants.RECEIVE_DEVICE_TYPES,
        deviceTypes
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.toString()
      })
    })
  },
  createCard(card) {
    const dimensions = card.dimensions.map(({id, type, filter = {}, format}) => {
      return {
        id,
        type,
        filter,
        ...getDateFormat(format, type)
      }
    })

    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'createCard',
      args: { ...card, dimensions },
      onSuccess: () => ({
        type: constants.CREATE_CARD_SUCCESS
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.toString()
      })
    })
  },
  fetchAnalyticData(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchAnalyticData',
      args: query,
      onSuccess: ({data: analyticData}) => ({
        type: constants.RECEIVE_ANALYTIC_DATA,
        analyticData
      }),
      onFailure: (error) => ({
        type: constants.FETCH_ANALYTIC_DATA_FAILURE,
        error
      })
    })
  },
  fetchSelectedAnalyticData(args) {
    const {
      dataType, deviceTypeId, selectedMeasures, selectedDimensions
    } = args

    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchAnalyticData',
      args: { dataType, deviceTypeId },
      onSuccess: ({ data: { measure, dimension } }) => {
        return {
          type: constants.RECEIVE_ANALYTIC_DATA,
          analyticData: {
            'measure': filterDataById(selectedMeasures, measure),
            'dimension': filterDataById(selectedDimensions, dimension)
          }
        }
      },
      onFailure: (error) => ({
        type: constants.FETCH_ANALYTIC_DATA_FAILURE,
        error
      })
    })
  },
  searchMetric({metric, name, ...searchParams}) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'searchMetric',
      args: {metric, name, query: searchParams},
      onSuccess: ({data: metrics}) => ({
        type: constants.RECEIVE_METRIC_SEARCH_RESULTS,
        metrics
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.toString()
      })
    })
  },
  fetchDataTypes(deviceTypeId, operation = 'create') {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDataTypes',
      args: {
        deviceTypeId,
        operation
      },
      onSuccess: ({data: dataTypes}) => ({
        type: constants.RECEIVE_DATA_TYPES,
        dataTypes
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.toString()
      })
    })
  },
  selectMetric (metricId, metricType, metricDataType) {
    return async(dispatch, getState) => {
      await dispatch(actions.deSelectMetric())
      dispatch({
        type: constants.SELECT_METRIC,
        data: {
          metricId,
          metricType,
          metricDataType
        }
      })
    }
  },
  deSelectMetric () {
    return {
      type: constants.DESELECT_METRIC
    }
  },
  removeAnalyticData() {
    return {
      type: constants.REMOVE_ANALYTIC_DATA
    }
  },
  clearStoreValues () {
    return {
      type: constants.CLEAR_STORE_VALUES
    }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  fetchAnalyticDataError: {},
  deviceTypes: [],
  analyticData: {},
  dataTypes: [],
  selectedMetric: {},
  searchFilterResults: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.RECEIVE_DEVICE_TYPES:
      return state
        .set('fetching', false)
        .set('deviceTypes', formatDeviceTypesForDropdown(action.deviceTypes))
    case constants.RECEIVE_ANALYTIC_DATA:
      return state
        .set('fetching', false)
        .set('fetchAnalyticDataError', Map())
        .set('analyticData', getAnalyticData(action.analyticData))
    case constants.FETCH_ANALYTIC_DATA_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchAnalyticDataError', Map(action.error))
    case constants.CREATE_CARD_SUCCESS:
      return state
        .set('fetching', false)
    case constants.SELECT_METRIC:
      return state
        .set('selectedMetric', fromJS(action.data))
    case constants.DESELECT_METRIC:
      return state
        .set('selectedMetric', Map())
    case constants.RECEIVE_METRIC_SEARCH_RESULTS:
      return state
        .set('fetching', false)
        .set('fetchError', null)
        .set('searchFilterResults', fromJS(action.metrics))
    case constants.RECEIVE_DATA_TYPES:
      return state
        .set('dataTypes', formatDataTypesForDropdown(action.dataTypes))
        .set('fetching', false)
        .set('fetchAnalyticDataError', Map())
    case '@@redux-form/DESTROY':
      return state
        .set('searchFilterResults', fromJS([]))
    case constants.REMOVE_ANALYTIC_DATA:
      return state
        .set('analyticData', fromJS({}))
    case constants.CLEAR_STORE_VALUES:
      return state
        .set('analyticData', fromJS({}))
        .set('fetchAnalyticDataError', Map())
        .set('deviceTypes', fromJS([]))
        .set('dataTypes', fromJS([]))
        .set('selectedMetric', Map())
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.manageCard

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  fetchAnalyticDataError: createSelector(getState, (state) =>
    state.get('fetchAnalyticDataError')
  ),
  deviceTypes: createSelector(getState, (state) =>
    state.get('deviceTypes')
  ),
  analyticData: createSelector(getState, (state) =>
    state.get('analyticData')
  ),
  dataTypes: createSelector(getState, (state) =>
    state.get('dataTypes')
  ),
  searchFilterResults: createSelector(getState, (state) =>
    state.get('searchFilterResults')
  ),
  selectedMetric: createSelector(getState, (state) =>
    state.get('selectedMetric')
  )
}

// Helper methods

/**
  creates new array with elements with id matches reference
  array from data

  @param {array} reference
  @param {array}  data
  @return {array}
*/
function filterDataById(reference, data) {
  return reference
    .map(({ id: _id }) => {
      return data.find(({ id }) => {
        return _id === id
      })
    })
}
