import React from 'react'
import { Button } from '@liquid-design/liquid-design-react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import './AddButtonInHeader.scss'

const propTypes = { 
  handleOnClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  iconName: PropTypes.element,
  message: PropTypes.string,
  buttonId: PropTypes.string
}
const AddButtonInHeader = ({
  handleOnClick,
  className,
  style,
  iconName,
  message,
  buttonId,
  ...resProps
}) => {
  return (
    <Button
      onClick={handleOnClick}
      id={buttonId}
      className={`add-button ${className ? className : ''}`}
      style={{ ...style }}
      {...resProps}
    >
          {iconName}
        <div className="btn-text-message" id={buttonId}>{message && <FormattedMessage id={`next.${message}`} />}</div>
    </Button>
  )
}

AddButtonInHeader.propTypes = propTypes
export default AddButtonInHeader
