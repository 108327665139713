import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {getIcon} from 'utils/iconUtils'
import './Loader.scss'

class Loader extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(['sm', '', 'lg']),
    customClass: PropTypes.string
  }

  static defaultProps = {
    size: '',
    timer: false
  }

  render() {
    const { customClass } = this.props
    return (
      <div className={`loader-container ${customClass || ''}`}>
        <div className="row">
          <div className="inner">
            <div className="pulse-icon">
              <div className="icon-wrap">
                <img alt='' src={getIcon('logo_merck_loader')} width="60" />
              </div>
              <div className="elements">
                <div className="circle circle-outer" />
                <div className="circle circle-inner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Loader
