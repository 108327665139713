export const moduleId = 'devices'
export const deviceTypes = {
  LABWATER: 'labwater',
  LABWATERID: 2,
  USERMANUALID: 2
}

export const certificateTypes = {
  CA_CHAIN: 'cachain',
  DEVICE: 'devicecertificate'
}

export const addPartnerPeripheralFormName = 'addPartnerPeripheral'

export const partnerPeripheralRelationTypes = {
  feed: 'Feed',
  source: 'Source',
  primary: 'Primary',
  secondary: 'Secondary'
}

export const deviceConnectivity = {
  connected: 'connected',
  validatedSystem: 'smRegulatedEnvironment'
}

export const partnerPeripheralSuppliers = {
  'MILLI_Q': 5
}
export const filterTypes = {
  'CUSTOMER_NAME': 'customerName',
  'DEVICE_NAME': 'deviceName',
  'DEVICE_TYPE': 'deviceType',
  'DEVICE_LOCATION': 'deviceLocation',
  'LOCATION_NAME': 'locationName',
  'CONNECTED_STATUS': 'connectedStatus',
  'INSTALLED_DATE': 'installedDate',
  'COUNTRY_CODE': 'countryCode',
  'ORGANIZATION_NAME': 'disOrgName'
}
export const filterLabels = {
  'DEVICE_CUSTOMER_NAME': 'devicelistingcustomerName'
}

export const iotCapabilityLabels = {
  'REGISTER_TO_IOT': 'REGISTER_TO_IOT',
  'UPLOAD_CSR': 'UPLOAD_CSR',
  'INPROGRESS': 'INPROGRESS',
  'DOWNLOAD_CRT': 'DOWNLOAD_CRT',
  'COMPLETED': 'COMPLETED',
}

export const dateFormat = 'YYYYMMDD'
