import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH_CHART_DATA: 'REMOTE_CONTROL/FETCH_CHART_DATA',
  RECEIVE_CHART_DATA: 'REMOTE_CONTROL/RECEIVE_CHART_DATA',
  CHART_FAILURE: 'REMOTE_CONTROL/CHART_FAILURE',
  FETCH_DURATION_DATA: 'REMOTE_CONTROL/FETCH_DURATION_DATA',
  RECEIVE_DURATION_DATA: 'REMOTE_CONTROL/RECEIVE_DURATION_DATA',
  DURATION_DATA_FAILURE: 'REMOTE_CONTROL/DURATION_DATA_FAILURE',
  FETCH_FSE_DATA: 'REMOTE_CONTROL/FETCH_FSE_DATA',
  RECEIVE_FSE_DATA: 'REMOTE_CONTROL/RECEIVE_FSE_DATA',
  FSE_DATA_FAILURE: 'REMOTE_CONTROL/FSE_DATA_FAILURE',
}



// Action Creators
export const actions = {

  getRemoteControlChartData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_CHART_DATA }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_CHART_DATA, data }),
      onFailure: (error) => ({ type: constants.CHART_FAILURE, error: error.toString() })
    })
  },
  getRemoteControlDurationData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_DURATION_DATA }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_DURATION_DATA, data }),
      onFailure: (error) => ({ type: constants.DURATION_DATA_FAILURE, error: error.toString() })
    })
  },
  getFsePerformanceData(payload) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FSE_DATA }),
      apiFunction: 'getRemoteControlChartData',
      args: payload,
      onSuccess: (data) => ({ type: constants.RECEIVE_FSE_DATA, data }),
      onFailure: (error) => ({ type: constants.FSE_DATA_FAILURE, error: error.toString() })
    })
  },
}
// Reducer
export const initialState = fromJS({
  fetchingChartData: false,
  fetchChartError: null,
  remoteControlChartData: null,
  fetchingDurationData: false,
  fetchDurationError: null,
  remoteControlDurationData: null,
  fsePerformanceData: null,
  fetchingFseData: false,
  fetchFseError: null,
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_CHART_DATA:
      return state
        .set('fetchingChartData', true)
        .set('fetchChartError', null)

    case constants.RECEIVE_CHART_DATA:
      return state
        .set('fetchingChartData', false)
        .set('remoteControlChartData', action.data.data)

    case constants.CHART_FAILURE:
      return state
        .set('fetchChartError', action.error)
        .set('fetchingChartData', false)

    case constants.FETCH_DURATION_DATA:
      return state
        .set('fetchingDurationData', true)
        .set('fetchDurationError', null)

    case constants.RECEIVE_DURATION_DATA:
      return state
        .set('fetchingDurationData', false)
        .set('remoteControlDurationData', action.data.data)

    case constants.DURATION_DATA_FAILURE:
      return state
        .set('fetchDurationError', action.error)
        .set('fetchingDurationData', false)

    case constants.FETCH_FSE_DATA:
      return state
        .set('fetchingFseData', true)
        .set('fetchFseError', null)

    case constants.RECEIVE_FSE_DATA:
      return state
        .set('fetchingFseData', false)
        .set('fsePerformanceData', action.data.data)

    case constants.FSE_DATA_FAILURE:
      return state
        .set('fetchFseError', action.error)
        .set('fetchingFseData', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.remoteControlAnalysis

export const selectors = {
  fetchingChartData: createSelector(getState, (state) =>
    state.get('fetchingChartData')
  ),
  fetchChartError: createSelector(getState, (state) =>
    state.get('fetchChartError')
  ),
  remoteControlChartData: createSelector(getState, (state) =>
    state.get('remoteControlChartData')
  ),
  fetchingDurationData: createSelector(getState, (state) =>
    state.get('fetchingDurationData')
  ),
  fetchDurationError: createSelector(getState, (state) =>
    state.get('fetchDurationError')
  ),
  remoteControlDurationData: createSelector(getState, (state) =>
    state.get('remoteControlDurationData')
  ),
  fetchingFseData: createSelector(getState, (state) =>
    state.get('fetchingFseData')
  ),
  fsePerformanceData: createSelector(getState, (state) =>
    state.get('fsePerformanceData')
  ),
  fetchFseError: createSelector(getState, (state) =>
    state.get('fetchFseError')
  ),
}
