import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
const AddIconSvg = (props) => {
  const { className, width, height, style } = props
  return (
    <svg
      className={className}
      width={`${width}`}
      height={`${height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
    >
      <defs> </defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            style={{fill:'#fff', ...style}}
            d='M13,5.5H8.5V1a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1V5.5H1a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H5.5V13a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V8.5H13a1,1,0,0,0,1-1v-1A1,1,0,0,0,13,5.5Z'
          />
        </g>
      </g>
    </svg>
  )
}
AddIconSvg.propTypes = propTypes
AddIconSvg.defaultProps = {
  width: '18',
  height: '18',
}
export default AddIconSvg
