import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
const HomeSvg = (props) => {
  const { className } = props
  return (
    <svg
      className={className}
      width={`${props.width}`}
      height={`${props.height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
    >
      <defs></defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            style={{ fill: '#afafaf', ...props.style }}
            d='M19.46,8.7h0L11.3.54a1.83,1.83,0,0,0-2.6,0L.54,8.69h0a1.85,1.85,0,0,0,1.23,3.14h.38v6A2.16,2.16,0,0,0,4.3,20H7.49a.58.58,0,0,0,.58-.59v-4.7a1,1,0,0,1,1-1h1.88a1,1,0,0,1,1,1v4.7a.59.59,0,0,0,.59.59H15.7a2.16,2.16,0,0,0,2.16-2.15v-6h.3a1.84,1.84,0,0,0,1.3-3.14Zm-.83,1.77a.65.65,0,0,1-.47.2h-.89a.59.59,0,0,0-.59.59v6.59a1,1,0,0,1-1,1H13.1V14.71a2.16,2.16,0,0,0-2.16-2.16H9.06A2.16,2.16,0,0,0,6.9,14.71v4.12H4.3a1,1,0,0,1-1-1V11.26a.58.58,0,0,0-.58-.59h-.9a.61.61,0,0,1-.46-.2.66.66,0,0,1,0-.94h0L9.53,1.37a.65.65,0,0,1,.94,0l8.16,8.15h0A.66.66,0,0,1,18.63,10.47Z'
          />
        </g>
      </g>
    </svg>
  )
}
HomeSvg.defaultProps= {
  width: '20',
  height: '20'
}
HomeSvg.propTypes = propTypes
export default HomeSvg
