import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Icon.scss'

const cx = classNames.bind(styles)

const getIcon = (icon) => {
  try {
    return require(`../../static/icons/svg/${icon}.svg`)
  } catch (error) {
    console.log(error)
  }
}

const SIZES = ['sm', '', 'lg', 'toolbar']
const COLORS = [
  'primary',
  'muted',
  'info',
  'success',
  'warning',
  'danger',
  'action',
  'black',
  'white',
  'success-dark',
  'gray-light'
]

const propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  exactSize: PropTypes.number,
  color: PropTypes.oneOf(COLORS),
  hasAction: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  isPositioned: PropTypes.bool,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  className: PropTypes.string
}

const Icon = ({
  icon,
  size,
  exactSize,
  color,
  active,
  disabled,
  hasAction,
  isPositioned,
  top,
  left,
  right,
  bottom,
  className,
  ...iconProps
}) => {
  const sizeClass = size && `icon-${size}`
  const colorClass = color && `icon-${color}`
  const iconOptions = {
    'has-action': hasAction,
    'has-positioning': isPositioned,
    'active': active,
    'disabled': disabled
  }
  const iconStyle = {
    height: exactSize,
    width: exactSize,
    top,
    right,
    bottom,
    left
  }
  return (
    <svg
      viewBox="0 0 50 50"
      style={iconStyle}
      className={cx('icon', `icon-${icon}`, className, sizeClass, colorClass, iconOptions)}
      {...iconProps}
    >
      <defs> </defs>
      <g
        viewBox="0 0 50 50"
        dangerouslySetInnerHTML={{ __html: getIcon(icon) }}
      />
    </svg>
  )
}

Icon.propTypes = propTypes

export default Icon
