import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { filterTypes as feedbackDefaultersilterTypes } from 'constants/feedbackDefaulters'

// Constants
export const constants = {
  FETCH: 'FEEDBACK_DEFAULTERS/FETCH',
  FETCH_FAILURE: 'FEEDBACK_DEFAULTERS/FETCH_FAILURE',
  FETCH_FILTER_FAILURE: 'FEEDBACK_DEFAULTERS/FETCH_FILTER_FAILURE',
  FETCH_FILTER_TYPES: 'FEEDBACK_DEFAULTERS/FETCH_FILTER_TYPES',
  RECEIVE_FEEDBACK_DEFAULT_FILTERS: 'FEEDBACK_DEFAULTERS/RECEIVE_FILTER_TYPES'
}

// Action Creators
export const actions = {
  feedbackDefaulterFilters () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTER_TYPES }),
      apiFunction: 'feedbackDefaulterFilters',
      onSuccess: ({data}) => ({ type: constants.RECEIVE_FEEDBACK_DEFAULT_FILTERS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FEEDBACK_DEFAULT_FILTER_FAILURE, error })
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  showDeviceTypeFields: false,
  fetchFilterError: null,
  feedBackDefaultfilterCategories: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('downloadError', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.FETCH_FILTER_TYPES:
      return state
        .set('fetchingFilters', true)
        .set('fetching', false)
        .set('downloadError', null)
    case constants.RECEIVE_FEEDBACK_DEFAULT_FILTERS:
      return state
        .set('fetchingFilters', false)
        .set('fetching', false)
        .set('feedBackDefaultfilterCategories', formatFeedbackFilterCategories(action.data))
    case constants.FETCH_FILTER_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
        .set('fetchingFilters', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.feedbackDefaulters

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  feedBackDefaultfilterCategories: createSelector(getState, (state) => {
    return state.get('feedBackDefaultfilterCategories')
  }),
  fetchFilterError: createSelector(getState, (state) => {
    return state.get('fetchFilterError')
  }),
  fetchingFilters: createSelector(getState, (state) => {
    return state.get('fetchingFilters')
  })
}

// helper functions

function formatFeedbackFilterCategories (feedbackDefaultFilters) {
  let feedBackDefaultfilterCategories = []
  const filterOrder = [
    feedbackDefaultersilterTypes.COUNTRY_CODE,
    feedbackDefaultersilterTypes.CUSTOMER_ORGANIZATION,
    feedbackDefaultersilterTypes.ROLES,
    feedbackDefaultersilterTypes.SHOW_OLD_FEEDBACK
  ]
  filterOrder.forEach((filter) => {
    let isSearchBox = filter === feedbackDefaultersilterTypes.COUNTRY_CODE ||
    filter === feedbackDefaultersilterTypes.CUSTOMER_ORGANIZATION ||
    filter === feedbackDefaultersilterTypes.SHOW_OLD_FEEDBACK
    if (filter in feedbackDefaultFilters) {
      const values = feedbackDefaultFilters[filter]
      const categoryType = filter
      const categoryValues = !values
          ? []
          : values.map((val, index) => {
            return {
              label: val,
              value: isSearchBox ? val : `${filter}.${val}`,
              key: index,
              name: val
            }
          })
      feedBackDefaultfilterCategories.push({categoryType, categoryValues, searchBox: isSearchBox})
    }
  })
  return feedBackDefaultfilterCategories
}
