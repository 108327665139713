import React from 'react'
import PropTypes from 'prop-types'
import { Theme } from '@liquid-design/liquid-design-react'
import TranslatedContainer from 'containers/TranslatedContainer/TranslatedContainer'
import { GlobalOverlayProvider } from 'shared-components'
function CoreLayout ({ children}) {
  return (
      <Theme>
        <TranslatedContainer>
          <GlobalOverlayProvider wrapperStyle={{ height: '100%' }}>
            {children}
          </GlobalOverlayProvider>
        </TranslatedContainer>
      </Theme>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.element
}

export default CoreLayout

