import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { createSelector } from 'reselect'

// Constants
export const constants = {
  FETCH: 'SUBMIT_FORM/FETCH',
  SUBMIT_FORM_SUCCESS: 'SUBMIT_FORM/SUBMIT_FORM_SUCCESS',
  SUBMIT_FORM_FAILURE: 'SUBMIT_FORM/SUBMIT_FORM_FAILURE',
  RESET_EEROR_MESSAGE: 'SUBMIT_FORM/RESET_EEROR_MESSAGE',
  RESET_SUCCESS_STATUS: 'SUBMIT_FORM/RESET_SUCCESS_STATUS'
}
// Action Creators
export const actions = {
  submitForm(apiFunction, args, additionalValue, preventToken = false, callBackFunc) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: apiFunction,
      args: args,
      callBackFunc,
      throwError: true,
      onSuccess: (data) => ({ type: constants.SUBMIT_FORM_SUCCESS, additionalValue, data, args }),
      onFailure: (error) => ({
        type: constants.SUBMIT_FORM_FAILURE,
        error: error
      }),
      preventRetryAccessToken: preventToken
    })
  },
  resetSuccessStatus() {
    return {
      type: constants.RESET_SUCCESS_STATUS
    }
  },
  resetErrorMessage() {
    return {
      type: constants.RESET_EEROR_MESSAGE
    }
  }
}
export const initialState = fromJS({
  isSubmitted: false,
  isAddedSuccessfully: null,
  errorMessage: null,
  additionalValue: null,
  responseData: null,
  errorCode: null,
  errorStatus: null,
  fetchingStatus: null,
  formPayload: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('isSubmitted', true)
        .set('errorMessage', null)
        .set('errorCode', null)
        .set('errorStatus', null)
        .set('fetchingStatus', true)
        .set('isAddedSuccessfully', null)
        .set('formPayload', null) 
    case constants.SUBMIT_FORM_SUCCESS:
      return state
        .set('isSubmitted', false)
        .set('isAddedSuccessfully', 'success')
        .set('responseData', action.data)
        .set('additionalValue', action?.additionalValue) 
        .set('formPayload', action?.args) 
        .set('fetchingStatus', false)
    case constants.SUBMIT_FORM_FAILURE:
      return state
        .set('isSubmitted', false)
        .set('isAddedSuccessfully', null)
        .set('errorMessage', action.error.errorMessage)
        .set('errorCode', action.error.errorCode)
        .set('errorStatus', action.error.status)
        .set('fetchingStatus', false)
    case constants.RESET_EEROR_MESSAGE:
      return state
        .set('isSubmitted', null)
        .set('errorMessage', null)
        .set('errorCode', null)
        .set('isAddedSuccessfully', null)
        .set('responseData', null)
        .set('additionalValue', null)
        .set('errorStatus', null)
    case constants.RESET_SUCCESS_STATUS:
      return state
        .set('isAddedSuccessfully', null)
        .set('errorMessage', null)
        .set('errorCode', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.withSubmitForm

export const selectors = {
  isSubmitted: createSelector(getState, (state) => {
    return state.get('isSubmitted')
  }),
  isAddedSuccessfully: createSelector(getState, (state) => {
    return state.get('isAddedSuccessfully')
  }),
  errorMessage: createSelector(getState, (state) => {
    return state.get('errorMessage')
  }),
  responseData: createSelector(getState, (state) => {
    return state.get('responseData')
  }),
  additionalValue: createSelector(getState, (state) => {
    return state.get('additionalValue')
  }),
  errorCode: createSelector(getState, (state) => {
    return state.get('errorCode')
  }),
  errorStatus: createSelector(getState, (state) => {
    return state.get('errorStatus')
  }),
  fetchingStatus: createSelector(getState, (state) => {
    return state.get('fetchingStatus')
  }),
  formPayload: createSelector(getState, (state) => {
    return state.get('formPayload')
  })
}