import React from 'react'
import PropTypes from 'prop-types'
import ContentContainer from 'containers/ContentContainer/ContentContainer'

const DefaultLayout = (props) => {
  return (
    <ContentContainer {...props}>
      {props.children}
    </ContentContainer>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
  params: PropTypes.object
}

export default DefaultLayout
