import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  selectors as pageSelectors
} from 'store/modules/Pagination'
import * as dataUtils from 'utils/dataUtils'

// Constants
export const constants = {
  FETCH: 'RATING/FETCH',
  FETCH_RATING_SUCCESS: 'RATING/FETCH_RATING_SUCCESS',
  FETCH_RATING_FAIL: 'RATING/FETCH_RATING_FAIL',
  RECEIVE_RATING_FACETS: 'RATING/RECEIVE_RATING_FACETS',
  FETCH_FAILURE: 'RATING/FETCH_FAILURE',
  SELECTED_FILTER: 'RATING/SELECTED_FILTER',
  SET_CURRENT_PAGE: 'RATING/SET_CURRENT_PAGE',
  SELECTED_QUERY_PARAM: 'RATING/SELECTED_QUERY_PARAM',
  ADD_FILTERS: 'RATING/ADD_FILTERS',
  SET_DEFAULT_VALUES: 'RATING/SET_DEFAULT_VALUES',
  POST_USER_FEEDBACK_START: 'RATING/POST_USER_FEEDBACK_START',
  POST_USER_FEEDBACK_FAIL: 'RATING/POST_USER_FEEDBACK_FAIL',
  POST_USER_FEEDBACK_SUCCESS: 'RATING/POST_USER_FEEDBACK_SUCCESS',
  OVERALL_RATING_SUCCESS: 'RATING/OVERALL_RATING_SUCCESS',
  OVERALL_RATING_FAIL: 'RATING/OVERALL_RATING_FAIL',
  FETCH_FEEDBACK_TREND_SUCCESS: 'RATING/FETCH_FEEDBACK_TREND_SUCCESS',
  FETCH_FEEDBACK_TREND_FAILURE: 'RATING/FETCH_FEEDBACK_TREND_FAILURE',
  FETCH_RATING_TREND_SUCCESS: 'RATING/FETCH_RATING_TREND_SUCCESS',
  FETCH_RATING_TREND_FAILURE: 'RATING/FETCH_RATING_TREND_FAILURE',
  RESET_LISTING: 'RATING/RESET_LISTING'
}

const Page_Limit = 10
const ratingFilterTypes = {
  'RATING': 'rating',
  'COUNTRY': 'countryCode',
  'ROLE': 'roles',
  'ORG': 'cusorg'
}
// Action Creators
export const actions = {
  setInitialValues (values, listingName) {
    return {
      type: constants.SET_DEFAULT_VALUES,
      listingName
    }
  },
  getRatingFetchQuery(getState, userId) {
    const filterBy = selectors.selectedFilterValues(getState()).toJS()
    const page = pageSelectors.pagination(getState()).getIn(['ratingAndImprovements', 'currentPage'])
    const pageLimit = pageSelectors.pagination(getState()).getIn(['ratingAndImprovements', 'perPage']) || Page_Limit
    const query = {
      filterBy,
      page,
      pageLimit
    }
    if (userId) return { query, userId }
    else return query
  },
  loadRatings () {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      preventMultipleRequest: true,
      apiFunction: 'getRatingImprovementList',
      args: actions.getRatingFetchQuery,
      onSuccess: ({ data: {users, headers, totalPages} }) => ({type: constants.FETCH_RATING_SUCCESS, users, headers, totalPages}),
      onFailure: (error) => ({type: constants.FETCH_RATING_FAIL, error: error})
    })
  },
  addQueryParams (selections, listingName) {
    return { type: constants.SELECTED_QUERY_PARAM, selections, listingName }
  },
  loadFilters (filterCategories, listingName) {
    return async (dispatch, getState) => {
      const filters = filterCategories.map((filter) => {
        const categoryValues = filter.categoryValues
        const modifiedCategoryValues = categoryValues.map((item) => {
          return {checked: false, ...item}
        })
        return {...filter, categoryValues: modifiedCategoryValues}
      })
      dispatch({ type: constants.ADD_FILTERS, filters, listingName })
    }
  },
  resetListing (listingName) {
    return { type: constants.RESET_LISTING, listingName }
  },
  setCurrentPage (page) {
    return { type: constants.SET_CURRENT_PAGE, page }
  },
  addfilterCategory (filterType, selectedValue) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.SELECTED_FILTER, selectedValue })
    }
  },
  addfilterCatogory (filterType, selections, listingName) {
    return { type: constants.SELECTED_FILTER, selections, listingName }
  },
  fetchFilterCategories() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getRatingFilter',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_RATING_FACETS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error })
    })
  },
  loadRatingFacets() {
    return async(dispatch, getState) => {
      dispatch(actions.fetchFilterCategories())
    }
  },
  postUserFeedback() {
    return safeFetch({
      onFetch: () => ({ type: constants.POST_USER_FEEDBACK_START }),
      apiFunction: 'postUserFeedback',
      onSuccess: (data) => ({ type: constants.POST_USER_FEEDBACK_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.POST_USER_FEEDBACK_FAIL, error: error })
    })
  },
  getOverallFeedback(userId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getOverallFeedback',
      args: (getState) => (actions.getRatingFetchQuery(getState, userId)),
      onSuccess: ({ data }) => ({ type: constants.OVERALL_RATING_SUCCESS, data }),
      onFailure: ({ error, status }) => ({ type: constants.OVERALL_RATING_FAIL, error, status })
    })
  },
  getFeedbackValues (duration) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchFeedbackTrendValues',
      args: {duration},
      onSuccess: ({ data }) => ({ type: constants.FETCH_FEEDBACK_TREND_SUCCESS, data }),
      onFailure: ({status}) => ({ type: constants.FETCH_FEEDBACK_TREND_FAILURE, status })
    })
  },
  getRatingValues (duration) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchRatingTrendValues',
      args: {duration},
      onSuccess: ({ data }) => ({ type: constants.FETCH_RATING_TREND_SUCCESS, data }),
      onFailure: ({status}) => ({ type: constants.FETCH_RATING_TREND_FAILURE, status })
    })
  }
}
// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  ratingList: [],
  selectedFilterValues: [],
  selectedQueryParams: {},
  ratingFacets: [],
  totalPages: 0,
  currentPage: 0,
  isSubmit: false,
  overallRating: {},
  userFeedback: [],
  userRating: [],
  errorCode: null,
  errorMessage: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
        .set('errorCode', null)
        .set('errorMessage', null)
    case constants.FETCH_RATING_SUCCESS:
      return state
        .set('ratingList', fromJS(action.users))
        .set('totalPages', action.totalPages)
        .set('fetching', false)
    case constants.FETCH_RATING_FAIL:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RECEIVE_RATING_FACETS:
      return state
        .set('fetching', false)
        .set('ratingFacets', sanitizeFilters(action.data))
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.SELECTED_QUERY_PARAM:
      return toggleQueryParams(state, action.selections, action.listingName)
    case constants.ADD_FILTERS:
      return state
        .set('filters', fromJS(action.filters))
        .set('fetching', false)
    case constants.SELECTED_FILTER:
      return toggleFilterValues(state, action.selections, action.listingName)
    case constants.SET_DEFAULT_VALUES:
      let newState = setInitialValues(state, action.data, action.listingName)
      return newState
    case constants.RESET_LISTING:
      return state
        .set('fetchError', null)
        .set('selectedQueryParams', fromJS({}))
    case constants.POST_USER_FEEDBACK_START:
      return state
        .set('isSubmit', true)
        .set('fetchError', null)
    case constants.POST_USER_FEEDBACK_SUCCESS:
      return state
        .set('isSubmit', false)
        .set('fetchError', null)
    case constants.POST_USER_FEEDBACK_FAIL:
      return state
        .set('isSubmit', false)
        .set('fetchError', action.error)
    case constants.OVERALL_RATING_SUCCESS:
      return state
        .set('fetching', false)
        .set('overallRating', fromJS(action.data))
    case constants.OVERALL_RATING_FAIL:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.FETCH_FEEDBACK_TREND_SUCCESS:
      return state
        .set('userFeedback', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_RATING_TREND_SUCCESS:
      return state
        .set('userRating', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_RATING_TREND_FAILURE:
      return state
        .set('fetching', false)
        .set('errorCode', action.status)
        .set('errorMessage', action.status.toString())
    case constants.FETCH_FEEDBACK_TREND_FAILURE:
      return state
        .set('fetching', false)
        .set('errorCode', action.status)
        .set('errorMessage', action.status.toString())
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.ratingAndImprovements

export const selectors = {
  genericListing: createSelector(getState, (state) =>
    state
  ),
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  ratingImprovementList: createSelector(getState, (state) =>
    state.get('ratingList')
  ),
  getRatingFacets: createSelector(getState, (state) =>
    state.get('ratingFacets')
  ),
  selectedFilterValues: createSelector(getState, (state) =>
   state.get('selectedFilterValues')
  ),
  selectedQueryParams: createSelector(getState, (state) =>
    state.get('selectedQueryParams').toJS()
  ),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages')
  ),
  currentPage: createSelector(getState, (state) =>
    state.get('currentPage')
  ),
  isSubmit: createSelector(getState, (state) =>
  state.get('isSubmit')
  ),
  overallRating: createSelector(getState, (state) =>
  state.get('overallRating')
  ),
  userFeedback: createSelector(getState, (state) =>
    state.get('userFeedback')
  ),
  userRating: createSelector(getState, (state) =>
    state.get('userRating')
  ),
  errorCode: createSelector(getState, (state) =>
    state.get('errorCode')
  ),
  errorMessage: createSelector(getState, (state) =>
    state.get('errorMessage')
  )
}

function sanitizeFilters(filterTypes) {
  const filterOrder = [
    ratingFilterTypes.RATING,
    ratingFilterTypes.COUNTRY,
    ratingFilterTypes.ROLE,
    ratingFilterTypes.ORG
  ]

  let filterCategories = []
  filterOrder
    .forEach((filter) => {
      if (filter in filterTypes) {
        const values = filterTypes[filter]
        const isSearchBox = (filter === ratingFilterTypes.ORG || filter === ratingFilterTypes.COUNTRY)
        const categoryType = filter
        const categoryValues = !values
          ? []
          : values.map((val, index) => {
            const filterWithCaleVal = `${filter}.${val}`
            return{
              name: val,
              label: val,
              key: index,
              value: `${isSearchBox ? val : filterWithCaleVal}`
            }
          })

        filterCategories.push({categoryType, categoryValues, searchBox: isSearchBox})
      }
    })
  return filterCategories
}

const toggleQueryParams = (state, selections, listingName) => {
  let selectedQueryParams = state.getIn([listingName, 'selectedQueryParams'])
  Object.keys(selections).forEach(name => {
    if (selections[name]) {
      selectedQueryParams = selectedQueryParams.set(name, selections[name])
    }
  })
  return state.setIn([listingName, 'selectedQueryParams'], selectedQueryParams)
}
/**
  method is used to update filter values.
  (i) remove if filter value exist
  (ii) insert if filter value not exist

  @param {map} state
  @param {string|array} selections
  @param {string} listingName
  @return {map}
*/
const toggleFilterValues = (state, selections, listingName) => {
  const updateValues = dataUtils.isString(selections)
    ? [selections]
    : selections
  let filters = state.get('selectedFilterValues')
  updateValues.forEach((value) => {
    const indexOfValue = filters.indexOf(value)

    filters = (indexOfValue > -1)
      ? filters.delete(indexOfValue)
      : filters.push(value)
  })
  return state.set('selectedFilterValues', filters)
}
/**
  setInitialValues

  (*) update intial values
  (*) used inside reducer.
  (*) Set initialstate

  @param {map} state
  @param {object} data
  @param {string} listingName
  @return {map}
*/
const setInitialValues = (state, data = {}, listingName) => {
  let newState = state
  // default values are set in two ways
  // by using container and hoc. if state already defined no need to
  // define again
  newState = newState.set(fromJS(initialState))
  return newState
}
