import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import style from './Confirmation.scss'
import { FormattedMessage } from 'react-intl'
import { LightBox, Button } from '@liquid-design/liquid-design-react'
import { Modal } from 'antd'
import 'antd/lib/modal/style/css'

const cx = classNames.bind(style)

const propTypes = {
  confirmationText: PropTypes.node,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  yesText: PropTypes.node,
  noText: PropTypes.node
}
const WpropTypes = {
  confirmationText: PropTypes.node,
  onOkClick: PropTypes.func,
  yesText: PropTypes.node,
}
const defaultProps = {
  yesText: <FormattedMessage id='next.CONFIRMATION_BOX_OK_MESSAGE' />,
  noText: <FormattedMessage id='next.CONFIRMATION_BOX_CANCEL_MESSAGE' />
}

const wdefaultProps = {
  yesText: <FormattedMessage id='next.CONFIRMATION_BOX_OK_MESSAGE' />
}
export const Confirmation = ({ onYesClick, onNoClick, confirmationText, yesText, noText }) => (
 <Modal closable={false} visible onClose={() => onNoClick()} label="">
  <div>
      <div className={cx('confirmation-text')}>{confirmationText}</div>
      <Button
        size='sm'
        style={{ textTransform: 'uppercase', float: 'left' }}
        onClick={() => onYesClick()}
      >
        {yesText}
      </Button>
      { onNoClick &&
        <Button
          size='sm'
          appearance='secondary'
          style={{ textTransform: 'uppercase', float: 'right' }}
          onClick={() => onNoClick()}
        >
          {noText}
        </Button>
      }
    </div>
  </Modal>
)

export const Warning = ({ onOkClick, confirmationText, yesText }) => (
  <LightBox open onClose={() => onOkClick()} label="">
   <div>
       <div className={cx('warning-text')}>{confirmationText}</div>
       <Button
         size='sm'
         className={'ok-button'}
         onClick={() => onOkClick()}
       >
         {yesText}
       </Button>
     </div>
   </LightBox>
 )

Confirmation.propTypes = propTypes
Confirmation.defaultProps = defaultProps

Warning.defaultProps = wdefaultProps
Warning.propTypes = WpropTypes

export default Confirmation
