// import { SubmissionError } from 'redux-form'

export const safeSubmit = (onSubmit) => async (values) => {
  try {
    await onSubmit(values)
  } catch (error) {
    // throw new SubmissionError({
    //   _error: `${error.status}`
    // })
  }
}
export const safeSubmitV2 = (onSubmit) => async (values, {setStatus, setErrors}) => {
  try {
    await onSubmit(values)
  } catch ({status, errorCode}) {
    // throw new SubmissionError({
    //   _error: `${errorCode || status}`
    // })
    setStatus(400)
    setErrors(400)
  }
}

/**
  Debounce

  This method is used to invoke a callback with delay. function to be invoked
  is passed as argument.

  This method returns a function, calling this function will invoke the callback
  after the delay.

  If return function is called continously, invokation of callback only will get
  delayed and timer will reset and start from the beginning.

  @param {function} func - function to be invoked
  @param {number} wait - delay
  @param {boolean} immediate - whether function to be called for first time
  @param {function}
*/
export const debounce = (func, wait, immediate = false) => {
  let timeout

  return function() {
    const later = () => {
      timeout = null
      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    const callNow = immediate && !timeout
    if (callNow) {
      func.apply(this, arguments)
    }
  }
}
