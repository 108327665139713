import { getClassName } from './utils'
import { Dropdown } from '@liquid-design/liquid-design-react'

export const TEXT = {
  PER_PAGE: () => 'Items per page:',
  OF_ITEMS: ({ amount, total }) => `${amount} of ${total} items`,
  OF_PAGES: ({ amount, total }) => `${amount} of ${total} pages`,
}

export const GLOBAL_CSS_PREFIX= 'LD__'

export const TABLE_PAGINATION_CLASSNAME = getClassName({
  name: 'TablePagination',
})

export const DROPDOWN_CLASSNAME = getClassName(Dropdown)

export const DROPDOWN_TRIGGER_CLASSNAME = getClassName({
  name: 'DropdownTrigger',
})

export const OPTIONS_GROUP_CLASSNAME = getClassName({ name: 'OptionsGroup' })

export const ICON_CLASSNAME = getClassName({ name: 'Icon' })