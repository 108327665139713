import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'CARDS_CREATION/FETCH',
  FETCH_FAILURE: 'CARDS_CREATION/FETCH_FAILURE',
  CREATE_CARD_SUCCESS: 'CARDS_CREATION/CREATE_CARD_SUCCESS',
  CREATE_CARD_RESET_ERROR: 'CARDS_CREATION/CREATE_CARD_RESET_ERROR'
}

// Action Creators
export const actions = {
  createCard(card) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'createCard',
      args: card,
      onSuccess: () => ({
        type: constants.CREATE_CARD_SUCCESS
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.errorMessage
      }),
      throwError: true
    })
  },
  resetErrorMessage() {
    return ({type: constants.CREATE_CARD_RESET_ERROR})
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  cardCreationError: null,
  fetchSuccess: false
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.CREATE_CARD_RESET_ERROR:
      return state
        .set('cardCreationError', null)
        .set('fetchSuccess', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchSuccess', false)
        .set('cardCreationError', action.error)
    case constants.CREATE_CARD_SUCCESS:
      return state
        .set('fetching', false)
        .set('fetchSuccess', true)
        .set('cardCreationError', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.cardsCreation

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  cardCreationError: createSelector(getState, (state) =>
    state.get('cardCreationError')
  ),
  fetchSuccess: createSelector(getState, (state) =>
    state.get('fetchSuccess')
  )
}
