import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { OPERATION, RESOURCE_TYPE, searchFilters } from 'constants/customerDevices'
import {
  actions as listingActions
} from 'store/modules/GenericListing'
import {
  selectors as userSelectors
} from 'store/modules/User'

// Constants
export const constants = {
  FETCH: 'CUSTOMER_DEVICES/FETCH',
  FETCH_FAILURE: 'CUSTOMER_DEVICES/FETCH_FAILURE',
  RECEIVE_USER_PRIVILEGES: 'CUSTOMER_DEVICES/RECEIVE_USER_PRIVILEGES',
  ASSIGN_USER_DEVICE: 'CUSTOMER_DEVICES/ASSIGN_USER_DEVICE',
  REMOVE_USER_DEVICE: 'CUSTOMER_DEVICES/REMOVE_USER_DEVICE',
  RECEIVE_FILTER_CATEGORIES: 'CUSTOMER_DEVICES/RECEIVE_FILTER_CATEGORIES',
  RECEIVE_ASSIGN_PRIVILEGE_ID: 'CUSTOMER_DEVICES/RECEIVE_ASSIGN_PRIVILEGE_ID',
  RECEIVE_USER_DETAILS: 'CUSTOMER_DEVICES/RECEIVE_USER_DETAILS',
  FETCH_COMPLETE: 'CUSTOMER_DEVICES/FETCH_COMPLETE',
  USER_RESPONSE_VALIDATION:'CUSTOMER_DEVICES/USER_RESPONSE_VALIDATION'
}

// Actions
export const actions = {
  /**
    @return {object}
  */
  fetchAssignmentPrivilegeId() {
    return safeFetch({
      throwError: true,
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'getPrivileges',
      args: { filterBy: RESOURCE_TYPE },
      onSuccess: ({ data }) => {
        const { privilegeId } = data.find(({ operation }) => {
          return operation === OPERATION
        })

        return {
          type: constants.RECEIVE_ASSIGN_PRIVILEGE_ID,
          privilegeId
        }
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error
      })
    })
  },
  /**
    @param {number} userId
    @param {array} privileges
    @return {object}
  */
  assignUserDevice(userId, privileges) {
    return safeFetch({
      throwError: true,
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'addUserPrivileges',
      args: { userId, privileges },
      onSuccess: ({ data }) => {
        return ({
          type: constants.ASSIGN_USER_DEVICE,
          privileges
        })
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error
      })
    })
  },
  /**
    @param {number} userId
    @param {array} privileges
    @return {object}
  */
  removeUserDevice(userId, privileges) {
    return safeFetch({
      throwError: true,
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'removeUserPrivileges',
      args: { userId, privileges },
      onSuccess: ({ data }) => ({
        type: constants.REMOVE_USER_DEVICE,
        privileges
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error
      })
    })
  },
  /**
    @return {object}
  */

  fetchFilterCategories(data) {
    return ({ type: constants.RECEIVE_FILTER_CATEGORIES, data })
  },

  /**
    @param {string} userId

    @return {object}
  */
  fetchUserDetails(userId) {
    return safeFetch({
      throwError: true,
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'fetchUserProfileData',
      args: userId,
      onSuccess: ({ data }) => {
        return {
          type: constants.RECEIVE_USER_DETAILS,
          data
        }
      },
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error })
    })
  },
  /**
    @param {string} userId
    @param {string} listingName

    @return {object}
  */
  fetchUserDataAndFilter(userId, listingName) {
    return async(dispatch, getState) => {
      await dispatch(actions.fetchUserDetails(userId))
      const permission = userSelectors.getUserPrivilege(getState())
      const isCustomerView = permission.get('lwcustomer') && !!permission.getIn(['lwcustomer', 'VIEW'])

      // Country filter is only used for Labwater Admin
      if (!isCustomerView) {
        const userDetails = selectors.userDetails(getState())
        const countryCode = userDetails.country && userDetails.country.countryCode
        const filterValue = countryCode && `countryCode.${countryCode}`

        dispatch(listingActions.addfilterCatogory(filterValue, filterValue, listingName))
      }
    }
  },
  fetchFacetValues(query) {
    return safeFetch({
      throwError: true,
      getResponse: true,
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceAssignmenFacetValues',
      args: query,
      onSuccess: () => ({ type: constants.FETCH_COMPLETE }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error })
    })
  },
  responseUserDevice(query) {
    return safeFetch({
      throwError: true,
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deviceAssignmentValidation',
      args: query,
      onSuccess: ({ data }) => {
        return ({
          type: constants.USER_RESPONSE_VALIDATION
        })
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error
      })
    })
  },
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  assignPrivilegeId: null,
  userDetails: {},
  assignedDevices: [],
  filterCategories: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.FETCH_COMPLETE:
      return state.set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.ASSIGN_USER_DEVICE:
      return state.set('fetchError', null).set('fetching', false)
    case constants.REMOVE_USER_DEVICE:
      return state.set('fetchError', null).set('fetching', false)
    case constants.RECEIVE_FILTER_CATEGORIES:
      return state
        .set('fetchError', null)
        .set('fetching', false)
        .set('filterCategories', parseCustomerDeviceFilters(action.data))
    case constants.RECEIVE_ASSIGN_PRIVILEGE_ID:
      return state
        .set('fetchError', null)
        .set('fetching', false)
        .set('assignPrivilegeId', action.privilegeId)
    case constants.RECEIVE_USER_DETAILS:
      return state
        .set('fetchError', null)
        .set('fetching', false)
        .set('userDetails', action.data)
    case constants.USER_RESPONSE_VALIDATION:
      return state.set('fetchError', null).set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.customerDevices

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) => (
    state.get('fetchError')
  )),
  assignedDevices: createSelector(getState, (state) => (
    state.get('assignedDevices')
  )),
  filterCategories: createSelector(getState, (state) => (
    state.get('filterCategories')
  )),
  assignPrivilegeId: createSelector(getState, (state) => (
    state.get('assignPrivilegeId')
  )),
  userDetails: createSelector(getState, (state) => (
    state.get('userDetails')
  ))
}

// Helper function
const parseCustomerDeviceFilters = (filters) => {
  const hasSearchFilter = [
    searchFilters.COUNTRY_CODE,
    searchFilters.CUSTOMER_NAME,
    searchFilters.LOCATION_NAME
  ]


  const updatedFilters = !filters ? [] : Object.keys(filters).map(name => {

    if (filters[name] === null) {
      return false
    }

    const filterConfig = {
      searchBox: hasSearchFilter.includes(name),
      deviceAssignmentSearch: hasSearchFilter.includes(name),
      searchBoxHighlightSelection: true,
      ...({ minSearchCharacter: 1 }),
      ...((searchFilters.LOCATION_NAME !== name)
        ? {}
        : { categoryParam: 'locationName' })
    }

    return {
      categoryType: name,
      categoryValues: filters[name].map(value => ({
        name: value,
        label: value,
        value: name === searchFilters.DEVICE_NAME ? `${name}.${value}` : value
      })),
      ...filterConfig
    }
  })
  .filter(filter => typeof filter === 'object')

  return fromJS(updatedFilters)
}