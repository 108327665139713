import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames/bind'
const SIZES = ['sm', '', 'lg', 'toolbar']
const COLORS = [
  'primary',
  'muted',
  'info',
  'success',
  'warning',
  'danger',
  'action',
  'black',
  'white'
]

const propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  exactSize: PropTypes.number,
  color: PropTypes.oneOf(COLORS),
  hasAction: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  isPositioned: PropTypes.bool,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number
}

const RawIcon = ({
  icon,
  size,
  exactSize,
  color,
  active,
  disabled,
  hasAction,
  isPositioned,
  top,
  left,
  right,
  bottom,
  ...iconProps
}) => {
  const sizeClass = size && `icon-${size}`
  const colorClass = color && `icon-${color}`
  const iconOptions = {
    'has-action': hasAction,
    'has-positioning': isPositioned,
    'active': active,
    'disabled': disabled
  }
  const iconStyle = {
    height: exactSize,
    width: exactSize,
    top,
    right,
    bottom,
    left
  }
  return (
    <svg
      viewBox="0 0 24 24"
      style={iconStyle}
      className={cx('icon', `icon-${icon}`, sizeClass, colorClass, iconOptions)}
      {...iconProps}
    >
      <g
        viewBox="0 0 24 24"
        dangerouslySetInnerHTML={{ __html: icon }}
      />
    </svg>
  )
}

RawIcon.propTypes = propTypes

export default RawIcon
