import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

// Constants
export const constants = {
  OPEN: 'GlobalOverlay/OPEN',
  CLOSE: 'GlobalOverlay/CLOSE',
  MODAL: 'GlobalOverlay/MODAL',
  SNACK_BAR: 'GlobalOverlay/SNACK_BAR',
  OFF_CANVAS: 'GlobalOverlay/OFF_CANVAS'
}

// Action Creators
export const actions = {
  open({ element, overlayType, timed = false, speed = 'default', animation = 'no-animation' }) {
    return { type: constants.OPEN, element, overlayType, timed, speed, animation }
  },
  close(options = {}) {
    const { except, only } = options
    return async (dispatch, getState) => {
      const overlayType = selectors.overlayType(getState())
      if (overlayType) {
        if (except && overlayType !== except) {
          dispatch({ type: constants.CLOSE })
        } else if (only && overlayType === only) {
          dispatch({ type: constants.CLOSE })
        } else if (!except && !only) {
          dispatch({ type: constants.CLOSE })
        }
      } else {
        dispatch({ type: constants.CLOSE })
      }
    }
  }
}

// Reducer
export const initialState = fromJS({
  open: false
})
export default function (state = initialState, action) {
  switch (action.type) {
    case constants.OPEN:
      return state
        .set('open', true)
        .set('element', action.element)
        .set('overlayType', action.overlayType)
        .set('timed', action.timed)
        .set('speed', action.speed)
        .set('animation', action.animation)
    case constants.CLOSE:
      return initialState
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.globalOverlay

export const selectors = {
  open: createSelector(getState, (state) =>
    state.get('open')
  ),
  element: createSelector(getState, (state) =>
    state.get('element')
  ),
  overlayType: createSelector(getState, (state) =>
    state.get('overlayType')
  ),
  timed: createSelector(getState, (state) =>
    state.get('timed')
  ),
  speed: createSelector(getState, (state) =>
    state.get('speed')
  ),
  animation: createSelector(getState, (state) =>
    state.get('animation')
  )
}
