import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  actions as PageActions,
  selectors as PageSelectors
} from 'store/modules/Pagination'
import {
  selectors as listingSelectors,
  constants as listingConstants
} from 'store/modules/GenericListing'
import { moduleId } from 'constants/deviceRegistry'

// Constants
export const constants = {
  FETCH: 'registry/FETCH',
  RECEIVE: 'registry/RECEIVE',
  FETCH_FAILURE: 'registry/FETCH_FAILURE',
  ADD_DEVICE_SUCCESS: 'registry/ADD_DEVICE_SUCCESS',
  ADD_DEVICE_FAILURE: 'registry/ADD_DEVICE_FAILURE',
  RESET_ADD_DEVICE_SUCCESS: 'registry/RESET_ADD_DEVICE_SUCCESS',
  DELETE_DEVICE_SUCCESS: 'registry/DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILURE: 'registry/DELETE_DEVICE_FAILURE',
  RESET_DELETE_DEVICE_SUCCESS: 'registry/RESET_DELETE_DEVICE_SUCCESS',
  TOGGLE_ACTIVATION: 'registry/TOGGLE_ACTIVATION',
  UPDATE_DEVICE_SUCCESS: 'registry/UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_FAILURE: 'registry/UPDATE_DEVICE_FAILURE',
  OPEN_ADD_DEVICE_FORM: 'registry/OPEN_ADD_DEVICE_FORM',
  CLOSE_ADD_DEVICE_FORM: 'registry/CLOSE_ADD_DEVICE_FORM',
  SORT: 'registry/SORT',
  REDUCE_TOTAL_PAGE: 'registry/REDUCE_TOTAL_PAGE',
  ADD_TOTAL_PAGE: 'registry/ADD_TOTAL_PAGE',
  SET_SEARCH_TERM: 'registry/SET_SEARCH_TERM',
  RESET_SORT_DIRECTION: 'registry/RESET_SORT_DIRECTION',
  FETCH_HISTORY: 'registry/FETCH_HISTORY',
  FETCH_DEVICE_HISTORY_SUCCESS: 'registry/FETCH_DEVICE_HISTORY_SUCCESS',
  FETCH_DEVICE_HISTORY_FAILURE: 'registry/FETCH_DEVICE_HISTORY_FAILURE',
  RESET_UPDATE_DEVICE_SUCCESS: 'registry/RESET_UPDATE_DEVICE_SUCCESS',
  SET_DEVICE_ID: 'registry/SET_DEVICE_ID',
  DOWNLOAD_CERTIFICATE_SUCCESS: 'registry/DOWNLOAD_CERTIFICATE_SUCCESS',
  DOWNLOAD_CERTIFICATE_FAIL: 'registry/DOWNLOAD_CERTIFICATE_FAIL',
  DEVICE_TYPES_SUCCESS: 'registry/DEVICE_TYPES_SUCCESS',
  DEVICE_TYPES_FAILURE: 'registry/DEVICE_TYPES_FAILURE',
  RESET_DOWNLOAD_CERTIFICATE_FAIL: 'registry/RESET_DOWNLOAD_CERTIFICATE_FAIL',
  CLEAR_DEVICE_HISTORY: 'registry/CLEAR_DEVICE_HISTORY',
  ADD_FILTER_CATEGORY: 'registry/ADD_FILTER_CATEGORY',
  REMOVE_FILTER_CATEGORY: 'registry/REMOVE_FILTER_CATEGORY',
  ADD_FILTER_CATEGORY_VALUES: 'registry/ADD_FILTER_CATEGORY_VALUES',
  ADD_FILTERS: 'registry/ADD_FILTERS',
  STATUS_TOGGLE: 'registry/STATUS_TOGGLE',
  STATUS_TOGGLE_FAILURE: 'registry/STATUS_TOGGLE_FAILURE',
  STATUS_TOGGLE_SUCCESS: 'registry/STATUS_TOGGLE_SUCCESS',
}

const pageLimit = 10
const resolveStream = ({data, headers}) => {
  return async (dispatch) => {
    dispatch(actions.downloadSuccess())
    var certificateData = data && data.certificate
    var file = new Blob([certificateData], {type: 'text/plain'})
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = window.URL.createObjectURL(file)
    a.download = 'Certificate.txt'
    a.click()
  }
}

const deviceRegistryFilters = [
  {
    categoryType: 'active',
    categoryValues: [
      {
        count: 0,
        name: 'Activated',
        value: 'active.true'
      },
      {
        count: 0,
        name: 'Deactivated',
        value: 'active.false'
      }
    ]
  }
]

// Action Creators
export const actions = {
  getDeviceFetchQuery(getState) {
    const filterBy = selectors.filterBy(getState())
    const sortBy = selectors.sortColumn(getState())
    const sortDirection = selectors.sortDirection(getState())
    const searchTerm = selectors.searchTerm(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const page = PageSelectors.pagination(getState()).getIn(['deviceRegistry', 'currentPage'])
    const query = {
      filterBy: filterBy && filterBy.toJS(),
      sortBy: sortBy,
      orderBy: orderBy,
      searchBy: searchTerm,
      page: page,
      pageLimit
    }
    return query
  },
  getDeviceHistoryFetchQuery(getState) {
    const deviceId = selectors.deviceId(getState())
    const page = PageSelectors.pagination(getState()).getIn(['deviceHistory', 'currentPage'])
    const args = {
      page,
      pageLimit: 10
    }
    return {deviceId, args}
  },
  fetch () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceRegistryData',
      args: actions.getDeviceFetchQuery,
      onSuccess: ({ data, headers }) => ({ type: constants.RECEIVE, data, headers }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error })
    })
  },
  fetchDeviceTypes () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getDeviceTypes',
      onSuccess: ({ data }) => ({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes: data }),
      onFailure: (error) => ({ type: constants.DEVICE_TYPES_FAILURE, error: error })
    })
  },
  addDevice (deviceData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addDevice',
      args: deviceData,
      onSuccess: ({ data }, getState) => {
        const deviceNumb = selectors.devicesNumber(getState()) + 1 || 0
        return { type: constants.ADD_DEVICE_SUCCESS, status: String(data), deviceNumb, deviceName: deviceData.deviceName }
      },
      onFailure: (error) => ({ type: constants.ADD_DEVICE_FAILURE, error: error.status }),
      throwError: true
    })
  },
  deleteDevice (deviceId, deviceName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deleteDevice',
      args: deviceId,
      onSuccess: ({ data }, getState) => {
        const deviceNumb = selectors.devicesNumber(getState()) - 1 || 0
        return { type: constants.DELETE_DEVICE_SUCCESS, status: String(data), deviceId, deviceNumb, deviceName }
      },
      onFailure: (error) => ({ type: constants.DELETE_DEVICE_FAILURE, error: error.status, deviceName })
    })
  },
  toggleActivation (deviceId, active, deviceName) {
    return async (dispatch) => {
      await dispatch({ type: constants.STATUS_TOGGLE })
      await dispatch(safeFetch({
        apiFunction: 'updateDevice',
        args: {deviceId, active, deviceName},
        onSuccess: (res, getState) => {
          dispatch({ type: constants.STATUS_TOGGLE_SUCCESS, deviceId, active, deviceName })
          const totalPagesValue = listingSelectors.totalPages(getState(), moduleId)
          const data = listingSelectors.listingData(getState(), moduleId)
          const newData = data.update(data.findIndex(item => item.get('deviceId') === deviceId), item => item.set('active', active)).toJS()
          return ({
            type: listingConstants.RECEIVE_LIST,
            listingName: moduleId,
            data: newData,
            headers: {get: () => totalPagesValue}
          })
        },
        onFailure: (error) => ({ type: constants.STATUS_TOGGLE_FAILURE, error, deviceName })
      }))
    }
  },
  fetchDeviceHistory () {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_HISTORY }),
      apiFunction: 'fetchDeviceHistoryData',
      args: actions.getDeviceHistoryFetchQuery,
      onSuccess: ({ data, headers }) => ({ type: constants.FETCH_DEVICE_HISTORY_SUCCESS, data, headers }),
      onFailure: (error) => ({ type: constants.FETCH_DEVICE_HISTORY_FAILURE, error: error })
    })
  },
  clearDeviceHistory () {
    return { type: constants.CLEAR_DEVICE_HISTORY }
  },
  applySort (sortColumn) {
    return { type: constants.SORT, sortColumn }
  },
  openAddDeviceForm () {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_ADD_DEVICE_FORM })
    }
  },
  closeAddDeviceForm () {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_ADD_DEVICE_FORM })
    }
  },
  resetDeleteDeviceStatus () {
    return {type: constants.RESET_DELETE_DEVICE_SUCCESS}
  },
  resetAddDeviceStatus () {
    return {type: constants.RESET_ADD_DEVICE_SUCCESS}
  },
  resetUpdateDeviceStatus () {
    return {type: constants.RESET_UPDATE_DEVICE_SUCCESS}
  },
  resetSortDirection () {
    return {type: constants.RESET_SORT_DIRECTION}
  },
  refreshDevicePage() {
    return async (dispatch, getState) => {
      const deviceNumb = await selectors.devicesNumber(getState()) || 0
      let page = await PageSelectors.pagination(getState()).getIn(['deviceRegistry', 'currentPage'])
      const totalPages = await selectors.totalPages(getState()) || 0
      if (deviceNumb === 0) {
        page = page - 1
        dispatch({type: constants.REDUCE_TOTAL_PAGE, totalPages})
      }
      if (deviceNumb >= 20) {
        page = page === totalPages ? totalPages + 1 : totalPages
        dispatch({type: constants.ADD_TOTAL_PAGE, totalPages})
      }
      if (deviceNumb === 0 || deviceNumb >= 20) {
        dispatch(PageActions.setCurrentPage(page, 'deviceRegistry'))
        dispatch(actions.fetch())
      }
    }
  },
  setSearchTerm (searchTerm) {
    return { type: constants.SET_SEARCH_TERM, searchTerm }
  },
  setDeviceId (deviceId) {
    return { type: constants.SET_DEVICE_ID, deviceId }
  },
  downloadCertificate (uuid) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'downloadCertificate',
      args: uuid,
      onSuccess: resolveStream,
      onFailure: (error) => ({ type: constants.DOWNLOAD_CERTIFICATE_FAIL, error: error.message })
    })
  },
  downloadSuccess () {
    return {
      type: constants.DOWNLOAD_CERTIFICATE_SUCCESS
    }
  },
  resetDownloadCertificateFailStatus () {
    return {type: constants.RESET_DOWNLOAD_CERTIFICATE_FAIL}
  },
  loadDeviceRegistryFilters () {
    return async (dispatch, getState) => {
      dispatch({ type: constants.FETCH })
      try {
        const filters = deviceRegistryFilters.map((filter) => {
          const categoryValues = filter.categoryValues
          const modifiedCategoryValues = categoryValues.map((item) => {
            return {checked: false, ...item}
          }
          )
          return {...filter, categoryValues: modifiedCategoryValues}
        }
        )
        dispatch({ type: constants.ADD_FILTERS, filters })
      } catch (error) {
        dispatch({ type: constants.FETCH_FAILURE, error: error })
      }
    }
  },
  addfilterCatogory (filterType) {
    return { type: constants.ADD_FILTER_CATEGORY, filterType }
  },
  addfilterCatogoryValues (categoryValue, isChecked) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.FETCH })
      const deviceFilters = selectors.filters(getState()).toJS()
      const filters = deviceFilters.map((filter) => {
        const categoryValues = filter.categoryValues
        const modifiedCategoryValues = categoryValues.map((item) => {
          return item.name === categoryValue
          ? {...item, checked: isChecked}
          : {...item}
        }
        )
        return {...filter, categoryValues: modifiedCategoryValues}
      }
      )
      dispatch({ type: constants.ADD_FILTERS, filters })
      dispatch({ type: constants.ADD_FILTER_CATEGORY_VALUES, categoryValue })
    }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  data: null,
  devicesNumber: 0,
  totalPages: 0,
  totalPagesHistory: 0,
  searchTerm: '',
  deviceId: null,
  addDeviceSuccess: null,
  deleteDeviceSuccess: null,
  deleteDeviceError: null,
  updateDeviceSuccess: null,
  updateDeviceError: null,
  addDeviceError: null,
  openAddDeviceForm: false,
  sortColumn: 'deviceName',
  sortDirection: true,
  deviceHistoryRecords: null,
  fetchingHistory: false,
  deviceTypes: null,
  downloadError: null,
  filterType: '',
  filterBy: [],
  filters: [],
  filterCategoryType: [],
  deviceTypesFilters: [],
  togglefetching: false
})
export default function (state = initialState, action) {
  switch (action.type) {

    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
        .set('addDeviceError', null)
        .set('deleteDeviceSuccess', null)
        .set('updateDeviceSuccess', null)
        .set('fetchingHistory', false)
        .set('updateDeviceError', null)
        .set('deleteDeviceError', null)
    case constants.RECEIVE:
      return state
        .set('fetching', false)
        .set('data', fromJS(action.data))
        .set('totalPages', parseInt(fromJS(action.headers.get('x-pagination-count'))))
        .set('devicesNumber', fromJS(action.data.length))
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', fromJS(action.error))
        .set('fetching', false)
    case constants.ADD_DEVICE_SUCCESS:
      return state
        .set('addDeviceSuccess', {status: action.status, deviceName: action.deviceName})
        .set('fetching', false)
        .set('devicesNumber', action.deviceNumb)
    case constants.ADD_DEVICE_FAILURE:
      return state
        .set('addDeviceError', action.error)
        .set('fetching', false)
    case constants.RESET_ADD_DEVICE_SUCCESS:
      return state
        .set('addDeviceSuccess', null)
    case constants.DELETE_DEVICE_SUCCESS:
      return state
        .set('deleteDeviceSuccess', {status: action.status, devicename: action.deviceName})
        .set('fetching', false)
        .set('data', state.get('data').filter(item => item.get('deviceId') !== action.deviceId))
        .set('devicesNumber', action.deviceNumb)
    case constants.DELETE_DEVICE_FAILURE:
      return state
        .set('deleteDeviceError', {error: action.error, devicename: action.deviceName})
        .set('fetching', false)
    case constants.RESET_DELETE_DEVICE_SUCCESS:
      return state
        .set('deleteDeviceSuccess', null)
        .set('deleteDeviceError', null)
    case constants.FETCH_HISTORY:
      return state
        .set('fetchingHistory', true)
    case constants.FETCH_DEVICE_HISTORY_SUCCESS:
      return state
        .set('deviceHistoryRecords', action.data)
        .set('fetchingHistory', false)
        .set('totalPagesHistory', parseInt(fromJS(action.headers.get('x-pagination-count'))))
        .set('historyNumber', fromJS(action.data.length))
    case constants.FETCH_DEVICE_HISTORY_FAILURE:
      return state
        .set('fetchingHistory', false)
    case constants.CLEAR_DEVICE_HISTORY:
      return state
        .set('deviceHistoryRecords', null)
        .set('totalPagesHistory', 0)
        .set('historyNumber', 0)
    case constants.STATUS_TOGGLE:
      return state
        .set('fetching', true)
    case constants.STATUS_TOGGLE_FAILURE:
      return state
        .set('fetching', false)
        .set('updateDeviceError', action.error)
    case constants.STATUS_TOGGLE_SUCCESS:
      return state
        .set('fetching', false)
        .set('updateDeviceSuccess', {status: action.active, deviceName: action.deviceName})
    case constants.RESET_UPDATE_DEVICE_SUCCESS:
      return state
        .set('updateDeviceSuccess', null)
        .set('updateDeviceError', null)
    case constants.OPEN_ADD_DEVICE_FORM:
      return state
        .set('openAddDeviceForm', true)
        .set('deleteDeviceSuccess', null)
    case constants.CLOSE_ADD_DEVICE_FORM:
      return state
        .set('openAddDeviceForm', false)
        .set('addDeviceSuccess', null)
    case constants.SORT:
      return state
        .set('sortColumn', fromJS(action.sortColumn))
        .set('sortDirection', !state.get('sortDirection'))
    case constants.REDUCE_TOTAL_PAGE:
      return state
        .set('totalPages', action.totalPages - 1)
    case constants.ADD_TOTAL_PAGE:
      return state
        .set('totalPages', action.totalPages + 1)
    case constants.SET_SEARCH_TERM:
      return state
        .set('searchTerm', action.searchTerm)
    case constants.SET_DEVICE_ID:
      return state
        .set('deviceId', action.deviceId)
    case constants.RESET_SORT_DIRECTION:
      return state
        .set('sortDirection', initialState.get('sortDirection'))
        .set('sortColumn', initialState.get('sortColumn'))
        .set('filterBy', initialState.get('filterBy'))
    case constants.DOWNLOAD_CERTIFICATE_SUCCESS:
      return state
        .set('fetching', false)
    case constants.DOWNLOAD_CERTIFICATE_FAIL:
      return state
        .set('downloadError', action.error)
        .set('fetching', false)
    case constants.RESET_DOWNLOAD_CERTIFICATE_FAIL:
      return state
        .set('fetching', false)
        .set('downloadError', null)
    case constants.DEVICE_TYPES_SUCCESS:
      return state
        .set('deviceTypes', sanitizeFilters(action.deviceTypes))
        .set('fetching', false)
    case constants.ADD_FILTER_CATEGORY:
      return state
        .set('filterType', action.filterType)
        .updateIn(['filterCategoryType'], (state) => {
          return state.find(categoryType => categoryType === action.filterType)
          ? state : state.push(action.filterType)
        })
    case constants.REMOVE_FILTER_CATEGORY:
      return state
      .updateIn(['filterCategoryType'], (state) => {
        return state.find(categoryType => categoryType === action.categoryType)
        ? state.filter(item => item !== action.categoryType) : state
      })
    case constants.ADD_FILTER_CATEGORY_VALUES:
      return state
        .updateIn(['filterBy'], (state) => {
          return state.find(category => category === action.categoryValue)
          ? state.filter(item => item !== action.categoryValue) : state.push(action.categoryValue)
        })
    case constants.ADD_FILTERS:
      return state
        .set('filters', fromJS(action.filters))
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceRegistry

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  devices: createSelector(getState, (state) =>
    state.get('data')
  ),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages')
  ),
  totalPagesHistory: createSelector(getState, (state) =>
    state.get('totalPagesHistory')
  ),
  devicesNumber: createSelector(getState, (state) =>
    state.get('devicesNumber')
  ),
  sortColumn: createSelector(getState, (state) =>
    state.get('sortColumn')
  ),
  sortDirection: createSelector(getState, (state) =>
    state.get('sortDirection')
  ),
  addDeviceSuccess: createSelector(getState, (state) =>
    state.get('addDeviceSuccess')
  ),
  addDeviceError: createSelector(getState, (state) =>
    state.get('addDeviceError')
  ),
  deleteDeviceSuccess: createSelector(getState, (state) =>
    state.get('deleteDeviceSuccess')
  ),
  deleteDeviceError: createSelector(getState, (state) =>
    state.get('deleteDeviceError')
  ),
  updateDeviceSuccess: createSelector(getState, (state) =>
    state.get('updateDeviceSuccess')
  ),
  updateDeviceError: createSelector(getState, (state) =>
    state.get('updateDeviceError')
  ),
  openAddDeviceForm: createSelector(getState, (state) =>
    state.get('openAddDeviceForm')
  ),
  searchTerm: createSelector(getState, (state) =>
    state.get('searchTerm')
  ),
  deviceHistoryRecords: createSelector(getState, (state) =>
    state.get('deviceHistoryRecords')
  ),
  deviceId: createSelector(getState, (state) =>
    state.get('deviceId')
  ),
  downloadError: createSelector(getState, (state) =>
    state.get('downloadError')
  ),
  deviceTypes: createSelector(getState, (state) =>
    state.get('deviceTypes')
  ),
  fetchingHistory: createSelector(getState, (state) =>
    state.get('fetchingHistory')
  ),
  filterBy: createSelector(getState, (state) =>
    state.get('filterBy') || []
    ),
  filterType: createSelector(getState, (state) =>
    state.get('filterType')
    ),
  filters: createSelector(getState, (state) =>
    state.get('filters') || []
    ),
  filterCount: createSelector(getState, (state) =>
    state.get('filterBy').size || 0
    ),
  filterCategories: createSelector(getState, (state) =>
    state.get('filterCategories')
  )
}
// helper functions
function sanitizeFilters(filterTypes) {
  let filterCategories = filterTypes.map((val, index) => ({
    name: val.deviceType,
    label: val.deviceType,
    key: index,
    value: `deviceType.${val.deviceType}`,
  }))
  let deviceTypeObj = {categoryType: 'deviceType', categoryValues: filterCategories}
  return fromJS(deviceTypeObj)
}
