import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { isString } from 'utils/dataUtils'

// Constants
export const constants = {
  FETCH: 'USER_NOTIFICATION_PREFERENCES/FETCH',
  RECEIVE_USER_SUBSCRIPTION_CATEGORIES_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_USER_SUBSCRIPTION_CATEGORIES_SUCCESS',
  RECEIVE_USER_SUBSCRIPTION_CATEGORIES_FAILURE: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_USER_SUBSCRIPTION_CATEGORIES_FAILURE',
  RECEIVE_USER_SUBSCRIPTION_DATA_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_USER_SUBSCRIPTION_DATA_SUCCESS',
  RECEIVE_USER_SUBSCRIPTION_DATA_FAILURE: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_USER_SUBSCRIPTION_DATA_FAILURE',
  UPDATE_USER_SUBSCRIPTION_DATA_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/UPDATE_USER_SUBSCRIPTION_DATA_SUCCESS',
  UPDATE_USER_SUBSCRIPTION_DATA_FAILURE: 'USER_NOTIFICATION_PREFERENCES/UPDATE_USER_SUBSCRIPTION_DATA_FAILURE',
  POST_USER_SUBSCRIPTION_DATA_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/POST_USER_SUBSCRIPTION_DATA_SUCCESS',
  POST_USER_SUBSCRIPTION_DATA_FAILURE: 'USER_NOTIFICATION_PREFERENCES/POST_USER_SUBSCRIPTION_DATA_FAILURE',
  SET_USER_EMAILS: 'USER_NOTIFICATION_PREFERENCES/SET_USER_EMAILS',
  RECEIVE_USER_EMAILS_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_USER_EMAILS_SUCCESS',
  RECEIVE_USER_EMAILS_FAILURE: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_USER_EMAILS_FAILURE',
  POST_USER_EMAILS_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/POST_USER_EMAILS_SUCCESS',
  POST_USER_EMAILS_FAILURE: 'USER_NOTIFICATION_PREFERENCES/POST_USER_EMAILS_FAILURE',
  UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY: 'USER_NOTIFICATION_PREFERENCES/UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY',
  UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY_SMS: 'USER_NOTIFICATION_PREFERENCES/UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY_SMS',
  UPDATE_SMS_SUBSCRIPTION_PHONE_NUMBER: 'USER_NOTIFICATION_PREFERENCES/UPDATE_SMS_SUBSCRIPTION_PHONE_NUMBER',
  UPDATE_SMS_SUBSCRIPTION_ISD_CODE: 'USER_NOTIFICATION_PREFERENCES/UPDATE_SMS_SUBSCRIPTION_ISD_CODE',
  RESET_SMS_SUBSCRIPTION_OTP_ERROR: 'USER_NOTIFICATION_PREFERENCES/RESET_SMS_SUBSCRIPTION_OTP_ERROR',
  RESET_SMS_SUBSCRIPTION_PHONE_NUMBER_ERROR: 'USER_NOTIFICATION_PREFERENCES/RESET_SMS_SUBSCRIPTION_PHONE_NUMBER_ERROR',
  UPDATE_CUSTOM_MESSAGE: 'USER_NOTIFICATION_PREFERENCES/UPDATE_CUSTOM_MESSAGE',
  UPDATE_REMAINDER_DAY_EMAIL: 'USER_NOTIFICATION_PREFERENCES/UPDATE_REMAINDER_DAY_EMAIL',
  UPDATE_REMAINDER_DAY_SMS: 'USER_NOTIFICATION_PREFERENCES/UPDATE_REMAINDER_DAY_SMS',
  RESET_EMAIL_SUBSCRIPTION_STATE: 'USER_NOTIFICATION_PREFERENCES/RESET_EMAIL_SUBSCRIPTION_STATE',
  SET_DEFAULT_EMAIL_IDS: 'USER_NOTIFICATION_PREFERENCES/SET_DEFAULT_EMAIL_IDS',
  UPDATE_USER_EMAIL_DATA_FOR_EACH_SUBSCRIPTION: 'USER_NOTIFICATION_PREFERENCES/UPDATE_USER_EMAIL_DATA_FOR_EACH_SUBSCRIPTION',
  UPDATE_DISABLED_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY: 'USER_NOTIFICATION_PREFERENCES/UPDATE_DISABLED_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY',
  SET_CUSTOM_MESSAGE_ERROR: 'USER_NOTIFICATION_PREFERENCES/SET_CUSTOM_MESSAGE_ERROR',
  UNSET_CUSTOM_MESSAGE_ERROR: 'USER_NOTIFICATION_PREFERENCES/UNSET_CUSTOM_MESSAGE_ERROR',
  RECEIVE_PHONE_NUMBER_VALIDATION_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_PHONE_NUMBER_VALIDATION_SUCCESS',
  RECEIVE_PHONE_NUMBER_VALIDATION_FAILURE: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_PHONE_NUMBER_VALIDATION_FAILURE',
  RECEIVE_PHONE_NUMBER_OTP_VALIDATION_SUCCESS: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_PHONE_NUMBER_OTP_VALIDATION_SUCCESS',
  RECEIVE_PHONE_NUMBER_OTP_VALIDATION_FAILURE: 'USER_NOTIFICATION_PREFERENCES/RECEIVE_PHONE_NUMBER_OTP_VALIDATION_FAILURE'
}

// Action Creators
export const actions = {
  getSubscriptionCategories() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchSubscriptionCategories',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_USER_SUBSCRIPTION_CATEGORIES_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.RECEIVE_USER_SUBSCRIPTION_CATEGORIES_FAILURE, status })
    })
  },
  getUserSubscriptionsDataByUserId(userId) {
    return async (dispatch) => {
      await dispatch(actions.getUserSubscriptions(userId))
      dispatch({ type: constants.UPDATE_DISABLED_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY })
    }
  },
  getUserSubscriptions(userId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: userId,
      apiFunction: 'fetchUserSubscriptions',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_USER_SUBSCRIPTION_DATA_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.RECEIVE_USER_SUBSCRIPTION_DATA_FAILURE, status })
    })
  },
  validatePhoneNumber(phoneObj) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'validatePhoneNumber',
      args: phoneObj,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_PHONE_NUMBER_VALIDATION_SUCCESS, data }),
      onFailure: ({ status, errorCode }) => ({ type: constants.RECEIVE_PHONE_NUMBER_VALIDATION_FAILURE, status, errorCode })
    })
  },
  validateOTP(params) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getPhoneNumberValidationOTP',
      args: params,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_PHONE_NUMBER_OTP_VALIDATION_SUCCESS, data }),
      onFailure: ({ status, errorCode }) => ({ type: constants.RECEIVE_PHONE_NUMBER_OTP_VALIDATION_FAILURE, status, errorCode })
    })
  },
  updateUserSubscriptions({ userId, dataObj }) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateUserSubscriptions',
      args: { userId, dataObj },
      onSuccess: ({ data }) => ({ type: constants.UPDATE_USER_SUBSCRIPTION_DATA_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.UPDATE_USER_SUBSCRIPTION_DATA_FAILURE, status })
    })
  },
  createUserSubscriptions({ userId, dataObj }) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'postUserSubscriptions',
      args: { userId, dataObj },
      onSuccess: ({ data }) => ({ type: constants.POST_USER_SUBSCRIPTION_DATA_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.POST_USER_SUBSCRIPTION_DATA_FAILURE, status })
    })
  },
  setUserEmails(emailData, subscriptionCategoryId) {
    return {
      type: constants.SET_USER_EMAILS, emailData, subscriptionCategoryId
    }
  },
  getUserEmails(emailId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchUserEmails',
      args: emailId,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_USER_EMAILS_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.RECEIVE_USER_EMAILS_FAILURE, status })
    })
  },
  postUserEmails(emailData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'postUserEmails',
      args: emailData,
      onSuccess: ({ data }) => ({ type: constants.POST_USER_EMAILS_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.POST_USER_EMAILS_FAILURE, status }),
      throwError: true
    })
  },
  updateSubscriptionData(updatedSubscrDataObject, typeFrom) {
    return (dispatch, getState) => {
      if (typeFrom === 'Email') {
        let indexToUpdate = selectors.userEmailSubscriptionUpdatedData(getState())
          .findIndex(subsData => subsData.get('subscriptionCategoryId') === updatedSubscrDataObject.subscriptionCategoryId)
        dispatch({ type: constants.UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY, updatedSubscrDataObject, indexToUpdate })
      } else {
        let indexToUpdateSms = selectors.userSmsSubscriptionUpdatedData(getState())
          .findIndex(subsData => subsData.get('subscriptionCategoryId') === updatedSubscrDataObject.subscriptionCategoryId)
        dispatch({ type: constants.UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY_SMS, updatedSubscrDataObject, indexToUpdateSms })
      }
      dispatch({ type: constants.UPDATE_DISABLED_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY, typeFrom })
    }
  },
  updateSmsSubPhoneNo(phoneNo) {
    return {
      type: constants.UPDATE_SMS_SUBSCRIPTION_PHONE_NUMBER, phoneNo
    }
  },
  updateISDCode(isdCode) {
    return {
      type: constants.UPDATE_SMS_SUBSCRIPTION_ISD_CODE, isdCode
    }
  },
  resetOtpError() {
    return {
      type: constants.RESET_SMS_SUBSCRIPTION_OTP_ERROR
    }
  },
  resetPhoneNumberError() {
    return {
      type: constants.RESET_SMS_SUBSCRIPTION_PHONE_NUMBER_ERROR
    }
  },
  updateCustomMessageData(customMessage) {
    return {
      type: constants.UPDATE_CUSTOM_MESSAGE, customMessage
    }
  },
  updateNumberOfDaysAdvance(updatedNumberOfDaysAdvance, typeFrom, subscriptionCategoryId, userEmailSubscriptionUpdatedData, userSmsSubscriptionUpdatedData) {
    if (typeFrom === 'Email') {
      const newEmailSubscriptionData = userEmailSubscriptionUpdatedData.toJS().map(object => {
        if (object.subscriptionCategoryId === subscriptionCategoryId) {
          return {
            ...object,
            numberOfDaysAdvance: updatedNumberOfDaysAdvance
          }
        }
        return object
      })
      return {
        type: constants.UPDATE_REMAINDER_DAY_EMAIL, newEmailSubscriptionData
      }
    } else {
      const newSmsSubscriptionData = userSmsSubscriptionUpdatedData.toJS().map(object => {
        if (object.subscriptionCategoryId === subscriptionCategoryId) {
          return {
            ...object,
            numberOfDaysAdvance: updatedNumberOfDaysAdvance
          }
        }
        return object
      })
      return {
        type: constants.UPDATE_REMAINDER_DAY_SMS, newSmsSubscriptionData
      }
    }
  },
  resetEmailSubscriptionState() {
    return {
      type: constants.RESET_EMAIL_SUBSCRIPTION_STATE
    }
  },
  setDefaultEmailIds(emailData, subscriptionCategoryId) {
    return {
      type: constants.SET_DEFAULT_EMAIL_IDS, emailData, subscriptionCategoryId
    }
  },
  updateUserEmailData(emailData, subscriptionCategoryId) {
    return {
      type: constants.UPDATE_USER_EMAIL_DATA_FOR_EACH_SUBSCRIPTION, emailData, subscriptionCategoryId
    }
  },
  setCustomMsgError() {
    return {
      type: constants.SET_CUSTOM_MESSAGE_ERROR
    }
  },
  unSetCustomMsgError() {
    return {
      type: constants.UNSET_CUSTOM_MESSAGE_ERROR
    }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  userNotificationPreferencesData: [],
  userSmsNotificationPreferencesData: [],
  userEmailError: null,
  userSubscriptionDataByID: [],
  getUserEmailsData: null,
  selectedEmailData: [],
  userEmailSubscriptionUpdatedData: [],
  userSmsSubscriptionUpdatedData: [],
  customMessage: null,
  defaultEmailIds: [],
  userCategoryDisabledData: [],
  maxCustomizeMsgError: false,
  smsSubPhoneNo: null,
  isdCode: null,
  isValidPhoneNumber: false,
  otpSend: false,
  phoneNumberOTPValid: false,
  phoneNumberError: null,
  otpError: null
})
export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.RECEIVE_USER_SUBSCRIPTION_CATEGORIES_SUCCESS:
      return state
        .set('userNotificationPreferencesData', fromJS(action.data[0]))
        .set('userSmsNotificationPreferencesData', fromJS(action.data[1]))
        .set('fetching', false)
    case constants.RECEIVE_USER_SUBSCRIPTION_CATEGORIES_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RECEIVE_USER_SUBSCRIPTION_DATA_SUCCESS:
      return state
        .set('userSubscriptionDataByID', fromJS(action.data))
        .set('smsSubPhoneNo', getUpdatedPhoneNumberSms(action.data, state))
        .set('isdCode', getUpdatedISDCode(action.data, state))
        .set('isValidPhoneNumber', getUpdatedPhoneNumberSmsValid(action.data, state))
        .set('otpSend', false)
        .set('userEmailSubscriptionUpdatedData', getUpdatedData(action.data, state.getIn(['userNotificationPreferencesData', 'subscriptionCategories']).toJS(), 1))
        .set('userSmsSubscriptionUpdatedData', getUpdatedData(action.data, state.getIn(['userSmsNotificationPreferencesData', 'subscriptionCategories']).toJS(), 2))
        .set('fetching', false)
    case constants.RECEIVE_PHONE_NUMBER_VALIDATION_SUCCESS:
      return state
        .set('otpSend', true)
        .set('fetching', false)
    case constants.RECEIVE_PHONE_NUMBER_VALIDATION_FAILURE:
      return state
        .set('isValidPhoneNumber', false)
        .set('otpSend', false)
        .set('phoneNumberError', action.errorCode)
        .set('fetching', false)
    case constants.RECEIVE_PHONE_NUMBER_OTP_VALIDATION_SUCCESS:
      return state
        .set('isValidPhoneNumber', true)
        .set('otpSend', false)
        .set('phoneNumberOTPValid', true)
        .set('fetching', false)
    case constants.RECEIVE_PHONE_NUMBER_OTP_VALIDATION_FAILURE:
      return state
        .set('phoneNumberOTPValid', false)
        .set('otpError', action.errorCode)
        .set('fetching', false)
    case constants.UPDATE_DISABLED_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY:
      return state
        .set('userCategoryDisabledData', getDisabledData(state, action.typeFrom))
        .set('fetching', false)
    case constants.RECEIVE_USER_SUBSCRIPTION_DATA_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.POST_USER_SUBSCRIPTION_DATA_SUCCESS:
      return state
        .set('fetching', false)
    case constants.POST_USER_SUBSCRIPTION_DATA_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.RECEIVE_USER_EMAILS_SUCCESS:
      return state
        .set('getUserEmailsData', action.data)
        .set('fetching', false)
    case constants.RECEIVE_USER_EMAILS_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.POST_USER_EMAILS_SUCCESS:
      return state
        .set('fetching', false)
        .set('userEmailError', action.data.invalidEmails)
    case constants.POST_USER_EMAILS_FAILURE:
      return state
        .set('fetching', false)
    case constants.RESET_EMAIL_SUBSCRIPTION_STATE:
      return state
        .set('userEmailError', null)
        .set('getUserEmailsData', null)
    case constants.SET_DEFAULT_EMAIL_IDS:
      return state
        .updateIn(['defaultEmailIds', action.subscriptionCategoryId], () => action.emailData)
    case constants.UPDATE_USER_SUBSCRIPTION_DATA_SUCCESS:
      return state
        .set('fetching', false)
    case constants.UPDATE_USER_SUBSCRIPTION_DATA_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.SET_USER_EMAILS:
      return state
        .updateIn(['selectedEmailData', action.subscriptionCategoryId], () => action.emailData)
    case constants.UPDATE_USER_EMAIL_DATA_FOR_EACH_SUBSCRIPTION:
      let emailDataToUpdate = state.get('userEmailSubscriptionUpdatedData')
      let emailIndexToUpdate = emailDataToUpdate && emailDataToUpdate.size > 0 &&
        emailDataToUpdate.findIndex(eachDataObj => eachDataObj.get('subscriptionCategoryId') === action.subscriptionCategoryId)
      let indexOfDependentObj = emailDataToUpdate?.size > 0 && action.subscriptionCategoryId === 1 &&
        emailDataToUpdate.findIndex(eachDataObj => eachDataObj.get('subscriptionCategoryId') === 23)
      let smsDataToUpdate = state.get('userSmsSubscriptionUpdatedData')
      let smsIndexToUpdate = smsDataToUpdate && smsDataToUpdate.size > 0 &&
        smsDataToUpdate.findIndex(eachDataObj => eachDataObj.get('subscriptionCategoryId') === action.subscriptionCategoryId)
      return state
        .updateIn(['userEmailSubscriptionUpdatedData', emailIndexToUpdate, 'emails'], () => fromJS(action.emailData))
        .updateIn(['userSmsSubscriptionUpdatedData', smsIndexToUpdate, 'emails'], () => fromJS(action.emailData))
        .updateIn(['userEmailSubscriptionUpdatedData', indexOfDependentObj, 'emails'], () => fromJS(action.emailData))
        .updateIn(['selectedEmailData', action.subscriptionCategoryId], () => action.emailData)
    case constants.UPDATE_SMS_SUBSCRIPTION_PHONE_NUMBER:
      return state
        .update('smsSubPhoneNo', () => action.phoneNo)
        .set('phoneNumberError', null)
        .update('otpSend', () => false)
        .update('phoneNumberOTPValid', () => false)
    case constants.UPDATE_SMS_SUBSCRIPTION_ISD_CODE:
      return state
        .set('isdCode', action.isdCode)
    case constants.RESET_SMS_SUBSCRIPTION_OTP_ERROR:
      return state
        .set('otpError', null)
    case constants.RESET_SMS_SUBSCRIPTION_PHONE_NUMBER_ERROR:
      return state
        .set('phoneNumberError', null)
    case constants.UPDATE_CUSTOM_MESSAGE:
      return state
        .updateIn(['userSubscriptionDataByID', 'customMessage'], () => action.customMessage)
    case constants.UPDATE_REMAINDER_DAY_EMAIL:
      return state
        .set('userEmailSubscriptionUpdatedData', fromJS(action.newEmailSubscriptionData))
    case constants.UPDATE_REMAINDER_DAY_SMS:
      return state
        .set('userSmsSubscriptionUpdatedData', fromJS(action.newSmsSubscriptionData))
    case constants.UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY:
      let dependentEmailObjIndex = action.updatedSubscrDataObject?.subscriptionCategoryId === 1 ?
        state.get('userEmailSubscriptionUpdatedData').findIndex(subsData =>
          subsData.get('subscriptionCategoryId') === 23) : null
      return state
        .updateIn(['userEmailSubscriptionUpdatedData', action.indexToUpdate, 'status'], () => fromJS(action.updatedSubscrDataObject.status))
        .updateIn(['userEmailSubscriptionUpdatedData', action.indexToUpdate, 'emails'], () => fromJS(action.updatedSubscrDataObject.emails))
        .updateIn(['userEmailSubscriptionUpdatedData', dependentEmailObjIndex, 'status'], () => fromJS(action.updatedSubscrDataObject.status))
    case constants.UPDATE_SUBSCRIPTION_DATA_FOR_EACH_CATEGORY_SMS:
      let dependentSmsObjIndex = action.updatedSubscrDataObject?.subscriptionCategoryId === 5 ?
        state.get('userSmsSubscriptionUpdatedData').findIndex(subsData =>
          subsData.get('subscriptionCategoryId') === 24) : null
      return state
        .updateIn(['userSmsSubscriptionUpdatedData', action.indexToUpdateSms, 'status'], () => fromJS(action.updatedSubscrDataObject.status))
        .updateIn(['userSmsSubscriptionUpdatedData', action.indexToUpdateSms, 'emails'], () => fromJS(action.updatedSubscrDataObject.emails))
        .updateIn(['userSmsSubscriptionUpdatedData', dependentSmsObjIndex, 'status'], () => fromJS(action.updatedSubscrDataObject.status))
    case constants.SET_CUSTOM_MESSAGE_ERROR:
      return state
        .set('maxCustomizeMsgError', true)
    case constants.UNSET_CUSTOM_MESSAGE_ERROR:
      return state
        .set('maxCustomizeMsgError', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.userNotificationPreferences

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  defaultEmailIds: createSelector(getState, (state) =>
    state.get('defaultEmailIds')
  ),
  maxCustomizeMsgError: createSelector(getState, (state) =>
    state.get('maxCustomizeMsgError')
  ),
  userNotificationPreferencesData: createSelector(getState, (state) =>
    state.get('userNotificationPreferencesData')
  ),
  userSmsNotificationPreferencesData: createSelector(getState, (state) =>
    state.get('userSmsNotificationPreferencesData')
  ),
  userEmailError: createSelector(getState, (state) =>
    state.get('userEmailError')
  ),
  userSubscriptionDataByID: createSelector(getState, (state) =>
    state.get('userSubscriptionDataByID')
  ),
  getUserEmailsData: createSelector(getState, (state) =>
    state.get('getUserEmailsData')
  ),
  selectedEmailData: createSelector(getState, (state) =>
    state.get('selectedEmailData')
  ),
  getOtpStatus: createSelector(getState, (state) =>
    state.get('otpSend')
  ),
  getSmsSubPhoneNo: createSelector(getState, (state) =>
    state.get('smsSubPhoneNo')
  ),
  getIsdCode: createSelector(getState, (state) =>
    state.get('isdCode')
  ),
  isValidPhoneNumber: createSelector(getState, (state) =>
    state.get('isValidPhoneNumber')
  ),
  isValidOTP: createSelector(getState, (state) =>
    state.get('phoneNumberOTPValid')
  ),
  customMessage: createSelector(getState, (state) =>
    state.getIn(['userSubscriptionDataByID', 'customMessage'])
  ),
  userEmailSubscriptionUpdatedData: createSelector(getState, (state) =>
    state.get('userEmailSubscriptionUpdatedData')
  ),
  userSmsSubscriptionUpdatedData: createSelector(getState, (state) =>
    state.get('userSmsSubscriptionUpdatedData')
  ),
  userCategoryDisabledData: createSelector(getState, (state) =>
    state.get('userCategoryDisabledData')
  ),
  phoneNumberApiError: createSelector(getState, (state) =>
    state.get('phoneNumberError')
  ),
  otpApiError: createSelector(getState, (state) =>
    state.get('otpError')
  )
}

function getUpdatedPhoneNumberSms(subscriptionData) {
  let updatedSmsSubscriptionDate = subscriptionData.subscriptionTypeDTOs.length > 0 ? (subscriptionData.subscriptionTypeDTOs.find(item => item.subscriptionTypeId === 2)) : null
  return updatedSmsSubscriptionDate ? updatedSmsSubscriptionDate.phones : ''
}
function getUpdatedISDCode(subscriptionData) {
  let updatedSmsSubscriptionDate = subscriptionData.subscriptionTypeDTOs.length > 0 ? (subscriptionData.subscriptionTypeDTOs.find(item => item.subscriptionTypeId === 2)) : null
  return updatedSmsSubscriptionDate?.isdCode || ''
}
function getUpdatedPhoneNumberSmsValid(subscriptionData) {
  let updatedSmsSubscriptionDate = subscriptionData.subscriptionTypeDTOs.length > 0 && (subscriptionData.subscriptionTypeDTOs.find(item => item.subscriptionTypeId === 2))
  return updatedSmsSubscriptionDate?.phones
}
const getUpdatedData = (subscriptionData, initialCategoryData, subcriptionCategoryId) => {
  let updatedData = []
  let updatedSubscriptionData = subscriptionData.subscriptionTypeDTOs.length > 0 ? (subscriptionData.subscriptionTypeDTOs.filter(item => item.subscriptionTypeId === subcriptionCategoryId)) : []
  const updatedCategoryData = subscriptionData.subscriptionTypeDTOs.length > 0
    ? updatedSubscriptionData.length > 0 ? updatedSubscriptionData[0].subscriptionCategories : []
    : []
  initialCategoryData.forEach((dt) => {
    let indexToUpdate = updatedCategoryData && updatedCategoryData.length > 0
      ? updatedCategoryData.findIndex(data => data.subscriptionCategoryId === dt.subscriptionCategoryId) // all true values
      : -1
    if (indexToUpdate < 0) {
      updatedData.push(dt)
    }
  })
  updatedData = [...updatedCategoryData, ...updatedData]
  return fromJS(updatedData)
}
const getDisabledData = (state, typeFrom) => {
  let userCategoryDisabledData = state.get('userCategoryDisabledData').toJS()
  let initialDefaultData = []

  if (isString(typeFrom)) {
    initialDefaultData = (typeFrom === 'Email')
      ? state.get('userEmailSubscriptionUpdatedData').toJS()
      : state.get('userSmsSubscriptionUpdatedData').toJS()
  } else {
    // If typeForm is not string, we assume that is action is dispatched from
    // getUserSubscriptionsDataByUserId

    initialDefaultData = [].concat(
      state.get('userEmailSubscriptionUpdatedData').toJS(),
      state.get('userSmsSubscriptionUpdatedData').toJS()
    )
  }

  initialDefaultData.forEach(({ subscriptionCategoryId }) => {
    if (subscriptionCategoryId === 3 || subscriptionCategoryId === 23) {
      let disabledStatus = initialDefaultData
        .filter(dt => (dt.subscriptionCategoryId === 1 && dt.status))
      userCategoryDisabledData[subscriptionCategoryId] = !(disabledStatus && disabledStatus.length <= 0)
    }
    if (subscriptionCategoryId === 4) {
      let disabledAlarmStatus = initialDefaultData
        .filter(dt => (dt.subscriptionCategoryId === 1 && dt.status))
      let disabledAlertStatus = initialDefaultData
        .filter(dt => (dt.subscriptionCategoryId === 2 && dt.status))
      userCategoryDisabledData[4] = (!(disabledAlarmStatus && disabledAlarmStatus.length <= 0) &&
        !(disabledAlertStatus && disabledAlertStatus.length <= 0))
    }

    if (subscriptionCategoryId === 9 || subscriptionCategoryId === 24) {
      let disabledStatus = initialDefaultData
        .filter(dt => (dt.subscriptionCategoryId === 5 && dt.status))
      userCategoryDisabledData[subscriptionCategoryId] = !(disabledStatus && disabledStatus.length <= 0)
    }

    if (subscriptionCategoryId === 10) {
      let disabledAlarmStatus = initialDefaultData
        .filter(dt => (dt.subscriptionCategoryId === 5 && dt.status))
      let disabledAlertStatus = initialDefaultData
        .filter(dt => (dt.subscriptionCategoryId === 6 && dt.status))
      userCategoryDisabledData[10] = (!(disabledAlarmStatus && disabledAlarmStatus.length <= 0) &&
        !(disabledAlertStatus && disabledAlertStatus.length <= 0))
    }
  })
  return fromJS(userCategoryDisabledData)
}