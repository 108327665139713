import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { FormattedMessage, injectIntl } from 'react-intl'
import Localize from 'components/Localize/Localize'
import styles from './ManageOrganization.scss'
import { Formik, Form } from 'formik'
import { Input, AntSelect, AntTextarea } from 'antDComponents'
import { Select, Button } from 'antd'
import { asyncValidate } from './ManageOrganizaionValidaionSchema'
import withStateSubscription from './withStateSubscritpion'
import {
  statusOptions,
  historicalDataSubsArr,
} from 'constants/orgUsersListing'
import RenderErrors from 'components/RenderErrors/RenderErrors'
import Loader from 'components/Loader/Loader'
import withSubmitForm from 'containers/withSubmitForm/withSubmitForm'
import { orgTypes as organizationTypes } from 'constants/orgUsersListing'

const { Option } = Select

const historicalDataSubsOpt = () =>
  historicalDataSubsArr?.map(({ label, value }) => (
    <Option key={value} value={value}>
      {label}
    </Option>
  ))

const cx = classNames.bind(styles)
const createStatusOptions = statusOptions.map(({ label, value }) => (
  <Option key={value} value={value}>
    {label}
  </Option>
))

class ManageOrganization extends PureComponent {
  constructor(props) {
    super()

    this.state = { isPartner: false, selectedPartnerType: [], orgName: null }
    this.submittedValues = {}
  }
  componentDidMount = async () => {
    const {
      initialValues,
      mode,
      getBrandValues,
      fetchOrganizationTypes,
      fetchPeripheralPartnerTypes,
    } = this.props
    fetchPeripheralPartnerTypes()
    if (mode === 'edit') {
      const { partnerTypes, type } = initialValues
      let list = []
      if (type === organizationTypes.PARTNERS) {
        list = partnerTypes.length
          ? partnerTypes?.map(({ partnerTypeId }) => partnerTypeId)
          : []
        await getBrandValues(list)
        this.setState({
          selectedPartnerType: list,
        })
      }
    } else {
      await fetchOrganizationTypes()
    }
  }
  handlePartnerTypeDeSelect = (value) => {
    const { getBrandValues } = this.props
    const { selectedPartnerType } = this.state
    const valueIndex = selectedPartnerType.indexOf(value)
    const list = [
      ...selectedPartnerType.slice(0, valueIndex),
      ...selectedPartnerType.slice(valueIndex + 1),
    ]
    getBrandValues(list)
    this.setState({
      selectedPartnerType: list,
    })
  }

  handlePartnerTypeSelect = async (value, type = '', setFieldValue) => {
    const { getBrandValues } = this.props
    const list = [...value]
    await getBrandValues(list)
    setFieldValue('brand', '')
    this.setState({
      selectedPartnerType: list,
    })
  }
  handleSubmit = async (values) => {
    const { handleSubmitForm, mode } = this.props
    this.submittedValues = values
    let template = ''
    if (values.type === organizationTypes.CUSTOMERS) {
      template = 'labwater_customer'
    } else if (values.type === organizationTypes.PARTNERS) {
      template = 'labwater_partner'
    } else if (values.type === organizationTypes.DISTRIBUTORS) {
      template = 'labwater_distributor'
    }

    let submitValue = {
      customProperties: {
        partner_peripheral_supplier: values.brand,
      },
      template: template,
      ...values,
    }
    this.setState({
      orgName: values.name
    })
    if (mode !== 'edit') {
      await handleSubmitForm('addOrganizationDetails', submitValue, 'CREATE')
    } else this.onEditSubmit(submitValue)
  }
  onEditSubmit = async (
    {
      name,
      desc = '',
      brand,
      type,
      status,
      historicalDataSubscription,
      template,
      partnerTypeIds,
    },
    partnerTypes
  ) => {
    const {
      handleSubmitForm,
      loadList,
      data: { orgId },
    } = this.props
    const partnerPayload =
      type === organizationTypes.PARTNERS
        ? {
          customProperties: {
            partner_peripheral_supplier: brand,
          },
          partnerTypeIds: partnerTypeIds,
        }
        : ''
    await handleSubmitForm('editOrganizationDetails', {
      orgId,
      name,
      desc: desc,
      type,
      status,
      template,
      historicalDataSubscription,
      ...partnerPayload,
    }, 'UPDATE')
    await loadList()
  }

  handleError = (error) => {
    const { name } = this.submittedValues

    return (
      <Localize
        values={{ name }}
        fallback={`next.${error}`}
        id={`next.MANAGE_ORGANIZATION_${error}_ERROR`}
      />
    )
  }

  statusFieldOptions = (options) =>
    options && options.length
      ? options.map(({ orgTypeName }) => (
        <Option key={orgTypeName} value={orgTypeName}>
          {orgTypeName}
        </Option>
      ))
      : []
  createPartnerTypeOptions = (options = []) =>
    options?.length
      ? options.map(({ partnerTypeId, partnerTypeName }) => (
        <Option key={partnerTypeId} value={partnerTypeId}>
          {partnerTypeName}
        </Option>
      ))
      : []
  createBrandOptions = (options) =>
    options?.length
      ? options.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))
      : []
  initialState = () => {
    const {
      data: {
        description,
        name,
        type,
        customProperties,
        status,
        historicalDataSubscription,
        partnerTypes,
      },
    } = this.props
    return {
      name: name || '',
      historicalDataSubscription: historicalDataSubscription || historicalDataSubsArr[0].value,
      type: type,
      brand: customProperties?.partner_peripheral_supplier?.id,
      status: status,
      desc: description,
      partnerTypeIds: partnerTypes?.length && partnerTypes.map((type) => type.partnerTypeId) || [],
    }
  }

  render() {
    const {
      intl,
      mode,
      close,
      orgTypes,
      getPeripheralPartnerType,
      errorMessage,
      errorCode,
      brandValues,
      fetchPartnerTypeError,
      fetchingStatus,
      data: { autoCreated },
      user
    } = this.props
    const { orgName } = this.state
    const isDistributor = user?.role === 'Distributor Admin'
    return (
      <>
        {fetchingStatus && <Loader />}
        <Formik
          validationSchema={asyncValidate}
          onSubmit={(values) => this.handleSubmit(values)}
          initialValues={this.initialState()}
        >
          {({
            setFieldValue,
            values,
            handleSubmit,
            handleChange,
            isSubmitting,
          }) => {
            return (
              <Form>
                <RenderErrors
                  isSubmitting={isSubmitting}
                  errorMessage={errorMessage}
                  message={<FormattedMessage id={`next.MANAGE_ORGANIZATION_${errorCode}_ERROR`} values={{ name: orgName && orgName }} />}
                />
                <div className={cx('form-wrap')}>
                  <AntSelect
                    name='type'
                    setFieldValue={setFieldValue}
                    disabled={mode === 'view' || mode === 'edit'}
                    handleChange={handleChange}
                    value={values.type}
                    placeholder={intl.formatMessage({ id: 'next.MANAGE_ORGANIZATION_TYPE_PLACEHOLDER' })}
                    options={this.statusFieldOptions(orgTypes)}
                    label={<FormattedMessage id={'next.MANAGE_ORGANIZATION_TYPE_LABEL'} />}
                    isRequired
                  />
                  <Input
                    isRequired
                    name='name'
                    value={values.name}
                    disabled={mode === 'view' || autoCreated}
                    label={intl.formatMessage({
                      id: 'next.MANAGE_ORGANIZATION_NAME_LABEL',
                    })}
                    handleChange={handleChange}
                    placeholder={intl.formatMessage({
                      id: 'next.MANAGE_ORGANIZATION_NAME_PLACEHOLDER',
                    })}
                  />
                  {values.type === organizationTypes.PARTNERS && (
                    <>
                      <AntSelect
                        name='partnerTypeIds'
                        mode='multiple'
                        setFieldValue={setFieldValue}
                        options={this.createPartnerTypeOptions(getPeripheralPartnerType)}
                        value={values.partnerTypeIds}
                        handleChange={handleChange}
                        placeholder={intl.formatMessage({ id: 'next.MANAGE_ORGANIZATION_PARTNER_TYPE_PLACEHOLDER' })}
                        label={<FormattedMessage id={'next.MANAGE_ORGANIZATION_PARTNER_TYPE_LABEL'} />}
                        isCallback
                        callbackFunction={this.handlePartnerTypeSelect}
                        isRequired
                      />
                      {fetchPartnerTypeError && fetchPartnerTypeError.errorCode && (
                        <p className={cx('partner-type-required')}>
                          {intl.formatMessage({ id: `next.MANAGE_ORGANIZATION_${fetchPartnerTypeError.errorCode}_ERROR` })}
                        </p>
                      )}
                      <AntSelect
                        name='brand'
                        setFieldValue={setFieldValue}
                        disabled={mode === 'view'}
                        options={this.createBrandOptions(brandValues)}
                        value={values.brand}
                        handleChange={handleChange}
                        placeholder={intl.formatMessage({ id: 'next.MANAGE_ORGANIZATION_BRAND_PLACEHOLDER' })}
                        label={<FormattedMessage id={'next.MANAGE_ORGANIZATION_BRAND_LABEL'} />}
                        isRequired
                      />
                    </>
                  )}
                  <AntSelect
                    name='status'
                    disabled={mode === 'view'}
                    setFieldValue={setFieldValue}
                    value={values.status}
                    handleChange={handleChange}
                    options={createStatusOptions}
                    label={<FormattedMessage id={'next.MANAGE_ORGANIZATION_STATUS_LABEL'} />}
                    placeholder={<FormattedMessage id={'next.MANAGE_ORGANIZATION_STATUS_LABEL'} />}
                    isRequired
                  />
                  <AntTextarea
                    name='desc'
                    label={intl.formatMessage({ id: `next.MANAGE_ORGANIZATION_DESCRIPTION_LABEL` })}
                    placeholder={intl.formatMessage({ id: `next.MANAGE_ORGANIZATION_DESCRIPTION_PLACEHOLDER` })}
                    disabled={mode === 'view'}
                    value={values.desc}
                    isRequired
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    maxLength={100}
                  />
                  {!isDistributor && (
                    <AntSelect
                      name='historicalDataSubscription'
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      value={values.historicalDataSubscription}
                      options={historicalDataSubsOpt()}
                      placeholder={intl.formatMessage({ id: 'next.MANAGE_ORGANIZATION_HISTORICAL_DATA_SUBSCRIPTION_LABEL' })}
                      label={<FormattedMessage id={'next.MANAGE_ORGANIZATION_HISTORICAL_DATA_SUBSCRIPTION_LABEL'} />}
                      isRequired
                    />
                  )}
                </div>
                <div className={cx('button-bar')}>
                  <Button onClick={close} type='default'>
                    <FormattedMessage id='next.MANAGE_ORGANIZATION_CANCEL' />
                  </Button>
                  <Button
                    disabled={mode === 'view' || isSubmitting}
                    type='primary'
                    onClick={handleSubmit}>
                    <FormattedMessage id={mode === 'edit' ? 'next.UPDATE' : 'next.MANAGE_ORGANIZATION_SUBMIT'} />
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </>
    )
  }

  static propTypes = {
    intl: PropTypes.object,
    initialValues: PropTypes.object,
    close: PropTypes.func,
    changeFieldValue: PropTypes.func,
    mode: PropTypes.string,
    options: PropTypes.array,
    type: PropTypes.string,
    onSubmit: PropTypes.func,
    addOrganization: PropTypes.bool,
    data: PropTypes.data,
    partnerTypes: PropTypes.array,
    getBrandValues: PropTypes.func,
    fetchOrganizationTypes: PropTypes.func,
    orgTypes: PropTypes.func,
    getPeripheralPartnerType: PropTypes.array,
    fetchPeripheralPartnerTypes: PropTypes.func,
    errorMessage: PropTypes.string,
    handleSubmitForm: PropTypes.func,
    errorCode: PropTypes.string,
    brandValues: PropTypes.array,
    fetchPartnerTypeError: PropTypes.object,
    loadList: PropTypes.func,
    fetchingStatus: PropTypes.bool,
    permission: PropTypes.object,
    user: PropTypes.object
  }
}

export default injectIntl(
  withStateSubscription(withSubmitForm(ManageOrganization))
)
