export const connectionTypes = {
  REMOTE: 'REMOTE_CONNECT_BY_FSE',
  LOCAL: 'REMOTE_CONNECT_BY_CUSTOMER'
}

export const remoteConnectionRoute = {
  PATHNAME: '/nextconnect/remote/api/device/remote/data'
}

export const statusTypes = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  REJECTED: 'REJECTED'
}

export const feedbackOptions = [
  {
    label: 'IN FULL',
    value: 'IN FULL'
  },
  {
    label: 'PARTIALLY',
    value: 'PARTIALLY'
  },
  {
    label: 'NONE',
    value: 'NONE'
  }]

export const defaultOrgName = 'LABWATER'

export const SESSION_DISCONNECTED = 'sessionDisconnected'
