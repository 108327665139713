import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  actions as listingActions
} from 'store/modules/GenericListing'

/**
  HOC to register listing component
  and set initial values to state

  @param {class|function} Component
  @param {object} config
  @return {class}
*/
const genericListing = (Component, config) => {
  class ExtendedComponent extends PureComponent {
    constructor(props) {
      super(props)
      // register name with initial values
      const { setInitialValues } = props
      const { name, values = {} } = config
      setInitialValues(values, name)
    }
    render() {
      return <Component {...this.props} />
    }
  }

  const mapStateToProps = (state, ownProps) => ({})
  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({
      setInitialValues: listingActions.setInitialValues
    }, dispatch)
  }

  ExtendedComponent.propTypes = {
    setInitialValues: PropTypes.func
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExtendedComponent)
}

export default genericListing
