import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import './StaticHome.scss'
import { FormattedMessage } from 'react-intl'


class StaticHome extends Component {

  render() {
    return (
      <div className='static-home-body'>
        <span>
          <FormattedMessage id='next.REGISTERED_USER_STATIC_HOME_PAGE_CONTENT' />
        </span>
      </div>
    )
  }
}
export default injectIntl(StaticHome)
