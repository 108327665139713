import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

export const moduleId = 'newsFeed'
const newsFeedFilterTypes = {
  NEWSCATEGORY: 'categoryName',
  STATUS: 'activeStatus'
}
// Constants
export const constants = {
  FETCH: 'NEWSFEED/FETCH',
  FETCH_FAILURE: 'NEWSFEED/FETCH_FAILURE',
  FETCH_NEWS_FEED: 'NEWSFEED/FETCH_NEWS_FEED',
  RECEIVE_NEWS_FEED_FACETS: 'NEWSFEED/RECEIVE_NEWS_FEED_FACETS',
  SET_CURRENT_PAGE: 'NEWSFEED/SET_CURRENT_PAGE',
  DELETE: 'NEWSFEED/DELETE',
  DELETE_SUCCESS: 'NEWSFEED/DELETE_SUCCESS',
  DELETE_FAILURE: 'NEWSFEED/DELETE_FAILURE',
  RESET_DELETE_NEWS_FEED_STATUS: 'NEWSFEED/RESET_DELETE_NEWS_FEED_STATUS',
  NEWS_FEED_SUCCESS: 'NEWSFEED/NEWS_FEED_SUCCESS',
  NEWS_FEED_FAILURE: 'NEWSFEED/NEWS_FEED_FAILURE',
  ADD_NEWS_FEED_TEMPLATE_START: 'NEWSFEED/ADD_NEWS_FEED_TEMPLATE_START',
  ADD_NEWS_FEED_TEMPLATE_SUCCESS: 'NEWSFEED/ADD_NEWS_FEED_TEMPLATE_SUCCESS',
  ADD_NEWS_FEED_TEMPLATE_FAIL: 'NEWSFEED/ADD_NEWS_FEED_TEMPLATE_FAIL',
  EDIT_NEWS_FEED_TEMPLATE_START: 'NEWSFEED/EDIT_NEWS_FEED_TEMPLATE_START',
  EDIT_NEWS_FEED_TEMPLATE_SUCCESS: 'NEWSFEED/EDIT_NEWS_FEED_TEMPLATE_SUCCESS',
  EDIT_NEWS_FEED_TEMPLATE_FAIL: 'NEWSFEED/EDIT_NEWS_FEED_TEMPLATE_FAIL',
  RESET_UPLOAD_UPDATE_SUCCESS: 'NEWSFEED/RESET_UPLOAD_UPDATE_SUCCESS',
  FETCH_NEWS_CATEGORY_START: 'newsFeed/FETCH_NEWS_CATEGORY_START',
  FETCH_NEWS_CATEGORY_SUCCESS: 'GLOBAL/FETCH_NEWS_CATEGORY_SUCCESS',
  FETCH_NEWS_CATEGORY_FAILURE: 'GLOBAL/FETCH_NEWS_CATEGORY_FAILURE',
  FETCHING_NEWS_IMAGES_SUCCESS: 'GLOBAL/FETCHING_NEWS_IMAGES_SUCCESS',
  FETCHING_NEWS_IMAGES_FAILURE: 'GLOBAL/FETCHING_NEWS_IMAGES_FAILURE',
  FETCHING_IMAGES: 'GLOBAL/FETCHING_IMAGES',
  RESET_NEWS_FEED_STATE: 'GLOBAL/RESET_NEWS_FEED_STATE',
  RESET_ADD_EDIT_NEWS_FEED_POPUP: 'NEWSFEED/RESET_ADD_EDIT_NEWS_FEED_POPUP',
  SUCCESS_VALIDATE_SEQUENCE: 'NEWSFEED/SUCCESS_VALIDATE_SEQUENCE',
  FAILURE_VALIDATE_SEQUENCE: 'NEWSFEED/FAILURE_VALIDATE_SEQUENCE',
  FETCH_APPLICATION_DOMAIN_START: 'NEWSFEED/FETCH_APPLICATION_DOMAIN_START',
  FETCH_APPLICATION_DOMAIN_SUCCESS: 'NEWSFEED/FETCH_APPLICATION_DOMAIN_SUCCESS',
  FETCH_APPLICATION_DOMAIN_FAILURE: 'NEWSFEED/FETCH_APPLICATION_DOMAIN_FAILURE'
}

// Action Creators
export const actions = {
  addNewsFeedTemplate(data) {
    return safeFetch({
      onFetch: () => ({ type: constants.ADD_NEWS_FEED_TEMPLATE_START }),
      throwError: true,
      apiFunction: 'addNewsFeedTemplate',
      args: data,
      onSuccess: ({ data, headers }) => ({
        type: constants.ADD_NEWS_FEED_TEMPLATE_SUCCESS,
        data,
        headers,
      }),
      onFailure: (error) => ({
        type: constants.ADD_NEWS_FEED_TEMPLATE_FAIL,
        error: error,
      }),
    })
  },
  editNewsFeedTemplate(data) {
    return safeFetch({
      onFetch: () => ({ type: constants.EDIT_NEWS_FEED_TEMPLATE_START }),
      throwError: true,
      apiFunction: 'editNewsFeedTemplate',
      args: data,
      onSuccess: ({ data, headers }) => ({
        type: constants.EDIT_NEWS_FEED_TEMPLATE_SUCCESS,
        data,
        headers,
      }),
      onFailure: (error) => ({
        type: constants.EDIT_NEWS_FEED_TEMPLATE_FAIL,
        error: error,
      }),
    })
  },
  resetUploadUpdateState() {
    return { type: constants.RESET_UPLOAD_UPDATE_SUCCESS }
  },
  setCurrentPage(page) {
    return { type: constants.SET_CURRENT_PAGE, page }
  },
  deleteNewsFeed(templateNo, templateName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deleteNewsFeed',
      args: { templateNo },
      onSuccess: () => ({
        type: constants.DELETE_SUCCESS,
        status: 204,
        templateName,
      }),
      onFailure: ({ status }) => ({ type: constants.DELETE_FAILURE, status }),
    })
  },
  resetNewsFeedDeleteStatus() {
    return { type: constants.RESET_DELETE_NEWS_FEED_STATUS }
  },
  loadNewsFeedFacets() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchNewsFeedFacets',
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_NEWS_FEED_FACETS,
        data,
      }),
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.message,
      }),
    })
  },
  getNewsFeedCategory() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_NEWS_CATEGORY_START }),
      apiFunction: 'getNewsCategory',
      onSuccess: ({ data }) => ({ type: constants.NEWS_FEED_SUCCESS, data }),
      onFailure: (error) => ({
        type: constants.NEWS_FEED_FAILURE,
        error: error.message,
      }),
    })
  },
  getApplicationDomain(query, token) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_APPLICATION_DOMAIN_START }),
      apiFunction: 'getApplicationDomain',
      args: { query, token },
      onSuccess: ({ data }) => ({ type: constants.FETCH_APPLICATION_DOMAIN_SUCCESS, data }),
      onFailure: (error) => ({
        type: constants.FETCH_APPLICATION_DOMAIN_FAILURE,
        error: error.message,
      }),
    })
  },
  getActiveNewsFeedCate() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCHING_IMAGES }),
      apiFunction: 'getActiveNewsFeedCate',
      onSuccess: ({ data }) => ({
        type: constants.FETCH_NEWS_CATEGORY_SUCCESS,
        data,
      }),
      onFailure: (error) => ({
        type: constants.FETCH_NEWS_CATEGORY_FAILURE,
        error,
      }),
    })
  },
  fetchNewsImages(category) {
    if (typeof category === 'number') {
      category = { categoryId: category }
    }
    return safeFetch({
      onFetch: () => ({ type: constants.FETCHING_IMAGES }),
      args: category,
      apiFunction: 'fetchNewsImages',
      onSuccess: ({ data }) => ({
        type: constants.FETCHING_NEWS_IMAGES_SUCCESS,
        data,
      }),
      onFailure: (error) => ({
        type: constants.FETCHING_NEWS_IMAGES_FAILURE,
        error,
      }),
    })
  },
  resetNewsFeedState() {
    return { type: constants.RESET_NEWS_FEED_STATE }
  },
  fetchImageAndCategory() {
    return async (dispatch, getState) => {
      let categories = selectors.newsFeedCategory(getState())
      categories = categories && categories.toJS()
      if (categories && categories.length > 0) {
        dispatch(actions.fetchNewsImages(categories[0]['categoryId']))
      }
    }
  },
  sequenceOredrValidation(query) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: query,
      apiFunction: 'sequenceOredrValidation',
      onSuccess: ({ data }) => ({
        type: constants.SUCCESS_VALIDATE_SEQUENCE,
        data,
      }),
      onFailure: (error) => ({
        type: constants.FAILURE_VALIDATE_SEQUENCE,
        error,
      }),
    })
  },
  resetNewsFeedPopup() {
    return { type: constants.RESET_ADD_EDIT_NEWS_FEED_POPUP }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  currentPage: 0,
  selectedFilterValues: [],
  newsFeedDeleteStatus: null,
  cardFacets: [],
  fileUploaded: null,
  fileUpdated: null,
  fileUploadError: null,
  fileUpdateError: null,
  fetchingStart: false,
  newsFeedTemplate: [],
  newsFeedCategory: [],
  newsImages: [],
  isFetchingImg: false,
  imgFetchError: null,
  sequenceError: null,
  sequenceAvailabe: null,
  applicationDomains: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
        .set('fetchError', null)
        .set('sequenceError', null)
    case constants.RECEIVE_NEWS_FEED_FACETS:
      return state
        .set('cardFacets', sanitizeFilters(action.data))
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state.set('fetchError', action.error).set('fetching', false)
    case constants.DELETE_SUCCESS:
      return state
        .set('newsFeedDeleteStatus', {
          status: action.status,
          name: action.templateName,
        })
        .set('fetching', false)
    case constants.DELETE_FAILURE:
      return state
        .set('newsFeedDeleteStatus', { status: action.status })
        .set('fetching', false)
    case constants.RESET_DELETE_NEWS_FEED_STATUS:
      return state.set('newsFeedDeleteStatus', null)
    case constants.FETCH_NEWS_CATEGORY_START:
      return state.set('fetchingStart', true).set('fetchError', null)
    case constants.NEWS_FEED_SUCCESS:
      return state
        .set('newsFeedCategory', fromJS(action.data))
        .set('fetchingStart', false)
    case constants.NEWS_FEED_FAILURE:
      return state.set('fetchError', action.error).set('fetchingStart', false)
    case constants.ADD_NEWS_FEED_TEMPLATE_START:
      return state.set('fetchingStart', true)
    case constants.ADD_NEWS_FEED_TEMPLATE_SUCCESS:
      return state.set('fileUploaded', true).set('fetchingStart', false)
    case constants.ADD_NEWS_FEED_TEMPLATE_FAIL:
      return state
        .set('fetchError', action.error)
        .set('fetchingStart', false)
        .set('fileUploaded', null)
    case constants.EDIT_NEWS_FEED_TEMPLATE_START:
      return state.set('fetchingStart', true)
    case constants.EDIT_NEWS_FEED_TEMPLATE_SUCCESS:
      return state.set('fileUpdated', true).set('fetchingStart', false)
    case constants.EDIT_NEWS_FEED_TEMPLATE_FAIL:
      return state
        .set('fetchError', action.error)
        .set('fetchingStart', false)
        .set('fileUpdated', null)
    case constants.RESET_UPLOAD_UPDATE_SUCCESS:
      return state.set('fileUpdated', null).set('fileUploaded', null)
    case constants.FETCH_NEWS_CATEGORY_SUCCESS:
      return state
        .set('newsFeedCategoryHeader', fromJS(action.data))
        .set('isFetchingImg', false)
    case constants.FETCH_NEWS_CATEGORY_FAILURE:
      return state.set('fetchError', action.error).set('isFetchingImg', false)
    case constants.FETCHING_IMAGES:
      return state.set('imgFetchError', null).set('isFetchingImg', true)
    case constants.FETCHING_NEWS_IMAGES_SUCCESS:
      return state
        .set('newsImages', fromJS(action.data))
        .set('isFetchingImg', false)
    case constants.FETCHING_NEWS_IMAGES_FAILURE:
      return state
        .set('imgFetchError', action.error)
        .set('newsImages', fromJS([]))
        .set('isFetchingImg', false)
    case constants.RESET_NEWS_FEED_STATE:
      return state
        .set('imgFetchError', null)
        .set('newsImages', fromJS([]))
        .set('newsFeedTemplate', fromJS([]))
        .set('isFetchingImg', false)
    case constants.FETCH_APPLICATION_DOMAIN_START:
      return state.set('fetchingStart', true).set('fetchError', null)
    case constants.FETCH_APPLICATION_DOMAIN_SUCCESS:
      return state
        .set('applicationDomains', fromJS(action.data))
        .set('fetchingStart', false)
    case constants.FETCH_APPLICATION_DOMAIN_FAILURE:
      return state.set('fetchError', action.error).set('fetchingStart', false)
    case constants.SUCCESS_VALIDATE_SEQUENCE:
      return state
        .set('fetching', false)
        .set('sequenceAvailabe', action.data)
    case constants.FAILURE_VALIDATE_SEQUENCE:
      return state
        .set('sequenceError', action.error)
        .set('fetching', false)
    case constants.RESET_ADD_EDIT_NEWS_FEED_POPUP:
      return state
        .set('sequenceAvailabe', null)
        .set('sequenceError', null)
    default:
      return state
  }
}
// Selectors
const getState = (state) => state.newsFeed

export const selectors = {
  fetching: createSelector(getState, (state) => state.get('fetching')),
  fetchError: createSelector(getState, (state) => state.get('fetchError')),
  getCardFacets: createSelector(getState, (state) => state.get('cardFacets')),
  currentPage: createSelector(getState, (state) => state.get('currentPage')),
  newsFeedDeleteStatus: createSelector(getState, (state) =>
    state.get('newsFeedDeleteStatus')
  ),
  fileUploaded: createSelector(getState, (state) => state.get('fileUploaded')),
  fileUploadError: createSelector(getState, (state) =>
    state.get('fileUploadError')
  ),
  fileUpdated: createSelector(getState, (state) => state.get('fileUpdated')),
  fileUpdateError: createSelector(getState, (state) =>
    state.get('fileUpdateError')
  ),
  fetchingStart: createSelector(getState, (state) =>
    state.get('fetchingStart')
  ),
  newsFeedCategory: createSelector(getState, (state) => {
    return state.get('newsFeedCategory')
  }),
  newsFeedCategoryHeader: createSelector(getState, (state) => {
    return state.get('newsFeedCategoryHeader')
  }),
  imgFetchError: createSelector(getState, (state) => {
    return state.get('imgFetchError')
  }),
  newsImages: createSelector(getState, (state) => {
    return state.get('newsImages')
  }),
  isFetchingImg: createSelector(getState, (state) => {
    return state.get('isFetchingImg')
  }),
  sequenceError: createSelector(getState, (state) => {
    return state.get('sequenceError')
  }),
  sequenceAvailabe: createSelector(getState, (state) => {
    return state.get('sequenceAvailabe')
  }),
  applicationDomains: createSelector(getState, (state) => {
    return state.get('applicationDomains')
  })
}

// Helper functions

function sanitizeFilters(filterTypes) {
  const filterOrder = [newsFeedFilterTypes.NEWSCATEGORY, newsFeedFilterTypes.STATUS]

  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filter in filterTypes) {
      const values = filterTypes[filter]
      const isSearchBox = false
      const categoryType = filter
      const categoryValues = !values
        ? []
        : values.map((val, index) => ({
          name: val,
          label: val,
          key: index,
          value: `${val}`,
        }))

      filterCategories.push({
        categoryType,
        categoryValues,
        searchBox: isSearchBox,
      })
    }
  })
  return filterCategories
}