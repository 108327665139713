//  Utils for handling data types

/**
  method to determine value is undefined

  @param {any} value
  @return {boolean}
*/
export const isUndefined = (value) => {
  return typeof value === 'undefined'
}

/**
  method to determine value is string

  @param {any}
  @return {boolean}
*/
export const isString = (value) => {
  return typeof value === 'string'
}

/**
  method to remove undefined values from object

  @param {object} object
  @return {object}
*/
export const rectifyObject = (object) => {
  return JSON.parse(JSON.stringify(object))
}

/**
  method to check object has any properties

  @param {object} object
  @return {boolean}
*/
export const isEmptyObject = (object) => {
  return Object.keys(object).length === 0
}

/**
  method to check array has any properties

  @param {array} array
  @return {boolean}
*/
export const isEmptyArray = (array) => {
  return array?.length === 0 || array?.size === 0
}

/**
  method to check array

  @param {array} array
  @return {boolean}
*/
export const isArray = (array) => {
  return Array.isArray(array)
}

/**
  method to check value is true

  @param {any} value
  @return {boolean}
*/
export const isTruthy = (value) => {
  return !!value
}

/**
  method to check value is object

  @param {any} value
  @return {boolean}
*/
export const isObject = (value) => {
  return value instanceof Object
}

/**
  method to check value is object

  @param {string} value
  @return {string}
*/
export const formatString = (value) => {
  return typeof value === 'string' ? value.trim() : value
}

/**
  method to remove properties from array

  @param {object}
  @param {array}
  @return {object}
 */
export const removeObjectProperties = (object, properties) => {
  if (!isObject(object) || isEmptyObject(object)) {
    return {}
  }

  let { ...newObject } = object

  properties.forEach(name => {
    if (isString(name)) {
      delete newObject[name]
    }
  })

  return newObject
}
/**
 * Time Format
 * @type {string}
 */
export const timeFormat = 'HH:mm:ss'

/**
 * Date with Time Stamp
 * @type {string}
 */
export const dateTimeFormat = 'YYYY/MM/DD HH:mm:ss'

export const setLocalStorage = (itemName, data) => {
  if (typeof data === 'object') {
    window.localStorage.setItem(itemName, JSON.stringify(data))
  } else {
    window.localStorage.setItem(itemName, data)
  }
}
