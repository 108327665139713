import { fromJS } from 'immutable'

const DATE_FORMATS = {
  date: ['DATE'],
  hour: ['HH'],
  month: ['YYYY', 'MM'],
  year: ['YYYY'],
  timestamp: []
}

export const getDateFormat = (format, type) => {
  if (format) {
    const dateFormat = DATE_FORMATS[format]
    if (!dateFormat) return {}

    return { format: dateFormat }
  } else {
    if (type !== 'TIMESTAMP') {
      return {}
    } else {
      return { format: DATE_FORMATS['date'] }
    }
  }
}

export const getDateFormatString = (format) => {
  if (format) {
    let dateFormatString
    Object.keys(DATE_FORMATS).some(formatKey => {
      if (fromJS(DATE_FORMATS[formatKey]).equals(fromJS(format))) {
        dateFormatString = formatKey
        return true
      }
    })
    return dateFormatString || ''
  } else {
    return ''
  }
}

export const formatDeviceTypesForDropdown = (deviceTypes) => {
  return fromJS(deviceTypes.map(({deviceTypeId, deviceType}) => {
    return {
      label: deviceType,
      value: deviceTypeId
    }
  }))
}

export const getAnalyticData = (data) => {
  const { measure, dimension } = data
  return {
    measures: (measure || []).map((measure) => {
      return {
        ...measure,
        showMenu: false
      }
    }),
    dimensions: dimension || []
  }
}

export const formatDataTypesForDropdown = (datatypes) => {
  return fromJS(datatypes.map(({shortName, displayName}) => {
    return {
      label: displayName,
      value: shortName
    }
  }))
}

export const CARD_CATEGORIES = {
  GENERIC: 'generic'
}

export const DATA_TYPE_AGGREGATION_MAPPINGS = {
  STRING: ['MAX', 'MIN'],
  INT: ['AVG', 'SUM', 'MAX', 'MIN'],
  LONG: ['AVG', 'SUM', 'MAX', 'MIN'],
  DOUBLE: ['AVG', 'SUM', 'MAX', 'MIN'],
  BOOL: ['MAX', 'MIN'],
  TIMESTAMP: ['MAX', 'MIN']
}
