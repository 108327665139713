import React, { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, SubmissionError } from 'redux-form'

import Alert from '../Alert'
import Loader from '../Loader'

// Hack to fix async validation bug in redux-form
const submitHandler = async (values, dispatch, props) => {
  const { asyncValidate, onSubmit } = props
  try {
    if (asyncValidate) {
      await asyncValidate(values, dispatch, props)
    }
  } catch (errors) {
    throw new SubmissionError(errors)
  }
  return await onSubmit(values, dispatch, props)
}

class Form extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    error: PropTypes.string,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    handleSubmit: PropTypes.func,
    submitButton: PropTypes.element,
    onSubmit: PropTypes.func.isRequired,
    renderError: PropTypes.func,
    loaderOnSubmit: PropTypes.bool
  }
  static defaultProps = {
    renderError: (error) => error
  }

  render() {
    const {
      error,
      children,
      handleSubmit,
      submitButton,
      invalid,
      submitting,
      renderError,
      form,
      loaderOnSubmit
    } = this.props
    const submitOverrides = {
      disabled: (submitButton && submitButton.props.disabled) || invalid || submitting
    }
    const clonedSubmit = submitButton && cloneElement(submitButton, submitOverrides)
    return (
      <div>
        {(loaderOnSubmit && submitting) && <Loader />}
        {error && <Alert color='danger' alertType='outline' text={renderError(error)} />}
        <form name={form} onSubmit={handleSubmit(submitHandler)} noValidate>
          {typeof children === 'function'
            ? children(clonedSubmit)
            : (
              <fieldset>
                {children}
                {clonedSubmit}
              </fieldset>
            )
          }
        </form>
      </div>
    )
  }
}

export default reduxForm({
  shouldAsyncValidate: ({ trigger }) => trigger === 'blur'
})(Form)
