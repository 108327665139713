import React from 'react'
import PropTypes from 'prop-types'

import TableCell from '../TableCell'
import { TABLE_ROW_STATES, AUX_CELL_CLASSNAME } from '../utils'
import { getAuxComponent } from '../tableIcons'
import RowWrapper from './RowWrapper'
import {
  useIntl, injectIntl
} from 'react-intl'

const TableRow = ({
  intl,
  isSelectable,
  handleStateChange,
  cellsInfo,
  accessors,
  renderers,
  disabled,
  checkboxDisabled,
  displayRowInfoArrow,
  size,
  columns,
  rowState,
  columnWidths,
}) => {
  const getClickHandler = () => {
    if (!disabled && !checkboxDisabled) {
      return () =>
        handleStateChange(
          cellsInfo && cellsInfo.rowInfo
            ? TABLE_ROW_STATES.isExpanded
            : isSelectable
              ? TABLE_ROW_STATES.isSelected
              : null,
        )
    }
  }
  const renderAuxComponent = () => {
    const Component = getAuxComponent({
      rowInfoArrow: displayRowInfoArrow,
      checkbox: isSelectable,
      checkboxDisabled,
      size,
    })
    return (
      Component && (
        <td className={AUX_CELL_CLASSNAME}>
          <Component
            {...(isSelectable && {
              isChecked: rowState.isSelected,
            })}
            {...(displayRowInfoArrow && { pointingDown: rowState.isExpanded })}
            disabled={disabled}
          />
        </td>
      )
    )
  }

  const shouldDisplayInfoRow = Boolean(rowState.isExpanded && cellsInfo.rowInfo)

  // access and render cells
  const cells = accessors
    .map(fn => fn(cellsInfo))
    .map((cell, index) => {
      const cellRenderer = renderers[index]
      return cellRenderer ? cellRenderer(cell, cellsInfo) : cell
    })

  return (
    <>
      <RowWrapper
        onClick={getClickHandler()}
        isExpanded={rowState.isExpanded}
        disabled={disabled}
        size={size}
      >
        {renderAuxComponent()}
        {cells.map((value, i) => (
          <TableCell
            key={i} size={size}
            columnWidth={columnWidths && columnWidths[i]}
            data-th={intl.formatMessage({ id: columns[i].header.props.id })}
          >
            {value}
          </TableCell>
        ))}
      </RowWrapper>
      {shouldDisplayInfoRow && (
        <RowWrapper isInfoCell>
          <TableCell isInfoCell colSpan={cells.length + 1}>
            {cellsInfo.rowInfo}
          </TableCell>
        </RowWrapper>
      )}
    </>
  )
}

TableRow.propTypes = {
  intl: useIntl.isRequired,
  cellsInfo: PropTypes.object.isRequired,
  /** if at least one other row has rowInfo, then info arrow has to be rendered for table layout consistency */
  displayRowInfoArrow: PropTypes.bool.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  rowState: PropTypes.object.isRequired,
  handleStateChange: PropTypes.func,
  disabled: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
  accessors: PropTypes.array.isRequired,
  columns: PropTypes.array,
  renderers: PropTypes.array.isRequired,
  columnWidths: PropTypes.array,
}

TableRow.defaultProps = {
  handleStateChange: () => { },
  disabled: false,
  checkboxDisabled: false,
  columnWidth: 10,
}

export default injectIntl(TableRow)
