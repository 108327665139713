import React from 'react'
import * as Yup from 'yup'
import { FormattedMessage } from 'react-intl'
import {orgTypes} from 'constants/orgUsersListing'
const testSuccessively = (schemas) =>
  async function (value) {
    const context = this
    const asyncValidationPromises = schemas.map((schema) =>
      schema.validate(value)
    )

    try {
      await Promise.all(asyncValidationPromises)
    } catch ({ errors }) {
      const option = {
        path: context.path,
        message: errors[0],
      }
      return context.createError(option)
    }

    return true
}

export const asyncValidate =Yup.object().shape({
  name: Yup.string().test(
    'is valid name',
    testSuccessively([
      Yup.string().required(<FormattedMessage id='next.MANAGE_ORGANIZATION_NAME_REQUIRED' />),
      Yup.string().trim().min(2,
          <FormattedMessage
            values={{ length: 2 }}
            id='next.MANAGE_ORGANIZATION_NAME_MIN_LENGTH_ERROR'
          />
        ),
      Yup.string().trim().max(50,
          <FormattedMessage
            values={{ length: 50 }}
            id='next.MANAGE_ORGANIZATION_NAME_MAX_LENGTH_ERROR'
          />
        ),
    ])
  ),
  type: Yup.mixed().required(
    <FormattedMessage id='next.MANAGE_ORGANIZATION_TYPE_REQUIRED' />
  ),
  status: Yup.boolean().required(
    <FormattedMessage id='next.MANAGE_ORGANIZATION_STATUS_REQUIRED' />
  ),
  brand: Yup.mixed().when('type', (type, schema) =>{
    return type === orgTypes.PARTNERS
      ? schema.required(
          <FormattedMessage id='next.MANAGE_ORGANIZATION_BRAND_REQUIRED' />
        )
      : schema
      }
  ),
  desc: Yup.string().trim()
  .required(<FormattedMessage key='descReq' id={'next.MANAGE_ORGANIZATION_DESCRIPTION_REQUIRED'} />)
  .max(100,
    <FormattedMessage values={{ length: 100 }} id='next.MANAGE_ORGANIZATION_DESCRIPTION_MAX_LENGTH_ERROR' />
    ),
  historicalDataSubscription: Yup.string().required(
    <FormattedMessage values={{ length: 100 }} id='next.MANAGE_ORGANIZATION_HISTORICAL_DATA_SUBSCRIPTION_REQUIRED' />
  ),
})
