import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import {
  selectors as pageSelectors
} from 'store/modules/Pagination'
import {
  actions as privilegActions
} from 'store/modules/Privileges'
import {
  selectors as listingSelectors,
  constants as listingConstants
} from 'store/modules/GenericListing'
// import {
//   selectors as userSelectors
// } from 'store/modules/User'

import { filterTypes as usersFilterTypes, moduleId } from 'constants/usersListing'

// Constants
export const constants = {
  FETCH: 'USERS/FETCH',
  FETCH_SUCCESS: 'USERS/FETCH_SUCCESS',
  RECEIVE_USERS: 'USERS/RECEIVE_USERS',
  INVITING_USER: 'USERS/INVITING_USER',
  INVITE_USER_SUCCESS: 'USERS/INVITE_USER_SUCCESS',
  INVITE_USER_FAILURE: 'USERS/INVITE_USER_FAILURE',
  FETCH_FAILURE: 'USERS/FETCH_FAILURE',
  TOGGLE_VIEW: 'USERS/TOGGLE_VIEW',
  SET_SEARCH_TERM: 'USERS/SET_SEARCH_TERM',
  ADD_FILTER_CATEGORY: 'USERS/ADD_FILTER_CATEGORY',
  ADD_FILTER_CATEGORY_VALUES: 'USERS/ADD_FILTER_CATEGORY_VALUES',
  ADD_FILTERS: 'USERS/ADD_FILTERS',
  SELECTED_FILTER: 'USERS/SELECTED_FILTER',
  SORT: 'USERS/SORT',
  STATUS_TOGGLE: 'USERS/TOGGLE_USER_STATUS',
  CLOSE_INVITE_USER_FORM: 'USER/CLOSE_INVITE_USER_FORM',
  REMOVE_USER: 'USERS/REMOVE_USER',
  REMOVE_USER_SUCCESS: 'USERS/REMOVE_USER_SUCCESS',
  REMOVE_USER_FAILURE: 'USERS/REMOVE_USER_FAILURE',
  RESET_REMOVE_USER_SUCCESS: 'USERS/RESET_REMOVE_USER_SUCCESS',
  ADD_USER_SUCCESS: 'USERS/ADD_USER_SUCCESS',
  RESET_ADD_USER_SUCCESS: 'USERS/RESET_ADD_USER_SUCCESS',
  SET_USER_TYPE: 'USERS/SET_USER_TYPE',
  LOADROLES: 'USERS/LOADROLES',
  RECEIVE_ROLES: 'USERS/RECEIVE_ROLES',
  FETCH_ROLE_FAILURE: 'USERS/FETCH_ROLE_FAILURE',
  REDUCE_TOTAL_PAGE: 'USERS/REDUCE_TOTAL_PAGE',
  ADD_TOTAL_PAGE: 'USERS/ADD_TOTAL_PAGE',
  ADDROLES: 'USERS/ADDROLES',
  ADD_ROLE_SUCCESS: 'USERS/ADD_ROLE_SUCCESS',
  REMOVE_ROLE_SUCCESS: 'USERS/REMOVE_ROLE_SUCCESS',
  OPEN_ADD_ROLE_FORM: 'USERS/OPEN_ADD_ROLE_FORM',
  CLOSE_ADD_ROLE_FORM: 'USERS/CLOSE_ADD_ROLE_FORM',
  OPEN_ADD_USER_FORM: 'USERS/OPEN_ADD_USER_FORM',
  CLOSE_ADD_USER_FORM: 'USERS/CLOSE_ADD_USER_FORM',
  REMOVEROLES_SUCCESS: 'USERS/REMOVEROLES_SUCCESS',
  REMOVEROLES_FAILURE: 'USERS/REMOVEROLES_FAILURE',
  REMOVEROLES: 'USERS/REMOVEROLES',
  RESET_REMOVE_ROLE_STATUS: 'USERS/RESET_REMOVE_ROLE_STATUS',
  EDIT_ROLES_STATUS: 'USERS/EDIT_ROLES_STATUS',
  RESET_ADD_ROLE_STATUS: 'USERS/RESET_ADD_ROLE_STATUS',
  ADD_ROLE_FAILURE: 'USERS/ADD_ROLE_FAILURE',
  TOGGLE_FETCH: 'USERS/TOGGLE_FETCH',
  RECEIVE_DOMAINS: 'USERS/RECEIVE_DOMAINS',
  STATUS_TOGGLE_FAILURE: 'USERS/STATUS_TOGGLE_FAILURE',
  STATUS_TOGGLE_SUCCESS: 'USERS/STATUS_TOGGLE_SUCCESS',
  RESET_STATUS_TOGGLE: 'USERS/RESET_STATUS_TOGGLE',
  RECEIVE_COUNTRIES: 'USERS/RECEIVE_COUNTRIES',
  RECEIVE_COUNTRIES_ZONE: 'USERS/RECEIVE_COUNTRIES_ZONE',
  EDIT_ROLE_NAME: 'USERS/EDIT_ROLE_NAME',
  EDIT_ROLE_NAME_SUCCESS: 'USERS/EDIT_ROLE_NAME_SUCCESS',
  EDIT_ROLE_NAME_FAILURE: 'USERS/EDIT_ROLE_NAME_FAILURE',
  OPEN_EDIT_ROLE_FORM: 'USERS/OPEN_EDIT_ROLE_FORM',
  CLOSE_EDIT_ROLE_FORM: 'USERS/CLOSE_EDIT_ROLE_FORM',
  RESET_EDIT_ROLE_NAME_SUCCESS: 'USERS/RESET_EDIT_ROLE_NAME_SUCCESS',
  RESET_REMOVE_USER_FAILURE: 'USERS/RESET_REMOVE_USER_FAILURE',
  UPDATE_USER_FAILURE: 'USERS/UPDATE_USER_FAILURE',
  UPDATE_USER_SUCCESS: 'USERS/UPDATE_USER_SUCCESS',
  RESET_UPDATE_USER_SUCCESS: 'USERS/RESET_UPDATE_USER_SUCCESS',
  OPEN_RESET_PASSWORD_FORM: 'USERS/OPEN_RESET_PASSWORD_FORM',
  CLOSE_RESET_PASSWORD_FORM: 'USERS/CLOSE_RESET_PASSWORD_FORM',
  RECEIVE_ORGNIZATIONS: 'USERS/RECEIVE_ORGNIZATIONS',
  RECEIVE_ORGANIZATION_TYPES: 'USERS/RECEIVE_ORGANIZATION_TYPES',
  RECEIVE_SELECTED_ORGNIZATIONS: 'USERS/RECEIVE_SELECTED_ORGNIZATIONS',
  RECEIVE_USER_FACETS: 'USERS/RECEIVE_USER_FACETS',
  FETCH_LANGUAGES_SUCCESS: 'USER_REGISTRATION_CONSOLIDATED/FETCH_LANGUAGES_SUCCESS',
  REGISTER_USER: 'USERS/REGISTER_USER',
  REGISTER_USER_SUCCESS: 'USERS/REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'USERS/REGISTER_USER_FAILURE',
  VALIDATE_USER: 'USERS/VALIDATE_USER',
  VALIDATE_USER_SUCCESS: 'USERS/VALIDATE_USER_SUCCESS',
  VALIDATE_USER_FAILURE: 'USERS/VALIDATE_USER_FAILURE',
  RESET_REGISTRATION_STATE: 'USERS/RESET_REGISTRATION_STATE',
  RESET_VALIDATION_STATE: 'USERS/RESET_VALIDATION_STATE',
  RECIEVE_BRAND_AND_PARTNER_TYPE_SUCCESS: 'USERS/RECIEVE_BRAND_AND_PARTNER_TYPE_SUCCESS',
  SET_VALIDATE_TYPE_STATE: 'USERS/SET_VALIDATE_TYPE_STATE',
  RESET_UPDATE_USER_DETAILS_ERROR: 'USERS/RESET_UPDATE_USER_DETAILS_ERROR',
  VALIDATE_SELF_REG: 'USERS/VALIDATE_SELF_REG',
  VALIDATE_SELF_REG_SUCCESS: 'USERS/VALIDATE_SELF_REG_SUCCESS',
  VALIDATE_SELF_REG_FAILURE: 'USERS/VALIDATE_SELF_REG_FAILURE',
  RESET_SELF_REG_VALIDATION: 'USERS/RESET_SELF_REG_VALIDATION',
  RESET_SELF_REG_ERROR: 'USERS/RESET_SELF_REG_ERROR'
}
const pageLimit = 10
const userFilters = [
  {
    categoryType: 'Role',
    categoryValues: [
      {
        count: 0,
        name: 'Owner'
      },
      {
        count: 0,
        name: 'Manager'
      }
    ]
  },
  {
    categoryType: 'Status',
    categoryValues: [
      {
        count: 0,
        name: 'Active'
      },
      {
        count: 0,
        name: 'Inactive'
      },
      {
        count: 0,
        name: 'Pending'
      },
      {
        count: 0,
        name: 'In Progress'
      }
    ]
  }
]
// Action Creators
export const actions = {
  resetAPIError() {
    return ({ type: constants.RESET_UPDATE_USER_DETAILS_ERROR })
  },
  getUserFetchQuery(getState) {
    const filterBy = selectors.selectedFilterValues(getState()).toJS()
    const sortBy = selectors.sortColumn(getState())
    const sortDirection = selectors.sortDirection(getState())
    const orderBy = sortDirection === true ? 'asc' : 'desc'
    const searchTerm = selectors.searchTerm(getState())
    const page = pageSelectors.pagination(getState()).getIn(['usersPagination', 'currentPage'])
    const query = {
      filterBy: filterBy,
      sortBy: sortBy,
      orderBy: orderBy,
      searchBy: searchTerm,
      page: page,
      pageLimit
    }
    return query
  },
  loadUsers(isHandheldDevice = false) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchUsers',
      args: actions.getUserFetchQuery,
      onSuccess: ({ data, headers }) => ({ type: constants.RECEIVE_USERS, data, headers, isHandheldDevice }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  loadRoles(orgId = null) {
    return safeFetch({
      onFetch: () => ({ type: constants.LOADROLES }),
      apiFunction: 'fetchRoles',
      args: { orgId: orgId },
      getResponse: true,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_ROLES, data, orgId }),
      onFailure: (error) => ({ type: constants.FETCH_ROLE_FAILURE, error: error.message })
    })
  },
  addMoreRole(role) {
    return safeFetch({
      onFetch: () => ({ type: constants.ADDROLES }),
      apiFunction: 'addRoles',
      args: role,
      onSuccess: ({ data }) => ({ type: constants.ADD_ROLE_SUCCESS, addedRole: role, status: 'success' }),
      onFailure: (error) => ({ type: constants.ADD_ROLE_FAILURE, error: error.message }),
      throwError: true
    })
  },
  openAddRoleForm() {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_ADD_ROLE_FORM })
    }
  },
  closeAddRoleForm() {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_ADD_ROLE_FORM })
    }
  },
  removeRole(roleId, roleName) {
    return safeFetch({
      onFetch: () => ({ type: constants.REMOVEROLES }),
      apiFunction: 'RemoveRole',
      args: roleId,
      onSuccess: ({ data }) => {
        return { type: constants.REMOVEROLES_SUCCESS, roleId, status: 204, roleName }
      },
      onFailure: (error) => ({ type: constants.REMOVEROLES_FAILURE, error: error.default, status: error.status }),
      throwError: true
    })
  },
  editRoleName(name, roleId) {
    return safeFetch({
      onFetch: () => ({ type: constants.EDIT_ROLE_NAME }),
      apiFunction: 'EditRoleName',
      args: { name, roleId },
      onSuccess: ({ data }) => {
        return (dispatch) => {
          dispatch(privilegActions.updateSelectedRole(name))
          dispatch({ type: constants.EDIT_ROLE_NAME_SUCCESS })
        }
      },
      onFailure: (error) => ({ type: constants.EDIT_ROLE_NAME_FAILURE, error: error.default, status: error.status }),
      throwError: true
    })
  },
  resetEditRoleNameStatus() {
    return { type: constants.RESET_EDIT_ROLE_NAME_SUCCESS }
  },
  reseteditStatus() {
    return (dispatch) => {
      dispatch({ type: constants.RESET_EDIT_ROLES_STATUS })
    }
  },
  resetRemoveRoleStatus() {
    return (dispatch) => {
      dispatch({ type: constants.RESET_REMOVE_ROLE_STATUS })
    }
  },
  resetAddRoleStatus() {
    return (dispatch) => {
      dispatch({ type: constants.RESET_ADD_ROLE_STATUS })
    }
  },
  updateUserDetails(user, userId, showNotification) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updateUserDetails',
      args: { user, userId },
      onSuccess: () => ({ type: constants.UPDATE_USER_SUCCESS, showNotification }),
      onFailure: (error) => ({ type: constants.UPDATE_USER_FAILURE, error }),
      throwError: true
    })
  },
  updateProfileStatus() {
    return { type: constants.RESET_UPDATE_USER_SUCCESS }
  },
  applySort(sortColumn) {
    return { type: constants.SORT, sortColumn }
  },
  setSearchTerm(searchTerm) {
    return { type: constants.SET_SEARCH_TERM, searchTerm }
  },
  addfilterCatogory(filterType, selectedValue) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.SELECTED_FILTER, selectedValue })
    }
  },
  addfilterCatogoryValues(categoryValue, isChecked) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.FETCH })
      const userFilters = selectors.filters(getState()).toJS()
      const filters = userFilters.map((filter) => {
        const categoryValues = filter.categoryValues
        const modifiedCategoryValues = categoryValues.map((item) => {
          return item.name === categoryValue
            ? { ...item, checked: isChecked }
            : { ...item }
        }
        )
        return { ...filter, categoryValues: modifiedCategoryValues }
      }
      )
      dispatch({ type: constants.ADD_FILTERS, filters })
      dispatch({ type: constants.ADD_FILTER_CATEGORY_VALUES, categoryValue })
    }
  },
  loadUserFilters() {
    return async (dispatch, getState) => {
      dispatch({ type: constants.FETCH })
      try {
        const filters = userFilters.map((filter) => {
          const categoryValues = filter.categoryValues
          const modifiedCategoryValues = categoryValues.map((item) => {
            return { checked: false, ...item }
          }
          )
          return { ...filter, categoryValues: modifiedCategoryValues }
        }
        )
        dispatch({ type: constants.ADD_FILTERS, filters })
      } catch (error) {
        dispatch({ type: constants.FETCH_FAILURE, error: error })
      }
    }
  },
  toggleActivation(userId, status) {
    return async (dispatch) => {
      await dispatch({ type: constants.STATUS_TOGGLE })
      await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'changeStatus',
        args: { userId, status },
        onSuccess: (res, getState) => {
          const data = listingSelectors.listingData(getState(), moduleId)
          let newStatus = status.status ? 'Active' : 'Inactive'
          const newData = data.update(data.findIndex(item => item.get('userId') === userId), item => item.set('status', newStatus)).toJS()
          return ({
            type: listingConstants.RECEIVE_LIST,
            listingName: moduleId,
            data: newData,
            headers: { get: () => listingSelectors.totalPages(getState(), moduleId) }
          })
        },
        onFailure: (error) => ({ type: constants.STATUS_TOGGLE_FAILURE, error: error.message })
      }))
      await dispatch({ type: constants.STATUS_TOGGLE_SUCCESS })
      return true
    }
  },
  resetToggleStatusError() {
    return { type: constants.RESET_STATUS_TOGGLE }
  },
  inviteUser(user) {
    return safeFetch({
      onFetch: () => ({ type: constants.INVITING_USER }),
      apiFunction: 'invitingUser',
      args: user,
      onSuccess: ({ data }, getState) => {
        const userNumb = selectors.usersNumber(getState()) + 1
        return { type: constants.INVITE_USER_SUCCESS, user: data, userNumb }
      },
      onFailure: ({ status }) => ({ type: constants.INVITE_USER_FAILURE, error: status }),
      throwError: true
    })
  },
  closeInviteUserForm() {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_INVITE_USER_FORM })
    }
  },
  removeUser(userId, name) {
    return safeFetch({
      onFetch: () => ({ type: constants.INVITING_USER }),
      apiFunction: 'removeUser',
      args: userId,
      onSuccess: ({ data }, getState) => {
        const userNumb = selectors.usersNumber(getState()) - 1
        return { type: constants.REMOVE_USER_SUCCESS, userId, status: 'success', userNumb, name }
      },
      onFailure: ({ status, errorCode }) => ({ type: constants.REMOVE_USER_FAILURE, status: status === 422 ? errorCode : status })
    })
  },
  resetremoveUserFailure() {
    return { type: constants.RESET_REMOVE_USER_FAILURE }
  },
  openAddUserForm() {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_ADD_USER_FORM })
    }
  },
  closeAddUserForm() {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_ADD_USER_FORM })
    }
  },
  enableEditRole() {
    return { type: constants.OPEN_EDIT_ROLE_FORM }
  },
  resetRemoveUserStatus() {
    return { type: constants.RESET_REMOVE_USER_SUCCESS }
  },
  addUserSuccessMessage() {
    return { type: constants.ADD_USER_SUCCESS, status: 'success' }
  },
  resetAddUserStatus() {
    return { type: constants.RESET_ADD_USER_SUCCESS }
  },
  setusertype(Usertype) {
    return { type: constants.SET_USER_TYPE, userType: Usertype }
  },
  refreshUserPage() {
    return async (dispatch, getState) => {
      dispatch(actions.loadUsers())
    }
  },
  getDomains(orgId) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.LOADROLES }),
        apiFunction: 'fetchDomains',
        args: { orgId: orgId },
        getResponse: true,
        onSuccess: ({ data }) => ({ type: constants.RECEIVE_DOMAINS, data }),
        onFailure: (error) => ({ type: constants.FETCH_DOMAIN_FAILURE, error: error.message })
      }))
      return resp.data
    }
  },
  loadCountries(country = null) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'loadCountries',
      args: { searchBy: country },
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES, data, country }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  loadCountriesZone(countryZone = null) {
    return async (dispatch) => {
      const { data } = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'loadCountriesZone',
        args: { countryId: countryZone },
        getResponse: true,
        onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES_ZONE, data, countryZone }),
        onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
      }))
      return data
    }
  },
  openResetPasswordForm() {
    return { type: constants.OPEN_RESET_PASSWORD_FORM }
  },
  closeResetPasswordForm() {
    return { type: constants.CLOSE_RESET_PASSWORD_FORM }
  },
  fetchOrganizationTypes() {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'fetchOrgTypes',
        getResponse: true,
        args: { operation: 'manage_accounts' },
        onSuccess: ({ data }) => ({ type: constants.RECEIVE_ORGANIZATION_TYPES, data }),
        onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
      }))
      return resp.data
    }
  },
  fetchOrganizations(type) {
    return async (dispatch) => {
      const resp = await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH }),
        apiFunction: 'loadOrganization',
        getResponse: true,
        args: { operation: 'manage_accounts', type },
        onSuccess: ({ data }) => ({ type: constants.RECEIVE_ORGNIZATIONS, data }),
        onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
      }))
      return resp.data
    }
  },
  searchOrganizations(searchOrg) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'loadOrganization',
      args: { operation: 'manage_accounts', searchBy: searchOrg },
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_SELECTED_ORGNIZATIONS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  fetchFilterCategories(isPartnerOrCustomerView) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'loadUserFacets',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_USER_FACETS, data, isPartnerOrCustomerView }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
    })
  },
  loadUserFacets() {
    return async (dispatch, getState) => {
      // const permission = userSelectors.getUserPrivilege(getState())
      // const isPartnerOrCustomerView = permission && (permission.get('lwpartner') && !!permission.getIn(['lwpartner', 'VIEW'])) ||
      //   (permission.get('lwcustomer') && !!permission.getIn(['lwcustomer', 'VIEW']))
      // dispatch(actions.fetchFilterCategories(isPartnerOrCustomerView))
      dispatch(actions.fetchFilterCategories())
    }
  },
  getAllLanguages() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchAllLanguagesUserRegistration',
      onSuccess: ({ data }) => ({ type: constants.FETCH_LANGUAGES_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.FETCH_FAILURE, status })
    })
  },
  loadRolesWithoutAuth() {
    return safeFetch({
      onFetch: () => ({ type: constants.LOADROLES }),
      apiFunction: 'fetchRolesWithoutAuth',
      getResponse: true,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_ROLES, data }),
      onFailure: (error) => {
        return ({ type: constants.FETCH_ROLE_FAILURE, error: error.message })
      }
    })
  },
  loadCountriesWithoutAuth() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchCountriesWithoutAuth',
      getResponse: true,
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES, data }),
      onFailure: (error) => {
        return ({ type: constants.FETCH_FAILURE, error: error.message })
      }
    })
  },
  registerUser(user) {
    return safeFetch({
      onFetch: () => ({ type: constants.REGISTER_USER }),
      apiFunction: 'registerUser',
      args: user,
      onSuccess: ({ data }, getState) => {
        const userNumb = selectors.usersNumber(getState()) + 1
        return { type: constants.REGISTER_USER_SUCCESS, user: data, userNumb }
      },
      onFailure: (error) => {
        return ({ type: constants.REGISTER_USER_FAILURE, error: error })
      },
      throwError: true
    })
  },
  validateUser(userData) {
    return safeFetch({
      onFetch: () => ({ type: constants.VALIDATE_USER }),
      apiFunction: 'validateUser',
      args: userData,
      onSuccess: ({ data }, getState) => {
        return { type: constants.VALIDATE_USER_SUCCESS, user: data }
      },
      onFailure: (error) => {
        return ({ type: constants.VALIDATE_USER_FAILURE, error: error })
      },
      throwError: true
    })
  },
  resetRegistrationState() {
    return { type: constants.RESET_REGISTRATION_STATE }
  },
  resetValidationState() {
    return { type: constants.RESET_VALIDATION_STATE }
  },
  loadBrandAndPartnerType(orgId = null) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchPartnerAndBrandType',
      getResponse: true,
      args: { orgId: orgId },
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_COUNTRIES, data }),
      onFailure: (error) => {
        return ({ type: constants.FETCH_FAILURE, error: error.message })
      }
    })
  },
  setValidateType(validateType) {
    return { type: constants.SET_VALIDATE_TYPE_STATE, value: validateType }
  },
  validateSelfReg(user) {
    return safeFetch({
      onFetch: () => ({ type: constants.VALIDATE_SELF_REG }),
      apiFunction: 'validateSelfReg',
      args: user,
      onSuccess: ({ data }) => { return { type: constants.VALIDATE_SELF_REG_SUCCESS, data } },
      onFailure: (error) => {
        return ({ type: constants.VALIDATE_SELF_REG_FAILURE, error: error })
      },
      throwError: true
    })
  },
  resetValidation() {
    return { type: constants.RESET_SELF_REG_VALIDATION }
  },
  resetSelfRegError() {
    return { type: constants.RESET_SELF_REG_ERROR }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  data: [],
  usersNumber: 0,
  searchTerm: '',
  sortColumn: 'firstName',
  sortDirection: true,
  selectedFilterValues: [],
  filterType: [],
  filterBy: [],
  filters: [],
  totalPages: 0,
  openUserInviteForm: false,
  removeUserSuccess: null,
  removeUserFailure: null,
  addUserSuccess: null,
  newUser: {},
  roles: [],
  roleFetching: false,
  roleFechError: null,
  inviting: false,
  invited: false,
  invitedFailure: false,
  fetchSuccess: false,
  openAddRoleForm: false,
  openAddUserForm: false,
  addRoleSuccess: null,
  addedRole: null,
  removeRolesSuccess: false,
  removeRolesFailure: false,
  removeRoleMsg: null,
  editstatus: null,
  togglefetching: false,
  domains: [],
  toggleStatusError: null,
  countries: [],
  countriesZone: [],
  openEditRoleForm: false,
  editRoleNameSuccess: false,
  updateusersuccess: false,
  openresetPasswordForm: false,
  organizations: [],
  organizationTypes: [],
  orgRoles: [],
  searchedOrganizations: [],
  searchedCountries: [],
  searchedCountriesZone: [],
  userFacets: [],
  languages: [],
  registering: false,
  registered: false,
  registerFailure: false,
  registerError: null,
  validatingUser: false,
  validateUserError: null,
  validated: false,
  brandAndPartnerType: {},
  validateType: '',
  userUpdateError: null,
  isSelfRegValidated: false
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.INVITING_USER:
      return state
        .set('inviting', true)
    case constants.INVITE_USER_SUCCESS:
      return state
        .update('data', data => data.push(fromJS(action.user)))
        .set('invited', true)
        .set('usersNumber', action.userNumb)
        .set('newUser', fromJS(action.user))
        .set('addUserSuccess', action.status)
    case constants.INVITE_USER_FAILURE:
      return state
        .set('invitedFailure', true)
    case constants.CLOSE_INVITE_USER_FORM:
      return state
        .set('openUserInviteForm', false)
    case constants.REMOVE_USER:
      return state
        .update('data', data => data.filter(user => user.get('userId') !== action.userId
        ))
    case constants.REMOVE_USER_SUCCESS:
      return state
        .set('removeUserSuccess', { status: action.status, name: action.name })
        .update('data', data => data.filter(user => user.get('userId') !== action.userId))
        .set('usersNumber', action.userNumb)
    case constants.REMOVE_USER_FAILURE:
      return state
        .set('removeUserFailure', { status: action.status })
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.FETCH_SUCCESS:
      return state
        .set('fetching', false)
        .set('fetchSuccess', true)
    case constants.RECEIVE_USERS:
      if (action.isHandheldDevice) {
        let users = state.get('data')
        return state
          .set('data', fromJS([
            ...users,
            ...action.data
          ]))
          .set('totalPages', parseInt(fromJS(action.headers.get('x-pagination-count'))))
          .set('fetching', false)
      }
      return state
        .set('data', fromJS(action.data))
        .set('totalPages', parseInt(fromJS(action.headers.get('x-pagination-count'))))
        .set('usersNumber', fromJS(action.data.length))
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.TOGGLE_VIEW:
      return state
        .set('tileView', action.toggle)
    case constants.SET_SEARCH_TERM:
      return state
        .set('searchTerm', action.searchTerm)
    case constants.ADD_FILTER_CATEGORY:
      return state
        .set('filterType', action.filterType)
    case constants.ADD_FILTER_CATEGORY_VALUES:
      return state
        .updateIn(['filterBy'], (state) => {
          return state.find(category => category === action.categoryValue)
            ? state.filter(item => item !== action.categoryValue) : state.push(action.categoryValue)
        })
    case constants.SELECTED_FILTER:
      return state
        .updateIn(['selectedFilterValues'], (state) => {
          return state.find(selectedFilter => selectedFilter === action.selectedValue)
            ? state.filter(item => item !== action.selectedValue) : state.push(action.selectedValue)
        })
    case constants.ADD_FILTERS:
      return state
        .set('filters', fromJS(action.filters))
        .set('fetching', false)
    case constants.SORT:
      return state
        .set('sortColumn', fromJS(action.sortColumn))
        .set('sortDirection', !state.get('sortDirection'))
    case constants.STATUS_TOGGLE:
      return state
        .set('togglefetching', true)
    case constants.STATUS_TOGGLE_SUCCESS:
      return state
        .set('togglefetching', false)
    case constants.RESET_REMOVE_USER_SUCCESS:
      return state
        .set('removeUserSuccess', null)
    case constants.RESET_REMOVE_USER_FAILURE:
      return state
        .set('removeUserFailure', null)
    case constants.ADD_USER_SUCCESS:
      return state
        .set('addUserSuccess', action.status)
        .set('newUser', action.user)
    case constants.RESET_ADD_USER_SUCCESS:
      return state
        .set('addUserSuccess', null)
    case constants.SET_USER_TYPE:
      return state
        .set('userType', action.userType)
    case constants.LOADROLES:
      return state
        .set('roleFetching', true)
        .set('addedRole', null)
        .set('removeRoleMsg', null)
        .set('removeRolesFailure', false)
        .set('removeRolesSuccess', false)
    case constants.RECEIVE_ROLES:
      if (action.orgId !== null) {
        return state
          .set('roleFetching', false)
          .set('orgRoles', fromJS(action.data))
          .set('roleFechError', null)
      } else {
        return state
          .set('roleFetching', false)
          .set('roles', fromJS(action.data))
          .set('roleFechError', null)
      }
    case constants.FETCH_ROLE_FAILURE:
      return state
        .set('roleFechError', fromJS(action.error))
        .set('roleFetching', false)
    case constants.OPEN_ADD_ROLE_FORM:
      return state
        .set('openAddRoleForm', true)
        .set('openEditRoleForm', false)
    case constants.CLOSE_ADD_ROLE_FORM:
      return state
        .set('openAddRoleForm', false)
        .set('openEditRoleForm', false)
    case constants.OPEN_ADD_USER_FORM:
      return state
        .set('openAddUserForm', true)
    case constants.CLOSE_ADD_USER_FORM:
      return state
        .set('openAddUserForm', false)
    case constants.OPEN_EDIT_ROLE_FORM:
      return state
        .set('openEditRoleForm', true)
        .set('openAddRoleForm', false)
    case constants.ADD_ROLE_SUCCESS:
      return state
        .set('addedRole', action.addedRole)
    case constants.EDIT_ROLE_NAME_SUCCESS:
      return state
        .set('editRoleNameSuccess', true)
    case constants.RESET_EDIT_ROLE_NAME_SUCCESS:
      return state
        .set('editRoleNameSuccess', false)
    case constants.REMOVEROLES_SUCCESS:
      return state
        .set('removeRolesSuccess', true)
        .set('removeRoleMsg', { status: action.status, name: action.roleName })
        .update('roles', roles => roles.filter(role => role.get('roleId') !== action.roleId))
    case constants.REMOVEROLES_FAILURE:
      return state
        .set('removeRoleMsg', { status: action.status })
        .set('removeRolesFailure', true)
    case constants.RESET_ADD_ROLE_STATUS:
      return state
        .set('addRoleSuccess', null)
        .set('addedRole', null)
    case constants.RESET_REMOVE_ROLE_STATUS:
      return state
        .set('removeRoleMsg', null)
        .set('removeRolesFailure', false)
        .set('removeRolesSuccess', false)
        .set('deleteRoleMessage', null)
    case constants.EDIT_ROLES_STATUS:
      return state
        .set('editstatus', 'Edit process in progress')
    case constants.RESET_EDIT_ROLES_STATUS:
      return state
        .set('editstatus', null)
    case constants.REDUCE_TOTAL_PAGE:
      return state
        .set('totalPages', action.totalPages - 1)
    case constants.ADD_TOTAL_PAGE:
      return state
        .set('totalPages', action.totalPages + 1)
    case constants.TOGGLE_FETCH:
      return state
        .set('togglefetching', false)
    case constants.STATUS_TOGGLE_FAILURE:
      return state
        .set('togglefetching', false)
        .set('toggleStatusError', action.error)
    case constants.RESET_STATUS_TOGGLE:
      return state
        .set('toggleStatusError', null)
    case constants.RECEIVE_DOMAINS:
      return state
        .set('domains', fromJS(action.data))
    case constants.RECEIVE_COUNTRIES:
      if (action.country) {
        return state
          .set('searchedCountries', fromJS(action.data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      } else {
        return state
          .set('countries', fromJS(action.data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      }
    case constants.RECEIVE_COUNTRIES_ZONE:
      if (action.countryZone) {
        const { data } = action
        return state
          .set('searchedCountriesZone', fromJS(data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      } else {
        return state
          .set('countriesZone', fromJS(action.data))
          .set('fetching', false)
          .set('fetchSuccess', true)
      }
    case constants.UPDATE_USER_SUCCESS:
      return state
        .set('fetching', false)
        .set('updateusersuccess', action.showNotification)
        .set('userUpdateError', null)
    case constants.UPDATE_USER_FAILURE:
      return state
        .set('fetching', false)
        .set('userUpdateError', action.error)
    case constants.RESET_UPDATE_USER_SUCCESS:
      return state
        .set('updateusersuccess', false)
    case constants.OPEN_RESET_PASSWORD_FORM:
      return state
        .set('openresetPasswordForm', true)
    case constants.CLOSE_RESET_PASSWORD_FORM:
      return state
        .set('openresetPasswordForm', false)
    case constants.RECEIVE_ORGNIZATIONS:
      return state
        .set('fetching', false)
        .set('organizations', action.data)
    case constants.RECEIVE_ORGANIZATION_TYPES:
      return state
        .set('fetching', false)
        .set('organizationTypes', action.data)
    case constants.RECEIVE_SELECTED_ORGNIZATIONS:
      return state
        .set('fetching', false)
        .set('searchedOrganizations', action.data)
    case constants.RECEIVE_USER_FACETS:
      return state
        .set('fetching', false)
        .set('userFacets', sanitizeFilters(action.data, action.isPartnerOrCustomerView))
    case constants.FETCH_LANGUAGES_SUCCESS:
      return state
        .set('languages', fromJS(action.data))
        .set('fetching', false)
    case constants.REGISTER_USER:
      return state
        .set('registering', true)
        .set('registerError', null)
    case constants.REGISTER_USER_SUCCESS:
      return state
        .update('data', data => data.push(fromJS(action.user)))
        .set('registered', true)
        .set('registering', false)
        .set('usersNumber', action.userNumb)
        .set('newUser', fromJS(action.user))
        .set('addUserSuccess', action.status)
        .set('registerError', null)
    case constants.REGISTER_USER_FAILURE:
      return state
        .set('registerFailure', true)
        .set('registering', false)
        .set('registerError', action.error.errorMessage)
    case constants.VALIDATE_USER:
      return state
        .set('validatingUser', true)
        .set('validateUserError', null)
    case constants.VALIDATE_USER_SUCCESS:
      return state
        .set('validated', true)
        .set('validatingUser', false)
        .set('validateUserError', null)
    case constants.VALIDATE_USER_FAILURE:
      return state
        .set('validatingUser', false)
        .set('validateUserError', action.error.errorMessage)
    case constants.RESET_REGISTRATION_STATE:
      return state
        .set('registerError', null)
        .set('registered', false)
    case constants.RESET_VALIDATION_STATE:
      return state
        .set('validated', false)
        .set('validateUserError', null)
    case constants.RECIEVE_BRAND_AND_PARTNER_TYPE_SUCCESS:
      return state
        .set('fetching', true)
        .set('fetchError', null)
        .set('brandAndPartnerType', action.data)
    case constants.SET_VALIDATE_TYPE_STATE:
      return state
        .set('validateType', action.value)
    case constants.RESET_UPDATE_USER_DETAILS_ERROR:
      return state
        .set('userUpdateError', null)
    case constants.VALIDATE_SELF_REG_SUCCESS:
      return state
        .set('orgRoles', fromJS(action.data))
        .set('isSelfRegValidated', true)
        .set('registerError', null)
    case constants.VALIDATE_SELF_REG:
      return state
        .set('orgRoles', fromJS([]))
        .set('isSelfRegValidated', false)
    case constants.VALIDATE_SELF_REG_FAILURE:
      return state
        .set('orgRoles', fromJS([]))
        .set('registerError', action.error.errorMessage)
        .set('isSelfRegValidated', false)
    case constants.RESET_SELF_REG_VALIDATION:
      return state
        .set('isSelfRegValidated', false)
        .set('registerError', null)
    case constants.RESET_SELF_REG_ERROR:
      return state
        .set('registerError', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.users

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  users: createSelector(getState, (state) =>
    state.get('data') || []
  ),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages') || 0
  ),
  usersNumber: createSelector(getState, (state) =>
    state.get('usersNumber') || 0
  ),
  searchTerm: createSelector(getState, (state) =>
    state.get('searchTerm')
  ),
  sortColumn: createSelector(getState, (state) =>
    state.get('sortColumn')
  ),
  sortDirection: createSelector(getState, (state) =>
    state.get('sortDirection')
  ),
  filterBy: createSelector(getState, (state) =>
    state.get('filterBy') || []
  ),
  filterType: createSelector(getState, (state) =>
    state.get('filterType')
  ),
  selectedFilterValues: createSelector(getState, (state) =>
    state.get('selectedFilterValues')
  ),
  filters: createSelector(getState, (state) =>
    state.get('filters') || []
  ),
  removeUserSuccess: createSelector(getState, (state) =>
    state.get('removeUserSuccess')
  ),
  addUserSuccess: createSelector(getState, (state) =>
    state.get('addUserSuccess')
  ),
  getNewUser: createSelector(getState, (state) =>
    state.get('newUser').toJS()
  ),
  getRoles: createSelector(getState, (state) =>
    state.get('roles')
  ),
  updateSuccess: createSelector(getState, (state) =>
    state.get('fetchSuccess')
  ),
  openAddRoleForm: createSelector(getState, (state) =>
    state.get('openAddRoleForm')
  ),
  openAddUserForm: createSelector(getState, (state) =>
    state.get('openAddUserForm')
  ),
  getRoleDeleteMessage: createSelector(getState, (state) =>
    state.get('removeRoleMsg')
  ),
  getEditStatus: createSelector(getState, (state) => {
    state.get('editstatus')
  }),
  addedRole: createSelector(getState, (state) =>
    state.get('addedRole')
  ),
  addRoleSuccess: createSelector(getState, (state) =>
    state.get('addRoleSuccess')
  ),
  togglefetching: createSelector(getState, (state) =>
    state.get('togglefetching')
  ),
  getDomains: createSelector(getState, (state) =>
    state.get('domains')
  ),
  getToggleStatusError: createSelector(getState, (state) =>
    state.get('toggleStatusError')
  ),
  getCountries: createSelector(getState, (state) =>
    state.get('countries')
  ),
  getCountriesZone: createSelector(getState, (state) =>
    state.get('countriesZone')
  ),
  editRoleForm: createSelector(getState, (state) =>
    state.get('openEditRoleForm')
  ),
  editRoleNameSuccess: createSelector(getState, (state) =>
    state.get('editRoleNameSuccess')
  ),
  removeUserFailure: createSelector(getState, (state) =>
    state.get('removeUserFailure')
  ),
  getUpdateusersuccess: createSelector(getState, (state) =>
    state.get('updateusersuccess')
  ),
  openresetPasswordForm: createSelector(getState, (state) =>
    state.get('openresetPasswordForm')
  ),
  getOrganizations: createSelector(getState, (state) =>
    state.get('organizations')
  ),
  getOrganizationTypes: createSelector(getState, (state) =>
    state.get('organizationTypes')
  ),
  getOrgRoles: createSelector(getState, (state) =>
    state.get('orgRoles')
  ),
  getSearchedOrganizations: createSelector(getState, (state) =>
    state.get('searchedOrganizations')
  ),
  getSearchedCountries: createSelector(getState, (state) =>
    state.get('searchedCountries')
  ),
  getSearchedCountriesZone: createSelector(getState, (state) =>
    state.get('searchedCountriesZone')
  ),
  getUserFacets: createSelector(getState, (state) =>
    state.get('userFacets')
  ),
  getAllLanguages: createSelector(getState, (state) =>
    state.get('languages')
  ),
  registered: createSelector(getState, (state) =>
    state.get('registered')
  ),
  validated: createSelector(getState, (state) =>
    state.get('validated')
  ),
  registerError: createSelector(getState, (state) =>
    state.get('registerError')
  ),
  validatingUser: createSelector(getState, (state) =>
    state.get('validatingUser')
  ),
  validatingError: createSelector(getState, (state) =>
    state.get('validateUserError')
  ),
  registering: createSelector(getState, (state) =>
    state.get('registering')
  ),
  getBrandAndPartnerType: createSelector(getState, (state) =>
    state.get('brandAndPartnerType')
  ),
  getValidateType: createSelector(getState, (state) =>
    state.get('validateType')
  ),
  roleFetching: createSelector(getState, (state) =>
    state.get('roleFetching')
  ),
  userUpdateError: createSelector(getState, (state) =>
    state.get('userUpdateError')
  ),
  isSelfRegValidated: createSelector(getState, (state) =>
    state.get('isSelfRegValidated')
  )
}

function sanitizeFilters(filterType, isPartnerOrCustomerView) {
  let filtersTypes = {}
  for (const key in filterType) {
    if (filterType[key] !== null) {
      filtersTypes[key] = filterType[key]
    }
  }
  const filterOrder = [
    usersFilterTypes.ROLE,
    usersFilterTypes.STATUS,
    usersFilterTypes.ORG,
    usersFilterTypes.COUNTRY
  ]

  let filterCategories = []
  filterOrder
    .filter(f => (f === usersFilterTypes.ORG) ? !isPartnerOrCustomerView : true)
    .forEach((filter) => {
      if (filter in filtersTypes) {
        const values = filtersTypes[filter]
        const isSearchBox = filter === usersFilterTypes.ORG || filter === usersFilterTypes.COUNTRY
        const categoryType = filter
        const categoryValues = !values
          ? []
          : values.map((val, index) => ({
            name: val,
            label: val,
            key: index,
            value: val
          }))

        filterCategories.push({ categoryType, categoryValues, searchBox: isSearchBox })
      }
    })

  return filterCategories
}
