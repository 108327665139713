import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as Modals } from 'antd'
import 'antd/lib/modal/style/css'
import './Modal.scss'

const Modal = (props) => {
  const [openModal, isShowing] = useState(true)
  const { children, title, width, close, ...restProps } = props
  return (
    <Modals
      title={title}
      visible={openModal}
      onOk={() => {
        close()
        isShowing(false)
      }}
      width={width}
      onCancel={() => {
        close()
        isShowing(false)
      }}
      {...restProps}>
      {children}
    </Modals>
  )
}
Modal.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.number,
  restProps: PropTypes.object
}

export default Modal
