import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { FormattedMessage } from 'react-intl'
import CheckBox from './CheckBox'
import styles from './CustomInput.scss'

const cx = classNames.bind(styles)
let prevValue = ''
class CustomInput extends Component {
  constructor({ initialValues = {} }) {
    super()
    const { input = '', checkbox = false } = initialValues
    this.state = {
      showCustomField: checkbox,
      customValue: input
    }
  }

  componentDidMount() {
    const { customFieldValue } = this.props
    if(customFieldValue && customFieldValue != this.state.customValue) {
      this.setState({
        customValue: customFieldValue,
        showCustomField: true
      })
    }
  }

  handleChange = ({ target: { value: newValue } }) => {
    const { pattern, onInputChange } = this.props
    const regex = new RegExp(pattern)

    if ((newValue >= 0 && regex.test(newValue)) || regex.test(newValue)) {
      this.setState({
        customValue: newValue
      })
    } else {
      this.setState({
        customValue: prevValue
      })
      return
    }
    if (onInputChange) {
      onInputChange(newValue)
    }
    prevValue = newValue
  }

  toggleCustomField = () => {
    const { showCustomField } = this.state
    const { onCheckBoxChange } = this.props
    const checked = !showCustomField

    this.setState({
      showCustomField: checked,
      ...(!checked ? { customValue: '' } : {})
    })

    if (onCheckBoxChange) {
      onCheckBoxChange(checked)
    }
  }

  render() {
    const { showCustomField, customValue } = this.state

    return (
      <CheckBox
        checked={showCustomField}
        onChange={this.toggleCustomField}
        label={<FormattedMessage id='next.custom' />}>
        {showCustomField && (
          <input
            value={customValue}
            onChange={this.handleChange}
            className={cx('custom-field')} />
        )}
      </CheckBox>
    )
  }
}

CustomInput.propTypes = {
  pattern: PropTypes.string,
  onInputChange: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
  initialValues: PropTypes.object,
  customFieldValue: PropTypes.string
}

export default CustomInput
