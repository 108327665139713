export const constants = {
  RESET_STATE: 'GENERIC/RESET_STATE',
  RESET_ALL_STATE: 'GENERIC/RESET_ALL_STATE'
}

export const actions = {
  resetState (moduleName) {
    return {type: constants.RESET_STATE, moduleName}
  },
  resetAllState () {
    return {type: constants.RESET_ALL_STATE}
  }
}
