import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  POST_PASSWORD_DETAILS: 'RESET_PASSWORD/POST_PASSWORD_DETAILS',
  POST_PASSWORD_DETAILS_SUCCESS: 'RESET_PASSWORD/POST_PASSWORD_DETAILS_SUCCESS',
  POST_PASSWORD_DETAILS_FAILURE: 'RESET_PASSWORD/POST_PASSWORD_DETAILS_FAILURE',
  UPDATE_RESET_STATUS: 'RESET_PASSWORD/UPDATE_RESET_STATUS',
  RESET_PASSWORD_EXPIRY_SUCCESS: 'RESET_PASSWORD/RESET_PASSWORD_EXPIRY_SUCCESS',
  RESET_PASSWORD_EXPIRY_FAILURE: 'RESET_PASSWORD/RESET_PASSWORD_EXPIRY_FAILURE'
}

// Action Creators
export const actions = {
  setPassword (resetPasswordData) {
    return safeFetch({
      onFetch: () => ({ type: constants.POST_PASSWORD_DETAILS }),
      apiFunction: 'setPassword',
      args: {resetPasswordData},
      onSuccess: ({ data }) => ({ type: constants.POST_PASSWORD_DETAILS_SUCCESS, status: String(data) }),
      onFailure: (error) => ({ type: constants.POST_PASSWORD_DETAILS_FAILURE, error: error.status }),
      throwError: true,
      preventRetryAccessToken: true
    })
  },
  resetPassword (resetPasswordData, profileId, preventRetryAccessToken) {
    return safeFetch({
      onFetch: () => ({ type: constants.POST_PASSWORD_DETAILS }),
      apiFunction: 'resetPassword',
      args: {resetPasswordData, profileId},
      onSuccess: ({ data }) => ({ type: constants.POST_PASSWORD_DETAILS_SUCCESS, status: String(data) }),
      onFailure: (error) => ({ type: constants.POST_PASSWORD_DETAILS_FAILURE, error: error.status }),
      throwError: true,
      preventRetryAccessToken: true
    })
  },
  updateResetPasswordStatus () {
    return {type: constants.UPDATE_RESET_STATUS}
  },
  resetExpiryCheck (token, preventRetryAccessToken) {
    return safeFetch({
      onFetch: () => ({ type: constants.POST_PASSWORD_DETAILS }),
      apiFunction: 'resetExpiryCheck',
      args: {token},
      onSuccess: () => ({ type: constants.RESET_PASSWORD_EXPIRY_SUCCESS }),
      onFailure: ({status}) => ({ type: constants.RESET_PASSWORD_EXPIRY_FAILURE, error: status }),
      throwError: true,
      preventRetryAccessToken: true
    })
  },
  changePassword (changePasswordData) {
    return safeFetch({
      onFetch: () => ({ type: constants.POST_PASSWORD_DETAILS }),
      apiFunction: 'changePassword',
      args: changePasswordData,
      onSuccess: ({ data }) => ({ type: constants.POST_PASSWORD_DETAILS_SUCCESS, status: String(data) }),
      onFailure: (error) => ({ type: constants.POST_PASSWORD_DETAILS_FAILURE, error: error.status }),
      throwError: true,
      preventRetryAccessToken: true
    })
  }
}

// Reducers
export const initialState = fromJS({
  posting: false,
  postSuccess: false,
  postFailure: false,
  postError: null,
  resetExpired: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.POST_PASSWORD_DETAILS:
      return state
        .set('posting', true)
        .set('postError', null)
    case constants.POST_PASSWORD_DETAILS_SUCCESS:
      return state
        .set('postSuccess', true)
        .set('posting', false)
    case constants.POST_PASSWORD_DETAILS_FAILURE:
      return state
        .set('postError', action.error)
        .set('posting', false)
    case constants.UPDATE_RESET_STATUS:
      return state
        .set('postSuccess', false)
    case constants.RESET_PASSWORD_EXPIRY_SUCCESS:
      return state
        .set('posting', false)
        .set('resetExpired', null)
    case constants.RESET_PASSWORD_EXPIRY_FAILURE:
      return state
        .set('postError', action.error)
        .set('posting', false)
        .set('resetExpired', action.error)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.resetPassword

export const selectors = {
  postError: createSelector(getState, (state) =>
    state.get('postError')
  ),
  posting: createSelector(getState, (state) =>
    state.get('posting')
  ),
  postSuccess: createSelector(getState, (state) =>
    state.get('postSuccess')
  ),
  resetExpired: createSelector(getState, (state) =>
    state.get('resetExpired')
  )
}
