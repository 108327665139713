import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider, ReactReduxContext } from 'react-redux'
import createStore from 'store/createStore'
import Routes from 'routes'
import enUS from 'antd/lib/locale-provider/en_US'
import {ConfigProvider} from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import ErrorBoundary from './ErrorBoundary'

class AppContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }
  shouldComponentUpdate () {
    return false
  }
  render () {
    const { history } = this.props
    const store = createStore({}, history)
    return (
      <ErrorBoundary>
        <Provider store={store} context={ReactReduxContext}>  
            <ConnectedRouter history={history} context={ReactReduxContext}>
              <ConfigProvider locale={enUS}>
                <Routes store={store} history={history}/>
              </ConfigProvider>
            </ConnectedRouter>
        </Provider>
      </ErrorBoundary>
    )
  }
}
export default AppContainer
