import React from 'react'
import PropTypes from 'prop-types'
import merckLogo from 'static/images/merck-logo.png'
import './UnAuthorized.scss'
import { FormattedMessage } from 'react-intl'
import {Link} from 'react-router-dom'
import {PATHS } from 'routes'

const propTypes = {
    id: PropTypes.string,
}

const defaultProps = {
    id: <FormattedMessage id='next.CARDS_LISTING_401_ERROR' />,
}

const UnAuthorized=(props)=>{
    return(
        <>
            <div className='unauthorized__div'>
                <img alt src={merckLogo} />
                <h2><FormattedMessage id={props?.id} /></h2><br/>
            </div>
            <b className={'goto-home'}><u><Link to={PATHS.DASHBOARD} >{'Go to Home'}</Link></u></b>
        </>
    )
}

UnAuthorized.propTypes = propTypes
UnAuthorized.defaultProps = defaultProps

export default UnAuthorized