import * as yup from 'yup'

// Bindings for redux-form compliant validations based on
// yup validation library
export default class FormValidation {
  // Wrap yup types
  static mixed = yup.mixed
  static array = yup.array
  static boolean = yup.boolean
  static date = yup.date
  static number = yup.number
  static object = yup.object
  static string = yup.string

  static VALIDATE_OPTIONS = {
    strict: true,
    abortEarly: false
  }

  static create(schemaObj) {
    const schema = yup.object().shape(schemaObj)
    return async (values) => {
      try {
        await schema.validate(values, FormValidation.VALIDATE_OPTIONS)
        return {}
      } catch (err) {
        throw err.inner.reduce((acc, next) => ({
          ...acc,
          [next.path]: acc[next.path] ? [...acc[next.path], ...next.errors] : next.errors
        }), {})
      }
    }
  }
}
