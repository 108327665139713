import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'antDComponents/Tooltip/Tooltip'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames/bind'
import styles from './OrganizationAdditionalInfo.scss'
import {InfoCircleOutlined} from '@ant-design/icons' 
const cx = classNames.bind(styles)
class OrganizationAdditionalInfo extends Component {
  renderInfo = (additionalInfo) => {
    const {historicalDataSubscription, partnerTypes} = additionalInfo
    return (
      <div className={cx('tooltip1')}>
        <span><b><FormattedMessage id='next.ORGANIZATION_ADDITIONAL_MORE_INFO' /></b></span>
        {<div className={cx('tooltip-row')}><div className={cx('tooltip-label')}><FormattedMessage id='next.MANAGE_ORGANIZATION_PARTNER_TYPE_LABEL' /></div><div className={cx('tooltip-value')}><b> {partnerTypes && partnerTypes.map(({partnerTypeName}) => partnerTypeName).join(',')}</b></div></div>}
        <div className={cx('tooltip-row')}><div className={cx('tooltip-label')}><FormattedMessage id='next.PARTNER_PERIPHERAL_SUPPLIER' /></div><div className={cx('tooltip-value')}><b> {(additionalInfo.customProperties && additionalInfo.customProperties.partner_peripheral_supplier && additionalInfo.customProperties.partner_peripheral_supplier.name) ? additionalInfo.customProperties.partner_peripheral_supplier.name : 'N/A'}</b></div></div>
        <div className={cx('tooltip-row')}>
            <div className={cx('tooltip-label')}>
              <FormattedMessage id='next.MANAGE_ORGANIZATION_HISTORICAL_DATA_SUBSCRIPTION_LABEL' />
            </div>
            <div className={cx('tooltip-value')}>
              <b>{(historicalDataSubscription === 24 || historicalDataSubscription === null)
              ? <FormattedMessage id='next.MANAGE_ORGANIZATION_HISTORICAL_DATA_SUBSCRIPTION_2 Years' />
              : <FormattedMessage id='next.MANAGE_ORGANIZATION_HISTORICAL_DATA_SUBSCRIPTION_Full History' />}
              </b>
            </div>
        </div>
      </div>
    )
  }
  render () {
    const { additionalInfo } = this.props
    return (
      <span className={cx('org_additional_onfo')}>
        <Tooltip
          placement="top"
          title={this.renderInfo(additionalInfo)}>
          <a>
            <InfoCircleOutlined />
          </a>
        </Tooltip>
      </span>
    )
  }
}

OrganizationAdditionalInfo.propTypes = {
  additionalInfo: PropTypes.object
}
export default OrganizationAdditionalInfo