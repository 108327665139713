export const moduleId = 'globalAlertsAndAlarms'

export const eventTypes = {
  'OFFLINE': 'Offline',
  'MAJOR_ALERT':'Major Alert',
  'ALARM_STOP':'Alarm Stop',
  'ALARM':'Alarm',
  'MINOR_ALERT':'Minor Alert',
  'ONLINE':'Online'
}

export const filterTypes = {
  'PARTNER': 'partner',
  'PRODUCT_CATALOG_NO': 'productCatalogNo',
  'EVENT_TYPE': 'eventType',
  'COUNTRY_CODE': 'countryIsoCode',
  'CUSTOMER_NAME': 'customerName',
  'DEVICE_NAME': 'deviceName',
  'CONTRACT_COVERAGE_LEVEL': 'contractStatus',
  'DIS_ORGANIZATION_NAME': 'disOrgName',
}
export const contractCovergeStatus = {
  'ACTIVE': '1',
  'YES': 'Yes',
  'NO': 'No'
}
export const contractStatusOption = ['1', '0']
