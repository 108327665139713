import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
    constructor(props) {
      super(props)
      this.state = { error: null, errorInfo: null }
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      const {error, errorInfo} = this.state  
      if (errorInfo || error) {
        // Error path
        return (
          <div>
            <h1 style={{textAlign:'center',padding:'30px'}}>{'Something went wrong. Please contact Developer'}</h1>
            <p style={{textAlign:'center'}}>{'Please see the Details for more information'}</p>
            <details style={{ whiteSpace: 'pre-wrap',padding:'20px' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        )
      }
      // Normally, just render children
      return this.props.children
    }  
  }

  ErrorBoundary.propTypes = {
    // renderError: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    // onError: PropTypes.func,
    children: PropTypes.node
  }

  export default ErrorBoundary
