export const moduleId = 'deviceSoftwareValidate'

export const constants = {
  'SOFTWARE_RELEASE_STATUS': 'swReleaseStatus',
  'SOFTWARE_RELEASE_VERSION': 'swReleaseVersion'
}
export const status = {
  'ACCEPTED': 'ACCEPTED',
  'REJECTED': 'REJECTED',
  'PENDING': 'PENDING',
  'RECALLED': 'RECALLED'
}
export const filterTypes = {
  'DEVICE_TYPE': 'deviceType',
  'SOFTWARE_RELEASE_STATUS': 'swReleaseStatus',
  'SOFTWARE_INFORMATION': 'swReleaseInformation'
}
