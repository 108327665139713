import React from 'react'
import { Alert } from 'antd'
import PropTypes from 'prop-types'
import 'antd/lib/alert/style/css'
import Loader from 'components/Loader'

const propTypes = {
  isSubmitting: PropTypes.string,
  errorMessage: PropTypes.string,
  message: PropTypes.string
}
const renderErrors = ({errorMessage, message, isSubmitting}) => {
  return(
    <span>
      {isSubmitting && <Loader />}
      {errorMessage ? errorMessage.length &&
      <Alert message={message} type="error" showIcon />
      : null}
    </span>
  )
}

renderErrors.propTypes = propTypes
export default renderErrors
