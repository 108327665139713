import { prop } from 'ramda'
import naturalSort from 'javascript-natural-sort'
import { css } from 'styled-components'
import { Checkbox } from '@liquid-design/liquid-design-react'
const GLOBAL_CSS_PREFIX= 'LD__'

export const getClassName = Component =>
`${GLOBAL_CSS_PREFIX}${Component.displayName || Component.name}`

export const AUX_CELL_CLASSNAME = getClassName({ name: 'TableAuxCell' })
export const AUX_CELL_WIDTH = 30

export const SORT_MODES = {
  ascending: 'ASCENDING',
  descending: 'DECENDING',
  unsorted: 'UNSORTED',
}

export const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

export const DEFAULT_SORT_MODE = SORT_MODES.unsorted

export const getSortingObject = fn => ({
  [SORT_MODES.ascending]: (a, b) => fn(a.stringValue, b.stringValue),
  [SORT_MODES.descending]: (a, b) => fn(b.stringValue, a.stringValue),
  [SORT_MODES.unsorted]: (a, b) => a.initialIndex - b.initialIndex,
})

export const getSortedRows = ({
  sortMode,
  sortingColumnIndex,
  rows,
  columns,
}) => {
  if (sortingColumnIndex === undefined) {
    return rows
  }
  const sortingColumn = columns[sortingColumnIndex]
  const sortingFunction = prop('sortMethod', sortingColumn) || naturalSort
  const sortingFn = getSortingObject(sortingFunction)[sortMode]
  return rows
    .map(({ id, initialIndex, cells }, index) => {
      let stringValue = ''
      const cell = sortingColumn && sortingColumn.accessor(cells)
      if (cell) {
        stringValue = typeof cell === 'object' ? prop('value', cell) : cell
      }

      return {
        stringValue,
        initialIndex,
        index,
      }
    })
    .sort(sortingFn)
    .map(({ index }) => rows[index])
}

export const CELL_MIN_WIDTH = '100px'

export const TABLE_ROW_STATES = {
  isExpanded: 'isExpanded',
  isSelected: 'isSelected',
}

export const getTableCellYPadding = size =>
  ({
    [SIZES.small]: '6px',
    [SIZES.medium]: '10px',
    [SIZES.large]: '6px',
  }[size])

export const renderNode = (node, props) =>
  typeof node === 'function' ? node(props) : node

  
export const isTouchDevice = () => {
  if (typeof document === 'undefined') return
  return 'ontouchstart' in document.documentElement
}

export const nonTouchDevicesHoverStyles = styleString =>
  !isTouchDevice() &&
  css`
    &:hover {
      ${styleString}
    }
  `

const CHECKBOX_CLASSNAME_BASE = getClassName(Checkbox)
export const CHECKBOX_CLASSNAMES = {
  BASE: CHECKBOX_CLASSNAME_BASE,
  UNCHECKED: `${CHECKBOX_CLASSNAME_BASE}--unchecked`,
  CHECKED: `${CHECKBOX_CLASSNAME_BASE}--checked`,
  HOVER: `${CHECKBOX_CLASSNAME_BASE}--hover`,
}

export const ICON_CLASSNAME = getClassName({ name: 'Icon' })

export const TABLE_PAGINATION_CLASSNAME = getClassName({
  name: 'TablePagination',
})
 
export const SCREEN_SIZES = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

const getMediaQuery = mediaQueryKey =>
  Object.keys(SCREEN_SIZES).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (${mediaQueryKey}: ${SCREEN_SIZES[label]}px) {
        ${css(...args)};
      }
    `

    return acc
  }, {})

export const media = {
  min: getMediaQuery('min-width'),
  max: getMediaQuery('max-width'),
  customMin: breakpoint => (...args) =>
    css`
      @media (min-width: ${breakpoint}px) {
        ${css(...args)}
      }
    `,
  customMax: breakpoint => (...args) =>
    css`
      @media (max-width: ${breakpoint}px) {
        ${css(...args)}
      }
    `,
}

// MS Edge also has a window.chrome object, but w/out csi
export const isChromeBrowser = () => {
  if (typeof document === 'undefined') return
  return !!(window.chrome && window.chrome.csi)
}

export const cursorValue = ({ disabled, defaultValue = 'default' }) => css`
  cursor: ${disabled ? 'not-allowed' : defaultValue}
`