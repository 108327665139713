import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default (WrappedComponent) => {
  return class InjectOverlay extends PureComponent {
    static contextTypes = {
      openModal: PropTypes.func,
      openSnackBar: PropTypes.func,
      openOffCanvas: PropTypes.func,
      close: PropTypes.func
    }

    render() {
      return (
        <WrappedComponent
          {...this.context}
          {...this.props}
        />
      )
    }
  }
}
