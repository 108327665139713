import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
export const moduleId = 'howTo'

// Constants
export const constants = {
    FETCH: 'HOWTO/FETCH',
    FETCH_FAILURE: 'HOWTO/FETCH_FAILURE',
    RECEIVE_HOW_TO_FILTERS: 'HOWTO/RECEIVE_HOW_TO_FILTERS',
    DEVICE_TYPES_SUCCESS: 'HOWTO/DEVICE_TYPES_SUCCESS',
    DEVICE_TYPES_FAILURE: 'HOWTO/DEVICE_TYPES_FAILURE',
    LOAD_LANGUAGES_SUCCESS: 'HOWTO/LOAD_LANGUAGES_SUCCESS',
    LOAD_LANGUAGES_FAILURE: 'HOWTO/LOAD_LANGUAGES_FAILURE',
    FETCH_SUBJECT_SUCCESS: 'HOWTO/FETCH_SUBJECT_SUCCESS',
    FETCH_SUBJECT_FAILURE: 'HOWTO/FETCH_SUBJECT_FAILURE',
    SET_UPLOADING_STATE: 'FILE/SET_UPLOADING_STATE',
    FETCH_VALID_PLAY_VIDEO_SUCCESS: 'HOWTO/FETCH_VALID_PLAY_VIDEO_SUCCESS',
    FETCH_VALID_PLAY_VIDEO_FAILURE: 'HOWTO/FETCH_VALID_PLAY_VIDEO_FAILURE',
    VALIDATE_HOW_TO_VIDEO_START: 'HOWTO/VALIDATE_HOW_TO_VIDEO_START',
    VALIDATE_HOW_TO_VIDEO_SUCCESS: 'HOWTO/VALIDATE_HOW_TO_VIDEO_SUCCESS',
    VALIDATE_HOW_TO_VIDEO_FAIL: 'HOWTO/VALIDATE_HOW_TO_VIDEO_FAIL',
    ADD_HOW_TO_VIDEO_START: 'HOWTO/ADD_HOW_TO_VIDEO_START',
    ADD_HOW_TO_VIDEO_SUCCESS: 'HOWTO/ADD_HOW_TO_VIDEO_SUCCESS',
    ADD_HOW_TO_VIDEO_FAIL: 'HOWTO/ADD_HOW_TO_VIDEO_FAIL',
    EDIT_HOW_TO_VIDEO_START: 'HOWTO/EDIT_HOW_TO_VIDEO_START',
    EDIT_HOW_TO_VIDEO_SUCCESS: 'HOWTO/EDIT_HOW_TO_VIDEO_SUCCESS',
    EDIT_HOW_TO_VIDEO_FAIL: 'HOWTO/EDIT_HOW_TO_VIDEO_FAIL',
    DELETE_SUCCESS: 'NEWSFEED/DELETE_SUCCESS',
    DELETE_FAILURE: 'NEWSFEED/DELETE_FAILURE',
    RESET_UPLOAD_UPDATE_SUCCESS: 'NEWSFEED/RESET_UPLOAD_UPDATE_SUCCESS',
}

// Action Creators
export const actions = {
    getHowToFilters(permission) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            apiFunction: 'fetchHowToFilters',
            onSuccess: ({ data }) => ({ type: constants.RECEIVE_HOW_TO_FILTERS, data, permission }),
            onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
        })
    },
    fetchDeviceTypes() {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            apiFunction: 'getAllDeviceTypes',
            onSuccess: ({ data }) => ({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes: data }),
            onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error: error })
        })
    },
    fetchAllLanguages() {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            apiFunction: 'fetchAllLanguages',
            onSuccess: ({ data }) => ({ type: constants.LOAD_LANGUAGES_SUCCESS, data }),
            onFailure: ({ status }) => ({ type: constants.LOAD_LANGUAGES_FAILURE, status })
        })
    },
    fetchSubjects() {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            apiFunction: 'getTypeOfSubject',
            onSuccess: ({ data }) => ({ type: constants.FETCH_SUBJECT_SUCCESS, data }),
            onFailure: ({ status }) => ({ type: constants.FETCH_SUBJECT_FAILURE, status })
        })
    },
    setUploadingState(uploadStatus) {
        return { type: constants.SET_UPLOADING_STATE, uploadStatus }
    },
    validatedVideo(data) {
        return safeFetch({
            onFetch: () => ({ type: constants.VALIDATE_HOW_TO_VIDEO_START }),
            throwError: true,
            apiFunction: 'validatedVideo',
            args: data,
            onSuccess: ({ data, headers }) => ({ type: constants.VALIDATE_HOW_TO_VIDEO_SUCCESS, data, headers }),
            onFailure: (error) => ({ type: constants.VALIDATE_HOW_TO_VIDEO_FAIL, error: error }),
        })
    },
    addHowToVideo(data) {
        return safeFetch({
            onFetch: () => ({ type: constants.ADD_HOW_TO_VIDEO_START }),
            throwError: true,
            apiFunction: 'addHowToVideo',
            args: data,
            onSuccess: ({ data, headers }) => ({ type: constants.ADD_HOW_TO_VIDEO_SUCCESS, data, headers }),
            onFailure: (error) => ({ type: constants.ADD_HOW_TO_VIDEO_FAIL, error: error }),
        })
    },
    editHowToVideo(data) {
        return safeFetch({
            onFetch: () => ({ type: constants.EDIT_HOW_TO_VIDEO_START }),
            throwError: true,
            apiFunction: 'editHowToVideo',
            args: data,
            onSuccess: ({ data, headers }) => ({ type: constants.EDIT_HOW_TO_VIDEO_SUCCESS, data, headers }),
            onFailure: (error) => ({ type: constants.EDIT_HOW_TO_VIDEO_FAIL, error: error }),
        })
    },
    deleteHowToVideo(videoId, videoTitle) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            apiFunction: 'deleteHowToVideo',
            args: videoId,
            onSuccess: () => ({ type: constants.DELETE_SUCCESS, status: 204, videoTitle }),
            onFailure: ({ status }) => ({ type: constants.DELETE_FAILURE, status }),
        })
    },
    resetUploadUpdateState() {
        return { type: constants.RESET_UPLOAD_UPDATE_SUCCESS }
    },
}

// Reducer
export const initialState = fromJS({
    fetching: false,
    fetchError: null,
    howToFilters: [],
    uploading: false,
    playVideos: null,
    validVideo: true,
    fileUploaded: null,
    fileUpdated: null,
    howtoDeleteStatus: null,
})

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.FETCH:
            return state
                .set('fetching', true)
                .set('fetchError', null)
        case constants.FETCH_FAILURE:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
        case constants.RECEIVE_HOW_TO_FILTERS:
            return state
                .set('howToFilters', parseFilters(action?.data, action?.permission))
                .set('fetching', false)
        case constants.DEVICE_TYPES_SUCCESS:
            return state
                .set('deviceTypes', fromJS(action.deviceTypes))
                .set('fetching', false)
        case constants.FETCH_FILTER_FAILURE:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
        case constants.LOAD_LANGUAGES_SUCCESS:
            return state
                .set('languages', fromJS(action.data))
        case constants.FETCH_SUBJECT_SUCCESS:
            return state
                .set('typeofSubjects', fromJS(action.data))
        case constants.SET_UPLOADING_STATE:
            return state
                .set('uploading', action.uploadStatus)
        case constants.FETCH_VALID_PLAY_VIDEO_SUCCESS:
            return state.set('playVideos', fromJS(action.data)).set('fetching', false)
        case constants.FETCH_VALID_PLAY_VIDEO_FAILURE:
            return state
                .set('fetchFilterError', action.error)
                .set('fetching', false)
        case constants.VALIDATE_HOW_TO_VIDEO_START:
            return state.set('fetching', true)
        case constants.VALIDATE_HOW_TO_VIDEO_SUCCESS:
            return state.set('validVideo', action.data).set('fetching', false)
        case constants.VALIDATE_HOW_TO_VIDEO_FAIL:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
                .set('validVideo', true)
        case constants.ADD_HOW_TO_VIDEO_START:
            return state.set('fetching', true)
        case constants.ADD_HOW_TO_VIDEO_SUCCESS:
            return state.set('fileUploaded', true).set('fetching', false)
        case constants.ADD_HOW_TO_VIDEO_FAIL:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
                .set('fileUploaded', null)
        case constants.EDIT_HOW_TO_VIDEO_START:
            return state.set('fetching', true)
        case constants.EDIT_HOW_TO_VIDEO_SUCCESS:
            return state.set('fileUpdated', true).set('fetching', false)
        case constants.EDIT_HOW_TO_VIDEO_FAIL:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
                .set('fileUpdated', null)
        case constants.DELETE_SUCCESS:
            return state
                .set('howtoDeleteStatus', {
                    status: action.status,
                    name: action.videoTitle,
                })
                .set('fetching', false)
        case constants.DELETE_FAILURE:
            return state
                .set('howtoDeleteStatus', { status: action.status })
                .set('fetching', false)
        case constants.RESET_UPLOAD_UPDATE_SUCCESS:
            return state.set('fileUpdated', null).set('fileUploaded', null)
        default:
            return state
    }
}
// Selectors
const getState = (state) => state.howTo

export const selectors = {
    fetching: createSelector(getState, (state) => state.get('fetching')),
    howToFilters: createSelector(getState, (state) => state.get('howToFilters')),
    deviceTypes: createSelector(getState, (state) => state.get('deviceTypes')),
    getAllLanguages: createSelector(getState, (state) => state.get('languages')),
    getAllSubjects: createSelector(getState, (state) => state.get('typeofSubjects')),
    uploading: createSelector(getState, (state) => state.get('uploading')),
    playVideos: createSelector(getState, (state) => { return state.get('playVideos') }),
    fileUploaded: createSelector(getState, (state) => state.get('fileUploaded')),
    validVideo: createSelector(getState, (state) => state.get('validVideo')),
    fileUpdated: createSelector(getState, (state) => state.get('fileUpdated')),
    fetchError: createSelector(getState, (state) => state.get('fetchError')),
    howtoDeleteStatus: createSelector(getState, (state) => state.get('howtoDeleteStatus')
  ),
}

// Helper functions
const parseFilters = ({ fieldOfInterests, deviceTypes }, permission) => {
    const filters = {
        fieldOfInterest: fieldOfInterests && fieldOfInterests.length && fieldOfInterests || [],
        deviceTypeId: deviceTypes && deviceTypes.length && deviceTypes || []
    }
    const searchFilters = ['videoTitle']
    const requiredFilters = ['fieldOfInterest', 'deviceTypeId']
    const updatedFilters = []
    requiredFilters.forEach((name) => {
        const filterConfig = {
            searchBox: searchFilters.includes(name),
            searchBoxHighlightSelection: true
        }

        updatedFilters.push({
            categoryType: name,
            disableSearchFilter: searchFilters.includes(name) && !filters[name]?.length,
            categoryValues: filters[name] ? filters[name]?.map((value) => {
                if (name === 'fieldOfInterest') {
                    return ({
                        name: value.fieldOfInterestName,
                        label: value.fieldOfInterestName,
                        value: value.fieldOfInterestName
                    })
                } else if (name === 'deviceTypeId') {
                    return ({
                        name: value.deviceTypeName,
                        label: value.deviceTypeName,
                        value: value.deviceTypeId
                    })
                }
            }) : [],
            ...filterConfig
        })
    })
    return updatedFilters
}