import React from 'react'
import PropTypes from 'prop-types'
const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
const EditIconSvg = (props) => {
  const { className, width, height, style } = props
  return (
    <svg
      className={className}
      width={`${width}`}
      height={`${height}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16.4 16.4'
    >
      <defs></defs>

      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            style={{fill:'#e3f8fa',stroke:'#26c6da',strokeMiterlimit:'10',strokeWidth:'0.5px', ...style}}
            d='M13.17,16.15H3.23a3,3,0,0,1-3-3V3.23a3,3,0,0,1,3-3h9.94a3,3,0,0,1,3,3v9.94A3,3,0,0,1,13.17,16.15Z'
          />
          <path
            style={{fill:'#26c6da', ...style}}
            d='M12.71,9.23a.25.25,0,0,0-.25.25V11.7a.76.76,0,0,1-.75.76h-7a.76.76,0,0,1-.75-.76V5.17a.76.76,0,0,1,.75-.75H6.9a.25.25,0,0,0,0-.5H4.67A1.25,1.25,0,0,0,3.42,5.17V11.7A1.25,1.25,0,0,0,4.67,13h7A1.25,1.25,0,0,0,13,11.7V9.48A.25.25,0,0,0,12.71,9.23Z'
          />
          <path style={{fill:'#26c6da', ...style}} d='M7.34,7.91,11,4.25l1.18,1.18L8.52,9.09Z' />
          <path style={{fill:'#26c6da', ...style}} d='M6.74,9.69l1.31-.36-1-.95Z' />
          <path
            style={{fill:'#26c6da', ...style}}
            d='M12.51,3.63a.64.64,0,0,0-.89,0l-.27.27,1.18,1.18.27-.27a.64.64,0,0,0,0-.89Z'
          />
        </g>
      </g>
    </svg>
  )
}
EditIconSvg.propTypes = propTypes
EditIconSvg.defaultProps = {
  width: '18',
  height: '18'
}
export default EditIconSvg
