import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import { fromJS } from 'immutable'

export const constants = {
  FETCH: 'ABOUT/FETCH',
  UPDATE_APP_VERSION: 'ABOUT/UPDATE_APP_VERSION',
  FETCH_FAILURE: 'ABOUT/FETCH_FAILURE'
}

// Action Creators
export const actions = {
  getApplicationVersion () {
    return safeFetch({
      onFetch: () => ({type: constants.FETCH}),
      apiFunction: 'getApplicationVersion',
      onSuccess: ({data}) => ({type: constants.UPDATE_APP_VERSION, data}),
      onFailure: (error) => ({type: constants.FETCH_FAILURE, error: error.message})
    })
  }
}

export const initialState = fromJS({
  fetching: false,
  fetchError: {},
  version: null
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.UPDATE_APP_VERSION:
      return state
        .set('version', action.data.nextconnect.version)
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', {error: action.error})
        .set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.about

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  version: createSelector(getState, (state) =>
    state.get('version')
  )
}
