export const filterData = {
  data: {
    eventType: ['Alarm', 'Alarm Stop', 'Major Alert', 'Minor Alert', 'Offline'],
    productCatalogNo: null,
    customerName: [
      '.AutoTest Customer',
      'AutoTest Customer',
      'AveXis',
      'Customer Blue',
      'CustomerName_DeviceGroup',
      'GE Water and Process Technologies Hungary Kft',
      'Kemira Germany GmbH',
      'Merck Life Science, S.A.U.',
      'Merck Millipore',
      'Merck Millipore Malaysia',
      'Nextconnect',
      '_AutoTest Customer',
    ],
    countryCode: ['DE', 'FR', 'GE', 'HU', 'IN', 'INQQ', 'SP'],
    locationName: [
      'AutoTest location',
      'AutoTest location Spain',
      'AutoTest123',
      'Bangalore',
      'Bangalore12',
      'GE Water and Process Technologies Hungary Kft',
      'Kemira Germany GmbH',
      'Merck Millipore',
      'Millipore SAS',
      'TRAINING HUB ALGETE',
      'TRAINING HUB GUYANCOURT',
    ],
    partner: ['AutoTest Partner', 'No values', 'null', 'Partner Wenger'],
    deviceName: [
      '',
      'AFS 40E system  230V 50/60 Hz (LC)',
      'AFS 80E SYSTEM  100V 50/60 HZ (LC)',
      'AFS-E 150',
      'alibaba',
      'Automated Test Device aavxb18',
      'Automated Test Device atzxs21',
      'Automated Test Device buqvt87',
      'Automated Test Device qvwsa94',
      'Automated Test Device rxtyv97',
      'Automated Test Device rzaww95',
      'Automated Test Device sbzba87',
      'Automated Test Device taxrq72',
      'Automated Test Device uwqxa27',
      'Automated Test Device vtqrv26',
      'Bangalore-AFS40',
      'clx',
      'CLX 01 Test Device',
      'clx test',
      'CLX Test Device',
      'CONNECTBOX SIT SMAX1',
      'CONNECTBOX SIT SMAX2',
      'CONNECTBOX SIT SMAX3',
      'CONNECTBOX SIT SMAX4',
      'dev device name',
      'DName',
      'ELIX SIT 3',
      'HR 01 test device',
      'HR test',
      'HR test device',
      'HX 01 Test device',
      'HX test device',
      'HX_SD 01 Test Device',
      'HX_SD TEst',
      'HX_SD test device',
      'IQ',
      'IQ Device',
      'IQ-7000-qxuqy49',
      'IQ-7000-rsasu63',
      'IQ-7003&5- qtxvx31',
      'IQ-7003&5-bxatr46',
      'IQ-7003-bvzww20',
      'IQ-70XX-wrzry84',
      'IQ7000 Test',
      'IQ7000 Test Name',
      'IQ7005 Test',
      'IQ7005Test',
      'IQtest',
      'lw HR wybtw36',
      'Milli-Q HX 7040 SD (LC) 230V 50/60 HZ',
      'Milli-Q HX 7080 SD 230V 50/60 HZ',
      'Milli-Q HXSD 7080 SD (LC) 120V 60 HZ',
      'Milli-Qr CLX 7120 120V 60 Hz',
      'Milli-Qr CLX 7120 230V 50/60 Hz',
      'Milli-Q® CLX 7040 (HC) 120V 60 Hz',
      'Milli-Q® CLX 7040 (LC) 230V 50/60 Hz',
      'Milli-Q® CLX 7120 100V 50/60 Hz',
      'Milli-Q® CLX 7120 230V 50/60 Hz',
      'Milli-Q® HR 7060 (HC) 230V 50/60 Hz',
      'Milli-Q® HX 7040 (HC) 230V 50/60 Hz',
      'Milli-Q® HX 7080 (LC) 120V 60 Hz',
      'Milli-Q® HX 7150 120V 60 Hz',
      'Milli-Q® IQ 7010',
      'NC CLX aybqq97',
      'NC clx brura33',
      'NC CLX burya21',
      'NC CLX butby30',
      'NC CLX rbsvy56',
      'NC CLX rquxq56',
      'NC CLX sutyw76',
      'NC CLX svabq85',
      'NC CLX ttxby17',
      'NC CLX twuat74',
      'NC CLX vqbqv9',
      'NC CLX vvyrv27',
      'NC CLX yzswz55',
      'NC CLX ztrsw38',
      'NC HR axwrs35',
      'NC HR bbtvr48',
      'NC HR bwyvb11',
      'NC HR rsxvt14',
      'NC HR rwswq4',
      'NC HR svbwa50',
      'NC HR sxuzs27',
      'NC HR trwss61',
      'NC HR tzazx67',
      'NC HR uxrax96',
      'NC HR wsvzb55',
      'NC HR wwsyq31',
      'NC HR wwyyb51',
      'NC HR wzuys83',
      'NC HR ybaqv83',
      'NC HR yqasa61',
      'NC HR yqzst92',
      'NC HR zurqu35',
      'NC HR zuvsu97',
      'NC HX auatz2',
      'NC HX aysst20',
      'NC HX btrax16',
      'NC HX bxvtt21',
      'NC HX qazar99',
      'NC HX sttbt45',
      'NC HX TEST',
      'NC HX vbxtr74',
      'NC HX wqzry99',
      'NC HX xraya44',
      'NC HX zwsxt73',
      'NC HXSD qxwat85',
      'NC HXSD tqvzb76',
      'NC HXSD vbbru57',
      'NC HXSD vzsuw90',
      'NC HXSD wvtbs98',
      'NC HXSD yqzzs66',
      'NC HX_SD avxyz25',
      'NC HX_SD axbxy75',
      'NC HX_SD brubz13',
      'NC HX_SD trrwt56',
      'NC HX_SD tsxrt29',
      'NC HX_SD uxbbr84',
      'NC HX_SD vrtuw32',
      'NC HX_SD vzbza15',
      'NC HX_SD wbrtq10',
      'NC HX_SD yrswt99',
      'NC HX_SD ytxxb33',
      'NC IQ 7003 Testing',
      'NCCLX bxxuq67',
      'NCHR asusu77',
      'NCHR ayrsq18',
      'NCHR rvzxz87',
      'NCHR uwqvw33',
      'NCHR yxtbt36',
      'NCHR zvbux20',
      'NCHR zxqaa67',
      'NCHX aqrtq28',
      'NCHX arawb70',
      'NCHX aruwx64',
      'NCHX atwab27',
      'NCHX axwuv53',
      'NCHX btzwa83',
      'NCHX qqxtx8',
      'NCHX rvsaa95',
      'NCHX rwazz18',
      'NCHX sqzwr60',
      'NCHX stbqz55',
      'NCHX sxuwr10',
      'NCHX ttsbu86',
      'NCHX tuqsq48',
      'NCHX vqwsq7',
      'NCHX vtaar29',
      'NCHX vxsxb38',
      'NCHX vxyxz71',
      'NCHX xazyr7',
      'NCHX ybrvs16',
      'NCHX yzzys67',
      'NCHX zywys9',
      'NCHXSD vqvwt13',
      'NCHXSD wwasq99',
      'NCHXSD wxtyq68',
      'NCHXwasaz91',
      'NCQADEV azuzu10',
      'NC_CLX bxszt80',
      'NC_CLX bxzvu46',
      'NC_CLX ssuqq82',
      'NC_CLX vzswt6',
      'NC_HR',
      'NC_HR qvuvb80',
      'NC_HR rqswv54',
      'NC_HR srvbv26',
      'NC_HR sxyyv94',
      'NC_HR vbbvz67',
      'NC_HR wtatt6',
      'NC_HR wxazt46',
      'NC_HX sazst37',
      'NC_HX vvavw99',
      'NC_HX zvvby26',
      'NC_HXSD rrzar87',
      'NC_HXSD wqwst94',
      'NEW DEVICE TYPE',
      'NextConnect CLX',
      'NextConnect CLX 03',
      'NextConnect CLX Mobile',
      'NextConnect HR 0606',
      'Nextconnect HR 0706',
      'NextConnect HR Mobile',
      'NextConnect HXSD Mobile',
      'NextConnect-Clx',
      'NextConnectHR uvuxa11',
      'NextConnectQAHX tuyub61',
      'NextConnectQAHX txtuv57',
      'NextConnectQAHXSD qyxqq52',
      'NextConnectQAHXSD wzqys22',
      'NextConnectQAHXSD xwawv68',
      'NextConnectTest',
      'QA bszza82',
      'QA bxszx11',
      'QA CLX aqxys81',
      'QA CLX bwxuu46',
      'QA CLX bxrvu36',
      'QA clx Devicetype xruwt96',
      'QA CLX qvbzu64',
      'QA CLX qvwyv25',
      'QA CLX qwzzw13',
      'QA CLX rrtqq66',
      'QA CLX rsszt76',
      'QA CLX tvuqu83',
      'QA CLX ususu43',
      'QA CLX uvuya19',
      'QA CLX uyybb16',
      'QA CLX vyzyw57',
      'QA CLX wbzyb55',
      'QA CLX xszzv24',
      'QA CLX xwyss26',
      'QA CLX zssbu72',
      'QA CLX zxwyr21',
      'QA HR bqtwb27',
      'QA HR buqur60',
      'QA HR qsbyr64',
      'QA HR qwubw74',
      'QA HR rtssv32',
      'QA HR rwyzx43',
      'QA HR rxrua88',
      'QA HR sbbsa66',
      'QA HR suvqz70',
      'QA HR sxvtz67',
      'QA HR syzqw27',
      'QA HR txsqu34',
      'QA HR uqvra99',
      'QA HR uvuyx8',
      'QA HR uxatt75',
      'QA HR uywwy13',
      'QA HR vtybb16',
      'QA HR vvsxy50',
      'QA HR wrrxs61',
      'QA HR wsuta38',
      'QA HR xusxq0',
      'QA HR ywxbw52',
      'QA HR zwbba82',
      'QA HX atvqx9',
      'QA HX qbbrx53',
      'QA HX qyyuu40',
      'QA HX qzzbs2',
      'QA HX rrqrb61',
      'QA HX sqtxq26',
      'QA HX svsyw89',
      'QA HX ttvrq13',
      'QA HX tvyzb43',
      'QA HX tvzbt70',
      'QA HX twazr56',
      'QA HX uqquq59',
      'QA HX uqutv53',
      'QA HX vsurt78',
      'QA HX vuxat24',
      'QA HX wbbut57',
      'QA HX wwqvz98',
      'QA HX xayba85',
      'QA HX zrwuz90',
      'QA HXSD ayyvx28',
      'QA HXSD btavy39',
      'QA HXSD bwxww99',
      'QA HXSD rarty66',
      'QA HXSD suxvu1',
      'QA HXSD uzyuw66',
      'QA HXSD vttus28',
      'QA HXSD xytwv89',
      'QA HXSD zvqqr21',
      'QA HX_SD asqvb44',
      'QA HX_SD txaav90',
      'QA HX_SD urxau54',
      'QA HX_SD uzrqs79',
      'QA HX_SD vtvbx82',
      'QA HX_SD zsvbs40',
      'QA sqvtx47',
      'QA ttuxz29',
      'QA twxyu30',
      'QA uqqza8',
      'QA uxrvy69',
      'QA vtzyt85',
      'QA wrvua20',
      'QA wuvyu3',
      'QA wyusx65',
      'QA wywuz21',
      'QA xxyxr7',
      'QA ysrwr73',
      'QA zarvu49',
      'QA zwzqv13',
      'QAclx bszsv28',
      'QAclx burqu16',
      'QACLX qruzs10',
      'QACLX qvxvz63',
      'QACLX qxxaz68',
      'QACLX rwvva14',
      'QACLX saruq31',
      'QAclx ubsru37',
      'QACLX uquyt45',
      'QAclx vyyav44',
      'QAclx xraur15',
      'QAclx ytqxu49',
      'QAclx zvzuw33',
      'QAHR qusbz91',
      'QAHR yazzb46',
      'QAHR yxzvq51',
      'QAHR zatza85',
      'QAHX aquqr52',
      'QAHXSD yrtqz50',
      'RIOS 220',
      'Test',
      'TEST CLX',
      'Test device name',
    ],
    countryIsoCode: ['ES', 'FR', 'IN', 'US'],
  },
}
export const GlobalListingData = {
  data: [
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'nxnull',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'nnull',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
    {
      countryCode: 'FR',
      countryIsoCode: 'FR',
      customerName: 'Merck Millipore',
      customerSystemId: 'null',
      deviceId: 421057,
      deviceName: 'CONNECTBOX SIT SMAX2',
      eventType: 'Alarm Stop',
      locationName: 'Merck Millipore',
      partnerName: 'null',
      serialNo: 'CONNECTBOX_SMAX2',
      uuid: '50f765bd9fea9ed2c2a2f63ccc4de5d96238c54169e727f781ae806d116f68d7',
    },
  ],
  headers: new Headers(),
}
export const NextExpectedFilters = {
  data: {
    deviceName: ['ELIX SIT 3', 'Elix® Reference 15', 'AFS-E 150'],
    locationName: ['Merck Millipore', 'Szpital Wojewódzki nr 2'],
  },
}
export const NextExpectedListingData = {
  data: [
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007En4MAE',
      eventName: 'Service Visit',
      eventTime: '2020-06-15T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072544,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007En9MAE',
      eventName: 'Service Visit',
      eventTime: '2020-06-17T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072545,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007EnEMAU',
      eventName: 'Service Visit',
      eventTime: '2020-06-19T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072546,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007EnJMAU',
      eventName: 'Service Visit',
      eventTime: '2020-06-21T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072547,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007En4MAE',
      eventName: 'Service Visit',
      eventTime: '2020-06-15T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072544,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007En9MAE',
      eventName: 'Service Visit',
      eventTime: '2020-06-17T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072545,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007EnEMAU',
      eventName: 'Service Visit',
      eventTime: '2020-06-19T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072546,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007EnJMAU',
      eventName: 'Service Visit',
      eventTime: '2020-06-21T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072547,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007En4MAE',
      eventName: 'Service Visit',
      eventTime: '2020-06-15T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072544,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007En9MAE',
      eventName: 'Service Visit',
      eventTime: '2020-06-17T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072545,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007EnEMAU',
      eventName: 'Service Visit',
      eventTime: '2020-06-19T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072546,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
    {
      customerSystemId: 'null',
      deviceId: 421144,
      deviceName: 'AFS-E 150',
      eventId: 'a4GP00000007EnJMAU',
      eventName: 'Service Visit',
      eventTime: '2020-06-21T10:00:00.000Z',
      locationName: 'Merck Millipore',
      logSerialId: 2072547,
      serialNo: 'F4CLX4SIT',
      serviceVisitConfirmed: true,
      synced: 0,
      type: 2,
      uuid: '75a916d776ae408be24b5e247bc1d1311493806d4338ed160f1057919e652d67',
    },
  ],
  headers: new Headers(),
}

export const PartnerListingData = {
  data: [
    {
      connected: true,
      deviceId: 421002,
      deviceName: '',
      deviceTypeShortName: 'lw_clx',
      offline: true,
      peripheralNos: ['asdf12345'],
      serialNo: 'SAT_VENUS',
      uuid: '4c385238a815b8fd638603ad26b6a783a4b7774e0bff1c89969967ee5415cecf',
    },
    {
      connected: true,
      deviceId: 224801,
      deviceName: 'Milli-Qr CLX 7120 120V 60 Hz',
      deviceTypeShortName: 'lw_clx',
      offline: true,
      peripheralNos: ['QA12345S'],
      serialNo: 'finuf5695',
      uuid: '49b58480bd9c351ebfa26fef56f6bd304d6e67f22245ffcdd05e7773d4e3a89c',
    },
    {
      connected: true,
      deviceId: 421049,
      deviceName: '',
      deviceTypeShortName: 'lw_hr',
      offline: true,
      peripheralNos: ['12345'],
      serialNo: 'kjbgm3353',
      uuid: '483bea1974b16d3bc0be71ec59ad13847bf6e56e936415033fcef12f92bc2dbd',
    },
    {
      connected: true,
      deviceId: 224800,
      deviceName: 'Milli-Qr CLX 7120 120V 60 Hz',
      deviceTypeShortName: 'lw_clx',
      offline: true,
      peripheralNos: ['1234abcd'],
      serialNo: 'fjphl7535',
      uuid: 'a79e61af6cbdb111c845b62bdfe3e85200100b41ec8de939d58d79bb392a5cfb',
    },
  ],
  headers: new Headers(),
}
