import { setCookie } from 'utils/authUtils'
import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import {
  removeAtomToken,
  saveAccessToken,
  saveRefreshToken,
  checkIfEmailInString
} from 'utils/authUtils'
import safeFetch from 'store/utils/safeFetch'
import { PATHS } from 'routes'
import { routerActions } from 'connected-react-router'

// Constants
export const constants = {
  TOKEN_SUCCESS: 'Auth/TOKEN_SUCCESS',
  TOKEN_FAILURE: 'Auth/TOKEN_FAILURE',
  LOGIN: 'Auth/LOGIN',
  LOGIN_SUCCESS: 'Auth/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'Auth/LOGIN_FAILURE',
  LOGOUT: 'Auth/LOGOUT'
}

var activeTimeTimeout = null

// Action Creators
export const actions = {
  ssoLogin(id) {
    let token = {
      atomCacheId: id
    }
    return safeFetch({
      onFetch: () => ({ type: constants.LOGIN }),
      args: token,
      apiFunction: 'ssoAuth',
      onSuccess: ({ data }) => {
        saveAccessToken(data.accessToken)
        saveRefreshToken(data.refreshToken)
        removeAtomToken()
        return { type: constants.LOGIN_SUCCESS }
      },
      onFailure: (error) => ({ type: constants.LOGIN_FAILURE, error: error.message }),
      throwError: true,
      preventRetryAccessToken: true
    })
  },
  login(user, cookies) {
    let loginUser = {
      username: user.username,
      password: user.password,
      userdomain: ''
    }
    if (checkIfEmailInString(loginUser.username)) {
      loginUser.userdomain = 'nextconnect'
    } else if (loginUser.username.includes('\\')) {
      loginUser.userdomain = loginUser.username.split('\\')[0]
      loginUser.username = loginUser.username.split('\\')[1]
    } else {
      loginUser.userdomain = 'sial'
    }
    return async (dispatch, getState) =>
      dispatch(safeFetch({
        onFetch: () => ({ type: constants.LOGIN }),
        apiFunction: 'userLogin',
        args: loginUser,
        onSuccess: ({ data }) => {
          if (activeTimeTimeout) {
            clearTimeout(activeTimeTimeout)
          }
          activeTimeTimeout = setTimeout(() => dispatch(actions.autoLogOff()), 1000 * 60 * 60)
          saveAccessToken(data.accessToken)
          saveRefreshToken(data.refreshToken)
          window.localStorage.setItem('isVideo', true)
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(`VideoApi.js?meta=${data.accessToken}`)
          }
          if (user.remember) {
            setCookie(window.location.hostname, user.username, cookies)
          }
          return { type: constants.LOGIN_SUCCESS }
        },
        onFailure: (error) => {
          return ({ type: constants.LOGIN_FAILURE, error: error })
        },
        throwError: true,
        preventRetryAccessToken: true
      }))
  },
  logout() {
    return safeFetch({
      onFetch: () => ({ type: constants.LOGOUT }),
      apiFunction: 'logout',
      onSuccess: (data) => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(registration => {
            if (registration.active.scriptURL.includes('VideoApi.js')) {
              registration.unregister()
            }
          }).catch(error => {
            console.error(error.message)
          })
        }
        localStorage.clear()
        sessionStorage.clear()
        setTimeout(function () {
          window.location.reload()
        }, 2000)
      }
    })
  },
  getAccessToken() {
    return async (dispatch, getState) =>
      dispatch(safeFetch({
        apiFunction: 'getAccessToken',
        onSuccess: ({ data }) => {
         // localStorage.removeItem('isTokenCalled')
          saveAccessToken(data.accessToken)
          if (activeTimeTimeout) {
            clearTimeout(activeTimeTimeout)
          }
          activeTimeTimeout = setTimeout(() => dispatch(actions.autoLogOff()), 1000 * 60 * 60)
          return ({ type: constants.TOKEN_SUCCESS })
        },
        onFailure: (error) => {
          //localStorage.removeItem('isTokenCalled')
          return ({ type: constants.TOKEN_FAILURE, error: error.message })
        }
      }))
  },
  autoLogOff() {
    return async (dispatch, getState) => {
      const activeTime = selectors.activeTime(getState())
      if (activeTime) {
        if (activeTime <= new Date()) {
          dispatch(routerActions.push(PATHS.LOGOUT))
        } else {
          let count = 0
          this.interval = setInterval(() => {
            if (activeTime <= new Date()) {
              dispatch(routerActions.push(PATHS.LOGOUT))
            } else if (count > 5) {
              clearInterval(this.interval)
            }
            count++
          }, 5000)
        }
      }
    }
  }
}

// Reducer
export const initialState = fromJS({
  loggingIn: false,
  logoutFetch: false,
  logoutError: null,
  logoutSuccess: null,
  loginError: null,
  activeTime: false,
  videoServieWorker: false
})

export default function (state = initialState, action) {
  let newDate = new Date()
  let activeTime = newDate.setHours(newDate.getHours() + 1)
  switch (action.type) {
    case constants.LOGIN:
      return state
        .set('loggingIn', true)
        .set('loginError', null)
    case constants.LOGIN_SUCCESS:
      return state
        .set('activeTime', activeTime)
        .set('loggingIn', false)
        .set('logoutSuccess', null)
        .set('videoServieWorker', true)
    case constants.LOGIN_FAILURE:
      return state
        .set('loggingIn', false)
        .set('loginError', action.error.errorMessage)
        .set('videoServieWorker', false)
    case constants.LOGOUT:
      return state
        .set('logoutFetch', true)
        .set('fetchError', null)
        .set('videoServieWorker', false)
    case constants.TOKEN_SUCCESS:
      return state
        .set('activeTime', activeTime)
    case constants.TOKEN_FAILURE:
      return state
        .set('activeTime', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.auth

export const selectors = {
  error: createSelector(getState, (state) =>
    state.get('loginError')
  ),
  logginIn: createSelector(getState, (state) =>
    state.get('loggingIn')
  ),
  logoutSuccess: createSelector(getState, (state) =>
    state.get('logoutSuccess')
  ),
  videoServieWorker: createSelector(getState, (state) =>
    state.get('videoServieWorker')
  ),
  logoutError: createSelector(getState, (state) =>
    state.get('logoutError')
  ),
  activeTime: createSelector(getState, (state) =>
    state.get('activeTime')
  )
}
