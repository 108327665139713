import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { filterTypes as manageDeviceTypeFilterTypes } from 'constants/manageDeviceTypeConstant'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const moduleId = 'manageDeviceType'
export const constants = {
  FETCH: 'DEVICE_TYPES/FETCH',
  FETCH_COMPLETE: 'DEVICE_TYPES/FETCH_COMPLETE',
  FETCH_FAILURE: 'DEVICE_TYPES/FETCH_FAILURE',
  ADD_ORGANIZATION: 'ORGANIZATION_DETAILS/ADD_DEVICE_TYPES',
  EDIT_ORGANIZATION: 'DEVICE_TYPES/EDIT_DEVICE_TYPES',
  FETCH_CREATE_SUCCESS: 'DEVICE_TYPES/FETCH_CREATE_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'DEVICE_TYPES/FETCH_UPDATE_SUCCESS',
  UPDATE_DEVICE_TYPE_STATUS: 'DEVICE_TYPES/UPDATE_DEVICE_TYPE_STATUS',
  FETCH_DEVICE_TYPE_PRODUCTCODE_SUCCESS: 'DEVICE_TYPES/FETCH_DEVICE_TYPE_PRODUCTCODE_SUCCESS',
  FETCH_DEVICE_TYPE_PRODUCTCODE_ERROR: 'ORGANIZATION_DETAILS/FETCH_DEVICE_TYPE_PRODUCTCODE_ERROR',
  FETCH_FILTER_TYPES: 'DEVICE_TYPES/FETCH_FILTER_TYPES',
  DEVICE_TYPES_SUCCESS: 'DEVICE_TYPES/DEVICE_TYPES_SUCCESS',
  FETCH_FILTER_FAILURE: 'DEVICE_TYPES/FETCH_FILTER_FAILURE'
}

// Action Creators
export const actions = {
  addDeviceType(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'addDeviceType',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: () => ({ type: constants.FETCH_CREATE_SUCCESS }),
      onFailure: () => ({ type: constants.FETCH_FAILURE })
    })
  },
  editDeviceType(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'editDeviceType',
      onFetch: () => ({ type: constants.FETCH }),
      onSuccess: () => ({ type: constants.FETCH_UPDATE_SUCCESS }),
      onFailure: () => ({ type: constants.FETCH_FAILURE })
    })
  },
  fetchProductCode(code) {
    return safeFetch({
      apiFunction: 'fetchProductCode',
      args: code,
      onSuccess: ({ data }) => ({
        type: constants.FETCH_DEVICE_TYPE_PRODUCTCODE_SUCCESS,
        data
      }),
      onFailure: ({ status }) => ({
        type: constants.FETCH_DEVICE_TYPE_PRODUCTCODE_ERROR,
        error: status
      })
    })
  },
  resetSuccessItemStatus() {
    return { type: constants.UPDATE_DEVICE_TYPE_STATUS }
  },
  fetchDeviceTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTER_TYPES }),
      apiFunction: 'getDeviceTypesCustomers',
      onSuccess: ({ data }) => ({ type: constants.DEVICE_TYPES_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error: error })
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  productCode: [],
  successStatus: null,
  deviceTypefilterCategories: null,
  fetchingFilters: false
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.FETCH_COMPLETE:
      return state.set('fetching', false)
    case constants.FETCH_FAILURE:
      return state.set('fetching', false)
    case constants.FETCH_DEVICE_TYPE_PRODUCTCODE_SUCCESS:
      return state
        .set('productCode', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_DEVICE_TYPE_PRODUCTCODE_ERROR:
      return state
        .set('fetching', false)
    case constants.FETCH_CREATE_SUCCESS:
      return state
        .set('fetching', false)
        .set('successStatus', 'CREATE')
    case constants.FETCH_UPDATE_SUCCESS:
      return state
        .set('fetching', false)
        .set('successStatus', 'UPDATE')
    case constants.UPDATE_DEVICE_TYPE_STATUS:
      return state.set('successStatus', null)
    case constants.FETCH_FILTER_TYPES:
      return state
        .set('fetchingFilters', true)
    case constants.DEVICE_TYPES_SUCCESS:
      return state
        .set('fetchingFilters', false)
        .set('deviceTypefilterCategories', formatDeviceTypeFilterCategories(action.data))
    case constants.FETCH_FILTER_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
        .set('deviceTypefilterCategories', null)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.manageDeviceType

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  getProductCode: createSelector(getState, (state) => {
    return state.get('productCode')
  }),
  getSuccessStatus: createSelector(getState, (state) => {
    return state.get('successStatus')
  }),
  deviceTypefilterCategories: createSelector(getState, (state) => {
    return state.get('deviceTypefilterCategories')
  })
}

// helper functions

function formatDeviceTypeFilterCategories(filterTypes) {
  const filterOrder = [
    manageDeviceTypeFilterTypes.DEVICE_TYPE,
    manageDeviceTypeFilterTypes.IOT_CAPABILITY_STATUS
  ]

  let filterCategories = []
  filterOrder.forEach((filter) => {
    if (filterTypes[filter] && filterTypes[filter] instanceof Array && filterTypes[filter].length && filter in filterTypes) {
      const values = filterTypes[filter]
      const categoryType = filter
      const categoryValues = !values
        ? []
        : (filter === manageDeviceTypeFilterTypes.IOT_CAPABILITY_STATUS) ? (values.map((val, index) => ({
          name: val?'Yes':'No',
          label: val?'Yes':'No',
          key: index,
          value: `${filter}.${val}`
        }))
        )
          : (values.map((val, index) => ({
            name: val,
            label: val,
            key: index,
            value: `${filter}.${val}`
          })))
      filterCategories.push({ categoryType, categoryValues })
    }
  })
  return filterCategories
}