import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@liquid-design/liquid-design-react'
import { AntTextarea } from 'antDComponents'
import classNames from 'classnames/bind'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './UserRatingForm.scss'
import { Rate } from 'antd'
import 'antd/lib/rate/style/css'
import withSubmitForm from 'containers/withSubmitForm/withSubmitForm'
import RenderErrors from 'components/RenderErrors/RenderErrors'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import SubmitCancelFormButton from 'components/SubmitCancelFormButton/SubmitCancelFormButton'
import { ErrorMessage } from 'formik'

const cx = classNames.bind(styles)
const divStyel = {
  position: 'relative',
}
const asyncValidate = Yup.object().shape({
  feedback: Yup.string()
    .trim()
    .required(
      <FormattedMessage
        key='feedback'
        id={`next.USER_RATING_FEEDBACK_VALID_ERROR`}
      />
    ),
  rating: Yup.number().min(
    1,
    <FormattedMessage key='Rating' id={`next.USER_RATING_VALID_ERROR`} />
  ),
})

class UserRatingForm extends Component {
  static propTypes = {
    intl: PropTypes.object,
    changeUserRatingValue: PropTypes.func,
    close: PropTypes.func,
    userseRatingFormName: PropTypes.string,
    destroyForm: PropTypes.func,
    user: PropTypes.object,
    handleSubmitFeedback: PropTypes.func,
    userRating: PropTypes.object,
    errorMessage: PropTypes.string,
    handleSubmitForm: PropTypes.func,
    errorCode: PropTypes.string,
    isSubmitted: PropTypes.bool
  }

  componentDidMount() {
    const { userRating } = this.props
    if (userRating && userRating.rating) {
      this.setState({ rating: userRating.rating })
    }
  }

  submitUserRatingFeedback = async (values) => {
    const { close, handleSubmitForm } = this.props
    await handleSubmitForm('postUserFeedback', values, {isFeedback: true})
    setTimeout(close, 200)
  }

  _getFormData = () => {
    const { userRating } = this.props
    return {
      feedback: (userRating && userRating.feedback) || '',
      rating: (userRating && userRating.rating) || 0,
    }
  }

  render() {
    const { intl, errorMessage, errorCode, user, close, isSubmitted } = this.props
    return (
      <Formik
        validationSchema={asyncValidate}
        onSubmit={(values) => this.submitUserRatingFeedback(values)}
        initialValues={this._getFormData()}
      >
        {({ values, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
          <Form>
            <RenderErrors
              isSubmitting={isSubmitted}
              errorMessage={errorMessage}
              message={<FormattedMessage id={`next.${errorCode}_ERROR`} />}
            />
            <div className={cx('form-wrap')}>
              <div style={divStyel}>
                <div className={cx('user-icon')}>
                  <Icon size={30} name='person' isFilled />
                </div>
                <div className={cx('user-name')}>
                  <h4>{`${user && user.firstName} ${
                    user && user.lastName
                    }`}</h4>
                </div>
                <div>
                  <label htmlFor='employed'>
                    <FormattedMessage id='next.USER_RATING_EXPERIENCE_OVERALL' />
                  </label>
                  <div className={cx('star-rating')}>
                    <Rate
                      style={{ fontSize: '25px' }}
                      name='rating'
                      onChange={(evt) => setFieldValue('rating', evt)}
                      value={values.rating}
                    />
                  </div>
                  <span className='error-message'>
                    <ErrorMessage name='rating' />
                  </span>
                </div>
                <div className={cx('help-text')}>
                  <span>
                    <FormattedMessage id='next.USER_RATING_EXPERIENCE_FEEDBACK_SUGGESTION' />
                  </span>
                </div>
              </div>
              <AntTextarea
                size='lg'
                name='feedback'
                type='textarea'
                style={{ resize: 'none' }}
                value={values.feedback}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                placeholder={intl.formatMessage({id: `next.USER_RATING_FEEDBACK_DESCRIPTION`})}
                maxLength={1000}
              />
              <SubmitCancelFormButton
                close={close}
                cancelMessage={
                  intl.formatMessage({ id: 'next.USER_RATING_CANCEL_BUTTON' })
                }
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                submitMessage={
                  intl.formatMessage({ id: 'next.USER_RATING_BUTTON_SUBMIT' })
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}

export default injectIntl(withSubmitForm(UserRatingForm))
