import React from 'react'
import { Button } from '@liquid-design/liquid-design-react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'

export const Column = ({ children, className, title, toggleDragMenu, columnNames }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'Our first type',
    drop: () => ({ name: title }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item) => {
      const { DISPLAYED_MENU, OTHER_MENU } = columnNames
      const { currentColumnName } = item
      return ((currentColumnName === DISPLAYED_MENU) ||
        (currentColumnName === OTHER_MENU))
    },
  })

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return 'rgb(188,251,255)'
      } else if (!canDrop) {
        return 'rgb(255,188,188)'
      }
    } else {
      return ''
    }
  }

  return (
    <div ref={drop} className={className} style={{ backgroundColor: getBackgroundColor() }}>
      <div className="drag-menu-header">
        <div className="drag-menu-title">
          {title}
        </div>
        {(title === columnNames.DISPLAYED_MENU)
          && (<div className="drag-menu-close">
            <Button onClick={toggleDragMenu} appearance="ghost">x</Button>
          </div>)}
      </div>
      {children}
    </div>
  )
}
Column.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  toggleDragMenu: PropTypes.func,
  columnNames: PropTypes.object
}