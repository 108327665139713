import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { sizes, getTypeValues } from '../types'

const propTypes = {
  fieldType: PropTypes.oneOf(['radio', 'checkbox']),
  label: PropTypes.node,
  size: PropTypes.oneOf(getTypeValues(sizes)),
  value: PropTypes.string,
  className: PropTypes.string,
  isFormGroup: PropTypes.bool,
  formGroupStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.node
  })
}

const RadioCheckbox = (props) => {
  const {
    fieldType,
    label,
    size,
    value,
    className,
    inputStyle,
    name,
    disabled,
    inline,
    isFormGroup, // eslint-disable-line no-unused-vars
    formGroupStyle, // eslint-disable-line no-unused-vars
    input: inputProps,
    meta: { touched, error },
    ...otherProps
  } = props

  const showError = touched && error

  const componentClass = cx(
    {
      'form-check': !inline,
      'form-check-inline': inline,
      [`form-check-${size}`]: size,
      'disabled': disabled
    },
    className
  )
  return (
    <div className={componentClass} style={inputStyle}>
      <label className={cx('form-check-label')}>
        <input
          className={cx('form-check-input')}
          type={fieldType}
          name={name}
          value={value}
          disabled={disabled}
          checked={value == null
            ? !!(inputProps && inputProps.value)
            : !!value
          }
          {...inputProps}
          {...otherProps}
        />
        {label}
      </label>
      {showError &&
        <div className={cx('form-error-message')}>{error}</div>
      }
    </div>
  )
}

RadioCheckbox.propTypes = propTypes

export default RadioCheckbox
