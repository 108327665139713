import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  getTableCellYPadding,
  CELL_MIN_WIDTH,
  media,
  isChromeBrowser
} from './utils'

const TdWrapper = styled.td`
  > div {
    min-width: ${CELL_MIN_WIDTH};
  }
  ${props => css`
    padding: ${props.isInfoCell
      ? '15px 24px'
      : `${getTableCellYPadding(props.size)} 20px`};
  `};
  ${media.max.phone`
    padding-right: 5px;
  `};
  ${props => media.min.desktop`
    width: ${props.columnWidth}% !important;
    &:last-of-type {
      padding-left: 35px;
    }
  `};
  ${props =>
    props.isInfoCell &&
    // prevents a bug with parsing cell widths
    css`
      width: ${isChromeBrowser() ? '100%' : '0'};
    `};
`

const TableCell = ({ children, ...props }) => (
  <TdWrapper {...props}>
    <div>{children}</div>
  </TdWrapper>
)

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableCell
