export const moduleId = 'deviceSoftwareUpdate'

export const filterTypes = {
  'ORGANIZATION_NAME': 'customerName',
  'DEVICE_TYPE': 'deviceType',
  'DEVICE_SOFTWARE_STATUS': 'swStatus',
  'COUNTRY_CODE': 'countryCode',
  'DIS_ORG_NAME': 'disOrgName'
}

export const connectivity = {
  'OFFLINE': 'Offline',
  'ONLINE': 'Online',
  'POOR_CONNECTIVITY': 'Poor Connection' 
}

export const softwareProgress = {
  stroke: '#139b5f'
}
export const deletedCircleProgress = {
  stroke: 'red'
}
export const softwareProgressCircleText = {
  fontSize: '0.6em',
  fontWeight: 'bold',
  fill: '#129159'
}
export const deletedCircleText = {
  fontSize: '0.6em',
  fontWeight: 'bold',
  fill: 'red'
}

export const updateDeviceSoftwareStatus = {
  READY_FOR_UPGRADE: 'Update Available',
  DOWNLOADING: 'Software Download In Progress',
  RELEASE_DOWNLOADED: 'Download Completed',
  RELEASE_DOWNLOADING: 'Download in progress',
  UPGRADE_FAILED: 'Installation failed',
  INSTALL_IN_PROGRESS: 'Installation in progress',
  UPGRADE_NOT_ALLOWED: 'Not Authorized',
  RELEASE_INSTALLED: 'Installation Completed',
  READY_TO_INSTALL: 'Download completed',
  SW_UPDATE_REJECTED: 'Software Update Cancelled',
  INSTALLATION_FAILED: 'Installation failed',
  DOWNLOAD_FAILS: 'Download Error'
}
export const updateDeviceSoftwareStatusCode = {
  READY_FOR_UPGRADE: 'READY_FOR_UPGRADE',
  RELEASE_DOWNLOADING: 'RELEASE_DOWNLOADING',
  RELEASE_DOWNLOADED: 'RELEASE_DOWNLOADED',
  UPGRADE_FAILED: 'UPGRADE_FAILED',
  READY_TO_INSTALL: 'READY_TO_INSTALL',
  RELEASE_ACCEPTED: 'RELEASE_ACCEPTED',
  INSTALL_IN_PROGRESS: 'INSTALL_IN_PROGRESS',
  UPGRADE_NOT_ALLOWED: 'UPGRADE_NOT_ALLOWED',
  RELEASE_INSTALLED: 'RELEASE_INSTALLED',
  RELEASE_AVAILABLE: 'RELEASE_AVAILABLE',
  SW_UPDATE_REJECTED: 'SW_UPDATE_REJECTED',
  INSTALLATION_FAILED: 'INSTALLATION_FAILED',
  DOWNLOAD_FAILS: 'DOWNLOAD_FAILS',
  ACK_PENDING: 'ACK_PENDING'
}
