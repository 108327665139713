import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { isEmptyObject } from 'utils/dataUtils'
import {
  setCards,
  updateCard,
  updateCardVersion,
  insertNotifications,
  updateNotifications,
  createNotificationDTO,
  shouldContinuePolling,
  updatePollingResult,
  updatePollingFailure,
  updatePollingStatus,
  updatePollingCounter,
  getNotificationSections,
  updateDownloadCount,
  toggleCard,
  resetCardVisibility
} from 'store/utils/deviceDetailsUtils'
import { deviceTypes, DEVICEDETAILS_VERTICALBUTTONS } from 'constants/device'
import { notificationSections } from 'constants/nextconnectServicesConfiguration'

// Constants
export const constants = {
  FETCH: 'DEVICE_DETAILS/FETCH',
  RECEIVE_DEVICE_DETAILS: 'DEVICE_DETAILS/RECEIVE_DEVICE_DETAILS',
  FETCH_FAILURE: 'DEVICE_DETAILS/FETCH_FAILURE',
  FETCH_DEVICE_FAILURE: 'DEVICE_DETAILS/FETCH_DEVICE_FAILURE',
  RECEIVE_CARDS: 'DEVICE_DETAILS/RECEIVE_CARDS',
  UPDATE_FILTER: 'DEVICE_DETAILS/UPDATE_FILTER',
  TOGGLE_TAB_VISIBILITY: 'DEVICE_DETAILS/TOGGLE_TAB_VISIBILITY',
  RECEIVE_EQUIPMENT_SERVICE_RECORDS: 'DEVICE_DETAILS/RECEIVE_EQUIPMENT_SERVICE_RECORDS',
  FETCH_EQUIPMENT_SERVICE_RECORDS: 'DEVICE_DETAILS/FETCH_EQUIPMENT_SERVICE_RECORDS',
  UPDATE_CARDS_VERSION: 'DEVICE_DETAILS/UPDATE_CARDS_VERSION',
  UPDATE_CARD: 'DEVICE_DETAILS/UPDATE_CARD',
  ADD_TRANSIENT_CARD: 'DEVICE_DETAILS/ADD_TRANSIENT_CARD',
  DELETE_CARD: 'DEVICE_DETAILS/DELETE_CARD',
  UPDATE_DOWNLOAD_COUNT: 'DEVICE_DETAILS/UPDATE_DOWNLOAD_COUNT',
  INSERT_NOTIFICATIONS: 'DEVICE_DETAILS/INSERT_NOTIFICATION',
  RESET_NOTIFICATIONS: 'DEVICE_DETAILS/RESET_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS: 'DEVICE_DETAILS/UPDATE_NOTIFICATIONS',
  FETCH_NOTIFICATION_COORDINATOR: 'DEVICE_DETAILS/FETCH_NOTIFICATION_COORDINATOR',
  FETCH_NOTIFICATION_COORDINATOR_FAILURE: 'DEVICE_DETAILS/FETCH_NOTIFICATION_COORDINATOR_FAILURE',
  RECEIVE_NOTIFICATION_COORDINATOR: 'DEVICE_DETAILS/RECEIVE_NOTIFICATION_COORDINATOR',
  FETCH_ANALYTICS_POLL: 'DEVICE_DETAILS/FETCH_ANALYTICS_POLL',
  RECEIVE_ANALYTICS_POLL: 'DEVICE_DETAILS/RECEIVE_ANALYTICS_POLL',
  FETCH_ANALYTICS_POLL_FAILURE: 'DEVICE_DETAILS/FETCH_ANALYTICS_POLL_FAILURE',
  UPDATE_NOTIFICATION_SECTIONS: 'DEVICE_DETAILS/UPDATE_NOTIFICATION_SECTIONS',
  SET_POLL_TIMER_ID: 'DEVICE_DETAILS/SET_POLL_TIMER_ID',
  CLEAR_POLL_TIMER_ID: 'DEVICE_DETAILS/CLEAR_POLL_TIMER_ID',
  SORT_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/SORT_PERIPHERAL_INFORMATION',
  SET_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/SET_PERIPHERAL_INFORMATION',
  UNSET_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/UNSET_PERIPHERAL_INFORMATION',
  ADDED_PARTNER_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/ADDED_PARTNER_PERIPHERAL_INFORMATION',
  SET_QUALITY_REPORT_TIME_PERIOD_SUCCESS: 'DEVICE_DETAILS/SET_QUALITY_REPORT_TIME_PERIOD_SUCCESS',
  SET_QUALITY_REPORT_TIME_PERIOD_FAILURE: 'DEVICE_DETAILS/SET_QUALITY_REPORT_TIME_PERIOD_FAILURE',
  RESET_QUALITY_REPORT_TIME_PERIOD_SUCCESS_STATUS: 'DEVICE_DETAILS/RESET_QUALITY_REPORT_TIME_PERIOD_SUCCESS_STATUS',
  FETCH_ACTIVE_EVENTS_SUCCESS: 'DEVICE_DETAILS/FETCH_ACTIVE_EVENTS_SUCCESS',
  FETCH_ACTIVE_EVENTS_FAILURE: 'DEVICE_DETAILS/FETCH_ACTIVE_EVENTS_FAILURE',
  SEND_CONTACT_HOTLINE_SUCCESS: 'DEVICE_DETAILS/SEND_CONTACT_HOTLINE_SUCCESS',
  SEND_CONTACT_HOTLINE_FAILURE: 'DEVICE_DETAILS/SEND_CONTACT_HOTLINE_FAILURE',
  RESET_CONTACT_HOTLINE_STATUS: 'DEVICE_DETAILS/RESET_CONTACT_HOTLINE_STATUS',
  FETCH_REMOTE_CONNECTION: 'DEVICE_DETAILS/FETCH_REMOTE_CONNECTION',
  TOGGLE_REMOTE_CONNECTION: 'DEVICE_DETAILS/TOGGLE_REMOTE_CONNECTION',
  FETCH_REMOTE_CONNECTION_FAILURE: 'DEVICE_DETAILS/FETCH_REMOTE_CONNECTION_FAILURE',
  FETCH_HOTLINE_CATEGORY_SUCCESS: 'DEVICE_DETAILS/FETCH_HOTLINE_CATEGORY_SUCCESS',
  UPDATED_PARTNER_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/UPDATED_PARTNER_PERIPHERAL_INFORMATION',
  DELETED_PARTNER_PERIPHERAL_INFORMATION: 'DEVICE_DETAILS/DELETED_PARTNER_PERIPHERAL_INFORMATION',
  FETCH_PERIPHERAL_DEVICE_FAILURE: 'DEVICE_DETAILS/FETCH_PERIPHERAL_DEVICE_FAILURE',
  PERIPHERAL_DEVICE_OPERATION_FAILURE: 'DEVICE_DETAILS/PERIPHERAL_DEVICE_OPERATION_FAILURE',
  FETCH_PERIPHERAL_SUPPLIER: 'DEVICE_DETAILS/FETCH_PERIPHERAL_SUPPLIER',
  FETCH_PERIPHERAL_SUPPLIER_FAILURE: 'DEVICE_DETAILS/FETCH_PERIPHERAL_SUPPLIER_FAILURE',
  FETCH_DEVICES_SUCCESS: 'DEVICE_DETAILS/FETCH_DEVICES_SUCCESS',
  CLEAR_SEARCHED_DEVICES: 'DEVICE_DETAILS/CLEAR_SEARCHED_DEVICES',
  RECEIVE_DEVICE_CONFIG: 'DEVICE_DETAILS/RECEIVE_DEVICE_CONFIG',
  FETCH_DEVICE_CONFIG_FAILURE: 'DEVICE_DETAILS/FETCH_DEVICE_CONFIG_FAILURE',
  TOGGLE_CARD: 'DEVICE_DETAILS/TOGGLE_CARD',
  RESET_CARD_VISIBILITY: 'DEVICE_DETAILS/RESET_CARD_VISIBILITY',
  RECEIVE_DEVICE_SYSTEM_CONFIGURATION: 'DEVICE_DETAILS/RECEIVE_DEVICE_SYSTEM_CONFIGURATION',
  FETCH_SYSTEM_CONFIGURATION_FAILURE: 'DEVICE_DETAILS/FETCH_SYSTEM_CONFIGURATION_FAILURE',
  FETCH_PERIPHERAL_PARTNER_TYPE_SUCCESS: 'ORGANIZATION_DETAILS/FETCH_PERIPHERAL_PARTNER_TYPE_SUCCESS',
  FETCH_PERIPHERAL_PARTNER_TYPE_ERROR: 'ORGANIZATION_DETAILS/FETCH_PERIPHERAL_PARTNER_TYPE_ERROR',
  RESET_EEROR_MESSAGE: 'RESET_EEROR_MESSAGE',
  DEVICE_REMOTE_SOFTWARE_RESPONSE: 'DEVICE_DETAILS/DEVICE_REMOTE_SOFTWARE_RESPONSE',
  DEVICE_REMOTE_SOFTWARE_FAILURE: 'DEVICE_DETAILS/DEVICE_REMOTE_SOFTWARE_FAILURE',
  UPDATE_DEVICE_REMOTE_SOFTWARE_REGULATED_VALUE: 'DEVICE_DETAILS/UPDATE_DEVICE_REMOTE_SOFTWARE_REGULATED_VALUE',
  DEVICE_UPDATE_REMOTE_SOFTWARE_RESET: 'DEVICE_DETAILS/DEVICE_UPDATE_REMOTE_SOFTWARE_RESET',
  SET_DISPENSE_REPORT_PERIOD_SUCCESS: 'DEVICE_DETAILS/SET_DISPENSE_REPORT_PERIOD_SUCCESS',
  RESET_DISPENSE_REPORT_PERIOD_SUCCESS_STATUS: 'DEVICE_DETAILS/RESET_DISPENSE_REPORT_PERIOD_SUCCESS_STATUS',
  FETCH_DEVICE_DETAILS_BTN: 'DEVICE_DETAILS/FETCH_DEVICE_DETAILS_BTN',
  FETCHED_DEVICE_DETAILS_BTN_SUCCESS: 'DEVICE_DETAILS/FETCHED_DEVICE_DETAILS_BTN_SUCCESS',
  FETCHED_DEVICE_DETAILS_BTN_FAILURE: 'DEVICE_DETAILS/FETCHED_DEVICE_DETAILS_BTN_FAILURE',
  FETCH_SAVE_DEVICE_DETAILS_BTN: 'DEVICE_DETAILS/FETCH_SAVE_DEVICE_DETAILS_BTN',
  SAVE_DEVICE_DETAILS_BTN_SUCCESS: 'DEVICE_DETAILS/SAVE_DEVICE_DETAILS_BTN_SUCCESS',
  SAVE_DEVICE_DETAILS_BTN_FAILURE: 'DEVICE_DETAILS/SAVE_DEVICE_DETAILS_BTN_FAILURE',
  RESET_DEVICE_DETAILS_BTNS: 'DEVICE_DETAILS/RESET_DEVICE_DETAILS_BTNS',
  REMOTE_CONNECTION_SESSION_DATA: 'DEVICE_DETAILS/REMOTE_CONNECTION_SESSION_DATA',
  RESET_REMOTE_CONNECTION_SESSION_DATA: 'DEVICE_DETAILS/RESET_REMOTE_CONNECTION_SESSION_DATA',
  SET_CONTACT_HOTLINE_STATUS_SUCCESS: 'DEVICE_DETAILS/SET_CONTACT_HOTLINE_STATUS_SUCCESS',
  FETCH_HOTLINE_USER_SUCCESS: 'DEVICE_DETAILS/FETCH_HOTLINE_USER_SUCCESS',
  FETCH_HOTLINE_USER_FAILURE: 'DEVICE_DETAILS/FETCH_HOTLINE_USER_FAILURE',
  FETCH_HOTLINE_USER_ACCESS_SUCCESS: 'DEVICE_DETAILS/FETCH_HOTLINE_USER_ACCESS_SUCCESS',
  FETCH_HOTLINE_USER_ACCESS_FAILURE: 'DEVICE_DETAILS/FETCH_HOTLINE_USER_ACCESS_FAILURE'
}

// Action Creators
export const actions = {
  saveDeviceDetailsBtn(data) {
    return async (dispatch) => {
      await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH_SAVE_DEVICE_DETAILS_BTN }),
        apiFunction: 'saveDeviceDetailsBtn',
        args: data,
        onSuccess: ({ data }) => {
          dispatch(actions.fetchDeviceDetailsBtn(DEVICEDETAILS_VERTICALBUTTONS))
          return ({
            type: constants.SAVE_DEVICE_DETAILS_BTN_SUCCESS,
            data
          })
        },
        onFailure: ({ status }) => ({
          type: constants.SAVE_DEVICE_DETAILS_BTN_FAILURE,
          error: status
        })
      })
      )
    }
  },
  fetchDeviceDetailsAndCards(deviceId) {
    return async (dispatch, getState) => {
      await dispatch(actions.fetchDeviceDetails(deviceId))
      const device = selectors.device(getState()).toJS() || {}
      if (!isEmptyObject(device)) {
        const {
          deviceType: { id: deviceTypeId, name: deviceTypeName, deviceFamily: { name: deviceFamilyName } }, connected
        } = device

        await dispatch(actions.fetchDeviceConfig(deviceId, false))

        await dispatch(actions.fetchDeviceCards(deviceTypeId, connected, deviceId))
        const cards = selectors.cards(getState())?.toJS()

        dispatch(actions.fetchEquipmentServiceRecords(deviceTypeId))

        await dispatch(actions.fetchNotificationSections(connected ? 'connected' : 'unconnected', deviceFamilyName))

        const notificationSections = selectors.notificationSections(getState())?.toJS()
        const newNotifications = getNotificationSections(cards, notificationSections)

        if (newNotifications.length > 0) {
          dispatch(actions.insertNotifications(newNotifications))
          dispatch(actions.initiateAnalyticsPoll('CREATE', newNotifications, 'PAGE_LOAD'))
        }

        if (deviceTypeName !== deviceTypes.LABWATER) {
          // expand first section with cards
          for (let section in cards) {
            if (cards[section].length > 0) {
              dispatch(actions.toggleAccordionState(section))
              break
            }
          }
        } else {
          // expand default section
          // if section is present and it has atleast one card
          let defaultSections = ['general', 'diagnostics']
          defaultSections.forEach((section) => {
            if ((section in cards) && (cards[section].length > 0)) {
              dispatch(actions.toggleAccordionState(section))
            }
          })
        }
      }
    }
  },
  fetchDeviceConfig(deviceId, throwError = true) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceConfig',
      args: { deviceId },
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_DEVICE_CONFIG,
        data
      }),
      throwError,
      onFailure: ({ status }) => ({
        type: constants.FETCH_DEVICE_CONFIG_FAILURE,
        error: status
      })
    })
  },
  fetchDeviceDetails(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceDetails',
      args: {
        deviceId
      },
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_DEVICE_DETAILS,
        data
      }),
      onFailure: ({ status }) => ({
        type: constants.FETCH_DEVICE_FAILURE,
        error: status
      })
    })
  },
  updatePartnerPeripheral(peripheralDeviceId, peripheralDeviceInfo) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'updatePartnerPeripheralInformation',
      args: { peripheralDeviceId, peripheralDeviceInfo },
      throwError: true,
      onSuccess: () => ({ type: constants.UPDATED_PARTNER_PERIPHERAL_INFORMATION }),
      onFailure: (error) => ({
        type: constants.PERIPHERAL_DEVICE_OPERATION_FAILURE,
        error: error
      })
    })
  },
  fetchPeripheralSuppliers(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchPeripheralSuppliers',
      args: {
        deviceId
      },
      onSuccess: ({ data }) => ({
        type: constants.FETCH_PERIPHERAL_SUPPLIER,
        data
      }),
      onFailure: ({ status }) => ({
        type: constants.FETCH_PERIPHERAL_SUPPLIER_ERROR,
        error: status
      })
    })
  },
  fetchDeviceCards(deviceTypeId, connected, deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceCards',
      onSuccess: ({ data }) => {
        return ({
          type: constants.RECEIVE_CARDS,
          cards: data
        })
      },
      args: {
        deviceTypeId,
        connected: (connected) ? 'true' : 'false',
        deviceId
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.toString()
      })
    })
  },
  fetchNotificationSections(connectivity, deviceFamilyName) {
    return {
      type: constants.UPDATE_NOTIFICATION_SECTIONS,
      notificationSections: notificationSections[connectivity][deviceFamilyName] || []
    }
  },
  filterEquipmentServiceRecd(filter) {
    return {
      type: constants.UPDATE_FILTER,
      filter
    }
  },
  fetchEquipmentServiceRecords(deviceTypeId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_EQUIPMENT_SERVICE_RECORDS }),
      apiFunction: 'fetchEquipmentServiceRecords',
      onSuccess: ({ data: equipmentServiceRecords }) => ({
        type: constants.RECEIVE_EQUIPMENT_SERVICE_RECORDS,
        equipmentServiceRecords
      }),
      args: {
        deviceTypeId
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error: error.toString()
      })
    })
  },
  refreshCards(cardsReferences) {
    return {
      type: constants.UPDATE_CARDS_VERSION,
      cardsReferences
    }
  },
  updateCard(cardId, card) {
    return {
      type: constants.UPDATE_CARD,
      cardId,
      card
    }
  },
  toggleCard(cardsReferences) {
    return {
      type: constants.TOGGLE_CARD,
      cardsReferences
    }
  },
  addTransientCard(card) {
    return {
      type: constants.ADD_TRANSIENT_CARD,
      card
    }
  },
  deleteCard(id, dataType) {
    return {
      type: constants.DELETE_CARD,
      id,
      dataType
    }
  },
  updateDownloadCount(cardId, dataType, downloadAs) {
    return {
      type: constants.UPDATE_DOWNLOAD_COUNT,
      cardId,
      dataType,
      downloadAs
    }
  },
  toggleAccordionState(section) {
    return {
      type: constants.TOGGLE_TAB_VISIBILITY,
      section
    }
  },
  insertNotifications(aliasNames) {
    return {
      type: constants.INSERT_NOTIFICATIONS,
      aliasNames
    }
  },
  resetNotifications(aliasNames) {
    return {
      type: constants.RESET_NOTIFICATIONS,
      aliasNames
    }
  },
  setPollTimerId(pollTimerId) {
    return {
      type: constants.SET_POLL_TIMER_ID,
      pollTimerId
    }
  },
  clearPollTimerId() {
    return {
      type: constants.CLEAR_POLL_TIMER_ID
    }
  },
  getPartnerTypesByBrandId(id) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'getPartnerTypesByBrandId',
      args: id,
      onSuccess: ({ data }) => ({ type: constants.FETCH_PERIPHERAL_PARTNER_TYPE_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_PERIPHERAL_PARTNER_TYPE_ERROR, error }),
      throwError: true
    })
  },
  // NOTE:
  // (1) supported actions GET, CREATE
  initiateAnalyticsPoll(action, aliasNames, initiator) {
    return async (dispatch, getState) => {
      const {
        deviceType: { id: deviceTypeId }, uuid: deviceUUID, deviceId
      } = selectors.device(getState()).toJS()

      const pollTimerId = selectors.pollTimerId(getState())

      // since shouldContinuePolling() decides from the state of notifications
      // should be invoked before updating notifications
      const pollingInProgress = shouldContinuePolling(
        selectors.notifications(getState()).toJS())

      dispatch(actions.resetNotifications(aliasNames))

      const notificationAliases = aliasNames
        .map(({ aliasName }) => aliasName)

      await dispatch(actions.fetchNotificationCoordinator({
        action,
        deviceId,
        deviceTypeId,
        deviceUUID,
        notificationAliases,
        initiator
      }))

      if (!pollingInProgress) {
        if (typeof pollTimerId === 'number') {
          clearTimeout(pollTimerId)
          dispatch(actions.clearPollTimerId())
        }

        dispatch(actions.pollDevice())
      }
    }
  },
  fetchNotificationCoordinator({ action, initiator, ...notificationData }) {
    const { notificationAliases: aliasNames } = notificationData

    return safeFetch({
      onFetch: () => ({
        type: constants.FETCH_NOTIFICATION_COORDINATOR,
        aliasNames,
        initiator
      }),
      apiFunction: 'fetchNotificationCoordinator',
      args: { action, notificationData },
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_NOTIFICATION_COORDINATOR,
        data
      }),
      onFailure: () => ({
        type: constants.FETCH_NOTIFICATION_COORDINATOR_FAILURE,
        aliasNames,
        initiator
      })
    })
  },
  analyticsPoll(notificationDTO) {
    return safeFetch({
      apiFunction: 'analyticsPoll',
      args: { ...notificationDTO },
      onFetch: () => ({
        type: constants.FETCH_ANALYTICS_POLL
      }),
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_ANALYTICS_POLL,
        data
      }),
      onFailure: () => {
        const notificationIds = notificationDTO.notifications
          .map(({ notificationId }) => notificationId[0])

        return {
          type: constants.FETCH_ANALYTICS_POLL_FAILURE,
          notificationIds
        }
      }
    })
  },
  // NOTE:
  //  (1) recursiveness is decided by method shouldContinuePolling(), which
  //      checks pollingStatus of each notifications entries
  //  (2) shouldContinuePolling() should be checked before invoking
  //      actions.pollDevice()
  //  (3) assuming state will reset on unmount, since all notifications will
  //      get cleared shouldContinuePolling() will return false
  pollDevice() {
    return async (dispatch, getState) => {
      const notificationDTO = createNotificationDTO(
        selectors.device(getState()).toJS(),
        selectors.notifications(getState()).toJS()
      )

      await dispatch(actions.analyticsPoll(notificationDTO))

      const pollTimerId = setTimeout(() => {
        const shouldPoll = shouldContinuePolling(
          selectors.notifications(getState()).toJS())

        if (shouldPoll) {
          dispatch(actions.pollDevice())
        }
      }, 4000)

      dispatch(actions.setPollTimerId(pollTimerId))
    }
  },
  sortPeripheralInformation(sortColumn) {
    return { type: constants.SORT_PERIPHERAL_INFORMATION, sortColumn }
  },
  addPartnerPeripheral(deviceId, peripheralDeviceInfo, selectedPartnerType) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'addPartnerPeripheralInformation',
      args: { deviceId, peripheralDeviceInfo, selectedPartnerType },
      throwError: true,
      onSuccess: () => ({ type: constants.ADDED_PARTNER_PERIPHERAL_INFORMATION }),
      onFailure: (error) => ({
        type: constants.PERIPHERAL_DEVICE_OPERATION_FAILURE,
        error: error
      })
    })
  },
  fetchActiveEvents(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchActiveEvents',
      args: { deviceId, active: true },
      onSuccess: ({ data }) => ({ type: constants.FETCH_ACTIVE_EVENTS_SUCCESS, events: data }),
      onFailure: ({ status }) => ({
        type: constants.FETCH_ACTIVE_EVENTS_FAILURE,
        error: status
      })
    })
  },
  sendContactHotline(values) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'sendContactHotline',
      args: values,
      onSuccess: () => ({ type: constants.SEND_CONTACT_HOTLINE_SUCCESS }),
      onFailure: ({ status }) => ({
        type: constants.SEND_CONTACT_HOTLINE_FAILURE,
        error: status
      }),
      throwError: true
    })
  },
  resetContactHotlineStatus() {
    return { type: constants.RESET_CONTACT_HOTLINE_STATUS }
  },
  toggleRemoteConnectionBtn() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_REMOTE_CONNECTION }),
      apiFunction: 'fetchRemoteConnectionBtnDisplayStatus',
      onSuccess: ({ data }) => ({
        type: constants.TOGGLE_REMOTE_CONNECTION,
        data
      }),
      onFailure: (error) => ({
        type: constants.FETCH_REMOTE_CONNECTION_FAILURE,
        error: error.toString()
      })
    })
  },
  fetchHotlineCategories(regionId, countryId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchHotlineCategories',
      args: { regionId, countryId },
      onSuccess: ({ data }) => ({ type: constants.FETCH_HOTLINE_CATEGORY_SUCCESS, category: data }),
      onFailure: (error) => ({
        type: constants.FETCH_HOTLINE_CATEGORY_FAILURE,
        error: error.toString()
      })
    })
  },
  setQualityReportTimePeriod({ uuid, params }) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'setQualityReportTimePeriod',
      args: { uuid, params },
      onSuccess: () => ({ type: constants.SET_QUALITY_REPORT_TIME_PERIOD_SUCCESS }),
      onFailure: (error) => ({
        type: constants.SET_QUALITY_REPORT_TIME_PERIOD_FAILURE,
        error
      }),
      throwError: true
    })
  },
  resetSetQualityReportTimePeriodSuccessStatus() {
    return {
      type: constants.RESET_QUALITY_REPORT_TIME_PERIOD_SUCCESS_STATUS
    }
  },
  searchDeviceBySerialno(serialno) {
    const query = {
      searchBy: serialno
    }

    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDevices',
      args: query,
      onSuccess: ({ data }) => {
        return {
          type: constants.FETCH_DEVICES_SUCCESS,
          data
        }
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error
      }),
      throwError: true
    })
  },
  clearSearchedDevices() {
    return {
      type: constants.CLEAR_SEARCHED_DEVICES
    }
  },
  resetCardVisibility(section) {
    return { type: constants.RESET_CARD_VISIBILITY, section }
  },
  setRemoteConnectionSessionData(data) {
    return ({ type: constants.REMOTE_CONNECTION_SESSION_DATA, data })
  },
  resetRemoteConnectionSessionData() {
    return { type: constants.RESET_REMOTE_CONNECTION_SESSION_DATA }
  },
  resetErrorMessage() {
    return { type: constants.RESET_EEROR_MESSAGE }
  },
  fetchDeviceSystemConfiguration(uuid) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceSystemConfiguration',
      args: { uuid },
      onSuccess: ({ data }) => ({
        type: constants.RECEIVE_DEVICE_SYSTEM_CONFIGURATION,
        data
      }),
      onFailure: ({ status }) => ({
        type: constants.FETCH_SYSTEM_CONFIGURATION_FAILURE,
        error: status
      })
    })
  },
  fetchDeviceSoftwareConfig(deviceId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchDeviceSoftwareConfig',
      args: deviceId,
      onSuccess: ({ data }) => ({ type: constants.DEVICE_REMOTE_SOFTWARE_RESPONSE, data }),
      onFailure: (error) => ({ type: constants.DEVICE_REMOTE_SOFTWARE_FAILURE, error }),
      throwError: true
    })
  },
  fetchHotlineUserRole() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchHotlineUserRole',
      onSuccess: ({ data }) => ({ type: constants.FETCH_HOTLINE_USER_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_HOTLINE_USER_FAILURE, error }),
      throwError: true
    })
  },
  updateDeviceSoftwareRegaulate(regulated) {
    return { type: constants.UPDATE_DEVICE_REMOTE_SOFTWARE_REGULATED_VALUE, regulated }
  },
  resetDeviceSoftwareConfig() {
    return { type: constants.DEVICE_UPDATE_REMOTE_SOFTWARE_RESET }
  },
  setDispenseReportPeriodStatus() {
    return ({ type: constants.SET_DISPENSE_REPORT_PERIOD_SUCCESS })
  },
  setContactHotlineStatus() {
    return ({ type: constants.SET_CONTACT_HOTLINE_STATUS_SUCCESS })
  },
  resetDispenseReportPeriodSuccessStatus() {
    return { type: constants.RESET_DISPENSE_REPORT_PERIOD_SUCCESS_STATUS }
  },
  resetDeviceDetailsBtn() {
    return { type: constants.RESET_DEVICE_DETAILS_BTNS }
  },
  fetchDeviceDetailsBtn(menuGroupName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_DEVICE_DETAILS_BTN }),
      apiFunction: 'fetchDeviceDetailsBtn',
      args: menuGroupName,
      onSuccess: ({ data }) => ({ type: constants.FETCHED_DEVICE_DETAILS_BTN_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCHED_DEVICE_DETAILS_BTN_FAILURE, error }),
      throwError: true
    })
  },
  fetchHotlineUserAccess() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchHotlineUserAccess',
      onSuccess: ({ data }) => ({ type: constants.FETCH_HOTLINE_USER_ACCESS_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_HOTLINE_USER_ACCESS_FAILURE, error }),
      throwError: true
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  device: {},
  searchedDevices: [],
  cards: {},
  fetchError: null,
  fetchDeviceError: null,
  filter: '',
  accordionState: {
    general: false,
    diagnostic: false,
    process: false,
    inventory: false
  },
  pollTimerId: null,
  notificationSections: [],
  notifications: {},
  equipmentServiceRecords: [],
  fetchEquipmentServiceRecords: false,
  transientCards: {},
  partnerPeripheralInformation: [],
  peripheralInformationSortDirection: true,
  peripheralInformationSortColumn: 'name',
  selectedperipheralInformation: null,
  qualityReportTimePeriodSuccess: '',
  fetchQualityReportTimePeriodFailure: null,
  sendContactHotlineSuccess: null,
  sendContactHotlineFailure: null,
  hotlineCategory: null,
  deviceActiveEvents: [],
  loadInitialContactHotline: false,
  fetchPeripheralDeviceError: null,
  suppliers: [],
  fetchPeripheralSuppliers: null,
  deviceConfig: {},
  deviceSystemConfiguration: [],
  regulated: false,
  deviceSoftwareConfigSuccess: false,
  deviceSoftwareConfig: null,
  qualityDispenseReportSuccess: null,
  remoteConnectionSessionData: {},
  deviceDetailsBtnList: null,
  fetchingDeviceDetailsBtnList: false,
  deviceDetailsBtnListError: null,
  isServiceVisit: false,
  fetchingSaveDeviceDetailsBtn: false,
  ackSaveDeviceDetailsBtn: null,
  errorSaveDeviceDetailsBtn: null,
  cardsLoaded: false,
  hotlineUser: null,
  hotlineAccessValue: null
})
let indexToUpdate = '', updatedCardsList = '', indexToDelete = '', indexToUpdateCard = ''
export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
    case constants.RECEIVE_DEVICE_DETAILS:
      return state
        .set('fetching', false)
        .set('device', fromJS(action.data))
        .set('regulated', fromJS(!action.data.userRegulatedEnvironment))
    case constants.FETCH_PERIPHERAL_SUPPLIER:
      return state
        .set('fetching', false)
        .set('suppliers', action.data)
    case constants.FETCH_PERIPHERAL_SUPPLIER_FAILURE:
      return state
        .set('fetching', false)
    case constants.RECEIVE_CARDS:
      return setCards(state, action.cards)
        .set('cardsLoaded', true)
        .set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('cardsLoaded', true)
        .set('fetchError', fromJS(action.error))
        .set('fetching', false)
    case constants.FETCH_DEVICE_FAILURE:
      return state
        .set('fetchDeviceError', fromJS(action.error))
        .set('fetching', false)
    case constants.UPDATE_FILTER:
      return state.set('filter', fromJS(action.filter))
    case constants.TOGGLE_TAB_VISIBILITY:
      return state.updateIn(['accordionState', action.section], state => !state)
    case constants.FETCH_EQUIPMENT_SERVICE_RECORDS:
      return state
        .set('fetchEquipmentServiceRecords', true)
    case constants.RECEIVE_EQUIPMENT_SERVICE_RECORDS:
      return state
        .set('equipmentServiceRecords', fromJS(action.equipmentServiceRecords))
        .set('fetchEquipmentServiceRecords', false)
    case constants.UPDATE_CARDS_VERSION:
      return updateCardVersion(state, action.cardsReferences)
    case constants.UPDATE_CARD:
      return updateCard(state, action.cardId, action.card)
    case constants.TOGGLE_CARD:
      return toggleCard(state, action.cardsReferences)
    case constants.RESET_CARD_VISIBILITY:
      return resetCardVisibility(state, action.section)
    case constants.ADD_TRANSIENT_CARD:
      indexToUpdate = state.getIn(['transientCards', action.card.dataType])
        .findIndex(card => card.get('id') === action.card.id)
      updatedCardsList = state.getIn(['cards', action.card.dataType])
        .push(fromJS(action.card))
      return state
        .setIn(['cards', action.card.dataType], updatedCardsList)
        .updateIn(['transientCards', action.card.dataType, indexToUpdate], card =>
          card.set('added', true))
    case constants.DELETE_CARD:
      indexToDelete = state.getIn(['cards', action.dataType])
        .findIndex(card => card.get('id') === action.id)
      indexToUpdateCard = state.getIn(['transientCards', action.dataType])
        .findIndex(card => card.get('id') === action.id)
      return state
        .deleteIn(['cards', action.dataType, indexToDelete])
        .updateIn(['transientCards', action.dataType, indexToUpdateCard], card =>
          card.set('added', false)
        )
    case constants.UPDATE_DOWNLOAD_COUNT:
      return updateDownloadCount(state, action.cardId, action.dataType, action.downloadAs)
    case constants.UPDATE_NOTIFICATION_SECTIONS:
      return state
        .set('notificationSections', fromJS(action.notificationSections))
        .set('fetching', false)
    case constants.INSERT_NOTIFICATIONS:
      return insertNotifications(state, action.aliasNames, false)
    case constants.RESET_NOTIFICATIONS:
      return insertNotifications(state, action.aliasNames, true)
    case constants.FETCH_NOTIFICATION_COORDINATOR:
      return updatePollingStatus(state, action.aliasNames, true, action.initiator)
    case constants.RECEIVE_NOTIFICATION_COORDINATOR:
      return updateNotifications(state, action.data)
    case constants.FETCH_NOTIFICATION_COORDINATOR_FAILURE:
      return updatePollingStatus(state, action.aliasNames, false, action.initiator)
    case constants.FETCH_ANALYTICS_POLL:
      return updatePollingCounter(state)
    case constants.RECEIVE_ANALYTICS_POLL:
      return updatePollingResult(state, action.data)
    case constants.FETCH_ANALYTICS_POLL_FAILURE:
      return updatePollingFailure(state, action.notificationIds)
    case constants.SET_POLL_TIMER_ID:
      return state.set('pollTimerId', action.pollTimerId)
    case constants.CLEAR_POLL_TIMER_ID:
      return state.set('pollTimerId', null)
    case constants.SORT_PERIPHERAL_INFORMATION:
      return state
        .set('peripheralInformationSortColumn', action.sortColumn)
        .set('peripheralInformationSortDirection', !state.get('peripheralInformationSortDirection'))
    case constants.ADDED_PARTNER_PERIPHERAL_INFORMATION:
      return state
        .set('fetching', false)
    case constants.UPDATED_PARTNER_PERIPHERAL_INFORMATION:
      return state
        .set('fetching', false)
    case constants.PERIPHERAL_DEVICE_OPERATION_FAILURE:
      return state
        .set('fetching', false)
        .set('errorMessage', action.error.errorMessage)
    case constants.RESET_EEROR_MESSAGE:
      return state
        .set('fetchQualityReportTimePeriodFailure', null)
        .set('errorMessage', null)
    case constants.DELETED_PARTNER_PERIPHERAL_INFORMATION:
      return state
        .set('fetching', false)
    case constants.FETCH_ACTIVE_EVENTS_SUCCESS:
      return state
        .set('fetching', false)
        .set('deviceActiveEvents', action.events)
        .set('loadInitialContactHotline', true)
    case constants.FETCH_ACTIVE_EVENTS_FAILURE:
      return state
        .set('fetching', false)
        .set('loadInitialContactHotline', true)
    case constants.SEND_CONTACT_HOTLINE_SUCCESS:
      return state
        .set('fetching', false)
        .set('sendContactHotlineSuccess', true)
    case constants.SEND_CONTACT_HOTLINE_FAILURE:
      return state
        .set('fetching', false)
        .set('sendContactHotlineFailure', true)
    case constants.RESET_CONTACT_HOTLINE_STATUS:
      return state
        .set('sendContactHotlineSuccess', null)
        .set('sendContactHotlineFailure', null)
    case constants.FETCH_HOTLINE_CATEGORY_SUCCESS:
      return state
        .set('fetching', false)
        .set('hotlineCategory', action.category)
    case constants.SET_QUALITY_REPORT_TIME_PERIOD_SUCCESS:
      return state
        .set('fetching', false)
        .set('qualityReportTimePeriodSuccess', 'SUCCESS')
    case constants.SET_QUALITY_REPORT_TIME_PERIOD_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchQualityReportTimePeriodFailure', (action.error.errorMessage))
    case constants.RESET_QUALITY_REPORT_TIME_PERIOD_SUCCESS_STATUS:
      return state
        .set('qualityReportTimePeriodSuccess', '')
    case constants.FETCH_DEVICES_SUCCESS:
      return state
        .set('fetching', false)
        .set('searchedDevices', fromJS(action.data))
    case constants.CLEAR_SEARCHED_DEVICES:
      return state
        .set('searchedDevices', fromJS([]))
    case constants.RECEIVE_DEVICE_CONFIG:
      return state
        .set('deviceConfig', fromJS(action.data))
        .set('fetching', false)
    case constants.FETCH_DEVICE_CONFIG_FAILURE:
      if (action.error !== 404) {
        return state
          .set('fetchError', fromJS(action.error))
          .set('fetching', false)
      }
      return state
        .set('deviceConfig', fromJS({}))
        .set('fetchError', null)
        .set('fetching', false)
    case constants.RECEIVE_DEVICE_SYSTEM_CONFIGURATION:
      return state
        .set('deviceSystemConfiguration', fromJS(action.data))
        .set('fetching', false)
        .set('fetchSystemConfigError', null)
    case constants.FETCH_SYSTEM_CONFIGURATION_FAILURE:
      return state
        .set('fetchSystemConfigError', fromJS(action.error))
        .set('fetching', false)
    case constants.FETCH_PERIPHERAL_PARTNER_TYPE_SUCCESS:
      return state
        .set('partnerTypes', action.data)
        .set('fetching', false)
    case constants.DEVICE_REMOTE_SOFTWARE_RESPONSE:
      return state
        .set('deviceSoftwareConfig', fromJS(action.data))
        .set('regulated', fromJS(!action.data.regulated))
        .set('fetching', false)
    case constants.DEVICE_REMOTE_SOFTWARE_FAILURE:
      return state
        .set('fetching', false)
    case constants.UPDATE_DEVICE_REMOTE_SOFTWARE_REGULATED_VALUE:
      return state
        .set('regulated', !action.regulated)
        .set('deviceSoftwareConfigSuccess', true)
        .set('fetching', false)
    case constants.DEVICE_UPDATE_REMOTE_SOFTWARE_RESET:
      return state
        .set('deviceSoftwareConfig', null)
        .set('deviceSoftwareConfigSuccess', false)
    case constants.SET_DISPENSE_REPORT_PERIOD_SUCCESS:
      return state
        .set('fetching', false)
        .set('qualityDispenseReportSuccess', 'Success')
    case constants.RESET_DISPENSE_REPORT_PERIOD_SUCCESS_STATUS:
      return state
        .set('fetching', false)
        .set('qualityDispenseReportSuccess', null)
    case constants.REMOTE_CONNECTION_SESSION_DATA:
      return state
        .set('remoteConnectionSessionData', fromJS(action.data))
    case constants.RESET_REMOTE_CONNECTION_SESSION_DATA:
      return state
        .set('remoteConnectionSessionData', {})
    case constants.FETCH_DEVICE_DETAILS_BTN:
      return state
        .set('fetchingDeviceDetailsBtnList', true)
    case constants.FETCHED_DEVICE_DETAILS_BTN_SUCCESS:
      return state
        .set('deviceDetailsBtnList', fromJS(action.data))
        .set('fetchingDeviceDetailsBtnList', false)
    case constants.FETCHED_DEVICE_DETAILS_BTN_FAILURE:
      return state
        .set('deviceDetailsBtnListError', action.error)
        .set('fetchingDeviceDetailsBtnList', false)
    case constants.SET_CONTACT_HOTLINE_STATUS_SUCCESS:
      return state
        .set('fetching', false)
        .set('sendContactHotlineSuccess', 'Success')
    case constants.FETCH_SAVE_DEVICE_DETAILS_BTN:
      return state
        .set('fetchingSaveDeviceDetailsBtn', true)
    case constants.SAVE_DEVICE_DETAILS_BTN_SUCCESS:
      return state
        .set('fetchingSaveDeviceDetailsBtn', false)
        .set('ackSaveDeviceDetailsBtn', action.data)
    case constants.SAVE_DEVICE_DETAILS_BTN_FAILURE:
      return state
        .set('fetchingSaveDeviceDetailsBtn', false)
        .set('errorSaveDeviceDetailsBtn', action.error)
    case constants.RESET_DEVICE_DETAILS_BTNS:
      return state
        .set('fetchingSaveDeviceDetailsBtn', false)
        .set('ackSaveDeviceDetailsBtn', null)
        .set('errorSaveDeviceDetailsBtn', null)
    case constants.FETCH_HOTLINE_USER_SUCCESS:
      return state
        .set('hotlineUser', action?.data)
    case constants.FETCH_HOTLINE_USER_ACCESS_SUCCESS:
      return state
        .set('hotlineAccessValue',action?.data)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceDetails

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  fetchDeviceError: createSelector(getState, (state) => {
    return state.get('fetchDeviceError')
  }),
  device: createSelector(getState, (state) => {
    return state.get('device')
  }),
  cards: createSelector(getState, (state) => {
    return state.get('cards')
  }),
  getSuppliers: createSelector(getState, (state) => {
    return state.get('suppliers')
  }),
  notificationSections: createSelector(getState, (state) => {
    return state.get('notificationSections')
  }),
  notifications: createSelector(getState, (state) => {
    return state.get('notifications')
  }),
  accordionState: createSelector(getState, (state) => {
    return state.get('accordionState')
  }),
  equipmentServiceRecords: createSelector(getState, (state) => {
    return state.get('equipmentServiceRecords')
  }),
  transientCards: createSelector(getState, (state) => {
    return state.get('transientCards')
  }),
  pollTimerId: createSelector(getState, (state) => {
    return state.get('pollTimerId')
  }),
  partnerPeripheralInformation: createSelector(getState, (state) => {
    return state.get('partnerPeripheralInformation')
  }),
  peripheralInformationSortColumn: createSelector(getState, (state) => {
    return state.get('peripheralInformationSortColumn')
  }),
  peripheralInformationSortDirection: createSelector(getState, (state) => {
    return state.get('peripheralInformationSortDirection')
  }),
  selectedperipheralInformation: createSelector(getState, (state) => {
    return state.get('selectedperipheralInformation')
  }),
  fetchQualityReportTimePeriodFailure: createSelector(getState, (state) => {
    return state.get('fetchQualityReportTimePeriodFailure')
  }),
  qualityReportTimePeriodSuccess: createSelector(getState, (state) => {
    return state.get('qualityReportTimePeriodSuccess')
  }),
  getdeviceActiveEvents: createSelector(getState, (state) => {
    return state.get('deviceActiveEvents')
  }),
  getDeviceName: createSelector(getState, (state) => {
    return state.getIn(['device', 'deviceName'])
  }),
  getDeviceTypeName: createSelector(getState, (state) => {
    return state.getIn(['device', 'deviceType', 'name'])
  }),
  getDeviceSerialNumber: createSelector(getState, (state) => {
    return state.getIn(['device', 'serialno'])
  }),
  getProductCatelogeNumber: createSelector(getState, (state) => {
    return state.getIn(['device', 'productCatalogNo'])
  }),
  sendContactHotlineSuccess: createSelector(getState, (state) => {
    return state.get('sendContactHotlineSuccess')
  }),
  sendContactHotlineFailure: createSelector(getState, (state) => {
    return state.get('sendContactHotlineFailure')
  }),
  getHotlineCategory: createSelector(getState, (state) => {
    return state.get('hotlineCategory')
  }),
  getDeviceCountry: createSelector(getState, (state) => {
    return state.getIn(['device', 'countryName'])
  }),
  getLoadInitialContactHotline: createSelector(getState, (state) => {
    return state.get('loadInitialContactHotline')
  }),
  fetchPeripheralDeviceError: createSelector(getState, (state) => {
    return state.get('fetchPeripheralDeviceError')
  }),
  fetchPeripheralSuppliers: createSelector(getState, (state) => {
    return state.get('fetchPeripheralSuppliers')
  }),
  searchedDevices: createSelector(getState, (state) => {
    return state.get('searchedDevices')
  }),
  deviceConfig: createSelector(getState, (state) => {
    return state.get('deviceConfig')
  }),
  deviceSystemConfiguration: createSelector(getState, (state) => {
    return state.get('deviceSystemConfiguration')
  }),
  partnerTypes: createSelector(getState, (state) => {
    return state.get('partnerTypes')
  }),
  errorMessage: createSelector(getState, (state) => {
    return state.get('errorMessage')
  }),
  deviceSoftwareConfig: createSelector(getState, (state) => {
    return state.get('deviceSoftwareConfig')
  }),
  deviceSoftwareConfigSuccess: createSelector(getState, (state) => {
    return state.get('deviceSoftwareConfigSuccess')
  }),
  deviceSoftwareConfigRegulated: createSelector(getState, (state) => {
    return state.get('regulated')
  }),
  qualityDispenseReportSuccess: createSelector(getState, (state) => {
    return state.get('qualityDispenseReportSuccess')
  }),
  remoteConnectionSessionData: createSelector(getState, (state) => {
    return state.get('remoteConnectionSessionData')
  }),
  deviceDetailsBtnList: createSelector(getState, (state) => {
    return state.get('deviceDetailsBtnList')
  }),
  fetchingDeviceDetailsBtnList: createSelector(getState, (state) => {
    return state.get('fetchingDeviceDetailsBtnList')
  }),
  deviceDetailsBtnListError: createSelector(getState, (state) => {
    return state.get('deviceDetailsBtnListError')
  }),
  fetchingSaveDeviceDetailsBtn: createSelector(getState, (state) => {
    return state.get('fetchingSaveDeviceDetailsBtn')
  }),
  ackSaveDeviceDetailsBtn: createSelector(getState, (state) => {
    return state.get('ackSaveDeviceDetailsBtn')
  }),
  errorSaveDeviceDetailsBtn: createSelector(getState, (state) => {
    return state.get('errorSaveDeviceDetailsBtn')
  }),
  isServiceVisit: createSelector(getState, (state) => {
    return state.get('isServiceVisit')
  }),
  cardsLoaded: createSelector(getState, (state) => {
    return state.get('cardsLoaded')
  }),
  hotlineUser: createSelector(getState, (state) => {
    return state.get('hotlineUser')
  }),
  hotlineAccessValue: createSelector(getState, (state) => {
    return state.get('hotlineAccessValue')
  })
}
