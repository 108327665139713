import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
import { searchFilters } from 'constants/customerDevices'
export const moduleId = 'DeviceAccessAction'

// Constants
export const constants = {
  FETCH: 'DEVICE_ACCESS_ACTION/FETCH',
  FETCH_FAILURE: 'DEVICE_ACCESS_ACTION/FETCH_FAILURE',
  RECEIVE_APPROVER_FILTER_CATEGORIES: 'DEVICE_ACCESS_ACTION/RECEIVE_APPROVER_FILTER_CATEGORIES',
  FETCH_COMPLETE: 'DEVICE_ACCESS_ACTION/FETCH_COMPLETE',
  USER_RESPONSE_VALIDATION: 'DEVICE_ACCESS_ACTION/USER_RESPONSE_VALIDATION'
}

// Actions
export const actions = {
  responseUserDevice(query) {
    return safeFetch({
      throwError: true,
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'deviceAssignmentValidation',
      args: query,
      onSuccess: ({ data }) => {
        return ({
          type: constants.USER_RESPONSE_VALIDATION
        })
      },
      onFailure: (error) => ({
        type: constants.FETCH_FAILURE,
        error
      })
    })
  },
  /**
    @return {object}
  */
  loadApproverfilters() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'fetchApproverfilters',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_APPROVER_FILTER_CATEGORIES, data }),
      onFailure: (error) => ({ type: constants.FETCH_FAILURE, error })
    })
  },
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  filterCategories: []
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state.set('fetching', true)
    case constants.FETCH_COMPLETE:
      return state.set('fetching', false)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .set('fetchError', action.error)
    case constants.RECEIVE_APPROVER_FILTER_CATEGORIES:
      return state
        .set('filterCategories', parseCustomerDeviceFilters(action?.data))
        .set('fetchError', null)
        .set('fetching', false)
    case constants.USER_RESPONSE_VALIDATION:
      return state
        .set('fetchError', null)
        .set('fetching', false)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.deviceAccessAction

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) => (
    state.get('fetchError')
  )),
  filterCategories: createSelector(getState, (state) => (
    state.get('filterCategories')
  ))
}

// Helper function
const parseCustomerDeviceFilters = (filters) => {
  let parsedServiceFilters = []
  const filterOrder = [
    searchFilters.STATUS
  ]
  filterOrder.forEach((filter) => {
    if (filter in filters && filters[filter]) {
      const values = filters[filter]
      const categoryType = 'statusName'
      const categoryValues = !values
        ? []
        : values.map((val, index) => {
          return {
            label: val.statusName,
            value: val.statusName,
            key: val.statusId,
            name: val.statusName
          }
        })
      parsedServiceFilters.push({ categoryType, categoryValues })
    }
  })
  return fromJS(parsedServiceFilters)
}