export const screenSize = {
  XS: 0,
  SM: 544,
  MD: 768,
  LG: 992,
  XL: 1200
}

export const deviceMaxWidth = {
  MOBILE: 767,
  IPAD: 1024
}